export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete,
    // onOpenReportLeadConvert,
    handleOpenEditor,
    onOpenReportOutlookEmail,
    onOpenCallsProspect,
    onOpenMeetingProspect,
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.onOpenMassupdate = onOpenMassupdate;
    this.onOpenReportDelete = onOpenReportDelete;
    // this.onOpenReportLeadConvert = onOpenReportLeadConvert;
    this.handleOpenEditor = handleOpenEditor;
    this.onOpenReportOutlookEmail = onOpenReportOutlookEmail;
    this.onOpenCallsProspect = onOpenCallsProspect;
    this.onOpenMeetingProspect = onOpenMeetingProspect;
  }

  handleEditClickMassupdate() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Mass Update.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenMassupdate();
  }

  handleEditClickMassdelete() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Mass delete.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    this.onOpenReportDelete();
  }

  handleEditClickMassleadtoprospect() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Lead to prospect.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    // If checkBoxSelected is true, you can continue with the action.
    this.onOpenReportLeadConvert();
  }

  handleEditClickMassmail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Mass Mail.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the action.
    this.handleOpenEditor();
  }

  handleEditClickOutlookMail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for OutlookMail.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the action.
    this.onOpenReportOutlookEmail();
  }

  handleEditClickCalls() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for call.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for call.");
      this.toast({
        title: "Select only one row for call",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    // If checkBoxSelected is true, you can continue with the action.
    this.onOpenCallsProspect();
  }

  handleEditClickMeeting() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Meeting.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for meeting.");
      this.toast({
        title: "Select only one row for meeting",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    // If checkBoxSelected is true, you can continue with the action.
    this.onOpenMeetingProspect();
  }
}
