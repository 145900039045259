import { React, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Bruhat_Logo from "../../assets/img/Bruhat_Logo.png";
import { MdRefresh } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import Userprofile from "../User/Userprofile";
import { useToast, Menu, MenuButton, MenuList, MenuItem, Checkbox } from "@chakra-ui/react";
import { AuthAPIInstance } from "../../api-instance";
import { useSelectedModules } from "./SelectedModulesContext";
 
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { selectedOtherModules, handleModuleSelect } = useSelectedModules();
  const [isLoading, setIsLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedModules, setSelectedModules] = useState(
    JSON.parse(localStorage.getItem("selectedModules")) || []
  );
 
  const managerRoles = ["Sales Manager", "Operations Manager", "CFO", "Finance Manager"];
 
  const roles = {
    "Sales Manager": ["dashboard", "lead", "prospect", "report", "enquiry", "rate", "quotation"],
    Admin: ["dashboard", "lead", "prospect", "report", "enquiry", "rate", "quotation"],
    "Sales Team Lead": ["dashboard", "lead", "prospect", "report", "enquiry", "rate", "quotation"],
    "Sales Team Prospect": ["dashboard", "lead", "prospect", "report", "enquiry", "rate", "quotation"],
    "Sales Agent": ["dashboard", "lead", "prospect", "enquiry", "rate", "quotation"],
    TechSupport: ["dashboard", "lead", "prospect", "enquiry", "rate", "quotation", "report", "operation_home"],
    "Operations Manager": [
      "operation_home",
      "booking",
      "operation_job",
      "operation_incomplete_task",
      "operation_update",
    ],
    "Operations Asst Manager": [
      "operation_home",
      "booking",
      "operation_job",
      "operation_incomplete_task",
      "operation_update",
    ],
    CFO: ["proforma_invoice", "tax_invoice", "creditnote", "debitnote", "intercompanypi"],
    "Finace Agent": ["proforma_invoice", "tax_invoice", "creditnote", "debitnote", "intercompanypi"],
    "Finance Manager": ["proforma_invoice", "tax_invoice", "creditnote", "debitnote", "intercompanypi"],
  };
 
  const isManagerRole = () => {
    return userRoles.some((role) => managerRoles.includes(role));
  };
 
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await AuthAPIInstance.post(
        `page_auth?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&id=${JSON.parse(localStorage.getItem("user")).userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        setUserRoles(data.data.map((role) => role.Role));
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      toast({
        title: errorMessage,
        position: "bottom-center",
        isClosable: true,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
    getData();
  }, []);
 
  useEffect(() => {
    localStorage.setItem("selectedModules", JSON.stringify(selectedModules));
  }, [selectedModules]);
 
  const handleModuleToggle = (module) => {
    setSelectedModules((prevSelected) =>
      prevSelected.includes(module)
        ? prevSelected.filter((m) => m !== module)
        : [...prevSelected, module]
    );
  };  
 
  const canSeeTab = (tab) => {
    const exclusiveRoles = ["CFO", "Finace Agent", "Finance Manager"];
    if (tab === "intercompanypi") {
      return (
        userRoles.every((role) => exclusiveRoles.includes(role)) &&
        userRoles.some((role) => exclusiveRoles.includes(role))
      );
    }
    return userRoles.some((role) => roles[role]?.includes(tab));
  };
 
  const renderLink = (to, label) => {
    const isActive = location.pathname.includes(to);
    return (
      canSeeTab(to) && (
        <li key={to}>
          <Link
            to={`/${to}`}
            className={`block py-2 pr-4 pl-3 rounded lg:bg-transparent lg:p-0 hover:text-black ${
              isActive ? "text-blue-950 font-bold  border-b-4 border-orange-400" : "text-gray-500"
            }`}
          >
            {label}
          </Link>
        </li>
      )
    );
  };
 
  const masterDataItems = [
    { name: "Country", path: "/country" },
    { name: "City", path: "/city" },
    { name: "State", path: "/state" },
    { name: "Tag", path: "/tag" },
    { name: "Num Range", path: "/numrange" },
    { name: "Auth", path: "/auth" },
    { name: "Tier", path: "/grade" },
    { name: "Region", path: "/region" },
    { name: "Mode", path: "/mode" },
    { name: "Vendor Type", path: "/vendortype" },
    { name: "Product", path: "/product" },
    { name: "Industry", path: "/industry" },
    { name: "Port", path: "/port" },
    { name: "Source", path: "/source" },
    { name: "Airport", path: "/airport" },
    { name: "Incoterm", path: "/incoterm" },
    { name: "Status", path: "/status" },
    { name: "Packing Type", path: "/packingtype" },
    { name: "Client", path: "/client" },
    { name: "Operation User Region", path: "/operationuserregion" },
    { name: "User Region", path: "/userregion" },
    { name: "Equipment Container", path: "/equipmentcontainer" },
    { name: "Shippingline", path: "/shippingline" },
    { name: "Gst", path: "/gst" },
    { name: "Vendor", path: "/vendor" },
    { name: "Role", path: "/role" },
    { name: "Roleauth", path: "/roleauth" },
    { name: "Customer", path: "/customer" },
    { name: "Bank Details", path: "/bank" },
    { name: "Configs", path: "/config" },
  ].sort((a, b) => a.name.localeCompare(b.name));
 
  const normalizedLinks = [
    { to: "dashboard", label: "Dashboard" },
    { to: "lead", label: "Leads" },
    { to: "prospect", label: "Prospects" },
    { to: "enquiry", label: "Enquiry" },
    { to: "rate", label: "Rate" },
    { to: "quotation", label: "Quotation" },
    { to: "report", label: "Reports" },
    { to: "operation_home", label: "Operation Dashboard" },
    { to: "booking", label: "Booking" },
    { to: "operation_job", label: "Jobs" },
    { to: "operation_incomplete_task", label: "Incomplete Task" },
    { to: "operation_update", label: "Updates" },
    { to: "proforma_invoice", label: "Proforma Invoice" },
    { to: "tax_invoice", label: "Tax Invoice" },
    { to: "creditnote", label: "Credit Note" },
    { to: "debitnote", label: "Debit Note" },
    { to: "intercompanypi", label: "Inter-Company PI" }
  ];
 
  const visibleLinks = normalizedLinks.filter((link) => canSeeTab(link.to));
 
  const maxVisible = 4;
  const displayedLinks = visibleLinks.slice(0, maxVisible);
  const otherLinks = visibleLinks.slice(maxVisible);
 
  return (
    <header>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-slate-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link className="flex items-center">
            <img src={Bruhat_Logo} className="mr-3 h-6 sm:h-9" alt="Bruhat" />
          </Link>
          <div className="flex items-center lg:order-2">
            <MdRefresh className="mr-5 cursor-pointer" size={20} />
            <Userprofile className="ml-5 cursor-pointer" size={20} />
            <button
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <GiHamburgerMenu size={25} />
            </button>
          </div>
          <div
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {displayedLinks.map((link) => renderLink(link.to, link.label))}
              {selectedModules.map((module) => {
                const link = normalizedLinks.find((link) => link.to === module);
                return link ? renderLink(link.to, link.label) : null;
              })}
              {otherLinks.length > 0 && (
                <Menu>
                  <MenuButton>Add modules</MenuButton>
                  <MenuList style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {otherLinks.map((link) => (
                      <MenuItem key={link.to}>
                        <label>
                          <Checkbox
                            isChecked={selectedModules.includes(link.to)}
                            onChange={() => handleModuleToggle(link.to)}
                            size="sm"
                          >
                            {link.label}
                          </Checkbox>
                        </label>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
              {isManagerRole() && (
                <Menu>
                  <MenuButton className="block py-2 pr-4 pl-3 rounded lg:bg-transparent lg:p-0 text-gray-500">
                    Master Data Maintenance
                  </MenuButton>
                  <MenuList className="flex flex-col" maxHeight="300px" overflowY="auto">
                    {masterDataItems.map((item) => (
                      <MenuItem key={item.name}>
                        <Link to={item.path}>{item.name}</Link>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
 
export default Header;