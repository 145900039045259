import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Sicreation.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  select,
  Textarea,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import TextArea from "antd/es/input/TextArea";
import { Header } from "../../../components";

const AddSiCreation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  //const [formDataIndex, setFormDataIndex] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [PortData, setportData] = useState([]);
  const [IncotermData, setincotermData] = useState([]);

  const [descriptionType, setDescriptionType] = useState("Single");
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [editedSiData, setEditedSiData] = useState({});
  const [selectedBL_Type, setSelectedBL_Type] = useState("");
  const [packageTypeData, setPackageTypeData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [addressDetails, setAddressDetails] = useState("");
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("SI Job Data:", selectedJob);
  const [selectedSiData, setSelectedSiData] = useState({});

  useEffect(() => {
    if (selectedJob) {
      setSelectedSiData({
        JobInfoNo: selectedJob.JobInfono || "",
        SiNumber: selectedJob.SiNumber || "",
        Shipper: selectedJob.Shipper || "",
        JobNumber: selectedJob.JobNumber || "",
        BookingNumber: selectedJob.BookingNumber || "",
        PlaceOfReceipt: selectedJob.PlaceOfReceipt || "",
        Consignee: selectedJob.Consignee || "",
        POL: selectedJob.POL || "",
        POD: selectedJob.POD || "",
        Notify_Party_1: selectedJob.Notify_Party_1 || "",
        POD: selectedJob.POD || "",
        Notify_Party_2: selectedJob.Notify_Party_2 || "",
        PlaceOfDelivery: selectedJob.PlaceOfDelivery || "",
        Liner_Bookingno: selectedJob.Liner_Bookingno || "",
        VesselNameVoyage: selectedJob.VesselNameVoyage || "",
        Incoterm: selectedJob.Incoterm || "",
        BL_Type: selectedJob.BL_Type || "",
        HSNCode: selectedJob.HSNCode || "",
        Originals: selectedJob.Originals || "",
        Marks_Number: selectedJob.Marks_Number || "",
        Payment_terms_origin: selectedJob.Payment_terms_origin || "",
        Payment_terms_destiantion: selectedJob.Payment_terms_destiantion || "",
        Payment_terms_freight: selectedJob.Payment_terms_freight || "",
        SiOwner: JSON.parse(localStorage.getItem("user")).userId,
        //   Operations: selectedJob.Containers && selectedJob.Containers !== ""
        //   ? selectedJob.Containers.split(";").map((container) => {
        //     const containerObj = {};
        //     container.split(",").forEach((attribute) => {
        //       const [key, value] = attribute.trim().split(":");
        //       containerObj[key] = value.trim();
        //     });
        //     return containerObj;
        //   })
        // : [],
        Operations:
          selectedJob.Containers && selectedJob.Containers !== ""
            ? selectedJob.Containers.split(";").map((container) => {
                const containerObj = {};
                container.split(",").forEach((attribute) => {
                  const [key, value] = attribute
                    .trim()
                    .split(":")
                    .map((item) => item.trim());
                  containerObj[key] = value === "" ? null : value;
                });
                return containerObj;
              })
            : [],
      });
    }
  }, [selectedJob]);
  console.log(selectedSiData.Operations);

  const [formData, setFormData] = useState(selectedSiData);

  // useEffect(() => {
  //   // Update formData with editedSiData
  //   setFormData(editedSiData);
  // }, [editedSiData, descriptionType]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...editedSiData,
      Shipper: addressDetails,
    }));
  }, [editedSiData, descriptionType, addressDetails]);
  


 useEffect(() => {
    if (editedSiData.Shipper) {
      const selectedShipper = customerData.find(
        (item) => item.Company === editedSiData.Shipper
      );
      if (selectedShipper) {
        const formattedAddress = [
          selectedShipper.Company.trim(),
          `${selectedShipper.Address_Line_1.trim()} ${selectedShipper.Address_Line_2.trim()}`,
          `${selectedShipper.City.trim()}, ${selectedShipper.State.trim()},`,
          `${selectedShipper.Country.trim()} - ${selectedShipper.Pincode.trim()}.`
        ]
          .filter((line) => line !== "")
          .join("\n");
  
        setAddressDetails(formattedAddress);
      }
    }
  }, [editedSiData.Shipper, customerData]);

  const handleSingleDescription = (value) => {
    console.log(descriptionType);
    console.log(value);
    if (descriptionType === "Single" && value) {
      const updatedOperationsWithDescription = editedSiData.Operations.map(
        (operation) => ({
          ...operation,
          descriptionOfGoods: value,
        })
      );
      setEditedSiData((prevState) => ({
        ...prevState,
        type: "Single",
        Operations: updatedOperationsWithDescription,
      }));
    }
  };
  const handleDescriptionChange = (index, value) => {
    const updatedOperations = [...editedSiData.Operations];
    updatedOperations[index].descriptionOfGoods = value;
    setEditedSiData((prevState) => ({
      ...prevState,
      type: "Containerwise",
      Operations: updatedOperations,
    }));
  };

  useEffect(() => {
    setEditedSiData(selectedSiData);
  }, [selectedSiData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setincotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedSiData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const handleShipperChange = (e) => {
  //   setEditedSiData((prevData) => ({
  //     ...prevData,
  //     Shipper: e.target.value,
  //   }));
  // };

  const handleShipperChange = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      Shipper: e.target.value,
    }));
  };
  
  const handleAddressChange = (e) => {
    const { value } = e.target;
    setAddressDetails(value); // Update addressDetails state
    setFormData((prevFormData) => ({
      ...prevFormData,
      addressDetails: value, // Also update formData with new addressDetails
    }));
  };

  const handleConsigneeChange = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      Consignee: e.target.value,
    }));
  };
  const handleNotifyParty1Change = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      Notify_Party_1: e.target.value,
    }));
  };
  const handleNotifyParty2Change = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      Notify_Party_2: e.target.value,
    }));
  };
  const handlePolChange = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };
  const handleIncotermChange = (e) => {
    setEditedSiData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };
  // End
  //const Pagerefresh = () => window.location.reload(true);
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log(formData, "form");
  // };

  const handleBL_TypeChange = (e) => {
    setSelectedBL_Type(e.target.value);
    setEditedSiData((prevState) => ({
      ...prevState,
      BL_Type: e.target.value,
    }));
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(formData, "form");
      
  
      const { data } = await OperationDocumentAPIInstance.post(
        `add_sicreation?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
  
      console.log("====================================");
      console.log(data);
      console.log("====================================");
  
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedSiData.Operations];
    updatedOperations[index][key] = value;
    setEditedSiData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <div>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">SI Creation</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          {/* <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button> */}
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">SI Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-sicreations">
                <div className="input-box" hidden>
                  <span className="sicreations" hidden>
                    Job Info No
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="JobInfoNo"
                    defaultValue={
                      formData["JobInfoNo"] || selectedSiData["JobInfoNo"]
                    }
                    value={editedSiData["JobInfoNo"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your JobInfoNo"
                    required
                    disabled
                    hidden
                  />
                </div>
                <div className="input-box">
                  <span className="sicreations">
                    Shipper
                  </span>
                  <select
      width="190px"
      className="text-black bg-white dark:bg-gray-950 dark:text-white"
      value={editedSiData.Shipper || selectedSiData.Shipper || ""}
      onChange={handleShipperChange}
    >
      <option>Select Shipper</option>
      {customerData.map((item, idx) => (
        <option key={idx} value={item.Company}>
          {item.Company}
        </option>
      ))}
    </select>

    {/* Combined Address Textarea */}
    {/* <TextArea
      name="addressDetails"
      value={addressDetails}
      onChange={handleAddressChange}
      placeholder="Enter Address Details"
      rows={6} // Adjust rows as needed
    /> */}
                </div>
                <div className="input-box">
                  <span className="sicreations">
                    Place of Receipt
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfReceipt"
                    defaultValue={
                      formData["PlaceOfReceipt"] ||
                      selectedSiData["PlaceOfReceipt"]
                    }
                    value={editedSiData["PlaceOfReceipt"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Place Of Receipt"
                  />
                </div>

                <div className="input-box">
                  <span className="sicreations">
                    Consignee
                  </span>
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Consignee"] || selectedSiData["Consignee"]
                    }
                    value={editedSiData["Consignee"] || ""}
                    onChange={handleConsigneeChange}
                  >
                    <option>Select Consignee</option>
                    <option
                      value={
                        formData["Consignee"] || selectedSiData["Consignee"]
                      }
                    >
                      {formData["Consignee"] || selectedSiData["Consignee"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <span className="sicreations">
                    POL
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedSiData["POL"]}
                    value={editedSiData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option value={formData["POL"] || selectedSiData["POL"]}>
                      {formData["POL"] || selectedSiData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <span className="sicreations">
                    Notify Party
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party_1"
                    defaultValue={
                      formData["Notify_Party_1"] ||
                      selectedSiData["Notify_Party_1"]
                    }
                    value={editedSiData["Notify_Party_1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify Party"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Notify_Party_1"
                    defaultValue={
                      formData["Notify_Party_1"] ||
                      selectedSiData["Notify_Party_1"]
                    }
                    value={editedSiData["Notify_Party_1"] || ""}
                    onChange={handleNotifyParty1Change}
                  >
                    <option>Select Notify Party 1</option>
                    <option
                      value={
                        formData["Notify_Party_1"] || selectedSiData["Notify_Party_1"]
                      }
                    >
                      {formData["Notify_Party_1"] || selectedSiData["Notify_Party_1"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="input-box">
                  <span className="sicreations">POD</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedSiData["POD"]}
                    value={editedSiData["POD"] || ""}
                    onChange={handlePodChange}
                    placeholder="Enter your POD"
                  >
                    <option value={formData["POD"] || selectedSiData["POD"]}>
                      {formData["POD"] || selectedSiData["POD"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <span className="sicreations">Notify Party 2</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party_2"
                    defaultValue={
                      formData["Notify_Party_2"] ||
                      selectedSiData["Notify_Party_2"]
                    }
                    value={editedSiData["Notify_Party_2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify Party 2"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Notify_Party_2"
                    defaultValue={
                      formData["Notify_Party_2"] ||
                      selectedSiData["Notify_Party_2"]
                    }
                    value={editedSiData["Notify_Party_2"] || ""}
                    onChange={handleNotifyParty2Change}
                  >
                    <option>Select Notify Party 2</option>
                    <option
                      value={
                        formData["Notify_Party_2"] || selectedSiData["Notify_Party_2"]
                      }
                    >
                      {formData["Notify_Party_2"] || selectedSiData["Notify_Party_2"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <span className="sicreations">
                    Place Of Delivery
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfDelivery"
                    defaultValue={
                      formData["PlaceOfDelivery"] ||
                      selectedSiData["PlaceOfDelivery"]
                    }
                    value={editedSiData["PlaceOfDelivery"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfDelivery"
                  />
                </div>
              
                
                <div className="input-box">
                  <span className="sicreations">Liner Booking Number</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Liner_Bookingno"
                    defaultValue={
                      formData["Liner_Bookingno"] ||
                      selectedSiData["Liner_Bookingno"]
                    }
                    value={editedSiData["Liner_Bookingno"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Liner_Bookingno"
                  />
                </div>
                <div className="input-box">
                  <span className="sicreations">Vessel and Voyage</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    defaultValue={
                      formData["VesselNameVoyage"] ||
                      selectedSiData["VesselNameVoyage"]
                    }
                    value={editedSiData["VesselNameVoyage"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your VesselNameVoyage"
                  />
                </div>
                <div className="input-box">
                  <span className="sicreations">Incoterm</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Incoterm"
                    defaultValue={
                      formData["Incoterm"] || selectedSiData["Incoterm"]
                    }
                    value={editedSiData["Incoterm"] || ""}
                    onChange={handleIncotermChange}
                  >
                    placeholder="Enter your Incoterm"
                    <option
                      value={formData["Incoterm"] || selectedSiData["Incoterm"]}
                    >
                      {formData["Incoterm"] || selectedSiData["IncotermD"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {IncotermData.map((item, idx) => (
                      <option key={idx} value={item.Incoterm}>
                        {item.Incoterm}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="input-box">
                  <span className="sicreations">B/L Type</span>
                  <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="BL_Type"
                    value={selectedBL_Type}
                    onChange={handleBL_TypeChange}
                  >
                    <option value="">Select BL_Type</option>
                    <option value="Original">Original</option>
                    <option value="Surrender">Surrender</option>
                    <option value="Seaway">Seaway</option>
                  </select>
                </div>
                

                <div className="input-box">
                  <span className="sicreations">HSN Code</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HSNCode"
                    defaultValue={
                      formData["HSNCode"] || selectedSiData["HSNCode"]
                    }
                    value={editedSiData["HSNCode"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the HSNCode"
                  />
                </div>

                {selectedBL_Type === "Original" && (
                  <div className="input-box">
                    <span className="sicreations">
                      In case of Original(No of Originals)
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="number"
                      name="Originals"
                      value={editedSiData["Originals"] || ""}
                      onChange={handleChange}
                      placeholder="Enter the Originals"
                    />
                  </div>
                )}
                
                <div className="input-box">
                  <span className="sicreations">Marks and Number</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Marks_Number"
                    defaultValue={
                      formData["Marks_Number"] || selectedSiData["Marks_Number"]
                    }
                    value={editedSiData["Marks_Number"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Marks_Number"
                  />
                </div>
                {/* Payment terms */}
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <tr>
                      <th
                        className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white"
                        rowSpan="3"
                      >
                        Payment terms
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                        Payment_terms_origin
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                        Payment_terms_freight
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                        Payment_terms_destiantion
                      </th>
                    </tr>

                    <tr>
                      <td>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Payment_terms_origin"
                          defaultValue={
                            formData["Payment_terms_origin"] ||
                            selectedSiData["Payment_terms_origin"]
                          }
                          value={editedSiData["Payment_terms_origin"] || ""}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="Prepaid">Prepaid</option>
                          <option value="Collect">Collect</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Payment_terms_freight"
                          defaultValue={
                            formData["Payment_terms_freight"] ||
                            selectedSiData["Payment_terms_freight"]
                          }
                          value={editedSiData["Payment_terms_freight"] || ""}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="Prepaid">Prepaid</option>
                          <option value="Collect">Collect</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Payment_terms_destiantion"
                          defaultValue={
                            formData["Payment_terms_destiantion"] ||
                            selectedSiData["Payment_terms_destiantion"]
                          }
                          value={
                            editedSiData["Payment_terms_destiantion"] || ""
                          }
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="Prepaid">Prepaid</option>
                          <option value="Collect">Collect</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                &nbsp;
                {/*Container table */}
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Type
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Seal Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Number of Packages
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Type Of Package
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Net Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Gross Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Volume
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedSiData?.Operations?.map((operation, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="ContainerType"
                              value={
                                editedSiData["ContainerType"] ||
                                operation.ContainerType
                              }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerType",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.ContainerType}>
                                {operation.ContainerType}
                              </option>

                              {/* Render options from equipmentData */}
                              {equipmentData.map((item, idx) => (
                                <option key={idx} value={item.ContainerType}>
                                  {item.ContainerType}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="ContainerNumber"
                              defaultValue={
                                formData[operation.ContainerNumber] ||
                                selectedSiData[operation.ContainerNumber]
                              }
                              //value={editedSiData[operation.ContainerNumber] || ""}
                              value={operation.ContainerNumber}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="SealNumber"
                              value={operation.SealNumber}
                              defaultValue={
                                formData[operation.SealNumber] ||
                                selectedSiData[operation.SealNumber]
                              }
                              //value={editedSiData[operation.SealNumber] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "SealNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="No_Of_Pkge"
                              value={operation.No_Of_Pkge}
                              defaultValue={
                                formData[operation.No_Of_Pkge] ||
                                selectedSiData[operation.No_Of_Pkge]
                              }
                              //value={editedSiData[operation.No_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "No_Of_Pkge",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            {/* <input
                              type="text"
                              name="Type_Of_Pkge"
                              value={operation.Type_Of_Pkge}
                              defaultValue={
                                formData[operation.Type_Of_Pkge] ||
                                selectedSiData[operation.Type_Of_Pkge]
                              }
                              //value={editedSiData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            /> */}
                            <select
                              width="190px"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              value={
                                editedSiData["Type_Of_Pkge"] ||
                                operation.Type_Of_Pkge
                              }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.Type_Of_Pkge}>
                                {operation.Type_Of_Pkge}
                              </option>

                              {/* Render options from equipmentData */}
                              {packageTypeData.map((item, idx) => (
                                <option key={idx} value={item.packingType}>
                                  {item.packingType}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="NtWeight"
                              value={operation.NtWeight}
                              defaultValue={
                                formData[operation.NtWeight] ||
                                selectedSiData[operation.NtWeight]
                              }
                              //value={editedSiData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "NtWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="GrWeight"
                              value={operation.GrWeight}
                              defaultValue={
                                formData[operation.GrWeight] ||
                                selectedSiData[operation.GrWeight]
                              }
                              //value={editedSiData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "GrWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="Volume"
                              value={operation.Volume}
                              defaultValue={
                                formData[operation.Volume] ||
                                selectedSiData[operation.Volume]
                              }
                              //value={editedSiData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Volume",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {/*description part */}
                <br />
                <br />
                <div className="description-box">
                  <div className="inline-flex">
                    <button
                      type="button"
                      onClick={() => setDescriptionType("Single")}
                      className={`${
                        descriptionType === "Single"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Single Description
                    </button>

                    <button
                      type="button"
                      onClick={() => setDescriptionType("Containerwise")}
                      className={`${
                        descriptionType === "Containerwise"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Containerwise Description
                    </button>
                    <br />
                  </div>
                </div>
                <div className="description-box">
                  {descriptionType === "Single" && (
                    <div className="input-box">
                      <span className="sicreations">Description of goods</span>
                      <Textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={editedSiData["descriptionOfGoods"]}
                        onChange={(e) =>
                          handleSingleDescription(e.target.value)
                        }
                        placeholder="Enter the description of goods"
                        rows="3" // Increased number of rows for height
                        cols="155" // Increased number of columns for width
                      />
                    </div>
                  )}
                  {descriptionType === "Containerwise" && (
                    <div className="input-box">
                      <span className="sicreations">Description of goods</span>
                      <div className="table-container">
                        <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                          <thead className="flex-nowrap text-center bg-blue-950 text-white">
                            <tr>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Container Type
                              </th>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {editedSiData.Operations.map((operation, idx) => (
                              <tr key={idx}>
                                <td>
                                  {operation.ContainerType}-
                                  {operation.ContainerNumber}
                                </td>
                                <td>
                                  <Textarea
                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                    name={`Description_${idx}`}
                                    value={operation.descriptionOfGoods || ""}
                                    onChange={(e) =>
                                      handleDescriptionChange(
                                        idx,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter the description of goods"
                                    rows="3" // You can adjust the number of rows for the height
                                    cols="125" // You can adjust the number of columns for the width
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSiCreation;
