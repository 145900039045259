/* eslint-disable react-hooks/exhaustive-deps */
import {
  React,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  AuthAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance.js";
import "../../../assets/css/container.css";
import {
  DraggableIncompleteTask,
  DraggableUpdateTable,
  Header,
  OperationSidebar,
  UnAuthorizedModal,
} from "../../../components";
// Internal modules
import "./Update.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Text, Tooltip } from "@chakra-ui/react";
import { ModalOverlay, Button } from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";

import { MdOutlineDownloadForOffline } from "react-icons/md";

import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiPrinter } from "react-icons/fi";

const Update = () => {
  // State Variables
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();

  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();

  const cancelRef = useRef();
  const searchRef = useRef(null);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [updateoperationData, setUpdateoperationData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [recipients, setRecipients] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [cc, setCC] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    JobNumber: true,
    BookingNumber: true,
    POL: true,
    POD: true,
    Client: true,
    Client_email_id: true,
    PIC: true,
    Equipments: true,
    Updates: true,
    Product: false,
    HandleBy: false,
    Shipper: false,
    Equipment: false,
    Job_Status: false,
    DOBC: false,
    Consignee: false,
    Agent: false,
    QuotationNumber: false,
    Scope: false,
    Region: false,
    Incoterm: false,
    Sales: false,
    Cargo: false,
    Cargo_Nature: false,
    HS_Code: false,
    Shipping_Line: false,
    VesselNameVoyage: false,
    CY_Open: false,
    SI_Cutoff: false,
    VGM_Cutoff: false,
    CY_Cutoff: false,
    Customs_Cutoff: false,
    OOG_Cutoff: false,
    ETD: false,
    ETA: false,
    days_at_origin: false,
    days_at_destiantion: false,
    Freight: false,
    MBL_Type: false,
    HBL_Type: false,
    Liner_Bookingno: false,
    MBLNo: false,
    HBLNo: false,
    Cargo_Pickup_Date: false,
    Empty_Pickup_Date: false,
    Stuffing_Date: false,
    Laden_Gate_In_Date: false,
    Vessel_Arrival_Date: false,
    Operation_Transfer_Deadline: false,
    Detention: false,
    Demurrage: false,
    Detention_By_Liner: false,
    Demurage_By_Liner: false,
    DetentionNo: false,
    DemurrageNo: false,
    Port_Storage: false,
    PortStorage: false,
    Port_Storage_Days: false,
    Current_Status: true,
    Notes: false,
    add_costid: false,
    Vendor: false,
    Detail: false,
    Amount: false,
    Containers: false,
    JobOwner: true,
    CreatedBy: true,
  });
  const [tableColumnsUpdateOperation, settableColumnsUpdateOperation] =
    useState([]);

  useEffect(() => {
    getData();
  }, []); // isdelete state changes

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsUpdateOperation((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsUpdateOperation.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem(
      "tableColumnsUpdateOperation",
      JSON.stringify(newData)
    );
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await OperationDocumentAPIInstance.get(
        `get_update_ops?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setUpdateoperationData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setUpdateoperationData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("updateoperationfilter")?.length === 0) {
      return setUpdateoperationData([]);
    }

    if (window.localStorage.getItem("updateoperationfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("updateoperationfilterRows"))
      ? JSON.parse(localStorage.getItem("updateoperationfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("updateoperationfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem(
      "updateoperationfilterRows",
      JSON.stringify(filterRows)
    );
    localStorage.setItem(
      "updateoperationfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "updateoperationfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await OperationDocumentAPIInstance.post(
        `update_massfilter?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("updateoperationfilter", JSON.stringify([]));
        return setUpdateoperationData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setUpdateoperationData(filteredData);

      localStorage.setItem(
        "updateoperationfilter",
        JSON.stringify(filteredData)
      );
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("updateoperationfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setUpdateoperationData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setUpdateoperationData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("updateoperationfilterConditions");
    window.localStorage.removeItem("updateoperationfilter");
    window.localStorage.removeItem("updateoperationfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 =
      updateoperationData[0] && Object?.keys(updateoperationData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("updateoperationfilter")?.length === 0) {
      return setUpdateoperationData([]);
    }

    if (window.localStorage.getItem("updateoperationfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  //Mass email
  const handleOpenEditor = (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default link behavior
    }
    setIsEditorOpen(true);
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected(false);
  };


  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "landscape";
  
    const doc = new jsPDF(orientation, unit, size);
  
    const tableColumnsUpdateOperation = [
      "JobNumber",
      "BookingNumber",
      "Product",
      "PlaceOfReceipt",
      "PlaceOfDelivery",
      "Shipper",
      "POL",
      "POD",
      "PIC",
      "Equipments",
      "Client_email_id",
      "Job_Status",
      "DOBC",
      "Consignee",
      "Agent",
      "QuotationNumber",
      "Scope",
      "Shipping_Line",
      "Vendors",
      "Current_Status",
      "Notes",
    ];
  
    const formatData = (data) =>
      data.map((item) => {
        return tableColumnsUpdateOperation.map((column) => {
          if (column === "DOBC") {
            return item[column] ? item[column].split("T")[0] : ""; // Remove time from DOBC
          }
          if (column === "Vendors") {
            return item[column]
              ? item[column]
                  .replace(/, add_costid: [^,]+/g, "")
                  .replace(/;\s*$/, "") // Remove add_costid from Vendors
              : "";
          }
          return item[column];
        });
      });
  
    const tableRows = formatData(data);
  
    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 5;
  
    doc.setFontSize(titleFontSize);
  
    const title = "Job Updates";
    doc.text(title, marginLeft, marginTop);
  
    const tableOptions = {
      startY: marginTop + titleFontSize + 10,
      headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 5 },
      bodyStyles: { fontSize: 5 },
      columnStyles: { cellWidth: 2000 },
      head: [tableColumnsUpdateOperation],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };
  
    doc.autoTable(tableOptions);
  
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `Job Updates_${currentDate}_${currentTime}.pdf`;
  
    // Download the PDF with the specified filename
    doc.save(finalFileName);
  };

  const handlePrintClick = () => {
    if (exportData.length > 0) {
      exportToPDF(exportData, "JobUpdates");
    } else {
      console.error("No data available for export.");
    }
  };



  const formatEmailBody = (selectedItems) => {
    let emailBody = "<html><body>";
    selectedItems.forEach((item) => {
      emailBody += `<p>Dear ${item.PIC},</p>`;
      emailBody += `<p style="margin-left:100px">Good day !</p>`;
      emailBody += `<p>Status Update : POL : ${item.POL} to POD : ${item.POD}</p>`;
      emailBody += `<p>Equipment : ${item.Equipments}</p>`;
      emailBody += `<p>Current Status: ${item.Current_Status}</p>`;
    });
    emailBody += `<p>Thank You!</p>`;
    emailBody += `<p>Regards,<br>Bruhat Teams</p>`;
    emailBody += "</body></html>";
    return emailBody;
  };

  const formatEmailSubject = (selectedItems) => {
    return selectedItems
      .map((item) => `REG - ${item.POL} to ${item.POD} - ${item.Equipments}`)
      .join(", ");
  };

  const handleSubmitMassEmail = async () => {
    try {
      const selectedIds = checkBoxSelected;
      const selectedItems = updateoperationData.filter((item) =>
        selectedIds.includes(item.id)
      );

      // Format the email body and subject
      const body = formatEmailBody(selectedItems);
      const formattedSubject = formatEmailSubject(selectedItems);
      setSubject(formattedSubject); // Set the subject in state

      const recipients = selectedItems.map((item) => item.Client_email_id);
      const jobNumbers = selectedItems.map((item) => item.JobNumber);
      const bookingNumbers = selectedItems.map((item) => item.BookingNumber);
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const emailData = {
        recipients,
        cc: cc.split(",").map((email) => email.trim()),
        subject: formattedSubject, // Use the formatted subject here
        body,
        sentby: userId,
        JobNumber: jobNumbers,
        BookingNumber: bookingNumbers,
      };
      console.log(emailData);

      const response = await AuthAPIInstance.post(
        `/emails/operation_send_email?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      const { data } = response;
      if (data.message) {
        toast({
          title: "Email sent successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setBody("");
      setIsEditorOpen(false);
      setSubject("");
      setCC("");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected(false);
    setCheckBoxSelected([]);
  };

  useEffect(() => {
    if (isEditorOpen) {
      const selectedIds = checkBoxSelected;
      const selectedItems = updateoperationData.filter((item) =>
        selectedIds.includes(item.id)
      );
      const formattedBody = formatEmailBody(selectedItems);
      setBody(formattedBody);
    }
  }, [isEditorOpen]);

  // Windows application Mail
  // const handleSubmitOutlookEmail = async () => {
  //   // Filter updateoperationData based on selected checkboxes and get recipient email addresses
  //   const recipients = updateoperationData
  //     .filter((item) => checkBoxSelected.includes(item.id))
  //     .map((item) => item.Email);

  //   if (recipients.length === 0) {
  //     return;
  //   }

  //   const subject = "Your subject here";
  //   const body = "Your email body here";

  //   // Join recipient email addresses into a comma-separated string
  //   const emailAddresses = recipients.join(",");

  //   // Create a mailto link with subject, body, and BCC parameter
  //   const mailtoLink =
  //     "mailto:?bcc=" +
  //     encodeURIComponent(emailAddresses) +
  //     "&subject=" +
  //     encodeURIComponent(subject) +
  //     "&body=" +
  //     encodeURIComponent(body);

  //   // Open the user's mail client with the mailto link
  //   window.location.href = mailtoLink;

  //   setSelectAllPages(false);
  //   setCheckBoxAll(false);
  //   setCheckBoxSelected([]);
  // };

  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    handleOpenEditor
  );

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await OperationDocumentAPIInstance.get(
      `search_update?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setUpdateoperationData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible: true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setUpdateoperationData(filteredData);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(updateoperationData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsUpdateOperation")) {
      settableColumnsUpdateOperation(
        JSON.parse(localStorage.getItem("tableColumnsUpdateOperation"))
      );
    } else {
      settableColumnsUpdateOperation(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsUpdateOperation];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsUpdateOperation",
      JSON.stringify(updatedColumns)
    );
    settableColumnsUpdateOperation(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const Managecolumn = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    // backdropFilter: "blur(10px)",
    overflowY: "auto",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            {/* <OperationSidebar
          size={20}
        /> */}
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
            {/* <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        /> */}
            &nbsp;
            <div className="relative inline-block">
              {/* <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={toggleActionDropdown}
              >
                Actions
              </button> */}
              &nbsp;&nbsp;
              {/* {isActionOpen && (
                <div className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
                  <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                    <FiPrinter size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Print
                    </span>
                  </a>

                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      handleActionSelection();
                      checkValidation.handleEditClickMassmail();
                    }}
                  >
                    <HiOutlineMail size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      
                    >
                      Mass Email
                    </span>
                  </a>

                  <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Download
                </span>
              </a>
                </div>
              )} */}
            </div>
            <>
              {isOpenFilter && (
                <>
                 <div style={backdropStyle}></div>
                <div className={`FilterModal`}>
                  {/* {${scrollBehavior}} */}
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200,
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Criteria
                    </header>
                    <div
                      className="closemodule"
                      onClick={() => {
                        onCloseFilter(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    <FormControl>
                      {filterRows.map((index) => (
                        <Flex key={index}>
                          <Select
                            id={`column-select-${index}`}
                            placeholder="Select"
                            onChange={(e) => handleFilterTypeSelect(e, index)}
                            value={filterConditions[index]?.column || "select"}
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            style={{ position: "relative" }}
                          >
                            <option value="JobNumber">Job Number</option>
                            <option value="Region">Region</option>
                            <option value="HandleBy">Handled By</option>
                          </Select>
                          &nbsp;&nbsp;
                          <Select
                            id={`filter-type-${index}`}
                            onChange={(e) =>
                              handleFilterConditionSelect(e, index)
                            }
                            value={
                              filterConditions[index]?.condition || "select"
                            }
                            placeholder="Select"
                          >
                            {filterConditions[index]?.column === "CreatedAt" ||
                            filterConditions[index]?.column === "ChangedAt" ? (
                              <>
                                <option value="equalsDate">=</option>
                                <option value="not-equalsDate">!=</option>
                                <option value="greaterDate">&gt;</option>
                                <option value="lesserDate">&lt;</option>
                                <option value="greater-equalDate">&gt;=</option>
                                <option value="lesser-equalDate">&lt;=</option>
                              </>
                            ) : filterConditions[index]?.column ===
                              "JobOwnerName" ? (
                              <>
                                <option value="isleadname">is</option>
                                <option value="containsleadname">
                                  contains
                                </option>
                                <option value="startsWithleadname">
                                  starts with
                                </option>
                                <option value="endsWithleadname">
                                  ends with
                                </option>
                              </>
                            ) : filterConditions[index]?.column === "Phone" ||
                              filterConditions[index]?.column ===
                                "FollowUpInterval" ||
                              filterConditions[index]?.column ===
                                "NotificationDays" ||
                              filterConditions[index]?.column ===
                                "FollowUpDate" ||
                              filterConditions[index]?.column ===
                                "DeletionFlag" ||
                              filterConditions[index]?.column ===
                                "ConversionFlag" ? (
                              <>
                                <option value="equals">=</option>
                                <option value="not-equals">!=</option>
                                <option value="greater">&gt;</option>
                                <option value="lesser">&lt;</option>
                                <option value="greater-equal">&gt;=</option>
                                <option value="lesser-equal">&lt;=</option>
                              </>
                            ) : (
                              <>
                                <option value="is">is</option>
                                <option value="contains">contains</option>
                                <option value="startsWith">starts with</option>
                                <option value="endsWith">ends with</option>
                              </>
                            )}
                          </Select>
                          &nbsp;&nbsp;
                          {/* <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      /> */}
                          {/* Conditionally render the input field based on the selected column */}
                          {["FollowUpDate", "ChangedAt", "CreatedAt"].includes(
                            filterConditions[index]?.column
                          ) ? (
                            <Input
                              type="date"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          )}
                          &nbsp;&nbsp;{" "}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={() => addFilterRow()}
                          />
                          {filterRows?.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </FormControl>
                    <div
                      style={{
                        float: "right",
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSubmitFilter();
                          onCloseFilter();
                        }}
                        mr={3}
                      >
                        Apply
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleClearFilters();
                          onCloseFilter();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      <RadioGroup
                        style={{ flex: 1 }}
                        value={handleCheckboxFilter}
                      >
                        <Stack direction="row">
                          <Radio value="AND" onChange={handleCheckboxChange}>
                            All
                          </Radio>
                          <Radio value="OR" onChange={handleCheckboxChange}>
                            Any
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>
            <>
              {isOpenManagecolumn && (
                <div style={modalStyleUpdate}>
                  <Rnd
                    style={Managecolumn}
                    default={{
                      x: 0,
                      y: 0,
                      width: 320,
                      height: 400,
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Manage Column
                    </header>
                    <div
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    {tableColumnsUpdateOperation.map(
                      (label, index) =>
                        label.key !== "id" && (
                          <label
                            key={index}
                            className="flex items-center"
                            draggable
                            onDragStart={(event) =>
                              handleDragStart(event, index)
                            }
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, index)}
                          >
                            <input
                              type="checkbox"
                              // checked={label.label}
                              className="cursor-pointer"
                              disabled={
                                label.key === "IncompleteTask"
                                  ? true
                                  : label.key === "JobNumber"
                                  ? true
                                  : label.key === "Pol"
                                  ? true
                                  : label.key === "Pod"
                                  ? true
                                  : label.key === "Equipment"
                                  ? true
                                  : label.key === "Sales"
                                  ? true
                                  : label.key === "ShipmentHandledBy"
                                  ? true
                                  : label.key === "Client"
                                  ? true
                                  : label.key === "Update"
                                  ? true
                                  : null
                              }
                              checked={label.isVisible}
                              onChange={(event) => {
                                handleCheckboxColumn(event, label);
                              }}
                            />
                            <span
                              className="ml-1"
                              style={{ cursor: "all-scroll" }}
                            >
                              {label.label}
                            </span>
                            {label.isDisabled && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </label>
                        )
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        float: "right",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="green"
                      >
                        OK
                      </Button>
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="red"
                        ml={3}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Rnd>
                </div>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>

              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}

              {/* <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip> */}
              <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <HiOutlineMail
                    onClick={() => {
                      checkValidation.handleEditClickMassmail();
                    }}
                    size={20}
                  />
                </div>
              </Tooltip>

              <Tooltip hasArrow label="Print" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                <FiPrinter
      size={20}
      onClick={() => {
        handleActionSelection();
        handlePrintClick();
      }}
    />
                </div>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Select all pages"
                bg="gray.300"
                color="black"
              >
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <MdSelectAll
                    onClick={() => {
                      handleCheckbox(
                        updateoperationData.map((item) => item.id)
                      );
                      setCheckBoxAll((prev) => {
                        if (prev === true) {
                          setCheckBoxSelected([]);
                        }
                        return !prev;
                      });
                    }}
                    size={24}
                  />
                </div>
              </Tooltip>
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, updateoperationData?.length)}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {updateoperationData?.length}
                  </span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <Selectallpages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggableUpdateTable
                isLoading={isLoading}
                columns={columns2}
                updateoperationData={updateoperationData}
                getData={getData}
                data={updateoperationData.slice(
                  indexOfFirstItem,
                  indexOfLastItem
                )}
                setUpdateoperationData={setUpdateoperationData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsUpdateOperation={tableColumnsUpdateOperation}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
      <>
        {isOpenReportMassEmail && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassMailConfirmModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Send Mail
              </header>
              <div
                onClick={() => {
                  onCloseReportMassEmail(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Send the mail?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassEmail();
                  onCloseReportMassEmail();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassEmail();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      {/* <>
        {isOpenReportOutlookEmail && (
          <div className={`OultlookMailModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Send Mail
              </header>
              <div
                onClick={() => {
                  onCloseReportOutlookEmail(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to send the mail?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitOutlookEmail();
                  onCloseReportOutlookEmail();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportOutlookEmail();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
        )}
      </> */}
      <>
        {isEditorOpen && (
          <>
          <div style={backdropStyle}></div>
          <div className={`EditMailModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Compose Email
              </header>
              <div
                onClick={() => {
                  handleCloseEditor(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {/* <FormControl id="recipients">
                <FormLabel>To</FormLabel>
                <Input
                  value={recipients}
                  onChange={(e) => setRecipients(e.target.value)}
                  placeholder="Enter recipients"
                />
              </FormControl> */}

              {/* <FormControl id="subject">
                <FormLabel>Subject</FormLabel>
                <Input
          value={subject}
        
        />
              </FormControl> */}
              <FormControl id="cc">
                <FormLabel>CC</FormLabel>
                <Input
                  value={cc}
                  onChange={(e) => setCC(e.target.value)}
                  placeholder="Ex: matrixmindz.contact@gmail.com, matrixmindz@gmail.com"
                />
              </FormControl>

              <Editor
                value={body}
                onEditorChange={setBody}
                apiKey="5kj9ktj3wwm6fnpgklcagq15dc678i2t0i8u3zspzbt6rbmd"
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
                }}
              />
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassEmail();
                  handleCloseEditor();
                }}
              >
                Submit
              </Button>

              <Button colorScheme="red" ml={3} onClick={handleCloseEditor}>
                Cancel
              </Button>
            </Rnd>
          </div>
          </>

        )}
      </>
    </div>
  );
};

export default Update;
