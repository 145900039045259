import React from "react";
import { useState, useEffect } from "react";
import Axios from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";

// Internal module import
//import "./DraggaableSourcesTable.css";

// Chakara ui modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const DraggableClientTable = ({
  isLoading,
  columns,
  data,
  PartnerData,
  getData,
  setpartnerData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsPartner,
  pageNumbers,
}) => {
  const toast = useToast();

  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedPartner, setselectedPartner] = useState({});
  
  const [editedPartner, seteditedPartner] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({});

  // UseEffects
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...PartnerData].sort((a, b) => {
      if (a[key] < b[key]) return newSortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return newSortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setpartnerData === "function") {
      setpartnerData(sortedData);
    }
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    //overflowY: "scroll",
    //overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  return (
    <div>
      {!isLoading ? (
        tableColumnsPartner?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                  {false? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });
 
                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                 
                  </div>
                </th>
                {tableColumnsPartner.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.key === "ClientNumber" ? (
                          <>
                            <span>Client Number</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                          ) : column.key === "ClientName" ? (
                            <>
                              <span>Client Name</span>
                              <BiSort
                                style={{
                                  marginLeft: "160px",
                                  marginTop: "-22px",
                                  cursor: "pointer",
                                }}
                                onClick={() => sortTable(column.key)}
                              />
                            </>
                        ) : column.key === "Clienttype" ? (
                          <>
                            <span>Client Type</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                           ) : column.key === "SPECIALIZATION" ? (
                            <>
                              <span>SPECIALIZATION</span>
                              <BiSort
                                style={{
                                  marginLeft: "160px",
                                  marginTop: "-22px",
                                  cursor: "pointer",
                                }}
                                onClick={() => sortTable(column.key)}
                              />
                            </>
                           ) : column.key === "Addressline1" ? (
                            <>
                              <span>Address Line 1</span>
                              <BiSort
                                style={{
                                  marginLeft: "160px",
                                  marginTop: "-22px",
                                  cursor: "pointer",
                                }}
                                onClick={() => sortTable(column.key)}
                              />
                            </>
                             ) : column.key === "Addressline2" ? (
                                <>
                                  <span>Address Line 2</span>
                                  <BiSort
                                    style={{
                                      marginLeft: "160px",
                                      marginTop: "-22px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => sortTable(column.key)}
                                  />
                                </>
                                 ) : column.key === "CITY" ? (
                                    <>
                                      <span>CITY</span>
                                      <BiSort
                                        style={{
                                          marginLeft: "160px",
                                          marginTop: "-22px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => sortTable(column.key)}
                                      />
                                    </>
                                    ) : column.key === "COUNTRY" ? (
                                        <>
                                          <span>COUNTRY</span>
                                          <BiSort
                                            style={{
                                              marginLeft: "160px",
                                              marginTop: "-22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => sortTable(column.key)}
                                          />
                                        </>
                                        ) : column.key === "PostalCode" ? (
                                            <>
                                              <span>Postal Code</span>
                                              <BiSort
                                                style={{
                                                  marginLeft: "160px",
                                                  marginTop: "-22px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => sortTable(column.key)}
                                              />
                                            </>
                                            ) : column.key === "State" ? (
                                                <>
                                                  <span>State</span>
                                                  <BiSort
                                                    style={{
                                                      marginLeft: "160px",
                                                      marginTop: "-22px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => sortTable(column.key)}
                                                  />
                                                </>
                                                ) : column.key === "TaxNumber" ? (
                                                    <>
                                                      <span>Tax Number</span>
                                                      <BiSort
                                                        style={{
                                                          marginLeft: "160px",
                                                          marginTop: "-22px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => sortTable(column.key)}
                                                      />
                                                    </>
                                                    ) : column.key === "Industry" ? (
                                                        <>
                                                          <span>Industry</span>
                                                          <BiSort
                                                            style={{
                                                              marginLeft: "160px",
                                                              marginTop: "-22px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => sortTable(column.key)}
                                                          />
                                                        </>
                                                        ) : column.key === "Remarks" ? (
                                                            <>
                                                              <span>Remarks</span>
                                                              <BiSort
                                                                style={{
                                                                  marginLeft: "160px",
                                                                  marginTop: "-22px",
                                                                  cursor: "pointer",
                                                                }}
                                                                onClick={() => sortTable(column.key)}
                                                              />
                                                            </>
                                                            ) : column.key === "Grade" ? (
                                                                <>
                                                                  <span>Grade</span>
                                                                  <BiSort
                                                                    style={{
                                                                      marginLeft: "160px",
                                                                      marginTop: "-22px",
                                                                      cursor: "pointer",
                                                                    }}
                                                                    onClick={() => sortTable(column.key)}
                                                                  />
                                                                </>
                                                                ) : column.key === "Tag" ? (
                                                                    <>
                                                                      <span>Tag</span>
                                                                      <BiSort
                                                                        style={{
                                                                          marginLeft: "160px",
                                                                          marginTop: "-22px",
                                                                          cursor: "pointer",
                                                                        }}
                                                                        onClick={() => sortTable(column.key)}
                                                                      />
                                                                    </>
                                                                    ) : column.key === "GSTInfo" ? (
                                                                      <>
                                                                        <span>GSTInfo</span>
                                                                        <BiSort
                                                                          style={{
                                                                            marginLeft: "160px",
                                                                            marginTop: "-22px",
                                                                            cursor: "pointer",
                                                                          }}
                                                                          onClick={() => sortTable(column.key)}
                                                                        />
                                                                      </>
                                                                    ) : column.key === "PAN" ? (
                                                                        <>
                                                                          <span>PAN</span>
                                                                          <BiSort
                                                                            style={{
                                                                              marginLeft: "160px",
                                                                              marginTop: "-22px",
                                                                              cursor: "pointer",
                                                                            }}
                                                                            onClick={() => sortTable(column.key)}
                                                                          />
                                                                        </>
                                                                        ) : column.key === "SST" ? (
                                                                            <>
                                                                              <span>SST</span>
                                                                              <BiSort
                                                                                style={{
                                                                                  marginLeft: "160px",
                                                                                  marginTop: "-22px",
                                                                                  cursor: "pointer",
                                                                                }}
                                                                                onClick={() => sortTable(column.key)}
                                                                              />
                                                                            </>
                                                                             ) : column.key === "TSD" ? (
                                                                                <>
                                                                                  <span>TSD</span>
                                                                                  <BiSort
                                                                                    style={{
                                                                                      marginLeft: "160px",
                                                                                      marginTop: "-22px",
                                                                                      cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => sortTable(column.key)}
                                                                                  />
                                                                                </>
                                                                            
                                                                        ) : column.key === "FAX" ? (
                                                                            <>
                                                                              <span>FAX</span>
                                                                              <BiSort
                                                                                style={{
                                                                                  marginLeft: "160px",
                                                                                  marginTop: "-22px",
                                                                                  cursor: "pointer",
                                                                                }}
                                                                                onClick={() => sortTable(column.key)}
                                                                              />
                                                                            </> 
                     ) : column.key === "PIC1" ? (
                        <>
                          <span>PIC1</span>
                          <BiSort
                            style={{
                              marginLeft: "160px",
                              marginTop: "-22px",
                              cursor: "pointer",
                            }}
                            onClick={() => sortTable(column.key)}
                          />
                        </>   
                         ) : column.key === "PIC1_email" ? (
                            <>
                              <span>PIC1_email</span>
                              <BiSort
                                style={{
                                  marginLeft: "160px",
                                  marginTop: "-22px",
                                  cursor: "pointer",
                                }}
                                onClick={() => sortTable(column.key)}
                              />
                            </> 
                            ) : column.key === "PIC1_contactno" ? (
                                <>
                                  <span>PIC1_ContactNo</span>
                                  <BiSort
                                    style={{
                                      marginLeft: "160px",
                                      marginTop: "-22px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => sortTable(column.key)}
                                  />
                                </>   
                                ) : column.key === "PIC1_Designation" ? (
                                    <>
                                      <span>PIC1_Designation</span>
                                      <BiSort
                                        style={{
                                          marginLeft: "160px",
                                          marginTop: "-22px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => sortTable(column.key)}
                                      />
                                    </>
                                     ) : column.key === "PIC2" ? (
                                        <>
                                          <span>PIC2</span>
                                          <BiSort
                                            style={{
                                              marginLeft: "160px",
                                              marginTop: "-22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => sortTable(column.key)}
                                          />
                                        </>   
                                         ) : column.key === "PIC2_email" ? (
                                            <>
                                              <span>PIC2_email</span>
                                              <BiSort
                                                style={{
                                                  marginLeft: "160px",
                                                  marginTop: "-22px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => sortTable(column.key)}
                                              />
                                            </> 
                                            ) : column.key === "PIC2_contactno" ? (
                                                <>
                                                  <span>PIC2_ContactNo</span>
                                                  <BiSort
                                                    style={{
                                                      marginLeft: "160px",
                                                      marginTop: "-22px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => sortTable(column.key)}
                                                  />
                                                </>   
                                                ) : column.key === "PIC2_Designation" ? (
                                                    <>
                                                      <span>PIC2_Designation</span>
                                                      <BiSort
                                                        style={{
                                                          marginLeft: "160px",
                                                          marginTop: "-22px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => sortTable(column.key)}
                                                      />
                                                    </>   
                                                     ) : column.key === "PIC3" ? (
                                                        <>
                                                          <span>PIC3</span>
                                                          <BiSort
                                                            style={{
                                                              marginLeft: "160px",
                                                              marginTop: "-22px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => sortTable(column.key)}
                                                          />
                                                        </>   
                                                         ) : column.key === "PIC3_email" ? (
                                                            <>
                                                              <span>PIC3_email</span>
                                                              <BiSort
                                                                style={{
                                                                  marginLeft: "160px",
                                                                  marginTop: "-22px",
                                                                  cursor: "pointer",
                                                                }}
                                                                onClick={() => sortTable(column.key)}
                                                              />
                                                            </> 
                                                            ) : column.key === "PIC3_contactno" ? (
                                                                <>
                                                                  <span>PIC3_ContactNo</span>
                                                                  <BiSort
                                                                    style={{
                                                                      marginLeft: "160px",
                                                                      marginTop: "-22px",
                                                                      cursor: "pointer",
                                                                    }}
                                                                    onClick={() => sortTable(column.key)}
                                                                  />
                                                                </>   
                                                                ) : column.key === "PIC3_Designation" ? (
                                                                    <>
                                                                      <span>PIC3_Designation</span>
                                                                      <BiSort
                                                                        style={{
                                                                          marginLeft: "160px",
                                                                          marginTop: "-22px",
                                                                          cursor: "pointer",
                                                                        }}
                                                                        onClick={() => sortTable(column.key)}
                                                                      />
                                                                    </>   
                                                                          
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row?.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                         {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row?.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    {tableColumnsPartner.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {row[column.key]}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsPartner.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};
export default DraggableClientTable;
