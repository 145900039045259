import React, { useEffect } from "react";
import { Button, Tooltip } from "@chakra-ui/react";
// A component for selecting all pages
const SelectAllPages = ({ selectAllPages, setSelectAllPages, checkBoxAll }) => {
  useEffect(() => {
     // When checkBoxAll becomes false, deselect all pages
    if (!checkBoxAll) {
      setSelectAllPages(false); // When checkBoxAll becomes false, deselect all pages
    }
  }, [checkBoxAll]);
 // Handles the checkbox click to select all pages
  function handleSelectAllPages(event) {
    setSelectAllPages(event.target.checked); // Toggle the selectAllPages state when the checkbox is clicked
  }

  return (
    <></>
    // <>
    //   {checkBoxAll && (
    //     <Tooltip hasArrow label="Select All Pages" bg="gray.300" color="black">
    //       <Button colorScheme="blue" size="xs">
    //         {/* The checkbox input for selecting all pages */}
    //         <input
    //           type="checkbox"
    //           checked={selectAllPages}
    //           onChange={handleSelectAllPages}
    //           className="cursor-pointer"
    //         />{" "}
    //         Select All Pages
    //       </Button>
    //     </Tooltip>
    //   )}
    // </>
  );
};

export default SelectAllPages;
