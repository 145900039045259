/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { FiPrinter } from "react-icons/fi";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  ProfomaInvoiceAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";
import "../../assets/css/container.css";
import {
  DraggablePiTable,
  Header,
  OperationSidebar,
  UnAuthorizedModal,
} from "../../components";
// Internal modules
//import "./ProformaInvoice.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
import { JobforPI } from "../index.js";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Text, Tooltip } from "@chakra-ui/react";
import { ModalOverlay, Button } from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";

import { MdOutlineDownloadForOffline } from "react-icons/md";

import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal, TbUserEdit } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { GoMoveToEnd } from "react-icons/go";

const ProformaInvoice = () => {
  // State Variables
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();

  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();

  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();

  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();
  const {
    isOpen: isOpenReportLeadConvert,
    onOpen: onOpenReportLeadConvert,
    onClose: onCloseReportLeadConvert,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const cancelRef = useRef();
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [proinvData, setProinvData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption,setSelectedExportOption  ] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [bookingWarning, setBookingWarning] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);

  // Unallcoted
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [jobforPiState, setJobforPiState] = useState([]);

  const [piNumber, setPiNumber] = useState("");
  const [piCountry, setPiCountry] = useState("");
  const [bilCountry, setBillCountry] = useState("");
  const [selectedExportType, setSelectedExportType] = useState(null);
  const [isProformaDownloading, setIsProformaDownloading] = useState(false);
  const [isTaxDownloading, setIsTaxDownloading] = useState(false);
  const [deletionFlag, setDeletionFlag] = useState("");
  const [conversionFlag, setConversionFlag] = useState("");
  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };

  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    PiNumber: true,
    TypeOfShipment: true,
    JobNumber: true,
    BookingNumber: true,
    BankDetails: true,
    MBL: true,
    HBL: true,
    Bill_To: true,
    BillAddress: true,
    BillAddressCity: true,
    BillAddressCountry: true,
    BillAddressPincode: true,
    BillAddressState: true,
    BillGst: true,
    POL: true,
    POD: true,
    ETA: true,
    ETD: true,
    Invoice_Date: true,
    Due_Date: true,
    ContainerType: true,
    VesselNameVoyage: true,
    TotalInvValue: true,
    CompanySelection: true,
    PiOwner: true,
    Containers: true,
    BeneficaryName: true,
    BankName: true,
    AccNumber: true,
    IFSCCode: true,
    CompanyName: true,
    OfficeAddress: true,
    Gst_vatNumber: true,
    PiOwnerName: true,
    PiOwnerPhone: true,
    PiOwnerEmail: true,
    ChangeAt: true,
    PiRegion: true,
    PiState: true,
    DeletionFlag: true,
    ConversionFlag: true,
    ExchangeRate:true,
    Remark:true,
    PiCurrency:true,
  });
  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  //   useEffect(() => {
  //   const fetchExportData = async () => {
  //     try {
  //       let endpoint = `get_receivable?userId=${JSON.parse(localStorage.getItem("user")).userId}`;

  //       if (selectedExportType === "Proforma") {
  //         // For Proforma Receivable, ConversionFlag should be 0
  //         endpoint += "&converted=0";
  //       } else if (selectedExportType === "Tax") {
  //         // For Tax Receivable, ConversionFlag can be 0 or 1
  //         endpoint += "&converted=1";
  //       }

  //       const response = await ProfomaInvoiceAPIInstance.get(endpoint, {
  //         headers: {
  
  //           "Content-Type": "application/json",
  //           Authorization: JSON.parse(localStorage.getItem("token")),
  //         },
  //       });

  //     setExportData(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching export data:", error);
  //       toast.error("Failed to fetch export data.");
  //     }
  //   };
  //   if (selectedExportOption) {
  //     fetchExportData();
  //   }
  // }, [selectedExportOption]);

  //   const handleExport = async (exportType) => {
  //     // Set the appropriate downloading state based on the export type
  //     if (exportType === 'Proforma') {
  //       setIsProformaDownloading(true);
  //     } else if (exportType === 'Tax') {
  //       setIsTaxDownloading(true);
  //     }
  //     setIsDownloading(true);
  //     setDownloadComplete(false);

  //     if (selectedExportOption === "Excel") {
  //       setTimeout(() => {
  //         exportToExcel(exportData, "Proforma_Invoice");
  //         setIsDownloading(false);
  //         setDownloadComplete(true);
  //         setIsProformaDownloading(false);
  //         setIsTaxDownloading(false);
  //       }, 2000);
  //     }
  //     else if (selectedExportOption === "CSV") {
  //       setTimeout(() => {
  //         exportToCSV(exportData, "Proforma_Invoice");
  //         setIsDownloading(false);
  //         setDownloadComplete(true);
  //         setIsProformaDownloading(false);
  //         setIsTaxDownloading(false);
  //       }, 2000);
  //     } else if (selectedExportOption === "PDF") {
  //       setTimeout(() => {
  //         exportToPDF(exportData, "Proforma_Invoice");
  //         setIsDownloading(false);
  //         setDownloadComplete(true);
  //         setIsProformaDownloading(false);
  //         setIsTaxDownloading(false);
  //       }, 2000);
  //     }
  //   };

  const handleExport = async (exportType) => {
    setSelectedExportType(exportType);

    // Set the appropriate downloading state based on the export type
    if (exportType === "Proforma") {
      setIsProformaDownloading(true);
    } else if (exportType === "Tax") {
      setIsTaxDownloading(true);
    }
    setIsDownloading(true);
    setDownloadComplete(false);

    try {
      // Determine the endpoint based on the selected export type
      let endpoint = `get_receivable?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`;

      if (exportType === "Proforma") {
        // For Proforma Receivable, ConversionFlag should be 0
        endpoint += "&converted=0";
      } else if (exportType === "Tax") {
        // For Tax Receivable, ConversionFlag can be 0 or 1
        endpoint += "&converted=1";
      }

      const response = await ProfomaInvoiceAPIInstance.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      });

      const exportData = response.data.data;

      // Export the data based on the selected export option
      if (selectedExportOption === "Excel") {
        exportToExcel(exportData, "Proforma_Invoice");
      } else if (selectedExportOption === "CSV") {
        exportToCSV(exportData, "Proforma_Invoice");
      } else if (selectedExportOption === "PDF") {
        exportToPDF(exportData, "Proforma_Invoice");
      }

      // Mark the download as complete
      setIsDownloading(false);
      setDownloadComplete(true);
      setIsProformaDownloading(false);
      setIsTaxDownloading(false);
    } catch (error) {
      console.error("Error fetching export data:", error);
      toast.error("Failed to fetch export data.");
      setIsDownloading(false);
      setIsProformaDownloading(false);
      setIsTaxDownloading(false);
    }
  };

  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Map data to a 2D array
    const dataArray = data.map((item) => [
      item.Pinumber,
      item.Region,
      item.Company,
      item.InvoiceAmount,
      moment(item.Date).format("YYYY-MM-DD"),
      item.NoOfDaysCreated,
      item["0-30"],
      item["31-60"],
      item["61-90"],
      item["<120"],
    ]);

    // Calculate the total for each relevant column
    const totalRow = [
      "Total", // Label for the total row
      "", // Empty for Region column
      "", // Empty for Company column
      data.reduce(
        (acc, item) => acc + (parseFloat(item.InvoiceAmount) || 0),
        0
      ), // Sum of InvoiceAmount
      "", // Empty for Date column
      "", // Empty for NoOfDaysCreated column
      data.reduce((acc, item) => acc + (parseFloat(item["0-30"]) || 0), 0), // Sum of 0-30
      data.reduce((acc, item) => acc + (parseFloat(item["31-60"]) || 0), 0), // Sum of 31-60
      data.reduce((acc, item) => acc + (parseFloat(item["61-90"]) || 0), 0), // Sum of 61-90
      data.reduce((acc, item) => acc + (parseFloat(item["<120"]) || 0), 0), // Sum of <120
    ];

    // Add the totalRow to dataArray
    dataArray.push(totalRow);

    // Define the headers and create the worksheet
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "PiNumber",
        "Region",
        "Company",
        "Invoice Amount",
        "Invoice Date",
        "No Of Created Date",
        "0-30",
        "31-60",
        "61-90",
        "<120",
      ],
      ...dataArray,
    ]);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `Proforma Invoice ${new Date().toISOString().split("T")[0]}`
    );

    // Generate the Excel file and trigger download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });

    // Prepare the final filename
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    // Save the Excel file
    saveAs(dataBlob, finalFileName);
  };

  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.Pinumber,
      item.Region,
      item.Company,
      item.InvoiceAmount,
      moment(item.Date).format("YYYY-MM-DD"),
      item.NoOfDaysCreated,
      item["0-30"],
      item["31-60"],
      item["61-90"],
      item["<120"],
    ]);

    // Calculate totals for each relevant column
    const totalRow = [
      "Total",
      "",
      "",
      data
        .reduce((sum, item) => sum + parseFloat(item.InvoiceAmount || 0), 0)
        .toFixed(2),
      "",
      "", // Empty placeholders for non-numeric columns
      data
        .reduce((sum, item) => sum + parseFloat(item["0-30"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["31-60"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["61-90"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["<120"] || 0), 0)
        .toFixed(2),
    ];

    // Add the total row to the dataArray
    dataArray.push(totalRow);

    const csvContent = [
      [
        "PiNumber",
        "Region",
        "Company",
        "Invoice Amount",
        "Invoice Date",
        "No Of Created Date",
        "0-30",
        "31-60",
        "61-90",
        "<120",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };

  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code
  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";
    const doc = new jsPDF(orientation, unit, size);
    const tableColumnsPi = [
      "Pinumber",
      "Region",
      "Company",
      "Invoice Amount",
      "Invoice Date",
      "No Of Created Days",
      "0-30",
      "31-60",
      "61-90",
      "<120",
    ];
    const tableRows = data.map((item) => [
      item.Pinumber,
      item.Region,
      item.Company,
      item.InvoiceAmount,
      moment(item.Date).format("YYYY-MM-DD"),
      item.NoOfDaysCreated,
      item["0-30"],
      item["31-60"],
      item["61-90"],
      item["<120"],
    ]);

    // Add a last row for totals
    const totalRow = [
      "Total",
      "",
      "",
      data
        .reduce((sum, item) => sum + parseFloat(item.InvoiceAmount || 0), 0)
        .toFixed(2),
      "",
      "",
      data
        .reduce((sum, item) => sum + parseFloat(item["0-30"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["31-60"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["61-90"] || 0), 0)
        .toFixed(2),
      data
        .reduce((sum, item) => sum + parseFloat(item["<120"] || 0), 0)
        .toFixed(2),
    ];

    // Add the total row to the tableRows array
    tableRows.push(totalRow);

    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;

    doc.setFontSize(titleFontSize);

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");

    const title = `Proforma Invoice Receivables ${currentDate}`;
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumnsPi],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };

    doc.autoTable(tableOptions);

    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };

  const [tableColumnsProinv, settableColumnsProinv] = useState([]);

  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes
  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsProinv((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsProinv.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsProinv", JSON.stringify(newData));
  };
  const handleCheckbox = (id, PiNumber, PiRegion, BillAddressCountry,DeletionFlag,ConversionFlag) => {
    console.log(id, PiNumber);
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
    setPiNumber(PiNumber);
    setPiCountry(PiRegion);
    setBillCountry(BillAddressCountry);
    setDeletionFlag(DeletionFlag);
    setConversionFlag(ConversionFlag);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await ProfomaInvoiceAPIInstance.get(
        `get_proformainvoice?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeleted=${isdelete}&isConverted=${isconvert}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setProinvData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            // item === "Bill_To"
            //   ? false
            // : // : item === "Supply_to"
            // ? false
            item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETD"
              ? false
              : item === "Invoice_Date"
              ? false
              : item === "Due_date"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "Address_GST"
              ? false
              : item === "BankDetails"
              ? false
              : item === "CompanySelection"
              ? false
              : item === "BeneficaryName"
              ? false
              : item === "BankName"
              ? false
              : item === "AccNumber"
              ? false
              : item === "IFSCCode"
              ? false
              : item === "CompanyName"
              ? false
              : item === "OfficeAddress"
              ? false
              : item === "Gst_vatNumber"
              ? false
              : item === "PiOwnerName"
              ? false
              : item === "PiOwnerPhone"
              ? false
              : item === "PiOwnerEmail"
              ? false
              : item === "ChangeAt"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : //           BeneficaryName: true,
                // BankName: true,
                // AccNumber: true,
                // IFSCCode: true,
                // CompanyName: true,
                // OfficeAddress: true,
                // Gst_vatNumber: true,
                // PiOwnerName: true,
                // PiOwnerPhone: true,
                // PiOwnerEmail: true,
                true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setProinvData(filteredData);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (window.localStorage.getItem("proinvfilter")?.length === 0) {
      return setProinvData([]);
    }

    if (window.localStorage.getItem("proinvfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };

  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await ProfomaInvoiceAPIInstance.post(
        `delete_proinv?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? proinvData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        if (data?.data.message === "BookingNumber exists in proinv table") {
          const proinvs = data?.data.data;
          // const bookings = data?.data.data;
          if (proinvs.length === 1) {
            const bookingNumber = proinvs[0].BookingNumber;
            const proinvNumber = proinvs[0].JobNumber;
            setBookingWarning(
              `ProformaInvoice ${proinvNumber} exists for Booking ${bookingNumber}, do you want to delete the corresponding proinv!`
            );
          } else {
            const proinvCount = proinvs.length;
            setBookingWarning(
              `${proinvCount} proinv booking exist, do you want to delete them!`
            );
          }
          return;
        }
        toast({
          title: `ProformaInvoice Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("proinvfilter");
      localStorage.removeItem("proinvfilterConditions");
      localStorage.removeItem("proinvfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("proinvfilter")) {
      const storedData = JSON.parse(localStorage.getItem("proinvfilter") || []);

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? proinvData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("proinvfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("proinvfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("proinvfilterRows"))
      ? JSON.parse(localStorage.getItem("proinvfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("proinvfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("proinvfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "proinvfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "proinvfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await ProfomaInvoiceAPIInstance.post(
        `/proformainvoice/massfilter_pi?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}&isConverted=${isconvert}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("proinvfilter", JSON.stringify([]));
        return setProinvData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            // item === "Bill_To"
            //   ? false
            // : // : item === "Supply_to"
            // ? false
            item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETD"
              ? false
              : item === "Invoice_Date"
              ? false
              : item === "Due_date"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "Address_GST"
              ? false
              : item === "BankDetails"
              ? false
              : item === "CompanySelection"
              ? false
              : item === "BeneficaryName"
              ? false
              : item === "BankName"
              ? false
              : item === "AccNumber"
              ? false
              : item === "IFSCCode"
              ? false
              : item === "CompanyName"
              ? false
              : item === "OfficeAddress"
              ? false
              : item === "Gst_vatNumber"
              ? false
              : item === "PiOwnerName"
              ? false
              : item === "PiOwnerPhone"
              ? false
              : item === "PiOwnerEmail"
              ? false
              : item === "ChangeAt"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setProinvData(filteredData);

      localStorage.setItem("proinvfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("proinvfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setProinvData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            // item === "Bill_To"
            //   ? false
            //   : // : item === "Supply_to"
            // ? false
            item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETD"
              ? false
              : item === "Invoice_Date"
              ? false
              : item === "Due_date"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "Address_GST"
              ? false
              : item === "BankDetails"
              ? false
              : item === "CompanySelection"
              ? false
              : item === "BeneficaryName"
              ? false
              : item === "BankName"
              ? false
              : item === "AccNumber"
              ? false
              : item === "IFSCCode"
              ? false
              : item === "CompanyName"
              ? false
              : item === "OfficeAddress"
              ? false
              : item === "Gst_vatNumber"
              ? false
              : item === "PiOwnerName"
              ? false
              : item === "PiOwnerPhone"
              ? false
              : item === "PiOwnerEmail"
              ? false
              : item === "ChangeAt"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setProinvData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("proinvfilterConditions");
    window.localStorage.removeItem("proinvfilter");
    window.localStorage.removeItem("proinvfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = proinvData[0] && Object?.keys(proinvData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("proinvfilter")?.length === 0) {
      return setProinvData([]);
    }

    if (window.localStorage.getItem("proinvfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await ProfomaInvoiceAPIInstance.put(
        `/proformainvoice/update_pi?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? proinvData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "ProformaInvoice Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await ProfomaInvoiceAPIInstance.get(
      `/proformainvoice/search_pi?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}&isConverted=${isconvert}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setProinvData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          // item === "Bill_To"
          //   ? false
          //   : // : item === "Supply_to"
          // ? false
          item === "POL"
            ? false
            : item === "POD"
            ? false
            : item === "ETA"
            ? false
            : item === "ETD"
            ? false
            : item === "Invoice_Date"
            ? false
            : item === "Due_date"
            ? false
            : item === "VesselNameVoyage"
            ? false
            : item === "Address_GST"
            ? false
            : item === "BankDetails"
            ? false
            : item === "CompanySelection"
            ? false
            : item === "BeneficaryName"
            ? false
            : item === "BankName"
            ? false
            : item === "AccNumber"
            ? false
            : item === "IFSCCode"
            ? false
            : item === "CompanyName"
            ? false
            : item === "OfficeAddress"
            ? false
            : item === "Gst_vatNumber"
            ? false
            : item === "PiOwnerName"
            ? false
            : item === "PiOwnerPhone"
            ? false
            : item === "PiOwnerEmail"
            ? false
            : item === "ChangeAt"
            ? false
            : item === "DeletionFlag"
            ? false
            : item === "ConversionFlag"
            ? false
            : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setProinvData(filteredData);
    setCurrentPage(1);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(proinvData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsProinv")) {
      settableColumnsProinv(
        JSON.parse(localStorage.getItem("tableColumnsProinv"))
      );
    } else {
      settableColumnsProinv(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsProinv];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsProinv", JSON.stringify(updatedColumns));
    settableColumnsProinv(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "hidden",
    position: "relative",
  };
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
    zIndex: "100",
  };

  const ManagecolumnmodalStyleUpdate = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    position: "relative",
    
  };
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
    }
  };
 
  useEffect(() => {
    if (isActionOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionOpen]);
  const handlePrint = async () => {
    console.log("yessss");
    console.log(piCountry);
  
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));
  
      const ids = selectAllPages
        ? proinvData.map((item) => item.id).join(",")
        : checkBoxSelected.join(",");
  
      const pdfResponse = await ProfomaInvoiceAPIInstance.get(
        `get_pidata?userId=${userId}&id=${ids}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      const PIData = pdfResponse.data.data[0]; // Fetch PIData from the response
      console.log(PIData);
  
      // Extract operations from the Containers field
      if (PIData && PIData.Containers && PIData.Containers !== "") {
        const operations = JSON.parse(PIData.Containers).map((container) => {
          const containerObj = {};
          Object.keys(container).forEach((key) => {
            containerObj[key] = container[key] === "" ? null : container[key];
          });
          return containerObj;
        });
        PIData.Operations = operations;
      }
  
      let currency = "";
      let PiCurrency = PIData.PiCurrency; // Assign PiCurrency from PIData
  
      if (PIData.Operations && Array.isArray(PIData.Operations)) {
        const matchingOperation = PIData.Operations.find(
          (operation) => parseFloat(operation.Ex_Rate) === 1
        );
  
        if (matchingOperation) {
          currency = matchingOperation.Currency;
        }
        console.log("Currency with Ex_Rate = 1:", currency);
      }
  
      console.log("PICurrency:", PiCurrency);
  
      // Check if currency matches PiCurrency
      if (currency === PiCurrency) {
        await processPDF(PiCurrency, PIData); // Pass PiCurrency and PIData to processPDF
      } else {
        // Show popup for user to select between PiCurrency and Currency
        setShowPopup(true);
        setCurrencyOptions({ PiCurrency, currency, PIData });
      }
    } catch (error) {
      console.error("Error in handlePrint:", error);
    }
  };
  
  const processPDF = async (type, PIData) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));
      const pdfConfigResponse = await ProfomaInvoiceAPIInstance.get(
        `get_pdf?userId=${userId}&country=${piCountry}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      const pdfConfigData = pdfConfigResponse.data.data[0];
      console.log("PDF Config Data:", pdfConfigData);
  
      if (pdfConfigData && pdfConfigData.proformaApi) {
        const proformaApiEndpoint = `/${pdfConfigData.proformaApi}?currtype=${type}`;
  
        const postResponse = await ProfomaInvoiceAPIInstance.post(
          proformaApiEndpoint,
          PIData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob",
          }
        );
  
        const pdfBlob = new Blob([postResponse.data], {
          type: "application/pdf",
        });
  
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      console.error("Error in processPDF:", error);
    }
  };
  
  // Modal for user selection
  const [showPopup, setShowPopup] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState({});
  
  const handlePopupSelection = (type) => {
    setShowPopup(false); // Close the popup
    processPDF(type, currencyOptions.PIData); // Proceed with the selected type
    setCheckBoxSelected([]);
  };
 // try {
    //   const userId = JSON.parse(localStorage.getItem("user")).userId;
    //   // const user_state = JSON.parse(localStorage.getItem("user")).state;
    //   const token = JSON.parse(localStorage.getItem("token"));

    //   const ids = selectAllPages
    //     ? proinvData.map((item) => item.id).join(",")
    //     : checkBoxSelected.join(",");

    //   // Fetch PI data with IDs as part of the query string
    //   const pdfResponse = await ProfomaInvoiceAPIInstance.get(
    //     `get_pidata?userId=${userId}&id=${ids}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: token,
    //       },
    //     }
    //   );
    //   const PIData = pdfResponse.data.data[0];
    //   console.log(PIData);

    //   if (PIData && PIData.Containers && PIData.Containers !== "") {
    //     const operations = JSON.parse(PIData.Containers).map((container) => {
    //       const containerObj = {};
    //       Object.keys(container).forEach((key) => {
    //         containerObj[key] = container[key] === "" ? null : container[key];
    //       });
    //       return containerObj;
    //     });
    //     PIData.Operations = operations;
    //   }
    //   console.log("seo", PIData);

    //   // Determine the API endpoint based on PiRegion and other conditions
    //   let apiEndpoint;
    //   if (
    //     PIData.PiRegion === "India" &&
    //     PIData.BillAddressCountry === "India"
    //   ) {
    //     if (PIData.PiState === PIData.BillAddressState) {
    //       apiEndpoint = "/indiapdf_same_states";
    //     } else {
    //       apiEndpoint = "/indiapdf_different_states";
    //     }
    //   } else if (PIData.PiRegion === "United Kingdom") {
    //     apiEndpoint = "/ukpdf";
    //   } else if (PIData.PiRegion === "Malaysia") {
    //     apiEndpoint = "/malaysiapdf";
    //   } else if (PIData.PiRegion === "Singapore") {
    //     apiEndpoint = "/singaporepdf";
    //   } else {
    //     apiEndpoint = "/indiapdf_different_country";
    //   }

    //   // Fetch PDF data
    //   await ProfomaInvoiceAPIInstance.post(
    //     apiEndpoint,
    //     {
    //       PIData,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: token,
    //       },
    //       responseType: "blob",
    //     }
    //   )
    //     .then((response) => {
    //       const pdfBlob = new Blob([response.data], {
    //         type: "application/pdf",
    //       });

    //       const pdfUrl = URL.createObjectURL(pdfBlob);
    //       window.open(pdfUrl, "_blank");
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching PDF:", error);
    //     });
    // } catch (error) {
    //   console.error("Error fetching PI creation data:", error);
    // }
  // const handleConvert = async () => {
  //   console.log(piCountry, bilCountry);
  //   if (piCountry === "India") {
  //     try {
  //       const apikey = "de3a3a01-273a-4a81-8b75-13fe37f14dc6";
  //       const userId = JSON.parse(localStorage.getItem("user")).userId;
  //       const token = JSON.parse(localStorage.getItem("token"));
  //       // Fetch PI data with IDs as part of the query string
  //       const taxPiResponse = await ProfomaInvoiceAPIInstance.get(
  //         `get_pitaxdata?userId=${userId}&id=${piNumber}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: token,
  //           },
  //         }
  //       );
  //       const PITaxData = taxPiResponse.data.data;
  //       console.log("PITaxData:", PITaxData);

  //       // Send the PITaxData to GSTZen
  //       const gstzenResponse = await axios.post(
  //         "https://my.gstzen.in/~gstzen/a/post-einvoice-data/einvoice-json/",
  //         JSON.stringify(PITaxData),
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Token: apikey,
  //           },
  //         }
  //       );
  //       if (gstzenResponse.data.status === 0) {
  //         toast({
  //           title: gstzenResponse.data.message,
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: "error",
  //           containerStyle: {
  //             width: "98.5vw",
  //             maxWidth: "98.5vw",
  //             alignContent: "center",
  //           },
  //         });
  //       } else if (gstzenResponse.data.status === 1) {
  //         toast({
  //           title: gstzenResponse.data.message,
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: "success",
  //           containerStyle: {
  //             width: "98.5vw",
  //             maxWidth: "98.5vw",
  //             alignContent: "center",
  //           },
  //         });
  //         // Handle the success case
  //         console.log("GSTZen Response:", gstzenResponse.data);
  //         console.log("Data Sent to GSTZen:", PITaxData);

  //         // Send the response along with PITaxData.DocDtls.No to another endpoint
  //         const taxInvoiceData = {
  //           ...gstzenResponse.data,
  //           docNumber: piNumber,
  //           PiRegion: piCountry,
  //           No: PITaxData.DocDtls.No,
  //         };

  //         try {
  //           // Make the API call
  //           const taxInvoiceResponse = await ProfomaInvoiceAPIInstance.post(
  //             `add_tax?userId=${userId}`,
  //             taxInvoiceData,
  //             {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: token,
  //               },
  //             }
  //           );

  //           // Check if the response status code is in the 2xx range
  //           if (
  //             taxInvoiceResponse.status >= 200 &&
  //             taxInvoiceResponse.status < 300
  //           ) {
  //             const isSuccess =
  //               taxInvoiceResponse.data.success === "true" ||
  //               taxInvoiceResponse.data.success === true;

  //             if (isSuccess) {
  //               toast({
  //                 title: taxInvoiceResponse.data.message,
  //                 position: "bottom-center",
  //                 isClosable: true,
  //                 status: "success",
  //                 containerStyle: {
  //                   width: "98.5vw",
  //                   maxWidth: "98.5vw",
  //                   alignContent: "center",
  //                 },
  //               });
  //             } else {
  //               // Show an error toast if the success field indicates failure
  //               toast({
  //                 title:
  //                   taxInvoiceResponse.data.message || "Something went wrong.",
  //                 position: "bottom-center",
  //                 isClosable: true,
  //                 status: "error",
  //                 containerStyle: {
  //                   width: "98.5vw",
  //                   maxWidth: "98.5vw",
  //                   alignContent: "center",
  //                 },
  //               });
  //             }
  //           } else {
  //             // Handle non-2xx status codes
  //             toast({
  //               title: taxInvoiceResponse.data.message,
  //               position: "bottom-center",
  //               isClosable: true,
  //               status: "error",
  //               containerStyle: {
  //                 width: "98.5vw",
  //                 maxWidth: "98.5vw",
  //                 alignContent: "center",
  //               },
  //             });
  //           }
  //         } catch (error) {
  //           // Handle network or unexpected errors
  //           toast({
  //             title: error.response?.data?.message,
  //             position: "bottom-center",
  //             isClosable: true,
  //             status: "error",
  //             containerStyle: {
  //               width: "98.5vw",
  //               maxWidth: "98.5vw",
  //               alignContent: "center",
  //             },
  //           });
  //         }
  //       } else {
  //         // Handle the success case or other cases if needed
  //         console.log("GSTZen Response:", gstzenResponse.data);
  //         console.log("Data Sent to GSTZen:", PITaxData);
  //       }
  //     } catch (error) {
  //       const errorMessage =
  //         error.response?.data?.message || "An unexpected error occurred.";
  //       toast({
  //         title: errorMessage, // Show an error toast notification
  //         position: "bottom-right",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   } else {
  //     const userId = JSON.parse(localStorage.getItem("user")).userId;
  //     const token = JSON.parse(localStorage.getItem("token"));

  //     try {
  //       // Fetch tax PI data
  //       const taxPiResponse = await ProfomaInvoiceAPIInstance.get(
  //         `get_pitaxdata?userId=${userId}&id=${piNumber}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: token,
  //           },
  //         }
  //       );

  //       const taxInvoiceData = taxPiResponse.data.data;
  //       console.log("taxInvoiceData:", taxInvoiceData);

  //       // Post the tax invoice data
  //       const taxInvoiceResponse = await ProfomaInvoiceAPIInstance.post(
  //         `add_tax?userId=${userId}`,
  //         taxInvoiceData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: token,
  //           },
  //         }
  //       );

  //       // Check if the response status code is in the 2xx range
  //       if (
  //         taxInvoiceResponse.status >= 200 &&
  //         taxInvoiceResponse.status < 300
  //       ) {
  //         const isSuccess =
  //           taxInvoiceResponse.data.success === "true" ||
  //           taxInvoiceResponse.data.success === true;

  //         if (isSuccess) {
  //           toast({
  //             title: taxInvoiceResponse.data.message,
  //             position: "bottom-center",
  //             isClosable: true,
  //             status: "success",
  //             containerStyle: {
  //               width: "98.5vw",
  //               maxWidth: "98.5vw",
  //               alignContent: "center",
  //             },
  //           });
  //         } else {
  //           // Show an error toast if the success field indicates failure
  //           toast({
  //             title: taxInvoiceResponse.data.message || "Something went wrong.",
  //             position: "bottom-center",
  //             isClosable: true,
  //             status: "error",
  //             containerStyle: {
  //               width: "98.5vw",
  //               maxWidth: "98.5vw",
  //               alignContent: "center",
  //             },
  //           });
  //         }
  //       } else {
  //         // Handle non-2xx status codes
  //         toast({
  //           title:
  //             taxInvoiceResponse.data.message || "Failed to process request.",
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: "error",
  //           containerStyle: {
  //             width: "98.5vw",
  //             maxWidth: "98.5vw",
  //             alignContent: "center",
  //           },
  //         });
  //       }
  //     } catch (error) {
  //       // Handle network or unexpected errors
  //       toast({
  //         title: error.response?.data?.message || "Network error occurred.",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   }
  //   setSelectAllPages(false);
  //   setCheckBoxAll(false);
  //   setCheckBoxSelected(false);
  //   getData();
  // };
  
  const handleConvert = async (event) => {
    try{
      if (deletionFlag === true || deletionFlag === 1 || conversionFlag === true || conversionFlag === 1) {
        toast({
          title: `Action not allowed. This record is either deleted or already converted.`,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return; // Exit the function
      }
      const data = await ProfomaInvoiceAPIInstance.get(
        `get_taxregion?userId=${JSON.parse(localStorage.getItem("user")).userId}`
      );
      
      if (data.data.success) {
      console.log("dad",data.data.data);
      const regions = data.data.data;
      const piSubstring = piNumber.substring(5, 3);
      console.log("piNumber",piSubstring)
        if (!regions.includes(piSubstring)) {
          // Show toast if piCountry is not found in the data array
          toast({
            title: `Permission denied contact the administrator for more details`,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
    const { data } = await ProfomaInvoiceAPIInstance.post(
      `add_tax_full?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&id=${piNumber}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/proforma_invoice");
    } else {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",  
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }}}
  } catch (error) {
    // Handling any errors that occur during the API request
    console.error("Error during the form submission:", error);

    toast({
      title: "An error occurred. Please try again later.",
      position: "bottom-center",
      isClosable: true,
      status: "error",
      containerStyle: {
        width: "98.5vw",
        maxWidth: "98.5vw",
        alignContent: "center",
      },
    });
  }
  setSelectAllPages(false);
  setCheckBoxAll(false);
  setCheckBoxSelected(false);
  getData();
  
  };

  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    handlePrint,
    handleConvert,
    onOpenMassupdate,
    onOpenReportDelete
    // handleOpenEditor,
    // onOpenReportOutlookEmail,
  );
  const [productData, setProductData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            {/* <OperationSidebar
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
          size={20}
        /> */}
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
            <TbAdjustmentsHorizontal
              onClick={
                isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
              }
              size={25}
              style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
            />
            &nbsp;
            <div className="relative inline-block">
              <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
                <Link to="/add_proformainvoice">Create Profoma Invoice</Link>
              </button>
            </div>
            &nbsp;&nbsp;
            <div className="relative inline-block">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={toggleActionDropdown}
              >
                Actions
              </button>
              &nbsp;&nbsp;
              {isActionOpen && (
                <div 
                ref = {dropdownRef}
                className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      checkValidation.handleEditClickMassdelete();
                    }}
                  >
                    <RiDeleteBinLine size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Mass Delete
                    </span>
                  </a>
                  <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                    <FiPrinter size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={() => {
                        handleActionSelection();
                        checkValidation.handlePrintClick();
                      }}
                    >
                      View
                    </span>
                  </a>
                  <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                  <GoMoveToEnd size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={() => {
                        handleActionSelection();
                        checkValidation.handleConvertClick();
                      }}
                    >
                      Convert
                    </span>
                  </a>
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      onOpenDownload();
                      setOverlay(<OverlayOne />);
                      toggleActionDropdown();
                    }}
                  >
                    <MdOutlineDownloadForOffline size={20} />

                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Export Data
                    </span>
                  </a>
                  {/* <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}
              >
                <HiOutlineMail size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Email
                </span>
              </a> */}
                  {/* 
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  onOpenDownload();
                  setOverlay(<OverlayOne />);
                  toggleActionDropdown();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Download
                </span>
              </a> */}
                </div>
              )}
            </div>
            <>
              {isOpenFilter && (
                <>
                 <div style={backdropStyle}></div>
                <div className={`FilterModal`}>
                  {/* {${scrollBehavior}} */}
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200,
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Criteria
                    </header>
                    <div
                      className="closemodule"
                      onClick={() => {
                        onCloseFilter(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    <FormControl>
                      {filterRows.map((index) => (
                        <Flex key={index}>
                          <Select
                            id={`column-select-${index}`}
                            placeholder="Select"
                            onChange={(e) => handleFilterTypeSelect(e, index)}
                            value={filterConditions[index]?.column || "select"}
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            style={{ position: "relative" }}
                          >
                            <option value="PiNumber">PiNumber</option>
                            <option value="JobNumber">JobNumber</option>
                            <option value="BookingNumber">BookingNumber</option>
                            <option value="ETD">ETD</option>
                            <option value="ETA">ETA</option>
                            <option value="TypeOfShipment">
                              Type of shipment
                            </option>
                            <option value="Invoice_Date">Invoice Date</option>
                            <option value="Due_Date">Due Date</option>
                            <option value="POL">POL</option>
                            <option value="POD">POD</option>
                            <option value="ContainerType">
                              Container Type
                            </option>
                            <option value="MBL">MBL</option>
                            <option value="HBL">HBL</option>
                            <option value="VesselNameVoyage">
                              Vessel / Voyage
                            </option>
                            <option value="DeletionFlag">DeletionFlag</option>
                            <option value="ConversionFlag">
                              Conversion Flag
                            </option>
                            <option value="Bill_To">Region</option>
                            <option value="BillAddress">Bill Address</option>
                            <option value="BillAddressState">Bill State</option>
                            <option value="BillAddressCountry">
                              Bill Country
                            </option>
                            <option value="BillAddressCity">Bill City</option>
                            <option value="BillGst">Bill GST</option>
                            <option value="ChangeAt">ChangeAt</option>
                          </Select>
                          &nbsp;&nbsp;
                          <Select
                            id={`filter-type-${index}`}
                            onChange={(e) =>
                              handleFilterConditionSelect(e, index)
                            }
                            value={
                              filterConditions[index]?.condition || "select"
                            }
                            placeholder="Select"
                          >
                            {filterConditions[index]?.column === "ETD" ||
                            filterConditions[index]?.column === "ETA" ||
                            filterConditions[index]?.column ===
                              "Invoice_Date" ||
                            filterConditions[index]?.column === "ChangeAt" ||
                            filterConditions[index]?.column === "Due_Date" ? (
                              <>
                                <option value="equalsDate">=</option>
                                <option value="not-equalsDate">!=</option>
                                <option value="greaterDate">&gt;</option>
                                <option value="lesserDate">&lt;</option>
                                <option value="greater-equalDate">&gt;=</option>
                                <option value="lesser-equalDate">&lt;=</option>
                              </>
                            ) : filterConditions[index]?.column ===
                                "DeletionFlag" ||
                              filterConditions[index]?.column ===
                                "ConversionFlag" ? (
                              <>
                                <option value="equals">=</option>
                                <option value="not-equals">!=</option>
                                <option value="greater">&gt;</option>
                                <option value="lesser">&lt;</option>
                                <option value="greater-equal">&gt;=</option>
                                <option value="lesser-equal">&lt;=</option>
                              </>
                            ) : (
                              <>
                                <option value="is">is</option>
                                <option value="contains">contains</option>
                                <option value="startsWith">starts with</option>
                                <option value="endsWith">ends with</option>
                              </>
                            )}
                          </Select>
                          &nbsp;&nbsp;
                          {/* Conditionally render the input field based on the selected column */}
                          {[
                            "ETD",
                            "ETA",
                            "Invoice_Date",
                            "Due_Date",
                            "ChangeAt",
                          ].includes(filterConditions[index]?.column) ? (
                            <Input
                              type="date"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          )}
                          &nbsp;&nbsp;{" "}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={() => addFilterRow()}
                          />
                          {filterRows?.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </FormControl>
                    <div
                      style={{
                        float: "right",
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSubmitFilter();
                          onCloseFilter();
                        }}
                        mr={3}
                      >
                        Apply
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleClearFilters();
                          onCloseFilter();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      <RadioGroup
                        style={{ flex: 1 }}
                        value={handleCheckboxFilter}
                      >
                        <Stack direction="row">
                          <Radio value="AND" onChange={handleCheckboxChange}>
                            All
                          </Radio>
                          <Radio value="OR" onChange={handleCheckboxChange}>
                            Any
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </Rnd>
                </div>
                </>
              
              )}
            </>
            <>
              {isOpenManagecolumn && (
                <>
                  <div style={backdropStyle}></div>
                  <div style={modalStyleUpdate}>
                    <Rnd
                      style={ManagecolumnmodalStyleUpdate}
                      default={{
                        x: 0,
                        y: 0,
                        width: 320,
                        height: 450,
                      }}
                      // cancel="X"
                      cancel="cancel"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Manage Column
                      </header>
                      <div
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />

                      {tableColumnsProinv.map(
                        (label, index) =>
                          label.key !== "id" &&
                          label.key !== "Containers" &&
                          label.key !== "BankDetails" &&
                          label.key !== "BeneficaryName" &&
                          label.key !== "CompanySelection" &&
                          label.key !== "BankName" &&
                          label.key !== "AccNumber" &&
                          label.key !== "IFSCCode" &&
                          label.key !== "CompanyName" &&
                          label.key !== "Gst_vatNumber" &&
                          label.key !== "PiOwnerPhone" &&
                          label.key !== "PiOwnerEmail" && (
                            <label
                              key={index}
                              className="flex items-center"
                              draggable
                              onDragStart={(event) =>
                                handleDragStart(event, index)
                              }
                              onDragOver={handleDragOver}
                              onDrop={(event) => handleDrop(event, index)}
                            >
                              <input
                                type="checkbox"
                                // checked={label.label}
                                className="cursor-pointer"
                                disabled={
                                  label.key === "PiNumber" ||
                                  label.key === "BookingNumber" ||
                                  label.key === "JobNumber" ||
                                  label.key === "PiRegion" ||
                                  label.key === "Invoice_Date" ||
                                  label.key === "Due_Date" ||
                                  label.key === "Bill_To"
                                }
                                checked={label.isVisible}
                                onChange={(event) => {
                                  handleCheckboxColumn(event, label);
                                }}
                              />
                              <span
                                className="ml-2"
                                style={{ cursor: "all-scroll" }}
                              >
                                {label.label}
                              </span>
                              {label.isDisabled && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </label>
                          )
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          float: "right",
                        }}
                      >
                        <Button
                          onClick={() => {
                            onCloseManagecolumn(false);
                          }}
                          colorScheme="green"
                        >
                          OK
                        </Button>
                        <Button
                          onClick={() => {
                            onCloseManagecolumn(false);
                          }}
                          colorScheme="red"
                          ml={3}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Rnd>
                  </div>
                </>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>

              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}

              {/* <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip> */}

              {isconvert ? (
                <Tooltip
                  hasArrow
                  label="Show converted records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiShow size={24} onClick={handleRecordConvert} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  hasArrow
                  label="Hide converted records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiHide size={24} onClick={handleRecordConvert} />
                  </div>
                </Tooltip>
              )}
              {/* {isconvert ? (
            <Tooltip
              hasArrow
              label="Show converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              <BiShow size={24} onClick={handleRecordConvert} />
               
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              <BiShow size={24} onClick={handleRecordConvert} />
                
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              <BiHide size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          )}
          {/* {isconvert ? (
            <Tooltip
              hasArrow
              label="Show converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              <BiShow size={24} onClick={handleRecordConvert} />
               
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          )} */}
              <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
                  <RiDeleteBinLine
                    size={20}
                    onClick={() => {
                      checkValidation.handleEditClickMassdelete();
                    }}
                  />
                </div>
              </Tooltip>
              {isdelete ? (
                <Tooltip
                  hasArrow
                  label="Show deleted records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiShow size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  hasArrow
                  label="Hide deleted records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiHide size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              )}
              {/* <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <HiOutlineMail
                onClick={() => {
                  // checkValidation.handleEditClickMassmail();
                }}
                size={20}
              />
            </div>
          </Tooltip> */}
              {/* <Tooltip hasArrow label="Outlook Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <SiMicrosoftoutlook
                onClick={() => {
                  // checkValidation.handleEditClickOutlookMail();
                }}
                size={20}
              />
            </div>
          </Tooltip> */}

              {/* <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <FiEdit
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassupdate();
                }}
              />
            </div>
          </Tooltip> */}

              <Tooltip
                hasArrow
                label="Select all pages"
                bg="gray.300"
                color="black"
              >
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <MdSelectAll
                    onClick={() => {
                      handleCheckbox(proinvData.map((item) => item.id));
                      setCheckBoxAll((prev) => {
                        if (prev === true) {
                          setCheckBoxSelected([]);
                        }
                        return !prev;
                      });
                    }}
                    size={24}
                  />
                </div>
              </Tooltip>
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, proinvData?.length)}
                  </span>{" "}
                  of <span className="font-medium">{proinvData?.length}</span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <Selectallpages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggablePiTable
                isLoading={isLoading}
                columns={columns2}
                proinvData={proinvData}
                getData={getData}
                data={proinvData.slice(indexOfFirstItem, indexOfLastItem)}
                setProinvData={setProinvData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsProinv={tableColumnsProinv}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
      <>
        {
          <div>
            {isOpenMassupdate && (
              <>
              <div style={backdropStyle}></div>
              <div className={`MassUpdateModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 500,
                    // height: 200
                  }}
                  // cancel="X"
                  cancel="cancel"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Mass Update
                  </header>
                  <div
                    onClick={() => {
                      onCloseMassupdate(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl>
                    <Flex>
                      <Select
                        mt={7}
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={selectedOption}
                        onChange={(e) => handleOptionChange(e.target.value)}
                      >
                        <option value="Select a field">Select a field</option>
                        <option value="TypeOfShipment">TypeOfShipment</option>
                        <option value="ETA">ETA</option>
                        <option value="ETD">ETD</option>
                        <option value="Due_Date">Due_Date</option>
                      </Select>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {selectedOption === "TypeOfShipment" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select product</option>
                          {productData.map((data) => (
                            <option value={data.Product}>{data.Product}</option>
                          ))}
                        </Select>
                      ) : selectedOption === "ETA" ? (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="date"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        />
                      ) : selectedOption === "ETD" ? (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="date"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        />
                      ) : selectedOption === "Due_Date" ? (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="date"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        />
                      ) : (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="text"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          disabled={selectedOption === "Select a field"}
                        />
                      )}
                    </Flex>
                  </FormControl>
                  <br />
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      onOpenReportMassupdate();
                      onCloseMassupdate();
                    }}
                    mr={3}
                  >
                    Submit
                  </Button>
                </Rnd>
              </div>
              </>
            )}
          </div>
        }
      </>
      <>
        {isOpenReportMassupdate && (

          <>
<div style={backdropStyle}></div>
          <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Update ProformaInvoice
              </header>
              <div
                onClick={() => {
                  onCloseReportMassupdate(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Update the proinvs?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitUpdate();
                  onCloseReportMassupdate();
                }}
              >
                Update
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassupdate();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>

      <>
        {isOpenDownload && (

          <>
          <div style={backdropStyle}></div>
          <div className={`DownloadExcelModule `}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
                  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
                  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <>
                <Button
                  colorScheme="green"
                  onClick={() => handleExport("Proforma")}
                  disabled={isProformaDownloading}
                  mr={3}
                >
                  {isProformaDownloading
                    ? "Downloading..."
                    : "Export Proforma Receivable"}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => handleExport("Tax")}
                  disabled={isTaxDownloading}
                  mr={3}
                >
                  {isTaxDownloading
                    ? "Downloading..."
                    : "Export Tax Receivable"}
                </Button>
              </>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Proforma Invoice
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to delete the Proforma Invoice?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitDelete();
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Delete
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
    {showPopup && (
      <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
          <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
        <header>Show the Proforma Invoice in</header>
        <div
                onClick={() => {
                  setShowPopup(false);
                  setCheckBoxSelected([]);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
        <Button  colorScheme="blue" onClick={() => handlePopupSelection(currencyOptions.PiCurrency)}>
           {currencyOptions.PiCurrency}
        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Button colorScheme="blue" onClick={() => handlePopupSelection(currencyOptions.currency)}>
   {currencyOptions.currency}
        </Button>
        </Rnd>
          </div>
          </>
    )}
    {/* The rest of your component */}
  </>
      {/* <>
        {isOpenDownload && (
          <div className={`DownloadExcelModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
      {/*  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
      {/*  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                    <option value="Text">Text</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleExport();
                }}
                disabled={isDownloading}
                mr={3}
              >
                {isDownloading ? "Downloading..." : "Export Data"}
              </Button>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
        )}
      </> */}
    </div>
  );
};

export default ProformaInvoice;
