/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import { RateAPIInstance, AuthAPIInstance } from "../../api-instance";
import "../../assets/css/container.css";
import { DraggableRateTable } from "../../components";
import { Header } from "../../components";
// Internal modules
import "./Rate.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
// import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";


import { FormControl, FormLabel, Input } from "@chakra-ui/react";


import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import
import { MdDownloading } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { BsFiletypeExe } from "react-icons/bs";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { IoMdPrint } from "react-icons/io";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import Addunallocatedrate from "../AddRate/unallocatedrate";


const Rate = () => {
  // State Variables
  const navigate = useNavigate();
  const deleterecordshowhide = true;
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [size, setSize] = React.useState("xl");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);


  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenPrint,
    onOpen: onOpenPrint,
    onClose: onClosePrint,
  } = useDisclosure();


  const cancelRef = React.useRef();
  const searchRef = useRef(null);
  const editorRef = useRef(null);
  const [ratenumber, setRateNumber] = useState([]);
  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [rateData, setRateData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);


  // Unallcoted
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [EnquirytoRate, setEnquirytoRateState] = useState([]);
  const [formData, setFormData] = useState([{
    RateNumber: "",
    Mode: "",
    OriginCountry: "",
    DestinationCountry: "",
    PickUpLocation: "",
    TypeofPort: "",
    OriginPortAirport: "",
    DestinationPortAirport: "",
    DeliveryLocation: "",
    Incoterm: "",
    RateOwner: JSON.parse(localStorage.getItem("user")).userId,
    Commodity: "",
    HSCode: "",
    Scope: "",
    FreeTime: "",
    Stackable: "",
    DGNonDG: "",
    PackingType: "",
    EnquiryDescription: "",
    CargoReadiness: "",
    Attachments: "",
    Remarks_sales:"",
    Remarks_pricing:"",
    Company:"",
    NotesRemarks: "",
    TotalEquipmentValue: "",
    Containers: "Equipment: ,  Quantity: ;",
    RatesContainers: [],
    EnquiryRateContainers: "",
    // Rates_Status:"",


  }]);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
    }
  };
  useEffect(() => {
    if (isActionOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionOpen]);
  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);


  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };


  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    RateNumber: true,
    RateOwner: true,
    EnquiryNumber: true,
    EnquiryOwner: true,
    Mode: true,
    OriginCountry: true,
    DestinationCountry: true,
    PickUpLocation: true,
    TypeofPort: true,
    OriginPortAirport: true,
    DestinationPortAirport: true,
    DeliveryLocation: true,
    Incoterm: true,
    Commodity: true,
    HSCode: true,
    Scope: true,
    FreeTime: true,
    Stackable: true,
    DGNonDG: true,
    PackingType: true,
    EnquiryDescription: true,
    CargoReadiness: true,
    Attachments: true,
    Remarks_sales:true,
    Remarks_pricing:true,
    Company:true,
    NotesRemarks: true,
    DeletionFlag: true,
    RatesContainers: true,
    EnquiryRateContainers: true,
    // Rates_Status:true,
    TotalEquipmentValue: true,


  });
  const [tableColumnsRate, settableColumnsRate] = useState([]);


  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes


  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };


  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };


  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);


    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(exportData, "Rate");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Rate");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Rate");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Rate");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };
  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArray = data.map((item) => [
      item.RateNumber,
      item.RateOwner,
      item.Mode,
      item.OriginCountry,
      item.DestinationCountry,
      item.PickUpLocation,
      item.TypeofPort,
      item.OriginPortAirport,
      item.DestinationPortAirport,
      item.DeliveryLocation,
      item.Incoterm,
      item.Commodity,
      item.HSCode,
      item.Weight,
      item.Scope,
      item.FreeTime,
      item.Equipment,
      item.Stackable,
      item.DGNonDG,
      item.PackingType,
      item.Attachments,
      item.CargoReadiness,
      item.Remarks_sales,
      item.Remarks_pricing,
      item.Company,
      item.NotesRemarks,
      item.Rate,
      item.Vendor,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "RateNumber",
        "RateOwner",
        "Mode",
        "OriginCountry",
        "DestinationCountry",
        "PickUpLocation",
        "TypeofPort",
        "OriginPortAirport",
        "DestinationPortAirport",
        "DeliveryLocation",
        "Incoterm	",
        "Commodity",
        "HSCode",
        "Scope",
        "FreeTime",
        "Equipment",
        "Stackable",
        "DGNonDG",
        "PackingType",
        "Attachments",
        "CargoReadiness",
        "Remarks_sales",
        "Remarks_pricing",
        "Company",
        "NotesRemarks",
        "Rate",
        "Vendor",
      ],
      ...dataArray,
    ]);


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rates");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;


    saveAs(dataBlob, finalFileName);
  };
  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.RateNumber,
      item.RateOwner,
      item.Mode,
      item.OriginCountry,
      item.DestinationCountry,
      item.PickUpLocation,
      item.TypeofPort,
      item.OriginPortAirport,
      item.DestinationPortAirport,
      item.DeliveryLocation,
      item.Incoterm,
      item.Commodity,
      item.HSCode,
      item.Weight,
      item.Scope,
      item.FreeTime,
      item.Equipment,
      item.Stackable,
      item.DGNonDG,
      item.PackingType,
      item.Attachments,
      item.CargoReadiness,
      item.Remarks_sales,
      item.Remarks_pricing,
      item.Company,
      item.NotesRemarks,
      item.Rate,
      item.Vendor,
    ]);


    const csvContent = [
      [
        "RateNumber",
        "RateOwner",
        "Mode",
        "OriginCountry",
        "DestinationCountry",
        "PickUpLocation",
        "TypeofPort",
        "OriginPortAirport",
        "DestinationPortAirport",
        "DeliveryLocation",
        "Incoterm	",
        "Commodity",
        "HSCode",
        "Scope",
        "FreeTime",
        "Equipment",
        "Stackable",
        "DGNonDG",
        "PackingType",
        "Attachments",
        "CargoReadiness",
        "Remarks_sales",
        "Remarks_pricing",
        "Company",
        "NotesRemarks",
        "Rate",
        "Vendor",


      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");


    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";


    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });


    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;


    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code


  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";


    const doc = new jsPDF(orientation, unit, size);
    const tableColumnsRate = [
      "RateNumber",
      "RateOwner",
      "Mode",
      "OriginCountry",
      "DestinationCountry",
      "PickUpLocation",
      "TypeofPort",
      "OriginPortAirport",
      "DestinationPortAirport",
      "DeliveryLocation",
      "Incoterm	",
      "Commodity",
      "HSCode",
      "Scope",
      "FreeTime",
      "Equipment",
      "Stackable",
      "DGNonDG",
      "PackingType",
      "EnquiryDescription",
      "CargoReadiness",
      "Remarks_sales",
      "Remarks_pricing",
      "Company",
      "Attachments",
      "NotesRemarks",
      "Rate",
      "Vendor",];


    const tableRows = data.map((item) => {
      return tableColumnsRate.map((column) => item[column]);
    });


    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;


    doc.setFontSize(titleFontSize);


    const title = "Rates Report";
    doc.text(title, marginLeft, marginTop);


    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumnsRate],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };


    doc.autoTable(tableOptions);


    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;


    doc.save(finalFileName);
  };


  const exportToText = (data, fileName) => {
    const tableColumnsRate = [
      "RateNumber",
      "RateOwner",
      "Mode",
      "OriginCountry",
      "DestinationCountry",
      "PickUpLocation",
      "TypeofPort",
      "OriginPortAirport",
      "DestinationPortAirport",
      "DeliveryLocation",
      "Incoterm	",
      "Commodity",
      "HSCode",
      "Scope",
      "FreeTime",
      "Equipment",
      "Stackable",
      "DGNonDG",
      "PackingType",
      "EnquiryDescription",
      "CargoReadiness",
      "Attachments",
      "Remarks_sales",
      "Remarks_pricing",
      "Company",
      "NotesRemarks",
      "Rate",
      "Vendor",
    ];


    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });


    const textContent = [tableColumnsRate.join(",")]
      .concat(tableRows)
      .join("\n");


    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";


    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };


  const handleCheckboxColumn = (event, item) => {
    settableColumnsRate((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });


    const newData = tableColumnsRate.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );


    localStorage.setItem("tableColumnsRate", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };


  //get timezone api
  // Timezone API
  //  const [timezoneData, setTimezoneData] = useState([]);
  //  useEffect(() => {
  //    const getData = async () => {
  //      try {
  //        const { data } = await Axios.get(`/masterdata/get_timezone`);
  //        setTimezoneData(data.data);
  //        console.log(data.data);
  //      } catch (error) {
  //        toast({
  //          title: error.message,
  //          position: "bottom-center",
  //          isClosable: true,
  //          status: "error",
  //          containerStyle: {
  //            width: "98.5vw",
  //            maxWidth: "98.5vw",
  //            alignContent: "center",
  //          },
  //        });
  //      }
  //    };
  //    getData();
  //  }, []);
  // Admin API
  const [userdata, setUserdata] = useState([]);


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await AuthAPIInstance.get(
          `user/get_user?userId=${JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );


        const userDataList = response?.data?.data || [];
        setUserdata(userDataList);
      } catch (error) {
        // Handle error
      }
    };


    getData();
  }, []);


  // Extracting the roles from user data
  const userRoles = userdata.map(user => user.Role);


  // Check if the user has the 'Admin' role
  const isAdmin = userRoles.includes('Admin');
  // End Admin API
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await RateAPIInstance.get(
        `get_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setRateData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "Incoterm"
              ? false
              : item === "Commodity"
                ? false
                : item === "HSCode"
                  ? false
                  : item === "Scope"
                    ? false
                    : item === "FreeTime"
                      ? false
                      : item === "Equipment"
                        ? false
                        : item === "Stackable"
                          ? false
                          : item === "DGNonDG"
                            ? false
                            : item === "PackingType"
                              ? false
                              : item === "EnquiryDescription"
                                ? false
                                : item === "CargoReadiness"
                                  ? false
                                  : item === "Attachments"
                                    ? false
                                    : item === "NotesRemarks"
                                      ? false
                                      : item === "Remarks_sales"
                                      ? false
                                      : item === "Remarks_pricing"
                                      ? false
                                      : item === "Company"
                                      ? false
                                      : item === "DeletionFlag"
                                        ? false
                                        : item === "Rate"
                                          ? false
                                          : item === "Vendor"
                                            ? false
                                            : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });


        return filteredItem;
      });
      setRateData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (window.localStorage.getItem("ratefilter")?.length === 0) {
      return setRateData([]);
    }


    if (window.localStorage.getItem("ratefilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);


  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("ratefilterRows"))
      ? JSON.parse(localStorage.getItem("ratefilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("ratefilterConditions")
    );
    return savedConditions || {};
  });


  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };


  useEffect(() => {
    localStorage.setItem("ratefilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "ratefilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);


  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);


    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };


  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "ratefilterConditions",
      JSON.stringify(filterConditions)
    );


    const transformedConditions = {};


    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;


      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });


    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await RateAPIInstance.post(
        `mass_filter?userId=${JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("ratefilter", JSON.stringify([]));
        return setRateData([]);
      }


      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "Incoterm"
              ? false
              : item === "Commodity"
                ? false
                : item === "HSCode"
                  ? false
                  : item === "Scope"
                    ? false
                    : item === "FreeTime"
                      ? false
                      : item === "Equipment"
                        ? false
                        : item === "Stackable"
                          ? false
                          : item === "DGNonDG"
                            ? false
                            : item === "PackingType"
                              ? false
                              : item === "EnquiryDescription"
                                ? false
                                : item === "CargoReadiness"
                                  ? false
                                  : item === "Attachments"
                                    ? false
                                    : item === "NotesRemarks"
                                      ? false
                                      : item === "Remarks_sales"
                                      ? false
                                      : item === "Remarks_pricing"
                                      ? false
                                      : item === "Company"
                                      ? false
                                      : item === "Deletion Flag"
                                        ? false
                                        : item === "Rate"
                                          ? false
                                          : item === "Vendor"
                                            ? false
                                            : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });


      setRateData(filteredData);


      localStorage.setItem("ratefilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };


  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };




  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };


  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("ratefilter")),
      };


      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setRateData([]);
      }


      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "Incoterm"
              ? false
              : item === "Commodity"
                ? false
                : item === "HSCode"
                  ? false
                  : item === "Scope"
                    ? false
                    : item === "FreeTime"
                      ? false
                      : item === "Equipment"
                        ? false
                        : item === "Stackable"
                          ? false
                          : item === "DGNonDG"
                            ? false
                            : item === "PackingType"
                              ? false
                              : item === "EnquiryDescription"
                                ? false
                                : item === "CargoReadiness"
                                  ? false
                                  : item === "Attachments"
                                    ? false
                                    : item === "NotesRemarks"
                                      ? false
                                      : item === "Remarks_sales"
                                      ? false
                                      : item === "Remarks_pricing"
                                      ? false
                                      : item === "Company"
                                      ? false
                                      : item === "Rate"
                                        ? false
                                        : item === "Vendor"
                                          ? false
                                          : true,
        };
      });


      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });


      setRateData(filteredData);


      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };


  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });


    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });


    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("ratefilterConditions");
    window.localStorage.removeItem("ratefilter");
    window.localStorage.removeItem("ratefilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };


  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = rateData[0] && Object?.keys(rateData[0]);


    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }


    if (window.localStorage.getItem("ratefilter")?.length === 0) {
      return setRateData([]);
    }


    if (window.localStorage.getItem("ratefilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);


  // Mass Update Input Type


  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };


  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);


      const { data } = await RateAPIInstance.put(
        `update_rate?userId=${JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? rateData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: "Rates Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };
  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);


      const { data } = await RateAPIInstance.post(
        `delete_rate?userId=${JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? rateData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: `Rate Deleted Successfully!`,
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
      onCloseReportDelete();
    }


    if (selectAllPages) {
      localStorage.removeItem("ratefilter");
      localStorage.removeItem("ratefilterConditions");
      localStorage.removeItem("ratefilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }


    if (localStorage.getItem("ratefilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("ratefilter") || []
      );


      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? rateData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("ratefilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("ratefilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    }
  };


  const handleOpenEditor = (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default link behavior
    }
    setIsEditorOpen(true);
  };


  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };


  console.log("Rate data:", rateData);
  console.log("set rate data:", setRateData);
  // const handleSubmitMassEmail = async () => {
  //   try {
  //     const selectedIds = checkBoxSelected;
  //     const recipients = rateData
  //       .filter((item) => selectedIds.includes(item.id))
  //       .map((item) => item.id); // Map to only include IDs, not emails


  //     // Payload with only the selected IDs
  //     const payload = {
  //       recipients, // array of selected rateData IDs
  //     };




  //     const response = await RateAPIInstance.post(
  //       "/massmail_rate",
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: JSON.parse(localStorage.getItem("token")),
  //         },
  //       }
  //     );
  //     const { data } = response;
  //     if (data.success) {
  //       toast({
  //         title: "Email sent successfully!",
  //         position: " bottom-center",
  //         isClosable: true,
  //         status: "success",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       setSelectAllPages(false);
  //       setCheckBoxAll(false);
  //       setCheckBoxSelected([]);
  //     } else {
  //       toast({
  //         title: data.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //     setBody("");
  //     setIsEditorOpen(false);
  //     setSubject("");
  //   } catch (error) {
  //     if (error?.response?.data?.message) {
  //       toast({
  //         title: error?.response?.data?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else if (error?.response?.statusText) {
  //       toast({
  //         title: error?.response?.statusText,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else {
  //       toast({
  //         title: error.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   }
  //   setSelectAllPages(false);
  //   setCheckBoxAll(false);
  //   setCheckBoxSelected([]);
  // };
  const handleSubmitMassEmail = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);


      const { data } = await RateAPIInstance.post(
        `massmail_rate?userId=${JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? rateData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: "Rates Mail Send Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };
  const handleSearch = async (event) => {
    const queryData = event.target.value;


    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };


    const { data } = await RateAPIInstance.get(
      `search_rate?userId=${JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );


    if (data?.data?.length === 0) {
      return setRateData([]);
    }


    const array1 = Object.keys(columns).filter((key) => columns[key]);


    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "Incoterm"
            ? false
            : item === "Commodity"
              ? false
              : item === "HSCode"
                ? false
                : item === "Scope"
                  ? false
                  : item === "FreeTime"
                    ? false
                    : item === "Equipment"
                      ? false
                      : item === "Stackable"
                        ? false
                        : item === "DGNonDG"
                          ? false
                          : item === "PackingType"
                            ? false
                            : item === "EnquiryDescription"
                              ? false
                              : item === "CargoReadiness"
                                ? false
                                : item === "Attachments"
                                  ? false
                                  : item === "NotesRemarks"
                                    ? false
                                    : item === "Remarks_sales"
                                    ? false
                                    : item === "Remarks_pricing"
                                    ? false
                                    : item === "Company"
                                    ? false
                                    : item === "Rate"
                                      ? false
                                      : item === "Vendor"
                                        ? false
                                        : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));


    setColumns2(filteredDataHeader);


    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};


      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });


      return filteredItem;
    });


    setRateData(filteredData);
  };
  // Pagination


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];


  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };


  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };


  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };


  const pageNumbers = Math.ceil(rateData?.length / itemsPerPage);


  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.


    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);


  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );


    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };




  useEffect(() => {
    if (localStorage.getItem("tableColumnsRate")) {
      settableColumnsRate(JSON.parse(localStorage.getItem("tableColumnsRate")));
    } else {
      settableColumnsRate(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);


  //console.log(columns2);


  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };
  // const handleRecordDelete = () => {
  //   setIsdelete(!isdelete);
  // };
  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const handleDrop = (event, dropIndex) => {
    event.preventDefault();


    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsRate];


    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsRate", JSON.stringify(updatedColumns));
    settableColumnsRate(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const columnstyle = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    scrollbarWidth: "thin", // Set the width of the scrollbar to thin
    scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)"
  };


  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };


  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };


  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };


  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };


  //class declaration


  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenReportDelete,
    onOpenReportMassEmail,
    onOpenMassupdate,
    onOpenPrint
  );


  return (
    <>
      <Header />
      <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
        <FaFilter
          size={25}
          onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
        />
        &nbsp;&nbsp;
        <>
          <div className="pt-2 ml-10 relative mx-auto text-gray-600">
            <input
              className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
              ref={searchRef}
              type="search"
              name="search"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </>
        &nbsp;
        <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        />
        &nbsp;
        <div className="relative inline-block">
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
            <Link to="/add_quotation">Create Quotation</Link>
          </button> */}
          <Addunallocatedrate
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            onApply={handleApply}
            setEnquirytoRateState={setEnquirytoRateState}
            setFormData={setFormData}
            formData={formData} />
        </div>
        &nbsp;&nbsp;
        {/* action button */}
        <div className="relative inline-block">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
            onClick={toggleActionDropdown}
          >
            Actions
          </button>
          &nbsp;&nbsp;
          {isActionOpen && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
              {/* Mass update icon */}
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <FiEdit size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    checkValidation.handleEditClickMassupdate();
                    handleActionSelection();
                  }}
                >
                  Mass Upload
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              >
                <RiDeleteBinLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Delete
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  window.print(); // This triggers the browser's print functionality
                }}
              >
                <IoMdPrint size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Print
                </span>
              </a>
              {/* Download icon */}
              {isAdmin && (<a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  onOpenDownload();
                  setOverlay(<OverlayOne />);
                  toggleActionDropdown();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />


                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Data
                </span>
              </a>)}
            </div>
          )}
        </div>
        {/* Mass filter */}
        <>
          {isOpenFilter && (
            <>
              <div style={backdropStyle}></div>
              <div className={`FilterModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                    // height: 200,
                  }}
                  cancel=".cancelDragAndDrop"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Criteria
                  </header>
                  <div
                    className="closemodule cancelDragAndDrop"
                    onClick={() => {
                      onCloseFilter(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />


                  <FormControl className="cancelDragAndDrop">
                    {filterRows.map((index) => (
                      <Flex key={index}>
                        <Select
                          id={`column-select-${index}`}
                          placeholder="Select"
                          onChange={(e) => handleFilterTypeSelect(e, index)}
                          value={filterConditions[index]?.column || "select"}
                          size={"md"}
                          isDisabled={false}
                          blurInputOnSelect={true}
                          isSearchable={false}
                          style={{ position: "relative" }}
                        >
                          <option value="RateNumber">Rate Number</option>
                          <option value="RateOwner">RateOwner</option>
                          <option value="Mode">Mode</option>
                          <option value="OriginCountry">OriginCountry</option>
                          <option value="DestinationCountry">DestinationCountry</option>
                          <option value="PickUpLocation">PickUpLocation</option>
                          <option value="TypeofPort">TypeofPort</option>
                          <option value="OriginPortAirport">OriginPortAirport</option>
                          <option value="DestinationPortAirport">DestinationPortAirport</option>
                          <option value="DeliveryLocation">Delivery Location</option>
                          <option value="Incoterm">Incoterm</option>
                          <option value="Commodity">Commodity</option>
                          <option value="HSCode">HSCode</option>
                          <option value="Scope">Scope</option>
                          <option value="Stackable">	Stackable</option>
                          <option value="DGNonDG">DGNonDG</option>
                          <option value="PackingType">PackingType</option>
                          <option value="EnquiryDescription">EnquiryDescription</option>
                          <option value="CargoReadiness">CargoReadiness</option>
                          {/* <option value="Attachments">Attachments</option> */}
                          <option value="NotesRemarks">NotesRemarks</option>
                          <option value="Remarks_sales">Remarks_sales</option>
                          <option value="Remarks_pricing">Remarks_pricing</option>
                          <option value="Company">Company</option>


                        </Select>
                        &nbsp;&nbsp;
                        <Select
                          id={`filter-type-${index}`}
                          onChange={(e) => handleFilterConditionSelect(e, index)}
                          value={filterConditions[index]?.condition || "select"}
                          placeholder="Select"
                        >
                          {filterConditions[index]?.column === "CreatedAt" ||
                            filterConditions[index]?.column === "ChangedAt" ? (
                            <>
                              <option value="equalsDate">=</option>
                              <option value="not-equalsDate">!=</option>
                              <option value="greaterDate">&gt;</option>
                              <option value="lesserDate">&lt;</option>
                              <option value="greater-equalDate">&gt;=</option>
                              <option value="lesser-equalDate">&lt;=</option>
                            </>
                          ) : filterConditions[index]?.column === "RateOwnerName" ? (
                            <>
                              <option value="isratename">is</option>
                              <option value="containsratename">contains</option>
                              <option value="startsWithratename">starts with</option>
                              <option value="endsWithratename">ends with</option>
                            </>
                          ) : filterConditions[index]?.column === "Phone" ? (
                            <>
                              <option value="equals">=</option>
                              <option value="not-equals">!=</option>
                              <option value="greater">&gt;</option>
                              <option value="lesser">&lt;</option>
                              <option value="greater-equal">&gt;=</option>
                              <option value="lesser-equal">&lt;=</option>
                            </>
                          ) : (
                            <>
                              <option value="is">is</option>
                              <option value="contains">contains</option>
                              <option value="startsWith">starts with</option>
                              <option value="endsWith">ends with</option>
                            </>
                          )}
                        </Select>


                        &nbsp;&nbsp;
                        {/* <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      /> */}
                        {/* Conditionally render the input field based on the selected column */}
                        {["CreatedAt"].includes(filterConditions[index]?.column) ? (
                          <Input
                            type="date"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        ) : (
                          <Input
                            type="text"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        )}
                        &nbsp;&nbsp;{" "}


                        <IoMdAddCircle
                          className="cursor-pointer"
                          size={70}
                          style={{ marginTop: "-15px", color: "green" }}
                          onClick={() => addFilterRow()}
                        />
                        {filterRows?.length > 1 && index > 0 && (
                          <BiSolidMinusCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "red" }}
                            onClick={() => removeFilterRow(index)}
                          />
                        )}
                      </Flex>
                    ))}
                  </FormControl>
                  <div className="cancelDragAndDrop" style={{ float: "right", display: "block", marginTop: "10px" }}>
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <RadioGroup className="cancelDragAndDrop" style={{ flex: 1 }} value={handleCheckboxFilter}>
                      <Stack direction="row">
                        <Radio value="AND" onChange={handleCheckboxChange}>
                          All
                        </Radio>
                        <Radio value="OR" onChange={handleCheckboxChange}>
                          Any
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
        {/* Manage column */}
        <>
          {isOpenManagecolumn && (
            <>
              <div style={backdropStyle}></div>
              <div style={modalStyleUpdate}>
                <Rnd
                  style={columnstyle}
                  default={{
                    x: 0,
                    y: 0,
                    width: 400,
                    height: 500,
                  }}


                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Manage Column
                  </header>
                  <div
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />


                  {tableColumnsRate.map(
                    (label, index) =>
                      label.key !== "id" &&
                      label.key !== "DialCode" && (
                        <label
                          key={index}
                          className="flex items-center"
                          draggable
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDragOver={handleDragOver}
                          onDrop={(event) => handleDrop(event, index)}
                        >
                          <input
                            type="checkbox"
                            // checked={label.label}
                            className="cursor-pointer"
                            disabled={
                              label.key === "RateNumber"
                                ? true
                                // : label.key === "Commodity"
                                //   ? true
                                //   : label.key === "Weight"
                                //     ? true
                                //     : label.key === "Scope"
                                //       ? true
                                //       : label.key === "Stackable"
                                //         ? true
                                // : label.key === "RateOwner"
                                //   ? true
                                : null
                            }
                            checked={label.isVisible}
                            onChange={(event) => {
                              handleCheckboxColumn(event, label);
                            }}
                          />
                          <span className="ml-1" style={{ cursor: "all-scroll" }}>
                            {label.label}
                          </span>
                          {label.isDisabled && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>
                      )
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      float: "right",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="green"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="red"
                      ml={3}
                    >
                      Cancel
                    </Button>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>


          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>


          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>


          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>
          {/* Icons */}
          <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassupdate();
                }}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Mass update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              < FiEdit
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}


              />
            </div>
          </Tooltip>


          {/* <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip> */}
          <Tooltip hasArrow label="Mass Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <HiOutlineMail
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip>
          {isdelete ? (
            <Tooltip
              hasArrow
              label="Show deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          )}
          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(rateData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>


          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, rateData?.length)}
              </span>{" "}
              of <span className="font-medium">{rateData?.length}</span> records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>
        {/* table */}
        <div className="table-container">
          <DraggableRateTable
            isLoading={isLoading}
            columns={columns2}
            rateData={rateData}
            getData={getData}
            data={rateData.slice(indexOfFirstItem, indexOfLastItem)}
            setRateData={setRateData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsRate={tableColumnsRate}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>


        <>{


          <div>
            {isOpenReportMassEmail && (
              <><div style={backdropStyle}></div>
                <div className={`MassMailConfirmModule ${scrollBehavior}`}>
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200
                    }}
                    //cancel="X"
                    cancel="cancel"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Send Mail
                    </header>
                    <div
                      onClick={() => {
                        onCloseReportMassEmail(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />
                    <FormControl>
                      {" "}
                      Are you sure you want to Send the mail?
                    </FormControl>
                    <br />
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitMassEmail();
                        onCloseReportMassEmail();
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        onCloseReportMassEmail();
                      }}
                      ml={3}
                    >
                      Cancel
                    </Button>
                  </Rnd>
                </div>
              </>
            )}
          </div>
        }
        </>
        <>
          {isOpenReportDelete && (
            <>
              <div style={backdropStyle}></div>
              <div className={`MassDeleteModule ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                    // height: 200
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Delete Rate
                  </header>
                  <div
                    onClick={() => {
                      onCloseReportDelete(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl>
                    {" "}
                    Are you sure you want to delete the Rate?
                  </FormControl>
                  <br />
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      handleSubmitDelete();
                      onCloseReportDelete();
                    }}
                    mr={3}
                  >
                    Delete
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {


                      onCloseReportDelete();
                    }}
                    mr={3}
                  >
                    Cancel
                  </Button>
                </Rnd>
              </div>
            </>
          )}
        </>
        {/* Mass update */}
        <>
          {
            <div>
              {isOpenMassupdate && (
                <>
                  <div style={backdropStyle}></div>
                  <div className={`MassUpdateModal ${scrollBehavior}`}>
                    <Rnd
                      style={style}
                      default={{
                        x: 0,
                        y: 0,
                        width: 500,
                        // height: 200
                      }}
                      cancel="X"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Mass Update
                      </header>
                      <div
                        onClick={() => {
                          onCloseMassupdate(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />
                      <FormControl>
                        <Flex>
                          <Select
                            mt={7}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            value={selectedOption}
                            onChange={(e) => handleOptionChange(e.target.value)}
                          >
                            <option value="Select a field">Select a field</option>
                            {/* <option value="RateNumber">Rate Number</option>
                            <option value="RateOwner">RateOwner</option> */}
                            <option value="Mode">Mode</option>
                            <option value="OriginCountry">OriginCountry</option>
                            <option value="DestinationCountry">DestinationCountry</option>
                            <option value="PickUpLocation">PickUpLocation</option>
                            <option value="TypeofPort">TypeofPort</option>
                            <option value="OriginPortAirport">OriginPortAirport</option>
                            <option value="DestinationPortAirport">DestinationPortAirport</option>
                            <option value="DeliveryLocation">Delivery Location</option>
                            <option value="Incoterm">Incoterm</option>
                            <option value="Commodity">Commodity</option>
                            <option value="HSCode">HSCode</option>
                            <option value="Scope">Scope</option>
                            <option value="Stackable">	Stackable</option>
                            <option value="DGNonDG">DGNonDG</option>
                            <option value="PackingType">PackingType</option>
                            <option value="EnquiryDescription">EnquiryDescription</option>
                            <option value="CargoReadiness">CargoReadiness</option>
                            {/* <option value="Attachments">Attachments</option> */}
                            <option value="NotesRemarks">NotesRemarks</option>
                            <option value="Remarks_sales">Remarks_sales</option>
                          <option value="Remarks_pricing">Remarks_pricing</option>
                          <option value="Company">Company</option>
                            {/* <option value="Rate">Rate</option>
                            <option value="Vendor">Vendor</option> */}
                          </Select>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {(
                            <Input
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              type="text"
                              name={selectedOption}
                              id="updateField"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              disabled={selectedOption === "Select a field"}
                            />
                          )}
                        </Flex>
                      </FormControl>
                      <br />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          onOpenReportMassupdate();
                          onCloseMassupdate();
                        }}
                        mr={3}
                      >
                        Submit
                      </Button>
                    </Rnd>
                  </div>
                </>
              )}
            </div>
          }
        </>
      </div>


      {/* Mass update confirmation*/}
      <>
        {isOpenReportMassupdate && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Update Rates
                </header>
                <div
                  onClick={() => {
                    onCloseReportMassupdate(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to Update the rates?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitUpdate();
                    onCloseReportMassupdate();
                  }}
                >
                  Update
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportMassupdate();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>




      {/* Download */}
      <>
        {isOpenDownload && (
          <>
            <div style={backdropStyle}></div>
            <div className={`DownloadExcelModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 500,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Do you wanna to export data?
                </header>
                <div
                  onClick={() => {
                    onCloseDownload(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  <Flex alignItems="center ">
                    {" "}
                    {/* Use alignItems to vertically center the contents */}
                    <FormLabel marginLeft="100">Select</FormLabel>{" "}
                    {/* Push FormLabel to the left */}
                    <Select
                      w={60}
                      mt={-1}
                      placeholder="Select option"
                      size="md"
                      ref={initialRef}
                      value={selectedExportOption}
                      onChange={handleExportOptionChange}
                    >
                      <option value="Excel">Excel</option>
                      <option value="CSV">CSV</option>
                      <option value="PDF">PDF</option>
                      <option value="Text">Text</option>
                    </Select>
                  </Flex>
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleExport();
                  }}
                  disabled={isDownloading}
                  mr={3}
                >
                  {isDownloading ? "Downloading..." : "Export Data"}
                </Button>
                {downloadComplete && (
                  <Text mt={2} color="green">
                    Download completed!
                  </Text>
                )}
              </Rnd>
            </div>
          </>
        )}
      </>




    </>
  );
};
export default Rate;