import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import {
  Addlead,
  Addprospect,
  Dashboard,
  Lead,
  Login,
  Prospect,
  Register,
  Checkmail,
  Restpassword,
  Emailverify,
  Report,
  Home,
  Job,
  IncompleteTask,
  Update,
  Booking,
  Addbooking,
  Documents,
  OperationVendors,
  Checklist,
  Activitylog,
  Addjob,
  Createjob,
  BookingPdf,
  UploadDocument,
  AddVendorOps,
  AddChecklist,
  AddOpsVendor,
  AddSiCreation,
  AddHBLCreation,
  AddSwitchHbl,
  AddNoaCreation,
  AddFreightCertificate,
  AddHdoCreation,
  AddCfsCreation,
  EditSiCreation,
  EditHBLCreation,
  EditSwitchHbl,
  EditFreightCertificate,
  EditCfsCreation,
  EditHdoCreation,
  EditNoaCreation,
  Createbooking,
  ProformaInvoice,
  AddProformaInvoice,
  TaxInvoice,
  CreditNote,
  DebitNote,
  // Matser Data
  Addindustry,
  Country,
  Addcountry,
  Incoterm,
  Addincoterm,
  Status,
  Addstatus,
  City,
  Addcity,
  Airport,
  Addairport,
  Equipment,
  Addequipment,
  Addnumrange,
  Addstate,
  Addtag,
  Numrange,
  State,
  Tag,
  Addsource,
  Source,
  Addport,
  Port,
  Industry,
  Grade,
  Region,
  Addregion,
  Mode,
  Addmode,
  Product,
  Addproduct,
  Auth,
  Addgrade,
  AddAuth,
  VendorType,
  Addvendortype,
  PackingType,
  Client,
  Addclient,
  AddPackingType,
  AddOperationuserRegion,
  OperationuserRegion,
  AdduserRegion,
  UserRegion,
  Userregion,
  AddEquipmentContainer,
  EquipmentContainer,
  Shippingline,
  AddGst,
  Gst,
  Vendor,
  AddVendor,
  Role,
  Roleauth,
  Addrole,
  Addroleauth,
  Customer,
  Bank,
  AddBank,
  AddCustomer,
  Config,
  AddConfig,
  AddShippingline,
  AddInterCompanyPi,
  InterCompanyPi,
  Enquiry,
  Quotation,
  AddEnquiry,
  AddQuotation,
  AddRate,
  Rate,
} from "../page";
// Master Data

import {
  Userprofile,
  LeadTemplate,
  ProspectTemplate,
  Error,
  EditJobPage,
  RegionTemplate,
  ModeTemplate,
  VendorTypeTemplate,
  AirportTemplate,
  EquipmentTemplate,
  Incotermtemplate,
  Statustemplate,
  ProductTemplate,
  PortTemplate,
  EquipmentContainerTemplate,
  SourceTemplate,
  ClientTemplate,
  AuthTemplate,
  CityTemplate,
  CountryTemplate,
  CustomerTemplate,
  GstTemplate,
  IndustryTemplate,
  NumrangeTemplate,
  OperationuserregionTemplate,
  PackingTypeTemplate,
  RoleTemplate,
  RoleauthTemplate,
  ShippinglineTemplate,
  StateTemplate,
  TagTemplate,
  TierTemplate,
  UserregionTemplate,
  VendorTemplate,
  ConfigTemplate,
} from "../components";
import BankTemplate from "../components/Layout/BankdetailsTemplate";
import Industrytemplate from "../components/Layout/IndustryTemplate";
// import { Rate } from "antd";

export default function RootRouter() {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgetpassword" element={<Emailverify />} />
      <Route path="/otpverfiy" element={<Checkmail />} />
      <Route path="/restpassword" element={<Restpassword />} />
      <Route path="*" element={<Error />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lead"
        element={
          <ProtectedRoute>
            <Lead />
          </ProtectedRoute>
        }
      />
      <Route
        path="/prospect"
        element={
          <ProtectedRoute>
            <Prospect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/enquiry"
        element={
          <ProtectedRoute>
            <Enquiry />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quotation"
        element={
          <ProtectedRoute>
            <Quotation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/rate"
        element={
          <ProtectedRoute>
            <Rate/>
          </ProtectedRoute>
        }
      />
      <Route
        path="/booking"
        element={
          <ProtectedRoute>
            <Booking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/report"
        element={
          <ProtectedRoute>
            <Report />
          </ProtectedRoute>
        }
      />
      <Route
        path="/operation_home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/operation_job"
        element={
          <ProtectedRoute>
            <Job />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editjob/:JobNumber" ///:JobNumber/:BookingNumber
        element={
          <ProtectedRoute>
            <EditJobPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/operation_incomplete_task"
        element={
          <ProtectedRoute>
            <IncompleteTask />
          </ProtectedRoute>
        }
      />
      <Route
        path="/operation_update"
        element={
          <ProtectedRoute>
            <Update />
          </ProtectedRoute>
        }
      />
      <Route
        path="/opertion_docs"
        element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/upload_document"
        element={
          <ProtectedRoute>
            <UploadDocument />
          </ProtectedRoute>
        }
      />
      <Route
        path="/opertion_vendor"
        element={
          <ProtectedRoute>
            <OperationVendors />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/opertion_checklist"
        auth, roleauth, userrole, user, operation_user_region
        element={
          <ProtectedRoute>
            <Checklist />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/opertion_activitylog"
        element={
          <ProtectedRoute>
            <Activitylog />
          </ProtectedRoute>
        }
      />
      <Route
        path="/addchecklist"
        element={
          <ProtectedRoute>
            <AddChecklist />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/view-booking"
        element={
          <ProtectedRoute>
            <BookingPdf />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="proforma_invoice"
        element={
          <ProtectedRoute>
            <ProformaInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tax_invoice"
        element={
          <ProtectedRoute>
            <TaxInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path="/creditnote"
        element={
          <ProtectedRoute>
            <CreditNote />
          </ProtectedRoute>
        }
      />
      <Route
        path="/debitnote"
        element={
          <ProtectedRoute>
            <DebitNote />
          </ProtectedRoute>
        }
      />
      <Route path="/addlead" element={<Addlead />} />
      <Route path="/addprospect" element={<Addprospect />} />
      <Route path="/userprofile" element={<Userprofile />} />
      <Route path="/leadtemplate" element={<LeadTemplate />} />
      <Route path="/prospecttemplate" element={<ProspectTemplate />} />
      <Route path="/addenquiry" element={<AddEnquiry />} />
      <Route path="/add_quotation" element={<AddQuotation />} />
      <Route path="/add_rate" element={<AddRate />} />
      <Route path="/addjob" element={<Addjob />} />
      <Route path="/createjob" element={<Createjob />} />
      <Route path="/addbooking" element={<Addbooking />} />
      <Route path="/createbooking" element={<Createbooking />} />
      {/* <Route path="/addvendor" element={<AddVendorOps />} /> */}
      {/* <Route path="/addvendor" element={<AddOpsVendor />} /> */}
      {/* Add Document  */}
      <Route path="/add_sicreation" element={<AddSiCreation />} />
      <Route path="/add_hblcreation" element={<AddHBLCreation />} />
      <Route path="/add_switchhbl" element={<AddSwitchHbl />} />
      <Route path="/add_noacreation" element={<AddNoaCreation />} />
      <Route
        path="/freight_certification"
        element={<AddFreightCertificate />}
      />
      <Route path="/add_hdocreation" element={<AddHdoCreation />} />
      <Route path="/add_cfscreation" element={<AddCfsCreation />} />
      <Route path="/edit_sicreation" element={<EditSiCreation />} />
      <Route path="/edit_hblcreation" element={<EditHBLCreation />} />
      <Route path="/edit_shblcreation" element={<EditSwitchHbl />} />
      <Route path="/edit_fc" element={<EditFreightCertificate />} />
      <Route path="/edit_cfs" element={<EditCfsCreation />} />
      <Route path="/edit_hdo" element={<EditHdoCreation />} />
      <Route path="/edit_noa" element={<EditNoaCreation />} />
      <Route path="/add_proformainvoice" element={<AddProformaInvoice />} />
      <Route path="/add_intercompany" element={<AddInterCompanyPi />} />
      <Route path="/intercompanypi" element={<InterCompanyPi />} />
      {/* Master data Routes */}
      <Route
        path="/country"
        element={
          <ProtectedRoute>
            <Country />
          </ProtectedRoute>
        }
      />
      <Route
        path="/city"
        element={
          <ProtectedRoute>
            <City />
          </ProtectedRoute>
        }
      />
      <Route
        path="/incoterm"
        element={
          <ProtectedRoute>
            <Incoterm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/mode"
        element={
          <ProtectedRoute>
            <Mode />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vendortype"
        element={
          <ProtectedRoute>
            <VendorType />
          </ProtectedRoute>
        }
      />
      <Route
        path="/status"
        element={
          <ProtectedRoute>
            <Status />
          </ProtectedRoute>
        }
      />
      <Route
        path="/numrange"
        element={
          <ProtectedRoute>
            <Numrange />
          </ProtectedRoute>
        }
      />
      <Route
        path="/source"
        element={
          <ProtectedRoute>
            <Source />
          </ProtectedRoute>
        }
      />
      <Route
        path="/airport"
        element={
          <ProtectedRoute>
            <Airport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/equipment"
        element={
          <ProtectedRoute>
            <Equipment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/port"
        element={
          <ProtectedRoute>
            <Port />
          </ProtectedRoute>
        }
      />
      <Route
        path="/state"
        element={
          <ProtectedRoute>
            <State />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tag"
        element={
          <ProtectedRoute>
            <Tag />
          </ProtectedRoute>
        }
      />
      <Route
        path="/industry"
        element={<ProtectedRoute>{<Industry />}</ProtectedRoute>}
      />
      <Route
        path="/grade"
        element={
          <ProtectedRoute>
            <Grade />
          </ProtectedRoute>
        }
      />
      <Route
        path="/region"
        element={
          <ProtectedRoute>
            <Region />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product"
        element={
          <ProtectedRoute>
            <Product />
          </ProtectedRoute>
        }
      />
      <Route
        path="/auth"
        element={
          <ProtectedRoute>
            <Auth />
          </ProtectedRoute>
        }
      />
      <Route
        path="/packingtype"
        element={
          <ProtectedRoute>
            <PackingType />
          </ProtectedRoute>
        }
      />
      <Route
        path="/client"
        element={
          <ProtectedRoute>
            <Client />
          </ProtectedRoute>
        }
      />
      <Route
        path="/operationuserregion"
        element={
          <ProtectedRoute>
            <OperationuserRegion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userregion"
        element={
          <ProtectedRoute>
            <UserRegion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/equipmentcontainer"
        element={
          <ProtectedRoute>
            <EquipmentContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shippingline"
        element={
          <ProtectedRoute>
            <Shippingline />
          </ProtectedRoute>
        }
      />
      <Route
        path="/gst"
        element={
          <ProtectedRoute>
            <Gst />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vendor"
        element={
          <ProtectedRoute>
            <Vendor />
          </ProtectedRoute>
        }
      />
      <Route
        path="/role"
        element={
          <ProtectedRoute>
            <Role />
          </ProtectedRoute>
        }
      />
      <Route
        path="/roleauth"
        element={
          <ProtectedRoute>
            <Roleauth />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer"
        element={
          <ProtectedRoute>
            <Customer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bank"
        element={
          <ProtectedRoute>
            <Bank />
          </ProtectedRoute>
        }
      />
      <Route
        path="/config"
        element={
          <ProtectedRoute>
            <Config />
          </ProtectedRoute>
        }
      />
      {/* addpage */}
      <Route path="/addcountry" element={<Addcountry />} />
      <Route path="/addairport" element={<Addairport />} />
      <Route path="/addequipment" element={<Addequipment />} />
      <Route path="/addincoterm" element={<Addincoterm />} />
      <Route path="/addstatus" element={<Addstatus />} />
      <Route path="/addcity" element={<Addcity />} />
      <Route path="/addindustry" element={<Addindustry />} />
      <Route path="/addnumrange" element={<Addnumrange />} />
      <Route path="/addstate" element={<Addstate />} />
      <Route path="/addtag" element={<Addtag />} />
      <Route path="/addgrade" element={<Addgrade />} />
      <Route path="/addregion" element={<Addregion />} />
      <Route path="/addmode" element={<Addmode />} />
      <Route path="/addvendortype" element={<Addvendortype />} />
      <Route path="/addproduct" element={<Addproduct />} />
      <Route path="/addauth" element={<AddAuth />} />
      <Route path="/addclient" element={<Addclient />} />
      <Route path="/addpackingtype" element={<AddPackingType />} />
      <Route
        path="/addOperationuserRegion"
        element={<AddOperationuserRegion />}
      />
      <Route path="/adduserregion" element={<AdduserRegion />} />
      <Route
        path="/addequipmentcontainer"
        element={<AddEquipmentContainer />}
      />
      <Route path="/addshippingline" element={<AddShippingline />} />
      <Route path="/addgst" element={<AddGst />} />
      <Route path="/addvendor" element={<AddVendor />} />
      <Route path="/addrole" element={<Addrole />} />
      <Route path="/addroleauth" element={<Addroleauth />} />
      <Route path="/addbank" element={<AddBank />} />
      <Route path="/addcustomer" element={<AddCustomer />} />
      <Route path="/addport" element={<Addport />} />
      <Route path="/addsource" element={<Addsource />} />
      <Route path="/addconfig" element={<AddConfig />} />
      {/* template */}
      <Route path="/Regiontemplate" element={<RegionTemplate />} />
      <Route path="/Modetemplate" element={<ModeTemplate />} />
      <Route path="/VendorTypetemplate" element={<VendorTypeTemplate />} />
      <Route path="/Airporttemplate" element={<AirportTemplate />} />
      <Route path="/Equipmenttemplate" element={<EquipmentTemplate />} />
      <Route path="/Incotermtemplate" element={<Incotermtemplate />} />
      <Route path="/Statustemplate" element={<Statustemplate />} />
      <Route path="/ProductTemplate" element={<ProductTemplate />} />
      <Route path="/Porttemplate" element={<PortTemplate />} />
      <Route path="/Sourcetemplate" element={<SourceTemplate />} />
      <Route path="/Clienttemplate" element={<ClientTemplate />} />
      <Route path="/Authtemplate" element={<AuthTemplate />} />
      <Route path="/Banktemplate" element={<BankTemplate />} />;
      <Route path="/Citytemplate" element={<CityTemplate />} />
      <Route path="/Countrytemplate" element={<CountryTemplate />} />
      <Route path="/Customertemplate" element={<CustomerTemplate />} />
      <Route
        path="/Equipmentcontainertemplate"
        element={<EquipmentContainerTemplate />}
      />
      <Route path="/Gsttemplate" element={<GstTemplate />} />
      <Route path="/Industrytemplate" element={<IndustryTemplate />} />
      <Route path="/Numrangetemplate" element={<NumrangeTemplate />} />
      <Route
        path="/Operationuserregiontemplate"
        element={<OperationuserregionTemplate />}
      />
      <Route path="/PackingTytemplatepe" element={<PackingTypeTemplate />} />
      <Route path="/Roletemplate" element={<RoleTemplate />} />
      <Route path="/Roleauthtemplate" element={<RoleauthTemplate />} />
      <Route path="/Shippinglinetemplate" element={<ShippinglineTemplate />} />
      <Route path="/Statetemplate" element={<StateTemplate />} />
      <Route path="/Tagtemplate" element={<TagTemplate />} />
      <Route path="/Tiertemplate" element={<TierTemplate />} />
      <Route path="/Userregiontemplate" element={<UserregionTemplate />} />
      <Route path="/Vendortemplate" element={<VendorTemplate />} />
      <Route path="/Configtemplate" element={<ConfigTemplate />} />
    </Routes>
  );
}
