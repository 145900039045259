import React, { useState, useEffect, useRef } from "react";
import Axios, { AuthAPIInstance } from "../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const toastRef = useRef();
  const token = localStorage.getItem("token");

  const [isValidUser, setIsValidUser] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!token) {
          setIsValidUser(false);
          navigate("/");
        }
        const { data } = await AuthAPIInstance.get("/token", {
          headers: { Authorization: JSON.parse(token) },
        });

        if (data.success) {
          setIsValidUser(true);
        } else {
          navigate("/");
        }
      } catch (error) {
        if (error.response?.data === "Unauthorized" || error.status === 500) {
          navigate("/");
        }
      }
    };
    getData();
  }, []);

  return <div>{isValidUser && children}</div>;
};

export default ProtectedRoute;
// import React, { useState, useEffect, useRef } from "react";
// import Axios, { AuthAPIInstance } from "../api-instance";
// import { useToast } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";

// const ProtectedRoute = ({ children }) => {
//   const toast = useToast();
//   const navigate = useNavigate();

//   const token = localStorage.getItem("token");

//   const [isValidUser, setIsValidUser] = useState(false);

//   useEffect(() => {
//     const getData = async () => {
//       try {
//         if (!token) {
//           setIsValidUser(false);
//           navigate("/");
//           return;
//         }

//         const { data } = await AuthAPIInstance.get("/token", {
//           headers: { Authorization: token },
//         });

//         if (data.success) {
//           setIsValidUser(true);
//         } else {
//           navigate("/");
//         }
//       } catch (error) {
//         console.error("Error fetching token validation:", error);
//         if (
//           error.response?.data === "Unauthorized" ||
//           error.response?.status === 500
//         ) {
//           toast({
//             title: "Session expired.",
//             description: "Please log in again.",
//             status: "warning",
//             duration: 5000,
//             isClosable: true,
//             containerStyle: {
//               width: "98.5vw",
//               maxWidth: "98.5vw",
//               alignContent: "center",
//             },
//           });
//           navigate("/");
//         }
//       }
//     };
//     getData();
//   }, [token, navigate, toast]);

//   return <>{isValidUser && children}</>;
// };

// export default ProtectedRoute;
