export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    onOpenReportDelete,
    onOpenMassupdate,
    onOpenPrint
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.onOpenReportDelete = onOpenReportDelete;
    this.onOpenMassupdate = onOpenMassupdate;
    this.onOpenPrint = onOpenPrint;
  }
  handleEditClickMassdelete() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    this.onOpenReportDelete();
  }

  handleEditClickMassupdate() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenMassupdate();
  }

  handleEditPrint() {

    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for print.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
  
    if (this.checkBoxSelected.length > 1) {
      console.log("No row selected for print.");
      this.toast({
        title: "Please select one row",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },   
      });
      return;
    }

    // if (this.checkBoxSelected.length === 0) {
    //   console.log("No row selected for print.");
    //   this.toast({
    //     title: "Please select a row",
    //     position: "bottom-center",
    //     isClosable: true,
    //     status: "error",
    //     containerStyle: {
    //       width: "98.5vw",
    //       maxWidth: "98.5vw",
    //       alignContent: "center",
    //     },
    //   });
    //   return;
    // }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenPrint();
  }

  

}
