import * as XLSX from "xlsx";

export class Massupload {
  constructor(setExcelOpen, setMovies) {
    this.setExcelOpen = setExcelOpen;
    this.setMovies = setMovies;
    this.excelOpen = false;
  }
// Static method to read Excel file data
  static read(fileData) {
    return XLSX.read(fileData, { type: "array" });
  }
// Handles importing Excel data
  handleImport($event) {
    if (!this.excelOpen) {
      const files = $event.target.files;
      if (files?.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const wb = Massupload.read(event.target.result); // Use the static method
          const sheets = wb.SheetNames;

          if (sheets?.length) {
            const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
            const processedRows = rows.map((row) => {
              const { PortId, ...rest } = row;
              return rest;
            });
            console.log({ processedRows });
            this.setMovies(processedRows);
          }
        };
        reader.readAsArrayBuffer(file);
      }
      this.setExcelOpen(true);
    }
  }
  // Handles closing the Excel import dialog
  handleCloseExcel() {
    this.setExcelOpen(false);
  }
}
