import { React, useState } from "react";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import Axios, { OperationDocumentAPIInstance } from "../../api-instance";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Card,
  CardBody,
  CardFooter,
  Heading,
  CardHeader,
  Button,
  Text,
} from "@chakra-ui/react";
import { Header } from "../../components";
const UploadDocument = () => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("DocumentUpload", selectedJob);
  const toast = useToast();
  const navigate = useNavigate();
  const formData = new FormData();
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handlePageRefresh = () => {
    window.location.reload();
  };
  const handleUploadFile = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const formData = new FormData();
      formData.append("file", file);
      formData.append("BookingNumber", selectedJob.BookingNumber);
      formData.append("JobNumber", selectedJob.JobNumber);
      formData.append("JobInfono", selectedJob.JobInfono);
      formData.append("JobOwner", user.userId);
      const response = await OperationDocumentAPIInstance.post(
        `single_upload_document?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log(response.data);

      const data = response.data;
      if (data.message === "Document Upload successfully.") {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      handlePageRefresh();
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  // navigate('/opertion_docs', { state: { selectedJob: selectedJob } });

  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  //   navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
  // };
  return (
    <>
      <Header />

      <Card align="center" mt={10}>
        <CardHeader>
          <Heading size="md">Upload Document</Heading>
        </CardHeader>
        <CardBody>
          {/* <Text>
            Attach any related documents.
            </Text> */}
        </CardBody>
        <FormControl>
          {/* <FormLabel>Files</FormLabel> */}
          <Input
            type="file"
            onChange={handleFileChange}
            name="file"
            multiple
            sx={{
              "::file-selector-button": {
                height: 10,
                padding: 0,
                mr: 4,
                background: "none",
                border: "none",
                fontWeight: "bold",
                color: "red",
              },
            }}
            width={500}
          />
          <FormHelperText
            style={{
              textAlign: "center",
              fontSize: "14pt",
              fontFamily: "sans-serif",
            }}
          >
            <span style={{ color: "red" }}>*</span>Attach any related documents.
          </FormHelperText>
        </FormControl>
        <CardFooter>
          <Button
            colorScheme="red"
            size="md"
            // onClick={handleUploadFile}
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            leftIcon={<IoDocumentAttachOutline size={20} />}
            colorScheme="teal"
            aria-label="Export here"
            size="md"
            // onClick={handleUploadFile}
            onClick={() => {
              handleUploadFile();
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
          >
            Upload Document
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default UploadDocument;
