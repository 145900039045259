// import { BsArrowRight } from "react-icons/bs";
// import { FaTasks, FaPause, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// export default function TotalCount({
//   inProgressCount = 0,
//   holdCount = 0,
//   completedCount = 0,
//   cancelledCount = 0,
//   selected,
// }) {
//   const navigate = useNavigate();
//   const path = selected === "jobs" ? "/jobs" : "/booking";
//   return (
//     <div className="flex flex-wrap gap-6 justify-between">
//       <Card
//         text="In Progress"
//         value={inProgressCount}
//         icon={<FaTasks />}
//         bgColor="bg-gradient-to-r from-blue-400 via-blue-300 to-blue-500"
//       />
//       <Card
//         text="On Hold"
//         value={holdCount}
//         icon={<FaPause />}
//         bgColor="bg-gradient-to-r from-orange-300 via-orange-400 to-orange-500"
//       />
//       <Card
//         text="Completed"
//         value={completedCount}
//         icon={<FaCheckCircle />}
//         bgColor="bg-gradient-to-r from-teal-300 via-blue-300 to-teal-400"
//       />
//       <Card
//         text="Cancelled"
//         value={cancelledCount}
//         icon={<FaTimesCircle />}
//         bgColor="bg-gradient-to-r from-red-300 via-orange-300 to-red-400"
//       />
//     </div>
//   );
// }

// function Card({ text, value, icon, bgColor }) {
//   return (
//     <div
//       className={`flex flex-col items-center justify-center w-[240px] h-[140px] text-white rounded-xl bg-gradient-to-r ${bgColor} shadow-lg p-5`}
//     >
//       <div className="text-4xl mb-3">{icon}</div>
//       <div className="text-xl font-medium">{text}</div>
//       <div className="text-3xl font-bold mt-3">{value}</div>
//     </div>
//   );
// }

import { BsArrowRight } from "react-icons/bs";
import { Card } from "./Cards";
import { useNavigate } from "react-router-dom";

export default function TotalCount({
  totalCount = 0,
  currentCount = 0,
  totalConverted = 0,
  totalDeleted = 0,
  selected,
}) {
  const navigate = useNavigate();
const path = selected === "jobs" ? "/jobs" : "/operation_job"

  return (
    <Card>
      <div className="flex flex-wrap justify-between mr-2">
        <div className="flex gap-4 items-center text-medium text-black text-left">
          Total number of {selected}:{" "}
          <span className="text-heading3 font-semibold">{totalCount}</span>
          <BsArrowRight
            onClick={() => navigate(path)}
            className="text-2xl text-blue-sky cursor-pointer"
          />
        </div>

        <ShowData text="Open" value={currentCount} selectedScreen={selected} />
        {selected !== "Prospects" && (
          <ShowData
            text="Total Converted"
            value={totalConverted}
            selectedScreen={selected}
          />
        )}
        <ShowData
          text="Total Deleted"
          value={totalDeleted}
          selectedScreen={selected}
        />
      </div>
    </Card>
  );
}

function ShowData({ text, value, selectedScreen }) {
  return (
    <div className="flex gap-4 items-center text-base text-black text-left">
      {text} {selectedScreen}: <span className="text-heading3 ">{value}</span>
    </div>
  );
}

