import { React, useState } from "react";
import MXM from "../../assets/img/MXM.mp4";
import { useNavigate } from "react-router-dom";
import Axios, { AuthAPIInstance } from "../../api-instance";
import { Tooltip, useToast } from "@chakra-ui/react";
import bcrypt from "bcryptjs";
import "../../assets/css/login.css";
import { isEmpty } from "lodash";
import { BiShow, BiHide } from "react-icons/bi";

const Emailverify = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [page, setPage] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailOrUserName, setEmailOrUserName] = useState("");
  const [isGenerateOtpClicked, setIsGenerateOtpClicked] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const toast = useToast();
  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const hashedPassword = bcrypt.hashSync(
    newPassword,
    "$2a$10$CwTycUXWue0Thq9StjUM0u"
  ); // hash created previously created upon sign up

  const otpSending = async (e) => {
    e.preventDefault();
    if (!isEmpty(emailOrUserName)) {
      try {
        const { data } = await AuthAPIInstance.post(
          "/otpsend/send_otp",
          { emailOrUserName },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        if (data.message === "USER_DOES_NOT_EXIST") {
          setEmailError("Invalid Username or Email");
          setErrorFlag(true);
        } else {
          setIsGenerateOtpClicked(true);
          setEmail(data.data.email);
          setEmailError("OTP sent successfully");
          setErrorFlag(false);
        }
      } catch (error) {
        setEmailError(error);
        setErrorFlag(true);
      }
    } else {
      setEmailError("Please enter the values");
      setErrorFlag(true);
    }
  };

  const otpCheck = async (e) => {
    setEmailError(null);
    e.preventDefault();

    if (!isEmpty(otp)) {
      const { data } = await AuthAPIInstance.post(
        "/otpsend/otp_validation",
        { otp, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data.message === "Falied request, OTP expired") {
        setOtpError("Falied request, OTP expired");
        setErrorFlag(true);
      } else if (data.message === "Falied request, OTP doesn't match") {
        setOtpError("OTP not matching, try again");
        setErrorFlag(true);
      } else {
        toast({
          title: "OTP verified successfully",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setOtpError("OTP verified successfully");
        setErrorFlag(false);
        setPage(1);
      }
    } else {
      setOtpError("Please enter OTP");
      setErrorFlag(true);
    }
  };

  const submitConfirmPassword = async (e) => {
    setOtpError(null);
    e.preventDefault();
    let pwdCheck = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
    };

    const isValidPwd =
      pwdCheck.capital.test(newPassword) &&
      pwdCheck.length.test(newPassword) &&
      pwdCheck.specialChar.test(newPassword) &&
      pwdCheck.digit.test(newPassword);

    if (isValidPwd === true) {
      if (confirmPassword !== newPassword) {
        setPasswordError("passwords given are not matching, try again");
        setErrorFlag(true);
        setPage(1);
      } else if (isEmpty(confirmPassword) || isEmpty(newPassword)) {
        setPasswordError("Please enter the password");
        setErrorFlag(true);
        setPage(1);
      } else {
        const { data } = await AuthAPIInstance.post(
          "/otpsend/update_password",
          { hashedPassword, email },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data.message === "success") {
          toast({
            title: "Password changed successfully",
            position: "bottom-right",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setErrorFlag(false);
          navigate("/");
        } else {
          setPage(1);
          setPasswordError("Cannot be able to update new password, try again");
          setErrorFlag(true);
        }
      }
    } else {
      setPasswordError("Please enter the password in the specified format");
      setErrorFlag(true);
    }
  };

  return (
    //   <>
    //     <div className="p-4 flex flex-shrink w-full bg-slate-200">
    //       <div className="rounded-2xl flex flex-shrink shadow-md sm:flex-row flex-col w-full">
    //         <div className="flex flex-1 w-full ">
    //           <div className="container-control">
    //             <div className="rotating-text-wrapper">
    //               <h2>Welcome to Matrix Mindz</h2>
    //             </div>
    //             <video className="h-screen video-control" autoPlay muted loop>
    //               <source src={MXM} type="video/mp4" />
    //             </video>
    //           </div>
    //         </div>
    //         {page == 0 && (
    //           <div className="flex flex-1 flex-col justify-evenly min-w-0">
    //             <label className="ml-2 text-2xl font-medium">
    //               Account Recovery
    //             </label>
    //             <div className="px-8 md:px-16">
    //               <form className="font-sans text-sm rounded w-full max-w-xs mx-auto pb-32">
    //                 <br />
    //                 <div className="mb-2">
    //                   Enter your email or username and we'll send you a OTP to get
    //                   back into your account
    //                 </div>
    //                 <br />
    //                 <div className="relative border rounded mb-1 shadow appearance-none">
    //                   <input
    //                     className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
    //                     type="text"
    //                     placeholder="Enter your Email or Username"
    //                     id="emailOrUserName"
    //                     value={emailOrUserName}
    //                     disabled={isGenerateOtpClicked == true ? "disabled" : ""}
    //                     onChange={(e) => setEmailOrUserName(e.target.value)}
    //                   />
    //                 </div>
    //                 <p style={{ color: errorFlag == true ? "red" : "green" }}>
    //                   {" "}
    //                   {emailError}{" "}
    //                 </p>
    //                 <br />
    //                 <div className="text-xs  text-[#002D74] mb-12">
    //                   <br />
    //                   <button
    //                     type="submit"
    //                     onClick={otpSending}
    //                     className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
    //                   >
    //                     Generate OTP
    //                   </button>
    //                 </div>

    //                 <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]"></div>
    //                 <div className="mb-2">Enter your OTP</div>
    //                 <div className="mb-2">
    //                   <input
    //                     className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
    //                     type="text"
    //                     placeholder="Enter OTP"
    //                     disabled={isGenerateOtpClicked == false ? "disabled" : ""}
    //                     id="OTP"
    //                     value={otp}
    //                     onChange={(e) => setOtp(e.target.value)}
    //                   />
    //                 </div>
    //                 <p style={{ color: errorFlag == true ? "red" : "green" }}>
    //                   {" "}
    //                   {otpError}{" "}
    //                 </p>
    //                 <div className="text-xs  text-[#002D74]">
    //                   <br />
    //                   <button
    //                     type="submit"
    //                     onClick={otpCheck}
    //                     disabled={isGenerateOtpClicked == false ? "disabled" : ""}
    //                     className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
    //                   >
    //                     Continue
    //                   </button>
    //                 </div>
    //               </form>
    //             </div>
    //           </div>
    //         )}
    //         {page == 1 && (
    //           <div className="flex flex-1 flex-col justify-evenly  p-3.5 min-w-0">
    //             <label className="ml-2 text-2xl font-medium">
    //               Account Recovery
    //             </label>
    //             <div className="px-8 md:px-16">
    //               <form className="font-sans text-sm rounded w-full max-w-xs mx-auto pb-8">
    //                 <label className="ml-2 text-md font-medium whitespace-nowrap">
    //                   <i>Create a Strong Password </i>
    //                 </label>
    //                 <br />
    //                 <ul className="mb-2">
    //                   <li>Password should contains one Capital letter</li>
    //                   <li>Password should contains one Special character</li>
    //                   <li>Password should contains one Number</li>
    //                   <li>Length of password should be atleast 7</li>
    //                 </ul>
    //                 <br />
    //                 <div className="relative border rounded mb-4 shadow appearance-none label-floating">
    //                   <label>Enter your New Password</label>
    //                   <input
    //                     className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
    //                     type={isPasswordVisible ? "text" : "password"}
    //                     placeholder="New password"
    //                     id="newPassword"
    //                     value={newPassword}
    //                     onChange={(e) => setNewPassword(e.target.value)}
    //                   />
    //                 </div>
    //                 <div className="relative border rounded mb-4 shadow appearance-none label-floating">
    //                   <label>Enter your Confirm Password</label>
    //                   <input
    //                     className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
    //                     type={isPasswordVisible ? "text" : "password"}
    //                     placeholder="New password, again"
    //                     id="confirmPassword"
    //                     value={confirmPassword}
    //                     onChange={(e) => setConfirmPassword(e.target.value)}
    //                   />
    //                   {isPasswordVisible ? (
    //   <BiHide
    //     size={20}
    //     className="cursor-pointer eye-slash absolute top-10 right-3 -translate-y-1/2"
    //     onClick={handleTogglePasswordVisibility}
    //   />
    // ) : (
    //   <BiShow
    //     size={20}
    //     className="cursor-pointer eye-slash absolute top-10 right-3 -translate-y-1/2 "
    //     onClick={handleTogglePasswordVisibility}
    //   />
    // )}
    //                 </div>
    //                 <p style={{ color: errorFlag == true ? "red" : "green" }}>
    //                   {" "}
    //                   {passwordError}{" "}
    //                 </p>
    //                 <br />
    //                 <div className="text-xs  text-[#002D74]">
    //                   <br />
    //                   <button
    //                     type="submit"
    //                     className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
    //                     onClick={submitConfirmPassword}
    //                   >
    //                     Submit
    //                   </button>
    //                 </div>
    //               </form>
    //               <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]"></div>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </>

    // new UI
    <div className="flex w-screen flex-wrap text-slate-800">
      <div className="relative hidden h-screen select-none flex-col justify-center bg-blue-600 text-center md:flex md:w-1/2">
        <div className="mx-auto py-16 px-8 text-white xl:w-[40rem]">
          {/* <span className="rounded-full bg-white px-3 py-1 font-medium text-blue-600">
        New Feature
      </span> */}
         <p className="my-6 text-7xl font-serif leading-10">
              EON <br />
              <br />
              MatrixMindz
              {/* <span className="mx-auto block w-56 whitespace-nowrap rounded-lg bg-orange-400 py-2 text-white">
              MatrdixMindz
            </span> */}
            </p>
            {/* <p className="my-6 text-1xl font-serif leading-10">
              "No Guts No Glory"
            </p> */}
            <p className="my-6 text-1xl font-serif leading-10">
              &copy; {currentYear},EON MatrixMindz.
            </p>
          {/* <img src={login} alt="MatrixMindz" /> */}
          {/* <p className="mb-4">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt
        necessitatibus nostrum repellendus ab totam.
      </p>
      <a
        href="#"
        className="font-semibold tracking-wide text-white underline underline-offset-4"
      >
        Learn More
      </a> */}
        </div>
        {/* <img className="mx-auto w-11/12 max-w-lg rounded-lg object-cover" src="/images/SoOmmtD2P6rjV76JvJTc6.png" /> */}
      </div>
      <div className="flex w-full flex-col md:w-1/2">
        <div className="flex justify-center pt-12 md:justify-start md:pl-12">
          <a href="#" className="text-2xl font-bold text-blue-600">
            {" "}
            MatrixMindz CRM{" "}
          </a>
        </div>
        {page == 0 && (
          <div className="my-auto mx-auto flex flex-col justify-center px-6 pt-1 md:justify-start lg:w-[28rem]">
            <p className="text-center text-3xl font-bold md:text-center md:leading-tight">
              Account Recovery
            </p>
            <p className="mt-6 text-center font-medium md:text-center">
              Enter your email or username and we'll send you a OTP to get back
              into your account
            </p>

            <form className="flex flex-col items-stretch pt-3 md:pt-8">
              <div className="mb-4 flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    type="text"
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Email or Username"
                    id="emailOrUserName"
                    value={emailOrUserName}
                    disabled={isGenerateOtpClicked == true ? "disabled" : ""}
                    onChange={(e) => setEmailOrUserName(e.target.value)}
                  />
                </div>
              </div>
              <p style={{ color: errorFlag == true ? "red" : "green" }}>
                {" "}
                {emailError}{" "}
              </p>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <button
                  type="submit"
                  onClick={otpSending}
                  className="mt-6 mr-20 rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-40"
                >
                  Generate OTP
                </button>
              </div>
              <div className="mb-4 flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    type="text"
                    disabled={isGenerateOtpClicked == false ? "disabled" : ""}
                    id="OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Enter you OTP"
                  />
                </div>
              </div>
              <p style={{ color: errorFlag == true ? "red" : "green" }}>
                {" "}
                {otpError}{" "}
              </p>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <button
                  type="submit"
                  onClick={otpCheck}
                  disabled={isGenerateOtpClicked == false ? "disabled" : ""}
                  className="mt-6 mr-20 rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-40"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        )}
        {page == 1 && (
          <div className="my-auto mx-auto flex flex-col justify-center px-6 pt-1 md:justify-start lg:w-[28rem]">
            <p className="text-center text-3xl font-bold md:text-center md:leading-tight">
              Account Recovery
            </p>
            <p className="mt-6 text-center font-medium md:text-center">
              Create a Strong Password
            </p>
            <ul className="mb-2">
              <li>Password should contains one Capital letter</li>
              <li>Password should contains one Special character</li>
              <li>Password should contains one Number</li>
              <li>Length of password should be atleast 7</li>
            </ul>
            <form className="flex flex-col items-stretch pt-3 md:pt-8">
              <div className="mb-4 flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Enter your New Password"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Enter your Confirm Password"
                  />
                  <span onClick={handleShowPassword}>
                    {isPasswordVisible ? (
                      <BiHide
                        className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                        size={20}
                      />
                    ) : (
                      <BiShow
                        className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                        size={20}
                      />
                    )}
                  </span>
                </div>
              </div>
              <p style={{ color: errorFlag == true ? "red" : "green" }}>
                {" "}
                {passwordError}{" "}
              </p>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <button
                  type="submit"
                  onClick={submitConfirmPassword}
                  className="mt-6 mr-20 rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-40"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Emailverify;
