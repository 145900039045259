/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { EnquiryAPIInstance, AuthAPIInstance, MasterDataAPIInstance } from "../../api-instance.js";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
import { useLocation } from "react-router-dom";
// External module
// import Axios, { EnquiryAPIInstance } from "../../api-instance.js";
import "../../assets/css/container.css";
import { DraggableEnquiryTable } from "../../components";
import { Header } from "../../components";
// Internal modules
import "./Enquiry.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { Container, useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";


import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import
import { MdDownloading } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BiShow, BiHide } from "react-icons/bi";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BsFiletypeExe } from "react-icons/bs";
import { IoMdPrint } from "react-icons/io";
import AddUnallocatedProspect from "../AddEnquiry/AddUnallocatedProspect.js";
const Enquiry = () => {
  // State Variables
  const navigate = useNavigate();
  const deleterecordshowhide = true;
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [size, setSize] = React.useState("xl");
  const location = useLocation();
  //   const {  PNumber } = location.state || {};
  //   const{ CNumber }=location.state || {};
  //  console.log("PN:",PNumber," CN: ",CNumber);
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassUpload,
    onOpen: onOpenReportMassUpload,
    onClose: onCloseReportMassUpload,
  } = useDisclosure();


  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();


 
  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();


  const cancelRef = React.useRef();
  const searchRef = useRef(null);
  const editorRef = useRef(null);
  const [enquirynumber, setEnquiryNumber] = useState([]);
  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [unallocatedBookingState, setUnallocatedBookingState] = useState([]);
  const [formData, setFormData] = useState([
    {
      ProspectNumber: "",
      CustomerNumber:"",
      // Company: "",
      ContactPerson: "",
      Email: "",
      DialCode: "",
      Phone: "",
      City: "",
      Country: "",
      Industry: "",
      Tier: "",
      Company:"",
      Tags: "",
      Remarks_sales: "",
      Remarks_pricing:"",      
      Enquiry: "",
      Shipment: "",
      Invoices: "",
      Attachments: "",
      Containers:"Dimensions: , Weight: , Quantity: , Equipment: ,",
      FollowUpDate: "",
      // CustomerOwner: "",
      EnquiryOwner: "",
      DeletionFlag: "",
      ConversionFlag:"",
      Address: "",
      Region: "",
      Source: "",
      // HSCode:"",
      // Dimensions:"",
      // Weight:"",
      FollowUpStatus: "",
      NotificationDays: "",
      FollowUpInterval: "",
      QuotationNumber: "",
      RateNumber: "",
      LeadNumber: "",
      PricingTeam: [],
    },
  ]);
  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);


  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    EnquiryNumber: true,
    Mode: true,
    OriginCountry: true,
    DestinationCountry: true,
    PickUpLocation: true,
    OriginPortAirport: true,
    DestinationPortAirport: true,
    DeliveryLocation: true,
    EnquiryDescription: true,
    Incoterm: true,
    Commodity: true,
    HSCode: true,
    // Dimensions: true,
    // Weight: true,
    Scope: true,
    FreeTime: true,
    Equipment: true,
    Stackable: true,
    Rates_Status: true,
    DGNonDG: true,
    PackingType: true,
    CargoReadiness: true,
    Attachments: true,
    Containers: true,
    ShipperResponsibility: true,
    Status: true,
    Tier: true,
    Company: true,
    Remarks_sales: true,
    Remarks_pricing:true,
    PricingTeam: true,
    EnquiryOwner: true,   
    ConversionFlag: true,
    DeletionFlag: true,
    EnquiryOwnerName: true,
    ProspectNumber: true,
    CustomerNumber: true,
    QuotationNumber: true,
    RateNumber: true,
  });
  const [tableColumnsEnquiry, settableColumnsEnquiry] = useState([]);


  useEffect(() => {
    getData();
  }, [isdelete]); // isdelete state changes


  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };


  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);


    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(exportData, "Enquiry");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Enquiry");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Enquiry");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Enquiry");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };
  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArray = data.map((item) => [
      item.EnquiryNumber,
      item.Mode,
      item.OriginCountry,
      item.DestinationCountry,
      item.PickUpLocation,
      item.OriginPortAirport,
      item.DestinationPortAirport,
      item.DeliveryLocation,
      item.EnquiryDescription,
      item.Incoterm,
      item.Commodity,
      item.HSCode,
      // item.Dimensions,
      // item.Weight,
      item.Scope,
      item.FreeTime,
      item.Equipment,
      item.Stackable,
      item.Rates_Status,
      item.DGNonDG,
      item.PackingType,
      item.CargoReadiness,
      item.Attachments,
      item.Containers,
      item.ShipperResponsibility,
      item.Status,
      item.Grade,
      item.Remarks_sales,
      item.Remarks_pricing,
      item.PricingTeam,
      item.EnquiryOwner,
      item.EnquiryOwnerName,
      item.ProspectNumber,
      item.CustomerNumber,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "EnquiryNumber",
        "Mode",
        "OriginCountry",
        "DestinationCountry",
        "PickUpLocation",
        "OriginPortAirport",
        "DestinationPortAirport",
        "DeliveryLocation",
        "EnquiryDescription",
        "Incoterm",
        "Commodity",
        "HSCode",
        // "Dimensions",
        // "Weight",
        "Scope",
        "FreeTime",
        "Equipment",
        "Stackable",
        "Rates_Status",
        "DGNonDG",
        "PackingType",
        "CargoReadiness",
        "Attachments",
        "Containers",
        "ShipperResponsibility",
        "Status",
        "Grade",
        "Remarks_sales",
        "Remarks_pricing",
        "PricingTeam",
        "EnquiryOwner",
        "EnquiryOwnerName",
        "ProspectNumber",
        "CustomerNumber",
      ],
      ...dataArray,
    ]);


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enquirys");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;


    saveAs(dataBlob, finalFileName);
  };
  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.EnquiryNumber,
      item.Mode,
      item.OriginCountry,
      item.DestinationCountry,
      item.PickUpLocation,
      item.OriginPortAirport,
      item.DestinationPortAirport,
      item.DeliveryLocation,
      item.EnquiryDescription,
      item.Incoterm,
      item.Commodity,
      item.HSCode,
      // item.Dimensions,
      // item.Weight,
      item.Scope,
      item.FreeTime,
      item.Equipment,
      item.Stackable,
      item.Rates_Status,
      item.DGNonDG,
      item.PackingType,
      item.CargoReadiness,
      item.Attachments,
      item.Containers,
      item.ShipperResponsibility,
      item.Status,
      item.Grade,
      item.Remarks_sales,
      item.Remarks_pricing,
      item.PricingTeam,
      item.EnquiryOwner,
      item.EnquiryOwnerName,
      item.ProspectNumber,
      item.CustomerNumber,
    ]);


    const csvContent = [
      [
        "EnquiryNumber",
        "Mode",
        "OriginCountry",
        "DestinationCountry",
        "PickUpLocation",
        "OriginPortAirport",
        "DestinationPortAirport",
        "DeliveryLocation",
        "EnquiryDescription",
        "Incoterm",
        "Commodity",
        "HSCode",
        // "Dimensions",
        // "Weight",
        "Scope",
        "FreeTime",
        "Equipment",
        "Stackable",
        "Rates_Status",
        "DGNonDG",
        "PackingType",
        "CargoReadiness",
        "Attachments",
        "Containers",
        "ShipperResponsibility",
        "Status",
        "Grade",
        "Remarks_sales",
        "Remarks_pricing",
        "PricingTeam",
        "EnquiryOwner",
        "EnquiryOwnerName",
        "ProspectNumber",
        "CustomerNumber",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");


    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";


    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });


    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;


    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code


  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";


    const doc = new jsPDF(orientation, unit, size);
    const tableColumnsEnquiry = [
      "EnquiryNumber",
      "Mode",
      "OriginCountry",
      "DestinationCountry",
      "PickUpLocation",
      "OriginPortAirport",
      "DestinationPortAirport",
      "DeliveryLocation",
      "EnquiryDescription",
      "Incoterm",
      "Commodity",
      "HSCode",
      // "Dimensions",
      // "Weight",
      "Scope",
      "FreeTime",
      "Equipment",
      "Stackable",
      "Rates_Status",
      "DGNonDG",
      "PackingType",
      "CargoReadiness",
      "Attachments",
      "Containers",
      "ShipperResponsibility",
      "Status",
      "Grade",
      "Remarks_sales",
      "Remarks_pricing",
      "PricingTeam",
      "EnquiryOwner",
      "EnquiryOwnerName",
      "ProspectNumber",
      "CustomerNumber",
    ];


    const tableRows = data.map((item) => {
      return tableColumnsEnquiry.map((column) => item[column]);
    });


    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;


    doc.setFontSize(titleFontSize);


    const title = "Enquirys Report";
    doc.text(title, marginLeft, marginTop);


    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumnsEnquiry],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };


    doc.autoTable(tableOptions);


    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;


    doc.save(finalFileName);
  };


  const exportToText = (data, fileName) => {
    const tableColumnsEnquiry = [
      "EnquiryNumber",
      "Mode",
      "OriginCountry",
      "DestinationCountry",
      "PickUpLocation",
      "OriginPortAirport",
      "DestinationPortAirport",
      "DeliveryLocation",
      "EnquiryDescription",
      "Incoterm",
      "Commodity",
      "HSCode",
      // "Dimensions",
      // "Weight",
      "Scope",
      "FreeTime",
      "Equipment",
      "Stackable",
      "Rates_Status",
      "DGNonDG",
      "PackingType",
      "CargoReadiness",
      "Attachments",
      "Containers",
      "ShipperResponsibility",
      "Status",
      "Grade",
      "Remarks_sales",
      "Remarks_pricing",
      "PricingTeam",
      "EnquiryOwner",
      "EnquiryOwnerName",
      "ProspectNumber",
      "CustomerNumber",
    ];


    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });


    const textContent = [tableColumnsEnquiry.join(",")]
      .concat(tableRows)
      .join("\n");


    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";


    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };


  const handleCheckboxColumn = (event, item) => {
    settableColumnsEnquiry((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });


    const newData = tableColumnsEnquiry.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );


    localStorage.setItem("tableColumnsEnquiry", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };


  //get timezone api
  // Timezone API
  const [timezoneData, setTimezoneData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get(`get_timezone`);
        setTimezoneData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Admin API
  const [userdata, setUserdata] = useState([]);


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await AuthAPIInstance.get(
          `user/get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );


        const userDataList = response?.data?.data || [];
        setUserdata(userDataList);
      } catch (error) {
        // Handle error
      }
    };


    getData();
  }, []);


  // Extracting the roles from user data
  const userRoles = userdata.map((user) => user.Role);


  // Check if the user has the 'Admin' role
  const isAdmin = userRoles.includes("Admin");
  // End Admin API


  //get api
  //  const getData = async (ProspectNumber, CustomerNumber) => {
  //   try {
  //     setIsLoading(true);
  //     let apiUrl = "";
  //     if (PNumber !== undefined) {
  //       apiUrl = `/enquirys/get_viewenquiry?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}&col=ProspectNumber&no=${PNumber}`;
  //     } else if (CNumber !== undefined) {
  //       apiUrl = `/enquirys/get_viewenquiry?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}&col=CustomerNumber&no=${CNumber}`;
  //     } else {
  //       apiUrl = `/enquirys/get_enquiry?userId=${JSON.parse(localStorage.getItem("user")).userId}&isDeleted=${isdelete}`;
  //     }


  //     const { data } = await Axios.get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: JSON.parse(localStorage.getItem("token")),
  //       },
  //     });
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await EnquiryAPIInstance.get(
        `get_enquiry?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeleted=${isdelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setEnquiryData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "DestinationPortAirport	"
              ? false
              : item === "DeliveryLocation"
              ? false
              : item === "EnquiryDescription"
              ? false
              : item === "EnquiryOwner"
              ? false
              : item === "EnquiryOwnerName"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Commodity"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : item === "HSCode"
              ? false
              // : item === "Dimensions"
              // ? false
              // : item === "Weight"
              // ? false
              : item === "Scope"
              ? false
              : item === "FreeTime"
              ? false
              : item === "Equipment"
              ? false
              : item === "Attachments"
              ? false
              : item === "Containers"
              ? false
              : item === "Stackable"
              ? false
              : item === "Rates_Status"
              ? false
              : item === "DGNonDG"
              ? false
              : item === "PackingType"
              ? false
              : item === "CargoReadiness"
              ? false
              : item === "ShipperResponsibility"
              ? false
              : item === "Status"
              ? false
              : item === "Grade"
              ? false
              : item === "Remarks_sales"
              ? false
              : item === "Remarks_pricing"
              ? false
              : item === "PricingTeam"
              ? false
              : item === "ProspectNumber"
              ? false
              : item === "CustomerNumber"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });


        return filteredItem;
      });
      setEnquiryData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (window.localStorage.getItem("enquiryfilter")?.length === 0) {
      return setEnquiryData([]);
    }


    if (window.localStorage.getItem("enquiryfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);


  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  // const handleNavigate = () => {
  //   navigate("/addenquiry", { state: { PNumber: PNumber , CNumber: CNumber  } });
  // };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("enquiryfilterRows"))
      ? JSON.parse(localStorage.getItem("enquiryfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("enquiryfilterConditions")
    );
    return savedConditions || {};
  });


  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };


  useEffect(() => {
    localStorage.setItem("enquiryfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "enquiryfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);


  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);


    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };


  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "enquiryfilterConditions",
      JSON.stringify(filterConditions)
    );


    const transformedConditions = {};


    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;


      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });


    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await EnquiryAPIInstance.post(
        `mass_filter?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("enquiryfilter", JSON.stringify([]));
        return setEnquiryData([]);
      }


      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "DestinationPortAirport	"
              ? false
              : item === "DeliveryLocation"
              ? false
              : item === "EnquiryDescription"
              ? false
              : item === "EnquiryOwner"
              ? false
              : item === "EnquiryOwnerName"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Commodity"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : item === "HSCode"
              ? false
              // : item === "Dimensions"
              // ? false
              // : item === "Weight"
              // ? false
              : item === "Scope"
              ? false
              : item === "FreeTime"
              ? false
              : item === "Equipment"
              ? false
              : item === "Attachments"
              ? false
              : item === "Containers"
              ? false
              : item === "Stackable"
              ? false
              : item === "Rates_Status"
              ? false
              : item === "DGNonDG"
              ? false
              : item === "PackingType"
              ? false
              : item === "CargoReadiness"
              ? false
              : item === "ShipperResponsibility"
              ? false
              : item === "Status"
              ? false
              : item === "Grade"
              ? false
              : item === "Remarks_sales"
              ? false
              : item === "Remarks_pricing"
              ? false
              : item === "PricingTeam"
              ? false
              : item === "ProspectNumber"
              ? false
              : item === "CustomerNumber"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });


      setEnquiryData(filteredData);


      localStorage.setItem("enquiryfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };


  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };


  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("enquiryfilter")),
      };


      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setEnquiryData([]);
      }


      const array1 = Object.keys(columns).filter((key) => columns[key]);


      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "DestinationPortAirport	"
              ? false
              : item === "DeliveryLocation"
              ? false
              : item === "EnquiryDescription"
              ? false
              : item === "EnquiryOwner"
              ? false
              : item === "EnquiryOwnerName"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Commodity"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : item === "HSCode"
              ? false
              // : item === "Dimensions"
              // ? false
              // : item === "Weight"
              // ? false
              : item === "Scope"
              ? false
              : item === "FreeTime"
              ? false
              : item === "Equipment"
              ? false
              : item === "Attachments"
              ? false
              : item === "Containers"
              ? false
              : item === "Stackable"
              ? false
              : item === "Rates_Status"
              ? false
              : item === "DGNonDG"
              ? false
              : item === "PackingType"
              ? false
              : item === "CargoReadiness"
              ? false
              : item === "ShipperResponsibility"
              ? false
              : item === "Status"
              ? false
              : item === "Grade"
              ? false
              : item === "Remarks_sales"
              ? false
              : item === "Remarks_pricing"
              ? false
              : item === "PricingTeam"
              ? false
              : item === "ProspectNumber"
              ? false
              : item === "CustomerNumber"
              ? false
              : true,
        };
      });


      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );


      setColumns2(filteredDataHeader);


      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};


        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });


      setEnquiryData(filteredData);


      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };


  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });


    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });


    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("enquiryfilterConditions");
    window.localStorage.removeItem("enquiryfilter");
    window.localStorage.removeItem("enquiryfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };


  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = enquiryData[0] && Object?.keys(enquiryData[0]);


    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }


    if (window.localStorage.getItem("enquiryfilter")?.length === 0) {
      return setEnquiryData([]);
    }


    if (window.localStorage.getItem("enquiryfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);


  // Mass Update Input Type


  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };


  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);


      const { data } = await EnquiryAPIInstance.put(
        `update_enquiry?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? enquiryData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: "Enquirys Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };
  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);


      const { data } = await EnquiryAPIInstance.post(
        `delete_enquiry?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? enquiryData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: `Enquiry Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }


    if (selectAllPages) {
      localStorage.removeItem("enquiryfilter");
      localStorage.removeItem("enquiryfilterConditions");
      localStorage.removeItem("enquiryfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }


    if (localStorage.getItem("enquiryfilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("enquiryfilter") || []
      );


      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? enquiryData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("enquiryfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("enquiryfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  //mass email
  const handleOpenEditor = (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default link behavior
    }
    onOpenReportMassEmail(true);
  };
  const handleCloseEditor = () => {
    onCloseReportMassEmail(false);
  };
  console.log("Enquiry data:", enquiryData);
  console.log("set enquiry data:", setEnquiryData);


  const handleSubmitMassEmail = async () => {
    try {
      const selectedIds = checkBoxSelected;
      const recipients = enquiryData
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.Email);
      console.log(recipients);
      const emailData = {
        recipients,
        subject,
        body,
      };
      const response = await EnquiryAPIInstance.post("/emails/send_email", emailData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      });
      const { data } = response;
      if (data.success) {
        toast({
          title: "Email sent successfully!",
          position: " bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setBody("");
      setIsEditorOpen(false);
      setSubject("");
    } catch (error) {
      if (error.response.data.message) {
        toast({
          title: error.response.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error.response.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected(false);
  };


  //window outlook mail
  const handleSubmitOutlookEmail = async () => {
    // Filter EnquiryData based on selected checkboxes and get recipient email addresses
    const recipients = enquiryData
      .filter((item) => checkBoxSelected.includes(item.id))
      .map((item) => item.Email);


    if (recipients.length === 0) {
      return;
    }


    const subject = "Your subject here";
    const body = "Your email body here";


    // Join recipient email addresses into a comma-separated string
    const emailAddresses = recipients.join(",");


    // Create a mailto link with subject, body, and BCC parameter
    const mailtoLink =
      "mailto:?bcc=" +
      encodeURIComponent(emailAddresses) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(body);


    // Open the user's mail client with the mailto link
    window.location.href = mailtoLink;


    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected([]);
  };
  //Company, ContactPerson, Email, Country, DialCode, Phone, City, Industry, Tier, Tags, Enquiry, Shipment, Invoices, FollowUpDate are required for all row data
  // Mass Upload Excel
  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
  const handleSubmit = async (event) => {
    const validateData = () => {
      const requiredColumns = [
        "Mode",
        "OriginCountry",
        "DestinationCountry",
        "PickUpLocation",
        "OriginPortAirport",
        "DestinationPortAirport",
        "DeliveryLocation",
        "EnquiryDescription",
        "Incoterm",
        "Commodity",
        "HSCode",
        // "Dimensions",
        // "Weight",
        "Scope",
        "FreeTime",
        "Equipment",
        "Stackable",
        "Rates_Status",
        "DGNonDG",
        "PackingType",
        "CargoReadiness",
        "Attachments",
        "Containers",
        "EnquiryOwner",
        "ShipperResponsibility",
        "Status",
        "Grade",
        "Remarks_sales",
        "Remarks_pricing",
        "PricingTeam",
      ];
      let isValid = true;
      const missingColumns = [];
      const invalidEmailRows = [];


      for (let index = 0; index < movies.length; index++) {
        const movie = movies[index];
        let hasInvalidEmail = false; // Flag to track invalid emails in a row
        for (const column of requiredColumns) {
          if (!movie[column]) {
            if (!missingColumns.includes(column)) {
              missingColumns.push(column);
            }
            isValid = false;
          }
        }
        // Check if email is valid
        if (movie.Email && !isValidEmail(movie.Email)) {
          isValid = false;
          hasInvalidEmail = true;
        }


        if (hasInvalidEmail) {
          invalidEmailRows.push(index + 1); // Store the row number with invalid email
        }
      }
      if (missingColumns.length > 0) {
        const errorTitle = `${missingColumns.join(", ")} ${
          missingColumns.length > 1 ? "are" : "is"
        } required for all row data`;
        toast({
          title: errorTitle,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }


      if (invalidEmailRows.length > 0) {
        toast({
          title: `${
            invalidEmailRows.length
          } Invalid email address (Row Numbers: ${invalidEmailRows.join(
            ", "
          )})`,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }


      return isValid;
    };


    if (event) {
    }
    if (!validateData()) {
      return;
    }
    const movieData = movies?.map((item, i) => {
      const cargoReadiness = item.CargoReadiness || new Date().toISOString(); // Use current date if CargoReadiness is empty
      const date = new Date((cargoReadiness - 25569) * 86400 * 1000);
      const utcString = moment.utc(date).format();
      return {
        ...item,
        CargoReadiness: utcString,
        EnquiryNumber: "E" + (enquirynumber + i).toString().padStart(8, "0"),
        EnquiryOwner: JSON.parse(localStorage.getItem("user")).userId,
      };
    });
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
      movies: movieData,
    };
    console.log("Mass upload:", dataSet);
    try {
      const response = await EnquiryAPIInstance.post(
        `add_multiple_enquiry?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        dataSet,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(response);


      if (response.data.success) {
        // const dataLength = response.data.data.length;
        // Show success toast
        toast({
          title: response.data.data,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        // const dataErrorLength = response.data.data.slice(
        //   0,
        //   dataErrorLength - 1
        // );
        // Show error toast
        toast({
          title: response.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      //handleCloseExcel();
      onClose();
      getFilterData();
      getData();
    }
  };


  //search
  const handleSearch = async (event) => {
    const queryData = event.target.value;


    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };


    const { data } = await EnquiryAPIInstance.get(
      `search_enquiry?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );


    if (data?.data?.length === 0) {
      return setEnquiryData([]);
    }


    const array1 = Object.keys(columns).filter((key) => columns[key]);


    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "DestinationPortAirport	"
            ? false
            : item === "DeliveryLocation"
            ? false
            : item === "EnquiryDescription"
            ? false
            : item === "EnquiryOwner"
            ? false
            : item === "EnquiryOwnerName"
            ? false
            : item === "Incoterm"
            ? false
            : item === "Commodity"
            ? false
            : item === "DeletionFlag"
            ? false
            : item === "ConversionFlag"
            ? false
            : item === "HSCode"
            ? false
            // : item === "Dimensions"
            // ? false
            // : item === "Weight"
            // ? false
            : item === "Scope"
            ? false
            : item === "FreeTime"
            ? false
            : item === "Equipment"
            ? false
            : item === "Attachments"
            ? false
            : item === "Containers"
            ? false
            : item === "Stackable"
            ? false
            : item === "Rates_Status"
            ? false
            : item === "DGNonDG"
            ? false
            : item === "PackingType"
            ? false
            : item === "CargoReadiness"
            ? false
            : item === "ShipperResponsibility"
            ? false
            : item === "Status"
            ? false
            : item === "Grade"
            ? false
            : item === "Remarks_sales"
            ? false
            : item === "Remarks_pricing"
            ? false
            : item === "PricingTeam"
            ? false
            : item === "ProspectNumber"
            ? false
            : item === "CustomerName"
            ? false
            : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));


    setColumns2(filteredDataHeader);


    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};


      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });


      return filteredItem;
    });


    setEnquiryData(filteredData);
  };
  // Pagination


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];


  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };


  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };


  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };


  const pageNumbers = Math.ceil(enquiryData?.length / itemsPerPage);


  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.


    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);


  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );


    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };
  //mass upload-enquiry number
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await EnquiryAPIInstance.get("get_enquiryseries", {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        });


        setEnquiryNumber(data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [enquirynumber]);
  //mass upload excel
  function excelDateToJSDate(excelDate) {
    const jsDate = new Date(
      (excelDate - 25569) * 86400 * 1000
    ).toLocaleDateString("en-US", { timeZone: "UTC" });
    return jsDate;
  }


  useEffect(() => {
    if (localStorage.getItem("tableColumnsEnquiry")) {
      settableColumnsEnquiry(
        JSON.parse(localStorage.getItem("tableColumnsEnquiry"))
      );
    } else {
      settableColumnsEnquiry(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);


  //console.log(columns2);


  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };


  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const handleDrop = (event, dropIndex) => {
    event.preventDefault();


    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsEnquiry];


    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsEnquiry", JSON.stringify(updatedColumns));
    settableColumnsEnquiry(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const columnstyle = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    scrollbarWidth: "thin", // Set the width of the scrollbar to thin
    scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)",
  };


  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };


  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };


  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };


  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };


  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    
    onOpenReportDelete,
    // onOpenReportOutlookEmail,
    onOpenReportOutlookEmail,
    onOpenReportMassEmail
  );


  // mode API
  const [ModeData, setModeData] = useState([]);
  // Fetch Grade data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Grade API
  const [GradeData, setGradeData] = useState([]);
  // Fetch Grade data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setGradeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Equipment api
  const [EquipmentData, setEquipmentData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get(
          "get_equipment"
        );
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //PackingTypeData
  const [PackingTypeData, setPackingTypeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // Incoterm API
  const [incotermData, setIncotermData] = useState([]);
  // Fetch Grade data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // scope API
  const [scopeData, setScopeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
const handleConvertQoute =()=>
{
  navigate("/quotation");
}
  const MassUpload = new Massupload(
    setExcelOpen,
    setMovies,
    excelOpen,
    read,
    utils
  );
  return (
    <>
      <Header />
      <>
        {isOpen && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header
                  style={{
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Mass Upload
                </header>
                <div
                  className="closemodule"
                  onClick={() => {
                    onClose(false);
                    setExcelOpen(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                {excelOpen ? (
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <TableContainer>
                      <Table variant="simple">
                        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
                          <Thead>
                            <Tr>
                              {movies?.length > 0 &&
                                Object.keys(movies[0]).map((item, index) => (
                                  <Th
                                    key={index}
                                    className="dark:text-white bg-slate-200 dark:bg-slate-700 dark:border-gray-600"
                                  >
                                    {item}
                                  </Th>
                                ))}
                            </Tr>
                          </Thead>


                          <Tbody>
                            {movies?.map((mainItem, i) => (
                              <Tr key={i}>
                                {Object.keys(movies[0]).map((item, index) => (
                                  <Td key={index}>
                                    {item === "CargoReadiness"
                                      ? excelDateToJSDate(mainItem[item])
                                      : mainItem[item]}
                                  </Td>
                                ))}
                                {/* <Td>
                                  {
                                    JSON.parse(localStorage.getItem("user"))
                                      .userId
                                  }
                                </Td> */}
                              </Tr>
                            ))}
                          </Tbody>
                        </div>
                      </Table>
                    </TableContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onOpenReportMassUpload();
                          onClose(false);
                          setExcelOpen(false);
                          MassUpload.handleCloseExcel();
                        }}
                        colorScheme="blue"
                        mr={3}
                        mt={5}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="inputGroupFile"
                        required
                        onChange={(event) => {
                          MassUpload.handleImport(event);
                        }}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: "none" }}
                      />


                      <label
                        className="fileUploadContainer"
                        htmlFor="inputGroupFile"
                      >
                        <label
                          className="custom-file-label bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                          style={{ cursor: "pointer" }}
                          htmlFor="inputGroupFile"
                        >
                          Upload the file
                        </label>
                      </label>
                    </div>
                  </div>
                )}
              </Rnd>
            </div>
          </>
        )}
      </>
      <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
        <FaFilter
          size={25}
          onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
        />
        &nbsp;&nbsp;
        <>
          <div className="pt-2 ml-10 relative mx-auto text-gray-600">
            <input
              className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
              ref={searchRef}
              type="search"
              name="search"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </>
        &nbsp;
        <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        />
        &nbsp;
        <div className="relative inline-block">
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
            <Link to="/addcustomer">Create Customer</Link>
          </button> */}
          <AddUnallocatedProspect
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            onApply={handleApply}
            setUnallocatedBookingState={setUnallocatedBookingState}
            setFormData={setFormData}
            formData={formData}
          />
        </div>
        <div className="relative inline-block">
          <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950" 
           onClick={handleConvertQoute}
          >
            Convert Quotation
          </button>
        </div>
        
        &nbsp;&nbsp;
        <div className="relative inline-block">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
            onClick={toggleActionDropdown}
          >
            Actions
          </button>
          &nbsp;&nbsp;
          {isActionOpen && (
            <div className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
              {/* Mass update icon */}
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <FiEdit size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    checkValidation.handleEditClickMassupdate();
                    handleActionSelection();
                  }}
                >
                  Mass Update
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              >
                <RiDeleteBinLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Delete
                </span>
              </a>
              {/*mass upload */}
              {/* <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <LiaFileUploadSolid size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    onOpen();
                    toggleActionDropdown();
                    handleActionSelection();
                  }}
                >
                  Mass Upload
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  window.print(); // This triggers the browser's print functionality
                }}
              >
                <IoMdPrint size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Print
                </span>
              </a> */}
              {/* Download icon */}
              {isAdmin && (
                <a
                  className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                  onClick={() => {
                    onOpenDownload();
                    setOverlay(<OverlayOne />);
                    toggleActionDropdown();
                  }}
                > 
                  <MdOutlineDownloadForOffline size={20} />


                  <span
                    className="flex-grow cursor-pointer"
                    onClick={handleActionSelection}
                  >
                    Export Data
                  </span>
                </a>
              )}
              {/* <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  toggleActionDropdown();
                  {
                    navigate("/enquirytemplate");
                  }
                }}
              >
                <BsFiletypeExe size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Enquiry Template
                </span>
              </a> */}
            </div>
          )}
        </div>
        {/* Mass filter */}
        <>
          {isOpenFilter && (
            <>
              <div style={backdropStyle}></div>
              <div className={`FilterModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                    // height: 200,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Criteria
                  </header>
                  <div
                    className="closemodule"
                    onClick={() => {
                      onCloseFilter(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />


                  <FormControl>
                    {filterRows.map((index) => (
                      <Flex key={index}>
                        <Select
                          id={`column-select-${index}`}
                          placeholder="Select"
                          onChange={(e) => handleFilterTypeSelect(e, index)}
                          value={filterConditions[index]?.column || "select"}
                          size={"md"}
                          isDisabled={false}
                          blurInputOnSelect={true}
                          isSearchable={false}
                          style={{ position: "relative" }}
                        >
                          <option value="EnquiryNumber">Enquiry Number</option>
                          <option value="Mode">Mode</option>
                          <option value="OriginCountry">OriginCountry</option>
                          <option value="DestinationCountry">
                            DestinationCountry
                          </option>
                          <option value="PickUpLocation">PickUpLocation</option>
                          <option value="OriginPortAirport">
                            OriginPortAirport
                          </option>
                          <option value="DestinationPortAirport">
                            DestinationPortAirport
                          </option>
                          <option value="DeliveryLocation">
                            DeliveryLocation
                          </option>
                          <option value="EnquiryDescription">
                            EnquiryDescription
                          </option>
                          <option value="Incoterm">Incoterm</option>
                          <option value="Commodity">Commodity</option>
                          <option value="HSCode">HSCode</option>
                          {/* <option value="Dimensions">Dimensions</option>
                          <option value="Weight">Weight</option> */}
                          <option value="Scope">Scope</option>
                          <option value="FreeTime">FreeTime</option>
                          <option value="Equipment">Equipment</option>
                          <option value="Stackable">Stackable</option>
                          <option value="Rates_Status">Rates Status</option>
                          <option value="DGNonDG">DGNonDG</option>
                          <option value="PackingType">PackingType</option>
                          <option value="CargoReadiness">CargoReadiness</option>
                          <option value="Attachments">Attachments</option>
                          <option value="Containers">Containers</option>
                          <option value="ShipperResponsibility">
                            ShipperResponsibility
                          </option>
                          <option value="Status">Status</option>
                          <option value="Grade">Grade</option>
                          <option value="Remarks_sales">Remarks_sales</option>
                          <option value="Remarks_pricing">Remarks_pricing</option>
                          <option value="PricingTeam">PricingTeam</option>
                          <option value="EnquiryOwner">EnquiryOwner</option>
                          <option value="EnquiryOwnerName">
                            {" "}
                            EnquiryOwner Name
                          </option>
                          <option value="ProspectNumber">
                            {" "}
                            Prospect Number
                          </option>
                          <option value="CustomerNumber">
                            {" "}
                            Customer Number
                          </option>
                        </Select>
                        &nbsp;&nbsp;
                        <Select
                          id={`filter-type-${index}`}
                          onChange={(e) =>
                            handleFilterConditionSelect(e, index)
                          }
                          value={filterConditions[index]?.condition || "select"}
                          placeholder="Select"
                        >
                          {filterConditions[index]?.column ===
                          "CargoReadiness" ? (
                            <>
                              <option value="equalsDate">=</option>
                              <option value="not-equalsDate">!=</option>
                              <option value="greaterDate">&gt;</option>
                              <option value="lesserDate">&lt;</option>
                              <option value="greater-equalDate">&gt;=</option>
                              <option value="lesser-equalDate">&lt;=</option>
                            </>
                          ) : filterConditions[index]?.column ===
                            "EnquiryOwnerName" ? (
                            <>
                              <option value="isenquiryname">is</option>
                              <option value="containsenquiryname">
                                contains
                              </option>
                              <option value="startsWithenquiryname">
                                starts with
                              </option>
                              <option value="endsWithenquiryname">
                                ends with
                              </option>
                            </>
                          ) : filterConditions[index]?.column ===
                            "EnquiryOwner" ? (
                            <>
                              <option value="equals">=</option>
                              <option value="not-equals">!=</option>
                              <option value="greater">&gt;</option>
                              <option value="lesser">&lt;</option>
                              <option value="greater-equal">&gt;=</option>
                              <option value="lesser-equal">&lt;=</option>
                            </>
                          ) : (
                            <>
                              <option value="is">is</option>
                              <option value="contains">contains</option>
                              <option value="startsWith">starts with</option>
                              <option value="endsWith">ends with</option>
                            </>
                          )}
                        </Select>
                        &nbsp;&nbsp;
                        {/* <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      /> */}
                        {/* Conditionally render the input field based on the selected column */}
                        {["CargoReadiness"].includes(
                          filterConditions[index]?.column
                        ) ? (
                          <Input
                            type="date"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        ) : (
                          <Input
                            type="text"
                            id={`value-input-${index}`}
                            value={filterConditions[index]?.value || ""}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />
                        )}
                        &nbsp;&nbsp;{" "}
                        <IoMdAddCircle
                          className="cursor-pointer"
                          size={70}
                          style={{ marginTop: "-15px", color: "green" }}
                          onClick={() => addFilterRow()}
                        />
                        {filterRows?.length > 1 && index > 0 && (
                          <BiSolidMinusCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "red" }}
                            onClick={() => removeFilterRow(index)}
                          />
                        )}
                      </Flex>
                    ))}
                  </FormControl>
                  <div
                    style={{
                      float: "right",
                      display: "block",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <RadioGroup
                      style={{ flex: 1 }}
                      value={handleCheckboxFilter}
                    >
                      <Stack direction="row">
                        <Radio value="AND" onChange={handleCheckboxChange}>
                          All
                        </Radio>
                        <Radio value="OR" onChange={handleCheckboxChange}>
                          Any
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
        {/* Manage column */}
        <>
          {isOpenManagecolumn && (
            <>
              <div style={backdropStyle}></div>
              <div style={modalStyleUpdate}>
                <Rnd
                  style={columnstyle}
                  default={{
                    x: 0,
                    y: 0,
                    width: 400,
                    height: 500,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Manage Column
                  </header>
                  <div
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />


                  {tableColumnsEnquiry.map(
                    (label, index) =>
                      label.key !== "id" &&
                      label.key !== "DialCode" && (
                        <label
                          key={index}
                          className="flex items-center"
                          draggable
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDragOver={handleDragOver}
                          onDrop={(event) => handleDrop(event, index)}
                        >
                          <input
                            type="checkbox"
                            // checked={label.label}
                            className="cursor-pointer"
                            disabled={
                              label.key === "EnquiryNumber"
                                ? true
                                : label.key === "Mode"
                                ? true
                                // : label.key === "Containers"
                                // ? true
                                : label.key === "ContactPerson"
                                ? true
                                : label.key === "Email"
                                ? true
                                : label.key === "Phone"
                                ? true
                                : label.key === "City"
                                ? true
                                : null
                            }
                            checked={label.isVisible}
                            onChange={(event) => {
                              handleCheckboxColumn(event, label);
                            }}
                          />
                          <span
                            className="ml-1"
                            style={{ cursor: "all-scroll" }}
                          >
                            {label.label}
                          </span>
                          {label.isDisabled && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>
                      )
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      float: "right",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="green"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="red"
                      ml={3}
                    >
                      Cancel
                    </Button>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>


          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>


          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>


          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>
          {/* Icons */}
          <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <FiEdit
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassupdate();
                }}
              />
            </div>
          </Tooltip>


          <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <HiOutlineMail
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassmail();
                }}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Outlook Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <SiMicrosoftoutlook
                onClick={() => {
                  checkValidation.handleEditClickOutlookMail();
                }}
                size={20}
              />
            </div>
          </Tooltip>


     
          <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip>
          {isdelete ? (
            <Tooltip
              hasArrow
              label="Show deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          )}
          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(enquiryData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>


          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, enquiryData?.length)}
              </span>{" "}
              of <span className="font-medium">{enquiryData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>
        {/* table */}
        <div className="table-container">
          <DraggableEnquiryTable
            isLoading={isLoading}
            columns={columns2}
            enquiryData={enquiryData}
            getData={getData}
            data={enquiryData.slice(indexOfFirstItem, indexOfLastItem)}
            setEnquiryData={setEnquiryData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsEnquiry={tableColumnsEnquiry}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>
        {/* Mass update */}
        <>
          {
            <div>
              {isOpenMassupdate && (
                <>
                  <div style={backdropStyle}></div>
                  <div className={`MassUpdateModal ${scrollBehavior}`}>
                    <Rnd
                      style={style}
                      default={{
                        x: 0,
                        y: 0,
                        width: 500,
                        // height: 200
                      }}
                      cancel="X"
                      allowAnyClick={true}
                    >
                      <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Mass Update
                      </header>
                      <div
                        onClick={() => {
                          onCloseMassupdate(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>
                      <br />
                      <FormControl>
                        <Flex>
                          <Select
                            mt={7}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            value={selectedOption}
                            onChange={(e) => handleOptionChange(e.target.value)}
                          >
                            <option value="Select a field">
                              Select a field
                            </option>
                            <option value="Mode"> Mode</option>
                            <option value="OriginCountry"> OriginCountry</option>
                            <option value="DestinationCountry">DestinationCountry</option>
                            <option value="PickUpLocation">
                              PickUpLocation
                            </option>
                            <option value="OriginPortAirport">
                              OriginPortAirport
                            </option>
                            <option value="DestinationPortAirport	">
                              DestinationPortAirport{" "}
                            </option>
                            <option value="DeliveryLocation">
                              DeliveryLocation
                            </option>
                            <option value="EnquiryDescription">
                              EnquiryDescription
                            </option>
                            <option value="Incoterm">Incoterm</option>
                            <option value="Commodity">Commodity</option>
                            <option value="HSCode">HSCode</option>
                            {/* <option value="Dimensions">Dimensions</option>
                            <option value="Weight">Weight</option> */}
                            <option value="Scope">Scope</option>
                            <option value="FreeTime">FreeTime</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Stackable">Stackable</option>
                            <option value="Rates_Status">Rates Status</option>                            
                            <option value="DGNonDG">DGNonDG</option>
                            <option value="PackingType">PackingType</option>
                            <option value="CargoReadiness">
                              CargoReadiness
                            </option>
                            {/* <option value="Attachments">Attachments</option> */}


                            <option value="Containers">Containers</option>
                            <option value="ShipperResponsibility">
                              ShipperResponsibility
                            </option>
                            <option value="Status">Status</option>
                            <option value="Grade">Grade</option>
                            <option value="Remarks_sales">Remarks_sales</option>
                            <option value= "Remarks_pricing">Remarks_pricing</option>
                            <option value="PricingTeam">PricingTeam</option>
                            <option value="DeletionFlag">DeletionFlag</option>
                            <option value="ConversionFlag">ConversionFlag</option>


                            <option value="ProspectNumber">
                              Prospect Number
                            </option>
                            <option value="Customer Number">
                              Customer Number
                            </option>
                          </Select>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {selectedOption === "Mode" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Mode</option>
                              {ModeData.map((data) => (
                                <option value={data.modeId}>
                                  {data.modeId}
                                </option>
                              ))}
                            </Select>
                          ) : selectedOption === "Grade" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Grade</option>
                              {GradeData.map((data) => (
                                <option value={data.Tier}>{data.Tier}</option>
                              ))}
                            </Select>
                          )
                          : selectedOption === "Company" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Company </option>
                              {GradeData.map((data) => (
                                <option value={data.Company}>{data.Company}</option>
                              ))}
                            </Select>
                          ) : selectedOption === "Scope" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Scope</option>
                              {scopeData.map((data) => (
                                <option value={data.scopeId}>
                                  {data.scopeId}
                                </option>
                              ))}
                            </Select>
                          ) : selectedOption === "Incoterm" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Incoterm</option>
                              {incotermData.map((data) => (
                                <option value={data.Incoterm}>
                                  {data.Incoterm}
                                </option>
                              ))}
                            </Select>
                          ) : selectedOption === "Equipment" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Equipment</option>
                              {EquipmentData.map((data) => (
                                <option value={data.equipmentId}>
                                  {data.equipmentId}
                                </option>
                              ))}
                            </Select>
                          ) : selectedOption === "Stackable" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select Stackable</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Select>
                          ) 
                          : selectedOption === "Rates_Status" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Rates Status</option>
                              <option value="Complete">Complete</option>
                              <option value="Incomplete">Incomplete</option>
                            </Select>
                          ): selectedOption === "DGNonDG" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select DGNonDG</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Select>
                          ) : selectedOption === "CargoReadiness" ? (
                            <Input
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              type="date"
                              name={selectedOption}
                              id="updateField"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            />
                          ) : selectedOption === "PackingType" ? (
                            <Select
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            >
                              <option>Select PackingType</option>
                              {PackingTypeData.map((item) => (
                                <option value={item.packingtypeId}>
                                  {item.packingtypeId}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            <Input
                              mt={7}
                              onChange={(e) => setUpdatedValue(e.target.value)}
                              value={updatedValue}
                              type="text"
                              name={selectedOption}
                              id="updateField"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              disabled={selectedOption === "Select a field"}
                            />
                          )}
                        </Flex>
                      </FormControl>
                      <br />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          onOpenReportMassupdate();
                          onCloseMassupdate();
                        }}
                        mr={3}
                      >
                        Submit
                      </Button>
                    </Rnd>
                  </div>
                </>
              )}
            </div>
          }
        </>
      </div>
      {/*mass delete*/}
      <>
        {isOpenReportDelete && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassDeleteModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  height: 200, // You seem to have commented out height, I've uncommented it
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Delete Enquirys
                </header>
                <div
                  onClick={() => {
                    onCloseReportDelete(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {"Are you sure you want to delete the Enquirys?"}{" "}
                  {/* Enclose the string in curly braces */}
                </FormControl>
                <br />
                {/* Move the fragment inside the Rnd component */}
                <>
                  <Button colorScheme="green" onClick={handleSubmitDelete}>
                    Delete
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={() => onCloseReportDelete(false)}
                    style={{ background: "red", color: "white" }}
                    ml={3}
                  >
                    Cancel
                  </Button>
                </>
              </Rnd>
            </div>
          </>
        )}
      </>
      {/* Mass update confirmation*/}
      <>
        {isOpenReportMassupdate && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Update Enquirys
                </header>
                <div
                  onClick={() => {
                    onCloseReportMassupdate(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to Update the Enquirys?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitUpdate();
                    onCloseReportMassupdate();
                  }}
                >
                  Update
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportMassupdate();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>


      {/*mass upload report */}
      <>
        {isOpenReportMassUpload && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Mass Upload Enquirys
                </header>
                <div
                  onClick={() => {
                    onCloseReportMassUpload(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to Mass Upload the Enquiry?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmit();
                    onCloseReportMassUpload();
                  }}
                >
                  Submit
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportMassUpload();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}{" "}
      </>
      {/* outlook mail */}


      <>
        {isOpenReportOutlookEmail && (
          <>
            <div style={backdropStyle}></div>
            <div className={`OultlookMailModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Send Mail
                </header>
                <div
                  onClick={() => {
                    onCloseReportOutlookEmail(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to send the mail?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitOutlookEmail();
                    onCloseReportOutlookEmail();
                  }}
                >
                  Submit
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportOutlookEmail();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>


      <>
        {isOpenReportMassEmail && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`EditMailModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Compose Email
              </header>
              <div
                onClick={() => {
                  handleCloseEditor(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {/* Subject input */}
              <FormControl id="subject">
                <FormLabel>Subject</FormLabel>
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </FormControl>


              {/* Editor */}
              <Editor
                value={body}
                onEditorChange={setBody}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
                }}
              />
              {/* </ModalBody>
            <ModalFooter> */}
              <br />
              <Button colorScheme="red" mr={2} onClick={handleCloseEditor}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassEmail();
                  handleCloseEditor();
                }}
              >
                Submit
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>


      {/* Download */}
      <>
        {isOpenDownload && (
          <>
            <div style={backdropStyle}></div>
            <div className={`DownloadExcelModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 500,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Do you wanna to export data?
                </header>
                <div
                  onClick={() => {
                    onCloseDownload(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  <Flex alignItems="center ">
                    {" "}
                    {/* Use alignItems to vertically center the contents */}
                    <FormLabel marginLeft="100">Select</FormLabel>{" "}
                    {/* Push FormLabel to the left */}
                    <Select
                      w={60}
                      mt={-1}
                      placeholder="Select option"
                      size="md"
                      ref={initialRef}
                      value={selectedExportOption}
                      onChange={handleExportOptionChange}
                    >
                      <option value="Excel">Excel</option>
                      <option value="CSV">CSV</option>
                      <option value="PDF">PDF</option>
                      <option value="Text">Text</option>
                    </Select>
                  </Flex>
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleExport();
                  }}
                  disabled={isDownloading}
                  mr={3}
                >
                  {isDownloading ? "Downloading..." : "Export Data"}
                </Button>
                {downloadComplete && (
                  <Text mt={2} color="green">
                    Download completed!
                  </Text>
                )}
              </Rnd>
            </div>
          </>
        )}
      </>
    </>
  );
};
export default Enquiry;