export class Checkvalidation {
    constructor(
      checkBoxSelected,
      toast,
      handlePrint,
      handleCancel,
      handleCredit,
      handleDebit
    ) {
      this.checkBoxSelected = checkBoxSelected;
      this.toast = toast;
      this.handlePrint = handlePrint;
      this.handleCancel=handleCancel;
      this.handleCredit=handleCredit; 
      this.handleDebit=handleDebit;    
    }
  
  
    handlePrintClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handlePrint();
    } 
    handleCancelClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleCancel();
    } 
    handleCreditClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for filing to Credit note.");
        this.toast({
          title: "Please select a row for filing Credit note",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for filing to credit note.");
      this.toast({
        title: "Select only one row for filing to credit note.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleCredit();
    } 
    handleDebitClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for filing to debit note.");
        this.toast({
          title: "Please select a row for filing debit note",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for filing to debit note.");
      this.toast({
        title: "Select only one row for filing to debit note.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleDebit();
    } 
  }
  