import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import {
  DraggableBookingTable,
  Header,
  UnAuthorizedModal,
} from "../../components";
import {
  ModalOverlay,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Select,
  Input,
  Button,
  RadioGroup,
  Stack,
  Radio,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Axios, {
  BookingAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Rnd } from "react-rnd";
import { FiPrinter } from "react-icons/fi";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import SelectAllPages from "./Selectallpages.js";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Modal,
  // ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import DuplicateBooking from "../Addbooking/DuplicateBooking.js";
const Booking = () => {
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const modalBodyRef = useRef(null);
  const [size, setSize] = useState("full");
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [jobWarning, setJobWarning] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenPrint,
    onOpen: onOpenPrint,
    onClose: onClosePrint,
  } = useDisclosure();

  const cancelRef = useRef();
  const searchRef = useRef(null);
  const initialRef = useRef(null);  
  const dropdownRef = useRef(null);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);
  const [bookingNumber, setBookingNumber] = useState("");

  // Duplicate Booking
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [DuplicateBookingState, setDuplicateBookingState] = useState([]);
  const [formData, setFormData] = useState([
    {
      BookingType: "",
      // BookingNumber: "",
      Product: "",
      PlaceOfReceipt: "",
      Pol: "",
      Pod: "",
      PlaceOfDelivery: "",
      Cargo: "",
      CargoNature: "",
      Incoterm: "",
      Sales: "",
      Scope: "",
      CompanyName: "",
      Address: "",
      PIC: "",
      LinerRefNumber: "",
      SICutOff: "",
      VesselNameVoyage: "",
      VGMCutOff: "",
      ETD: "",
      ETA: "",
      ETAPOL: "",
      ETDOrigin: "",
      ETDDestination: "",
      CYCutOff: "",
      EmptyPickupLocation: "",
      Region: "",
      Remarks: "",
      Containers: "ContainerType: , Quantity: , Weight: , Dimensions: ;",
      TermsConditions: "TermsAndConditions: ;",
      VesselDetails: "VesselType: , Vessel: , Voyage: , PolEta: , PortOfLoading: , ETD: , PortOfDischarge: , ETA: ;",
    },
  ]);
  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);

  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  //backdrop and outside click close
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
    }
  };
 
  useEffect(() => {
    if (isActionOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionOpen]);
// end of backdrop and outside click close
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    BookingType: true,
    BookingNumber: true,
    Product: true,
    PlaceOfReceipt: true,
    Pol: true,
    Pod: true,
    PlaceOfDelivery: true,
    POL: true,
    POD: true,
    Cargo: true,
    CargoNature: true,
    Incoterm: true,
    Sales: true,
    Scope: true,
    CompanyName: true,
    Address: true,
    PIC: true,
    LinerRefNumber: true,
    SICutOff: true,
    VesselNameVoyage: true,
    VGMCutOff: true,
    ETD: true,
    ETA: true,
    ETDDestination: true,
    CYCutOff: true,
    EmptyPickupLocation: true,
    Region: true,
    Remarks: true,
    ContainerType: true,
    Quantity: true,
    Weight: true,
    Dimensions: true,
    CreatedBy: true,
    Containers: true,
    VesselDetails: true,
    TermsConditions: true,
    DeletionFlag: true,
    ConversionFlag: true,
  });
  const [tableColumnsBooking, settableColumnsBooking] = useState([]);

  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes

  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsBooking((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsBooking.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsBooking", JSON.stringify(newData));
  };
  const handleCheckbox = (id, BookingNumber) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
    setBookingNumber(BookingNumber);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await BookingAPIInstance.get(
        `get_booking?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeleted=${isdelete}&isConverted=${isconvert}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setBookingData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "PlaceOfReceipt"
              ? false
              : item === "PlaceOfDelivery"
              ? false
              : item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "Cargo"
              ? false
              : item === "CargoNature"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Scope"
              ? false
              : item === "Address"
              ? false
              : item === "PIC"
              ? false
              : item === "LinerRefNumber"
              ? false
              : item === "SICutOff"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETDDestination"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "Region"
              ? false
              : item === "Remarks"
              ? false
              : item === "ContainerType"
              ? false
              : item === "Quantity"
              ? false
              : item === "Weight"
              ? false
              : item === "Dimensions"
              ? false
              : // : item === "Containers"
                //   ? false
                true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setBookingData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("bookingfilter")?.length === 0) {
      return setBookingData([]);
    }

    if (window.localStorage.getItem("bookingfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };

  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await BookingAPIInstance.post(
        `delete_booking?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? bookingData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        if (data?.data.message === "BookingNumber exists in job table") {
          const bookings = data?.data.data;
          if (bookings.length === 1) {
            const bookingNumber = bookings[0].BookingNumber;
            const jobNumber = bookings[0].JobNumber;
            setJobWarning(
              `Job ${jobNumber} exists for Booking ${bookingNumber}, do you want to delete the corresponding job!`
            );
          } else {
            const bookingCount = bookings.length;
            setJobWarning(
              `${bookingCount} booking jobs exist, do you want to delete them!`
            );
          }
          return;
        }
        toast({
          title: `Booking Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("bookingfilter");
      localStorage.removeItem("bookingfilterConditions");
      localStorage.removeItem("bookingfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("bookingfilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("bookingfilter") || []
      );

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? bookingData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("bookingfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("bookingfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  const handleSubmitDeleteWithJob = async (e) => {
    try {
      if (e.target.value === "WITHOUT") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await BookingAPIInstance.post(
          `delete_booking_with_job?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITHOUT`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? bookingData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: `Booking  ${data?.bookingNumber} Deleted Successfully!`,
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      } else if (e.target.value === "WITH") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await BookingAPIInstance.post(
          `delete_booking_with_job?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITH`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? bookingData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: "Booking Deleted Successfully!",
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("bookingfilter");
      localStorage.removeItem("bookingfilterConditions");
      localStorage.removeItem("bookingfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("bookingfilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("bookingfilter") || []
      );

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? bookingData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("bookingfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("bookingfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };

  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("bookingfilterRows"))
      ? JSON.parse(localStorage.getItem("bookingfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("bookingfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("bookingfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "bookingfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "bookingfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await BookingAPIInstance.post(
        `booking_mass_filter?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}&isConverted=${isconvert}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("bookingfilter", JSON.stringify([]));
        return setBookingData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "PlaceOfReceipt"
              ? false
              : item === "PlaceOfDelivery"
              ? false
              : item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "Cargo"
              ? false
              : item === "CargoNature"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Scope"
              ? false
              : item === "Address"
              ? false
              : item === "PIC"
              ? false
              : item === "LinerRefNumber"
              ? false
              : item === "SICutOff"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETDDestination"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "Region"
              ? false
              : item === "Remarks"
              ? false
              : item === "ContainerType"
              ? false
              : item === "Quantity"
              ? false
              : item === "Weight"
              ? false
              : item === "Dimensions"
              ? false
              : // : item === "Containers"
                //   ? false
                true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setBookingData(filteredData);
      setCurrentPage(1);
      localStorage.setItem("bookingfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("bookingfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setBookingData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "PlaceOfReceipt"
              ? false
              : item === "PlaceOfDelivery"
              ? false
              : item === "POL"
              ? false
              : item === "POD"
              ? false
              : item === "Cargo"
              ? false
              : item === "CargoNature"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Scope"
              ? false
              : item === "Address"
              ? false
              : item === "PIC"
              ? false
              : item === "LinerRefNumber"
              ? false
              : item === "SICutOff"
              ? false
              : item === "VesselNameVoyage"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "ETDDestination"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "Region"
              ? false
              : item === "Remarks"
              ? false
              : item === "ContainerType"
              ? false
              : item === "Quantity"
              ? false
              : item === "Weight"
              ? false
              : item === "Dimensions"
              ? false
              : // : item === "Containers"
                //   ? false
                true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setBookingData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("bookingfilterConditions");
    window.localStorage.removeItem("bookingfilter");
    window.localStorage.removeItem("bookingfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = bookingData[0] && Object?.keys(bookingData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("bookingfilter")?.length === 0) {
      return setBookingData([]);
    }

    if (window.localStorage.getItem("bookingfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await BookingAPIInstance.put(
        `update_booking?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? bookingData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Booking Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await BookingAPIInstance.get(
      `search_booking?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}&isConverted=${isconvert}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setBookingData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "PlaceOfReceipt"
            ? false
            : item === "PlaceOfDelivery"
            ? false
            : item === "POL"
            ? false
            : item === "POD"
            ? false
            : item === "Cargo"
            ? false
            : item === "CargoNature"
            ? false
            : item === "Incoterm"
            ? false
            : item === "Scope"
            ? false
            : item === "Address"
            ? false
            : item === "PIC"
            ? false
            : item === "LinerRefNumber"
            ? false
            : item === "SICutOff"
            ? false
            : item === "VesselNameVoyage"
            ? false
            : item === "VGMCutOff"
            ? false
            : item === "ETD"
            ? false
            : item === "ETA"
            ? false
              : item === "ETDDestination"
            ? false
            : item === "CYCutOff"
            ? false
            : item === "Region"
            ? false
            : item === "Remarks"
            ? false
            : item === "ContainerType"
            ? false
            : item === "Quantity"
            ? false
            : item === "Weight"
            ? false
            : item === "Dimensions"
            ? false
            : // : item === "Containers"
              //   ? false
              true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setBookingData(filteredData);
    setCurrentPage(1);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(bookingData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsBooking")) {
      settableColumnsBooking(
        JSON.parse(localStorage.getItem("tableColumnsBooking"))
      );
    } else {
      settableColumnsBooking(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsBooking];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsBooking", JSON.stringify(updatedColumns));
    settableColumnsBooking(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const Managecolumn = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "30px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
  };
  const ManagecolumnmodalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "30%",
    zIndex: "200",
  };
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenReportDelete,
    onOpenMassupdate,
    onOpenPrint
  );
  const [productData, setProductData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [portData, setPortData] = useState([]);
  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Customer API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(exportData, "Booking");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Booking");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Booking");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Booking");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };

  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const formatDateWithoutTime = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    };

    const dataArray = data.map((item) => [
      item.BookingNumber,
      item.BookingType,
      item.Product,
      item.PlaceOfReceipt,
      item.POL,
      item.POD,
      item.PlaceOfDelivery,
      item.Cargo,
      item.CargoNature,
      formatDateWithoutTime(item.ETD),
      formatDateWithoutTime(item.ETA),
      formatDateWithoutTime(item.ETDDestination),
      formatDateWithoutTime(item.CYCutOff),
      formatDateWithoutTime(item.SICutOff),
      formatDateWithoutTime(item.VGMCutOff),
      item.EmptyPickupLocation,
      item.VesselNameVoyage,
      item.CompanyName,
      item.Address,
      item.PIC,
      item.LinerRefNumber,
      item.Remarks,
      item.Region,
      item.Sales,
      item.Incoterm,
      item.Scope,
      item.Containers,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "BookingNumber",
        "BookingType",
        "Product",
        "PlaceOfReceipt",
        "POL",
        "POD",
        "PlaceOfDelivery",
        "Cargo",
        "CargoNature",
        "ETD",
        "ETA",
        "ETDDestination",
        "CYCutOff",
        "SICutOff",
        "VGMCutOff",
        "EmptyPickupLocation",
        "VesselNameVoyage",
        "CompanyName",
        "Address",
        "PIC",
        "LinerRefNumber",
        "Remarks",
        "Region",
        "Sales",
        "Incoterm",
        "Scope",
        "Containers",
      ],
      ...dataArray,
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Booking");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(dataBlob, finalFileName);
  };

  const exportToCSV = (data, fileName) => {
    // Function to format date without time
    const formatDateWithoutTime = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    };
    const dataArray = data.map((item) => [
      item.BookingNumber,
      item.BookingType,
      item.Product,
      item.PlaceOfReceipt,
      item.POL,
      item.POD,
      item.PlaceOfDelivery,
      item.Cargo,
      item.CargoNature,
      formatDateWithoutTime(item.ETD),
      formatDateWithoutTime(item.ETA),
      formatDateWithoutTime(item.ETDDestination),
      formatDateWithoutTime(item.CYCutOff),
      formatDateWithoutTime(item.SICutOff),
      formatDateWithoutTime(item.VGMCutOff),
      item.EmptyPickupLocation,
      item.VesselNameVoyage,
      item.CompanyName,
      item.Address,
      item.PIC,
      item.LinerRefNumber,
      item.Remarks,
      item.Region,
      item.Sales,
      item.Incoterm,
      item.Scope,
      item.Containers,
    ]);

    const csvContent = [
      [
        "BookingNumber",
        "BookingType",
        "Product",
        "PlaceOfReceipt",
        "POL",
        "POD",
        "PlaceOfDelivery",
        "Cargo",
        "CargoNature",
        "ETD",
        "ETA",
        "ETDDestination",
        "CYCutOff",
        "SICutOff",
        "VGMCutOff",
        "EmptyPickupLocation",
        "VesselNameVoyage",
        "CompanyName",
        "Address",
        "PIC",
        "LinerRefNumber",
        "Remarks",
        "Region",
        "Sales",
        "Incoterm",
        "Scope",
        "Containers",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code

  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);

    const tableColumnsBooking = [
      "BookingNumber",
      "BookingType",
      "Product",
      "PlaceOfReceipt",
      "POL",
      "POD",
      "PlaceOfDelivery",
      "Cargo",
      "CargoNature",
      "ETD",
      "ETA",
      "ETDDestination",
      "CYCutOff",
      "SICutOff",
      "VGMCutOff",
      "EmptyPickupLocation",
      "VesselNameVoyage",
      "CompanyName",
      "Address",
      "PIC",
      "LinerRefNumber",
      "Remarks",
      "Region",
      "Sales",
      "Incoterm",
      "Scope",
      "Containers",
    ];

    const tableRows = data.map((item) => {
      return tableColumnsBooking.map((column) => item[column]);
    });

    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 5;

    doc.setFontSize(titleFontSize);

    const title = "Booking";
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
      startY: marginTop + titleFontSize + 10,
      headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 5 },
      bodyStyles: { fontSize: 5 },
      columnStyles: { cellWidth: 2000 },
      head: [tableColumnsBooking],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };

    doc.autoTable(tableOptions);

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };

  const exportToText = (data, fileName) => {
    // Function to format date without time
    const formatDateWithoutTime = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    };
    const tableColumnsBooking = [
      "BookingNumber",
      "BookingType",
      "Product",
      "PlaceOfReceipt",
      "POL",
      "POD",
      "PlaceOfDelivery",
      "Cargo",
      "CargoNature",
      "ETD",
      "ETA",
      "ETDDestination",
      "CYCutOff",
      "SICutOff",
      "VGMCutOff",
      "EmptyPickupLocation",
      "VesselNameVoyage",
      "CompanyName",
      "Address",
      "PIC",
      "LinerRefNumber",
      "Remarks",
      "Region",
      "Sales",
      "Incoterm",
      "Scope",
      "Containers",
    ];

    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });

    const textContent = [tableColumnsBooking.join(",")]
      .concat(tableRows)
      .join("\n");

    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";

    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };

  // const handlePrint = async () => {
  //   try {
  //     const userId = JSON.parse(localStorage.getItem("user")).userId;
  //     const token = JSON.parse(localStorage.getItem("token"));

  //     // First, fetch SI/HBL data
  //     const printBookingDataResponse = await BookingAPIInstance.get(
  //       `get_booking_print?userId=${userId}&BookingNumber=${bookingNumber}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     const printBookingData = printBookingDataResponse.data.data[0];

  //     if (
  //       printBookingData &&
  //       printBookingData.Containers &&
  //       printBookingData.Containers !== ""
  //     ) {
  //       const operations = JSON.parse(printBookingData.Containers).map(
  //         (container) => {
  //           const containerObj = {};

  //           // Iterate over each key-value pair in the container object
  //           for (const [key, value] of Object.entries(container)) {
  //             // If the value is an empty string or null, set it to null
  //             containerObj[key] = value === "" ? null : value;
  //           }

  //           return containerObj;
  //         }
  //       );

  //       printBookingData.Operations = operations;
  //     }

  //     if (
  //       printBookingData &&
  //       printBookingData.VesselDetails &&
  //       printBookingData.VesselDetails !== ""
  //     ) {
  //       const operationsVesselDetails = JSON.parse(printBookingData.VesselDetails).map(
  //         (vessel) => {
  //           const vesselObj = {};

  //           // Iterate over each key-value pair in the container object
  //           for (const [key, value] of Object.entries(vessel)) {
  //             // If the value is an empty string or null, set it to null
  //             vesselObj[key] = value === "" ? null : value;
  //           }

  //           return vesselObj;
  //         }
  //       );

  //       printBookingData.OperationsVesselDetails = operationsVesselDetails;
  //     }

  //     if (
  //       printBookingData &&
  //       printBookingData.TermsConditions &&
  //       printBookingData.TermsConditions !== ""
  //     ) {
  //       const operationsTermsConditions = JSON.parse(printBookingData.TermsConditions).map(
  //         (TermsCondition) => {
  //           const TermsConditionObj = {};

  //           // Iterate over each key-value pair in the container object
  //           for (const [key, value] of Object.entries(TermsCondition)) {
  //             // If the value is an empty string or null, set it to null
  //             TermsConditionObj[key] = value === "" ? null : value;
  //           }

  //           return TermsConditionObj;
  //         }
  //       );

  //       printBookingData.OperationsTermsConditions = operationsTermsConditions;
  //     }

  //     console.log(printBookingData);
  //     // Next, fetch PDF data
  //     const pdfDataResponse = await BookingAPIInstance.post(
  //       `booking_pdf`,
  //       {
  //         printBookingData, // Pass printBookingData directly in the request body
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: token,
  //         },
  //         responseType: "blob", // Set the response type to 'blob' to receive binary data
  //       }
  //     )
  //       .then((response) => {
  //         // Create a Blob from the binary data
  //         const pdfBlob = new Blob([response.data], {
  //           type: "application/pdf",
  //         });

  //         // Create a URL for the Blob
  //         const pdfUrl = URL.createObjectURL(pdfBlob);

  //         // Open the PDF in a new tab
  //         window.open(pdfUrl, "_blank");
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching PDF:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error fetching booking creation data:", error);
  //     // Handle error if necessary
  //   }
  // };


  const handlePrint = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      const token = JSON.parse(localStorage.getItem("token"));
  
      const printBookingDataResponse = await BookingAPIInstance.get(
        `get_booking_print?userId=${userId}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      const printBookingData = printBookingDataResponse.data.data[0];
  
      const processContainers = (data, key) => {
        if (data && data[key] && data[key] !== "") {
          return JSON.parse(data[key]).map((item) => {
            const obj = {};
            for (const [key, value] of Object.entries(item)) {
              obj[key] = value === "" ? null : value;
            }
            return obj;
          });
        }
        return [];
      };
  
      printBookingData.Operations = processContainers(printBookingData, 'Containers');
      printBookingData.OperationsVesselDetails = processContainers(printBookingData, 'VesselDetails');
      printBookingData.OperationsTermsConditions = processContainers(printBookingData, 'TermsConditions');
  
      console.log(printBookingData);
  
      // Next, fetch PDF data
      const pdfDataResponse = await BookingAPIInstance.post(
        `booking_pdf`,
        {
          printBookingData, // Pass printBookingData directly in the request body
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          responseType: "blob", // Set the response type to 'blob' to receive binary data
        }
      );
  
      // Create a Blob from the binary data
      const pdfBlob = new Blob([pdfDataResponse.data], {
        type: "application/pdf",
      });
  
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");
  
    } catch (error) {
      console.error("Error fetching booking creation data:", error);
      // Handle error if necessary
    }
  };

  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
            <TbAdjustmentsHorizontal
              onClick={
                isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
              }
              size={25}
              style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
            />
            &nbsp;
            <div className="relative inline-block">
              <DuplicateBooking
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                onApply={handleApply}
                setDuplicateBookingState={setDuplicateBookingState}
                setFormData={setFormData}
                formData={formData}
              />
              &nbsp;&nbsp;
              <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
                <Link to="/createbooking">Create Booking</Link>
              </button>
            </div>
            &nbsp;&nbsp;
            <div className="relative inline-block">
              <button
              
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={toggleActionDropdown}
              >
                Actions
              </button>
              &nbsp;&nbsp;
              {isActionOpen && (
                <div 
                ref = {dropdownRef}
                className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
                  {/* {isOpenPrint && ( */}
                  <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                    <FiPrinter size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={() => {
                        checkValidation.handleEditPrint();
                        handleActionSelection();
                        handlePrint();
                      }}
                    >
                      Print
                    </span>
                  </a>
                   {/* )} */}
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      onOpenDownload();
                      setOverlay(<OverlayOne />);
                      toggleActionDropdown();
                    }}
                  >
                    <MdOutlineDownloadForOffline size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Download
                    </span>
                  </a>
                </div>
              )}
            </div>
            <>
              {isOpenFilter && (
                <>
                <div style={backdropStyle}></div> 
                <div className={`FilterModal`}>
                  {/* {${scrollBehavior}} */}
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200,
                    }}
                    cancel=".cancelDragAndDrop"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Criteria
                    </header>
                    <div
                      className="closemodule cancelDragAndDrop"
                      onClick={() => {
                        onCloseFilter(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    <FormControl className="cancelDragAndDrop">
                      {filterRows.map((index) => (
                        <Flex key={index}>
                          <Select
                            id={`column-select-${index}`}
                            placeholder="Select"
                            onChange={(e) => handleFilterTypeSelect(e, index)}
                            value={filterConditions[index]?.column || "select"}
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            style={{ position: "relative" }}
                          >
                            <option value="BookingType">Booking Type</option>
                            <option value="Product">Product</option>
                            <option value="CompanyName">Company Name</option>
                            <option value="Sales">Sales Person</option>
                            <option value="Pol">POL</option>
                            <option value="Pod">POD</option>
                            <option value="Cargo">Cargo</option>
                            <option value="CargoNature">Cargo Nature</option>
                          </Select>
                          &nbsp;&nbsp;
                          <Select
                            id={`filter-type-${index}`}
                            onChange={(e) =>
                              handleFilterConditionSelect(e, index)
                            }
                            value={
                              filterConditions[index]?.condition || "select"
                            }
                            placeholder="Select"
                          >
                            {filterConditions[index]?.column ===
                              "DeletionFlag" ||
                            filterConditions[index]?.column ===
                              "ConversionFlag" ? (
                              <>
                                <option value="equals">=</option>
                                <option value="not-equals">!=</option>
                                <option value="greater">&gt;</option>
                                <option value="lesser">&lt;</option>
                                <option value="greater-equal">&gt;=</option>
                                <option value="lesser-equal">&lt;=</option>
                              </>
                            ) : (
                              <>
                                <option value="is">is</option>
                                <option value="contains">contains</option>
                                <option value="startsWith">starts with</option>
                                <option value="endsWith">ends with</option>
                              </>
                            )}
                          </Select>
                          &nbsp;&nbsp; &nbsp;&nbsp;
                          {filterConditions[index]?.column !== "BookingType" &&
                            filterConditions[index]?.column !== "Product" &&
                            filterConditions[index]?.column !== "Sales" &&
                            filterConditions[index]?.column !== "CompanyName" &&
                            filterConditions[index]?.column !== "Pol" &&
                            filterConditions[index]?.column !== "Pod" && (
                              <Input
                                type="text"
                                id={`value-input-${index}`}
                                value={filterConditions[index]?.value || ""}
                                onChange={(e) =>
                                  handleFilterValueChange(e, index)
                                }
                              />
                            )}
                          &nbsp;&nbsp;
                          {filterConditions[index]?.column ===
                            "BookingType" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option value="">Select Booking Type</option>
                              <option value="Original">Original</option>
                              <option value="Dummy">Dummy</option>
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Product" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Product</option>
                              {productData.map((data) => (
                                <option value={data.Product}>
                                  {data.Product}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column ===
                            "CompanyName" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Company </option>
                              {CustomerData.map((data) => (
                                <option value={data.Company}>
                                  {data.Company}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Sales" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Sales </option>
                              {salesPersonData.map((data) => (
                                <option value={data.fullname}>
                                  {data.fullname}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Pol" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Pol </option>
                              {portData.map((data) => (
                                <option value={data.Port}>{data.Port}</option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Pod" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Pod </option>
                              {portData.map((data) => (
                                <option value={data.Port}>{data.Port}</option>
                              ))}
                            </Select>
                          )}
                          {/* Conditionally render the input field based on the selected column */}
                          {/* {["FollowUpDate", "ChangedAt", "CreatedAt"].includes(filterConditions[index]?.column) ? (
                        <Input
                          type="date"
                          id={`value-input-${index}`}
                          value={filterConditions[index]?.value || ""}
                          onChange={(e) => handleFilterValueChange(e, index)}
                        />
                      ) : (
                        <Input
                          type="text"
                          id={`value-input-${index}`}
                          value={filterConditions[index]?.value || ""}
                          onChange={(e) => handleFilterValueChange(e, index)}
                        />
                      )} */}
                          &nbsp;&nbsp;{" "}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={() => addFilterRow()}
                          />
                          {filterRows?.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </FormControl>
                    <div
                      className="cancelDragAndDrop"
                      style={{
                        float: "right",
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSubmitFilter();
                          onCloseFilter();
                        }}
                        mr={3}
                      >
                        Apply
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleClearFilters();
                          onCloseFilter();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      <RadioGroup
                        className="cancelDragAndDrop"
                        style={{ flex: 1 }}
                        value={handleCheckboxFilter}
                      >
                        <Stack direction="row">
                          <Radio value="AND" onChange={handleCheckboxChange}>
                            All
                          </Radio>
                          <Radio value="OR" onChange={handleCheckboxChange}>
                            Any
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>

            <>
              {isOpenManagecolumn && (
                <>
                <div style={backdropStyle}></div> 
                <div style={ManagecolumnmodalStyleUpdate}>
                  <div></div>
                  <Rnd
                    style={Managecolumn}
                    default={{
                      x: 0,
                      y: 0,
                      width: 650,
                      height: 400
                    }}
                    cancel=".cancelDragAndDrop"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Manage Column
                    </header>
                    <div
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      style={closeModal}
                      className="closemodule cancelDragAndDrop"
                    >
                      <CgClose />
                    </div>
                    <br />

                    <div className="grid gap-x-8 gap-y-4 grid-cols-3 cancelDragAndDrop">
                      {tableColumnsBooking.map(
                        (label, index) =>
                          label.key !== "id" && (
                            <label
                              key={index}
                              className="flex items-center"
                              draggable
                              onDragStart={(event) =>
                                handleDragStart(event, index)
                              }
                              onDragOver={handleDragOver}
                              onDrop={(event) => handleDrop(event, index)}
                            >
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                disabled={
                                  label.key === "BookingType" ||
                                  label.key === "BookingNumber" ||
                                  label.key === "Product" ||
                                  label.key === "Pol" ||
                                  label.key === "Pod" ||
                                  label.key === "CompanyName" ||
                                  label.key === "Sales"
                                }
                                checked={label.isVisible}
                                onChange={(event) => {
                                  handleCheckboxColumn(event, label);
                                }}
                              />
                              <span
                                className="ml-2"
                                style={{ cursor: "all-scroll" }}
                              >
                                {label.label}
                              </span>
                              {label.isDisabled && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </label>
                          )
                      )}
                    </div>

                    <div
                      className="cancelDragAndDrop"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        float: "right",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="green"
                      >
                        OK
                      </Button>
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="red"
                        ml={3}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>
              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}

              <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
                  <FiEdit
                    size={20}
                    onClick={() => {
                      checkValidation.handleEditClickMassupdate();
                    }}
                  />
                </div>
              </Tooltip>
              {isconvert ? (
            <Tooltip
              hasArrow
               label="Hide Job Converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              
                <BiShow size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
             label="Show Job Converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
              <BiHide size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          )}
              <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
                  <RiDeleteBinLine
                    size={20}
                    onClick={() => {
                      checkValidation.handleEditClickMassdelete();
                    }}
                  />
                </div>
              </Tooltip>
              {isdelete ? (
                <Tooltip
                  hasArrow
                  label="Hide old records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiShow size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  hasArrow
                  label="Show old records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiHide size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              )}
              <Tooltip
                hasArrow
                label="Select all pages"
                bg="gray.300"
                color="black"
              >
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <MdSelectAll
                    onClick={() => {
                      handleCheckbox(bookingData.map((item) => item.id));
                      setCheckBoxAll((prev) => {
                        if (prev === true) {
                          setCheckBoxSelected([]);
                        }
                        return !prev;
                      });
                    }}
                    size={24}
                  />
                </div>
              </Tooltip>
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, bookingData?.length)}
                  </span>{" "}
                  of <span className="font-medium">{bookingData?.length}</span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <SelectAllPages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggableBookingTable
                isLoading={isLoading}
                columns={columns2}
                bookingData={bookingData}
                getData={getData}
                data={bookingData.slice(indexOfFirstItem, indexOfLastItem)}
                setBookingData={setBookingData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsBooking={tableColumnsBooking}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}

      <>
        {
          <div>
            {isOpenMassupdate && (
              <>
              <div style={backdropStyle}></div>
              <div className={`MassUpdateModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 500,
                    // height: 200
                  }}
                  // cancel="X"
                  cancel=".cancelDragAndDrop"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Mass Update
                  </header>
                  <div
                    className="closemodule cancelDragAndDrop"
                    onClick={() => {
                      onCloseMassupdate(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl className="closemodule cancelDragAndDrop">
                    <Flex>
                      <Select
                        mt={7}
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={selectedOption}
                        onChange={(e) => handleOptionChange(e.target.value)}
                      >
                        <option value="Select a field">Select a field</option>
                        <option value="BookingType">Booking Type</option>
                        <option value="Product">Product</option>
                        <option value="Scope">Scope</option>
                        <option value="Region">Region</option>
                        <option value="SICutOff">SICutOff</option>
                      </Select>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {selectedOption === "BookingType" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Booking Type</option>
                          <option value="Original">Original</option>
                          <option value="Dummy">Dummy</option>
                        </Select>
                      ) : selectedOption === "Product" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Product</option>
                          {productData.map((data) => (
                            <option key={data.Product} value={data.Product}>
                              {data.Product}
                            </option>
                          ))}
                        </Select>
                      ) : selectedOption === "Scope" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Scope</option>
                          {scopeData.map((data) => (
                            <option key={data.Scope} value={data.Scope}>
                              {data.Scope}
                            </option>
                          ))}
                        </Select>
                      ) : selectedOption === "Region" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Region</option>
                          {regionData.map((data) => (
                            <option key={data.Region} value={data.Region}>
                              {data.Region}
                            </option>
                          ))}
                        </Select>
                      ) : selectedOption === "SICutOff" ? (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="date"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        />
                      ) : (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="text"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          disabled={selectedOption === "Select a field"}
                        />
                      )}
                    </Flex>
                  </FormControl>
                  <br />
                  <Button
                    className="cancelDragAndDrop"
                    colorScheme="green"
                    onClick={() => {
                      onOpenReportMassupdate();
                      onCloseMassupdate();
                    }}
                    mr={3}
                  >
                    Submit
                  </Button>
                </Rnd>
              </div>
              </>
            )}
          </div>
        }
      </>

      <>
        {isOpenReportMassupdate && (

          <>
          <div style={backdropStyle}></div>
          <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Update Booking
              </header>
              <div
                className="closemodule cancelDragAndDrop"
                onClick={() => {
                  onCloseReportMassupdate(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl className="cancelDragAndDrop">
                {" "}
                Are you sure you want to Update the booking?
              </FormControl>
              <br />
              <Button
                className="cancelDragAndDrop"
                colorScheme="green"
                onClick={() => {
                  handleSubmitUpdate();
                  onCloseReportMassupdate();
                }}
              >
                Update
              </Button>
              <Button
                className="cancelDragAndDrop"
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassupdate();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>

      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Booking
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
                get_booking
              >
                <CgClose />
              </div>
              <br />
              <FormControl className="closemodule cancelDragAndDrop">
                {jobWarning
                  ? jobWarning
                  : "Are you sure you want to delete the booking?"}
              </FormControl>
              <br />
              {jobWarning ? (
                <>
                  <Button
                    colorScheme="green"
                    onClick={handleSubmitDeleteWithJob}
                    ml={3}
                    value="WITH"
                  >
                    Delete with Job
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={handleSubmitDeleteWithJob}
                    ml={3}
                    style={{ background: "red", color: "white" }}
                    value="WITHOUT"
                  >
                    Delete without Job
                  </Button>
                </>
              ) : (
                <>
                  <Button colorScheme="green" onClick={handleSubmitDelete}>
                    Delete
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={onCloseReportDelete}
                    style={{ background: "red", color: "white" }}
                    ml={3}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>

      <>
        {isOpenDownload && (
          <>
          <div style={backdropStyle}></div>
          <div className={`DownloadExcelModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              //cancel="X"
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl className="closemodule cancelDragAndDrop">
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
                  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
                  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                    <option value="Text">Text</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleExport();
                }}
                disabled={isDownloading}
                mr={3}
              >
                {isDownloading ? "Downloading..." : "Export Data"}
              </Button>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>

      
    </div>
  );
};

export default Booking;
