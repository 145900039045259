import React from "react";
import { useState, useEffect, useRef } from "react";
import { RateAPIInstance, MasterDataAPIInstance } from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment, { utc } from "moment/moment";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";


// Internal module import
import "./DraggableRateTable.css";


// Chakara ui modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";


import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input, Table, Th, Tbody, Tr, Thead, Td, TableContainer, Tabs, TabList, TabPanel, TabPanels, Tab, VStack,
  Text, CardFooter, CardBody, CardHeader, Card, Heading,
} from "@chakra-ui/react";


// Icons Imports
import { CloseIcon } from "@chakra-ui/icons";


import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import TextArea from "antd/es/input/TextArea";


const DraggableRateTable = ({
  isLoading,
  columns,
  data,
  rateData,
  getData,
  setRateData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsRate,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [CountryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedRate, setSelectedRate] = useState({});
  const [editedRate, setEditedRate] = useState({
    EnquiryRateContainers: selectedRate?.EnquiryRateContainers || "",
  });
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [files, setFiles] = useState([]);
  const [Attachments, setAttachments] = useState([])
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [sizeContainer, setSizeContainer] = useState("xl");
  const [selectedurls, setSelectedurls] = useState([]);
  const [VendorData, setVendorData] = useState([]);
  const [ModeData, setModeData] = useState([]);
  const [AirportData, setAirportData] = useState([]);
  const [PortData, setPortData] = useState([]);
  const [EquipmentData, setEquipmentData] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [isRateContainerOpen, setIsRateContainerOpen] = useState(false);
  const [isRnquiryRateContainerOpen, setIsEnquiryRateContainerOpen] = useState(false);
  const [sizeRateContainer, setSizeRateContainer] = useState("xl");
  const [sizeEnquiryRateContainer, setSizeEnquiryRateContainer] = useState("xl");
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
    FormattedContainers: "",
    files:[]
  });
  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [urls, setUrls] = useState([]);
  const [previewrate, setPreviewRate] = useState(null);
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files;
  //   const newFiles = Array.from(fileList); // Convert fileList to an array


  //   // Generate an array of objects, each containing file properties
  //   const newAttachments = newFiles.map((file, index) => ({
  //     // Generate a unique ID (you can replace this with your own logic)
  //     name: file.name,
  //     size:file.size,
  //   }));


  //   // Update Attachments state with the new files
  //   setAttachments((prevAttachments) => [
  //     ...prevAttachments,
  //     ...newAttachments,
  //   ]);


  //   // Append new files to the state for table display
  //   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  // };
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files; // Get file list
  //   const newFiles = Array.from(fileList); // Convert FileList to Array


  //   // Create URLs for the new files
  //   const newAttachments = newFiles.map((file) => ({
  //     // Generate a unique ID (you can replace this with your own logic)
  //     name: file.name,
  //     size: file.size,
  //   }));
  //   const newUrls = newFiles.map((file) => URL.createObjectURL(file));


  //   // Update state with files and URLs
  //   setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments]);
  //   setUrls((prevUrls) => [...prevUrls, ...newUrls]);


  //   console.log("Uploaded Files:", newFiles);
  // };
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files
        ? [...prevData.files, ...selectedFiles]
        : selectedFiles, // Safely append files
    }));
  };
  const handleUploadClick = () => {
    // Open the file dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  // UseEffects
  useEffect(() => {
    setEditedRate(selectedRate);
  }, [selectedRate]);


  useEffect(() => {
    getOriginCountry(editedRate["OriginCountry"]);
  }, [selectedRate, editedRate["OriginCountry"]]);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");


        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Equipment API 




  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Scope API
  const [ScopeData, setScopeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Incoterm Api 
  const [IncotermData, setIncotermData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Packing Type API 
  const [PackingTypeData, setPackingTypeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Lead Mode API


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_vendor");
        setVendorData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_airport");
        setAirportData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const calculateFormattedContainers = () => {
      // Extract EnquiryRateContainers from editedRate
      const enquiryRateContainers =
        editedRate?.EnquiryRateContainers || selectedRate?.EnquiryRateContainers || "";


      // Parse and filter valid container entries
      const filteredContainers = enquiryRateContainers
        .split(";") // Split into individual containers
        .filter((rate) => rate.trim() !== "") // Remove empty entries
        .map((rate) => {
          const attributes = rate.split(","); // Split each rate into key-value pairs
          return {
            Equipment: attributes[1]?.split(": ")[1]?.trim() || "", // Extract Equipment
            Quantity: parseFloat(attributes[2]?.split(": ")[1]) || 0, // Extract Quantity
          };
        })
        .filter(
          (container) =>
            container.Equipment && container.Quantity && !isNaN(container.Quantity)
        );


      // Format string: "2 X 20 FR(OW/OH), 1 X 40 OT (OH)"
      const formattedString = filteredContainers
        .map((container) => `${container.Quantity} X ${container.Equipment}`)
        .join(", ");


      return formattedString;
    };


    // Calculate formatted string
    const formattedString = calculateFormattedContainers();


    // Update formData and editedRate dynamically
    setFormData((prevFormData) => ({
      ...prevFormData,
      FormattedContainers: formattedString, // Updates formattedContainers
    }));


    setEditedRate((prev) => ({
      ...prev,
      TotalEquipmentValue: formattedString, // Keep TotalEquipmentValue in sync
    }));
  }, [editedRate?.EnquiryRateContainers, selectedRate?.EnquiryRateContainers]);




  const handleViewRate = (rateData) => {
    const rateString = rateData
      .split(",") // Split the Rate data into key-value pairs
      .find((item) => item.trim().startsWith("Rate:")) // Find the "Rate" key
      ?.split("Rate:")[1] // Extract only the value part
      ?.trim();


    console.log("Rate to preview:", rateString); // Debugging
    setPreviewRate(rateString || "No rate data available."); // Handle missing data gracefully
  };


  const {
    isOpen: isOpenContainer,
    onOpen: onOpenContainer,
    onClose: onCloseContainer,
  } = useDisclosure();


  const handleCloseConatiner = () => {
    setSelectedRowId(null);
    setIsContainerOpen(false);
    onCloseContainer(true);
  };
  const handleFullSizeContainer = () => {
    setSizeContainer("xl");
    onOpenContainer();
  };
  const {
    isOpen: isOpenRateContainer,
    onOpen: onOpenRateContainer,
    onClose: onCloseRateContainer,
  } = useDisclosure();




  const handleCloseRateConatiner = () => {
    setSelectedRowId(null);
    setIsRateContainerOpen(false);
    onCloseRateContainer(true);
  };
  const handleFullSizeRateContainer = () => {
    setSizeRateContainer("xl");
    onOpenRateContainer();
  };
  const {
    isOpen: isOpenEnquiryRateContainer,
    onOpen: onOpenEnquiryRateContainer,
    onClose: onCloseEnquiryRateContainer,
  } = useDisclosure();
  const handleCloseEnquiryRateConatiner = () => {
    setSelectedRowId(null);
    setIsEnquiryRateContainerOpen(false);
    onCloseEnquiryRateContainer(true);
  };
  const handleFullSizeEnquiryRateContainer = () => {
    setSizeEnquiryRateContainer("xl");
    onOpenEnquiryRateContainer();
  };
  useEffect(() => {
    const initialType = formData["TypeofPort"] || selectedRate["TypeofPort"] || "";
    setSelectedType(initialType);
  }, [formData, selectedRate]);
  // const handleTypeChange = (e) => {
  //   setSelectedType(e.target.value);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  //   handleChange(e);


  // };
  const handleTypeChange = (e) => {
    const { name, value } = e.target;


    // Update selected type (Port or AirPort)
    setSelectedType(value);


    // Update formData with the selected TypeofPort
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));


    // Optionally, clear dependent fields (OriginPortAirport and DestinationPortAirport)
    setEditedRate((prev) => ({
      ...prev,
      OriginPortAirport: "",
      DestinationPortAirport: "",
    }));
    handleChange(e);
  };


  // const handleChangePhone = (value, countryData) => {
  //   if (countryData && countryData.dialCode) {
  //     const dialCodeLength = countryData.dialCode.length;
  //     const Phone = value.slice(dialCodeLength);


  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       Phone,
  //       PhoneWithCode: value,
  //       CountryData: countryData,
  //     }));
  //   }
  // };
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;


      // Update editedRate state
      setEditedRate((prev) => ({
        ...prev,
        [name]: value,
      }));


      if (name === "Country") {
        // Update selectedRate state with new Country value and reset City to empty string
        setSelectedRate((prev) => ({
          ...prev,
          Country: value,
          City: "",
        }));


        // Fetch city data based on the selected Country
        const { data } = await MasterDataAPIInstance.get(
          `get_city?countryData=${value}`
        );


        // Update cityData state with fetched city data
        setCityData(data.data);
      } else if (name === "City") {
        // Update selectedRate state with new City value
        setSelectedRate((prev) => ({
          ...prev,
          City: value,
        }));
      }


      // Update editedRate state with updated flags
      setEditedRate((prev) => ({
        ...prev,
      }));
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };




  const getOriginCountry = async (country) => {
    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  console.log("SL:", selectedRate);
  // Single Update
  // const handleSubmitUpdate = async () => {
  //   const updateData = {
  //     ...editedRate,
  //     DialCode: formData?.CountryData
  //       ? formData.CountryData.dialCode
  //       : editedRate.DialCode, // Use the updated dial code
  //     Phone: formData?.Phone ? formData.Phone : editedRate.Phone, // Use the updated phone number
  //   };
  //   console.log("Updated data", updateData);
  //   try {
  //     const { data } = await RateAPIInstance.put(
  //       `/rates/singleupdate_rate?userId=?${JSON.parse(localStorage.getItem("user")).userId
  //       }`,
  //       {
  //         ...updateData,
  //         id: selectedRate.id,
  //         userId: JSON.parse(localStorage.getItem("user")).userId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: JSON.parse(localStorage.getItem("token")),
  //         },
  //       }
  //     );


  //     if (data?.success) {
  //       toast({
  //         title: "Rates Updated Successfully!",
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "success",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       // Optionally, you may want to update the selectedRate state here
  //       setSelectedRate((prevSelectedRate) => ({
  //         ...prevSelectedRate,
  //         ...editedRate,
  //       }));
  //       getData();
  //     } else {
  //       toast({
  //         title: data?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     if (error?.response?.data?.message) {
  //       toast({
  //         title: error?.response?.data?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else if (error?.response?.statusText) {
  //       toast({
  //         title: error?.response?.statusText,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else {
  //       toast({
  //         title: error?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   }
  // };




  // using Formdata
  const handleSubmitUpdate = async (event) => {
  
    // Create FormData to send in the API request
    const formDataToSend = new FormData();
  
    // Append basic fields from editedRate to FormData
    Object.keys(editedRate).forEach((key) => {
      // if (key !== "files" && key !== "EnquiryRateContainers" && key !== "RatesContainers"
      //   &&key !== "DeletionFlag"&&key !== "DeletionFlag1") {
      //   formDataToSend.append(key, editedRate[key]);
        
      // }
      if (key !== "files"&& key!=="DeletionFlagR"&& key!="DeletionFlagEn") {
        formDataToSend.append(key, editedRate[key]);
      }
    });
  
    // Append enquiryContainers and ratesContainers from editedRate as JSON strings
    // formDataToSend.append("EnquiryRateContainers", JSON.stringify(editedRate.EnquiryRateContainers));
    // formDataToSend.append("RatesContainers", JSON.stringify(editedRate.RatesContainers));
    formDataToSend.append("DeletionFlagR", JSON.stringify(editedRate.DeletionFlagR));
    formDataToSend.append("DeletionFlagEn", JSON.stringify(editedRate.DeletionFlagEn));
  
    // Append files from editedRate
    // if (editedRate.files) {
    //   editedRate.files.forEach((file) => {
    //     formDataToSend.append("file", file);
    //   });
    // }
    formData.files.forEach((file) => {
      formDataToSend.append("files", file);
    });
    // Append selectedRate id and userId
  
    formDataToSend.append("userId", JSON.parse(localStorage.getItem("user")).userId);
  
    try {
      const { data } = await RateAPIInstance.put(
        `/rates/singleupdate_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
  
      // Handle response
      if (data.success) {
        toast({
          title: "Rates Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectedRate((prevSelectedRate) => ({
          ...prevSelectedRate,
          ...editedRate,
        }));
        getData();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  // const fileURL = URL.createObjectURL(file);
  // // Use the URL for the link
  // URL.revokeObjectURL(fileURL); // Revoke it after the file is used


  //  Function to open the modal and fetch rate data for a particular ID
  const handleOpenModal = async (id, RateNumber) => {
    try {
      const selectedRateData = rateData.find((rate) => rate.id === id);
      //const RateNumber = rateData((rate) => rate.id === id);
      setSelectedRate(selectedRateData);
      handleFullSizeClick();
      setSelectedRowId(id);


      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${selectedRateData?.Country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    console.log(RateNumber);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };


  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );


      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);
  useEffect(() => {
    // Cleanup URLs when attachments or URLs state changes
    return () => {
      urls.forEach((url) => {
        URL.revokeObjectURL(url); // Revoke each URL
      });
    };
  }, [urls]);


  const sortTable = (key) => {
    let newSortOrder = "asc";


    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }


    const sortedData = [...rateData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });


    setSortKey(key);
    setSortOrder(newSortOrder);


    if (typeof setRateData === "function") {
      setRateData(sortedData);
    }
  };


  const handleRemoveFile = (index) => {
    // Remove file and URL at the specified index
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );
  };
  const handleOpenContainer = async (RateNumber) => {
    try {
      const selectedRateData = rateData.find(
        (rate) => rate.RateNumber === RateNumber
      );
      setSelectedRate(selectedRateData);
      handleFullSizeContainer();
      setSelectedRowId(RateNumber);
      console.log(selectedRateData, "Rate Data Id");
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenRateContainer = async (RateNumber) => {
    try {
      const selectedRateData = rateData.find(
        (rate) => rate.RateNumber === RateNumber
      );
      setSelectedRate(selectedRateData);
      handleFullSizeRateContainer();
      setSelectedRowId(RateNumber);
      console.log(selectedRateData, "Rate Data Id");
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenEnquiryRateContainer = async (RateNumber) => {
    try {
      const selectedRateData = rateData.find(
        (rate) => rate.RateNumber === RateNumber
      );
      setSelectedRate(selectedRateData);
      handleFullSizeEnquiryRateContainer();
      setSelectedRowId(RateNumber);
      console.log(selectedRateData, "Rate Data Id");
    } catch (error) {
      console.log(error);
    }
  };


  // Grade Map
  const [rateTypeData, setrateTypeData] = useState([]);
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data } = await Axios.get("/masterdataget_mode");
  //       setmodeData(data.data);
  //     } catch (error) {
  //       toast({
  //         title: error.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   };
  //   getData();
  // }, []);
  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const handleSelectUrl = (event, url) => {
    if (event.target.checked) {
      setSelectedurls((prev) => [...prev, url]);
    } else {
      setSelectedurls((prev) => prev.filter((u) => u !== url));
    }
  };


  const handleViewSelectedPDFs = () => {
    if (selectedurls.length < 1) {
      toast({
        title: "Please select at least one row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }


    if (selectedurls.length > 1) {
      toast({
        title: "Please select only one row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }


    // If exactly one URL is selected, open it
    selectedurls.forEach((url) => {
      window.open(url, "_blank");
    });
  };


  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };


  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };


  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };


  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //console.log(citiesDataSet);
  // const [showFactCheck, setShowFactCheck] = useState(false);
  // console.log(checkBoxSelected, currentPage, activePageCheckBoxSelect);
  // const  addrate = () => {
  //   setEditedRate(prev => ({
  //     ...prev,
  //     RatesContainers: (prev.RatesContainers || "") + "Vendor: ,Rate: ;",
  //   }));




  // };
  // console.log(editedRate);
  // function removeRowByIndex(data, indexToRemove) {
  //   let rows = data?.split(';').filter(row => row.trim() !== ''); // Split the data into rows
  //   if (indexToRemove >= 0 && indexToRemove < rows.length) { // Check if index is within bounds
  //     rows.splice(indexToRemove, 1); 
  //     // Remove row at specified index
  //   }
  //   return rows.join('; '); // Join the rows back into a single string
  // }


  // const removeRate = (indexToRemove) => {
  //   setEditedRate(prev => ({
  //     ...prev,
  //     "RatesContainers": removeRowByIndex(prev.RatesContainers, indexToRemove)
  //   }));


  // };
  const addrate = () => {
    setEditedRate((prev) => ({
      ...prev,
      RatesContainers:
        (prev.RatesContainers || "") +
        ";Container_Id: , Vendor: , Rate: ",
    }));
  };
  // const addEnquiryrate = () => {
  //   setEditedRate((prev) => ({
  //     ...prev,
  //     EnquiryRateContainers:
  //       (prev.EnquiryRateContainers|| "") +
  //       ";Equipment: , Quantity: , Weight: ,Dimensions: ",
  //   }));
  // };




  const addEnquiryrate = () => {
    setEditedRate((prev) => ({
      ...prev,
      EnquiryRateContainers:
        (prev.EnquiryRateContainers || "") +
        ";Container_Id: ,Equipment: , Quantity: , Weight: , Dimensions: ",
    }));
  };


  // function removeRowByIndex(data, indexToRemove) {
  //   let rows = data?.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
  //   if (indexToRemove >= 0 && indexToRemove < rows.length) {
  //     // Check if index is within bounds
  //     rows.splice(indexToRemove, 1); // Remove row at specified index
  //   }
  //   return rows.join("; "); // Join the rows back into a single string
  // }
  function removeRowByIndex(data, indexToRemove) {
    // Split the data into rows and filter out empty rows
    let rows = data?.split(";").map((row) => row.trim()).filter((row) => row !== "");
    const removedContainerIds = []; // Array to track removed container IDs


    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      const removedRow = rows[indexToRemove]; // Get the row being removed


      // Extract Container_ID from the row
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_Id:"))
        ?.split(":")[1]
        ?.trim();


      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Add to DeletionFlag
      }


      rows.splice(indexToRemove, 1); // Remove row at specified index
    }


    return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }
  function removeRowByEnIndex(data, indexToRemove) {
    // Split the data into rows and filter out empty rows
    let rows = data?.split(";").map((row) => row.trim()).filter((row) => row !== "");
    const removedContainerIds = []; // Array to track removed container IDs


    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      const removedRow = rows[indexToRemove]; // Get the row being removed


      // Extract Container_ID from the row
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_Id:"))
        ?.split(":")[1]
        ?.trim();


      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Add to DeletionFlag
      }


      rows.splice(indexToRemove, 1); // Remove row at specified index
    }


    return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }


  const removeRate = (indexToRemove) => {
    setEditedRate((prev) => {
      // Remove the container
      const { updatedRows, removedContainerIds } = removeRowByIndex(prev.RatesContainers, indexToRemove);


      return {
        ...prev,
        RatesContainers: updatedRows, // Update RatesContainers after removal
        DeletionFlagR: [...(prev.DeletionFlagR || []), ...removedContainerIds], // Append new deletion flags
      };
    });
  };
  // const removeEnquiryRate = (indexToRemove) => {
  //   setEditedRate((prev) => ({
  //     ...prev,
  //     EnquiryRateContainers: removeRowByIndex(prev.EnquiryRateContainers, indexToRemove),
  //   }));
  // };
  const removeEnquiryRate = (indexToRemove) => {
    setEditedRate((prev) => {
      // Remove the container
      const { updatedRows, removedContainerIds } = removeRowByEnIndex(prev.EnquiryRateContainers, indexToRemove);


      return {
        ...prev,
        EnquiryRateContainers: updatedRows, // Update EnquiryRateContainers after removal
        DeletionFlagEn: [...(prev.DeletionFlagEn || []), ...removedContainerIds], // Append new deletion flags
      };
    });
  };
  // const removeRate = (indexToRemove) => {
  //   setEditedRate((prev) => ({
  //     ...prev,
  //     RatesContainers: removeRowByIndex(prev.RatesContainers, indexToRemove),
  //   }));
  // };
  function handleRateChange(name, value, str) {
    // Parse the string into an array of objects
    let Rate = str?.split(';').map(item => {
      let obj = {};
      item.trim()?.split(',').forEach(prop => {
        let [key, val] = prop?.split(':').map(p => p.trim());
        obj[key] = isNaN(val) ? val : parseFloat(val);
      });
      return obj;
    });


    // Update the specified object
    let [propertyName, index] = name?.split('-');
    index = parseInt(index);
    Rate[index][propertyName] = value;


    // Convert the array back into a string
    let updatedStr = Rate.map(obj => {
      return Object.entries(obj).map(([key, val]) => `${key}: ${val}`).join(', ');
    }).join('; ');


    return updatedStr;
  }
  function handleRatesChange(name, value, str) {
    // Parse the string into an array of objects
    let Rate = str?.split(';').map((item) => {
      let obj = {};
      item
        .trim()
        ?.split(',')
        .forEach((prop) => {
          let [key, val] = prop?.split(':').map((p) => p.trim());
          if (!val) val = ""; // Ensure empty fields are treated as empty strings
          obj[key] = isNaN(val) || val === "" ? val : parseFloat(val);
        });
      return obj;
    });


    // Update the specified object
    let [propertyName, index] = name?.split('-');
    index = parseInt(index);


    // Prevent index errors
    if (!Rate[index]) Rate[index] = {};


    // Update the specific property
    Rate[index][propertyName] = value;


    // Convert the array back into a string
    let updatedStr = Rate.map((obj) =>
      Object.entries(obj)
        .map(([key, val]) => `${key}: ${val}`)
        .join(', ')
    ).join('; ');


    return updatedStr;
  }


  const handleInnerRateChange = async (e) => {
    try {
      const { name, value } = e.target;


      const ans = handleRateChange(name, value, editedRate.RatesContainers);




      // Update editedUser state
      setEditedRate((prev) => ({
        ...prev,
        "RatesContainers": ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  }
  const handleInnerEnquiryRateChange = async (e) => {
    try {
      const { name, value } = e.target;


      const ans = handleRatesChange(name, value, editedRate.EnquiryRateContainers);




      // Update editedUser state
      setEditedRate((prev) => ({
        ...prev,
        "EnquiryRateContainers": ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  }
  console.log("eee", editedRate, "sss", selectedRate)
  return (
    <div>
      {!isLoading ? (
        tableColumnsRate?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });


                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>


                {tableColumnsRate.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >


                        {(
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() =>
                            handleOpenModal(row?.id, row?.RateNumber)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsRate.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (<Badge colorScheme="green">Not Deleted</Badge>)
                              // ) : column.key === "Rates_Status" ? (
                              //   row[column.key] === 1 ? (
                              //     <Badge colorScheme="red">Not Completed</Badge>
                              //   ) : (<Badge colorScheme="green">Completed</Badge>)
                            ) : column.key === "CargoReadiness" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ValidTillDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Attachments" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenContainer(row?.RateNumber);
                                    }}
                                  >
                                    View Attachment
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : column.key === "RatesContainers" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenRateContainer(row?.RateNumber);
                                    }}
                                  >
                                    View RateValue
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : column.key === "EnquiryRateContainers" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenEnquiryRateContainer(row?.RateNumber);
                                    }}
                                  >
                                    View Containers
                                  </Button>
                                </>
                              ) : (
                                ""
                              )






                            ) :
                              (
                                row[column.key]
                              )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsRate.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        <>
          {isOpenContainer && (
            <>
              <div style={backdropStyle}></div>
              <div className={`MassUploadModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 1000,
                  }}
                  cancel=".cancelDragAndDrop"
                  allowAnyClick={true}
                >
                  <header>Rate Attachment</header>
                  <div
                    className="closemodule cancelDragAndDrop"
                    onClick={() => {
                      handleCloseConatiner(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>


                  <br />
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Select</Th>
                          <Th>Document ID</Th>
                          <Th>Filename</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {selectedRate?.Attachments?.split(";").map((attachment, index) => {
                          const attachmentDetails = attachment.trim()?.split(",");
                          const url = attachmentDetails.find(detail => detail.includes("URL:"))?.split(": ")[1];


                          return (
                            <Tr key={index}>
                              <Td>
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleSelectUrl(e, url)}
                                  className="cancelDragAndDrop"
                                />
                              </Td>
                              {attachmentDetails.map((detail, detailIndex) => {
                                const [key, value] = detail?.split(": ");
                                if (key.trim() === "URL" || key.trim() === "DeletionFlag") return null;
                                return (
                                  <Td
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                    key={detailIndex}
                                  >
                                    {value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>


                  <Button colorScheme="teal" size="sm" onClick={handleViewSelectedPDFs}>
                    View Selected
                  </Button>
                </Rnd>
              </div>
            </>
          )}
        </>








      </>
      <>
        {isOpenRateContainer && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <br />


                <header>Rate Value </header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseRateConatiner(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <br />
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Enquiry Number</Th>
                        <Th>Rate Number</Th>
                        <Th>Vendor</Th>
                        <Th>Rate</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedRate?.RatesContainers?.split(";").map((Rate, index) => {
                        const RateDetails = Rate.trim()?.split(",");
                        return (
                          <Tr key={index}>
                            {RateDetails.map((detail, detailIndex) => {
                              const [key, value] = detail?.split(": ");
                              if (key.trim() === "Rate") {
                                return (
                                  <Td key={detailIndex}>
                                    <Button
                                      colorScheme="teal"
                                      size="md"
                                      onClick={() => handleViewRate(Rate.trim())} // Pass the full Rate data
                                    >
                                      View Rate
                                    </Button>
                                  </Td>
                                );
                              }
                              if (["Enquiry_Number", "Rate_Number", "Vendor"].includes(key.trim())) {
                                return <Td key={detailIndex}>{value}</Td>;
                              }
                              return null;
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>


              </Rnd>


              {
                previewrate && (
                  <>
                    <div style={backdropStyle}></div>
                    <div className={`MassUploadModal ${scrollBehavior}`}>
                      <Rnd
                        style={style}
                        default={{
                          x: 0,
                          y: 0,
                          width: 1000,
                          // height: 200
                        }}
                        cancel=".cancelDragAndDrop"
                        allowAnyClick={true}
                      >
                        <br />
                        <header style={{ fontWeight: 'bold' }}>Rate Value</header>
                        <br />
                        <div
                          style={{
                            whiteSpace: "pre-wrap", // Preserve formatting (line breaks and spaces)
                            overflowY: "auto", // Make content scrollable if it overflows
                          }}
                        >
                          {previewrate}
                        </div>
                        <Button
                          onClick={() => setPreviewRate(null)}
                          style={{
                            marginTop: "10px",
                            padding: "5px 10px",
                            cursor: "pointer",
                            background: "#007BFF",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          Close
                        </Button>
                      </Rnd>
                    </div>
                  </>


                )
              }
            </div>
          </>
        )}
      </>
      <>
        {isOpenEnquiryRateContainer && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <br />


                <header>Enquiry Containers </header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseEnquiryRateConatiner(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <br />
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Equipment</Th>
                        <Th>Quantity</Th>
                        <Th>Weight</Th>
                        <Th>Dimensions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedRate?.EnquiryRateContainers?.split(";").map((Rate, index) => {
                        const RateDetails = Rate.trim()?.split(",");
                        return (
                          <Tr key={index}>
                            {RateDetails.map((detail, detailIndex) => {
                              const [key, value] = detail?.split(": ");
                              // Filter out Container_Id and DeletionFlag
                              if (key.trim() === "Container_Id" || key.trim() === "DeletionFlagEn") {
                                return null;
                              }
                              // Only display the required fields
                              if (
                                ["Equipment", "Quantity", "Weight", "Dimensions"].includes(
                                  key.trim()
                                )
                              ) {
                                return (
                                  <Td
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                    key={detailIndex}
                                  >
                                    {value}
                                  </Td>
                                );
                              }
                              return null;
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>


              </Rnd>
            </div>
          </>
        )}
      </>
      <>
        {isOpen && (
          <>
            <div style={backdropStyle}></div>
            <div className={`singleEditModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  height: 700,
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header
                  style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
                >
                  Edit Rate Information
                </header>
                <div
                  onClick={() => {
                    handleCloseModal(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                {selectedRate && Object.keys(selectedRate).length > 0 && (
                  <form>
                    <Badge variant="solid" colorScheme="red" fontSize="12pt">
                      Rate Number : {selectedRate["RateNumber"]}
                    </Badge>
                    <Tabs w="100%" variant="enclosed">
                      &nbsp;
                      <TabList className="cancelDragandDrop">
                        <Tab _selected={{ color: "white", bg: "#172554" }}>
                          Rate Information
                        </Tab>
                        <Tab _selected={{ color: "white", bg: "#172554" }}>View Attachments</Tab>
                        <Tab _selected={{ color: "white", bg: "#172554" }}>Upload Attachments</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            <GridItem colSpan={2}>
                              <FormControl>
                                <FormLabel>Mode</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="Mode"
                                  defaultValue={
                                    formData["Mode"] || selectedRate["Mode"]
                                  }
                                  value={editedRate["Mode"] || ""}
                                  onChange={handleChange}
                                >
                                  {ModeData.map((item) => (
                                    <option value={item.modeId}>{item.modeId}</option>
                                  ))}
                                </Select>
                                <FormLabel>OriginCountry</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="OriginCountry"
                                  defaultValue={
                                    formData["OriginCountry"] || selectedRate["OriginCountry"]
                                  }
                                  value={editedRate["OriginCountry"] || ""}
                                  onChange={handleChange}
                                >
                                  {CountryData.map((item) => (
                                    <option value={item.Country_name}>{item.Country_name}</option>
                                  ))}
                                </Select>




                                <FormLabel>DestinationCountry</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="DestinationCountry"
                                  defaultValue={
                                    formData["DestinationCountry"] || selectedRate["DestinationCountry"]
                                  }
                                  value={editedRate["DestinationCountry"] || ""}
                                  onChange={handleChange}
                                >
                                  {CountryData.map((item) => (
                                    <option value={item.Country_name}>{item.Country_name}</option>
                                  ))}
                                </Select>
                                <FormLabel>
                                  PickUpLocation<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Input
                                  type="text"
                                  name="PickUpLocation"
                                  defaultValue={
                                    formData["PickUpLocation"] || selectedRate["PickUpLocation"]
                                  }
                                  value={editedRate["PickUpLocation"] || ""}
                                  onChange={handleChange}


                                />
                                <FormLabel>
                                  <FormLabel>TypeOfPort</FormLabel>
                                  <Select className="cancelDragAndDrop"
                                    defaultValue={formData["TypeofPort"] || selectedRate["TypeofPort"]}
                                    value={editedRate["TypeofPort"] || ""}
                                    onChange={handleTypeChange}
                                    name="TypeofPort"
                                  >
                                    <option value="">Select TypeOfPort</option>
                                    <option value="Port">Port</option>
                                    <option value="AirPort">AirPort</option>
                                  </Select>
                                  OriginPort / Airport<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Select
                                  type="text"
                                  name="OriginPortAirport"
                                  defaultValue={
                                    formData["OriginPortAirport"] || selectedRate["OriginPortAirport"]
                                  }
                                  value={editedRate["OriginPortAirport"] || ""}
                                  onChange={handleChange}


                                >
                                  <option>Select OriginPortAirport</option>
                                  {selectedType === "Port" &&
                                    PortData.map((item) => (
                                      <option value={item.Port}>{item.Port}</option>


                                    ))}
                                  {selectedType === "AirPort" &&
                                    AirportData.map((item) => (
                                      <option value={item.Airport}>{item.Airport}</option>


                                    ))}
                                </Select>
                                <FormLabel>DestinationPort / Airport</FormLabel>
                                <Select
                                  type="text"
                                  name="DestinationPortAirport"
                                  defaultValue={
                                    formData["DestinationPortAirport"] || selectedRate["DestinationPortAirport"]
                                  }
                                  value={editedRate["DestinationPortAirport"] || ""}
                                  onChange={handleChange}
                                >
                                  <option>Select DestinationPort/Airport</option>
                                  {selectedType === "Port" &&
                                    PortData.map((item) => (
                                      <option value={item.Port}>{item.Port}</option>


                                    ))}
                                  {selectedType === "AirPort" &&
                                    AirportData.map((item) => (
                                      <option value={item.Airport}>{item.Airport}</option>


                                    ))}
                                </Select>
                                <FormLabel>Delivery Location</FormLabel>
                                <Input
                                  type="text"
                                  name="DeliveryLocation"
                                  defaultValue={
                                    formData["DeliveryLocation"] || selectedRate["DeliveryLocation"]
                                  }
                                  value={editedRate["DeliveryLocation"] || ""}
                                  onChange={handleChange}
                                />
                                <FormLabel>Incoterm</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="Incoterm"
                                  defaultValue={
                                    formData["Incoterm"] || selectedRate["Incoterm"]
                                  }
                                  value={editedRate["Incoterm"] || ""}
                                  onChange={handleChange}
                                >
                                  {IncotermData.map((item) => (
                                    <option value={item.Incoterm}>{item.Incoterm}</option>
                                  ))}
                                </Select>


                                <FormLabel>Commodity</FormLabel>
                                <Input
                                  type="text"
                                  name="Commodity"
                                  defaultValue={
                                    formData["Commodity"] || selectedRate["Commodity"]
                                  }
                                  value={editedRate["Commodity"] || ""}
                                  onChange={handleChange}
                                />
                                 <FormLabel>Remarks_pricing</FormLabel>
                                <TextArea
                                  type="text"
                                  name="Remarks_pricing"
                                  defaultValue={
                                    formData["Remarks_pricing"] || selectedRate["Remarks_pricing"]
                                  }
                                  value={editedRate["Remarks_pricing"] || ""}
                                  onChange={handleChange}
                                />
                                 <FormLabel>Company</FormLabel>
                                <TextArea
                                  type="text"
                                  name="Company"
                                  defaultValue={
                                    formData["Company"] || selectedRate["Company"]
                                  }
                                  value={editedRate["Company"] || ""}
                                  onChange={handleChange}
                                />


                              </FormControl>


                            </GridItem>
                            <GridItem colStart={4} colEnd={6}>
                              <FormControl>
                              <FormLabel>HSCode</FormLabel>
                                <Input
                                  type="text"
                                  name="HSCode"
                                  defaultValue={
                                    formData["HSCode"] || selectedRate["HSCode"]
                                  }
                                  value={editedRate["HSCode"] || ""}
                                  onChange={handleChange}
                                />
                                <FormLabel>Scope</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="Scope"
                                  defaultValue={
                                    formData["Scope"] || selectedRate["Scope"]
                                  }
                                  value={editedRate["Scope"] || ""}
                                  onChange={handleChange}
                                >
                                  {ScopeData.map((item) => (
                                    <option value={item.Scope}>{item.Scope}</option>
                                  ))}
                                </Select>
                                <FormLabel>FreeTime</FormLabel>
                                <Input
                                  type="text"
                                  name="FreeTime"
                                  defaultValue={
                                    formData["FreeTime"] || selectedRate["FreeTime"]
                                  }
                                  value={editedRate["FreeTime"] || ""}
                                  onChange={handleChange}


                                />


                                <FormLabel>Stackable</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  defaultValue={formData["Stackable"] || selectedRate["Stackable"]}
                                  value={editedRate["Stackable"] || ""}
                                  onChange={handleChange}
                                  name="Stackable"
                                >
                                  <option value="">Select Stackable</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Select>
                                <FormLabel>DGNonDG</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  defaultValue={formData["DGNonDG"] || selectedRate["DGNonDG"]}
                                  value={editedRate["DGNonDG"] || ""}
                                  onChange={handleChange}
                                  name="DGNonDG"
                                >
                                  <option value="">Select DGNonDG</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Select>
                                <FormLabel>PackingType</FormLabel>
                                <Select className="cancelDragAndDrop"
                                  name="PackingType"
                                  defaultValue={
                                    formData["PackingType"] || selectedRate["PackingType"]
                                  }
                                  value={editedRate["PackingType"] || ""}
                                  onChange={handleChange}
                                >
                                  {PackingTypeData.map((item) => (
                                    <option value={item.packingType}>{item.packingType}</option>
                                  ))}
                                </Select>
                  
                                <FormLabel>Remarks</FormLabel>
                                <TextArea
                                  type="text"
                                  defaultValue={
                                    formData["NotesRemarks"] || selectedRate["NotesRemarks"]
                                  }
                                  value={editedRate["NotesRemarks"] || ""}
                                  onChange={handleChange}
                                  name="NotesRemarks"
                                />
                                                              
                                <FormLabel>CargoReadiness</FormLabel>
                                <Input
                                  type="date"
                                  name="CargoReadiness"
                                  defaultValue={
                                    (editedRate["CargoReadiness"] ||
                                      formData["CargoReadiness"] ||
                                      selectedRate["CargoReadiness"] || ""


                                    )?.split("T")[0] || ""
                                  }


                                  onChange={handleChange}
                                />
                                <FormLabel>EnquiryDescription</FormLabel>
                                <Input
                                  type="text"
                                  name="EnquiryDescription"
                                  defaultValue={
                                    formData["EnquiryDescription"] || selectedRate["EnquiryDescription"]
                                  }
                                  value={editedRate["EnquiryDescription"] || ""}
                                  onChange={handleChange}
                                />
                                 <FormLabel>Remarks_sales</FormLabel>
                                <TextArea
                                  type="text"
                                  name="Remarks_sales"
                                  defaultValue={
                                    formData["Remarks_sales"] || selectedRate["Remarks_sales"]
                                  }
                                  value={editedRate["Remarks_sales"] || ""}
                                  onChange={handleChange}
                                />
                                
                                <FormLabel>TotalEquipmentValue</FormLabel>
                               
                                <Input
                                  type="text"
                                  name="TotalEquipmentValue"
                                  value={
                                    ["Airfreight", "LCL", "Breakbulk/RORO"].includes(editedRate?.Mode)
                                      ? "-" // Empty string if mode matches
                                      : formData?.FormattedContainers || "" // Display value otherwise
                                  }
                                  readOnly
                                  className="text-black bg-gray-100 dark:bg-gray-700 dark:text-white cursor-not-allowed px-3 py-2 rounded"
                                  placeholder={
                                    ["Airfreight", "LCL", "Breakbulk/RORO"].includes(editedRate?.Mode)
                                      ? "No TotalEquipmentValue" // Optional placeholder when empty
                                      : "Enter the TotalEquipmentValue"
                                  }
                                />
                                {/* <FormLabel>Rates_Status</FormLabel>
                        <Select className="cancelDragAndDrop"
                          defaultValue={formData["Rates_Status"] || selectedRate["Rates_Status"]}
                          value={editedRate["Rates_Status"] || ""}
                          onChange={handleChange}
                          name="Rates_Status"
                        >
                          <option value="">Select Rates_Status</option>
                          <option value="Complete">Complete</option>
                          <option value="Not Complete">Not Complete</option>
                        </Select> */}
                              </FormControl>


                            </GridItem>
                          </Grid>
                          <br>
                          </br>
                          <div className="table-container">
                            <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                              <thead className="flex-nowrap text-center bg-blue-950 text-white">
                                <tr>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Equipment
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Qty
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Weight
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Dimensions
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              {selectedRate.EnquiryRateContainers && (
                                <tbody>
                                  {editedRate?.EnquiryRateContainers?.split(';')?.map((Rate, index) => {
                                    const RateAttributes = Rate?.split(',');
                                    console.log("asaa", editedRate)


                                    return (
                                      <tr key={index}>


                                        {/* <Input
                                  name={`ContainerType-${index}`}
                                  type="text"
                                  value={editedUser[`ContainerType-${index}`] || containerAttributes[0]?.split(': ')[1]}
                                  onChange={(e) => handleInnerContainerChange(e, index, "ContainerType")}
                                /> */}
                                        <td className="text-center">
                                          {["Airfreight", "LCL", "Breakbulk/RORO"].includes(editedRate?.Mode) ? (
                                            <span>-</span>
                                          ) : (
                                            <Select
                                              w="190px"
                                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                              type="text"
                                              name={`Equipment-${index}`}
                                              value={
                                                editedRate[
                                                `Equipment-${index}`
                                                ] ||
                                                RateAttributes[1]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerEnquiryRateChange(
                                                  e,
                                                  index,
                                                  "Equipment"
                                                )
                                              }
                                            >
                                              <option value="">Select Equipment</option>
                                              {EquipmentData.map((item, idx) => (
                                                <option key={idx} value={item.ContainerType}>
                                                  {item.ContainerType}
                                                </option>
                                              ))}


                                            </Select>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {["Airfreight", "LCL", "Breakbulk/RORO"].includes(editedRate?.Mode) ? (
                                            <span>-</span>
                                          ) : (
                                            <Input
                                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                              type="text"
                                              name={`Quantity-${index}`}
                                              value={
                                                editedRate[
                                                `Quantity-${index}`
                                                ] ||
                                                RateAttributes[2]?.split(
                                                  ": "
                                                )[1]
                                              }
                                              onChange={(e) =>
                                                handleInnerEnquiryRateChange(
                                                  e,
                                                  index,
                                                  "Quantity"
                                                )
                                              }
                                              placeholder="Enter Quantity"
                                            />




                                          )}
                                        </td>
                                        <td>
                                          <Input
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                            type="text"
                                            name={`Weight-${index}`}
                                            value={
                                              editedRate[
                                              `Weight-${index}`
                                              ] ||
                                              RateAttributes[3]?.split(
                                                ": "
                                              )[1]
                                            }
                                            onChange={(e) =>
                                              handleInnerEnquiryRateChange(
                                                e,
                                                index,
                                                "Weight"
                                              )
                                            }
                                            placeholder="Enter Weight"
                                          />






                                        </td>
                                        <td>
                                          <Input
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                            type="text"
                                            name={`Dimensions-${index}`}
                                            value={
                                              editedRate[
                                              `Dimensions-${index}`
                                              ] ||
                                              RateAttributes[4]?.split(
                                                ": "
                                              )[1]
                                            }
                                            onChange={(e) =>
                                              handleInnerEnquiryRateChange(
                                                e,
                                                index,
                                                "Dimensions"
                                              )
                                            }
                                            placeholder="Enter Dimensions"
                                          />






                                        </td>
                                        <td>
                                          {editedRate?.EnquiryRateContainers?.split(';')?.length > 1 && (


                                            <BiSolidMinusCircle
                                              className="cursor-pointer"
                                              size={30}
                                              style={{ marginLeft: "5px", color: "red" }}
                                              onClick={() => removeEnquiryRate(index)}
                                            />


                                          )}


                                          <IoMdAddCircle
                                            className="cursor-pointer"
                                            size={30}
                                            style={{ marginLeft: "5px", color: "green" }}
                                            onClick={addEnquiryrate}
                                          />


                                        </td>
                                      </tr>
                                    );
                                  })}


                                </tbody>
                              )}
                            </table>
                            <br>
                            </br>
                          </div>
                          <div className="table-container">
                            <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                              <thead className="flex-nowrap text-center bg-blue-950 text-white">
                                <tr>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Vendor
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Rate
                                  </th>
                                  <th className="border border-slate-300 whitespace-nowrap text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              {selectedRate.RatesContainers && (
                                <tbody>
                                  {editedRate?.RatesContainers?.split(';')?.map((Rate, index) => {
                                    const RateAttributes = Rate?.split(',');
                                    console.log("asaa", editedRate)


                                    return (
                                      <tr key={index}>
                                        <td>
                                          {/* <Input
                                  name={`ContainerType-${index}`}
                                  type="text"
                                  value={editedUser[`ContainerType-${index}`] || containerAttributes[0]?.split(': ')[1]}
                                  onChange={(e) => handleInnerContainerChange(e, index, "ContainerType")}
                                /> */}
                                          <Select
                                            w="190px"
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                            type="text"
                                            name={`Vendor-${index}`}
                                            value={
                                              editedRate[
                                              `Vendor-${index}`
                                              ] ||
                                              RateAttributes[3]?.split(
                                                ": "
                                              )[1]
                                            }
                                            onChange={(e) =>
                                              handleInnerRateChange(
                                                e,
                                                index,
                                                "Vendor"
                                              )
                                            }
                                          >
                                            <option value="">Select Vendor</option>
                                            {VendorData.map((item, idx) => (
                                              <option key={idx} value={item.Company}>
                                                {item.Company}
                                              </option>
                                            ))}
                                          </Select>


                                        </td>
                                        <td>
                                          <TextArea
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                            type="text"
                                            name={`Rate-${index}`}
                                            value={
                                              editedRate[
                                              `Rate-${index}`
                                              ] ||
                                              RateAttributes[4]?.split(
                                                "Rate:"
                                              )[1]
                                            }
                                            onChange={(e) =>
                                              handleInnerRateChange(
                                                e,
                                                index,
                                                "Rate"
                                              )
                                            }
                                            placeholder="Enter Rate"
                                          />






                                        </td>
                                        <td>
                                          {editedRate?.RatesContainers?.split(';')?.length > 1 && (


                                            <BiSolidMinusCircle
                                              className="cursor-pointer"
                                              size={30}
                                              style={{ marginLeft: "5px", color: "red" }}
                                              onClick={() => removeRate(index)}
                                            />


                                          )}


                                          <IoMdAddCircle
                                            className="cursor-pointer"
                                            size={30}
                                            style={{ marginLeft: "5px", color: "green" }}
                                            onClick={addrate}
                                          />


                                        </td>
                                      </tr>
                                    );
                                  })}


                                </tbody>
                              )}
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <TableContainer className="custom-style">
                            <Table variant="simple">
                              <Thead>
                                <Tr>
                                  <Th>Select</Th>
                                  <Th>Document ID</Th>
                                  <Th>Filename</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {selectedRate?.Attachments?.split(";").map((attachment, index) => {
                                  const attachmentDetails = attachment.trim()?.split(",");
                                  const url = attachmentDetails.find(detail => detail.includes("URL:"))?.split(": ")[1];


                                  return (
                                    <Tr key={index}>
                                      <Td>
                                        <input
                                          type="checkbox"
                                          onChange={(e) => handleSelectUrl(e, url)}
                                          className="cancelDragAndDrop"
                                        />
                                      </Td>
                                      {attachmentDetails.map((detail, detailIndex) => {
                                        const [key, value] = detail?.split(": ");
                                        if (key.trim() === "URL" || key.trim() === "DeletionFlag") return null;
                                        return (
                                          <Td style={{ whiteSpace: "nowrap" }} key={detailIndex}>
                                            {value}
                                          </Td>
                                        );
                                      })}
                                    </Tr>
                                  );
                                })}
                              </Tbody>
                            </Table>
                          </TableContainer>


                          <br />


                          <Button colorScheme="teal" size="sm" onClick={handleViewSelectedPDFs}>
                            View Selected
                          </Button>

                        </TabPanel>
                        <TabPanel>
                          <>
                            <Card align="center" mt={5}>
                              <CardHeader>
                                <Heading size="md">Upload Document</Heading>
                              </CardHeader>
                              <CardBody>
                                <FormControl>
                                  <Input
                                    type="file"
                                    onChange={handleFileChange}
                                    name="file"
                                    multiple
                                    sx={{
                                      "::file-selector-button": {
                                        height: 10,
                                        padding: 0,
                                        mr: 4,
                                        background: "none",
                                        border: "none",
                                        fontWeight: "bold",
                                        color: "red",
                                      },
                                    }}
                                    width={500}
                                  />
                                  <FormHelperText
                                    style={{
                                      textAlign: "center",
                                      fontSize: "14pt",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span>Attach any related
                                    documents.
                                  </FormHelperText>
                                </FormControl>
                              </CardBody>
                              <CardFooter></CardFooter>
                            </Card>


                            <TableContainer mt={5}>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th>S.No</Th>
                                    <Th>Document Name</Th>
                                    <Th>Document Size</Th>
                                    <Th>Action</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                 
                                   {formData?.files && formData?.files?.length > 0 ? (
                                                formData.files.map((file, index) => (
                                                  <Tr key={index}>
                                                    <Td>{index + 1}</Td>
                                                    <Td>{file.name}</Td>
                                                    <Td>{file.type}</Td>
                                                     <Td>
                                                            <button
                                                              onClick={() => {
                                                                const updatedFiles = formData.files.filter((_, i) => i !== index);
                                                                setFormData((prevData) => ({ ...prevData, files: updatedFiles }));
                                                              }}
                                                              style={{
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                                border: 'none',
                                                                padding: '5px 10px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                              }}
                                                            >
                                                              Remove
                                                            </button>
                                                          </Td>
                                                  </Tr>
                                                ))
                                              ) : (
                                                <Tr>
                                                  <Td colSpan={3}>No files selected</Td>
                                                </Tr>
                                              )}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </>


                        </TabPanel>
                      </TabPanels>
                    </Tabs>


                    {!selectedRate && (
                      <p>Data not available for the selected Rate.</p>
                    )}
                  </form>
                )}
                <Stack
                  spacing={4}
                  direction="row"
                  align="center"
                  float="right"
                  marginTop={5}
                >
                  <Button onClick={handleCloseModal}>Cancel</Button>
                  <Button
                    colorScheme="messenger"
                    onClick={() => {
                      handleSubmitUpdate();
                      handleCloseModal();
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Rnd>
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default DraggableRateTable;