import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { MasterDataAPIInstance, RateAPIInstance } from "../../api-instance";
import {
  Textarea,
  useToast,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel, Tr, Td, TableContainer, Th, Tbody, Thead, Table, Button, Card,
  CardBody, CardFooter, CardHeader, Heading, FormControl, FormHelperText, IconButton
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddRate.css";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Input } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";


const AddRate = () => {
  const location = useLocation();
  const enquiryRate = location.state?.selectedData || {};
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const [equipmentData, setEquipmentData] = useState([]);
  const navigate = useNavigate();
  const [VendorData, setVendorData] = useState([]);
  const [ModeData, setModeData] = useState([]);
  const [ScopeData, setScopeData] = useState([]);
  const [IncotermData, setIncotermData] = useState([]);
  const [PackingTypeData, setPackingTypeData] = useState([]);
  const [AirportData, setAirportData] = useState([]);
  const [PortData, setPortData] = useState([]);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const fullName = `${storedUser.firstname} ${storedUser.lastname}`;
  const current = new Date();
  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [containers, setContainers] = useState([{}]);
  const [selectedurls, setSelectedurls] = useState([]);
  const [file, setFile] = useState([]);
  const [urls, setUrls] = useState([]);
  const [rate, setRate] = useState([{}]);


  const [ratesContainers, setRatesContainers] = useState([
    {
      vendor: "",
      Rate: "",
    },
  ]);
  const [enquiryContainers, setEnquiryContainers] = useState([
    {
      Equipment: "",
      Quantity: "",
      Weight: "",
      Dimensions: "",
    },
  ]);
  // const [files, setfiles] = useState([])
  const [formData, setFormData] = useState(
    {
      EnquiryNumber: "",
      Mode: "",
      OriginCountry: "",
      DestinationCountry: "",
      PickUpLocation: "",
      OriginPortAirport: "",
      DestinationPortAirport: "",
      DeliveryLocation: "",
      Incoterm: "",
      RateOwner: JSON.parse(localStorage.getItem("user")).userId,
      Commodity: "",
      HSCode: "",
      Scope: "",
      FreeTime: "",
      Stackable: "",
      DGNonDG: "",
      PackingType: "",
      NotesRemarks: "",
      EnquiryDescription: "",
      CargoReadiness: "",
      Remarks_sales:"",
      Remarks_pricing:"",
      Company:"",
      files:[],
      // Rates_Status: "",
      TotalEquipmentValue: "",
      enquiryContainers: [{ Equipment: "", Quantity: "", Weight: "", Dimensions: "" }],
      ratesContainers: [{ vendor: "", Rate: "" }],
      CreatedBy: fullName,
      // RateOwner: storedUser.userId,
    },
  );
  const [selectedType, setSelectedType] = useState(formData?.TypeofPort);
  useEffect(() => {
    if (storedUser && fullName) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        CreatedBy: fullName,
        RateOwner: storedUser.userId,
        ratesContainers,
        enquiryContainers,
      }));
    }
  }, [storedUser, fullName]);
  console.log("formdata", formData);
  useEffect(() => {
    setEditedData(selectedData);
  }, [selectedData]);
  useEffect(() => {
    setFormData(enquiryRate[0]);
    setSelectedData(enquiryRate);
  }, [enquiryRate]);


  useEffect(() => { }, [setSelectedData, setSelectedRows]);
  useEffect(() => {
    const parseContainers = () => {
      const parsedContainers =
        formData?.Containers?.split(";")
          .map((containerString) => {
            if (!containerString.trim()) return null;


            const container = {};
            containerString.split(",").forEach((pair) => {
              const [key, value] = pair.split(":");
              container[key?.trim()] = value?.trim();
            });


            return {
              Equipment: container.Equipment || "",
              Quantity: container.Quantity || "",
              Weight: container.Weight || "",
              Dimensions: container.Dimensions || "",
            };
          })
          .filter(Boolean) || [];


      setEnquiryContainers(parsedContainers);
    };


    parseContainers();
  }, [formData?.Containers]);




  // const handleFileChange = (event) => {
  //   const fileList = event.target.files;
  //   const fileNames = Array.from(fileList).map((file) => file.name);
  //   const combinedFileNames = fileNames.join(","); // Change to ";" to match the display logic
  //   setFormData((prev) => ({
  //     ...prev,
  //     files: combinedFileNames, // Using "files" as your state property
  //   }));
  // };
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files;
  //   const newFiles = Array.from(fileList); // Convert fileList to an array


  //   // Generate an array of objects, each containing file properties
  //   const newfiles = newFiles.map((file, index) => ({
  //     // Generate a unique ID (you can replace this with your own logic)
  //     name: file.name,
  //     size:file.size,
  //   }));


  //   // Update files state with the new files
  //   setfiles((prevfiles) => [
  //     ...prevfiles,
  //     ...newfiles,
  //   ]);


  //   // Append new files to the state for table display
  //   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  // };
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files; // Get file list
  //   const newFiles = Array.from(fileList); // Convert FileList to Array


  //   // Create URLs for the new files
  //   const newfiles = newFiles.map((file) => ({
  //     // Generate a unique ID (you can replace this with your own logic)
  //     name: file.name,
  //     size: file.size,
  //   }));
  //   const newUrls = newFiles.map((file) => URL.createObjectURL(file));


  //   // Update state with files and URLs
  //   setfiles((prevfiles) => [...prevfiles, ...newfiles]);
  //   setUrls((prevUrls) => [...prevUrls, ...newUrls]);
 
  //   console.log("Uploaded Files:", newFiles);
  // };
//  const handleFileChange =(event)=>{
//    setFile(event.target.files[0]);
//  }
 const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  setFormData((prevData) => ({
    ...prevData,
    files: prevData.files
      ? [...prevData.files, ...selectedFiles]
      : selectedFiles, // Safely append files
  }));
};
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files; // Get file list
  //   const newFiles = Array.from(fileList); // Convert FileList to Array


  //   // Read files as buffer and create necessary objects
  //   const filePromises = newFiles.map((file) => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         resolve({
  //           name: file.name,
  //           size: file.size,
  //           type: file.type,
  //           buffer: reader.result, // This will contain the file's ArrayBuffer
  //         });
  //       };
  //       reader.onerror = (error) => reject(error);
  //       reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
  //     });
  //   });


  //   // Process files and update state
  //   Promise.all(filePromises)
  //     .then((filesWithBuffers) => {
  //       setfiles((prevfiles) => [...prevfiles, ...filesWithBuffers]);


  //       // Optionally create object URLs for previews
  //       const newUrls = newFiles.map((file) => URL.createObjectURL(file));
  //       setUrls((prevUrls) => [...prevUrls, ...newUrls]);


  //       console.log("Uploaded Files with Buffers:", filesWithBuffers);
  //     })
  //     .catch((error) => console.error("Error reading files:", error));
  // };


  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      height: "45px",
      width: "100%",
      outline: "none",
      fontSize: "16px",
      borderRadius: "5px",
      paddingLeft: "15px",
      border: "1px solid #ccc",
      borderBottomWidth: "2px",
      transition: "all 0.3s ease",
      backgroundColor: "white", // Ensure the background color is white
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "45px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e0e0e0",
      borderRadius: "2px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#ccc",
        color: "black",
      },
    }),
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setVendorData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_airport");
        setAirportData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleSelectUrl = (event, url) => {
    if (event.target.checked) {
      setSelectedurls((prev) => [...prev, url]);
    } else {
      setSelectedurls((prev) => prev.filter((u) => u !== url));
    }
  };
  const handleCheckboxChange = (url) => {
    setSelectedurls((prevUrls) =>
      prevUrls.includes(url)
        ? prevUrls.filter((selectedurls) => selectedurls !== url)
        : [...prevUrls, url]
    );
  };
  const handleViewSelectedPDFs = () => {
    if (selectedurls.length < 1) {
      toast({
        title: "Please select at least one row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }


    if (selectedurls.length > 1) {
      toast({
        title: "Please select only one row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }


    // If exactly one URL is selected, open it
    selectedurls.forEach((url) => {
      window.open(url, "_blank");
    });
  };


  // useEffect(() => {
  //   const calculateTotalEquipmentValue = () => {
  //     // Filter out containers with invalid or empty values
  //     const filteredContainers = containers.filter(
  //       (container) =>
  //         container.Equipment &&
  //         container.Quantity &&
  //         !isNaN(parseFloat(container.Quantity))
  //     );


  //     // Construct the formatted string for each valid container
  //     const formattedValues = filteredContainers.map((container) => {
  //       const Equipment = container.Equipment;
  //       const quantity = parseFloat(container.Quantity) || 0;


  //       // Create the formatted string for this container
  //       return `${quantity} X ${Equipment}`;
  //     });


  //     // Join all formatted values with a comma
  //     return formattedValues.join(", ");
  //   };


  //   const totalValue = calculateTotalEquipmentValue();
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     TotalEquipmentValue: totalValue,
  //   }));
  // },[]);
  // useEffect(() => {
  //   const calculateTotalEquipmentValue = () => {
  //     const filteredContainers = containers.filter(
  //       (container) =>
  //         container.Equipment &&
  //         container.Quantity &&
  //         !isNaN(parseFloat(container.Quantity))
  //     );


  //     const formattedValues = filteredContainers.map((container) => {
  //       const Equipment = container.Equipment;
  //       const quantity = parseFloat(container.Quantity) || 0;
  //       return `${quantity} X ${Equipment}`;
  //     });


  //     return formattedValues.join(", ");
  //   };


  //   const totalValue = calculateTotalEquipmentValue();
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     TotalEquipmentValue: totalValue,
  //   }));
  // }, [containers]);
  useEffect(() => {
    const calculateTotalEquipmentValue = () => {
      const filteredContainers = enquiryContainers.filter(
        (container) =>
          container.Equipment &&
          container.Quantity &&
          !isNaN(parseFloat(container.Quantity))
      );


      return filteredContainers
        .map(
          (container) =>
            `${parseFloat(container.Quantity)} X ${container.Equipment}`
        )
        .join(", ");
    };


    const totalValue = calculateTotalEquipmentValue();
    setFormData((prevFormData) => ({
      ...prevFormData,
      TotalEquipmentValue: totalValue,
    }));
  }, [enquiryContainers, setFormData]);
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    console.log("asa", name, value);
    // setSelectedType(e.target.value);
    // if (
    //   e?.name === "POL" ||
    //   e?.name === "POD" ||
    //   e?.name === "Sales" ||
    //   e?.name === "CompanyName" ||
    //   e?.name === "PIC" ||
    //   e?.name === "Address"
    // ) {
    //   setFormData((prevData) => {
    //     const newData = [...prevData];
    //     newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
    //     return newData;
    //   });
    //   return;
    // }


    // const { name, value } = e.target;
    // console.log("ASA",name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // setFormData((prevFormData) => {
    //   const newData = [...prevFormData ]; // Use an empty array as default
    //   newData[formData] = { ...newData[formData], [name]: value };
    //   return newData;
    // });
    console.log("Pol Data", formData);
  };
  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;


  //   console.log("Field Change Detected:", name, value);


  //   setFormData((prevFormData) => {
  //     if (index !== undefined && Array.isArray(prevFormData.ratesContainers)) {
  //       // Handle updates to a specific index in the ratesContainers array
  //       const updatedRatesContainers = [...prevFormData.ratesContainers];
  //       updatedRatesContainers[index] = {
  //         ...updatedRatesContainers[index],
  //         [name]: value,
  //       };
  //       return {
  //         ...prevFormData,
  //         ratesContainers: updatedRatesContainers,
  //       };
  //     }


  //     // Update a single field while preserving the rest of the formData
  //     return {
  //       ...prevFormData,
  //       [name]: value,
  //     };
  //   });


  //   console.log("Updated formData:", formData);
  // };
  useEffect(() => {
    // Synchronize selectedType with formData.TypeofPort on the first render
    setSelectedType(formData?.TypeofPort);
  }, [formData?.TypeofPort]);


  // const handleTypeChange = (e) => {
  //   const { name, value } = e.target;
  //   setSelectedType(value);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  //   handleChange(e);
  // };
  const handleTypeChange = (e) => {
    const { name, value } = e.target;


    // Update selected type and reset dropdown values
    setSelectedType(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      OriginPortAirport: "", // Reset OriginPortAirport
      DestinationPortAirport: "", // Reset DestinationPortAirport
    }));
    handleChange(e);
  };


  // const handleTypeChange = (e) => {
  //   setSelectedType(e.target.value);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  //   handleChange(e);  
  // };
  // const handleContainerChange = (index, fieldName, value) => {
  //   const updatedContainers = [...containers];
  //   updatedContainers[index] = {
  //     ...updatedContainers[index],
  //     [fieldName]: value,
  //   };
  //   setContainers(updatedContainers);
  //   // setFormData({
  //   //   ...formData,
  //   //   equipmentEnquiry: updatedContainers,
  //   // });
  //    setFormData((prevFormData) => ({
  //     ...prevFormData,           // Preserve all other fields in formData
  //     enquiryContainers: updatedContainers, // Update ratesContainers specifically
  //   }));




  // };
  const handleContainerChange = (index, fieldName, value) => {
    const updatedContainers = [...enquiryContainers];
    updatedContainers[index][fieldName] = value;


    setEnquiryContainers(updatedContainers);


    setFormData((prevFormData) => ({
      ...prevFormData,
      enquiryContainers: updatedContainers,
    }));
  };
  const handleRateChange = (index, formData, value) => {
    const updatedContainers = [...ratesContainers];
    updatedContainers[index] = {
      ...updatedContainers[index],
      [formData]: value,
    };
    setRatesContainers(updatedContainers);


    setFormData((prevFormData) => ({
      ...prevFormData,           // Preserve all other fields in formData
      ratesContainers: updatedContainers, // Update ratesContainers specifically
    }));




  };
  //console.log("rtsds",ratesContainers);


  //   const handleRateChange = (index, field, value) => {
  //     const updatedContainers = [...ratesContainers];
  //     updatedContainers[index] = {
  //       ...updatedContainers[index],
  //       [field]: value, // Updates the correct field (either 'vendor' or 'Rate')
  //     };


  //     // Update ratesContainers state
  //     setRatesContainers(updatedContainers);


  //     // Synchronize with formData state
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       ratesContainers: updatedContainers,
  //     }));
  //   };
  // const addContainer = () => {
  //   setFormData((prevFormData) => {
  //     const newData = [...prevFormData];


  //     newData[formData] = {
  //       ...newData[formData],
  //       Containers:
  //         newData[formData]?.Containers + "Equipment: , Quantity: ;",
  //     };
  //     return newData;
  //   });
  // };
  // const addContainer = () => {
  //   setContainers([...containers, {}]);
  //   setEnquiryContainers([
  //     ...enquiryContainers,
  //     {
  //       Equipment: "",
  //       Quantity: "",
  //     },
  //   ]);
  // };
  // const addContainer = () => {
  //   setContainers([...containers, { Equipment: "", Quantity: "" }]);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     enquiryContainers: [
  //       ...(prevFormData.enquiryContainers || []),
  //       { Equipment: "", Quantity: "" },
  //     ],
  //   }));
  // };
  const addContainer = () => {
    setEnquiryContainers([
      ...enquiryContainers,
      { Equipment: "", Quantity: "", Weight: "", Dimensions: "" },
    ]);
  };




  // const handleRemoveFile = (index) => {
  //   // Remove file and URL at the specified index
  //   setfiles((prevfiles) =>
  //     prevfiles.filter((_, i) => i !== index)
  //   );
  //   setUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  // // };


  function removeRowByIndex(data, indexToRemove) {
    let rows = data.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }
    return rows.join("; "); // Join the rows back into a single string
  }
  console.log("formDataIndex", formDataIndex)
  const removeContainer = (indexToRemove) => {
    console.log(indexToRemove);
    setFormData((prevFormData) => {
      const newData = [...prevFormData];
      const containers = newData[formData]?.Containers || "";


      // Split the containers into rows
      let rows = containers.split(";").filter((row) => row.trim() !== "");


      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }


      // Join the rows back into a single string with semicolons
      newData[formData] = {
        ...newData[formData],
        Containers:
          rows.join("; ").trim() + (containers.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
        // Operations: newData[formDataIndex]?.Operations.filter(
        //   (_, index) => index !== indexToRemove
        // ),
      };
      return newData;
    });
  };
  const addrate = () => {
    setRate([...rate, {}]);
    setRatesContainers([
      ...ratesContainers,
      {
        vendor: "",
        Rate: "",
      },
    ]);
  };


  const removerate = (indexToRemove) => {
    setRate(rate.filter((_, index) => index !== indexToRemove));
    setRatesContainers(
      ratesContainers.filter((_, index) => index !== indexToRemove)
    );
  };


  const Pagerefresh = () => window.location.reload(true);


  // const handleSubmit = async (event) => {
  //   formData.ratesContainers = ratesContainers;
  //   formData.files =file;
  //   formData.enquiryContainers = enquiryContainers;
  //   event.preventDefault();
  //   console.log("Selected Form Data", formData);
  //   try {
  //     const { data } = await RateAPIInstance.post(
  //       `add_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: JSON.parse(localStorage.getItem("token")),
  //         },
  //       }
  //     );


  //     console.log("====================================");
  //     console.log(data);
  //     console.log("====================================");
  //     if (data.success) {
  //       toast({
  //         title: data.data.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "success",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       navigate("/rate");
  //     } else {
  //       toast({
  //         title: data.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     toast({
  //       title: error.message,
  //       position: "bottom-center",
  //       isClosable: true,
  //       status: "error",
  //       containerStyle: {
  //         width: "98.5vw",
  //         maxWidth: "98.5vw",
  //         alignContent: "center",
  //       },
  //     });
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Create FormData to send in the API request
    const formDataToSend = new FormData();
  
    // Append basic fields to FormData
    Object.keys(formData).forEach((key) => {
      if (key !== "files" && key !== "enquiryContainers" && key !== "ratesContainers") {
        formDataToSend.append(key, formData[key]);
      }
      // if (key !== "files") {
      //   formDataToSend.append(key, formData[key]);
      // }
    });
  
    // Append enquiryContainers and ratesContainers as JSON strings
    formDataToSend.append("enquiryContainers", JSON.stringify(formData.enquiryContainers));
    formDataToSend.append("ratesContainers", JSON.stringify(formData.ratesContainers));
  
    // Append files
    // formDataToSend.append("files", formData.files);
    formData.files.forEach((file) => {
      formDataToSend.append("files", file); 
    });
//     if (formData.files && formData.files.length > 0) {
//   formData.files.forEach((file) => {
//     formDataToSend.append("file", file);
//   });
// }
    
    try {
      const { data } = await RateAPIInstance.post(
        `add_rates?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
  
      // Handle response
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/rate");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  




  // trails


//   const handleSubmit = async () => {
//     // event.preventDefault();
//     try {
  
  
//     const formData = new FormData(); // Create a new FormData instance
  
//     // Append the form data fields
//     formData.append("RateNumber", formData.RateNumber);
//     formData.append("Mode", formData.Mode);
//     formData.append("OriginCountry", formData.OriginCountry);
//     formData.append("DestinationCountry", formData.DestinationCountry);
//     formData.append("PickUpLocation", formData.PickUpLocation);
//     formData.append("TypeofPort", formData.TypeofPort);
//     formData.append("OriginPortAirport", formData.OriginPortAirport);
//     formData.append("DestinationPortAirport", formData.DestinationPortAirport);
//     formData.append("DeliveryLocation", formData.DeliveryLocation);
//     formData.append("Incoterm", formData.Incoterm);
//     formData.append("Commodity", formData.Commodity);
//     formData.append("HSCode", formData.HSCode);
//     formData.append("Scope", formData.Scope);
//     formData.append("FreeTime", formData.FreeTime);
//     formData.append("Stackable", formData.Stackable);
//     formData.append("DGNonDG", formData.DGNonDG);
//     formData.append("PackingType", formData.PackingType);
//     formData.append("EnquiryDescription", formData.EnquiryDescription);
//     formData.append("CargoReadiness", formData.CargoReadiness);
//     formData.append("NotesRemarks", formData.NotesRemarks);
//     formData.append("RateOwner", formData.RateOwner);
//     formData.append("TotalEquipmentValue", formData.TotalEquipmentValue);


//     // Append enquiryContainers (assuming it's an array of objects)
//     formData.append("enquiryContainers", JSON.stringify(formData.enquiryContainers));


//     // Append ratesContainers (assuming it's an array of objects)
//     formData.append("ratesContainers", JSON.stringify(formData.ratesContainers));
//     formData.append("File", file);
//     // Append the file (if any)


//     // Handle the submission
  
//         const response = await RateAPIInstance.post(
//           `multi_upload_document?userId=${
//             JSON.parse(localStorage.getItem("user")).userId
//           }`,
//             formData,
//             {
//                 headers: {
//                     "Content-Type": "multipart/form-data",
//                     Authorization: JSON.parse(localStorage.getItem("token")),
//                 },
//             }
//         );


//         const data = response.data;


//         if (data.success) {
//             toast({
//                 title: data.data.message,
//                 position: "bottom-center",
//                 isClosable: true,
//                 status: "success",
//                 containerStyle: {
//                     width: "98.5vw",
//                     maxWidth: "98.5vw",
//                     alignContent: "center",
//                 },
//             });
//             navigate("/rate");
//         } else {
//             toast({
//                 title: data.message,
//                 position: "bottom-center",
//                 isClosable: true,
//                 status: "error",
//                 containerStyle: {
//                     width: "98.5vw",
//                     maxWidth: "98.5vw",
//                     alignContent: "center",
//                 },
//             });
//         }
//     } catch (error) {
//         toast({
//             title: error.message,
//             position: "bottom-center",
//             isClosable: true,
//             status: "error",
//             containerStyle: {
//                 width: "98.5vw",
//                 maxWidth: "98.5vw",
//                 alignContent: "center",
//             },
//         });
//     }
// };












  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     formData.ratesContainers = ratesContainers;
  //     console.log("Selected Form Data", formData);


  //     try {
  //         // First, submit the rate data
  //         const { data } = await RateAPIInstance.post(
  //             `add_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //             formData,
  //             {
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: JSON.parse(localStorage.getItem("token")),
  //                 },
  //             }
  //         );




  //         if (data.success) {
  //           // console.log("frg",data);
  //           //   const EnquiryNumber=data.EnquiryNumber;
  //           //   const files=data.files;
  //           //    // Adjust based on your API response structure


  //           //   // Prepare FormData for file upload
  //           //   const fileUploadFormData = new FormData();
  //           //   if (files && files.length > 0) {
  //           //       files.forEach((file) => {
  //           //           fileUploadFormData.append("files", file);
  //           //       });
  //           //   }


  //           //   fileUploadFormData.append("EnquiryNumber", EnquiryNumber);
  //           //   fileUploadFormData.append("Attachment", files);
  //           //   fileUploadFormData.append("UserId", JSON.parse(localStorage.getItem("user")).userId);


  //             // Upload the files
  //             try {
  //                 const uploadResponse = await RateAPIInstance.post(
  //                     `/multi_upload_document?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //                     formData,
  //                     {
  //                       headers: {
  //                         "Content-Type": "application/json",
  //                         Authorization: JSON.parse(localStorage.getItem("token")),
  //                     },
  //                     }
  //                 );


  //                 const uploadData = uploadResponse.data;


  //                 // Check if the upload was successful
  //                 if (uploadData.success) {
  //                     toast({
  //                         title: "Rate and files uploaded successfully.",
  //                         position: "bottom-center",
  //                         isClosable: true,
  //                         status: "success",
  //                         containerStyle: {
  //                             width: "98.5vw",
  //                             maxWidth: "98.5vw",
  //                             alignContent: "center",
  //                         },
  //                     });
  //                     navigate("/rate");
  //                 } else {
  //                     toast({
  //                         title: uploadData.message,
  //                         position: "bottom-center",
  //                         isClosable: true,
  //                         status: "error",
  //                         containerStyle: {
  //                             width: "98.5vw",
  //                             maxWidth: "98.5vw",
  //                             alignContent: "center",
  //                         },
  //                     });
  //                 }
  //             } catch (uploadError) {
  //                 toast({
  //                     title: uploadError.message,
  //                     position: "bottom-center",
  //                     isClosable: true,
  //                     status: "error",
  //                     containerStyle: {
  //                         width: "98.5vw",
  //                         maxWidth: "98.5vw",
  //                         alignContent: "center",
  //                     },
  //                 });
  //             }
  //         } else {
  //             toast({
  //                 title: data.message,
  //                 position: "bottom-center",
  //                 isClosable: true,
  //                 status: "error",
  //                 containerStyle: {
  //                     width: "98.5vw",
  //                     maxWidth: "98.5vw",
  //                     alignContent: "center",
  //                 },
  //             });
  //         }
  //     } catch (error) {
  //         toast({
  //             title: error.message,
  //             position: "bottom-center",
  //             isClosable: true,
  //             status: "error",
  //             containerStyle: {
  //                 width: "98.5vw",
  //                 maxWidth: "98.5vw",
  //                 alignContent: "center",
  //             },
  //         });
  //     }
  // };
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   formData.ratesContainers = ratesContainers;
  //   console.log("Selected Form Data", formData);


  //   try {
  //       // First, submit the rate data
  //       const { data } = await RateAPIInstance.post(
  //           `add_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //           formData,
  //           {
  //               headers: {
  //                   "Content-Type": "application/json",
  //                   Authorization: JSON.parse(localStorage.getItem("token")),
  //               },
  //           }
  //       );


  //       // Check if the rate submission was successful
  //       if (data.success) {
  //           // Assuming the response contains an ID or some identifier for the newly created rate
  //           const rateId = data.id; // or however you get the ID from the response


  //           // Prepare form data for file upload
  //           const uploadFormData = new FormData();
  //           uploadFormData.append('rateId', rateId); // Include the rate ID if needed


  //           // Assuming `formData.files` contains the file(s) you want to upload
  //           if (formData.files) {
  //               // If files is a single file
  //               uploadFormData.append('file', formData.files);
  //           }


  //           // If files is an array of files, you can loop through them
  //           // formData.files.forEach(file => {
  //           //     uploadFormData.append('files', file);
  //           // });


  //           // Now, upload the files
  //           const uploadResponse = await RateAPIInstance.post(
  //               `/multi_upload_document?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //               uploadFormData,
  //               {
  //                   headers: {
  //                       "Content-Type": "multipart/form-data",
  //                       Authorization: JSON.parse(localStorage.getItem("token")),
  //                   },
  //               }
  //           );


  //           // Handle the upload response
  //           if (uploadResponse.data.success) {
  //               toast({
  //                   title: "Rate and files uploaded successfully.",
  //                   position: "bottom-center",
  //                   isClosable: true,
  //                   status: "success",
  //                   containerStyle: {
  //                       width: "98.5vw",
  //                       maxWidth: "98.5vw",
  //                       alignContent: "center",
  //                   },
  //               });
  //               navigate("/rate");
  //           } else {
  //               toast({
  //                   title: uploadResponse.data.message,
  //                   position: "bottom-center",
  //                   isClosable: true,
  //                   status: "error",
  //                   containerStyle: {
  //                       width: "98.5vw",
  //                       maxWidth: "98.5vw",
  //                       alignContent: "center",
  //                   },
  //               });
  //           }
  //       } else {
  //           toast({
  //               title: data.message,
  //               position: "bottom-center",
  //               isClosable: true,
  //               status: "error",
  //               containerStyle: {
  //                   width: "98.5vw",
  //                   maxWidth: "98.5vw",
  //                   alignContent: "center",
  //               },
  //           });
  //       }
  //   } catch (error) {
  //       toast({
  //           title: error.message,
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: "error",
  //           containerStyle: {
  //               width: "98.5vw",
  //               maxWidth: "98.5vw",
  //               alignContent: "center",
  //           },
  //       });
  //   }
  // };
  // const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     formData.ratesContainers = ratesContainers;
  //     console.log("Selected Form Data", formData);


  //     try {
  //         // First, submit the rate data
  //         const { data } = await RateAPIInstance.post(
  //             `add_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //             formData,
  //             {
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: JSON.parse(localStorage.getItem("token")),
  //                 },
  //             }
  //         );


  //         // Check if the rate submission was successful
  //         if (data.success) {
  //             // Get the ID from the response
  //             const enquiryNumber = formData[0].EnquiryNumber; // Get the Enquiry Number


  //             // Prepare form data for file upload
  //             const uploadFormData = new FormData(); // Include the rate ID
  //             uploadFormData.append('EnquiryNumber', enquiryNumber); // Include the Enquiry Number


  //             // Assuming `formData.files` contains the file(s) you want to upload
  //             if (formData.files) {
  //                 // If files is a single file
  //                 uploadFormData.append('file', formData.files);
  //             }


  //             // Now, upload the files
  //             const uploadResponse = await RateAPIInstance.post(
  //                 `/multi_upload_document?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
  //                 uploadFormData,
  //                 {
  //                     headers: {
  //                         "Content-Type": "multipart/form-data",
  //                         Authorization: JSON.parse(localStorage.getItem("token")),
  //                     },
  //                 }
  //             );


  //             // Handle the upload response
  //             if (uploadResponse.data.success) {
  //                 toast({
  //                     title: "Rate and files uploaded successfully.",
  //                     position: "bottom-center",
  //                     isClosable: true,
  //                     status: "success",
  //                     containerStyle: {
  //                         width: "98.5vw",
  //                         maxWidth: "98.5vw",
  //                         alignContent: "center",
  //                     },
  //                 });
  //                 navigate("/rate");
  //             } else {
  //                 toast({
  //                     title: uploadResponse.data.message,
  //                     position: "bottom-center",
  //                     isClosable: true,
  //                     status: "error",
  //                     containerStyle: {
  //                         width: "98.5vw",
  //                         maxWidth: "98.5vw",
  //                         alignContent: "center",
  //                     },
  //                 });
  //             }
  //         } else {
  //             toast({
  //                 title: data.message,
  //                 position: "bottom-center",
  //                 isClosable: true,
  //                 status: "error",
  //                 containerStyle: {
  //                     width: "98.5vw",
  //                     maxWidth: "98.5vw",
  //                     alignContent: "center",
  //                 },
  //             });
  //         }
  //     } catch (error) {
  //         toast({
  //             title: error.message,
  //             position: "bottom-center",
  //             isClosable: true,
  //             status: "error",
  //             containerStyle: {
  //                 width: "98.5vw",
  //                 maxWidth: "98.5vw",
  //                 alignContent: "center",
  //             },
  //         });
  //     }
  // };






  const handleSubmitNext = async (event) => {
    event.preventDefault();


    formData.ratesContainers = ratesContainers;
    try {
      const { data } = await RateAPIInstance.post(
        `add_rate?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  // const handleGetDuplicateBooking = (index) => {
  //   setFormDataIndex(index);
  // };


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown}>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Rate</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/rate");
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>
        <Tabs w="100%" variant="enclosed">
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Enquiry Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}> Upload Attachments</Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}> Enquiry Attachments</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
                <div className="title">Enquiry Information</div>
                <br />
                <br />
                <div className="content">
                  <div className="form-user">
                    <div className="user-rates">
                      <div className="input-box" hidden>
                        {/* <span className="rates" hidden>
                  Rate ID<span style={{ color: "red" }}>*</span>
                </span>
                <input
                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                  type="text"
                  name="RateId"
                  value={rateId}
                  onChange={handleChange}
                  placeholder="Enter your Rate Id"
                  required
                  disabled
                  hidden
                /> */}
                      </div>
                      <div className="input-box">
                        <span className="rates">Enquiry Number</span>
                        {/* <select
                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                  name="EnquiryNumber"
                  value={formData[formDataIndex]?.EnquiryNumber}
                  onChange={handleChange}
                  placeholder="Enter your Enquiry Number"
                >
                  <option>Select Enquiry Number</option>
                  {uniqueEnquiryNumbers.map((enquiryNumber, index) => (
                    <option key={index} value={enquiryNumber}>
                      {enquiryNumber}
                    </option>
                  ))}
                </select> */}
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="EnquiryNumber"
                          value={formData?.EnquiryNumber}
                          onChange={handleChange}
                          placeholder="Enter your EnquiryNumber"
                        />
                        {/* <option>Select EnquiryNumber</option>
                  {EnquiryData.map((item) => (
                    <option value={item.EnquiryNumber}>{item.EnquiryNumber}</option>
                  ))} */}
                      </div>


                      <div className="input-box">
                        <span className="rates">Mode</span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Mode"
                          value={formData?.Mode}
                          onChange={handleChange}
                          placeholder="Enter your Mode"
                        >
                          <option>Select Mode</option>
                          {ModeData.map((item) => (
                            <option value={item.modeId}>{item.modeId}</option>
                          ))}
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="rates">Origin Country</span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="OriginCountry"
                          value={formData?.OriginCountry}
                          onChange={handleChange}
                          placeholder="Enter your OriginCountry"
                        />
                        {/* <option>Select OriginCountry</option>
                  {CountryData.map((item) => (
                    <option value={item.Country_name}>{item.Country_name}</option>
                  ))}
                </select> */}
                      </div>
                      <div className="input-box">
                        <span className="rates">Destination Country</span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="DestinationCountry"
                          value={formData?.DestinationCountry}
                          onChange={handleChange}
                          placeholder="Enter your DestinationCountry"
                        />
                        {/* <option>Select DestinationCountry</option>
                  {CountryData.map((item) => (
                    <option value={item.Country_name}>{item.Country_name}</option>
                  ))}
                </select> */}
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Pick Up Location
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="PickUpLocation"
                          value={formData?.PickUpLocation}
                          onChange={handleChange}
                          placeholder="Enter your PickUpLocation"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Type Of Port <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="TypeofPort"
                          value={formData?.TypeofPort}
                          onChange={handleTypeChange}
                        >


                          <option value="Port">Port</option>
                          <option value="AirPort">AirPort</option>
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="rates">Origin Port / Airport</span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="OriginPortAirport"
                          value={formData?.OriginPortAirport}
                          onChange={handleChange}
                          placeholder="Enter your OriginPortAirport"
                        >
                          <option>Select OriginPort/Airport</option>
                          {selectedType === "Port" &&
                            PortData.map((item) => (
                              <option value={item.Port}>{item.Port}</option>


                            ))}
                          {selectedType === "AirPort" &&
                            AirportData.map((item) => (
                              <option value={item.Airport}>{item.Airport}</option>


                            ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Destination Port / Airport
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="DestinationPortAirport"
                          value={
                            formData?.DestinationPortAirport
                          }
                          onChange={handleChange}
                          placeholder="Enter your DestinationPortAirport"
                        >
                          <option>Select DestinationPort/Airport</option>
                          {selectedType === "Port" &&
                            PortData.map((item) => (
                              <option value={item.Port}>{item.Port}</option>


                            ))}
                          {selectedType === "AirPort" &&
                            AirportData.map((item) => (
                              <option value={item.Airport}>{item.Airport}</option>


                            ))}
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="rates">
                          Delivery Location
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="DeliveryLocation"
                          value={formData?.DeliveryLocation}
                          onChange={handleChange}
                          placeholder="Enter your  DeliveryLocation"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">Incoterm</span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Incoterm"
                          value={formData?.Incoterm}
                          onChange={handleChange}
                          placeholder="Enter your Incoterm"
                        >
                          <option>Select Incoterm</option>
                          {IncotermData.map((item) => (
                            <option value={item.Incoterm}>{item.Incoterm}</option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Commodity<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Commodity"
                          value={formData?.Commodity}
                          onChange={handleChange}
                          placeholder="Enter your Commodity"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          HS Code<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="HSCode"
                          value={formData?.HSCode}
                          onChange={handleChange}
                          placeholder="Enter your  HSCode"
                        />
                      </div>
                      {/* <div className="input-box">
                        <span className="rates">
                          Dimension<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Dimensions"
                          value={formData?.Dimensions}
                          onChange={handleChange}
                          placeholder="Enter your Dimension"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Weight<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Weight"
                          value={formData?.Weight}
                          onChange={handleChange}
                          placeholder="Enter your Weight"
                        />
                      </div> */} 
                      <div className="input-box">
                        <span className="rates">Scope</span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Scope"
                          value={formData?.Scope}
                          onChange={handleChange}
                          placeholder="Enter your Scope"
                        >
                          <option>Select Scope</option>
                          {ScopeData.map((item) => (
                            <option value={item.Scope}>{item.Scope}</option>
                          ))}
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="rates">
                          Free Time<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="FreeTime"
                          value={formData?.FreeTime}
                          onChange={handleChange}
                          placeholder="Enter your FreeTime"
                        />
                      </div>


                      <div className="input-box">
                        <span className="rates">
                          Stackable<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Stackable"
                          value={formData?.Stackable}
                          onChange={handleChange}
                        >
                          <option value="">Select Stackable</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="rates">
                          DGNonDG<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="DGNonDG"
                          value={formData?.DGNonDG}
                          onChange={handleChange}
                        >
                          <option value="">Select DGNonDG</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="rates">Packing Type</span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="PackingType"
                          value={formData?.PackingType}
                          onChange={handleChange}
                          placeholder="Enter your PackingType"
                        >
                          <option>Select PackingType</option>
                          {PackingTypeData.map((item) => (
                            <option value={item.packingType}>{item.packingType}</option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Enquiry Description
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="EnquiryDescription"
                          value={formData?.EnquiryDescription}
                          onChange={handleChange}
                          placeholder="Enter your EnquiryDescription"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          CargoReadiness<span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="CargoReadiness"
                          value={
                            formData?.CargoReadiness?.split("T")[0]
                          }
                          onChange={handleChange}
                          placeholder="Enter your CargoReadiness"
                        />
                      </div>
 
                      <div className="input-box">
                        <span className="rates">
                          Remarks_sales<span style={{ color: "red" }}>*</span>
                        </span>
                        <Textarea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Remarks_sales"
                          value={formData?.Remarks_sales}
                          onChange={handleChange}
                          placeholder="Enter your Remarks_sales"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Remarks_pricing<span style={{ color: "red" }}>*</span>
                        </span>
                        <Textarea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Remarks_pricing"
                          value={formData?.Remarks_pricing}
                          onChange={handleChange}
                          placeholder="Enter your Remarks_pricing"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Company<span style={{ color: "red" }}>*</span>
                        </span>
                        <Textarea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Company"
                          value={formData?.Company}
                          onChange={handleChange}
                          placeholder="Enter your Company"
                        />
                      </div>
                      <div className="input-box">
                        <span className="rates">
                          Remarks<span style={{ color: "red" }}>*</span>
                        </span>
                        <Textarea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="NotesRemarks"
                          value={formData?.NotesRemarks}
                          onChange={handleChange}
                          placeholder="Enter your  NotesRemarks"
                        />
                      </div>

                      <div className="input-box text-center">
                        <span className="rates">
                          TotalEquipmentValue
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <Input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="TotalEquipmentValue"
                          value={
                            ["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData?.Mode)
                              ? "-" // Set value to empty string if condition is true
                              : formData?.TotalEquipmentValue // Otherwise, display the value
                          }
                          onChange={handleChange}
                          placeholder={
                            ["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData?.Mode)
                              ? "No TotalEquipmentValue" // Optional: Show placeholder "N/A" if condition is met
                              : "Enter your TotalEquipmentValue"
                          }
                          readOnly
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <br></br>
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Equipment
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Qty
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Dimensions
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      {enquiryContainers.map((container, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData?.Mode) ? (
                              <span>-</span>
                            ) : (
                              <select
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                value={container.Equipment || ""}
                                onChange={(e) =>
                                  handleContainerChange(index, "Equipment", e.target.value)
                                }
                              >
                                <option value="">Select Equipment</option>
                                {equipmentData.map((item, idx) => (
                                  <option key={idx} value={item.ContainerType}>
                                    {item.ContainerType}
                                  </option>
                                ))}
                              </select>
                            )}
                          </td>


                          {/* Quantity Column */}
                          <td className="text-center">
                            {["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData?.Mode) ? (
                              <span>-</span>
                            ) : (
                              <input
                                type="text"
                                placeholder="Enter Quantity"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                value={container.Quantity || ""}
                                onChange={(e) =>
                                  handleContainerChange(index, "Quantity", e.target.value)
                                }
                              />
                            )}
                          </td>


                          <td>
                            <input
                              type="text"
                              placeholder="Enter Weight"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              value={container.Weight || ""}
                              onChange={(e) =>
                                handleContainerChange(index, "Weight", e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Enter Dimensions"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              value={container.Dimensions || ""}
                              onChange={(e) =>
                                handleContainerChange(index, "Dimensions", e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginLeft: "5px", color: "green" }}
                              onClick={addContainer}
                            />
                            {enquiryContainers.length > 1 && (
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginLeft: "5px", color: "red" }}
                                onClick={() => {
                                  const updatedContainers = enquiryContainers.filter(
                                    (_, i) => i !== index
                                  );
                                  setEnquiryContainers(updatedContainers);
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    enquiryContainers: updatedContainers,
                                  }));
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br></br>
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Vendor
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Rate
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ratesContainers.map((containeres, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              name="vendor"
                              value={containeres.vendor}
                              onChange={(e) => handleRateChange(index, "vendor", e.target.value)}
                              placeholder="Enter your vendor"
                            >
                              <option>Select Vendor Company</option>
                              {VendorData.map((item) => (
                                <option value={item.Company}>
                                  {item.Company}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <span className="rates"></span>
                            <Textarea
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              type="text"
                              name="Rate"
                              value={Array.isArray(containeres.Rate) ? containeres.Rate.join("\n") :
                                containeres.Rate || ""
                              }
                              onChange={(e) => handleRateChange(index, "Rate", e.target.value)}
                              placeholder="Enter your Rate"
                            />
                          </td>


                          <td>
                            {ratesContainers.length > 1 && (
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginTop: "15px", color: "red" }}
                                onClick={() => removerate(index)}
                              />
                            )}


                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "green" }}
                              onClick={addrate}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <>
                <Card align="center" mt={5}>
                  <CardHeader>
                    <Heading size="md">Upload Document</Heading>
                  </CardHeader>
                  <CardBody>
                    <FormControl>
                    <Input
              type="file"
              onChange={handleFileChange}
              name="file"
              multiple
              sx={{
                "::file-selector-button": {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                  color: "red",
                },
              }}
              width={500}
            />
                      <FormHelperText
                        style={{
                          textAlign: "center",
                          fontSize: "14pt",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <span style={{ color: "red" }}>*</span>Attach any related
                        documents.
                      </FormHelperText>
                    </FormControl>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>


                <TableContainer mt={5}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>S.No</Th>
                        <Th>Document Name</Th>
                        <Th>Document Size</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {/* {files.map((file, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>
                            <a
                              href={urls[index]} // Use the generated URL
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", textDecoration: "underline" }}
                            >
                              {file.name}
                            </a>
                          </Td>
                          <Td>{(file.size / 1024).toFixed(2)} KB</Td>
                          <Td>
                            <IconButton
                              size="sm"
                              colorScheme="red"
                              icon={<CloseIcon />}
                              onClick={() => handleRemoveFile(index)}
                              aria-label="Remove file"
                            />
                          </Td>
                        </Tr>
                      ))} */}
                      {/* {formData?.files && formData?.files?.length > 0 ? (
              formData.files.map((file, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{file.name}</Td>
                  <Td>{file.type}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>No files selected</Td>
              </Tr>
            )} */}




{formData?.files && formData?.files.length > 0 ? (
  formData.files.map((file, index) => (
    <Tr key={index}>
      <Td>{index + 1}</Td>
      <Td>{file.name}</Td>
      <Td>{file.type}</Td>
      <Td>
        <button
          onClick={() => {
            const updatedFiles = formData.files.filter((_, i) => i !== index);
            setFormData((prevData) => ({ ...prevData, files: updatedFiles }));
          }}
          style={{
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Remove
        </button>
      </Td>
    </Tr>
  ))
) : (
  <Tr>
    <Td colSpan={4}>No files selected</Td>
  </Tr>
)}


                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            </TabPanel>
            <TabPanel>
              <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
                <div className="content">
                  <div className="form-user">
                    <div className="user-rates">
                      <div className="table-container">
                        <TableContainer
                          className="table-container custom-style"
                          overflowX="auto"
                          maxHeight="400px" // Set max height to enable vertical scrolling
                          overflowY="auto"  // Enable vertical scrolling
                          border="1px" // Border for the table container
                          borderColor="gray.300" // Border color similar to your example
                        >
                          <Table variant="unstyled" width="full" className="bg-white dark:bg-slate-950">
                            <Thead className="text-center bg-blue-950 text-white">
                              <Tr>
                                <Th className="border border-slate-300 whitespace-nowrap text-center">Select</Th>
                                <Th className="border border-slate-300 whitespace-nowrap text-center">AttachmentID</Th>
                                <Th className="border border-slate-300 whitespace-nowrap text-center">File Name</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {formData?.DocumentDetails?.split(";").map((containerString, index) => {
                                if (!containerString.trim()) return null;


                                // Convert container string into an object
                                const container = {};
                                containerString.split(",").forEach((pair) => {
                                  const [key, value] = pair.split(":");
                                  container[key?.trim()] = value?.trim();
                                });


                                const urls = formData?.DocumentURLs
                                  ?.split(";")
                                  .map((url) => url.replace("URL:", "").trim());


                                const url = urls[index];   // Get the URL for the document


                                return (
                                  <Tr key={index}>
                                    <Td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => handleSelectUrl(e, url)}
                                        className="cancelDragAndDrop"
                                      // Disable checkbox if no URL
                                      />
                                    </Td>
                                    <Td>{container.AttachmentID || "N/A"}</Td>
                                    <Td>{container.File_Name || "N/A"}</Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                        <Button
                          mt="4"
                          colorScheme="blue"
                          onClick={handleViewSelectedPDFs}
                        // Disable button if no URLs selected
                        >
                          View Selected
                        </Button>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </form>
    </>
  );
};
export default AddRate;