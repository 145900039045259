import { React, useEffect, useState } from "react";
import { Header } from "../../components";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FormControl,
  FormHelperText,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Card,
  CardBody,
  CardHeader,
  Text,
  Heading,
  CardFooter,
  UnorderedList,
  ListItem,
  Textarea,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import Axios from "../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import { Input } from "@chakra-ui/react";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { IoDocumentAttachOutline } from "react-icons/io5";
import {
  EnquiryAPIInstance,
  AuthAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";
import "react-datepicker/dist/react-datepicker.css";
import "./AddEnquiry.css";
import { GiCancel } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import TextArea from "antd/es/input/TextArea";
// import { Tabs } from "antd";


const Addenquiry = () => {
  const location = useLocation();
  const unProspect = location.state?.selectedData || {};
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [enquiryId, setEnquiryId] = useState(uuidv4());
  const [enquirynumber, setEnquiryNumber] = useState(null);
  const [ModeData, setModeData] = useState([]);
  const [IncotermData, setIncotermData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [prospectNumberData, setprospectNumberData] = useState([]);
  const [customerNumberData, setcustomerNumberData] = useState([]);
  const [ScopeData, setScopeData] = useState([]);
  const [containers, setContainers] = useState([{}]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [StatusData, setStatusData] = useState([]);
  const [PricingData, setPricingData] = useState([]);
  const [PackingTypeData, setPackingTypeData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [TierData, setTierData] = useState([]);
  const [CompanyData, setCompanyData] = useState([]);
  const [PortData, setPortData] = useState([]);
  const [mode, setMode] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [AirportData, setAirportData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const fullName = `${storedUser.firstname} ${storedUser.lastname}`;
  // const [formDataIndex, setFormDataIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [country, setCountry] = useState("in"); //This state is country has to be change same time Phone code also changed
  //get prospect number and customer
  // const location = useLocation();
  const { PNumber } = location.state || {};
  const { CNumber } = location.state || {};


  // Generated Date Auto
  const current = new Date();
  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formData, setFormData] = useState({
    Mode: "",
    OriginCountry: "",
    DestinationCountry: "",
    PickUpLocation: "",
    OriginPortAirport: "",
    DestinationPortAirport: "",
    DeliveryLocation: "",
    EnquiryDescription: "",
    Incoterm: "",
    EnquiryOwner: storedUser.userId,
    Created_By: fullName,
    Commodity: "",
    HSCode: "",
    // Dimensions: "",
    // Weight: "",
    Scope: "",
    FreeTime: "",
    // Equipment: "",
    Stackable: "",
    // Rates_Status:"",
    TypeofPort: "",
    DGNonDG: "",
    PackingType: "",
    CargoReadiness: "",
    ShipperResponsibility: "",
    TotalEquipmentValue: "",
    Status: "",
    Tier: "",
    Company: "",
    Remarks_sales: "",
    Remarks_pricing: "",
    // Remarks: "",
    PricingTeam: [],
    CustomerNumber: "" || CNumber,
    ProspectNumber: "" || PNumber,
    equipmentEnquiry: [
      {
        // id: 1,
        Equipment: "",
        // EnquiryNumber: "",
        // HSCode: "",
        Dimensions: "",
        Weight: "",
        Quantity: "",
      },
    ],


    // HSCode: "",
    Dimensions: "",
    Weight: "",
    files: [],
  });


  const [enquiryContainers, setEnquiryContainers] = useState([
    {
      ContainerType: "",
      Quantity: "",
      Dimension: "",
      Weight: "",
    },
  ]);


  useEffect(() => {
    // Calculate and set the initial follow-up date if not empty
    const calculatedFollowUpDate = calculateFollowUpDate();
    if (calculatedFollowUpDate) {
      setFormData((prevData) => ({
        ...prevData,
        FollowUpDate: calculatedFollowUpDate,
      }));
    }
  }, []);


  useEffect(() => {
    if (storedUser && fullName) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Created_By: fullName,
        EnquiryOwner: storedUser.userId,
      }));
    }
  }, [storedUser, fullName]);


  //const location = useLocation();
  // const { PNumber, CNumber } = location.state || {};


  console.log("State received in addenquiry page:", { PNumber, CNumber });


  // const calculateFollowUpDate = () => {
  //   const currentDate = new Date();
  //   const notificationDays = parseInt(formData.NotificationDays, 10) || 0;
  //   const followUpInterval = parseInt(formData.FollowUpInterval, 10) || 0;


  //   const followUpDate = new Date(currentDate);
  //   followUpDate.setDate(
  //     currentDate.getDate() + notificationDays + followUpInterval
  //   );


  //   return followUpDate.toISOString().split("T")[0];
  // };
  const calculateFollowUpDate = () => {
    const currentDate = new Date();
    const followUpInterval = 2; // Change the follow-up interval to 2 days


    const followUpDate = new Date(currentDate);
    followUpDate.setDate(currentDate.getDate() + followUpInterval);


    return followUpDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_tag");
        setTagData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Mode Api
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // useEffect(() => {
  //   setSelectedData(unProspect);
  //   const defaultCountry = CountryData.find(
  //     (item) => item.Country_name === unProspect.Country
  //   );
  //   if (defaultCountry) {
  //     setFormData((prevData) => {
  //       const newData = {
  //         ...prevData,
  //         Country: defaultCountry.Country_name,
  //         CountryData: defaultCountry,
  //         PhoneWithCode: `${defaultCountry.Phone_code}${
  //           unProspect.Phone || ""
  //         }`,
  //       };
  //       return newData;
  //     });
  //   } else {
  //     setFormData(unProspect);
  //   }
  // }, []);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_airport");
        setAirportData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get(
          "get_equipment"
        );
        setEquipmentData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);




  useEffect(()=>{
    if (unProspect && unProspect.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ProspectNumber: unProspect[0].ProspectNumber,
        Tier: unProspect[0].Tier,
        Company: unProspect[0].Company,
      }));
      setSelectedData(unProspect);
    }
  },[unProspect]);


  useEffect(() => {
    const calculateTotalEquipmentValue = () => {
      // Filter out containers with invalid or empty values
      const filteredContainers = containers.filter(
        (container) =>
          container.Equipment &&
          container.Quantity &&
          !isNaN(parseFloat(container.Quantity))
      );


      // Construct the formatted string for each valid container
      const formattedValues = filteredContainers.map((container) => {
        const containerType = container.Equipment;
        const quantity = parseFloat(container.Quantity) || 0;


        // Create the formatted string for this container
        return `${quantity} X ${containerType}`;
      });


      // Join all formatted values with a comma
      return formattedValues.join(", ");
    };


    const totalValue = calculateTotalEquipmentValue();
    setFormData((prevFormData) => ({
      ...prevFormData,
      TotalEquipmentValue: totalValue,
    }));
  }, [containers, equipmentData]);
  //Packing Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Status API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_status");
        setStatusData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  //Pricing Api
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_pricing");
        setPricingData(data.data); // Correctly set the data
        console.log("Team Data:", data.data); // Log the fetched data
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };


    getData();
  }, []);


  //Tier Api
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setTierData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //prospectNumber api
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_prospectNumber");
        setprospectNumberData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //customernuber api
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customerNumber");
        setcustomerNumberData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  function isWeekend(date) {
    return date.getDay() === 0 || date.getDay() === 6; // 0 is Sunday, 6 is Saturday
  }


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // const handleChangePhone = (value, countryData) => {
  //   if (countryData && countryData.dialCode) {
  //     const dialCodeLength = countryData.dialCode.length;
  //     const Phone = value.slice(dialCodeLength);
  //     setFormData({
  //       ...formData,
  //       Phone,
  //       PhoneWithCode: value,
  //       //Country: countryData.iso2,
  //     });
  //   } else {
  //     console.error("countryData is undefined or missing dialCode property");
  //   }
  // };


  // const handleCountryChange = async (e) => {
  //   const selectedCountry = e.target.value;
  //   const selectedCountryData = countryData.find(
  //     (item) => item.Country_name === selectedCountry
  //   );
  //   console.log("Selected Country Data:", selectedCountryData);
  //   if (selectedCountryData) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       Country: selectedCountry, // Update the "Country" field with the selected country name
  //       CountryData: selectedCountryData,
  //       PhoneWithCode: selectedCountryData.Phone_code,
  //     }));
  //     try {
  //       setCityData([]);
  //       const { data } = await Axios.get(
  //         `/masterdata/get_city?countryData=${selectedCountryData.Country_name}`
  //       );


  //       setCityData(data.data);
  //       console.log("Cities Data:", data.data);
  //     } catch (error) {
  //       toast({
  //         title: error.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   } else {
  //     toast({
  //       title: "Country Data Not Found",
  //       position: "bottom-center",
  //       isClosable: true,
  //       status: "error",
  //       containerStyle: {
  //         width: "98.5vw",
  //         maxWidth: "98.5vw",
  //         alignContent: "center",
  //       },
  //     });


  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   Country: selectedCountry, // Update the "Country" field with the selected country name
  //     //   CountryData: selectedCountryData,
  //     //   PhoneWithCode: selectedCountryData.Phone_code,
  //     // }));
  //   }
  // };




  const handleChange = async (e) => {
    const { name, value } = e.target;


    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
        equipmentEnquiry: [
          {
            Equipment: "",
            Quantity: "",
          },
        ],
      };
    });
  };


  const PricingTeam = formData?.PricingTeam || [];


  // Handle multi-select change
  const handleMultiSelectChange = (selectedOptions) => {
    console.log("Selected Options:", selectedOptions); // Debugging log
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];


    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData = {
        ...updatedData,
        PricingTeam: selectedValues, // Update with the selected values
      };
      return updatedData;
    });
  };


  // Prepare options for react-select
  const options = PricingData.map((item) => ({
    value: item.Team, // Use team name as value
    label: item.Team, // Use team name as label
  }));


  console.log("PricingData:", PricingData); // Log to check the data structure
  console.log("options:", options); // Log to check the options array
  console.log("formData:", formData); // Log to check formData
  console.log("PricingTeam:", PricingTeam); // Log to check selected team data


  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Create FormData to send in the API request
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "files" && key !== "equipmentEnquiry") {
        formDataToSend.append(key, formData[key]);
      }
    });
    formDataToSend.append("equipmentEnquiry",JSON.stringify(formData.equipmentEnquiry));
    formData.files.forEach((file) => {
      formDataToSend.append("files", file);
    });
  
    try {
      const { data } = await EnquiryAPIInstance.post(
        `add_enquiry?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
  


      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/enquiry");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();


      const { data } = await EnquiryAPIInstance.post(
        `add_Enquiry?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    handleChange(e);
  };


  const handleContainerChange = (index, fieldName, value) => {
    const updatedContainers = [...containers];
    updatedContainers[index] = {
      ...updatedContainers[index],
      [fieldName]: value,
    };


    setFormData({
      ...formData,
      equipmentEnquiry: updatedContainers,
    });


    setContainers(updatedContainers);
  };


  const addContainer = () => {
    setContainers([...containers, {}]);
    setEnquiryContainers([
      ...enquiryContainers,
      {
        ContainerType: "",
        Quantity: "",
        Dimension: "",
        Weight: "",
      },
    ]);
  };


  const removeContainer = (indexToRemove) => {
    setContainers(containers.filter((_, index) => index !== indexToRemove));
    setEnquiryContainers(
      enquiryContainers.filter((_, index) => index !== indexToRemove)
    );
  };


  // const handleFileChange = (e) => {
  //   // Convert the FileList object to an array
  //   const selectedFiles = Array.from(e.target.files);


  //   // Append new files to the existing files in state
  //   setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  // };
  // const handleFileChange = (event) => {
  //   const fileList = event.target.files;
  //   const fileNames = Array.from(fileList).map((file) => file.name);
  // const combinedFileNames = fileNames.join(",");
  // setFormData((prev) => ({
  //   ...prev,
  //   Attachments: combinedFileNames,
  // }));
  // };


  // const handleFileChange = (event) => {
  //   const fileList = event.target.files;
  //   const newFiles = Array.from(fileList); // Convert fileList to an array


  //   // Generate an array of objects, each containing file name and any other data (e.g., document ID)
  //   const newAttachments = newFiles.map((file, index) => ({
  //     id: Date.now() + index, // Generate a unique ID (you can replace this with your own logic)
  //     name: file.name,
  //     size: file.size, // Optional: Add size or any other properties you want to store
  //     type: file.type,
  //   }));


  //   // Update formData with the new attachments (array of objects)
  //   setFormData((prev) => ({
  //     ...prev,
  //     Attachments: [...(prev.Attachments || []), ...newAttachments], // Ensure Attachments is an array before spreading
  //   }));


  //   // Append new files to the state for table display
  //   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  // };
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files ? [...prevData.files, ...selectedFiles] : selectedFiles, // Safely append files
    }));
  };


  return (
    <>
      <Header />
      <div>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Enquiry</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/enquiry", {
                state: { PNumber: PNumber, CNumber: CNumber },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>


        <Tabs w="100%" variant="enclosed">
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Enquiry Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>Equipments</Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              upload Documents
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
                {/* <div className="title">Enquiry Information</div> */}
                <div className="content">
                  <div className="form-user">
                    <div className="user-enquirys">
                      <div className="input-box" hidden>
                        <span className="enquirys" hidden>
                          Enquiry ID<span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="EnquiryId"
                          value={enquiryId}
                          onChange={handleChange}
                          placeholder="Enter your Enquiry Id"
                          required
                          disabled
                          hidden
                        />
                      </div>


                      <div className="input-box">
                        <span className="enquirys">
                          ProspectNumber <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="ProspectNumber"
                          value={formData?.ProspectNumber}
                          onChange={handleChange}
                          placeholder="Enter your ProspectNumber"
                          disabled
                        />
                        {/* <option>Select ProspectNumber</option>
                          {prospectNumberData.map((item) => (
                            <option value={item.ProspectNumber}>
                              {item.ProspectNumber}
                            </option>
                          ))}
                        </select> */}
                      </div>


                      {/* <div className="input-box">
                        <span className="enquirys">
                          CustomerNumber <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="CustomerNumber"
                          value={formData?.CustomerNumber}
                          onChange={handleChange}
                          placeholder="Enter your CustomerNumber"
                        >
                          <option>Select CustomerNumber</option>
                          {customerNumberData.map((item) => (
                            <option value={item.CustomerNumber}>
                              {item.CustomerNumber}
                            </option>
                          ))}
                        </select>
                      </div> */}


                      {/* <div className="input-box">
                        <span className="enquirys">
                          Mode<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Mode"
                          value={formData?.Mode}
                          onChange={handleChange}
                          placeholder="Enter your Mode"
                        >
                          <option>Select Mode</option>
                          {ModeData.map((item) => (
                            <option value={item.modeId}>{item.modeId}</option>
                          ))}
                        </select>
                      </div> */}


                      <div className="input-box">
                        <span className="enquirys">
                          Mode<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Mode"
                          value={formData?.Mode}
                          onChange={(e) => {
                            handleChange(e);
                            setMode(e.target.value);
                          }}
                          placeholder="Enter your Mode"
                        >
                          <option value="">Select Mode</option>
                          {ModeData.map((item) => (
                            <option key={item.modeId} value={item.modeId}>
                              {item.modeId}
                            </option>
                          ))}
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="enquirys">
                          OriginCountry <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="OriginCountry"
                          value={formData?.OriginCountry}
                          onChange={handleChange}
                          placeholder="Enter your OriginCountry"
                        >
                          <option>Select OriginCountry</option>
                          {CountryData.map((item) => (
                            <option value={item.Country_name}>
                              {item.Country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          DestinationCountry{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="DestinationCountry"
                          value={formData?.DestinationCountry}
                          onChange={handleChange}
                          placeholder="Enter your DestinationCountry"
                        >
                          <option>Select DestinationCountry</option>
                          {CountryData.map((item) => (
                            <option value={item.Country_name}>
                              {item.Country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">PickUpLocation</span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="PickUpLocation"
                          value={formData?.PickUpLocation}
                          onChange={handleChange}
                          placeholder="Enter your PickUpLocation"
                        />
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          Select Port/Airport
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="TypeofPort"
                          value={formData?.TypeofPort}
                          onChange={handleTypeChange}
                          placeholder="Enter your  SelectedPort"
                        >
                          <option value="">Select Port/Airport</option>
                          <option value="Port">Port</option>
                          <option value="AirPort">Airport</option>
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="enquirys">
                          OriginPort/Airport
                          <span style={{ color: "red" }}>*</span>
                        </span>


                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="OriginPortAirport"
                          value={formData?.OriginPortAirport}
                          onChange={handleChange}
                          placeholder="Enter your OriginPortAirport"
                        >
                          <option>Select OriginPort/Airport</option>


                          {selectedType === "Port" &&
                            PortData.map((item) => (
                              <option key={item.Port} value={`${item.Port}`}>
                                {item.Port}
                              </option>
                            ))}
                          {selectedType === "AirPort" &&
                            AirportData.map((item) => (
                              <option
                                key={item.Airport}
                                value={`${item.Airport}`}
                              >
                                {item.Airport}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          DestinationPortAirport
                          <span style={{ color: "red" }}>*</span>
                        </span>


                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="DestinationPortAirport"
                          value={
                            formData?.DestinationPortAirport
                          }
                          onChange={handleChange}
                          placeholder="Enter your DestinationPortAirport"
                        >
                          <option>Select DestinationPortAirport</option>


                          {selectedType === "Port" &&
                            PortData.map((item) => (
                              <option key={item.Port} value={`${item.Port}`}>
                                {item.Port}
                              </option>
                            ))}
                          {selectedType === "AirPort" &&
                            AirportData.map((item) => (
                              <option
                                key={item.Airport}
                                value={`${item.Airport}`}
                              >
                                {item.Airport}
                              </option>
                            ))}
                        </select>
                      </div>
                      {/* <div className="input-box">
                  <span className="enquirys">
                    DestinationPortAirport
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DestinationPortAirport"
                    value={formData.DestinationPortAirport}
                    onChange={handleChange}
                    placeholder="Enter your  DestinationPortAirport"
                  />
                </div> */}
                      <div className="input-box">
                        <span className="enquirys">DeliveryLocation</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="DeliveryLocation"
                          value={formData?.DeliveryLocation}
                          onChange={handleChange}
                          placeholder="Enter your  DeliveryLocation"
                        />
                      </div>


                      <div className="input-box">
                        <span className="enquirys">Enquiry Description</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="EnquiryDescription"
                          value={formData?.EnquiryDescription}
                          onChange={handleChange}
                          placeholder="Enter your  EnquiryDescription"
                        />
                      </div>


                      <div className="input-box">
                        <span className="enquirys">
                          Incoterm <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Incoterm"
                          value={formData?.Incoterm}
                          onChange={handleChange}
                          placeholder="Enter your Incoterm"
                        >
                          <option>Select Incoterm</option>
                          {IncotermData.map((item) => (
                            <option value={item.Incoterm}>
                              {item.Incoterm}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          Commodity<span style={{ color: "red" }}>*</span>
                        </span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Commodity"
                          value={formData?.Commodity}
                          onChange={handleChange}
                          placeholder="Enter your Commodity"
                        />
                      </div>
                      <div className="input-box">
                        <span className="enquirys">HSCode</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="HSCode"
                          value={formData?.HSCode}
                          onChange={handleChange}
                          placeholder="Enter your  HSCode"
                        />
                      </div>
                      {/* <div className="input-box">
                        <span className="enquirys">
                          Dimension<span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Dimensions"
                          value={formData?.Dimensions}
                          onChange={handleChange}
                          placeholder="Enter your Dimension"
                        />
                      </div> */}
                      {/* <div className="input-box">
                        <span className="enquirys">
                          Weight<span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Weight"
                          value={formData?.Weight}
                          onChange={handleChange}
                          placeholder="Enter your Weight"
                        />
                      </div> */}
                      <div className="input-box">
                        <span className="enquirys">
                          Scope <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Scope"
                          value={formData?.Scope}
                          onChange={handleChange}
                          placeholder="Enter your Scope"
                        >
                          <option>Select Scope</option>
                          {ScopeData.map((item) => (
                            <option value={item.Scope}>{item.Scope}</option>
                          ))}
                        </select>
                      </div>


                      <div className="input-box">
                        <span className="enquirys">FreeTime</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="FreeTime"
                          value={formData?.FreeTime}
                          onChange={handleChange}
                          placeholder="Enter your FreeTime"
                        />
                      </div>
                      {/* <div className="input-box">
                  <span className="enquirys">Equipment</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Equipment"
                    value={formData.Equipment}
                    onChange={handleChange}
                    placeholder="Enter your Equipment"
                  >
                    <option>Select Equipment</option>
                    {EquipmentData.map((item) => (
                      <option value={item.equipmentId}>
                        {item.equipmentId}
                      </option>
                    ))}
                  </select>
                </div> */}
                      <div className="input-box">
                        <span className="enquirys">
                          Stackable<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Stackable"
                          value={formData?.Stackable}
                          onChange={handleChange}
                        >
                          <option value="">Select Stackable</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>


                      {/* <div className="input-box">
                        <span className="enquirys">
                          Rates Status<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Rates_Status"
                          value={formData?.Rates_Status}
                          onChange={handleChange}
                        >
                          <option value="">Rates Status</option>
                          <option value="Complete">Complete</option>
                          <option value="Incomplete">Incomplete</option>
                        </select>
                      </div> */}


                      <div className="input-box">
                        <span className="enquirys">
                          DGNonDG<span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="DGNonDG"
                          value={formData?.DGNonDG}
                          onChange={handleChange}
                        >
                          <option value="">Select DGNonDG</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          PackingType <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="PackingType"
                          value={formData?.PackingType}
                          onChange={handleChange}
                          placeholder="Enter your PackingType"
                        >
                          <option>Select PackingType</option>
                          {PackingTypeData.map((item) => (
                            <option value={item.packingType}>
                              {item.packingType}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          CargoReadiness<span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="date"
                          name="CargoReadiness"
                          value={formData?.CargoReadiness}
                          onChange={handleChange}
                          placeholder="Enter your CargoReadiness"
                        />
                      </div>
                      {/* <div className="input-box">
                        <span className="enquirys">Attachments</span>
                        <input
                          type="file"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Attachments"
                          onChange={handleFileChange}
                          multiple // If you want to allow multiple file uploads, add this attribute
                        />
                      </div> */}
                      <div className="input-box">
                        <span className="enquirys">ShipperResponsibility</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="ShipperResponsibility"
                          value={formData?.ShipperResponsibility}
                          onChange={handleChange}
                          placeholder="Enter your ShipperResponsibility"
                        />
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          Status <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Status"
                          value={formData?.Status}
                          onChange={handleChange}
                          placeholder="Enter your Status"
                        >
                          <option>Select Status</option>
                          {StatusData.map((item) => (
                            <option value={item.statusId}>
                              {item.statusId}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          Tier <span style={{ color: "red" }}>*</span>
                        </span>
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Tier"
                          value={formData?.Tier}
                          onChange={handleChange}
                          placeholder="Enter your Grade"
                        >
                          <option>Select Tier</option>
                          {TierData.map((item) => (
                            <option key={item.Tier} value={item.Tier}>
                              {item.Tier}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input-box">
                        <span className="enquirys">
                          Company <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="Company"
                          value={formData?.Company}
                          onChange={handleChange}
                          placeholder="Enter your Company"
                        ></input>
                      </div>
                      {/* 
                      <div className="input-box">
                        <span className="enquirys">Remarks</span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Remarks"
                          value={formData.Remarks}
                          onChange={handleChange}
                          placeholder="Enter your Remarks"
                        />
                      </div> */}


                      <div className="input-box">
                        <span className="enquirys">Remarks_sales</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Remarks_sales"
                          value={formData?.Remarks_sales}
                          onChange={handleChange}
                          placeholder="Enter your Remarks_sales"
                        />
                      </div>
                      <div className="input-box">
                        <span className="enquirys">Remarks_pricing</span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Remarks_pricing"
                          value={formData?.Remarks_pricing}
                          onChange={handleChange}
                          placeholder="Enter your Remarks_pricing"
                        />
                      </div>
                      {/* <div className="input-box">
                        <span className="enquirys">
                          PricingTeam<span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="PricingTeam"
                          value={formData?.PricingTeam}
                          onChange={handleChange}
                          placeholder="Enter your PricingTeam"
                        />
                      </div> */}


                      <div className="input-box">
                        <span className="enquirys">
                          PricingTeam <span style={{ color: "red" }}>*</span>
                        </span>
                        {
                          <select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            name="PricingTeam"
                            value={formData?.PricingTeam}
                            onChange={handleChange}
                            placeholder="Enter your PricingTeam"
                          >
                            <option>Pricing Team</option>
                            {PricingData.map((item) => (
                              <option value={item.userId}>{item.Team}</option>
                            ))}
                          </select>
                        }
                        {/* <Select
        id="pricing-team"
        isMulti
        options={options} 
        className="text-black bg-white dark:bg-gray-950 dark:text-white"
        placeholder="Select Pricing Teams"
        value={options.filter((option) =>
          PricingTeam.includes(option.value) 
        )}
        onChange={handleMultiSelectChange} 
      /> */}
                      </div>


                      <div className="input-box">
                        <span className="enquirys">
                          TotalEquipmentValue
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <TextArea
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Enquiry"
                          value={formData.TotalEquipmentValue}
                          onChange={handleChange}
                          placeholder="Enter your TotalEquipmentValue"
                          readOnly // Assuming this field is for display only
                          disabled={[
                            "Airfreight",
                            "LCL",
                            "Breakbulk/RORO",
                          ].includes(mode)} // Disable based on mode
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>


            <TabPanel>
              <div className="content">
                <div className="form-user">
                  <div className="user-enquirys"></div>
                  <div className="table-container">
                    {/* <div className="input-box">
                      <span className="enquirys">
                        Mode<span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        name="Mode"
                        value={formData?.Mode || ""}
                        onChange={(e) => {
                          handleChange(e);
                          setMode(e.target.value); // Ensure setMode is called to handle mode-specific actions
                        }}
                        placeholder="Enter your Mode"
                      >
                        <option value="">Select Mode</option>
                        {ModeData.map((item) => (
                          <option key={item.modeId} value={item.modeId}>
                            {item.modeId}
                          </option>
                        ))}
                        
                      </select>
                    </div> */}


                    {/* Additional hardcoded options */}
                    {/* <option value="Air Freight">Air Freight</option>
                        <option value="LCL">LCL</option>
                        <option value="Breakbulk/RORO">Breakbulk/RORO</option>
                        <option value="Other">Other</option> */}
                    <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                      <thead className="text-center bg-blue-950 text-white">
                        <tr>
                          <th className="border border-slate-300 whitespace-nowrap">
                            Equipment
                          </th>
                          <th
                            className="border border-slate-300 whitespace-nowrap"
                            style={{ width: "30%" }}
                          >
                            Qty
                          </th>
                          {/* <th className="border border-slate-300 whitespace-nowrap">
                            <span className="enquirys">HSCode</span>
                          </th> */}
                          <th className="border border-slate-300 whitespace-nowrap">
                            <span className="enquirys">Dimension</span>
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap">
                            <span className="enquirys">Weight</span>
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {containers.map((container, index) => (
                          <tr key={index}>
                            {/* Equipment Column */}
                            <td className="text-center">
                              {["Airfreight", "LCL", "Breakbulk/RORO"].includes(
                                mode
                              ) ? (
                                <span>-</span>
                              ) : (
                                <select
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  name="ContainerType"
                                  value={container.Equipment || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Equipment",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Equipment</option>
                                  {equipmentData.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item.ContainerType}
                                    >
                                      {item.ContainerType}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>


                            {/* Quantity Column */}
                            <td className="text-center">
                              {["Airfreight", "LCL", "Breakbulk/RORO"].includes(
                                mode
                              ) ? (
                                <span>-</span>
                              ) : (
                                <input
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                  type="text"
                                  name="Quantity"
                                  value={container.Quantity || ""}
                                  onChange={(e) =>
                                    handleContainerChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>


                            {/* HSCode Column */}
                            {/* <td className="text-center">
                              <input
                                className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                name="HSCode"
                                value={container.HSCode || ""}
                                onChange={(e) =>
                                  handleContainerChange(
                                    index,
                                    "HSCode",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter your HSCode"
                              />
                            </td> */}


                            {/* Dimension Column */}
                            <td className="text-center">
                              <input
                                className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                type="text"
                                name="Dimension"
                                value={container.Dimension || ""}
                                onChange={(e) =>
                                  handleContainerChange(
                                    index,
                                    "Dimension",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter Dimension"
                              />
                            </td>


                            {/* Weight Column */}
                            <td className="text-center">
                              <input
                                className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                type="text"
                                name="Weight"
                                value={container.Weight || ""}
                                onChange={(e) =>
                                  handleContainerChange(
                                    index,
                                    "Weight",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter Weight"
                              />
                            </td>


                            {/* Actions Column */}
                            <td className="text-center">
                              {containers.length > 1 && (
                                <button
                                  className="text-red-600"
                                  onClick={() => removeContainer(index)}
                                  style={{ marginTop: "15px" }}
                                >
                                  <BiSolidMinusCircle size={30} />
                                </button>
                              )}
                              <button
                                className="text-green-600"
                                onClick={addContainer}
                                style={{ marginTop: "15px" }}
                              >
                                <IoMdAddCircle size={30} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              {/* <Card align="center">
                <CardHeader>
                  <Heading size="md">Upload Document</Heading>
                </CardHeader>
                <CardBody>
                  <input type="file" multiple onChange={handleFileChange} />
                  <h3>Selected Files:</h3>
                  <UnorderedList>
                    {files.map((file, index) => (
                      <ListItem key={index}>{file.name}</ListItem>
                    ))}
                  </UnorderedList>
                </CardBody>
                <CardFooter>
                  <Button colorScheme="blue">View here</Button>
                </CardFooter>
              </Card> */}
              <Card align="center" mt={5}>
                <CardHeader>
                  <Heading size="md">Upload Document</Heading>
                </CardHeader>
                <CardBody>
                  <FormControl>
                    <Input
                      type="file"
                      onChange={handleFileChange}
                      name="file"
                      multiple // Allow multiple files to be selected
                      sx={{
                        "::file-selector-button": {
                          height: 10,
                          padding: 0,
                          mr: 4,
                          background: "none",
                          border: "none",
                          fontWeight: "bold",
                          color: "red",
                        },
                      }}
                      width={500}
                    />
                    <FormHelperText
                      style={{
                        textAlign: "center",
                        fontSize: "14pt",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>Attach any related
                      documents.
                    </FormHelperText>
                  </FormControl>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>


              <TableContainer mt={5}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>S.No</Th>
                      <Th>Document Name</Th>


                      <Th>Type</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {formData?.files && formData?.files?.length > 0 ? (
              formData.files.map((file, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{file.name}</Td>
                  <Td>{file.type}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>No files selected</Td>
              </Tr>
            )}
                  
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </>
  );
};


export default Addenquiry;