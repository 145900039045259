import React from "react";
import { useState, useEffect } from "react";
import { Header, OperationHeader } from "../../../../components";

import { v4 as uuidv4 } from "uuid";
import { OperationDocumentAPIInstance,MasterDataAPIInstance } from "../../../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../AddDocument/freightCertification/AddFreightCertificate.css";
import { GiCancel } from "react-icons/gi";

const EditFreightCertificate = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("sss:", selectedJob);
  const [fcnumber, setFCNumber] = useState(null);
  const [editedFcData, setEditedFcData] = useState({});
  const [selectedFcData, setselectedFcData] = useState({});
  const [PortData, setportData] = useState([]);
  useEffect(() => {
    if (selectedJob) {
      setselectedFcData({
        FcNumber: selectedJob.FcNumber || "",
        Date: selectedJob.Date || "",
        MBLNumber: selectedJob.MBLNumber || "",
        HBLNumber: selectedJob.HBLNumber || "",
        POL: selectedJob.POL || "",
        POD: selectedJob.POD || "",
        SeaFreight: selectedJob.SeaFreight || "",
        ExWork: selectedJob.ExWork || "",
        ContainerNumber: selectedJob.ContainerNumber || "",
        FCOwner: selectedJob.FCOwner || "",
      });
    }
  }, [selectedJob]);
  const [formData, setFormData] = useState(selectedFcData);

  useEffect(() => {
    // Update formData with editedSiData
    setFormData(editedFcData);
  }, [editedFcData]);
  const handlePolChange = (e) => {
    setEditedFcData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedFcData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedFcData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  // End
  useEffect(() => {
    setEditedFcData(selectedFcData);
  }, [selectedFcData]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //save
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.put(
      `edit_freightcertificate?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <form>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">
            Edit Freight Certificate
          </p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="content">
            <div className="form-user">
              <div className="user-freightcertificate">
                <div className="input-box">
                  <span className="freightcertificate">
                    Date
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="Date"
                    name="Date"
                    defaultValue={
                      (
                        formData["Date"] ||
                        selectedFcData["Date"] ||
                        editedFcData["Date"] ||
                        ""
                      )?.split("T")[0] || ""
                    }
                    onChange={handleChange}
                    placeholder="Enter your Date"
                  />
                </div>
                {/* <div className="input-box">
                  <span className="freightcertificate">
                    POL
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedFcData["POL"]}
                    value={editedFcData["POL"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POL"
                  />
                </div> */}
                <div className="input-box">
                  <span className="hblcreations">
                    POL
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedFcData["POL"]}
                    value={editedFcData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option value={formData["POL"] || selectedFcData["POL"]}>
                      {formData["POL"] || selectedFcData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="freightcertificate">
                    MBL Number
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBLNumber"
                    defaultValue={
                      formData["MBLNumber"] || selectedFcData["MBLNumber"]
                    }
                    value={editedFcData["MBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your MBLNumber"
                  />
                </div>
                {/* <div className="input-box">
                  <span className="freightcertificate">
                    POD
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedFcData["POD"]}
                    value={editedFcData["POD"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POD"
                  />
                </div> */}
                <div className="input-box">
                  <span className="freightcertificate">
                    POD
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedFcData["POD"]}
                    value={editedFcData["POD"] || ""}
                    onChange={handlePodChange}
                    placeholder="Enter your POD"
                  >
                    <option value={formData["POD"] || selectedFcData["POD"]}>
                      {formData["POD"] || selectedFcData["POD"]}
                    </option>

                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="freightcertificate">
                    HBL Number
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HBLNumber"
                    defaultValue={
                      formData["HBLNumber"] || selectedFcData["HBLNumber"]
                    }
                    value={editedFcData["HBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your HBLNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="freightcertificate">
                    Sea Freight
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="SeaFreight"
                    defaultValue={
                      formData["SeaFreight"] || selectedFcData["SeaFreight"]
                    }
                    value={editedFcData["SeaFreight"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your SeaFreight"
                  />
                </div>
                <div className="input-box">
                  <span className="freightcertificate">
                    Container Number
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContainerNumber"
                    defaultValue={
                      formData["ContainerNumber"] ||
                      selectedFcData["ContainerNumber"]
                    }
                    value={editedFcData["ContainerNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Container Number"
                  />
                </div>
                <div className="input-box">
                  <span className="freightcertificate">
                    ExWorks
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ExWork"
                    defaultValue={
                      formData["ExWork"] || selectedFcData["ExWork"]
                    }
                    value={editedFcData["ExWork"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ExWorks"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditFreightCertificate;
