import { React, useState, useEffect } from "react";
import Axios, { OperationDocumentAPIInstance } from "../../api-instance";
import { useNavigate } from "react-router-dom";
// Internal module import
import "./DraggableChecklistTable.css";
// Chakara ui modules

import {
  IconButton,
  Tooltip,
  useToast,
  Button,
  Select,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";

const DraggableChecklistTable = ({
  isLoading,
  columns,
  data,
  operationChecklistData,
  getData,
  setOperationChecklistData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsOperationChecklist,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const navigate = useNavigate();
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...operationChecklistData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setOperationChecklistData === "function") {
      setOperationChecklistData(sortedData);
    }
  };
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateChecklist = async (rowData) => {
    console.log(formData, "form");
    const requestData = {
      ...rowData,
      // Status: formData[`Status-${rowData.id}`] === "Yes" ? 1 : 0,
      Status: formData[`Status-${rowData.id}`],
    };
    try {
      const { data } = await OperationDocumentAPIInstance.PUT(
        `update_checklist?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log("====================================");
      console.log(data);
      console.log("====================================");
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_checklist");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.error("Error updating checklist:", error);
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <div>
      {!isLoading ? (
        tableColumnsOperationChecklist?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                {tableColumnsOperationChecklist.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            {/* <BiSort
                            style={{
                              marginLeft: "160px",
                              marginTop: "-22px",
                              cursor: "pointer",
                            }}
                            onClick={() => sortTable(column.key)}
                          /> */}
                          </>
                        }
                      </th>
                    )
                )}
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    {tableColumnsOperationChecklist.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Status" ? (
                              <Select
                                w="25%"
                                name={`Status-${row.id}`}
                                value={formData[`Status-${row.id}`] || ""}
                                onChange={handleChange}
                              >
                                <option>Select option</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Select>
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                    <td
                      style={{
                        textAlign: "left",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        colorScheme="teal"
                        size="xs"
                        onClick={() => handleUpdateChecklist(row)}
                      >
                        Submit
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsOperationChecklist.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DraggableChecklistTable;
