import { React, useState, useEffect } from "react";
import Axios, {
  JobAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
// Internal module import
import "./DraggableJobTable.css";
import { Rnd } from "react-rnd";
// Chakara ui modules
import { useNavigate } from "react-router-dom";
import {
  useDisclosure,
  IconButton,
  useToast,
  Stack,
  Button,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Input,
  Badge,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

const DraggableJobTable = ({
  isLoading,
  columns,
  data,
  jobData,
  getData,
  setJobData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsJob,
  pageNumbers,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});
  const [selectedJobContainer, setSelectedJobContainer] = useState({});
  const [selectedJobVendor, setSelectedJobVendor] = useState({});
  const [editedJob, setEditedJob] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("xl"); // Set the initial size to 'full'

  const {
    isOpen: isOpenContainer,
    onOpen: onOpenContainer,
    onClose: onCloseContainer,
  } = useDisclosure();

  const {
    isOpen: isOpenVendor,
    onOpen: onOpenVendor,
    onClose: onCloseVendor,
  } = useDisclosure();

  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const [sizeContainer, setSizeContainer] = useState("xl");
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const [sizeVendor, setSizeVendor] = useState("xl");
  const [formData, setFormData] = useState({});
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  // Grade Map
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // UseEffects
  useEffect(() => {
    setEditedJob(selectedJob);
  }, [selectedJob]);

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...jobData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setJobData === "function") {
      setJobData(sortedData);
    }
  };
  function handleContainerChange(name, value, str) {
    // Parse the string into an array of objects
    let containers = str.split(";").map((item) => {
      let obj = {};
      item
        .trim()
        .split(",")
        .forEach((prop) => {
          let [key, val] = prop.split(":").map((p) => p.trim());
          obj[key] = isNaN(val) ? val : parseFloat(val);
        });
      return obj;
    });

    // Update the specified object
    let [propertyName, index] = name.split("-");
    index = parseInt(index);
    containers[index][propertyName] = value;

    // Convert the array back into a string
    let updatedStr = containers
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, val]) => `${key}: ${val}`)
          .join(", ");
      })
      .join("; ");

    return updatedStr;
  }

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;

      // Update editedBooking state
      setEditedJob((prev) => ({
        ...prev,
        [name]: value,
      }));
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleInnerContainerChange = async (e) => {
    try {
      const { name, value } = e.target;

      const ans = handleContainerChange(name, value, editedJob.Containers);

      // Update editedBooking state
      setEditedJob((prev) => ({
        ...prev,
        Containers: ans,
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedJob,
    };
    console.log(updateData);
    try {
      const { data } = await JobAPIInstance.put(
        `singleupdate_job?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedJob.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Job Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedBooking state here
        setSelectedJob((prevSelectedJob) => ({
          ...prevSelectedJob,
          ...editedJob,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  //  Function to open the modal and fetch booking data for a particular ID
  const handleOpenModal = async (id) => {
    try {
      const selectedJobData = jobData.find((job) => job.id === id);
      setSelectedJob(selectedJobData);
      handleFullSizeClick();
      setSelectedRowId(id);
      // const { data } = await Axios.get(
      //   `/master_api/get_city?countryData=${selectedBookingData?.Country}`
      // );
      // setCityData(data.data);
    } catch (error) {
      console.log(error);
    }
    // console.log(JobNumber);
  };

  const handleOpenContainer = async (BookingNumber) => {
    try {
      const selectedJobData = jobData.find(
        (job) => job.BookingNumber === BookingNumber
      );
      console.log("View Container", selectedJobData.BookingNumber);
      setSelectedJobContainer(selectedJobData);
      setSelectedRowId(BookingNumber);
      handleFullSizeContainer();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenVendor = async (BookingNumber) => {
    try {
      const selectedJobData = jobData.find(
        (job) => job.BookingNumber === BookingNumber
      );
      console.log("View Vendor", selectedJobData.BookingNumber);
      setSelectedJobVendor(selectedJobData);
      setSelectedRowId(BookingNumber);
      handleFullSizeVendor();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  const handleCloseConatiner = () => {
    setSelectedRowId(null);
    setIsContainerOpen(false);
    onCloseContainer(true);
  };

  const handleCloseVendor = () => {
    setSelectedRowId(null);
    setIsVendorOpen(false);
    onCloseVendor(true);
  };

  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };

  const handleFullSizeContainer = () => {
    setSizeContainer("xl");
    onOpenContainer();
  };

  const handleFullSizeVendor = () => {
    setSizeVendor("xl");
    onOpenVendor();
  };

  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };

  const handleEditJob = (data) => {
    console.log(data);
    navigate(`/editjob/${data.JobNumber}`);
  };

  return (
    <div>
      {!isLoading ? (
        tableColumnsJob?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
                    <button
                    onClick={toggleIcon}
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    checkBoxAll={checkBoxAll}
                    >
                      {showFactCheck ? (
                        <MdOutlineFactCheck size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}
                    </button> */}
                  </div>
                </th>
                {/* <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th> */}

                {tableColumnsJob.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "JobOwner" &&
                    // column.label !== "Containers" &&
                    // column.label !== "add_costid" &&

                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.label === "Operation_Transfer_Deadline" ? (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "230px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>

                    {tableColumnsJob.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "JobOwner" &&
                        // column.label !== "Containers" &&
                        // column.label !== "add_costid" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "JobNumber" ? (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditJob(row)}
                              >
                                <Tooltip
                                  hasArrow
                                  label="Edit row"
                                  bg="green.600"
                                >
                                  {row.JobNumber}
                                </Tooltip>
                              </a>
                            ) : column.key === "DOBC" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "CY_Open" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "SI_Cutoff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "VGM_Cutoff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "CY_Cutoff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Customs_Cutoff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "OOG_Cutoff" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ETA" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ETD" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Cargo_Pickup_Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Empty_Pickup_Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Stuffing_Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Laden_Gate_In_Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Vessel_Arrival_Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Operation_Transfer_Deadline" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Detention" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Demurrage" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Port_Storage" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ConversionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">Converted</Badge>
                              ) : (
                                <Badge colorScheme="red">Not Converted</Badge>
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : column.key === "Containers" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() =>
                                      handleOpenContainer(row?.BookingNumber)
                                    }
                                  >
                                    View Conatiner
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : column.key === "Vendors" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() =>
                                      handleOpenVendor(row?.BookingNumber)
                                    }
                                  >
                                    View Vendor
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}

                    {/* <Accordion defaultIndex={[0]} allowMultiple>
                      <AccordionItem>
                        <h2>
                          <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as='span' flex='1' textAlign='left'>
                              {row.JobNumber}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>

                          {tableColumnsJob.map(
                            (column, columnIndex) =>
                              column.label !== "Id" &&
                              // column.label !== "Containers" &&
                              // column.label !== "add_costid" &&
                              column.isVisible === true && (
                                <td
                                  key={`${rowIndex}-${columnIndex}`}
                                  style={{
                                    textAlign: "left",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  {

                                    column.key === "JobNumber" ? (

                                      <a style={{ cursor: "pointer" }} onClick={() => handleEditJob(row)}>

                                        <Tooltip hasArrow label='Edit row' bg='green.600'>
                                          {row.JobNumber}
                                        </Tooltip>

                                      </a>
                                    )
                                      :
                                      column.key === "DOBC" ? (
                                        row[column.key] ? (
                                          new Date(row[column.key]).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) : column.key === "CY_Open" ? (
                                        row[column.key] ? (
                                          new Date(row[column.key]).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) :
                                        column.key === "SI_Cutoff" ? (
                                          row[column.key] ? (
                                            new Date(row[column.key]).toLocaleDateString()
                                          ) : (
                                            ""
                                          )
                                        ) :
                                          column.key === "VGM_Cutoff" ? (
                                            row[column.key] ? (
                                              new Date(row[column.key]).toLocaleDateString()
                                            ) : (
                                              ""
                                            )
                                          ) :
                                            column.key === "CY_Cutoff" ? (
                                              row[column.key] ? (
                                                new Date(row[column.key]).toLocaleDateString()
                                              ) : (
                                                ""
                                              )
                                            ) :
                                              column.key === "Customs_Cutoff" ? (
                                                row[column.key] ? (
                                                  new Date(row[column.key]).toLocaleDateString()
                                                ) : (
                                                  ""
                                                )
                                              ) :
                                                column.key === "OOG_Cutoff" ? (
                                                  row[column.key] ? (
                                                    new Date(row[column.key]).toLocaleDateString()
                                                  ) : (
                                                    ""
                                                  )
                                                ) :
                                                  column.key === "ETA" ? (
                                                    row[column.key] ? (
                                                      new Date(row[column.key]).toLocaleDateString()
                                                    ) : (
                                                      ""
                                                    )
                                                  ) :
                                                    column.key === "ETD" ? (
                                                      row[column.key] ? (
                                                        new Date(row[column.key]).toLocaleDateString()
                                                      ) : (
                                                        ""
                                                      )
                                                    ) :
                                                      column.key === "Cargo_Pickup_Date" ? (
                                                        row[column.key] ? (
                                                          new Date(row[column.key]).toLocaleDateString()
                                                        ) : (
                                                          ""
                                                        )
                                                      ) :
                                                        column.key === "Empty_Pickup_Date" ? (
                                                          row[column.key] ? (
                                                            new Date(row[column.key]).toLocaleDateString()
                                                          ) : (
                                                            ""
                                                          )
                                                        ) :
                                                          column.key === "Stuffing_Date" ? (
                                                            row[column.key] ? (
                                                              new Date(row[column.key]).toLocaleDateString()
                                                            ) : (
                                                              ""
                                                            )
                                                          ) :
                                                            column.key === "Laden_Gate_In_Date" ? (
                                                              row[column.key] ? (
                                                                new Date(row[column.key]).toLocaleDateString()
                                                              ) : (
                                                                ""
                                                              )
                                                            ) :
                                                              column.key === "Vessel_Arrival_Date" ? (
                                                                row[column.key] ? (
                                                                  new Date(row[column.key]).toLocaleDateString()
                                                                ) : (
                                                                  ""
                                                                )
                                                              ) :
                                                                column.key === "Operation_Transfer_Deadline" ? (
                                                                  row[column.key] ? (
                                                                    new Date(row[column.key]).toLocaleDateString()
                                                                  ) : (
                                                                    ""
                                                                  )
                                                                ) :
                                                                  column.key === "Detention" ? (
                                                                    row[column.key] ? (
                                                                      new Date(row[column.key]).toLocaleDateString()
                                                                    ) : (
                                                                      ""
                                                                    )
                                                                  ) :
                                                                    column.key === "Demurrage" ? (
                                                                      row[column.key] ? (
                                                                        new Date(row[column.key]).toLocaleDateString()
                                                                      ) : (
                                                                        ""
                                                                      )
                                                                    ) :
                                                                      column.key === "Port_Storage" ? (
                                                                        row[column.key] ? (
                                                                          new Date(row[column.key]).toLocaleDateString()
                                                                        ) : (
                                                                          ""
                                                                        )
                                                                      ) :

                                                                        column.key === "Containers" ? (
                                                                          row[column.key] ? (
                                                                            <>
                                                                              <Button
                                                                                colorScheme='teal'
                                                                                size='sm'
                                                                                onClick={() => handleOpenContainer(row?.id)}
                                                                              >
                                                                                View Conatiner
                                                                              </Button>
                                                                            </>
                                                                          ) : (
                                                                            ""
                                                                          )
                                                                        )

                                                                          : (
                                                                            row[column.key]
                                                                          )}
                                </td>
                              )
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion> */}

                    {/* <td className="flex-nowrap text-center" >
                      {row.Containers.split(';').map((containerInfo, index) => {
                        const containerDetails = containerInfo.trim().split(', ');
                        return (

                          <tr key={index}>
                            {containerDetails.map((detail, detailIndex) => {
                              const [key, value] = detail.split(': ');
                              if (key.trim() === 'Container_ID') {
                                // setContainerId(value.trim());

                                return null;
                              }
                              return <td
                                style={{
                                  whiteSpace: "nowrap",
                                  paddingLeft: "50px",
                                }}
                                key={detailIndex}>{value}</td>;
                            })}
                          </tr>

                        );
                      })}
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsJob.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}

      <>
        {isOpenContainer && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header>Container Information</header>
              <div
                className="closemodule"
                onClick={() => {
                  handleCloseConatiner(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Container Type</Th>
                      <Th>Quantity</Th>
                      <Th>Weight</Th>
                      <Th>Dimensions</Th>
                      <Th>Container Number</Th>
                      <Th>Seal Number</Th>
                      <Th>No Of Pkge</Th>
                      <Th>Type Of Pkge</Th>
                      <Th>Net Weight</Th>
                      <Th>Gross Weight</Th>
                      <Th>Volume</Th>
                      <Th>Tare Weight</Th>
                      <Th>VGM</Th>
                      <Th>VGM Method</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedJobContainer?.Containers?.split(";").map(
                      (containerInfo, index) => {
                        const containerDetails = containerInfo
                          .trim()
                          ?.split(", ");
                        return (
                          <Tr key={index}>
                            {containerDetails.map((detail, detailIndex) => {
                              const [key, value] = detail?.split(": ");
                              if (key.trim() === "Container_ID") {
                                // setContainerId(value.trim());
                                return null;
                              }
                              return (
                                <Td
                                  style={{
                                    whiteSpace: "nowrap",
                                    // paddingLeft: "50px",
                                  }}
                                  key={detailIndex}
                                >
                                  {value}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenVendor && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header>Vendor Information</header>
              <div
                className="closemodule"
                onClick={() => {
                  handleCloseVendor(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Vendor</Th>
                      <Th>Details</Th>
                      <Th>Currency</Th>
                      <Th>Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedJobVendor?.Vendors?.split(";").map(
                      (vendorInfo, index) => {
                        const vendorDetails = vendorInfo.trim().split(", ");
                        return (
                          <Tr key={index}>
                            {vendorDetails.map((detail, detailIndex) => {
                              const [key, value] = detail.split(": ");
                              if (key.trim() === "add_costid") {
                                // setContainerId(value.trim());
                                return null;
                              }
                              return (
                                <Td
                                  style={{
                                    whiteSpace: "nowrap",
                                    // paddingLeft: "50px",
                                  }}
                                  key={detailIndex}
                                >
                                  {value}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Rnd>
          </div>
          </>
        )}
      </>
    </div>
  );
};

export default DraggableJobTable;
