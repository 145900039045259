import React from "react"; // Import React
import { useState, useEffect } from "react"; // Import necessary hooks
import { Header } from "../../components"; // Import a Header component
import axios from "axios"; // Import Axios for making HTTP requests
import { v4 as uuidv4 } from "uuid"; // Import the uuidv4 function for generating unique IDs
import Axios,{MasterDataAPIInstance} from "../../api-instance";// Import Axios with a custom configuration
import { Center, useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI for displaying notifications
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router for navigation

import "./Addclient.css"; // Import CSS styles

const Addclient = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast(); // Initialize the useToast hook for notifications
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [tierData, setTierData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [formData, setFormData] = useState({
    // Initialize a state variable with form data,
    userId:JSON.parse(localStorage.getItem("user")).userId,
   
    ClientName: "",
    Clienttype:"",
    SPECIALIZATION:"",
Addressline1:"",
Addressline2:"",
CITY:"",
COUNTRY:"",
PostalCode:"",
State:"",
TaxNumber :"",
Industry:"",
Remarks:"",
Grade:"",
Tag:"",
GSTInfo:"",
PAN:"",
SST:"",
TDS:"",
FAX:"",
PIC1:"",
PIC1_email:"",
PIC1_contactno:"",
PIC1_designation:"",
PIC2:"",
PIC2_email:"",
PIC2_contactno:"",
PIC2_designation:"",
PIC3:"",
PIC3_email:"",
PIC3_contactno:"",
PIC3_designation:"",
userId: JSON.parse(localStorage.getItem("user")).userId,

  });

  //Industry Api 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_industry");
        setIndustryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
//tag
useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("get_tag");
      setTagData(data.data);
      console.log(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);
  //Grade Api 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setTierData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countryData.find(
      (item) => item.Country_name === selectedCountry
    );
    console.log("Selected Country Data:", selectedCountryData);
    if (selectedCountryData) {
      setFormData((prevData) => ({
        ...prevData,
        Country: selectedCountry, // Update the "Country" field with the selected country name
        CountryData: selectedCountryData,
        PhoneWithCode: selectedCountryData.Phone_code,
      }));
      try {
        setStateData([]);
        const { data } = await MasterDataAPIInstance.get(
          `get_country_state?countryData=${selectedCountryData.Country_name}`
        );

        setStateData(data.data);
        console.log("State Data:", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "Country Data Not Found",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });

      // setFormData((prevData) => ({
      //   ...prevData,
      //   Country: selectedCountry, // Update the "Country" field with the selected country name
      //   CountryData: selectedCountryData,
      //   PhoneWithCode: selectedCountryData.Phone_code,
      // }));
    }
  };
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    const selectedStateData = stateData.find(
      (item) => item.State_name === selectedState
    );
    console.log("Selected State Data:", selectedStateData);
    if (selectedStateData) {
      setFormData((prevData) => ({
        ...prevData,
        State: selectedState, // Update the "State" field with the selected State name
        
      }));
      try {
        setCityData([]);
        const { data } = await MasterDataAPIInstance.get(
          `get_state_city?stateData=${selectedStateData.State_name}`
        );

        setCityData(data.data);
        console.log("State Data:", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "State Data Not Found",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });

      // setFormData((prevData) => ({
      //   ...prevData,
      //   Country: selectedCountry, // Update the "Country" field with the selected country name
      //   CountryData: selectedCountryData,
      //   PhoneWithCode: selectedCountryData.Phone_code,
      // }));
    }
  };
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(formData, "form"); // Log the form data to the console
    const { data } = await MasterDataAPIInstance.post(
      // Send a POST request using Axios
      `addMasterData/add_client?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData, // Include form data in the request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
        },
      }
    );

    console.log("===================================="); // Log a separator line
    console.log(data); // Log the response data
    console.log("===================================="); // Log another separator line

    if (data.success) {
      // Check if the response indicates success
      toast({
        title: "Client created successfully!", // Show a success toast notification
        position: "bottom-right",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/client"); // Navigate to the "/source" route
    } else {
      toast({
        title: data.message, // Show an error toast notification
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Function to handle input changes
  const handleChange = async (e) => {
    const { name, value } = e.target; // Get the name and value from the input element

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value, // Update the form data with the changed input value
      };
    });
  };
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await MasterDataAPIInstance.post(
        `addMasterData/add_client?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: "Client created successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header /> {/* Render a Header component */}
      <form>
        {/* Create a form element with a submission handler */}
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          {/* Create a top bar with buttons */}
          <p className="float-left py-1 px-2 rounded my-2">Create Client</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmitNext(e);
            }}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            onClick={() => {
              navigate("/client");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>
        <div className="container-from-data bg-white dark-bg-slate-950 text-black dark-text-white">
          {/* Create a container for form data */}
          <div className="title">Client Information</div> {/* Add a title */}
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-client">
                
                <div className="input-box">
                  <span className="ClientName"style={{ textAlign: 'left', display: 'block' }}>ClientName</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="ClientName"
                    value={formData.ClientName}
                    onChange={handleChange}
                    placeholder="Enter the ClientName"
                  />{" "}
                  </div>
                  <div className="input-box">
  <span className="Clienttype"style={{ textAlign: 'left', display: 'block' }}>Client Type</span>{" "}
  {/* Label for Description input */}
  <select
    className="text-black bg-white dark-bg-gray-950 dark-text-white"
    name="Clienttype"
    value={formData.Clienttype}
    onChange={handleChange}
  >
    <option value="" disabled>
      Select Client Type
    </option>
    <option value="CHA/Shipper/Consignee / Notify Party / Carrier / Airliner/ MLO/Freight Forwarder / Warehouse/Transporter / Others">CHA/Shipper/Consignee / Notify Party / Carrier / Airliner/ MLO/Freight Forwarder / Warehouse/Transporter / Others</option>
    <option value="Agent/Principal / Counter Part">Agent/Principal / Counter Part</option>
    {/* Add more options as needed */}
  </select>{" "}
</div>
<div className="input-box">
                  <span className="SPECIALIZATION"style={{ textAlign: 'left', display: 'block' }}>SPECIALIZATION</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="SPECIALIZATION"
                    value={formData.SPECIALIZATION}
                    onChange={handleChange}
                    placeholder="Enter the SPECIALIZATION"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box"></div>
                  <div className="input-box">
                  <span className="Addressline1"style={{ textAlign: 'left', display: 'block' }}>Address line 1</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Addressline1"
                    value={formData.Addressline1}
                    onChange={handleChange}
                    placeholder="Enter the Addressline1"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="Addressline2"style={{ textAlign: 'left', display: 'block' }}>Address line 2</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Addressline2"
                    value={formData.Addressline2}
                    onChange={handleChange}
                    placeholder="Enter the Addressline2"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="COUNTRY"style={{ textAlign: 'left', display: 'block' }}>COUNTRY</span>{" "}
                  {/* Label for Description input */}
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="COUNTRY"
                    value={formData.COUNTRY}
                    onChange={handleChange}
                    placeholder="Enter the COUNTRY"
                    align-content="center"
                  />{" "} */}
                   <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="COUNTRY"
                    value={formData.COUNTRY}
                    onChange={(e) => {
                      handleCountryChange(e);
                      handleChange(e);
                    }}
                  >
                    <option>Select Country</option>
                    {countryData.map((item) => (
                      <option value={item.Country_name}>
                        {item.Country_name}
                      </option>
                    ))}
                  </select>
                  </div>
                  <div className="input-box">
                  <span className="State"style={{ textAlign: 'left', display: 'block' }}>State</span>{" "}
                  {/* Label for Description input */}
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    placeholder="Enter the State"
                    align-content="center"
                  />{" "} */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="State"
                    value={formData.State}
                    onChange={(e) => {
                      handleStateChange(e);
                      handleChange(e);
                    }}
                  >
                    <option>Select State</option>
                    {stateData.map((item) => (
                      <option value={item.State_name}>
                        {item.State_name}
                      </option>
                    ))}
                  </select>
                  </div>
                  <div className="input-box">
                  <span className="CITY"style={{ textAlign: 'left', display: 'block' }}>CITY</span>{" "}
                  {/* Label for Description input */}
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="CITY"
                    value={formData.CITY}
                    onChange={handleChange}
                    placeholder="Enter the CITY"
                    align-content="center"
                  />{" "} */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="CITY"
                    value={formData.CITY}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option>Select CITY</option>
                    {cityData.map((item) => (
                      <option value={item.City_name}>
                        {item.City_name}
                      </option>
                    ))}
                  </select>
                  </div>
                  
                  <div className="input-box">
                  <span className="PostalCode"style={{ textAlign: 'left', display: 'block' }}>Postal Code</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="PostalCode"
                    value={formData.PostalCode}
                    onChange={handleChange}
                    placeholder="Enter the PostalCode"
                    align-content="center"
                  />{" "}
                  </div>
                  
                  <div className="input-box">
                  <span className="TaxNumber"style={{ textAlign: 'left', display: 'block' }}>Tax Number</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="TaxNumber"
                    value={formData.TaxNumber}
                    onChange={handleChange}
                    placeholder="Enter the TaxNumber"
                    align-content="center"
                  />{" "} 
                  </div>
                  <div className="input-box">
                  <span className="Industry"style={{ textAlign: 'left', display: 'block' }}>Industry</span>{" "}
                  {/* Label for Description input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Industry"
                    value={formData.Industry}
                    onChange={handleChange}
                    placeholder="Enter your Industry"
                  >
                    <option>Select Industry</option>
                    {industryData.map((item) => (
                      <option value={item.Industry}>{item.Industry}</option>
                    ))}
                  </select>
                  </div>
                  <div className="input-box">
                  <span className="Remarks"style={{ textAlign: 'left', display: 'block' }}>Remarks</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleChange}
                    placeholder="Enter the Remarks"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="Grade"style={{ textAlign: 'left', display: 'block' }}>Grade</span>{" "}
                  {/* Label for Description input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Grade"
                    value={formData.Grade}
                    onChange={handleChange}
                    placeholder="Enter your Grade"
                  >
                    <option>Select Tier</option>
                    {tierData.map((item) => (
                      <option value={item.Tier}>{item.Tier}</option>
                    ))}
                  
                    </select>
                  </div>
                  <div className="input-box">
                  <span className="Tag"style={{ textAlign: 'left', display: 'block' }}>Tag</span>{" "}
                  {/* Label for Description input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Tag"
                    value={formData.Tag}
                    onChange={handleChange}
                    placeholder="Enter your Tags"
                  >
                    <option>Select Tags</option>
                    {tagData.map((item) => (
                      <option value={item.Tags}>{item.Tags}</option>
                    ))}
                    </select>
                  </div>
                  <div className="input-box">
                  <span className="GSTInfo"style={{ textAlign: 'left', display: 'block' }}>GSTInfo</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="GSTInfo"
                    value={formData.GSTInfo}
                    onChange={handleChange}
                    placeholder="Enter the GSTInfo"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="PAN"style={{ textAlign: 'left', display: 'block' }}>PAN</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="PAN"
                    value={formData.PAN}
                    onChange={handleChange}
                    placeholder="Enter the PAN"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="SST"style={{ textAlign: 'left', display: 'block' }}>SST</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="SST"
                    value={formData.SST}
                    onChange={handleChange}
                    placeholder="Enter the SST"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="TDS"style={{ textAlign: 'left', display: 'block' }}>TDS</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="TDS"
                    value={formData.TDS}
                    onChange={handleChange}
                    placeholder="Enter the TDS"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="input-box">
                  <span className="FAX"style={{ textAlign: 'left', display: 'block' }}>FAX</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="FAX"
                    value={formData.FAX}
                    onChange={handleChange}
                    placeholder="Enter the FAX"
                    align-content="center"
                  />{" "}
                  </div>
                  <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
  <thead className="flex-nowrap text-center bg-blue-950 text-white">
    <tr>
      <th className="border border-slate-300 whitespace-nowrap text-center">
        SI.no
      </th>
      <th className="border border-slate-300 whitespace-nowrap text-center">
        Email
      </th>
      <th className="border border-slate-300 whitespace-nowrap text-center">
        Contact No
      </th>
      <th className="border border-slate-300 whitespace-nowrap text-center">
        Designation
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200 dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC1"
          placeholder=""
          value={formData.PIC1}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC1_email"
          placeholder=""
          value={formData.PIC1_email}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC1_contactno"
          placeholder=""
          value={formData.PIC1_contactno}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC1_designation"
          placeholder=""
          value={formData.PIC1_designation}
          onChange={handleChange}
        />
      </td>
    </tr>
    <tr>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC2"
          placeholder=""
          value={formData.PIC2}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC2_email"
          placeholder=""
          value={formData.PIC2_email}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC2_contactno"
          placeholder=""
          value={formData.PIC2_contactno}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC2_designation"
          placeholder=""
          value={formData.PIC2_designation}
          onChange={handleChange}
        />
      </td>
    </tr>
    <tr>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC3"
          placeholder=""
          value={formData.PIC3}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC3_email"
          placeholder=""
          value={formData.PIC3_email}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC3_contactno"
          placeholder=""
          value={formData.PIC3_contactno}
          onChange={handleChange}
        />
      </td>
      <td className="border border-slate-300 text-center">
        <input
          className="text-black bg-slate-200  dark-bg-gray-950 dark-text-white w-full"
          type="text"
          name="PIC3_designation"
          placeholder=""
          value={formData.PIC3_designation}
          onChange={handleChange}
        />
      </td>
    </tr>
    {/* Add more rows as needed */}
  </tbody>
</table>


                  {/* Input field for Description */}
                </div>
              </div>
            </div>
            </div>
          </div>
      </form>
    </>
  );
};

export default Addclient;
