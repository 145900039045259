import { React, useEffect, useState } from "react";
import { OperationDocumentAPIInstance } from "../../../api-instance.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useToast, Tooltip, Badge } from "@chakra-ui/react";
import { FiArrowRightCircle } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { MdSelectAll } from "react-icons/md";
import SelectAllPages from "./Selectallpages";
import {
  DraggableActivelogTable,
  Header,
  OperationSidebar,
} from "../../../components";

const Activitylog = () => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("Activity selectedJob:", selectedJob);
  // State Variables
  const navigate = useNavigate();
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const toast = useToast();
  const [operationActivitylogData, setOperationActivitylogData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [columns, setColumns] = useState({
    id: true,
    BookingNumber: true,
    fieldName: false,
    Date: true,
    User: true,
    Description: true,
    TransferredFrom: true,
    ToTransferred: true,
  });
  const [
    tableColumnsOperationActivitylog,
    settableColumnsOperationActivitylog,
  ] = useState([]);

  useEffect(() => {
    getData();
  }, []); // isdelete state changes

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsOperationActivitylog((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsOperationActivitylog.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem(
      "tableColumnsOperationActivitylog",
      JSON.stringify(newData)
    );
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await OperationDocumentAPIInstance.get(
        `get_activity_logs?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&JobNumber=${selectedJob?.JobNumber}&BookingNumber=${selectedJob?.BookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setOperationActivitylogData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setOperationActivitylogData(filteredData);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(
    operationActivitylogData?.length / itemsPerPage
  );

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  useEffect(() => {
    if (localStorage.getItem("tableColumnsOperationActivitylog")) {
      settableColumnsOperationActivitylog(
        JSON.parse(localStorage.getItem("tableColumnsOperationActivitylog"))
      );
    } else {
      settableColumnsOperationActivitylog(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);
  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsOperationActivitylog];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsOperationActivitylog",
      JSON.stringify(updatedColumns)
    );
    settableColumnsOperationActivitylog(updatedColumns);
  };
  return (
    <>
      <Header />

      <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
        <p className="float-left">
          <OperationSidebar
            size={20}
            style={{ cursor: "pointer" }}
            selectedJob={selectedJob}
          />
        </p>
        <p className="float-left ml-5 mt-3 text-900">
          Activity Log Information
        </p>
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>

          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              {/* <MdSelectAll
                onClick={() => {
                  handleCheckbox(operationActivitylogData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              /> */}
            </div>
          </Tooltip>
          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, operationActivitylogData?.length)}
              </span>{" "}
              of{" "}
              <span className="font-medium">
                {operationActivitylogData?.length}
              </span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <SelectAllPages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
            <a>Title :</a>
            {selectedJob?.JobNumber}/ {selectedJob?.BookingNumber}
          </Badge>
          <DraggableActivelogTable
            isLoading={isLoading}
            columns={columns2}
            operationActivitylogData={operationActivitylogData}
            getData={getData}
            data={operationActivitylogData.slice(
              indexOfFirstItem,
              indexOfLastItem
            )}
            setOperationActivitylogData={setOperationActivitylogData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsOperationActivitylog={tableColumnsOperationActivitylog}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>
      </div>
    </>
  );
};

export default Activitylog;
