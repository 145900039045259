import { React, useRef, useEffect, useState } from "react";
import { OperationDocumentAPIInstance } from "../../../api-instance.js";
import {
  DraggableDocumentTable,
  Header,
  OperationSidebar,
} from "../../../components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  useToast,
  Tooltip,
  useDisclosure,
  FormControl,
  Button,
  Badge,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { FiPrinter } from "react-icons/fi";
import { MdOutlinePreview } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FiArrowRightCircle } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { MdSelectAll } from "react-icons/md";
import SelectAllPages from "./Selectallpages";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { saveAs } from "file-saver";
const Documents = () => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("selectedJob Booking Number:", selectedJob);
  // State Variables
  const cancelRef = useRef();
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [isActionOpen, setActionOpen] = useState(false);
  const [isAddDocumentsOpen, setAddDocumentsOpen] = useState(false);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const toast = useToast();
  const [documentData, setDocumentData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [jobNumber, setJobNumber] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [docName, setDocName] = useState("");
  const [urlObj, setUrlObj] = useState("");
  const dropdownRef = useRef(null);
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();

  // Droppdown Action

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
      setAddDocumentsOpen(false);
    }
  };
  
  useEffect(() => {
    if (isActionOpen || isAddDocumentsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionOpen, isAddDocumentsOpen]);
  
  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);

    toggleActionDropdown();
  };
  const handleAaddDocumentSelection = () => {
    setAddDocumentsOpen(false);
    toggleAddDocumentDropdown();
  };
  const toggleAddDocumentDropdown = () => {
    setAddDocumentsOpen(!isAddDocumentsOpen);
  };

  const [columns, setColumns] = useState({
    id: true,
    JobNumber: true,
    BookingNumber: true,
    JobInfono: true,
    DOCID: true,
    Doc_Name: true,
    ChangeDate: false,
    DOC_type: false,
    size: false,
    URL_Object: true,

    Createdby: true,
  });
  const [tableColumnsDocument, settableColumnsDocument] = useState([]);

  useEffect(() => {
    getData();
  }, []); // isdelete state changes

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsDocument((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsDocument.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsDocument", JSON.stringify(newData));
  };
  const handleCheckbox = (
    id,
    JobNumber,
    BookingNumber,
    Doc_Name,
    URL_Object
  ) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
    setJobNumber(JobNumber);
    setBookingNumber(BookingNumber);
    setDocName(Doc_Name);
    setUrlObj(URL_Object);
  };

  const handlePrint = async () => {
    console.log(jobNumber, bookingNumber, docName, urlObj);
    if (docName === "Shipping Instruction") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const SIDataResponse = await OperationDocumentAPIInstance.get(
          `get_sihbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SIData = SIDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (SIData && SIData.Containers && SIData.Containers !== "") {
          const operations = SIData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          SIData.Operations = operations;
        }
        console.log(SIData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `sipdf`,
          {
            SIData, // Pass SIData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_hbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HBLData = HBLDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (HBLData && HBLData.Containers && HBLData.Containers !== "") {
          const operations = HBLData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          HBLData.Operations = operations;
        }
        console.log(HBLData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `hblpdf`,
          {
            HBLData, // Pass HBLData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Switch House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const SHBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_shbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SHBLData = SHBLDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (SHBLData && SHBLData.Containers && SHBLData.Containers !== "") {
          const operations = SHBLData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          SHBLData.Operations = operations;
        }
        console.log(SHBLData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `shblpdf`,
          {
            SHBLData, // Pass SHBLData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Freight Certificate") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const FCDataResponse = await OperationDocumentAPIInstance.get(
          `get_hdofreight?userId=${userId}&BookingNumber=${bookingNumber}&JobNumber=${jobNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const FCData = FCDataResponse.data.data[0];

        console.log(FCData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `freightpdf`,
          {
            FCData, // Pass FCData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching fc creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "CFS Nomination") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const CFSDataResponse = await OperationDocumentAPIInstance.get(
          `get_cfsdata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const CFSData = CFSDataResponse.data.data[0];

        console.log(CFSData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `cfspdf`,
          {
            CFSData, // Pass CFSData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching cfs creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Do") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HDODataResponse = await OperationDocumentAPIInstance.get(
          `get_hdodata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HDOData = HDODataResponse.data.data[0];

        console.log(HDOData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `hbopdf`,
          {
            HDOData, // Pass HDOData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching HDO creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Notice Of Arrvial") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const NOADataResponse = await OperationDocumentAPIInstance.get(
          `get_noadata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const NOAData = NOADataResponse.data.data[0];

        if (NOAData && NOAData.Containers && NOAData.Containers !== "") {
          const operations = JSON.parse(NOAData.Containers).map((container) => {
            const containerObj = {};

            // Iterate over each key-value pair in the container object
            for (const [key, value] of Object.entries(container)) {
              // If the value is an empty string or null, set it to null
              containerObj[key] = value === "" ? null : value;
            }

            return containerObj;
          });

          NOAData.Operations = operations;
        }

        console.log(NOAData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `noapdf`,
          {
            NOAData, // Pass NOAData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        )
          .then((response) => {
            // Create a Blob from the binary data
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfUrl, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else {
      window.open(urlObj, "_blank");
      return null;
    }
  };
  const handleDownload = async () => {
    console.log(jobNumber, bookingNumber, docName, urlObj);
    if (docName === "Shipping Instruction") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));
        console.log(bookingNumber);
        // First, fetch SI/HBL data
        const SIDataResponse = await OperationDocumentAPIInstance.get(
          `get_sihbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SIData = SIDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (SIData && SIData.Containers && SIData.Containers !== "") {
          const operations = SIData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          SIData.Operations = operations;
        }
        console.log(SIData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `sipdf`,
          {
            SIData, // Pass SIData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        const currentDate = new Date()
          .toISOString()
          .replace(/:/g, "-")
          .replace(/\..+/, ""); // Format: yyyy-mm-ddThh-mm-ss
        const fileName = `SI_${currentDate}.pdf`;

        // Save the PDF file using FileSaver with the dynamically generated filename
        saveAs(new Blob([pdfDataResponse.data]), fileName);
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_hbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HBLData = HBLDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (HBLData && HBLData.Containers && HBLData.Containers !== "") {
          const operations = HBLData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          HBLData.Operations = operations;
        }
        console.log(HBLData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `hblpdf`,
          {
            HBLData, // Pass HBLData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "HBL.pdf");
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Switch House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const SHBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_shbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SHBLData = SHBLDataResponse.data.data[0];

        // Process the received SI/HBL data
        if (SHBLData && SHBLData.Containers && SHBLData.Containers !== "") {
          const operations = SHBLData.Containers.split(";").map((container) => {
            const containerObj = {};
            container.split(",").forEach((attribute) => {
              const [key, value] = attribute
                .trim()
                .split(":")
                .map((item) => item.trim());
              containerObj[key] = value === "" ? null : value;
            });
            return containerObj;
          });
          SHBLData.Operations = operations;
        }
        console.log(SHBLData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `shblpdf`,
          {
            SHBLData, // Pass SHBLData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "SwitchHBL.pdf");
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Freight Certificate") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const FCDataResponse = await OperationDocumentAPIInstance.get(
          `get_hdofreight?userId=${userId}&BookingNumber=${bookingNumber}&JobNumber=${jobNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const FCData = FCDataResponse.data.data[0];

        console.log(FCData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `freightpdf`,
          {
            FCData, // Pass FCData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "FreightCertificate.pdf");
      } catch (error) {
        console.error("Error fetching fc creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "CFS Nomination") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const CFSDataResponse = await OperationDocumentAPIInstance.get(
          `get_cfsdata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const CFSData = CFSDataResponse.data.data[0];

        console.log(CFSData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `cfspdf`,
          {
            CFSData, // Pass CFSData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "CFSNomination.pdf");
      } catch (error) {
        console.error("Error fetching cfs creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Do") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HDODataResponse = await OperationDocumentAPIInstance.get(
          `get_hdodata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HDOData = HDODataResponse.data.data[0];

        console.log(HDOData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `hbopdf`,
          {
            HDOData, // Pass HDOData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "HDO.pdf");
      } catch (error) {
        console.error("Error fetching HDO creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Notice Of Arrvial") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const NOADataResponse = await OperationDocumentAPIInstance.get(
          `get_noadata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const NOAData = NOADataResponse.data.data[0];

        if (NOAData && NOAData.Containers && NOAData.Containers !== "") {
          const operations = JSON.parse(NOAData.Containers).map((container) => {
            const containerObj = {};

            // Iterate over each key-value pair in the container object
            for (const [key, value] of Object.entries(container)) {
              // If the value is an empty string or null, set it to null
              containerObj[key] = value === "" ? null : value;
            }

            return containerObj;
          });

          NOAData.Operations = operations;
        }

        console.log(NOAData);
        // Next, fetch PDF data
        const pdfDataResponse = await OperationDocumentAPIInstance.post(
          `noapdf`,
          {
            NOAData, // Pass NOAData directly in the request body
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            responseType: "blob", // Set the response type to 'blob' to receive binary data
          }
        );
        saveAs(new Blob([pdfDataResponse.data]), "NOA.pdf");
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else {
      window.open(urlObj, "_blank");
      return null;
    }
  };

  const handleEditClick = async () => {
    console.log(jobNumber, bookingNumber, docName);
    if (docName === "Shipping Instruction") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const SIDataResponse = await OperationDocumentAPIInstance.get(
          `get_sihbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SIData = SIDataResponse.data.data[0];
        console.log(SIData);
        navigate("/edit_sicreation", { state: { selectedJob: SIData } });
      } catch (error) {
        console.error("Error fetching SI creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_hbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HBLData = HBLDataResponse.data.data[0];
        navigate("/edit_hblcreation", { state: { selectedJob: HBLData } });
      } catch (error) {
        console.error("Error fetching HBL creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Switch House Bill") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const SHBLDataResponse = await OperationDocumentAPIInstance.get(
          `get_shbldata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const SHBLData = SHBLDataResponse.data.data[0];
        navigate("/edit_shblcreation", { state: { selectedJob: SHBLData } });
      } catch (error) {
        console.error("Error fetching SHBL creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Freight Certificate") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const FCDataResponse = await OperationDocumentAPIInstance.get(
          `get_hdofreight?userId=${userId}&BookingNumber=${bookingNumber}&JobNumber=${jobNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const FCData = FCDataResponse.data.data[0];
        navigate("/edit_fc", { state: { selectedJob: FCData } });
      } catch (error) {
        console.error("Error fetching fc creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "CFS Nomination") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const CFSDataResponse = await OperationDocumentAPIInstance.get(
          `get_cfsdata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const CFSData = CFSDataResponse.data.data[0];
        navigate("/edit_cfs", { state: { selectedJob: CFSData } });
      } catch (error) {
        console.error("Error fetching cfs creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "House Do") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const HDODataResponse = await OperationDocumentAPIInstance.get(
          `get_hdodata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const HDOData = HDODataResponse.data.data[0];
        navigate("/edit_hdo", { state: { selectedJob: HDOData } });
      } catch (error) {
        console.error("Error fetching HDO creation data:", error);
        // Handle error if necessary
      }
    } else if (docName === "Notice Of Arrvial") {
      console.log("yessss");
      try {
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));

        // First, fetch SI/HBL data
        const NOADataResponse = await OperationDocumentAPIInstance.get(
          `get_noadata?userId=${userId}&BookingNumber=${bookingNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const NOAData = NOADataResponse.data.data[0];
        console.log("sssss", NOAData);

        navigate("/edit_noa", { state: { selectedJob: NOAData } });
      } catch (error) {
        console.error("Error fetching NOA creation data:", error);
        // Handle error if necessary
      }
    } else {
      toast({
        title: "This cannot be edited",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await OperationDocumentAPIInstance.get(
        `get_document?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        } &BookingNumber=${selectedJob?.BookingNumber}&JobNumber=${
          selectedJob?.JobNumber
        }`,
        //&JobNumber=${jobNumber}

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setDocumentData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setDocumentData(filteredData);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitDelete = async () => {
    try {
      const { data } = await OperationDocumentAPIInstance.post(
        `delete_document?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          id: selectAllPages
            ? documentData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: `Document Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
        setCheckBoxSelected([]);
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setCheckBoxSelected([]);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
      setCheckBoxSelected([]);
    }

    if (selectAllPages) {
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setCheckBoxSelected([]);
      return;
    }
    getData();
  };

  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(documentData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  useEffect(() => {
    if (localStorage.getItem("tableColumnsDocument")) {
      settableColumnsDocument(
        JSON.parse(localStorage.getItem("tableColumnsDocument"))
      );
    } else {
      settableColumnsDocument(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);
  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsDocument];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsDocument",
      JSON.stringify(updatedColumns)
    );
    settableColumnsDocument(updatedColumns);
  };
  
  const handleCheckSi = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
  
      // Fetch SI/HBL data
      const checkSiExist = await OperationDocumentAPIInstance.get(
        `check_si?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkSiExist.data.success) {
        navigate("/add_sicreation", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkSiExist?.data?.message || "Error checking SI creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }
    } catch (error) {
      console.error("Error checking SI creation:", error);
      toast({
        title: "Error checking SI creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };
  
  const handleCheckHbl = async (jobNumber, bookingNumber) => {
    console.log("Handling HBL check");
  
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
  
      // Fetch SI data using the selectedJob's BookingNumber
      const { data: siData } = await OperationDocumentAPIInstance.get(
        `get_sihbldata?userId=${user.userId}&BookingNumber=${selectedJob?.BookingNumber}`
      );
  console.log("asaa",siData)
      // Check if SI data is missing or invalid
      if (siData.data.length === 0 || siData.data[0].JobNumber === null || siData.data[0].BookingNumber === null) {
        toast({
          title: "No SI data found.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return; // Prevent further execution if SI data is missing
      }
  else{
      // Check if the HBL exists for the given job and booking numbers
      const checkHblExist = await OperationDocumentAPIInstance.get(
        `check_hbl?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response for HBL existence check
      if (checkHblExist.data.success) {
        navigate("/add_hblcreation", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit after navigating to avoid further code execution
      } else {
        toast({
          title: checkHblExist?.data?.message || "Error checking HBL creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }
    }
    } catch (error) {
      console.error("Error checking HBL creation:", error);
      toast({
        title: "Error checking HBL creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };
  

  const handleCheckShbl = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
      const { data: siData } = await OperationDocumentAPIInstance.get(
        `get_sihbldata?userId=${user.userId}&BookingNumber=${selectedJob?.BookingNumber}`
      );
  console.log("asaa",siData)
      // Check if SI data is missing or invalid
      if (siData.data.length === 0 || siData.data[0].JobNumber === null || siData.data[0].BookingNumber === null) {
        toast({
          title: "No SI data found.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return; // Prevent further execution if SI data is missing
      }
  else{
      // Fetch SI/HBL data
      const checkShblExist = await OperationDocumentAPIInstance.get(
        `check_shbl?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkShblExist.data.success) {
        navigate("/add_switchhbl", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkShblExist?.data?.message || "Error checking SHBL creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }}
    } catch (error) {
      console.error("Error checking HBL creation:", error);
      toast({
        title: "Error checking SHBL creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };

  const handleCheckHdo = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
  
      const { data: fcData } = await OperationDocumentAPIInstance.get(
        `get_hdofreight?BookingNumber=${selectedJob.BookingNumber}&JobNumber=${selectedJob.JobNumber}`
      );
  console.log("asaa",fcData)
      // Check if SI data is missing or invalid
      if (fcData.data.length === 0 || fcData.data[0].JobNumber === null || fcData.data[0].BookingNumber === null) {
        toast({
          title: "No Freight certification data found.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return; // Prevent further execution if SI data is missing
      }
    else{  // Fetch SI/HBL data
    const checkHdoExist = await OperationDocumentAPIInstance.get(
        `check_hdo?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkHdoExist.data.success) {
        navigate("/add_hdocreation", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkHdoExist?.data?.message || "Error checking HDO creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }}
    } catch (error) {
      console.error("Error checking HDO creation:", error);
      toast({
        title: "Error checking HDO creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };

  const handleCheckCfs = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
      const { data: fcData } = await OperationDocumentAPIInstance.get(
        `get_hdofreight?BookingNumber=${selectedJob.BookingNumber}&JobNumber=${selectedJob.JobNumber}`
      );
  console.log("asaa",fcData)
      // Check if SI data is missing or invalid
      if (fcData.data.length === 0 || fcData.data[0].JobNumber === null || fcData.data[0].BookingNumber === null) {
        toast({
          title: "No Freight certification data found.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return; // Prevent further execution if SI data is missing
      }
    else{ 
      // Fetch SI/HBL data
   const checkCfsExist = await OperationDocumentAPIInstance.get(
        `check_cfs?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkCfsExist.data.success) {
        navigate("/add_cfscreation", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkCfsExist?.data?.message || "Error checking CFS creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }}
    } catch (error) {
      console.error("Error checking CFS creation:", error);
      toast({
        title: "Error checking CFS creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };
  
  const handleCheckFc = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
  
      // Fetch SI/HBL data
    const checkFcExist = await OperationDocumentAPIInstance.get(
        `check_fc?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkFcExist.data.success) {
        navigate("/freight_certification", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkFcExist?.data?.message || "Error checking FC creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }
    } catch (error) {
      console.error("Error checking HBL creation:", error);
      toast({
        title: "Error checking FC creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };

  const handleCheckNoa = async (jobNumber, bookingNumber) => {
    try {
      // Check if user data and token are available in localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));
  
      // Fetch SI/HBL data
     const checkNoaExist = await OperationDocumentAPIInstance.get(
        `check_noa?userId=${user.userId}&JobNumber=${jobNumber}&BookingNumber=${bookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      // Handle the response
      if (checkNoaExist.data.success) {
        navigate("/add_noacreation", {
          state: { selectedJob: selectedJob },
        });
        return; // Exit the function to prevent further navigation
      } else {
        toast({
          title: checkNoaExist?.data?.message || "Error checking SHBL creation",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
      }
    } catch (error) {
      console.error("Error checking HBL creation:", error);
      toast({
        title: "Error checking NOA creation",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    }
  };

  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "199",
  };

  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenReportDelete,
    handlePrint,
    handleEditClick,
    handleDownload
    // handleOpenEditor,
    // onOpenReportOutlookEmail,
  );
  return (
    <>
      <Header />
      <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
        <p className="float-left">
          <OperationSidebar
            size={20}
            style={{ cursor: "pointer" }}
            selectedJob={selectedJob}
          />
        </p>
        <p className="float-left ml-5 mt-3 text-900">Document Information</p>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white  dark:bg-blue-950 mr-5 py-1 px-2 rounded my-2 float-right"
          onClick={toggleActionDropdown}
          type="submit"
        >
          Actions
        </button>
        &nbsp;&nbsp;
        {isActionOpen && (
          <div 
          ref = {dropdownRef}
          className="absolute right-20 mt-6 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FiEdit size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleActionSelection();
                  checkValidation.handleEditClickupdate();
                }}
              >
                Edit
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FiPrinter size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleActionSelection();
                  checkValidation.handlePrintClick();
                }}
              >
                View
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <RiDeleteBinLine size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleActionSelection();
                  checkValidation.handleEditClickMassdelete();
                }}
              >
                Delete
              </span>
            </a>
            {/* <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                            <HiOutlineMail size={20} />
                            <span
                                className="flex-grow cursor-pointer"
                                onClick={() => {
                                    handleActionSelection();
                                }}
                            >
                                Email
                            </span>
                        </a>
                        <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                            <MdOutlinePreview size={20} />
                            <span
                                className="flex-grow cursor-pointer"
                                onClick={() => {
                                    handleActionSelection();
                                }}
                            >
                                View
                            </span>
                        </a>*/}
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <MdOutlineDownloadForOffline size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleActionSelection();
                  checkValidation.handleDownloadClick();
                }}
              >
                Download
              </span>
            </a>
          </div>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white  dark:bg-blue-950 mr-5 py-1 px-2 rounded my-2 float-right"
          onClick={toggleAddDocumentDropdown}
          type="button"
        >
          Add Documents
        </button>
        &nbsp;&nbsp;
        {isAddDocumentsOpen && (
          <div
          ref = {dropdownRef}
          className="absolute right-0 mt-6 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileUpload size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  //console.log(jobNumber,bookingNumber)
                  handleCheckSi(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  navigate("/add_sicreation", {
                    state: { selectedJob: selectedJob },
                  });
                }}
              >
                SI Creation
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileUpload size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckHbl(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  // navigate("/add_hblcreation", {
                  //   state: { selectedJob: selectedJob },
                  // });
                }}
              >
                HBL Creation
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileUpload size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckShbl(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  // navigate("/add_switchhbl", {
                  //   state: { selectedJob: selectedJob },
                  // });
                }}
              >
                Switch HBL Creation
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileAlt size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckNoa(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  navigate("/add_noacreation", {
                    state: { selectedJob: selectedJob },
                  });
                }}
              >
                Notice Of Arrival
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileAlt size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckFc(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  navigate("/freight_certification", {
                    state: { selectedJob: selectedJob },
                  });
                }}
              >
                Freight Certificate
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileAlt size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckHdo(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  // navigate("/add_hdocreation", {
                  //   state: { selectedJob: selectedJob },
                  // });
                }}
              >
                HDO
              </span>
            </a>
            <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
              <FaFileAlt size={20} />
              <span
                className="flex-grow cursor-pointer"
                onClick={() => {
                  handleAaddDocumentSelection();
                  handleCheckCfs(
                    selectedJob.JobNumber,
                    selectedJob.BookingNumber
                  );
                  // navigate("/add_cfscreation", {
                  //   state: { selectedJob: selectedJob },
                  // });
                }}
              >
                CFS Nomination
              </span>
            </a>
          </div>
        )}
        &nbsp;
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white  dark:bg-blue-950 mr-5 py-1 px-2 rounded my-2 float-right"
          onClick={() => {
            handleAaddDocumentSelection();
            navigate("/upload_document", {
              state: { selectedJob: selectedJob },
            });
          }}
          type="button"
        >
          Upload Documents
        </button>
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>

          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(documentData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>
          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, documentData?.length)}
              </span>{" "}
              of <span className="font-medium">{documentData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <SelectAllPages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
            <a>Title :</a>
            {selectedJob?.JobNumber}/ {selectedJob?.BookingNumber}
          </Badge>
          <DraggableDocumentTable
            isLoading={isLoading}
            columns={columns2}
            documentData={documentData}
            getData={getData}
            data={documentData.slice(indexOfFirstItem, indexOfLastItem)}
            setDocumentData={setDocumentData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsDocument={tableColumnsDocument}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>
      </div>

      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Document
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to delete the document?
              </FormControl>
              <br />
              <>
                <Button colorScheme="green" onClick={handleSubmitDelete}>
                  Delete
                </Button>
                <Button
                  ref={cancelRef}
                  onClick={onCloseReportDelete}
                  style={{ background: "red", color: "white" }}
                  ml={3}
                >
                  Cancel
                </Button>
              </>
            </Rnd>
          </div>
          </>
        )}
      </>
    </>
  );
};

export default Documents;
