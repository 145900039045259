import { BsThreeDotsVertical } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Table } from "../../components";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks";
import Axios, { DashboardAPIInstance } from "../../api-instance";
// import { BiMinus } from "react-icons/bi";
// import { MdAdd } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { getConversionRate } from "./helper";
import { Card } from "./Card";

export default function LeadOwnerAnalytics() {
  const [columns, setColumns] = useLocalStorage(
    "DASHBOARD_LEAD_OWNER_ANALYTICS_TABLE_COLUMNS",
    []
  );

  const itemsPerPage = 5;

  const [showContent, setShowContent] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [showContentIcon, setShowContentIcon] = useState(<IoIosArrowUp />);

  // Toggle the content and change the icon when the button is clicked
  const toggleContent = () => {
    setShowContent((prev) => !prev);
    setShowContentIcon((prevIcon) =>
      prevIcon.type === IoIosArrowUp ? <IoIosArrowDown /> : <IoIosArrowUp />
    );
  };

  const leadOwnerAnalyticsQuery = useQuery({
    queryKey: ["dashboard-lead-owne-analytic"],
    queryFn: () =>
      DashboardAPIInstance.get(
        `leadsOwnerAnalytics?userId=${
          JSON.parse(localStorage.getItem("user"))?.userId
        }`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      ),
  });

  const data = leadOwnerAnalyticsQuery?.data?.data?.result || [];

  useEffect(() => {
    if (!data.length) return;
    initialColumnSetup(getConversionRate(data));
    paginateArray(data, currentPage);
  }, [data]);

  function initialColumnSetup(data) {
    const columns = Object.keys(data[0]).map((key) => ({
      name: key,
      visible: true,
    }));
    setColumns(columns);
  }

  function paginateArray(array, currentPage) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = array.slice(startIndex, endIndex);
    const totalPage = Math.ceil(array.length / itemsPerPage);

    setCurrentPage(currentPage);
    setFiltered(getConversionRate(paginatedData));
    setTotalPage(totalPage);
  }

  function prevPage() {
    if (currentPage === 1) return;
    paginateArray(data, currentPage - 1);
  }

  function nextPage() {
    if (currentPage === totalPage) return;
    paginateArray(data, currentPage + 1);
  }

  return (
    <Card className="flex flex-col gap-4">
      <div className="flex justify-between">
        <span className="flex items-center gap-6">
          <span className="text-medium font-semibold">
            Lead Owners Analytics
          </span>
          <span
            onClick={toggleContent}
            className="flex-center w-6 h-6 p-1 rounded-full active:bg-gray-light cursor-pointer"
          >
            {showContentIcon}
          </span>
        </span>
        <span className="flex items-center gap-6">
          {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
          <div className="text-black-shady text-normal">
            Page {currentPage} of {totalPage}
          </div>
          <div className="flex gap-2">
            <MdKeyboardArrowLeft
              onClick={prevPage}
              data-end={true}
              className="text-2xl text-black-shady rounded-md cursor-pointer select-none border border-gray-light data-[end=true]:text-gray-light"
            />
            <MdKeyboardArrowRight
              onClick={nextPage}
              data-end={true}
              className="text-2xl text-black-shady rounded-md cursor-pointer select-none border border-gray-light data-[end=true]:text-gray-light"
            />
          </div>
        </span>
      </div>
      <div
        data-hide={!showContent}
        className="max-h-max overflow-hidden transition-[max-height] duration-500 data-[hide=true]:max-h-0"
      >
        <Table
          {...{
            columns,
            data: filtered,
            setData: setFiltered,
            enableGrapScroll: true,
            hideEyeIcon: true,
          }}
        />
      </div>
    </Card>
  );
}
