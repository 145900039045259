import React from "react";
import { useState, useEffect } from "react";
import Axios from "../../api-instance";
import { LeadAPIInstance, MasterDataAPIInstance } from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment, { utc } from "moment/moment";

// Internal module import
import "./DraggableLeadTable.css";

// Chakara ui modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const DraggableLeadTable = ({
  isLoading,
  columns,
  data,
  leadData,
  getData,
  setLeadData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsLead,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedLead, setSelectedLead] = useState({});
  const [editedLead, setEditedLead] = useState({});
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
  });

  // UseEffects
  useEffect(() => {
    setEditedLead(selectedLead);
  }, [selectedLead]);

  useEffect(() => {
    getCountries(editedLead["Country"]);
  }, [selectedLead, editedLead["Country"]]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");

        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Grade Map
  const [gradeData, setGradeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setGradeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Industry Map
  const [industryData, setIndustryData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_industry");
        setIndustryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Tags Map
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_tag");
        setTagData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Region API
  const [regionData, setRegionData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Lead Source API
  const [leadSourceData, setLeadSourceData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_source");
        setLeadSourceData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };

  const handleChangePhone = (value, countryData) => {
    if (countryData && countryData.dialCode) {
      const dialCodeLength = countryData.dialCode.length;
      const Phone = value.slice(dialCodeLength);

      setFormData((prevFormData) => ({
        ...prevFormData,
        Phone,
        PhoneWithCode: value,
        CountryData: countryData,
      }));
    }
  };
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
  // Initialize flags to check if the value has changed
  let meetingConductedFlag = editedLead.meetingConductedFlag || 0;
  let meetingCanceledFlag = editedLead.meetingCanceledFlag || 0;
  let callConductedFlag = editedLead.callConductedFlag || 0;
  let callCanceledFlag = editedLead.callCanceledFlag || 0;
      // Update editedLead state
      setEditedLead((prev) => ({
        ...prev,
        [name]: value,
      }));
  
      if (name === "Country") {
        // Update selectedLead state with new Country value and reset City to empty string
        setSelectedLead((prev) => ({
          ...prev,
          Country: value,
          City: "",
        }));
  
        // Fetch city data based on the selected Country
        const { data } = await MasterDataAPIInstance.get(
          `get_city?countryData=${value}`
        );
  
        // Update cityData state with fetched city data
        setCityData(data.data);
      } else if (name === "City") {
        // Update selectedLead state with new City value
        setSelectedLead((prev) => ({
          ...prev,
          City: value,
        }));
      }  else if (name === "MeetingStatus") {
        // Update meetingConductedFlag and meetingCanceledFlag based on the selected MeetingStatus
        meetingConductedFlag = value === "Conducted" ? 1 : 0;
        meetingCanceledFlag = value === "Cancelled" ? 1 : 0;
      } else if (name === "CallStatus") {
        // Update callConductedFlag and callCanceledFlag based on the selected CallStatus
        callConductedFlag = value === "Conducted" ? 1 : 0;
        callCanceledFlag = value === "Cancelled" ? 1 : 0;
      }
  
      // Update editedLead state with updated flags
      setEditedLead((prev) => ({
        ...prev,
        meetingConductedFlag,
        meetingCanceledFlag,
        callConductedFlag,
        callCanceledFlag,
      }));
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  

  const getCountries = async (country) => {
    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
console.log("SL:",selectedLead);
  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedLead,
      DialCode: formData?.CountryData
        ? formData.CountryData.dialCode
        : editedLead.DialCode, // Use the updated dial code
      Phone: formData?.Phone ? formData.Phone : editedLead.Phone, // Use the updated phone number
      MeetingId: selectedLead.MeetingId, 
      CallId: selectedLead.CallId, 
    }; 
    console.log("Updated data",updateData);
    try {
      const { data } = await LeadAPIInstance.put(
        `singleupdate_lead?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedLead.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Lead Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedLead state here
        setSelectedLead((prevSelectedLead) => ({
          ...prevSelectedLead,
          ...editedLead,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  //  Function to open the modal and fetch lead data for a particular ID
  const handleOpenModal = async (id, LeadNumber) => {
    try {
      const selectedLeadData = leadData.find((lead) => lead.id === id);
      //const LeadNumber = leadData((lead) => lead.id === id);
      setSelectedLead(selectedLeadData);
      handleFullSizeClick();
      setSelectedRowId(id);

     // Access initial MeetingStatus and CallStatus
     const initialMeetingStatus = selectedLeadData.MeetingStatus;
     const initialCallStatus = selectedLeadData.CallStatus;
      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${selectedLeadData?.Country}`
      );
      console.log("Initial MS:", initialMeetingStatus, "Initial CS:", initialCallStatus);

      // Handle changes only if there is a change in MeetingStatus or CallStatus
      if (initialMeetingStatus !== "Conducted" && initialMeetingStatus !== "Cancelled") {
          // If MeetingStatus is not initially set, set the default flags
          const meetingConductedFlag = 0;
          const meetingCanceledFlag = 0;
          setEditedLead((prev) => ({ ...prev, meetingConductedFlag, meetingCanceledFlag }));
      }

      if (initialCallStatus !== "Conducted" && initialCallStatus !== "Cancelled") {
          // If CallStatus is not initially set, set the default flags
          const callConductedFlag = 0;
          const callCanceledFlag = 0;
          setEditedLead((prev) => ({ ...prev, callConductedFlag, callCanceledFlag }));
      }

      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    console.log(LeadNumber);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...leadData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setLeadData === "function") {
      setLeadData(sortedData);
    }
  };

  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //console.log(citiesDataSet);
  // const [showFactCheck, setShowFactCheck] = useState(false);
  // console.log(checkBoxSelected, currentPage, activePageCheckBoxSelect);
  return (
    <div>
      {!isLoading ? (
        tableColumnsLead?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
                    <button 
                    onClick={toggleIcon}
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    checkBoxAll={checkBoxAll}
                    >
                      {showFactCheck ? (
                        <MdOutlineFactCheck size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}
                    </button> */}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>

                {tableColumnsLead.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode" &&
                    column.label!=="CallId"&&
                    column.label!=="MeetingId"&&
                    column.label!="MeetingTimezone"&&
                    column.label!="MeetingCanceledFlag"&&
                    column.label!="MeetingConductedFlag"&&
                    column.label!="CallConductedFlag"&&
                    column.label!="CallCanceledFlag"&&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.key === "Source" ? (
                          <>
                            <span>Lead Source</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : column.key === "Region" ? (
                          <>
                            <span>Internal Region</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() =>
                            handleOpenModal(row?.id, row?.LeadNumber)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsLead.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode" &&
                        column.label!=="CallId"&&
                    column.label!=="MeetingId"&&
                    column.label!="MeetingTimezone"&&
                    column.label!="MeetingCanceledFlag"&&
                    column.label!="MeetingConductedFlag"&&
                    column.label!="CallConductedFlag"&&
                    column.label!="CallCanceledFlag"&&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "Phone" ? (
                              <>
                                {/* {console.log(row)} */}+{row["DialCode"]}-
                                {row["Phone"]}
                              </>
                            ) : column.key === "FollowUpDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "CreatedAt" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ): column.key === "ChangedAt" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ): column.key === "ConversionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">Converted</Badge>
                              ) : (
                                <Badge colorScheme="red">Not Converted</Badge>
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : column.key === "FollowUpStatus" ? (
                              row["FollowUpStatus"] ? ( // Make sure "FollowUpStatus" is correctly accessible in the row data
                                row["FollowUpStatus"] === "LeadConverted" ? (
                                  <Badge colorScheme="green">
                                    Lead Converted
                                  </Badge>
                                ) : row["FollowUpStatus"] === "LeadDeleted" ? (
                                  <Badge colorScheme="green">
                                    Lead Deleted
                                  </Badge>
                                ) : row["FollowUpStatus"] === "NoAction" ? (
                                  <Badge colorScheme="red">No Action</Badge>
                                ) : row["FollowUpStatus"] === "pending" ? (
                                  <Badge colorScheme="red">Pending</Badge>
                                ) : row["FollowUpStatus"] ===
                                  "SystemGenerated" ? (
                                  <Badge colorScheme="yellow">
                                    System Generated
                                  </Badge>
                                ) : null
                              ) : null
                            ) :  column.key === "MeetingDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ): column.key === "CallDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ):
                            (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsLead.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        {isOpen && (
          <>
          <div style={backdropStyle}></div>
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 500,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Lead Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedLead && Object.keys(selectedLead).length > 0 && (
                <form>
                  <Badge variant="solid" colorScheme="red" fontSize="12pt">
                    Lead Number : {selectedLead["LeadNumber"]}
                  </Badge>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>
                          Lead Owner<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="LeadOwner"
                          defaultValue={
                            formData["LeadOwner"] || selectedLead["LeadOwner"]
                          }
                          value={editedLead["LeadOwner"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Contact Person<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["ContactPerson"] ||
                            selectedLead["ContactPerson"]
                          }
                          value={editedLead["ContactPerson"] || ""}
                          onChange={handleChange}
                          name="ContactPerson"
                          disabled
                        />
                        <FormLabel>Phone</FormLabel>
                        <PhoneInput
                          name="Phone"
                          defaultValue={
                            formData["CountryData"]
                              ? `+${formData["CountryData"].dialCode} - ${formData["Phone"]}`
                              : `+${selectedLead["DialCode"]} - ${selectedLead["Phone"]}`
                          }
                          value={
                            `+${editedLead["DialCode"]} - ${editedLead["Phone"]}` ||
                            ""
                          }
                          onChange={(value, countryData) =>
                            handleChangePhone(value, countryData)
                          }
                        />
                        <FormLabel>City</FormLabel>
                        <Select
                          defaultValue={selectedLead["City"]}
                          value={editedLead["City"] || ""}
                          onChange={handleChange}
                          name="City"
                        >
                          {cityData.length > 0 ? (
                            cityData.map((item) => (
                              <option
                                key={item.City_code}
                                value={item.City_name}
                              >
                                {item.City_name}
                              </option>
                            ))
                          ) : (
                            <option value="">No cities available</option>
                          )}
                        </Select>
                        <FormLabel>Tier</FormLabel>
                        <Select
                          name="Tier"
                          defaultValue={
                            formData["Tier"] || selectedLead["Tier"]
                          }
                          value={editedLead["Tier"] || ""}
                          onChange={handleChange}
                        >
                          {gradeData.map((item) => (
                            <option value={item.Tier}>{item.Tier}</option>
                          ))}
                        </Select>{" "}
                        <FormLabel>Follow Up Date</FormLabel>
                        <Input
                          className="cursor-pointer"
                          type="date"
                          name="FollowUpDate"
                          defaultValue={
                            (
                              editedLead["FollowUpDate"] ||
                              formData["FollowUpDate"] ||
                              selectedLead["FollowUpDate"] ||
                              ""
                            ).split("T")[0] || ""
                          }
                          onChange={handleChange}
                        />
                        <FormLabel>Follow Up Interval</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["FollowUpInterval"] ||
                            selectedLead["FollowUpInterval"]
                          }
                          value={editedLead["FollowUpInterval"] || ""}
                          onChange={handleChange}
                          name="FollowUpInterval"
                        />
                        <FormLabel>Region</FormLabel>
                        <Select
                          name="Region"
                          defaultValue={
                            formData["Region"] || selectedLead["Region"]
                          }
                          value={editedLead["Region"] || ""}
                          onChange={handleChange}
                        >
                          {regionData.map((item) => (
                            <option value={item.Region}>{item.Region}</option>
                          ))}
                        </Select>
                        <FormLabel>Lead Source</FormLabel>
                        <Select
                          name="Source"
                          defaultValue={
                            formData["Source"] || selectedLead["Source"]
                          }
                          value={editedLead["Source"] || ""}
                          onChange={handleChange}
                        >
                          {leadSourceData.map((item) => (
                            <option value={item.Source}>{item.Source}</option>
                          ))}
                        </Select>
                        <FormLabel>Meeting Date</FormLabel>
                        <Input
                        type="text"
                        name="MeetingDate"
                        defaultValue={
                          (formData["MeetingDate"] || selectedLead["MeetingDate"] || "").split("T")[0]
                        }
                        value={(editedLead["MeetingDate"] || "").split("T")[0]}
                        onChange={handleChange}
                        disabled
                      />
                        <FormLabel>Meeting Status</FormLabel>
                        <Select
                          defaultValue={formData["MeetingStatus"] || selectedLead["MeetingStatus"]}
                          value={editedLead["MeetingStatus"] || ""}
                          onChange={handleChange}
                          name="MeetingStatus"
                        >
                          <option value="">Select Meeting Status</option>
                          <option value="Conducted">Conducted</option>
                          <option value="Cancelled">Cancelled</option>
                        </Select>
                        <FormLabel>Call Date</FormLabel>
                        <Input
                          type="date"
                          name="CallDate"
                          defaultValue={
                            (
                              formData["CallDate"] ||
                              selectedLead["CallDate"] ||
                              ""
                            ).split("T")[0] || ""
                          }
                          value={(editedLead["CallDate"] || "").split("T")[0]}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>Call Status</FormLabel>
                        <Select
                          defaultValue={formData["CallStatus"] || selectedLead["CallStatus"]}
                          value={editedLead["CallStatus"] || ""}
                          onChange={handleChange}
                          name="CallStatus"
                        >
                          <option value="">Select Call Status</option>
                          <option value="Conducted">Conducted</option>
                          <option value="Cancelled">Cancelled</option>
                        </Select>


                      </FormControl>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                      <FormControl>
                        <FormLabel>
                          Company<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Company"
                          defaultValue={
                            formData["Company"] || selectedLead["Company"]
                          }
                          value={editedLead["Company"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Email<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Email"
                          defaultValue={
                            formData["Email"] || selectedLead["Email"]
                          }
                          value={editedLead["Email"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Country<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Select
                          defaultValue={
                            formData["Country"] || selectedLead["Country"]
                          }
                          value={editedLead["Country"] || ""}
                          onChange={handleChange}
                          name="Country"
                          disabled={true}
                        >
                          {countryData.map((item) => (
                            <option
                              key={item.Phone_code}
                              value={item.Country_name}
                            >
                              {item.Country_name}
                            </option>
                          ))}
                        </Select>

                        <FormLabel>Industry</FormLabel>
                        <Select
                          name="Industry"
                          defaultValue={
                            formData["Industry"] || selectedLead["Industry"]
                          }
                          value={editedLead["Industry"] || ""}
                          onChange={handleChange}
                        >
                          {industryData.map((item) => (
                            <option value={item.Industry}>
                              {item.Industry}
                            </option>
                          ))}
                        </Select>
                        <FormLabel>Tags</FormLabel>
                        <Select
                          defaultValue={
                            formData["Tags"] || selectedLead["Tags"]
                          }
                          value={editedLead["Tags"] || ""}
                          onChange={handleChange}
                          name="Tags"
                        >
                          {tagData.map((item) => (
                            <option value={item.Tags}>{item.Tags}</option>
                          ))}
                        </Select>

                        <FormLabel>Remarks</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Remarks"] || selectedLead["Remarks"]
                          }
                          value={editedLead["Remarks"] || ""}
                          onChange={handleChange}
                          name="Remarks"
                        />
                        <FormLabel>Follow Up Status</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["FollowUpStatus"] ||
                            selectedLead["FollowUpStatus"]
                          }
                          value={editedLead["FollowUpStatus"] || ""}
                          onChange={handleChange}
                          name="FollowUpStatus"
                          disabled
                        />
                        <FormLabel>Notification Days</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["NotificationDays"] ||
                            selectedLead["NotificationDays"]
                          }
                          value={editedLead["NotificationDays"] || ""}
                          onChange={handleChange}
                          name="NotificationDays"
                        />
                        <FormLabel marginTop="39">Meeting Remarks</FormLabel>
                        <Input
                          type="text"
                          name="MeetingRemarks"
                          defaultValue={
                            formData["LeadOwner"] || selectedLead["LeadOwner"]
                          }
                          value={editedLead["MeetingRemarks"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel marginTop="39">Call Remarks</FormLabel>
                        <Input
                          type="text"
                          name="CallRemarks"
                          defaultValue={
                            formData["CallRemarks"] || selectedLead["CallRemarks"]
                          }
                          value={editedLead["CallRemarks"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                          <FormLabel>Meeting Time</FormLabel>
                        <Input
                          type="text"
                          name="MeetingTime"
                          defaultValue={
                            formData["MeetingTime"] || selectedLead["MeetingTime"]
                          }
                          value={editedLead["MeetingTime"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                          <FormLabel>Call Time</FormLabel>
                        <Input
                          type="text"
                          name="CallTime"
                          defaultValue={
                            formData["CallTime"] || selectedLead["CallTime"]
                          }
                          value={editedLead["CallTime"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {!selectedLead && (
                    <p>Data not available for the selected lead.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
          </>
        )}
      </>
    </div>
  );
};
export default DraggableLeadTable;
