import "./App.css";
import { SelectedModulesProvider } from "./components/Header/SelectedModulesContext";
import RootRouter from "./Navigation";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  // useEffect
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <SelectedModulesProvider>
            <RootRouter />
          </SelectedModulesProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </div>
  );
};
export default App;
