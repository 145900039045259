import React from "react";
import { useState, useEffect } from "react";
import { QuotationAPIInstance, MasterDataAPIInstance } from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment, { utc } from "moment/moment";

// Internal module import
import "./DraggableQuotationTable.css";

// Chakara ui modules
import { Badge, Textarea } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";

const DraggableQuotationTable = ({
  isLoading,
  columns,
  data,
  quotationData,
  setQuotationData,
  getData,
  setRateData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsQuotation,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [EnquiryData, setEnquiryData] = useState([]);
  const [mode, setmodeData] = useState([])
  const [selectedQuotation, setSelectedQuotation] = useState({});
  const [editedQuotation, setEditedQuotation] = useState({});
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
  });
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [containers, setContainers] = useState([{}]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const [sizeContainer, setSizeContainer] = useState("xl");
  const {
    isOpen: isOpenContainer,
    onOpen: onOpenContainer,
    onClose: onCloseContainer,
  } = useDisclosure();
  const handleCloseConatiner = () => {
    setSelectedRowId(null);
    setIsContainerOpen(false);
    onCloseContainer(true);
  };
  const handleFullSizeContainer = () => {
    setSizeContainer("xl");
    onOpenContainer();
  };


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Scope API
  const [ScopeData, setScopeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Incoterm Api 
  const [IncotermData, setIncotermData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Packing Type API 
  const [PackingTypeData, setPackingTypeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Lead Mode API
  const [ModeData, setModeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //customer
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setcustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Equipment API 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // UseEffects
  useEffect(() => {
    setEditedQuotation(selectedQuotation);
  }, [selectedQuotation]);

  const [quotationContainers, setQuotationContainers] = useState([
    {
      Description: "",
      ContainerType: "",
      Unit: "",
      UnitPrice: "",
      Currency: "",
      ExRate: "",
      TotalINRValue: "",
    },
  ]);

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);


  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...quotationData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setQuotationData === "function") {
      setQuotationData(sortedData);
    }
  };

  function handleContainerChange(name, value, str) {
    // Parse the string into an array of objects
    let containers = str?.split(";").map((item) => {
      let obj = {};
      item
        .trim()
        ?.split(",")
        .forEach((prop) => {
          let [key, val] = prop?.split(":").map((p) => p.trim());
          // obj[key] = isNaN(val) ? val : parseFloat(val);
          obj[key] = val;
        });
      return obj;
    });

    // Update the specified object
    let [propertyName, index] = name?.split("-");
    index = parseInt(index);
    containers[index][propertyName] = value;

    // Convert the array back into a string
    let updatedStr = containers
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, val]) => `${key}: ${val}`)
          .join(", ");
      })
      .join("; ");

    return updatedStr;
  }

  // const handleChange = async (e) => {
  //   try {
  //     const { name, value } = e.target;

  //     // Update the state properly for QuoteInclusiveof and QuoteExclusiveof
  //     setEditedQuotation((prev) => ({
  //       ...prev,
  //       [name]: value.split("\n"), // Split by newlines for multi-line input (back to array form)
  //     }));
  //   } catch (error) {
  //     // Handle errors if necessary
  //     toast({
  //       title: error.message,
  //       position: "bottom-center",
  //       isClosable: true,
  //       status: "error",
  //       containerStyle: {
  //         width: "98.5vw",
  //         maxWidth: "98.5vw",
  //         alignContent: "center",
  //       },
  //     });
  //   }
  // };

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;

      setEditedQuotation((prev) => ({
        ...prev,
        [name]:
          name === "QuoteInclusiveof" || name === "QuoteExclusiveof"
            ? value.split("\n") // Split by newlines for multi-line fields
            : value, // Use raw value for other fields
      }));
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  useEffect(() => {
    console.log("Edited Quotation updated:", editedQuotation);
  }, [editedQuotation]);



  const getOriginCountry = async (country) => {
    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  console.log("SL:", selectedQuotation);

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedQuotation,
      DialCode: formData?.CountryData
        ? formData.CountryData.dialCode
        : editedQuotation.DialCode, // Use the updated dial code
      Phone: formData?.Phone ? formData.Phone : editedQuotation.Phone, // Use the updated phone number
    };
    console.log("Updated data", updateData);
    try {
      const { data } = await QuotationAPIInstance.put(
        `singleupdate_quotation?userId=?${JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedQuotation.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Quotation Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedRate state here
        setSelectedQuotation((prevSelectedQuotation) => ({
          ...prevSelectedQuotation,
          ...editedQuotation,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  //  Function to open the modal and fetch rate data for a particular ID
  const handleOpenModal = async (id, QuotationNumber) => {
    try {
      const selectedQuotationData = quotationData.find((quotation) => quotation.id === id);
      //const QuotationNumber = quotationData((quotation) => quotation.id === id);
      setSelectedQuotation(selectedQuotationData);
      handleFullSizeClick();
      setSelectedRowId(id);

      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${selectedQuotationData?.Country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    console.log(QuotationNumber);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);


  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const handleOpenContainer = async (QuotationNumber) => {
    try {
      const selectedQuotationData = quotationData.find(
        (quotation) => quotation.QuotationNumber === QuotationNumber
      );
      setSelectedQuotation(selectedQuotationData);
      handleFullSizeContainer();
      setSelectedRowId(QuotationNumber);
      console.log(selectedQuotationData, "Quotation Data Id");
    } catch (error) {
      console.log(error);
    }
  };


  // const handleInnerContainerChange = (e, index, field) => {
  //   const { value } = e.target;

  //   setEditedQuotation((prevQuotation) => {
  //     // Split Containers from prevQuotation
  //     const containers = prevQuotation?.Containers?.split(";") || [];

  //     // Parse the selected container for the given index
  //     const containerAttributes = containers[index]?.split(", ").reduce((acc, curr) => {
  //       const [key, val] = curr.split(":");
  //       acc[key.trim()] = val?.trim() || "";
  //       return acc;
  //     }, {});

  //     // Update the specific field
  //     containerAttributes[field] = value;

  //     // If Quantity, UnitPrice, or ExRate is being updated, recalculate TotalINRValue
  //     if (field === "Quantity" || field === "UnitPrice" || field === "ExRate") {
  //       const quantity = parseFloat(containerAttributes["Quantity"]) || 0;
  //       const unitPrice = parseFloat(containerAttributes["UnitPrice"]) || 0;
  //       const exRate = parseFloat(containerAttributes["ExRate"]) || 0;

  //       // Automatically recalculate the TotalINRValue if not manually changed
  //       const totalValue = (quantity * unitPrice * exRate).toFixed(2);
  //       containerAttributes["TotalValue"] = totalValue;
  //     }

  //     // Rebuild the container string with updated values
  //     containers[index] = Object.entries(containerAttributes)
  //       .map(([key, val]) => `${key}: ${val}`)
  //       .join(", ");

  //     // Calculate the total quote value by summing up all TotalINRValue fields
  //     const totalQuoteValue = containers.reduce((total, container) => {
  //       const containerDetails = container.split(", ").reduce((acc, curr) => {
  //         const [key, val] = curr.split(":");
  //         acc[key.trim()] = val?.trim() || "";
  //         return acc;
  //       }, {});
  //       return total + (parseFloat(containerDetails["TotalValue"]) || 0);
  //     }, 0).toFixed(2);

  //     // Update the Containers and the TotalQuoteValue in editedQuotation and return new state
  //     return {
  //       ...prevQuotation,
  //       Containers: containers.join(";"),
  //       TotalQuoteValue: totalQuoteValue // Update TotalQuoteValue
  //     };
  //   });
  // };


  const handleInnerContainerChange = (e, index, field) => {
    const { value } = e.target;

    // Direct UI update for immediate feedback
    setEditedQuotation((prevQuotation) => {
      const containers = prevQuotation?.Containers?.split(";") || [];
      const containerAttributes = containers[index]?.split(", ").reduce((acc, curr) => {
        const [key, val] = curr.split(":");
        acc[key.trim()] = val?.trim() || "";
        return acc;
      }, {});

      // Update the specific field
      containerAttributes[field] = value;

      // Recalculate dependent values
      const chargeableWeight = parseFloat(containerAttributes["ChargeableWeight"]) || 0;
      const rate = parseFloat(containerAttributes["Rate"]) || 0;
      const exRate = parseFloat(containerAttributes["ExRate"]) || 0;

      containerAttributes["Rate1"] = (rate * exRate).toFixed(2);
      containerAttributes["TotalValue1"] = (chargeableWeight * rate).toFixed(2);
      containerAttributes["TotalValue2"] = (chargeableWeight * parseFloat(containerAttributes["Rate1"])).toFixed(2);

      // If Quantity, UnitPrice, or ExRate is being updated, recalculate TotalINRValue
      if (field === "Quantity" || field === "UnitPrice" || field === "ExRate") {
        const quantity = parseFloat(containerAttributes["Quantity"]) || 0;
        const unitPrice = parseFloat(containerAttributes["UnitPrice"]) || 0;
        const exRate = parseFloat(containerAttributes["ExRate"]) || 0;

        // Automatically recalculate the TotalINRValue if not manually changed
        const totalValue = (quantity * unitPrice * exRate).toFixed(2);
        containerAttributes["TotalValue"] = totalValue;
      }

      containers[index] = Object.entries(containerAttributes)
        .map(([key, val]) => `${key}: ${val}`)
        .join(", ");

      const totalQuoteValue = containers.reduce((total, container) => {
        const containerDetails = container.split(", ").reduce((acc, curr) => {
          const [key, val] = curr.split(":");
          acc[key.trim()] = val?.trim() || "";
          return acc;
        }, {});
        return total + (parseFloat(containerDetails["TotalValue"]) || 0);
      }, 0).toFixed(2);

      return {
        ...prevQuotation,
        Containers: containers.join(";"),
        TotalQuoteValue: totalQuoteValue,
      };
    });
  };


  const addContainer = () => {
    setEditedQuotation((prev) => ({
      ...prev,
      Containers:
        (prev.Containers ? prev.Containers + "; " : "") +
        "Container_Id: , Description: , Equipment: , Quantity: , Weight: , ChargeableWeight: , Rate: , Rate1: , Currency: , Currency1: , UnitPrice: , ExRate: , TotalValue: , TotalValue1: , TotalValue2: , DeletionFlag: 0",
    }));
  };

  const addairfreightContainer = () => {
    setEditedQuotation((prev) => {
      const newContainer = [
        "Container_Id: , Description: , Equipment: , Quantity: , Weight: , ChargeableWeight: , Rate: , Rate1: , Currency: , Currency1: , UnitPrice: , ExRate: , TotalValue: , TotalValue1: , TotalValue2: , DeletionFlag: 0",
      ];

      const updatedContainers = prev.Containers
        ? [...prev.Containers.split(";").filter((row) => row.trim()), ...newContainer].join("; ")
        : newContainer.join("; ");

      return {
        ...prev,
        Containers: updatedContainers,
      };
    });
  };


  function removeRowByIndex(data, indexToRemove) {
    // Split the data into rows and filter out empty rows
    let rows = data?.split(";").map((row) => row.trim()).filter((row) => row !== "");
    const removedContainerIds = []; // Array to track removed container IDs

    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      const removedRow = rows[indexToRemove]; // Get the row being removed

      // Extract Container_ID from the row
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_Id:"))
        ?.split(":")[1]
        ?.trim();

      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Add to DeletionFlag
      }

      rows.splice(indexToRemove, 1); // Remove row at specified index
    }

    return { updatedRows: rows.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }

  const removeContainer = (indexToRemove) => {
    setEditedQuotation((prev) => {
      // Remove the container
      const { updatedRows, removedContainerIds } = removeRowByIndex(prev.Containers, indexToRemove);

      // Calculate the updated TotalQuoteValue and other computed values
      const containersArray = updatedRows
        .split(";")
        .map((row) => row.trim())
        .filter((row) => row !== "");

      const { totalQuoteValue, recalculatedContainers } = containersArray.reduce(
        (result, container) => {
          // Split container details into key-value pairs
          const containerDetails = container.split(", ").reduce((acc, curr) => {
            const [key, val] = curr.split(":");
            acc[key.trim()] = val?.trim() || "";
            return acc;
          }, {});

          // Perform calculations
          const rate = parseFloat(containerDetails["Rate"]) || 0;
          const weight = parseFloat(containerDetails["Weight"]) || 0;
          const exRate = parseFloat(containerDetails["ExRate"]) || 0;

          containerDetails["TotalValue1"] = (rate * weight).toFixed(2);
          containerDetails["Rate1"] = (rate * exRate).toFixed(2);
          containerDetails["TotalValue2"] = (containerDetails["Rate1"] * weight).toFixed(2);

          // Update total quote value
          result.totalQuoteValue += parseFloat(containerDetails["TotalValue1"]) || 0;

          // Reconstruct container string with updated values
          const updatedContainer = Object.entries(containerDetails)
            .map(([key, val]) => `${key}: ${val}`)
            .join(", ");

          result.recalculatedContainers.push(updatedContainer);
          return result;
        },
        { totalQuoteValue: 0, recalculatedContainers: [] }
      );

      return {
        ...prev,
        Containers: recalculatedContainers.join("; "), // Update Containers with recalculated values
        TotalQuoteValue: totalQuoteValue.toFixed(2), // Update TotalQuoteValue
        DeletionFlag: [...(prev.DeletionFlag || []), ...removedContainerIds], // Append new deletion flags
      };
    });
  };

  const getCurrencyForValue = (currencyType) => {
    // Iterate through the Containers string and find the corresponding currency
    const containerString = editedQuotation?.Containers?.split(";")
      .find((container) => container.includes(`${currencyType}:`));
  
    // Extract and return the correct currency or fallback to default
    const currencyMatch = containerString?.split(", ").find(attr => attr.startsWith(`${currencyType}:`))?.split(":")[1];
    return currencyMatch || "Currency";
  };
  

  return (
    <div>
      {!isLoading ? (
        tableColumnsQuotation?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>

                {tableColumnsQuotation.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "Container_Id" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {(
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id, row?.QuotationNumber);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id, row?.QuotationNumber);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() =>
                            handleOpenModal(row?.id, row?.QuotationNumber)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsQuotation.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "Container_Id" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (<Badge colorScheme="green">Not Deleted</Badge>)
                            ) : column.key === "Date" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "ValidTillDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Containers" ? (
                              row[column.key] ? (
                                <>
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => {
                                      //if(columnIndex === )
                                      console.log("Column Index", columnIndex);
                                      handleOpenContainer(row?.QuotationNumber);
                                    }}
                                  >
                                    View Container
                                  </Button>
                                </>
                              ) : (
                                ""
                              )
                            ) :
                              (
                                row[column.key]
                              )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsQuotation.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        {isOpenContainer && (
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                // height: 200
              }}
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <br />

              <header>Container Information</header>
              <div
                className="closemodule cancelDragAndDrop"
                onClick={() => {
                  handleCloseConatiner(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <br />
              <div>
                {/* Table for Airfreight Mode */}
                {selectedQuotation?.Mode === "Airfreight" && (
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th>Weight (IN KG)</Th>
                          <Th>Currency</Th>
                          <Th>Rate (per KG)</Th>
                          <Th>Exchange Rate</Th>
                          <Th>Currency1</Th>
                          <Th>Rate1 (per KG)</Th>
                          <Th>TotalValue1</Th>
                          <Th>TotalValue2</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {selectedQuotation?.Containers?.split(";").map((containerInfo, index) => {
                          const containerDetails = containerInfo.trim()?.split(", ");
                          const containerData = Object.fromEntries(
                            containerDetails.map((detail) =>
                              detail.split(": ").map((item) => item?.trim())
                            )
                          );

                          return (
                            <Tr key={index}>
                              <Td>{containerData.Description || "-"}</Td>
                              <Td>{containerData.ChargeableWeight || "-"}</Td>
                              <Td>{containerData.Currency || "-"}</Td>
                              <Td>{containerData.Rate || "-"}</Td>
                              <Td>{containerData.ExRate || "-"}</Td>
                              <Td>{containerData.Currency1 || "-"}</Td>
                              <Td>{containerData.Rate1 || "-"}</Td>
                              <Td>{containerData.TotalValue1 || "-"}</Td>
                              <Td>{containerData.TotalValue2 || "-"}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
                {selectedQuotation?.Mode !== "Airfreight" && (
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th>Equipment</Th>
                          <Th>Unit</Th>
                          <Th>Currency</Th>
                          <Th>UnitPrice</Th>
                          <Th>ExRate</Th>
                          <Th>TotalValue</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {selectedQuotation?.Containers?.split(";").map((containerInfo, index) => {
                          // Parse container details into key-value pairs
                          const containerDetails = containerInfo.trim()?.split(", ");
                          const containerData = Object.fromEntries(
                            containerDetails.map((detail) =>
                              detail.split(": ").map((item) => item?.trim())
                            )
                          );

                          // Render table rows with proper mapping
                          return (
                            <Tr key={index}>
                              <Td>{containerData.Description || "-"}</Td>
                              <Td>
                                {["LCL", "Breakbulk/RORO"].includes(selectedQuotation.Mode)
                                  ? "-"
                                  : containerData.Equipment || "-"}
                              </Td>
                              <Td>{containerData.Quantity || "-"}</Td>
                              <Td>{containerData.Currency || "-"}</Td>
                              <Td>{containerData.UnitPrice || "-"}</Td>
                              <Td>{containerData.ExRate || "-"}</Td>
                              <Td>{containerData.TotalValue || "-"}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </Rnd>
          </div>
        )}
      </>
      <>
        {isOpen && (
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                height: 700,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Quotation Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedQuotation && Object.keys(selectedQuotation).length > 0 && (
                <form>
                  <Tabs>
                    <Badge variant="solid" colorScheme="red" fontSize="12pt">
                      Quotation Number: {selectedQuotation["QuotationNumber"]}
                    </Badge>
                  </Tabs>
                  <Tabs w="100%" variant="enclosed">
                    &nbsp;
                    <TabList className="cancelDragAndDrop">
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Quotation Information
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Conatiners
                      </Tab>
                    </TabList>
                    {selectedQuotation &&
                      Object.keys(selectedQuotation).length > 0 && (
                        <TabPanels>
                          {/* Quotation Info */}
                          <TabPanel>
                            <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel>Mode</FormLabel>
                                  <Select
                                    className="cancelDragAndDrop"
                                    name="Mode"
                                    defaultValue={formData["Mode"] || selectedQuotation["Mode"]}
                                    value={editedQuotation["Mode"] || ""}
                                    onChange={handleChange}
                                  >
                                    {ModeData.map((item) => (
                                      <option key={item.modeId} value={item.modeId}>
                                        {item.modeId}
                                      </option>
                                    ))}
                                  </Select>

                                  <FormLabel>CompanyName</FormLabel>
                                  <Input
                                    type="text"
                                    name="CompanyName"
                                    defaultValue={formData["CompanyName"] || selectedQuotation["CompanyName"]}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Address</FormLabel>
                                  <Input
                                    type="text"
                                    name="Address"
                                    defaultValue={formData["Address"] || selectedQuotation["Address"]}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>PIC</FormLabel>
                                  <Input
                                    type="text"
                                    name="PIC"
                                    defaultValue={formData["PIC"] || selectedQuotation["PIC"]}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Incoterm</FormLabel>
                                  <Select
                                    type="text"
                                    name="Incoterm"
                                    defaultValue={formData["Incoterm"] || selectedQuotation["Incoterm"]}
                                    onChange={handleChange}
                                  >
                                    {IncotermData.map((item) => (
                                      <option key={item.Incoterm} value={item.Incoterm}>
                                        {item.Incoterm}
                                      </option>
                                    ))}
                                  </Select>

                                  <FormLabel>Commodity</FormLabel>
                                  <Input
                                    type="text"
                                    name="Commodity"
                                    defaultValue={formData["Commodity"] || selectedQuotation["Commodity"]}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>PickUp Location</FormLabel>
                                  <Input
                                    type="text"
                                    name="PickupLocation"
                                    defaultValue={formData["PickupLocation"] || selectedQuotation["PickupLocation"]}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Delivery Location</FormLabel>
                                  <Input
                                    type="text"
                                    name="DeliveryLocation"
                                    defaultValue={formData["DeliveryLocation"] || selectedQuotation["DeliveryLocation"]}
                                    value={editedQuotation["DeliveryLocation"] || ""}
                                    onChange={handleChange}
                                  />


                                  <div className="input-box">
                                    <span className="quotation">Freight</span>
                                    <Select
                                      type="text"
                                      name="Freight"
                                      defaultValue={formData["Freight"] || selectedQuotation["Freight"]}
                                      value={editedQuotation["Freight"] || ""}
                                      onChange={handleChange}
                                    >
                                      <option value="">select a value</option>
                                      <option value="Prepaid">Prepaid</option>
                                      <option value="Collect">Collect</option>
                                    </Select>
                                  </div>

                                  {/* Conditional Rendering Based on Mode */}
                                  {editedQuotation.Mode === "Airfreight" && (
                                    <>
                                      {/* <FormLabel>Gross Weight</FormLabel>
                                      <Input
                                        type="text"
                                        name="GrossWeight"
                                        value={editedQuotation.GrossWeight || ""}
                                        onChange={handleChange}
                                      /> */}

                                      <FormLabel>Chargeable Weight</FormLabel>
                                      <Input
                                        type="text"
                                        name="ChargeableWeight"
                                        value={editedQuotation.ChargeableWeight || ""}
                                        onChange={handleChange}
                                      />

                                      <div className="input-box">
                                        <span className="quotation">Stackability</span>
                                        <Select
                                          name="Stackability"
                                          value={editedQuotation.Stackability || ""}
                                          onChange={handleChange}
                                        >
                                          <option value="">Select a value</option>
                                          <option value="Stackable">Stackable</option>
                                          <option value="Non Stackable">Non Stackable</option>
                                        </Select>
                                      </div>
                                    </>
                                  )}

                                  {editedQuotation.Mode === "LCL" && (
                                    <>
                                      <div className="input-box">
                                        <span className="quotation">Stackability</span>
                                        <Select
                                          name="Stackability"
                                          value={editedQuotation.Stackability || ""}
                                          onChange={handleChange}
                                        >
                                          <option value="">Select a value</option>
                                          <option value="Stackable">Stackable</option>
                                          <option value="Non Stackable">Non Stackable</option>
                                        </Select>
                                      </div>
                                    </>
                                  )}

                                  {editedQuotation.Mode !== "Airfreight" &&
                                    editedQuotation.Mode !== "LCL" && <></>}

                                </FormControl>
                              </GridItem>

                              <GridItem colStart={4} colEnd={6}>
                                <FormControl>
                                  <FormLabel>Date</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="Date"
                                    defaultValue={
                                      (
                                        editedQuotation["Date"] ||
                                        formData["Date"] ||
                                        selectedQuotation["Date"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Valid Till Date</FormLabel>
                                  <Input
                                    className="cursor-pointer"
                                    type="date"
                                    name="ValidTillDate"
                                    defaultValue={
                                      (
                                        editedQuotation["ValidTillDate"] ||
                                        formData["ValidTillDate"] ||
                                        selectedQuotation["ValidTillDate"] ||
                                        ""
                                      )?.split("T")[0] || ""
                                    }
                                    onChange={handleChange}
                                  />

                                  <FormLabel>
                                    {["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData[formDataIndex]?.Mode)
                                      ? "Dimension and weight" // Show only Dimension and weight
                                      : "Equipment, Dimension and weight" // Show full label for other Modes
                                    }
                                  </FormLabel>
                                  <Textarea
                                    type="text"
                                    name="EquipmentDimensionandweight"
                                    defaultValue={formData["EquipmentDimensionandweight"] || selectedQuotation["EquipmentDimensionandweight"]}
                                    value={editedQuotation["EquipmentDimensionandweight"] || ""}
                                    onChange={handleChange}
                                  />


                                  <FormLabel>Remarks</FormLabel>
                                  <Textarea
                                    type="text"
                                    name="Remarks"
                                    defaultValue={Array.isArray(formData["Remarks"])
                                      ? formData["Remarks"].join("\n")
                                      : formData["Remarks"] || selectedQuotation["Remarks"] || ""}
                                    value={Array.isArray(editedQuotation["Remarks"])
                                      ? editedQuotation["Remarks"].join("\n")
                                      : editedQuotation["Remarks"] || ""}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Quote Inclusive Of</FormLabel>
                                  <Textarea
                                    type="text"
                                    name="QuoteInclusiveof"
                                    defaultValue={Array.isArray(formData["QuoteInclusiveof"])
                                      ? formData["QuoteInclusiveof"].join("\n")
                                      : formData["QuoteInclusiveof"] || selectedQuotation["QuoteInclusiveof"] || ""}
                                    value={Array.isArray(editedQuotation["QuoteInclusiveof"])
                                      ? editedQuotation["QuoteInclusiveof"].join("\n")
                                      : editedQuotation["QuoteInclusiveof"] || ""}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Quote Exclusive Of</FormLabel>
                                  <Textarea
                                    type="text"
                                    name="QuoteExclusiveof"
                                    defaultValue={Array.isArray(formData["QuoteExclusiveof"])
                                      ? formData["QuoteExclusiveof"].join("\n")
                                      : formData["QuoteExclusiveof"] || selectedQuotation["QuoteExclusiveof"] || ""}
                                    value={Array.isArray(editedQuotation["QuoteExclusiveof"])
                                      ? editedQuotation["QuoteExclusiveof"].join("\n")
                                      : editedQuotation["QuoteExclusiveof"] || ""}
                                    onChange={handleChange}
                                  />

                                  <FormLabel>Quoting Address</FormLabel>
                                  <Textarea
                                    type="text"
                                    name="Quoting_Address"
                                    defaultValue={Array.isArray(formData["Quoting_Address"])
                                      ? formData["Quoting_Address"].join("\n")
                                      : formData["Quoting_Address"] || selectedQuotation["Quoting_Address"] || ""}
                                    value={Array.isArray(editedQuotation["Quoting_Address"])
                                      ? editedQuotation["Quoting_Address"].join("\n")
                                      : editedQuotation["Quoting_Address"] || ""}
                                    onChange={handleChange}
                                  />

                                  {/* <FormLabel>Total Quote Value</FormLabel>
                                  <Input
                                    type="text"
                                    name="TotalQuoteValue"
                                    value={editedQuotation["TotalQuoteValue"] || ""} // Use value instead of defaultValue
                                    readOnly
                                  /> */}

                                  {/* {formData[formDataIndex]?.Mode === "Airfreight" && (
  <>
    <FormLabel>TotalValue</FormLabel>
    <Input
      type="text"
      name="TotalValue1"
      value={editedQuotation["TotalValue1"] || ""}
      readOnly
    />

    <FormLabel>TotalValue</FormLabel>
    <Input
      type="text"
      name="TotalValue2"
      value={editedQuotation["TotalValue2"] || ""}
      readOnly
    />
  </>
)} */}

                                </FormControl>
                              </GridItem>
                            </Grid>
                          </TabPanel>
                          <TabPanel>
                            {selectedQuotation.Containers && (
                              <div className="table-container">
                                {editedQuotation?.Mode === "Airfreight" ? (
                                  // Airfreight Specific Table
                                  <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                                      <tr>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Description</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Weight (IN KG)</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Rate (per KG)</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">EXCHANGE RATE</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Rate (per KG)</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">{`Total (${getCurrencyForValue("Currency")})`}</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">{`Total (${getCurrencyForValue("Currency1")})`}</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {editedQuotation?.Containers?.split(";")?.map((container, index) => {
                                        const containerAttributes = container?.split(", ");
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                type="text"
                                                name={`Description-${index}`}
                                                value={
                                                  editedQuotation[`Description-${index}`] ||
                                                  containerAttributes[1]?.split(": ")[1]
                                                }
                                                onChange={(e) => handleInnerContainerChange(e, index, "Description")}
                                                placeholder="Enter Description"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name={`ChargeableWeight-${index}`}
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`ChargeableWeight-${index}`] ||
                                                  containerAttributes[5]?.split(": ")[1]
                                                }
                                                onChange={(e) => handleInnerContainerChange(e, index, "ChargeableWeight")}
                                                placeholder="Enter ChargeableWeight"
                                              />
                                            </td>
                                            <td>
                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                <Select
                                                  width="150px"
                                                  name={`Currency-${index}`}
                                                  value={
                                                    editedQuotation[`Currency-${index}`] ||
                                                    containerAttributes[8]?.split(": ")[1]
                                                  }
                                                  onChange={(e) => handleInnerContainerChange(e, index, "Currency")}
                                                >
                                                  <option value="">Select Currency</option>
                                                  {countryData.map((item, idx) => (
                                                    <option key={idx} value={item.Currency}>
                                                      {item.Currency}
                                                    </option>
                                                  ))}
                                                </Select>
                                                <Input
                                                  width="150px"
                                                  type="number"
                                                  name={`Rate-${index}`}
                                                  value={
                                                    editedQuotation[`Rate-${index}`] ||
                                                    containerAttributes[6]?.split(": ")[1]
                                                  }
                                                  onChange={(e) => handleInnerContainerChange(e, index, "Rate")}
                                                  placeholder="Enter your Rate"
                                                  style={{ marginLeft: "10px" }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name={`ExRate-${index}`}
                                                placeholder="Enter Ex-Rate"
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`ExRate-${index}`] ||
                                                  containerAttributes[11]?.split(": ")[1]
                                                }
                                                onChange={(e) => handleInnerContainerChange(e, index, "ExRate")}
                                              />
                                            </td>
                                            <td>
                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                <Select
                                                  width="150px"
                                                  name={`Currency1-${index}`}
                                                  value={
                                                    editedQuotation[`Currency1-${index}`] ||
                                                    containerAttributes[9]?.split(": ")[1]
                                                  }
                                                  onChange={(e) => handleInnerContainerChange(e, index, "Currency1")}
                                                >
                                                  <option value="">Select Currency</option>
                                                  {countryData.map((item, idx) => (
                                                    <option key={idx} value={item.Currency}>
                                                      {item.Currency}
                                                    </option>
                                                  ))}
                                                </Select>
                                                <Input
                                                  width="150px"
                                                  type="number"
                                                  name={`Rate1-${index}`}
                                                  value={
                                                    editedQuotation[`Rate1-${index}`] ||
                                                    containerAttributes[7]?.split(": ")[1]
                                                  }
                                                  onChange={(e) => handleInnerContainerChange(e, index, "Rate1")}
                                                  placeholder="Enter your Rate"
                                                  style={{ marginLeft: "10px" }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name={`TotalValue1-${index}`}
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`TotalValue1-${index}`] ||
                                                  containerAttributes[13]?.split(": ")[1]
                                                }
                                                onChange={(e) => handleInnerContainerChange(e, index, "TotalValue1")}
                                                readOnly
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name={`TotalValue2-${index}`}
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`TotalValue2-${index}`] ||
                                                  containerAttributes[14]?.split(": ")[1]
                                                }
                                                onChange={(e) => handleInnerContainerChange(e, index, "TotalValue1")}
                                                readOnly
                                              />
                                            </td>
                                            <td>
                                              {editedQuotation?.Containers?.split(";")?.length > 1 && (
                                                <BiSolidMinusCircle
                                                  className="cursor-pointer"
                                                  size={30}
                                                  style={{
                                                    marginLeft: "5px",
                                                    color: "red",
                                                  }}
                                                  onClick={() => removeContainer(index)}
                                                />
                                              )}
                                              <IoMdAddCircle
                                                className="cursor-pointer"
                                                size={30}
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "green",
                                                }}
                                                onClick={addairfreightContainer}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    </tbody>
                                  </table>
                                ) : (
                                  <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                                      <tr>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Description</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Equipment</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Unit</th> {/* Corrected from Unit */}
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Currency</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Unit Price</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Ex-Rate</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Total Value</th>
                                        <th className="border border-slate-300 whitespace-nowrap text-center">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {editedQuotation?.Containers?.split(";")?.map((container, index) => {
                                        const containerAttributes = container?.split(", ");
                                        const isAirfreightOrLCL =
                                          ["Airfreight", "LCL", "Breakbulk/RORO"].includes(editedQuotation?.Mode);

                                        return (
                                          <tr key={index}>
                                            {/* Description Field */}
                                            <td>
                                              <input
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                type="text"
                                                name={`Description-${index}`}
                                                value={
                                                  editedQuotation[`Description-${index}`] ||
                                                  containerAttributes[1]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "Description")
                                                }
                                                placeholder="Enter Description"
                                              />
                                            </td>

                                            {/* Equipment Field */}
                                            <td>
                                              {isAirfreightOrLCL ? (
                                                <span className="text-black dark:text-white">-</span>
                                              ) : (
                                                <select
                                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                  name={`Equipment-${index}`}
                                                  value={
                                                    editedQuotation[`Equipment-${index}`] ||
                                                    containerAttributes[2]?.split(": ")[1]
                                                  }
                                                  onChange={(e) =>
                                                    handleInnerContainerChange(e, index, "Equipment")
                                                  }
                                                >
                                                  <option value="">Select Equipment</option>
                                                  {equipmentData.map((item, idx) => (
                                                    <option key={idx} value={item.ContainerType}>
                                                      {item.ContainerType}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </td>
                                            {/* Unit Field */}
                                            <td>
                                              <input
                                                type="number"
                                                name={`Quantity-${index}`}
                                                placeholder="Enter Unit"
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`Quantity-${index}`] ||
                                                  containerAttributes[3]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "Quantity")
                                                }
                                              />
                                            </td>
                                            {editedQuotation?.Mode === "Airfreight" && (
                                              <>
                                                <td>
                                                  <input
                                                    type="number"
                                                    name={`Weight-${index}`}
                                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                    value={
                                                      editedQuotation[`Weight-${index}`] ||
                                                      containerAttributes[4]?.split(": ")[1]
                                                    }
                                                    onChange={(e) => handleInnerContainerChange(e, index, "Weight")}
                                                    placeholder="Enter Weight"
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    name={`Rate-${index}`}
                                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                    value={
                                                      editedQuotation[`Rate-${index}`] ||
                                                      containerAttributes[5]?.split(": ")[1]
                                                    }
                                                    onChange={(e) => handleInnerContainerChange(e, index, "Rate")}
                                                    placeholder="Enter Rate"
                                                  />
                                                </td>
                                              </>
                                            )}
                                            {/* Currency Field */}
                                            <td>
                                              <select
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                name={`Currency-${index}`}
                                                value={

                                                  containerAttributes[8]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "Currency")
                                                }
                                              >
                                                <option value="">Select Currency</option>
                                                {countryData.map((item, idx) => (
                                                  <option key={idx} value={item.Currency}>
                                                    {item.Currency}
                                                  </option>
                                                ))}
                                              </select>
                                            </td>

                                            {/* UnitPrice Field */}
                                            <td>
                                              <input
                                                type="number"
                                                name={`UnitPrice-${index}`}
                                                placeholder="Enter UnitPrice"
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`UnitPrice-${index}`] ||
                                                  containerAttributes[10]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "UnitPrice")
                                                }
                                                onKeyDown={(e) => {
                                                  if (e.key === "-" || e.key === "+" || e.key === "e") {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            </td>

                                            {/* Ex-Rate Field */}
                                            <td>
                                              <input
                                                type="number"
                                                name={`ExRate-${index}`}
                                                placeholder="Enter Ex-Rate"
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                value={
                                                  editedQuotation[`ExRate-${index}`] ||
                                                  containerAttributes[11]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "ExRate")
                                                }
                                                onKeyDown={(e) => {
                                                  if (e.key === "-" || e.key === "+" || e.key === "e") {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            </td>

                                            {/* TotalValue Field */}
                                            <td>
                                              <input
                                                type="text"
                                                name={`TotalValue-${index}`}
                                                value={
                                                  editedQuotation[`TotalValue-${index}`] ||
                                                  containerAttributes[12]?.split(": ")[1]
                                                }
                                                onChange={(e) =>
                                                  handleInnerContainerChange(e, index, "TotalValue")
                                                }
                                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                                readOnly
                                              />
                                            </td>

                                            {/* Actions */}
                                            <td>
                                              {editedQuotation?.Containers?.split(";")?.length > 1 && (
                                                <BiSolidMinusCircle
                                                  className="cursor-pointer"
                                                  size={30}
                                                  style={{
                                                    marginLeft: "5px",
                                                    color: "red",
                                                  }}
                                                  onClick={() => removeContainer(index)}
                                                />
                                              )}

                                              <IoMdAddCircle
                                                className="cursor-pointer"
                                                size={30}
                                                style={{
                                                  marginLeft: "5px",
                                                  color: "green",
                                                }}
                                                onClick={addContainer}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            )}
                            {!selectedQuotation && (
                              <p>Data not available for the selected Quotation.</p>
                            )}

                            {/* Summary Rows */}
                            <tr>
                              <td colSpan={5} style={{ height: "30px" }}></td>
                            </tr>
{/* Conditional Rendering for TotalValue1 and TotalValue2 */}
{editedQuotation?.Mode === "Airfreight" ? (
  <>
    {/* TotalValue1 */}
    <tr>
      <td colSpan={3}></td>
      <td className="font-bold text-left text-[16px]">
        {`Total (${getCurrencyForValue("Currency")})`}:
      </td>
      <td>
        <input
          className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
          style={{ width: "100%", height: "40px" }}
          type="number"
          name="TotalValue1"
          value={
            editedQuotation?.Containers?.split(";")
              .map((c) => {
                // Extract TotalValue1 and Currency
                const totalValue1 = parseFloat(c.split(", ").find(attr => attr.startsWith("TotalValue1:"))?.split(":")[1]) || 0;
                return totalValue1;
              })
              .reduce((total, value) => total + value, 0)
              .toFixed(2) // Summing TotalValue1
          }
          readOnly
        />
      </td>
    </tr>

    {/* Spacer Row */}
    <tr>
      <td colSpan={5} style={{ height: "30px" }}></td>
    </tr>

    {/* TotalValue2 */}
    <tr>
      <td colSpan={3}></td>
      <td className="font-bold text-left text-[16px]">
        {`Total (${getCurrencyForValue("Currency1")})`}:
      </td>
      <td>
        <input
          className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
          style={{ width: "100%", height: "40px" }}
          type="number"
          name="TotalValue2"
          value={
            editedQuotation?.Containers?.split(";")
              .map((c) => {
                // Extract TotalValue2 and Currency1
                const totalValue2 = parseFloat(c.split(", ").find(attr => attr.startsWith("TotalValue2:"))?.split(":")[1]) || 0;
                return totalValue2;
              })
              .reduce((total, value) => total + value, 0)
              .toFixed(2) // Summing TotalValue2
          }
          readOnly
        />
      </td>
    </tr>
  </>


                            ) : (
                              <>
                                {/* TotalQuoteValue */}
                                <tr>
                                  <td colSpan={5} style={{ height: "30px" }}></td>
                                </tr>
                                <tr>
                                  <td colSpan={3}></td>
                                  <td className="font-bold text-left text-[16px]">
                                    Total Quote Value:
                                  </td>
                                  <td>
                                    <input
                                      className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
                                      style={{ width: "100%", height: "40px" }}
                                      type="text"
                                      name="TotalQuoteValue"
                                      value={editedQuotation["TotalQuoteValue"] || ""}
                                      readOnly
                                    />
                                  </td>
                                </tr>
                              </>
                            )}


                          </TabPanel>
                        </TabPanels>
                      )}
                  </Tabs>
                  <Stack
                    spacing={4}
                    direction="row"
                    align="center"
                    float="right"
                    marginTop={5}
                  >
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                      colorScheme="messenger"
                      onClick={() => {
                        handleSubmitUpdate();
                        handleCloseModal();
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </form>
              )}
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};
export default DraggableQuotationTable;
