import { React, useState, useEffect } from "react";
import "./UnallocatedProspect.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";
import Axios, {
  BookingAPIInstance,
  JobAPIInstance,
} from "../../api-instance.js";
import { CgClose } from "react-icons/cg";
import {
  EnquiryAPIInstance,
  AuthAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";
import { Rnd } from "react-rnd";
import { useNavigate } from "react-router-dom";
const AddUnallocatedProspect = ({
  selectedRows,
  setSelectedRows,
  selectedData,
  setSelectedData,
  onApply,
  setUnallocatedBookingState,
  setFormData,
  formData,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const style = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    // overflowX: "auto",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "199",
  };

  const closeModal = {
    position: "absolute",
    top: "10%",
    right: "5%",
    cursor: "pointer",
  };
  const handleModalClose = () => {
    onClose();
  };

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await EnquiryAPIInstance.get(
        `get_prospect?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log("Data received:", data);
      if (data.data?.length === 0) {
        setData([]);
      } else {
        setData(data.data);
        setUnallocatedBookingState(data.data);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAllCheckboxClick = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
      setSelectedData([]);
      setFormData([]);
    } else {
      setFormData(data);
      setSelectedRows(data.map((item) => item.id));
      setSelectedData(data);
    }
  };

  const handleRowCheckboxClick = (id, rowData) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    if (rowData) {
      setSelectedData((prevSelectedData) => {
        if (prevSelectedData.some((row) => row?.id === id)) {
          return prevSelectedData.filter((row) => row?.id !== id);
        } else {
          return [...prevSelectedData, rowData];
        }
      });
    }

    setFormData([]);
  };

  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);

  console.log("Selected rows:", selectedRows);
  console.log("Selected data:", selectedData);

  const handleApplyClick = () => {
    if (selectedData.length > 0) {
      if (selectedData.length <= 5) {
        onApply(selectedData);
        //setSelectedRows([]);
        onClose();
        navigate("/addEnquiry", { state: { selectedData: selectedData } });
      } else {
        toast({
          title: "Please select a maximum of 5 booking.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "Please select at least one row.",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleCancelClick = () => {
    setSelectedRows([]);
    setSelectedData([]);
    setFormData([{}]);
    setData([]);
    onApply();
    setUnallocatedBookingState([]);
    getData();
    onClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter data based on the search query
  const filteredData = data.filter(
    (row) =>
      row.ProspectNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.Company.toLowerCase().includes(searchQuery.toLowerCase())||
      row.ContactPerson.toLowerCase().includes(searchQuery.toLocaleLowerCase())    // row.PickUpLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
    // row.DeliveryLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
    // row.Incoterm.toLowerCase().includes(searchQuery.toLowerCase()) ||
    // row.Commodity.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Button
        className="flexbox-container flexbox-item flexbox-item-2  bg-blue-500 hover:bg-blue-700 text-base font-semibold  dark:text-white dark:bg-blue-950"
        onClick={onOpen}
      >
        Create Enquiry
      </Button>

      {/* <div className="relative inline-block">
          <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950" 
           onClick={onOpen}
          >
            Create Enquiry
          </button>
        </div> */}

      {/* <Button
        className="flexbox-container flexbox-item flexbox-item-2  bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
       
      >
        Create Quotation
      </Button> */}

      {isOpen && (
        <>
          <div style={backdropStyle}></div>
          <div className={`MeetingModal `}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 950,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ fontSize: "20px", fontWeight: "bold", float: "left" }}
              >
                Unassigned Prospect
              </header>
              <input 
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search enquiries..."
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />

              <div onClick={handleModalClose} style={closeModal}>
                <CgClose />
              </div>
              <br />

              <div
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
              >
                <div className="table-container">
                  {!isLoading ? (
                    filteredData.length !== 0 ? (
                      <table className="table-auto border-collapse border w-full">
                        <thead className="border border-slate-300 whitespace-nowrap text-left">
                          <tr>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              <input
                                type="checkbox"
                                style={{
                                  cursor: "pointer",
                                  transform: "scale(1.3)",
                                  marginRight: "5px",
                                }}
                                checked={
                                  selectedRows.length === filteredData.length
                                }
                                onChange={handleAllCheckboxClick}
                              />
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Prospect Number
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Company
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Email
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              ContactPerson
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Phone
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              City
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Country
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              State
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Industry
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Remarks
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Tier
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Tags
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              FollowUpDate
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              ProspectOwner
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {console.log(
                                selectedData.ProspectNumber,
                                row?.ProspectNumber
                              )}
                              <td className="whitespace-nowrap text-center">
                                <input
                                  type="checkbox"
                                  style={{
                                    cursor: "pointer",
                                    transform: "scale(1.3)",
                                    marginRight: "5px",
                                  }}
                                  checked={selectedData.some(
                                    (data) =>
                                      data.ProspectNumber ===
                                      row?.ProspectNumber
                                  )}
                                  onChange={() =>
                                    handleRowCheckboxClick(row?.id, row)
                                  }
                                />
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.ProspectNumber}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Company}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Email}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.ContactPerson}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                +{row.DialCode} - {row.Phone}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.City}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Country}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.State}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Industry}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Remarks}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Tier}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Tags}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {new Date(
                                  row.FollowUpDate
                                ).toLocaleDateString()}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.ProspectOwner}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h1 style={{ textAlign: "center", color: "black" }}>
                        No Data Found!
                      </h1>
                    )
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  float: "right",
                  display: "block",
                  marginTop: "10px",
                }}
              >
                <Button colorScheme="green" onClick={handleApplyClick} mr={3}>
                  Apply
                </Button>
                <Button colorScheme="red" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </div>
            </Rnd>
          </div>
        </>
      )}
    </>
  );
};

export default AddUnallocatedProspect;
