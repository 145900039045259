import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import {
  useToast,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Stack,
  Box,
  AbsoluteCenter,
  Wrap,
  WrapItem,
  VStack,
  Tag,
  Button,
  Grid,
  GridItem,
  Tooltip
} from "@chakra-ui/react";
import { RxAvatar } from "react-icons/rx";
import { AuthAPIInstance } from "../../api-instance";

const Userprofile = () => {
  //hooks
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [userdata, setUserdata] = useState([]);

  // Function to handle closing the drawer
  const handleCloseUserProfile = () => {
    onClose();
  };

  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("tableColumns");
    localStorage.removeItem("DASHBOARD_RECENT_TABLE_COLUMNS");
    localStorage.removeItem("DASHBOARD_FOLLOWUP_TABLE_COLUMNS");
    localStorage.removeItem("DASHBOARD_LEAD_OWNER_ANALYTICS_TABLE_COLUMNS");
    localStorage.removeItem("selectedModules");
    localStorage.removeItem("jobfilterConditions");
    localStorage.removeItem("jobfilterRows");
    navigate("/");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserdata([user]);
    }
  }, []);

  // Function to handle opening the drawer
  const handleOpenUserProfile = () => {
    onOpen();
  };

  return (
    <>
    
    <Tooltip
  showArrow
  label={
    userdata[0]?.firstname && userdata[0]?.lastname
      ? `${userdata[0].firstname} ${userdata[0].lastname}`
      : "Loading..."
  }
  bg="gray.300"
  color="black"
>
<div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50">
  <FaRegUserCircle
    className="dark:bg-gray-800 dark:text-white cursor-pointer"
    size={20}
    onClick={handleOpenUserProfile}
  />
  </div>
</Tooltip>


      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleCloseUserProfile}
        finalFocusRef={firstField}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="0px" bg="#94a3b8">
            <DrawerCloseButton bg="red" color={"white"} />
          </DrawerHeader>
          <DrawerBody h={10} bg="#94a3b8">
            <Stack spacing="24px">
              <Box position="relative">
                <AbsoluteCenter mt={20} axis="both">
                  <Wrap>
                    <WrapItem>
                      <RxAvatar size={100} color="#ffff" />{" "}
                    </WrapItem>
                  </Wrap>
                </AbsoluteCenter>
              </Box>
              {userdata.length === 0 ? (
                <Box mt={150} color={"white"}>
                  Loading...
                </Box>
              ) : (
                <Box mt={150} color={"white"}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <VStack>
                      <Tag>
                        {userdata[0].firstname} {userdata[0].lastname}
                      </Tag>
                      <Tag>{userdata[0].email}</Tag>
                      <Tag>User ID: {userdata[0].userId}</Tag>
                    </VStack>
                  </div>
                </Box>
              )}
            </Stack>

            <Grid
              templateColumns="repeat(5, 1fr)"
              gap={4}
              mt={5}
              borderTopWidth="1px"
              color={"white"}
            >
              <GridItem colSpan={2} h="10" mt={5} className="cursor-pointer">
                <Button
                  variant="solid"
                  size="sm"
                  colorScheme="orange"
                  isDisabled
                >
                  My Account
                </Button>
              </GridItem>
              <GridItem
                colStart={4}
                colEnd={6}
                h="10"
                mt={5}
                className="cursor-pointer"
                onClick={handleLogout}
              >
                <Button variant="solid" size="sm" colorScheme="red">
                  Sign Out
                </Button>
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            bg="#cbd5e1"
            style={{
              textAlign: "center",
              display: "block",
            }}
          >
            <Tag variant="solid" fontSize="xl" fontWeight="bold">
              &copy; EON MatrixMindz @{currentYear}
            </Tag>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Userprofile;
