import moment from "moment";

export function getKeyAndValue(obj) {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  return [keys, values];
}

export function formatDate(data) {
  return data.map((item) => ({
    ...item,
    Datecreated: moment(item.Datecreated).format("D MMMM"),
    CreatedAt: moment(item.CreatedAt).format("D MMMM"),
    ChangedAt: moment(item.ChangedAt).format("D MMMM"),
    FollowUpDate: moment(item.FollowUpDate).format("D MMMM"),
  }));
}

export const omitedColumns = ["id", "userId"];

export function countKey(data, key) {
  const counts = {};
  for (const item of data || []) {
    counts[item[key]] = item.count || 0;
    counts[item[key]]++;
  }

  return counts;
}

export function getConversionRate(data) {
  return data.map((item) => ({
    ...item,
    ConversionRate:
      item.Booking !== 0 && item.Jobs !== 0
        ? ((item.Booking / item.Jobs) * 100).toFixed(2) + "%"
        : "0%",
  }));
}

export const defaultOmnitedColumns = [
  "id",
  "JobInfono",
  "Title",
  "DOBC",
  "Shipper",
  "Consignee",
  "Client",
  "Agent",
  "QuotationNumber",
  "Scope",
  "Region",
  "Incoterm",
  "Cargo",
  "Cargo_Nature",
  "HS_Code",
  "ContainerID",
  "Shipping_Line",
  "VesselNameVoyage",
  "CY_Open",
  "SI_Cutoff",
  "VGM_Cutoff",
  "CY_Cutoff",
  "Customs_Cutoff",
  "OOG_Cutoff",
  "ETD",
  "ETA",
  "days_at_origin",
  "days_at_destiantion",
  "Freight",
  "MBL_Type",
  "HBL_Type",
  "Liner_Bookingno",
  "MBLNo",
  "HBLNo",
  "Cargo_Pickup_Date",
  "Empty_Pickup_Date",
  "Stuffing_Date",
  "Laden_Gate_In_Date",
  "Vessel_Arrival_Date",
  "Operation_Transfer_Deadline",
  "Add_Cost_Id",
  "Container_Id",
  "Detention",
  "Demurrage",
  "Detention_By_Liner",
  "Demurrage_By_Liner",
  "DetentionNo",
  "DemurrageNo",
  "Port_Storage",
  "PortStorage",
  "Port_Storage_Days",
  "Combined_Detention_Demurrage",
  "Combined_Detention_Demurrage_Liner",
  "No_Of_Days_Detention_Demurrage_Occuring",
  "Container_Discharge_Date",
  "Port_Out_Date",
  "Empty_Return_Date",
  "Destination_Detention",
  "Destination_Demurrage",
  "Destination_Detention_By_Liner",
  "Destination_Demurrage_By_Liner",
  "Destination_No_Of_Detention_Occuring",
  "Destination_No_Of_Demurrage_Occuring",
  "Destination_Port_Storage",
  "Destination_Port_Storage_Given_By_Port",
  "Destination_No_Of_Days_Port_Storage_Occuring",
  "Destination_Combined_Detention_Demurrage",
  "Destination_Combined_Detention_Demurrage_By_Liner",
  "Destination_No_Of_Days_Detention_Demurrage_Occuring",
  "Current_Status",
  "Notes",
  "DeletionFlag",
  "ConversionFlag",
  "JobOwner",
  "CreatedBy",
  "ChangedBy",
  "CreatedOn",
  "CreatedDate",
  "ChangedDate",
  "ChangedOn",
  "HandleBy",
];
export const componentType = {
  table: {
    name: "Table",
    tableName: "",
    omitedColumns: defaultOmnitedColumns,
    collapsible: true,
    options: true,
    pagination: true,
  },
  // chart: {
  //   type: {
  //     pie: {
  //       name: "Pie",
  //       tableName: "",
  //       columnName: "",
  //     },
  //     donut: {
  //       name: "donut",
  //       tableName: "",
  //       columnName: "",
  //     },
  //     bar: {
  //       name: "Bar",
  //       tableName: "",
  //       columnName: "",
  //     },
  //   },
  // },
};

export const defaultComponents = [
  {
    name: "TOTALCOUNT",
    label: "Total Count",
    visible: true,
  },
  {
    name: "RECENTS",
    label: "Recently added",
    visible: true,
  },
];

export const customizeTableHelpText = `Drag and drop to reorder column. Click on “eye” to Toggle Visibility`;
