import { React, useState, useEffect } from "react";
// Internal module import
import "./DraggableVendorOperationTable.css";
// Chakara ui modules
import {
  useDisclosure,
  IconButton,
  useToast,
  Stack,
  Button,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { Rnd } from "react-rnd";
import {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../api-instance";
const DraggableVendorOperationTable = ({
  isLoading,
  columns,
  data,
  operationVendorData,
  getData,
  setOperationVendorData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsOperationVendor,
  pageNumbers,
  selectedJob,
}) => {
  const toast = useToast();
  // State Variables
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [editedVendor, setEditedVendor] = useState({});
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [size, setSize] = useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({});
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...operationVendorData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setOperationVendorData === "function") {
      setOperationVendorData(sortedData);
    }
  };
// Currency API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

   // Customer API
   useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedVendor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // UseEffects
  useEffect(() => {
    setEditedVendor(selectedVendor);
  }, [selectedVendor]);

  const handleOpenModal = async (id) => {
    try {
      const selectedVendor = operationVendorData.find(
        (vendor) => vendor.id === id
      );
      setSelectedVendor(selectedVendor);
      handleFullSizeClick();
      setSelectedRowId(id);
    } catch (error) {
      console.log(error);
    }
    console.log("Vendor Data", operationVendorData);
  };

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedVendor,
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(updateData));
    formData.append("id", selectedVendor.id);
    formData.append("userId", JSON.parse(localStorage.getItem("user")).userId);

    try {
      const { data } = await OperationDocumentAPIInstance.put(
        `singleupdate_operation_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data.message === "Vendor Information Updated successfully.") {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });

        setSelectedVendor((prev) => ({
          ...prev,
          ...editedVendor,
        }));
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.statusText ||
        error.message;

      toast({
        title: errorMessage,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    getData();
  };

  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  return (
    <div>
      {!isLoading ? (
        tableColumnsOperationVendor?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
              <button 
              onClick={toggleIcon}
              checked={selectAllPages}
              onChange={handleSelectAllPages}
              checkBoxAll={checkBoxAll}
              >
                {showFactCheck ? (
                  <MdOutlineFactCheck size={20} />
                ) : (
                  <MdCheckBoxOutlineBlank size={20} />
                )}
              </button> */}
                  </div>
                </th>

                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>
                {tableColumnsOperationVendor.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "BookingNumber" &&
                    column.label !== "JobNumber" &&
                    column.label !== "Invoice_Attachment" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left", width: "fit-content" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.label === "URL_Object" ? (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              Invoice Attachment
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "230px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.key === "Amount"
                                ? "Total Amount" // Handle the "Amount" label separately
                                : column.label
                                    .replace("PaidAmount", "Paid Amount")
                                    .replace("_", " ")
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "200px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>

                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => handleOpenModal(row?.id)}
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>

                    {tableColumnsOperationVendor.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "BookingNumber" &&
                        column.label !== "JobNumber" &&
                        column.label !== "Invoice_Attachment" &&
                        column.isVisible && (
                          <td
                            className="whitespace-nowrap text-left"
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "URL_Object" ? (
                              <a
                                style={{ cursor: "pointer" }}
                                href={row.URL_Object}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row.URL_Object.split("/").pop()}
                              </a>
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsOperationVendor.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}

      <>
        {isOpen && (
          <div className={`singleEditModal`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 400,
              }}
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Vendor Information
              </header>
              <div
                className="closemodule cancelDragAndDrop"
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <form>
                <Badge variant="solid" colorScheme="red" fontSize="12pt">
                  Booking Number : {selectedVendor["BookingNumber"]}
                </Badge>
                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>Vendor Name</FormLabel>
                      {/* <Input
                        type="text"
                        name="Vendor_name"
                        value={editedVendor["Vendor_name"] || ""}
                        onChange={handleChange}
                      /> */}
                       <Select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Vendor_name"
                    value={editedVendor["Vendor_name"] || ""}
                    onChange={handleChange}
                  >
                    <option>Select Vendor</option>
                    {customerData.map((item) => (
                      <option value={item.Company}>{item.Company}</option>
                    ))}
                  </Select>

                      <FormLabel>Currency</FormLabel>
                      <Select
                        name="Currency"
                        value={editedVendor["Currency"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select Currency</option>
                        {currencyData.map((item) => (
                          <option
                            key={item.Currency}
                            value={`${item.Currency}`}
                          >
                            {item.Currency}
                          </option>
                        ))}
                      </Select>

                      <FormLabel>Invoice Attachment</FormLabel>
                      <Input
                        width={280}
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                        sx={{
                          "::file-selector-button": {
                            height: 10,
                            padding: 0,
                            mr: 4,
                            background: "none",
                            border: "none",
                            fontWeight: "bold",
                            color: "red",
                          },
                        }}
                        placeholder="Upload your invoice attachment"
                      />
                    </FormControl>
                  </GridItem>
                  <br />

                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>Payment Status</FormLabel>
                      <Select
                        name="Payment_Status"
                        value={editedVendor["Payment_Status"] || ""}
                        onChange={handleChange}
                      >
                        <option>Select option</option>
                        <option value="Paid">Paid</option>
                        <option value="Not Paid">Not Paid</option>
                        <option value="Partial Payment">Partial Payment</option>
                      </Select>

                      <FormLabel>Amount</FormLabel>
                      <Input
                        type="text"
                        name="Amount"
                        value={editedVendor["Amount"] || ""}
                        onChange={handleChange}
                      />
                      <FormLabel>Paid Amount</FormLabel>
                      <Input
                        name="PaidAmount"
                        type="text"
                        value={editedVendor["PaidAmount"] || ""}
                        placeholder="Enter your paid amount"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>

                {!selectedVendor && (
                  <p>Data not available for the selected Vendor.</p>
                )}
              </form>
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button  colorScheme="red" onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};

export default DraggableVendorOperationTable;
