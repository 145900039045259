import React from "react";
import { useState, useEffect } from "react";
import Axios from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import moment, { utc } from "moment/moment";
import {
  EnquiryAPIInstance,
  AuthAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance.js";


// Internal module import
import "./DraggableEnquiryTable.css";


// Chakara ui modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";


import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
  Table,
  Th,
  Tbody,
  Tr,
  Thead,
  Td,
  TableContainer,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  VStack,
} from "@chakra-ui/react";


// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import Header from "../Header/Header.js";


const DraggableEnquiryTable = ({
  isLoading,
  columns,
  data,
  enquiryData,
  getData,
  setEnquiryData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsEnquiry,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [CountryData, setCountryData] = useState([]);
  const [containers, setContainers] = useState([{}]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [sizeContainer, setSizeContainer] = useState("xl");
  const [sizeAttachment, setSizeAttachment] = useState("xl");
  const [cityData, setCityData] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState({});
  const [editedEnquiry, setEditedEnquiry] = useState({});


  const [Mode, setMode] = useState("");
  // const disableTotalEquipmentValueModes = ["LCL", "Airfreight", "Breakbulk/RORO"];
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedurls, setSelectedurls] = useState([]);
  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
    FormattedContainers: "",
  });


  const [enquiryContainers, setEnquiryContainers] = useState([
    {
      ContainerType: "",
      Quantity: "",
    },
  ]);


  const {
    isOpen: isOpenContainer,
    onOpen: onOpenContainer,
    onClose: onCloseContainer,
  } = useDisclosure();


  const {
    isOpen: isOpenAttachment,
    onOpen: onOpenAttachment,
    onClose: onCloseAttachment,
  } = useDisclosure();


  const handleFullSizeContainer = () => {
    setSizeContainer("xl");
    onOpenContainer();
  };


  const handleFullSizeAttachment = () => {
    setSizeAttachment("xl");
    onOpenAttachment();
  };
  const handleSelectUrl = (event, url) => {
    if (event.target.checked) {
      setSelectedurls((prev) => [...prev, url]);
    } else {
      setSelectedurls((prev) => prev.filter((u) => u !== url));
    }
  };


  const handleViewSelectedPDFs = () => {
    selectedurls.forEach((url) => {
      window.open(url, "_blank");
    });
  };


  const handleCloseConatiner = () => {
    setSelectedRowId(null);
    setIsContainerOpen(false);
    onCloseContainer(true);
  };


  const handleCloseAttachment = () => {
    setSelectedRowId(null);
    setIsAttachmentOpen(false);
    onCloseAttachment(true);
  };
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };


  // UseEffects
  useEffect(() => {
    setEditedEnquiry(selectedEnquiry);
  }, [selectedEnquiry]);


  // useEffect(() => {
  //   const calculateTotalEquipmentValue = () => {
  //     // Filter out containers with invalid or empty values
  //     const filteredContainers = containers.filter(
  //       (container) =>
  //         container.ContainerType &&
  //         container.Quantity &&
  //         !isNaN(parseFloat(container.Quantity))
  //     );


  //     // Construct the formatted string for each valid container
  //     const formattedValues = filteredContainers.map((container) => {
  //       const containerType = container.ContainerType;
  //       const quantity = parseFloat(container.Quantity) || 0;


  //       // Create the formatted string for this container
  //       return `${quantity} X ${containerType}`;
  //     });


  //     // Join all formatted values with a comma
  //     return formattedValues.join(", ");
  //   };


  //   const totalValue = calculateTotalEquipmentValue();
  //   if (totalValue !== formData.TotalEquipmentValue) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       TotalEquipmentValue: totalValue,
  //     }));
  //   }
  // }, [containers]);


  useEffect(() => {
    getCountries(editedEnquiry["Country"]);
  }, [selectedEnquiry, editedEnquiry["Country"]]);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");


        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Equipment API


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get(
          "get_equipment"
        );
        setEquipmentData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Scope API
  const [ScopeData, setScopeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Incoterm Api
  const [IncotermData, setIncotermData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // Pricing API


  const [PricingData, setPricingData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_pricing");
        setPricingData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  //Packing Type API
  const [PackingTypeData, setPackingTypeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackingTypeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //  Mode API
  const [ModeData, setModeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Grade Api
  const [TierData, setTierData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setTierData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  const [CompanyData, setCompanyData] = useState([]);


  //Status API
  const [StatusData, setStatusData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_status");
        setStatusData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  const handleOpenContainer = async (EnquiryNumber) => {
    try {
      const selectedEnquiryData = enquiryData.find(
        (enquiry) => enquiry.EnquiryNumber === EnquiryNumber
      );
      setSelectedEnquiry(selectedEnquiryData);
      handleFullSizeContainer();
      setSelectedRowId(EnquiryNumber);
      console.log(selectedEnquiryData, "Enquiry Data Id");
    } catch (error) {
      console.log(error);
    }
  };


  const handleOpenAttachment = async (EnquiryNumber) => {
    try {
      const selectedEnquiryData = enquiryData.find(
        (enquiry) => enquiry.EnquiryNumber === EnquiryNumber
      );
      setSelectedEnquiry(selectedEnquiryData);
      handleFullSizeAttachment();
      setSelectedRowId(EnquiryNumber);
      console.log(selectedEnquiryData, "Enquiry Data Id");
    } catch (error) {
      console.log(error);
    }
  };


  const handleChangePhone = (value, countryData) => {
    if (countryData && countryData.dialCode) {
      const dialCodeLength = countryData.dialCode.length;
      const Phone = value.slice(dialCodeLength);


      setFormData((prevFormData) => ({
        ...prevFormData,
        Phone,
        PhoneWithCode: value,
        CountryData: countryData,
      }));
    }
  };
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;


      // Update editedEnquiry state
      setEditedEnquiry((prev) => ({
        ...prev,
        [name]: value,
      }));


      if (name === "Mode") {
        // Update selectedEnquiry state with the new Mode value
        setSelectedEnquiry((prev) => ({
          ...prev,
          Mode: value,
        }));


        // Update Equipment field when mode changes
        // setEditedEnquiry((prev) => ({
        //   ...prev,
        //   Equipment: disableTotalEquipmentValueModes.includes(value) ? "-" : "",
        // }));
      }


      if (name === "Country") {
        // Update selectedEnquiry state with new Country value and reset City to empty string
        setSelectedEnquiry((prev) => ({
          ...prev,
          Country: value,
          City: "",
        }));


        // Fetch city data based on the selected Country
        const { data } = await MasterDataAPIInstance.get(
          `get_city?countryData=${value}`
        );


        // Update cityData state with fetched city data
        setCityData(data.data);
      } else if (name === "City") {
        // Update selectedEnquiry state with new City value
        setSelectedEnquiry((prev) => ({
          ...prev,
          City: value,
        }));
      }


      // Update editedEnquiry state with updated flags
      setEditedEnquiry((prev) => ({
        ...prev,
      }));
    } catch (error) {
      // Handle errors if necessary
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const getCountries = async (country) => {
    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  console.log("SL:", selectedEnquiry);
  // Single Update
  const handleSubmitUpdate = async () => {
    console.log("LOG This button click is for handle submit update!!!");


    const updateData = {
      ...editedEnquiry,
      DialCode: formData?.CountryData
        ? formData.CountryData.dialCode
        : editedEnquiry.DialCode, // Use the updated dial code
      Phone: formData?.Phone ? formData.Phone : editedEnquiry.Phone, // Use the updated phone number
    };
    console.log("LOG Updated data", updateData);
    try {
      const { data } = await EnquiryAPIInstance.put(
        `singleupdate_enquiry?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedEnquiry.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );


      if (data?.success) {
        toast({
          title: "Enquiry Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedEnquiry state here
        setSelectedEnquiry((prevSelectedEnquiry) => ({
          ...prevSelectedEnquiry,
          ...editedEnquiry,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };


  //  Function to open the modal and fetch Enquiry data for a particular ID
  const handleOpenModal = async (id, EnquiryNumber) => {
    try {
      const selectedEnquiryData = enquiryData.find(
        (enquiry) => enquiry.id === id
      );
      //const EnquiryNumber = enquiryData((enquiry) => enquiry.id === id);
      setSelectedEnquiry(selectedEnquiryData);
      handleFullSizeClick();
      setSelectedRowId(id);


      const { data } = await MasterDataAPIInstance.get(
        `get_city?countryData=${selectedEnquiryData?.Country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
    console.log(EnquiryNumber);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };


  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );


      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);


  const sortTable = (key) => {
    let newSortOrder = "asc";


    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }


    const sortedData = [...enquiryData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });


    setSortKey(key);
    setSortOrder(newSortOrder);


    if (typeof setEnquiryData === "function") {
      setEnquiryData(sortedData);
    }
  };
  // Grade Map
  const [gradeData, setGradeData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_grade");
        setGradeData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // useEffect(() => {
  //   const calculateTotalEquipmentValue = () => {
  //     // Filter out containers with invalid or empty values
  //     const filteredContainers = containers.filter(
  //       (container) =>
  //         container.Equipment &&
  //         container.Quantity &&
  //         !isNaN(parseFloat(container.Quantity))
  //     );


  //     // Construct the formatted string for each valid container
  //     const formattedValues = filteredContainers.map((container) => {
  //       const containerType = container.Equipment;
  //       const quantity = parseFloat(container.Quantity) || 0;


  //       // Create the formatted string for this container
  //       return `${quantity} X ${containerType}`;
  //     });


  //     // Join all formatted values with a comma
  //     return formattedValues.join(", ");
  //   };


  //   const totalValue = calculateTotalEquipmentValue();
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     TotalEquipmentValue: totalValue,
  //   }));
  // }, [containers, equipmentData]);


  // Industry Map
  const [industryData, setIndustryData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_industry");
        setIndustryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // Tags Map
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_tag");
        setTagData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };


  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };


  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };


  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //console.log(citiesDataSet);
  // const [showFactCheck, setShowFactCheck] = useState(false);
  // console.log(checkBoxSelected, currentPage, activePageCheckBoxSelect);
  console.log("editedEnquiry", editedEnquiry);
  const handleContainerChange = (e, index, field) => {
    const { value } = e.target;
    console.log("value", value, index, field);
    // setEditedEnquiry((prevState) => {
    //   const updatedContainers = [...prevState.Containers];
    //   updatedContainers[index] = {
    //     ...updatedContainers[index],
    //     [field]: value,  // Update the field value
    //   };


    //   return {
    //     ...prevState,
    //     Containers: updatedContainers,  // Update Containers array
    //   };
    // });
    setEditedEnquiry((prevState) => {
      const containers = prevState?.Containers?.split(";") || [];
      const containerAttributes = containers[index]
        ?.split(", ")
        .reduce((acc, curr) => {
          const [key, val] = curr.split(":");
          acc[key.trim()] = val?.trim() || "";
          return acc;
        }, {});
      // Update the specific field
      containerAttributes[field] = value;


      containers[index] = Object.entries(containerAttributes)
        .map(([key, val]) => `${key}: ${val}`)
        .join(", ");


      return {
        ...prevState,
        Containers: containers.join(";"),
      };
    });
  };


  // const addContainer = () => {
  //   setEditedEnquiry((prev) => ({
  //   ...prev,
  //   Containers:
  //     (prev.Containers ? prev.Containers + "; " : "") +
  //     "Equipment: , Quantity: , Dimension: , Weight: ", // New empty container
  //   }));
  // };
  useEffect(() => {
    const calculateFormattedContainers = () => {
      // Extract EnquiryRateContainers from editedRate
      const enquiryContainers =
        editedEnquiry?.Containers || selectedEnquiry?.Containers || "";


      // Parse and filter valid container entries
      const filteredContainers = enquiryContainers
        .split(";") // Split into individual containers
        .filter((rate) => rate.trim() !== "") // Remove empty entries
        .map((rate) => {
          const attributes = rate.split(","); // Split each rate into key-value pairs
          return {
            Equipment: attributes[0]?.split(": ")[1]?.trim() || "", // Extract Equipment
            Quantity: parseFloat(attributes[1]?.split(": ")[1]) || 0, // Extract Quantity
          };
        })
        .filter(
          (container) =>
            container.Equipment &&
            container.Quantity &&
            !isNaN(container.Quantity)
        );


      // Format string: "2 X 20 FR(OW/OH), 1 X 40 OT (OH)"
      const formattedString = filteredContainers
        .map((container) => `${container.Quantity} X ${container.Equipment}`)
        .join(", ");


      return formattedString;
    };


    // Calculate formatted string
    const formattedString = calculateFormattedContainers();


    // Update formData and editedRate dynamically
    setFormData((prevFormData) => ({
      ...prevFormData,
      FormattedContainers: formattedString, // Updates formattedContainers
    }));


    setEditedEnquiry((prev) => ({
      ...prev,
      Equipment: formattedString, // Keep TotalEquipmentValue in sync
    }));
  }, [editedEnquiry?.Containers, selectedEnquiry?.Containers]);


  const addContainer = () => {
    setEditedEnquiry((prev) => ({
      ...prev,
      Containers:
        (prev.Containers ? prev.Containers + "; " : "") +
        "Equipment: , Quantity: , Weight: , Dimensions: , Container_Id: , DeletionFlag:",
    }));
  };


  console.log("setEditedEnquiry", editedEnquiry);
  function removeRowByIndex(data, indexToRemove) {
    console.log(
      "LOG: removeRowByIndex data: ",
      data,
      "\nindex: ",
      indexToRemove
    );


    // Split the data into rows and filter out empty rows
    let rows = data
      ?.split("; ")
      .map((ele) => ele.trim())
      .filter((ele) => ele !== "");
    const removedContainerIds = []; // Array to track removed container IDs


    let rowsAfterSplit = data?.split("; ");
    let rowsAfterTrim = rowsAfterSplit.map((ele) => ele.trim());
    let rowsAfterFilter = rowsAfterTrim.filter((ele) => ele !== "");


    if (indexToRemove >= 0 && indexToRemove < rowsAfterFilter.length) {
      // Check if index is within bounds
      const removedRow = rowsAfterFilter[indexToRemove]; // Get the row being removed


      // Extract Container_ID from the row
      const containerId = removedRow
        .split(", ")
        .find((part) => part.startsWith("Container_Id:"))
        ?.split(":")[1]
        ?.trim();


      if (containerId) {
        removedContainerIds.push({ id: containerId, flag: 1 }); // Add to DeletionFlag
      }


      rowsAfterFilter.splice(indexToRemove, 1); // Remove row at specified index
    }


    return { updatedRows: rowsAfterFilter.join("; "), removedContainerIds }; // Return updated rows and removed container IDs
  }


  const removeContainer = (indexToRemove) => {
    setEditedEnquiry((prev) => {
      // Remove the container
      const { updatedRows, removedContainerIds } = removeRowByIndex(
        prev.Containers,
        indexToRemove
      );


      // Calculate the updated TotalQuoteValue after removing the container
      const containersArray = updatedRows
        .split(";")
        .map((row) => row.trim())
        .filter((row) => row !== "");


      const totalEquipmentValue = containersArray
        .reduce((total, container) => {
          // Split container details into key-value pairs
          const containerDetails = container.split(", ").reduce((acc, curr) => {
            const [key, val] = curr.split(":");
            acc[key.trim()] = val?.trim() || "";
            return acc;
          }, {});


          const quantity = parseFloat(containerDetails["Quantity"]) || 0;
          const equipment = containerDetails["Equipment"] || "";


          if (quantity) {
            total.push(`${quantity} X ${equipment}`);
          }
          return total;
        }, [])
        .join(", ");


      // Format as "Quantity X Equipment" and add to total
      //   return total + (quantity ? `${quantity} X ${equipment}` : "");
      // }, [])
      // .join(", ");


      return {
        ...prev,
        Containers: updatedRows, // Update Containers after removal
        TotalEquipmentValue: totalEquipmentValue, // Update totalEquipmentValue after removal
        DeletionFlag: [...(prev.DeletionFlag || []), ...removedContainerIds], // Append new deletion flags
      };
    });
  };


  return (
    <div>
      {!isLoading ? (
        tableColumnsEnquiry?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {true ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });


                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}
                  </div>
                </th>


                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>


                {tableColumnsEnquiry.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        }
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() =>
                            handleOpenModal(row?.id, row?.EnquiryNumber)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsEnquiry.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "ConversionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">
                                  {" "}
                                  Quotation Converted
                                </Badge>
                              ) : (
                                <Badge colorScheme="red">
                                  {" "}
                                  Quotation Not Converted
                                </Badge>
                              )
                            ) : column.key === "Rates_Status" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">
                                  {" "}
                                  Rate Completed
                                </Badge>
                              ) : (
                                <Badge colorScheme="red">
                                  {" "}
                                  Rate Not Completed
                                </Badge>
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : column.key === "Phone" ? (
                              <>
                                +{row["DialCode"]}-{row["Phone"]}
                              </>
                            ) : column.key === "CargoReadiness" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "Attachments" ? (
                              row[column.key] ? (
                                <Button
                                  colorScheme="teal"
                                  size="sm"
                                  onClick={() => {
                                    console.log("Column Index", columnIndex);
                                    handleOpenAttachment(row?.EnquiryNumber);
                                  }}
                                >
                                  View Attachment
                                </Button>
                              ) : (
                                ""
                              )
                            ) : column.key === "Containers" ? (
                              row[column.key] ? (
                                <Button
                                  colorScheme="teal"
                                  size="sm"
                                  onClick={() => {
                                    console.log("Column Index", columnIndex);
                                    handleOpenContainer(row?.EnquiryNumber);
                                  }}
                                >
                                  View Container
                                </Button>
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsEnquiry.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}


      <>
        {isOpenContainer && (
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                // height: 200
              }}
              cancel=".cancelDragAndDrop"
              allowAnyClick={true}
            >
              <br />


              <header>Container Information</header>
              <div
                className="closemodule cancelDragAndDrop"
                onClick={() => {
                  handleCloseConatiner(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <br />
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Equipment</Th>
                      <Th>Quantity</Th>
                      <Th>Weight</Th>
                      <Th>Dimensions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedEnquiry?.Containers?.split(";").map(
                      (containerInfo, index) => {
                        const containerDetails = containerInfo
                          .trim()
                          ?.split(", ");
                        return (
                          <Tr key={index}>
                            {containerDetails.map((detail, detailIndex) => {
                              const [key, value] = detail?.split(": ");
                              if (
                                key.trim() === "Container_Id" ||
                                key.trim() === "DeletionFlag"
                              ) {
                                // setContainerId(value.trim());
                                return null;
                              }


                              const displayValue = value?.trim() || "-";


                              return (
                                <Td
                                  style={{
                                    whiteSpace: "nowrap",
                                    // paddingLeft: "50px",
                                  }}
                                  key={detailIndex}
                                >
                                  {displayValue}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Rnd>
          </div>
        )}
      </>


      <>
        {isOpenAttachment && (
          <>
            <div style={backdropStyle}></div>
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                }}
                cancel=".cancelDragAndDrop"
                allowAnyClick={true}
              >
                <header>Enquiry Attachment</header>
                <div
                  className="closemodule cancelDragAndDrop"
                  onClick={() => {
                    handleCloseAttachment(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>


                <br />
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Select</Th>
                        <Th>Document ID</Th>
                        <Th>Filename</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedEnquiry?.Attachments?.split(";").map(
                        (attachment, index) => {
                          const attachmentDetails = attachment
                            .trim()
                            ?.split(",");
                          const url = attachmentDetails
                            .find((detail) => detail.includes("URL:"))
                            ?.split(": ")[1];


                          return (
                            <Tr key={index}>
                              <Td>
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleSelectUrl(e, url)}
                                  className="cancelDragAndDrop"
                                />
                              </Td>
                              {attachmentDetails.map((detail, detailIndex) => {
                                const [key, value] = detail?.split(": ");
                                if (
                                  key.trim() === "URL" ||
                                  key.trim() === "DeletionFlag"
                                )
                                  return null;
                                return (
                                  <Td
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                    key={detailIndex}
                                  >
                                    {value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>


                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={handleViewSelectedPDFs}
                >
                  View Selected
                </Button>
              </Rnd>
            </div>
          </>
        )}
      </>


      <>
        {isOpen && (
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 500,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Enquiry Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedEnquiry && Object.keys(selectedEnquiry).length > 0 && (
                <form>
                  <Tabs>
                    <Badge variant="solid" colorScheme="red" fontSize="12pt">
                      Enquiry Number : {selectedEnquiry["EnquiryNumber"]}
                    </Badge>
                  </Tabs>


                  <Tabs w="100%" variant="enclosed">
                    &nbsp;
                    <TabList>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Enquiry
                      </Tab>
                      <Tab _selected={{ color: "white", bg: "#172554" }}>
                        Enquiry Container
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                          <GridItem colSpan={2}>
                            <FormControl>
                              <FormLabel>
                                EnquiryOwner
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="EnquiryOwner"
                                defaultValue={
                                  formData["EnquiryOwner"] ||
                                  selectedEnquiry["EnquiryOwner"]
                                }
                                value={editedEnquiry["EnquiryOwner"] || ""}
                                onChange={handleChange}
                                disabled
                              />
                              <FormLabel>
                                EnquiryOwner Name
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="EnquiryOwnerName"
                                defaultValue={
                                  formData["EnquiryOwnerName"] ||
                                  selectedEnquiry["EnquiryOwnerName"]
                                }
                                value={editedEnquiry["EnquiryOwnerName"] || ""}
                                onChange={handleChange}
                                disabled
                              />
                              <FormLabel>
                                Mode<span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="Mode"
                                defaultValue={
                                  formData["Mode"] || selectedEnquiry["Mode"]
                                }
                                value={editedEnquiry["Mode"] || ""}
                                onChange={handleChange}
                              >
                                {ModeData.map((item) => (
                                  <option value={item.modeId}>
                                    {item.modeId}
                                  </option>
                                ))}
                              </Select>
                              <FormLabel>
                                OriginCountry{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="OriginCountry"
                                defaultValue={
                                  formData["OriginCountry"] ||
                                  selectedEnquiry["OriginCountry"]
                                }
                                value={editedEnquiry["OriginCountry"] || ""}
                                onChange={handleChange}
                              >
                                {CountryData.map((item) => (
                                  <option value={item.Country_name}>
                                    {item.Country_name}
                                  </option>
                                ))}
                              </Select>


                              <FormLabel>
                                DestinationCountry{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="DestinationCountry"
                                defaultValue={
                                  formData["DestinationCountry"] ||
                                  selectedEnquiry["DestinationCountry"]
                                }
                                value={
                                  editedEnquiry["DestinationCountry"] || ""
                                }
                                onChange={handleChange}
                              >
                                {CountryData.map((item) => (
                                  <option value={item.Country_name}>
                                    {item.Country_name}
                                  </option>
                                ))}
                              </Select>
                              <FormLabel>
                                PickUpLocation
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="PickUpLocation"
                                defaultValue={
                                  formData["PickUpLocation"] ||
                                  selectedEnquiry["PickUpLocation"]
                                }
                                value={editedEnquiry["PickUpLocation"] || ""}
                                onChange={handleChange}
                              />
                              <FormLabel>
                                Type of Port{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                defaultValue={
                                  formData["TypeofPort"] ||
                                  selectedEnquiry["TypeofPort"]
                                }
                                value={editedEnquiry["TypeofPort"] || ""}
                                onChange={handleChange}
                                name="TypeofPort"
                              >
                                <option value="">Select Port / Airport</option>
                                <option value="Port">Port</option>
                                <option value="AirPort">Airport</option>
                              </Select>
                              <FormLabel>
                                OriginPortAirport
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="OriginPortAirport"
                                defaultValue={
                                  formData["OriginPortAirport"] ||
                                  selectedEnquiry["OriginPortAirport"]
                                }
                                value={editedEnquiry["OriginPortAirport"] || ""}
                                onChange={handleChange}
                                
                              />
                              <FormLabel>
                                DestinationPortAirport{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="DestinationPortAirport"
                                defaultValue={
                                  formData["DestinationPortAirport"] ||
                                  selectedEnquiry["DestinationPortAirport"]
                                }
                                value={
                                  editedEnquiry["DestinationPortAirport"] || ""
                                }
                                onChange={handleChange}
                              />


                              <FormLabel>
                                DeliveryLocation{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="DeliveryLocation"
                                defaultValue={
                                  formData["DeliveryLocation"] ||
                                  selectedEnquiry["DeliveryLocation"]
                                }
                                value={editedEnquiry["DeliveryLocation"] || ""}
                                onChange={handleChange}
                              />
                              <FormLabel>
                                EnquiryDescription{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="EnquiryDescription"
                                defaultValue={
                                  formData["EnquiryDescription"] ||
                                  selectedEnquiry["EnquiryDescription"]
                                }
                                value={
                                  editedEnquiry["EnquiryDescription"] || ""
                                }
                                onChange={handleChange}
                              />


                              <FormLabel>
                                Incoterm <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="Incoterm"
                                defaultValue={
                                  formData["Incoterm"] ||
                                  selectedEnquiry["Incoterm"]
                                }
                                value={editedEnquiry["Incoterm"] || ""}
                                onChange={handleChange}
                              >
                                {IncotermData.map((item) => (
                                  <option value={item.Incoterm}>
                                    {item.Incoterm}
                                  </option>
                                ))}
                              </Select>


                              <FormLabel>
                                Commodity{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="Commodity"
                                defaultValue={
                                  formData["Commodity"] ||
                                  selectedEnquiry["Commodity"]
                                }
                                value={editedEnquiry["Commodity"] || ""}
                                onChange={handleChange}
                              />
                              <FormLabel>HSCode</FormLabel>
                              <Input
                                type="text"
                                name="HSCode"
                                defaultValue={
                                  formData["HSCode"] ||
                                  selectedEnquiry["HSCode"]
                                }
                                value={editedEnquiry["HSCode"] || ""}
                                onChange={handleChange}
                              />
                              {/* <FormLabel>
                                Dimensions{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="Dimensions"
                                defaultValue={
                                  formData["Dimensions"] ||
                                  selectedEnquiry["Dimensions"]
                                }
                                value={editedEnquiry["Dimensions"] || ""}
                                onChange={handleChange}
                              /> */}
                            </FormControl>
                          </GridItem>
                          <GridItem colStart={4} colEnd={6}>
                            <FormControl>
                              {/* <FormLabel>
                                Weight <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="Weight"
                                defaultValue={
                                  formData["Weight"] ||
                                  selectedEnquiry["Weight"]
                                }
                                value={editedEnquiry["Weight"] || ""}
                                onChange={handleChange}
                              /> */}


                              <FormLabel>
                                Scope <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="Scope"
                                defaultValue={
                                  formData["Scope"] || selectedEnquiry["Scope"]
                                }
                                value={editedEnquiry["Scope"] || ""}
                                onChange={handleChange}
                              >
                                {ScopeData.map((item) => (
                                  <option value={item.Scope}>
                                    {item.Scope}
                                  </option>
                                ))}
                              </Select>


                              <FormLabel>FreeTime </FormLabel>
                              <Input
                                type="text"
                                name="FreeTime"
                                defaultValue={
                                  formData["FreeTime"] ||
                                  selectedEnquiry["FreeTime"]
                                }
                                value={editedEnquiry["FreeTime"] || ""}
                                onChange={handleChange}
                              />
                              {/* <FormLabel>Equipment</FormLabel>
                        <Select
                          className="cancelDragAndDrop"
                          name="Equipment"
                          defaultValue={
                            formData["Equipment"] ||
                            selectedEnquiry["Equipment"]
                          }
                          value={editedEnquiry["Equipment"] || ""}
                          onChange={handleChange}
                        >
                          {equipmentData.map((item) => (
                            <option value={item.ContainerType}>
                              {item.ContainerType}
                            </option>
                          ))}
                        </Select> */}
                              <FormLabel>
                                Stackable{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                defaultValue={
                                  formData["Stackable"] ||
                                  selectedEnquiry["Stackable"]
                                }
                                value={editedEnquiry["Stackable"] || ""}
                                onChange={handleChange}
                                name="Stackable"
                              >
                                <option value="">Select Stackable</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Select>
                              {/* <FormLabel>
                                Rates Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                defaultValue={
                                  formData["Rates_Status"] ||
                                  selectedEnquiry["Rates_Status"]
                                }
                                value={editedEnquiry["Rates_Status"] || ""}
                                onChange={handleChange}
                                name="Rates_Status"
                              >
                                <option value="">Rates Status</option>
                                <option value="Complete">Complete</option>
                                <option value="Incomplete">Incomplete</option>
                              </Select> */}
                              <FormLabel>
                                DGNonDG <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                defaultValue={
                                  formData["DGNonDG"] ||
                                  selectedEnquiry["DGNonDG"]
                                }
                                value={editedEnquiry["DGNonDG"] || ""}
                                onChange={handleChange}
                                name="DGNonDG"
                              >
                                <option value="">Select DGNonDG</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Select>
                              <FormLabel>
                                PackingType{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="PackingType"
                                defaultValue={
                                  formData["PackingType"] ||
                                  selectedEnquiry["PackingType"]
                                }
                                value={editedEnquiry["PackingType"] || ""}
                                onChange={handleChange}
                              >
                                {PackingTypeData.map((item) => (
                                  <option value={item.packingType}>
                                    {item.packingType}
                                  </option>
                                ))}
                              </Select>
                              {/* <FormLabel>
                                PricingTeam{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="PricingTeam"
                                defaultValue={
                                  formData["PricingTeam"] ||
                                  selectedEnquiry["PricingTeam"]
                                }
                                value={editedEnquiry["PricingTeam"] || ""}
                                onChange={handleChange}
                              >
                                {PricingData.map((item) => (
                                  <option value={item.userId}>
                                    {item.pricing_team_name}
                                  </option>
                                ))}
                              </Select> */}
                              <FormLabel>
                                CargoReadiness{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </FormLabel>
                              <Input
                                className="cursor-pointer"
                                type="date"
                                name="Cargo Readiness"
                                defaultValue={
                                  (
                                    editedEnquiry["CargoReadiness"] ||
                                    formData["CargoReadiness"] ||
                                    selectedEnquiry["CargoReadiness"] ||
                                    ""
                                  ).split("T")[0] || ""
                                }
                                onChange={handleChange}
                              />
                              {/* <FormLabel>
                          Attachments <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="AttachmentsID"
                          defaultValue={
                            formData["AttachmentsID"] ||
                            selectedEnquiry["AttachmentsID"]
                          }
                          value={editedEnquiry["AttachmentsID"] || ""}
                          onChange={handleChange}
                          multiple
                        /> */}
                              <FormLabel>
                                ShipperResponsibility{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="ShipperResponsibility"
                                defaultValue={
                                  formData["ShipperResponsibility"] ||
                                  selectedEnquiry["ShipperResponsibility"]
                                }
                                value={
                                  editedEnquiry["ShipperResponsibility"] || ""
                                }
                                onChange={handleChange}
                              />
                              <FormLabel>
                                Status <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="Status"
                                defaultValue={
                                  formData["Status"] ||
                                  selectedEnquiry["Status"]
                                }
                                value={editedEnquiry["Status"] || ""}
                                onChange={handleChange}
                              >
                                {StatusData.map((item) => (
                                  <option value={item.statusId}>
                                    {item.statusId}
                                  </option>
                                ))}
                              </Select>
                              <FormLabel>
                                Tier <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                className="cancelDragAndDrop"
                                name="Tier"
                                defaultValue={
                                  formData["Tier"] || selectedEnquiry["Tier"]
                                }
                                value={editedEnquiry["Tier"] || ""}
                                onChange={handleChange}
                              >
                                {TierData.map((item) => (
                                  <option value={item.Tier}>{item.Tier}</option>
                                ))}
                              </Select>
                              <FormLabel>
                                Company <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                name="Company"
                                defaultValue={
                                  formData["Company"] ||
                                  selectedEnquiry["Company"]
                                }
                                onChange={handleChange}
                              ></Input>


                              <FormLabel>
                                Remarks_sales{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                defaultValue={
                                  formData["Remarks_sales"] ||
                                  selectedEnquiry["Remarks_sales"]
                                }
                                value={editedEnquiry["Remarks_sales"] || ""}
                                onChange={handleChange}
                                name="Remarks_sales"
                              />


                              <FormLabel>
                                Remarks_pricing{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                defaultValue={
                                  formData["Remarks_pricing"] ||
                                  selectedEnquiry["Remarks_pricing"]
                                }
                                value={editedEnquiry["Remarks_pricing"] || ""}
                                onChange={handleChange}
                                name="Remarks_pricing"
                              />
                              {/* <FormLabel>
                          PricingTeam<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["PricingTeam"] ||
                            selectedEnquiry["PricingTeam"]
                          }
                          value={editedEnquiry["PricingTeam"] || ""}
                          onChange={handleChange}
                          name="PricingTeam"
                        /> */}


                              {/* <FormLabel>TotalEquipmentValue </FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Equipment"] ||
                            selectedEnquiry["Equipment"]
                          }
                          value={
                            disableTotalEquipmentValueModes.includes(editedEnquiry["Mode"])
                              ? "No Equipment"
                              : editedEnquiry["Equipment"] || ""
                          }
                          onChange={handleChange}
                          name="Equipment"
                          readOnly={disableTotalEquipmentValueModes.includes(editedEnquiry["Mode"])}
                          
                        /> */}


                              <FormLabel>
                                TotalEquipmentValue{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                type="text"
                                value={formData?.FormattedContainers || ""}
                                onChange={handleChange}
                                name="Equipment"
                                isReadOnly
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <div>
                          <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                            <thead className="text-center bg-blue-950 text-white">
                              <tr>
                                <th className="border border-slate-300 whitespace-nowrap">
                                  Equipment
                                </th>
                                <th
                                  className="border border-slate-300 whitespace-nowrap"
                                  style={{ width: "30%" }}
                                >
                                  Qty
                                </th>
                                <th className="border border-slate-300 whitespace-nowrap">
                                  Dimension
                                </th>
                                <th className="border border-slate-300 whitespace-nowrap">
                                  Weight
                                </th>
                                <th className="border border-slate-300 whitespace-nowrap">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {editedEnquiry?.Containers?.split(";")?.map(
                                (container, index) => {
                                  const containerAttributes =
                                    container?.split(", ");
                                  const isAirfreightOrLCL = [
                                    "Airfreight",
                                    "LCL",
                                    "Breakbulk/RORO",
                                  ].includes(editedEnquiry?.Mode);


                                  return (
                                    <tr key={index}>
                                      {/* Equipment Column */}
                                      <td>
                                        {isAirfreightOrLCL ? (
                                          <span className="text-black dark:text-white">
                                            -
                                          </span>
                                        ) : (
                                          <select
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                            name={`Equipment-${index}`}
                                            value={
                                              editedEnquiry[
                                                `Equipment-${index}`
                                              ] ||
                                              containerAttributes[0]?.split(
                                                ": "
                                              )[1]
                                            }
                                            disabled={isAirfreightOrLCL} // Disable for specific modes
                                            onChange={(e) =>
                                              handleContainerChange(
                                                e,
                                                index,
                                                "Equipment"
                                              )
                                            }
                                          >
                                            <option value="">
                                              Select Equipment
                                            </option>
                                            {equipmentData.map((item, idx) => (
                                              <option
                                                key={idx}
                                                value={item.ContainerType}
                                              >
                                                {item.ContainerType}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </td>


                                      {/* Quantity Column */}
                                      <td className="text-center">
                                        {isAirfreightOrLCL ? (
                                          <span className="text-black dark:text-white">
                                            -
                                          </span>
                                        ) : (
                                          <input
                                            className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                            type="text"
                                            name={`Quantity-${index}`}
                                            value={
                                              editedEnquiry[
                                                `Quantity-${index}`
                                              ] ||
                                              containerAttributes[1]?.split(
                                                ": "
                                              )[1]
                                            }
                                            disabled={isAirfreightOrLCL} // Disable for specific modes
                                            onChange={(e) =>
                                              handleContainerChange(
                                                e,
                                                index,
                                                "Quantity"
                                              )
                                            }
                                            placeholder="Enter Quantity"
                                          />
                                        )}
                                      </td>


                                      {/* Dimension Column */}
                                      <td className="text-center">
                                        <input
                                          className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                          type="text"
                                          name={`Dimensions-${index}`}
                                          value={
                                            editedEnquiry[
                                              `Dimensions-${index}`
                                            ] ||
                                            containerAttributes[3]?.split(
                                              ": "
                                            )[1]
                                          }
                                          onChange={(e) =>
                                            handleContainerChange(
                                              e,
                                              index,
                                              "Dimensions"
                                            )
                                          }
                                          placeholder="Enter Dimension"
                                        />
                                      </td>


                                      {/* Weight Column */}
                                      <td className="text-center">
                                        <input
                                          className="text-black bg-white dark:bg-gray-950 dark:text-white border border-slate-300 px-2 py-1"
                                          type="text"
                                          name={`Weight-${index}`}
                                          value={
                                            editedEnquiry[`Weight-${index}`] ||
                                            containerAttributes[2]?.split(
                                              ": "
                                            )[1]
                                          }
                                          onChange={(e) =>
                                            handleContainerChange(
                                              e,
                                              index,
                                              "Weight"
                                            )
                                          }
                                          placeholder="Enter Weight"
                                        />
                                      </td>


                                      {/* Actions Column */}
                                      <td>
                                        {editedEnquiry?.Containers?.split(";")
                                          ?.length > 1 && (
                                          <BiSolidMinusCircle
                                            className="cursor-pointer"
                                            size={30}
                                            style={{
                                              marginLeft: "5px",
                                              color: "red",
                                            }}
                                            onClick={() =>
                                              removeContainer(index)
                                            }
                                          />
                                        )}


                                        <IoMdAddCircle
                                          className="cursor-pointer"
                                          size={30}
                                          style={{
                                            marginLeft: "5px",
                                            color: "green",
                                          }}
                                          onClick={addContainer}
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>


                  <br></br>


                  {!selectedEnquiry && (
                    <p>Data not available for the selected enquiry.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};
export default DraggableEnquiryTable;