import { React, useState, useEffect } from "react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Badge,
  Stack,
} from "@chakra-ui/react";
import { LuSlidersHorizontal } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";

const Operationsidebar = ({ selectedJob }) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleOpenOperationDocs = () => {
    onOpen();
  };
  const handleCloseOperationDocs = () => {
    onClose();
  };

  const steps = [
    { title: "First", description: "Contact Info" },
    { title: "Second", description: "Date & Time" },
    { title: "Third", description: "Select Rooms" },
  ];

  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  return (
    <>
      <LuSlidersHorizontal
        onClick={handleOpenOperationDocs}
        size={25}
        style={{
          cursor: "pointer",
          display: "inline-flex",
          float: "inline-start",
          marginTop: "10px",
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={handleCloseOperationDocs}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Operation & Docs</DrawerHeader>

          <DrawerBody>
            <button
              onClick={() => {
                navigate("/opertion_docs", {
                  state: { selectedJob: selectedJob },
                });
              }}
              className={`block py-2 pr-4 pl-3 ${
                currentTab === "opertion_docs" ? "text-blue" : "text-gray-700"
              } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
            >
              Document
            </button>
            <br />

            <button
              onClick={() => {
                navigate("/opertion_vendor", {
                  state: { selectedJob: selectedJob },
                });
              }}
              className={`block py-2 pr-4 pl-3 ${
                currentTab === "opertion_vendor" ? "text-blue" : "text-gray-700"
              } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
            >
              Vendor
            </button>
            <br />

            <button
              onClick={() => {
                navigate("/addchecklist", {
                  state: { selectedJob: selectedJob },
                });
              }}
              className={`block py-2 pr-4 pl-3 ${
                currentTab === "addchecklist" ? "text-blue" : "text-gray-700"
              } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
            >
              Check List
            </button>
            <br />

            <button
              onClick={() => {
                navigate("/opertion_activitylog", {
                  state: { selectedJob: selectedJob },
                });
              }}
              className={`block py-2 pr-4 pl-3 ${
                currentTab === "opertion_activitylog"
                  ? "text-blue"
                  : "text-gray-700"
              } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
            >
              Activity Log
            </button>
            <br />
            {/* <button
              style={{ backgroundColor: "red", color: "whitesmoke" }}
              className={`block py-2 pr-4 pl-3 ${
                currentTab === "" ? "text-blue" : "text-gray-700"
              } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
            >
              Back
            </button> */}
            <Stack direction="row">
              <Badge
                size="100px"
                cursor="pointer"
                colorScheme="red"
                onClick={() => {
                  navigate(`/editjob/${selectedJob.JobNumber}`);
                }}
              >
                Back
              </Badge>
            </Stack>

            {/* <Link>Proforma Invoices</Link> */}
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Operationsidebar;
