import { React, useState, useEffect, useRef } from "react";
import "./DuplicateBooking.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  FormControl,
  Input,
  Select,
  Flex,
  RadioGroup,
  Radio,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import Axios, { BookingAPIInstance } from "../../api-instance.js";
import { CgClose } from "react-icons/cg";
import { Rnd } from "react-rnd";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { BiSolidMinusCircle } from "react-icons/bi";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";

const DuplicateBooking = ({
  selectedRows,
  setSelectedRows,
  selectedData,
  setSelectedData,
  onApply,
  setDuplicateBookingState,
  setFormData,
  formData,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const searchRef = useRef(null);  
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState("");
  const [checkboxFilter, setCheckboxFilter] = useState("AND");
  const [searchTerm, setSearchTerm] = useState("");
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const [filterConditions, setFilterConditions] = useState([
    { criteria: "", condition: "", value: "" },
  ]);
  const [filterRows, setFilterRows] = useState([
    { criteria: "", condition: "", value: "" },
  ]);

  const style = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    // overflowX: "auto",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "199",
  };

  const closeModal = {
    position: "absolute",
    top: "5%",
    right: "5%",
    cursor: "pointer",
  };
  const handleModalClose = () => {
    onClose();
  };



  useEffect(() => {
    getData();
  }, []);
  
  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await BookingAPIInstance.get(
        `get_duplicate_booking?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log("Data received:", data);
      if (data.data?.length === 0) {
        setData([]);
        setFilteredData([]);
      } else {
        setData(data.data);
        setFilteredData(data.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        setIsUnauthorized(true);
      } else {
        toast({
          title:
            error?.response?.data?.message ||
            error?.response?.statusText ||
            error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    const searchTermLower = searchRef.current.value.toLowerCase ();
    const filtered = data.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTermLower)
      )
    );
    setFilteredData(filtered.length > 0 ? filtered : data);
  };

  const handleSubmitFilter = () => {
    const filtered = data.filter((item) => {
      return filterRows.every((filterRow) => {
        const { criteria, condition, value } = filterRow;
        if (!criteria || !condition || !value) return true; // Skip if any field is empty
        switch (condition) {
          case "is":
            return item[criteria] === value;
          case "contains":
            return item[criteria]?.toString().toLowerCase().includes(value.toLowerCase());
          case "startsWith":
            return item[criteria]?.toString().toLowerCase().startsWith(value.toLowerCase());
          case "endsWith":
            return item[criteria]?.toString().toLowerCase().endsWith(value.toLowerCase());
          default:
            return true;
        }
      });
    });
    setFilteredData(filtered.length > 0 ? filtered : data);
  };

  const handleClearFilters = () => {
    setFilterRows([{ criteria: "", condition: "", value: "" }]);
    setFilteredData(data);
  };

  const handleCheckboxChange = (value) => {
    setCheckboxFilter(value);
  };

  const addFilterRow = () => {
    setFilterRows([...filterRows, { criteria: "", condition: "", value: "" }]);
  };

  const removeFilterRow = (index) => {
    if (filterRows.length > 1) {
      const updatedFilterRows = filterRows.filter((_, i) => i !== index);
      setFilterRows(updatedFilterRows);
    }
  };

  const handleFilterValueChange = (e, index) => {
    const { value } = e.target;
    const updatedConditions = [...filterRows];
    updatedConditions[index].value = value;
    setFilterRows(updatedConditions);
  };

  const handleCriteriaChange = (e, index) => {
    const updatedConditions = [...filterRows];
    updatedConditions[index].criteria = e.target.value;
    setFilterRows(updatedConditions);
  };

  const handleConditionChange = (e, index) => {
    const updatedConditions = [...filterRows];
    updatedConditions[index].condition = e.target.value;
    setFilterRows(updatedConditions);
  };

  const handleRowCheckboxClick = (id, rowData) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });

    if (rowData) {
      // Ensure rowData is defined
      setSelectedData((prevSelectedData) => {
        if (selectedRows.includes(id)) {
          return prevSelectedData.filter((row) => row?.id !== id); // Check row?.id
        } else {
          return [...prevSelectedData, rowData];
        }
      });
    }
    setFormData([]);
  };
  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);

  const handleAllCheckboxClick = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
      setSelectedData([]);
      setFormData([]);
    } else {
      setFormData(data);
      setSelectedRows(data.map((item) => item.id));
      setSelectedData(data);
    }
  };
  console.log("Selected rows:", selectedRows);
  console.log("Selected data:", selectedData);

  const handleApplyClick = () => {
    if (selectedData.length > 0) {
      if (selectedData.length <= 1) {
        onApply(selectedData);
        //setSelectedRows([]);
        onClose();
        navigate("/addbooking", { state: { selectedData: selectedData } });
      } else {
        toast({
          title: "Please select a maximum of 1 booking.",
          position: "bottom-center",
          isClosable: true,
          status: "warning",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "Please select at least one row.",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleCancelClick = () => {
    setSelectedRows([]);
    setSelectedData([]);
    setFormData([{}]);
    setData([]);
    onApply();
    setDuplicateBookingState([]);
    getData();
    onClose();
  };
  return (
    <>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
        onClick={onOpen}
      >
        Copy Booking
      </button>

      {isOpen && (
        <>
          <div style={backdropStyle}></div>
          <div className={`UnallocateModal `}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 950,
              }}
              cancel="X"
              allowAnyClick={true}
            >
             <header
                style={{ fontSize: "20px", fontWeight: "bold", display:"flex" }}
              >
                Unassigned Booking  &nbsp;&nbsp;
              
                
                <HiOutlineAdjustmentsHorizontal
                  size={20}
                  style={{marginTop: "10px",cursor:"pointer"}}
                  
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                />
              
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  className="ml-20 relative mx-auto text-gray-600"
                  style={{ float: "right" }}
                >
                  <input
                    className="border-2 border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                    ref={searchRef}
                    type="search"
                    name="search"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </div>
              </header>
              <br/>
              <div onClick={handleModalClose} style={closeModal}>
                <CgClose />
              </div>
              <br />

              <div
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
              >
                <div className="table-container">
                  {!isLoading ? (
                    filteredData.length !== 0 ? (
                      <table className="table-auto border-collapse border w-full">
                        <thead className="border border-slate-300 whitespace-nowrap text-left">
                          <tr>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              <input
                                type="checkbox"
                                style={{
                                  cursor: "pointer",
                                  transform: "scale(1.3)",
                                  marginRight: "5px",
                                }}
                                checked={selectedRows.length === data.length}
                                onChange={handleAllCheckboxClick}
                              />
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Booking Number
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Product
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              POL
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              POD
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Cargo
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              ETD
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              ETA
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Vessel Name & Voyage
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Company Name
                            </th>
                            <th className="border border-slate-300 whitespace-nowrap text-center">
                              Sales
                            </th>
                            {/* <table className="table-auto border-collapse border w-full">
                              <tr>
                      <th colspan="4" >Container Details</th>
                    </tr>
                              <tr>
                                <th className="whitespace-nowrap container-table-head">
                                  Container Type
                                </th>
                                <th>Quantity</th>
                                <th>Weight</th>
                                <th>Dimensions</th>
                              </tr>
                            </table> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className="whitespace-nowrap text-center">
                                <input
                                  type="checkbox"
                                  style={{
                                    cursor: "pointer",
                                    transform: "scale(1.3)",
                                    marginRight: "5px",
                                  }}
                                  checked={selectedRows.includes(row?.id)}
                                  onChange={() =>
                                    handleRowCheckboxClick(row?.id, row)
                                  } // Pass row along with id
                                />
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.BookingNumber}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Product}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.POL}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.POD}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Cargo}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {new Date(row.ETD).toLocaleDateString()}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {new Date(row.ETA).toLocaleDateString()}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.VesselNameVoyage}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.CompanyName}
                              </td>
                              <td className="whitespace-nowrap text-center">
                                {row.Sales}
                              </td>
                              {/* <td className="flex-nowrap text-center">
                                {row.Containers.split(";").map(
                                  (containerInfo, index) => {
                                    const containerDetails = containerInfo
                                      .trim()
                                      .split(", ");
                                    return (
                                      <tr key={index}>
                                        {containerDetails.map(
                                          (detail, detailIndex) => {
                                            const [key, value] =
                                              detail.split(": ");
                                            if (key.trim() === "Container_ID") {
                                              // setContainerId(value.trim());
                                              return null;
                                            }
                                            return (
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  paddingLeft: "50px",
                                                  justifyContent:
                                                    "space-evenly",
                                                }}
                                                key={detailIndex}
                                              >
                                                {value}
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h1 style={{ textAlign: "center", color: "black" }}>
                        No Data Found!
                      </h1>
                    )
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
              <div
                style={{
                  float: "right",
                  display: "block",
                  marginTop: "10px",
                }}
              >
                <Button colorScheme="green" onClick={handleApplyClick} mr={3}>
                  Apply
                </Button>
                <Button colorScheme="red" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </div>
            </Rnd>
          </div>
          {isOpenFilter && (
            <>
              <div style={backdropStyle}></div>
              <div className={`FilterModal`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                  }}
                  cancel=".cancelDragAndDrop"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                   Criteria
                  </header>
                  <div
                    className="closemodule"
                    onClick={() => {
                      onCloseFilter(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl className="closemodule cancelDragAndDrop">
                    <Flex direction="column">
                      {filterRows.map((filterRow, index) => (
                        <Flex key={index} mb={2} alignItems="center">
                          <Select
                            placeholder="Select Criteria"
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            value={filterRow.criteria}
                            onChange={(e) => handleCriteriaChange(e, index)}
                          >
                            <option value="BookingNumber">
                              Booking Number
                            </option>
                            <option value="Product">Product</option>  
                            <option value="CompanyName">Company Name</option>
                            <option value="Sales">Sales</option>
                          </Select>&nbsp;&nbsp;

                          <Select
                            placeholder="Select Condition"
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            value={filterRow.condition}
                            onChange={(e) => handleConditionChange(e, index)}
                          >
                            <option value="is">is</option>
                            <option value="contains">contains</option>
                            <option value="startsWith">starts with</option>
                            <option value="endsWith">ends with</option>
                          </Select> &nbsp;&nbsp;

                          <Input
                            type="text"
                            placeholder="Enter you Value"
                            value={filterRow.value}
                            onChange={(e) => handleFilterValueChange(e, index)}
                          />&nbsp;&nbsp;

                          <IoMdAddCircle
                            className="cursor-pointer closemodule cancelDragAndDrop"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={addFilterRow}
                          />
                          {filterRows.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer closemodule cancelDragAndDrop"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </Flex>
                  </FormControl>
                  <div
                    className="closemodule cancelDragAndDrop"
                    style={{
                      float: "right",
                      display: "block",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <RadioGroup
                      style={{ flex: 1 }}
                      value={checkboxFilter}
                      onChange={handleCheckboxChange}
                    >
                      <Stack direction="row">
                        <Radio value="OR">Any</Radio>
                        <Radio value="AND">All</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DuplicateBooking;
