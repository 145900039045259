/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  AuthAPIInstance,
  BookingAPIInstance,
  JobAPIInstance,
  MasterDataAPIInstance,
} from "../../../api-instance.js";
import "../../../assets/css/container.css";
import {
  DraggableJobTable,
  Header,
  OperationAddOps,
  OperationSidebar,
  OperationTransferData,
  OperationTransferUser,
  Table,
  UnAuthorizedModal,
} from "../../../components";
// Internal modules
import "./Job.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { filter, useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Text, Tooltip } from "@chakra-ui/react";
import { ModalOverlay, Button } from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

// Icons import

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { RiFileTransferLine } from "react-icons/ri";
import { MdOutlineDownloadForOffline } from "react-icons/md";

import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal, TbUserEdit } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import UnallocatedJob from "../../Addjob/UnallocatedJob.js";

const Job = () => {
  // State Variables
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();

  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();

  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();

  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();

  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();

  const [overlay, setOverlay] = useState(<OverlayOne />);
  const cancelRef = useRef();
  const searchRef = useRef(null);  
  const dropdownRef = useRef(null);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [bookingWarning, setBookingWarning] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);

  // Unallcoted
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [unallocatedBookingState, setUnallocatedBookingState] = useState([]);
  const [formData, setFormData] = useState([
    {
      Product: "",
      ShipmentHandledBy: "",
      JobStatus: "",
      JobNumber: "",
      JobInfono: "",
      DateOfBusinessConfirmed: "",
      POL: "",
      POD: "",
      Shipper: "",
      Consignee: "",
      Client: "",
      Agent: "",
      QuotationNumber: "",
      Scope: "",
      Region: "",
      Sales: "",
      Incoterm: "",
      Cargo: "",
      CargoNature: "",
      HsCode: "",
      ShippingLine: "",
      VesselNameVoyage: "",
      CyOpen: "",
      SICutOff: "",
      VGMCutOff: "",
      CYCutOff: "",
      CustomsCuttOff: "",
      OOGCuttOff: "",
      ETD: "",
      ETA: "",
      FreeDaysAtOrigin: "",
      FreeDaysAtDestination: "",
      Freight: "",
      MBLType: "",
      HBLType: "",
      LinerBookingNumber: "",
      LinerRefNumber: "",
      MBLNumber: "",
      HBLNumber: "",
      CargoPickUpDate: "",
      EmptyPickUpDate: "",
      Stuffingdate: "",
      LadenGateIndate: "",
      VesselArrivalDate: "",
      OperationTranferDeadline: "",
      Vendor: "",
      Detail: "",
      Amount: "",
      Currency: "",
      // ContainerType: "",
      // Quantity: "",
      // Weight: "",
      // Dimensions: "",
      ContainerNumber: "",
      SealNumber: "",
      No_Of_Pkge: "",
      Type_Of_Pkge: "",
      NtWeight: "",
      GrWeight: "",
      Volume: "",
      TareWeight: "",
      VGM: "",
      VGMMethod: "",
      Detention: "",
      Demurrage: "",
      Detention_By_Liner: "",
      Demurage_By_Liner: "",
      DetentionNo: "",
      DemurrageNo: "",
      Port_Storage: "",
      PortStorage: "",
      Port_Storage_Days: "",
      Current_Status: "",
      Notes: "",
      Containers: "ContainerType: , Quantity: , Weight: , Dimensions: ;",
      Operations: [
        {
          ContainerNumber: "",
          SealNumber: "",
          No_Of_Pkge: "",
          Type_Of_Pkge: "",
          NtWeight: "",
          GrWeight: "",
          Volume: "",
          TareWeight: "",
          VGM: "",
          VGMMethod: "",
        },
      ],
    },
  ]);
  useEffect(() => {
    if (selectedRows.length !== 0) {
      setFormData([]);
      setFormData(selectedData);
    }
  }, [selectedRows]);

  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
//backdrop and outside click close
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionOpen(false);
    }
  };
 
  useEffect(() => {
    if (isActionOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActionOpen]);
  // end of backdrop and outside click close
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    JobNumber: true,
    BookingNumber: true,
    Product: true,
    PlaceOfReceipt: true,
    PlaceOfDelivery: true,
    HandleBy: true,
    Shipper: true,
    POL: true,
    POD: true,
    Client: true,
    Equipment: true,
    Job_Status: true,
    DOBC: true,
    Consignee: true,
    Agent: true,
    QuotationNumber: true,
    Scope: true,
    Region: true,
    Incoterm: true,
    Sales: true,
    Cargo: true,
    Cargo_Nature: true,
    HS_Code: true,
    Shipping_Line: true,
    VesselNameVoyage: true,
    CY_Open: true,
    SI_Cutoff: true,
    VGM_Cutoff: true,
    CY_Cutoff: true,
    Customs_Cutoff: true,
    OOG_Cutoff: true,
    ETD: true,
    ETA: true,
    days_at_origin: true,
    days_at_destiantion: true,
    Freight: true,
    MBL_Type: true,
    HBL_Type: true,
    Liner_Bookingno: true,
    MBLNo: true,
    HBLNo: true,
    Cargo_Pickup_Date: true,
    Empty_Pickup_Date: true,
    Stuffing_Date: true,
    Laden_Gate_In_Date: true,
    Vessel_Arrival_Date: true,
    Operation_Transfer_Deadline: true,
    Detention: true,
    Demurrage: true,
    Detention_By_Liner: true,
    Demurage_By_Liner: true,
    DetentionNo: true,
    DemurrageNo: true,
    Port_Storage: true,
    PortStorage: true,
    Port_Storage_Days: true,
    Current_Status: true,
    Notes: true,
    add_costid: false,
    // Vendor: false,
    // Detail: false,
    // Amount: false,
    Vendors: true,
    Containers: true,
    JobOwner: true,
    CreatedBy: true,
    DeletionFlag: true,
    ConversionFlag: true,
  });
  const [tableColumnsJob, settableColumnsJob] = useState([]);

  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes

  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsJob((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsJob.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsJob", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await JobAPIInstance.get(
        `get_job?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeleted=${isdelete}&isConverted=${isconvert}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setJobData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "JobStatus"
              ? false
              : item === "Shipper"
              ? false
              : item === "DateOfBussinessConfirmed"
              ? false
              : item === "Consignee"
              ? false
              : item === "Agent"
              ? false
              : item === "QuoteNumber"
              ? false
              : item === "Scope"
              ? false
              : item === "Region"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Cargo"
              ? false
              : item === "Commodity"
              ? false
              : item === "CargoNature"
              ? false
              : item === "PackingType"
              ? false
              : item === "ShipmentLineNVOCCLCL"
              ? false
              : item === "SICutOff"
              ? false
              : item === "CYOpen"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "CustomsCutOff"
              ? false
              : item === "OOGCutOff"
              ? false
              : item === "Freight"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "FreeDaysAtOrigin"
              ? false
              : item === "FreeDaysAtDestination"
              ? false
              : item === "MBLType"
              ? false
              : item === "HBLType"
              ? false
              : item === "LinerBookingNumber"
              ? false
              : item === "MBLNumber"
              ? false
              : item === "HBLNumber"
              ? false
              : item === "CargoPickUpDate"
              ? false
              : item === "StuffingDate"
              ? false
              : item === "EmptyPickUpDate"
              ? false
              : item === "LadenGateInDate"
              ? false
              : item === "Detention"
              ? false
              : item === "Demurrage"
              ? false
              : item === "DetentionByLiner"
              ? false
              : item === "DemurageByPort"
              ? false
              : item === "NoOfDetentionOccuring"
              ? false
              : item === "NoOfDemurrageOccuring"
              ? false
              : item === "CurrentStatus"
              ? false
              : item === "OperationTransferDeadline"
              ? false
              : item === "Notes"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setJobData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("jobfilter")?.length === 0) {
      return setJobData([]);
    }

    if (window.localStorage.getItem("jobfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };

  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await JobAPIInstance.post(
        `delete_job?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? jobData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        if (data?.data.message === "BookingNumber exists in job table") {
          const jobs = data?.data.data;
          // const bookings = data?.data.data;
          if (jobs.length === 1) {
            const bookingNumber = jobs[0].BookingNumber;
            const jobNumber = jobs[0].JobNumber;
            setBookingWarning(
              `Job ${jobNumber} exists for Booking ${bookingNumber}, do you want to delete the corresponding job!`
            );
          } else {
            const jobCount = jobs.length;
            setBookingWarning(
              `${jobCount} job booking exist, do you want to delete them!`
            );
          }
          return;
        }
        toast({
          title: `Job Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("jobfilter");
      localStorage.removeItem("jobfilterConditions");
      localStorage.removeItem("jobfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("jobfilter")) {
      const storedData = JSON.parse(localStorage.getItem("jobfilter") || []);

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? jobData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("jobfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("jobfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };

  const handleSubmitDeleteWithBooking = async (e) => {
    try {
      if (e.target.value === "WITHOUT") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await JobAPIInstance.post(
          `delete_job_with_booking?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITHOUT`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? jobData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: `Job Deleted Successfully!`,
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      } else if (e.target.value === "WITH") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await JobAPIInstance.post(
          `delete_job_with_booking?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITH`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? jobData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: "Job Deleted Successfully!",
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("jobfilter");
      localStorage.removeItem("jobfilterConditions");
      localStorage.removeItem("jobfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("jobfilter")) {
      const storedData = JSON.parse(localStorage.getItem("jobfilter") || []);

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? jobData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("jobfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("jobfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("jobfilterRows"))
      ? JSON.parse(localStorage.getItem("jobfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("jobfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("jobfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "jobfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "jobfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await JobAPIInstance.post(
        `job_mass_filter?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}&isConverted=${isconvert}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("jobfilter", JSON.stringify([]));
        return setJobData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "JobStatus"
              ? false
              : item === "Shipper"
              ? false
              : item === "DateOfBussinessConfirmed"
              ? false
              : item === "Consignee"
              ? false
              : item === "Agent"
              ? false
              : item === "QuoteNumber"
              ? false
              : item === "Scope"
              ? false
              : item === "Region"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Cargo"
              ? false
              : item === "Commodity"
              ? false
              : item === "CargoNature"
              ? false
              : item === "PackingType"
              ? false
              : item === "ShipmentLineNVOCCLCL"
              ? false
              : item === "SICutOff"
              ? false
              : item === "CYOpen"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "CustomsCutOff"
              ? false
              : item === "OOGCutOff"
              ? false
              : item === "Freight"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "FreeDaysAtOrigin"
              ? false
              : item === "FreeDaysAtDestination"
              ? false
              : item === "MBLType"
              ? false
              : item === "HBLType"
              ? false
              : item === "LinerBookingNumber"
              ? false
              : item === "MBLNumber"
              ? false
              : item === "HBLNumber"
              ? false
              : item === "CargoPickUpDate"
              ? false
              : item === "StuffingDate"
              ? false
              : item === "EmptyPickUpDate"
              ? false
              : item === "LadenGateInDate"
              ? false
              : item === "Detention"
              ? false
              : item === "Demurrage"
              ? false
              : item === "DetentionByLiner"
              ? false
              : item === "DemurageByPort"
              ? false
              : item === "NoOfDetentionOccuring"
              ? false
              : item === "NoOfDemurrageOccuring"
              ? false
              : item === "CurrentStatus"
              ? false
              : item === "OperationTransferDeadline"
              ? false
              : item === "Notes"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setJobData(filteredData);

      localStorage.setItem("jobfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
      setCurrentPage(1);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("jobfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setJobData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "JobStatus"
              ? false
              : item === "Shipper"
              ? false
              : item === "DateOfBussinessConfirmed"
              ? false
              : item === "Consignee"
              ? false
              : item === "Agent"
              ? false
              : item === "QuoteNumber"
              ? false
              : item === "Scope"
              ? false
              : item === "Region"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Cargo"
              ? false
              : item === "Commodity"
              ? false
              : item === "CargoNature"
              ? false
              : item === "PackingType"
              ? false
              : item === "ShipmentLineNVOCCLCL"
              ? false
              : item === "SICutOff"
              ? false
              : item === "CYOpen"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "CustomsCutOff"
              ? false
              : item === "OOGCutOff"
              ? false
              : item === "Freight"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "FreeDaysAtOrigin"
              ? false
              : item === "FreeDaysAtDestination"
              ? false
              : item === "MBLType"
              ? false
              : item === "HBLType"
              ? false
              : item === "LinerBookingNumber"
              ? false
              : item === "MBLNumber"
              ? false
              : item === "HBLNumber"
              ? false
              : item === "CargoPickUpDate"
              ? false
              : item === "StuffingDate"
              ? false
              : item === "EmptyPickUpDate"
              ? false
              : item === "LadenGateInDate"
              ? false
              : item === "Detention"
              ? false
              : item === "Demurrage"
              ? false
              : item === "DetentionByLiner"
              ? false
              : item === "DemurageByPort"
              ? false
              : item === "NoOfDetentionOccuring"
              ? false
              : item === "NoOfDemurrageOccuring"
              ? false
              : item === "CurrentStatus"
              ? false
              : item === "OperationTransferDeadline"
              ? false
              : item === "Notes"
              ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setJobData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("jobfilterConditions");
    window.localStorage.removeItem("jobfilter");
    window.localStorage.removeItem("jobfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = jobData[0] && Object?.keys(jobData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("jobfilter")?.length === 0) {
      return setJobData([]);
    }

    if (window.localStorage.getItem("jobfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await JobAPIInstance.put(
        `update_job?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? jobData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Job Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setCheckBoxSelected([]);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setCheckBoxSelected([]);
    }
    console.log("selectedOption:", selectedOption);
    console.log("updatedValue:", updatedValue);
  };

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await JobAPIInstance.get(
      `search_job?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}&isConverted=${isconvert}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setJobData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "JobStatus"
            ? false
            : item === "Shipper"
            ? false
            : item === "DateOfBussinessConfirmed"
            ? false
            : item === "Consignee"
            ? false
            : item === "Agent"
            ? false
            : item === "QuoteNumber"
            ? false
            : item === "Scope"
            ? false
            : item === "Region"
            ? false
            : item === "Incoterm"
            ? false
            : item === "Cargo"
            ? false
            : item === "Commodity"
            ? false
            : item === "CargoNature"
            ? false
            : item === "PackingType"
            ? false
            : item === "ShipmentLineNVOCCLCL"
            ? false
            : item === "SICutOff"
            ? false
            : item === "CYOpen"
            ? false
            : item === "VGMCutOff"
            ? false
            : item === "CYCutOff"
            ? false
            : item === "CustomsCutOff"
            ? false
            : item === "OOGCutOff"
            ? false
            : item === "Freight"
            ? false
            : item === "ETD"
            ? false
            : item === "ETA"
            ? false
            : item === "FreeDaysAtOrigin"
            ? false
            : item === "FreeDaysAtDestination"
            ? false
            : item === "MBLType"
            ? false
            : item === "HBLType"
            ? false
            : item === "LinerBookingNumber"
            ? false
            : item === "MBLNumber"
            ? false
            : item === "HBLNumber"
            ? false
            : item === "CargoPickUpDate"
            ? false
            : item === "StuffingDate"
            ? false
            : item === "EmptyPickUpDate"
            ? false
            : item === "LadenGateInDate"
            ? false
            : item === "Detention"
            ? false
            : item === "Demurrage"
            ? false
            : item === "DetentionByLiner"
            ? false
            : item === "DemurageByPort"
            ? false
            : item === "NoOfDetentionOccuring"
            ? false
            : item === "NoOfDemurrageOccuring"
            ? false
            : item === "CurrentStatus"
            ? false
            : item === "OperationTransferDeadline"
            ? false
            : item === "Notes"
            ? false
            : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setJobData(filteredData);
    setCurrentPage(1);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(jobData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsJob")) {
      settableColumnsJob(JSON.parse(localStorage.getItem("tableColumnsJob")));
    } else {
      settableColumnsJob(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsJob];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsJob", JSON.stringify(updatedColumns));
    settableColumnsJob(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const Managecolumn = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "30px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    // overflowY: "auto",
    overflowY: "scroll",
  };
  const ManagecolumnmodalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: "200",
    
  };
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete
    // handleOpenEditor,
    // onOpenReportOutlookEmail,
  );
  const [productData, setProductData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Customer API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(exportData, "Job");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Job");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Job");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Job");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };


const exportToExcel = (data, fileName) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const formatDateWithoutTime = (date) => (date ? new Date(date).toISOString().split("T")[0] : "");

  // Define the headers
  const headerTitles = [
    "Job Number",
    "Booking Number",
    "Product",
    "Handle By",
    "Job Status",
    "DOBC",
    "POL",
    "POD",
    "Shipper",
    "Consignee",
    "Client",
    "Agent",
    "Quotation Number",
    "Scope",
    "Region",
    "Incoterm",
    "Sales",
    "Cargo",
    "Cargo Nature",
    "HS Code",
    "Shipping Line",
    "Vessel Name Voyage",
    "CY Open",
    "SI Cutoff",
    "VGM Cutoff",
    "CY Cutoff",
    "Customs Cutoff",
    "OOG Cutoff",
    "ETD",
    "ETA",
    "Days At Origin",
    "Days At Destiantion",
    "Freight",
    "MBL Type",
    "HBL Type",
    "Liner Booking Number",
    "MBL Number",
    "HBL Number",
    "Cargo Pickup Date",
    "Empty Pickup Date",
    "Stuffing Date",
    "Laden Gate In Date",
    "Vessel Arrival Date",
    "Operation Transfer Deadline",
    "Detention",
    "Demurrage",
    "Detention By Liner",
    "Demurrage By Liner",
    "Detention Number",
    "Demurrage Number",
    "Port Storage",
    "Port Storage Days",
    "Current Status",
    "Notes",
    "Containers",
    "Vendors",
  ];

  // Map data to match headers format
  const dataArray = data.map((item) => [
    item.JobNumber,
    item.BookingNumber,
    item.Product,
    item.HandleBy,
    item.Job_Status,
    formatDateWithoutTime(item.DOBC),
    item.POL,
    item.POD,
    item.Shipper,
    item.Consignee,
    item.Client,
    item.Agent,
    item.QuotationNumber,
    item.Scope,
    item.Region,
    item.Incoterm,
    item.Sales,
    item.Cargo,
    item.Cargo_Nature,
    item.HS_Code,
    item.Shipping_Line,
    item.VesselNameVoyage,
    formatDateWithoutTime(item.CY_Open),
    formatDateWithoutTime(item.SI_Cutoff),
    formatDateWithoutTime(item.VGM_Cutoff),
    formatDateWithoutTime(item.CY_Cutoff),
    formatDateWithoutTime(item.Customs_Cutoff),
    formatDateWithoutTime(item.OOG_Cutoff),
    formatDateWithoutTime(item.ETD),
    formatDateWithoutTime(item.ETA),
    item.days_at_origin,
    item.days_at_destiantion,
    item.Freight,
    item.MBL_Type,
    item.HBL_Type,
    item.Liner_Bookingno,
    item.MBLNo,
    item.HBLNo,
    formatDateWithoutTime(item.Cargo_Pickup_Date),
    formatDateWithoutTime(item.Empty_Pickup_Date),
    formatDateWithoutTime(item.Stuffing_Date),
    formatDateWithoutTime(item.Laden_Gate_In_Date),
    formatDateWithoutTime(item.Vessel_Arrival_Date),
    formatDateWithoutTime(item.Operation_Transfer_Deadline),
    formatDateWithoutTime(item.Detention),
    formatDateWithoutTime(item.Demurrage),
    item.Detention_By_Liner,
    item.Demurrage_By_Liner,
    item.DetentionNo,
    item.DemurrageNo,
    formatDateWithoutTime(item.Port_Storage),
    item.PortStorage,
    item.Port_Storage_Days,
    item.Current_Status,
    item.Notes,
    Array.isArray(item.Containers)
      ? item.Containers
          .map((container) => {
            // Construct a string of the container's properties excluding Container_ID
            return Object.entries(container)
              .filter(([key]) => key !== "Container_ID") // Exclude Container_ID
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");
          })
          .join(" | ") // Join multiple containers with " | "
      : item.Containers,
      Array.isArray(item.Vendors)
      ? item.Vendors
          .map((vendor) => {
            // Construct a string of the container's properties excluding Container_ID
            return Object.entries(vendor)
              .filter(([key]) => key !== "add_costid") // Exclude Container_ID
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");
          })
          .join(" | ") // Join multiple containers with " | "
      : item.Vendors,
  ]);
  
  
  

  // Create a new workbook and worksheet
  const ws = XLSX.utils.aoa_to_sheet([headerTitles, ...dataArray]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Job");

  // Generate Excel file
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], { type: fileType });
  const currentDate = new Date().toISOString().split("T")[0];
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
  const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

  saveAs(dataBlob, finalFileName);
};


  const exportToCSV = (data, fileName) => {
    // Function to format date without time
    const formatDateWithoutTime = () => {
      const date = new Date();
      return date.toISOString().split("T")[0];
    };
    const dataArray = data.map((item) => [
      item.JobNumber,
      item.BookingNumber,
      item.Product,
      item.HandleBy,
      item.Job_Status,
      formatDateWithoutTime(item.DOBC),
      item.POL,
      item.POD,
      item.Shipper,
      item.Consignee,
      item.Client,
      item.Agent,
      item.QuotationNumber,
      item.Scope,
      item.Region,
      item.Incoterm,
      item.Sales,
      item.Cargo,
      item.Cargo_Nature,
      item.HS_Code,
      item.Shipping_Line,
      item.VesselNameVoyage,
      formatDateWithoutTime(item.CY_Open),
      formatDateWithoutTime(item.SI_Cutoff),
      formatDateWithoutTime(item.VGM_Cutoff),
      formatDateWithoutTime(item.CY_Cutoff),
      formatDateWithoutTime(item.Customs_Cutoff),
      formatDateWithoutTime(item.OOG_Cutoff),
      formatDateWithoutTime(item.ETD),
      formatDateWithoutTime(item.ETA),
      item.days_at_origin,
      item.days_at_destiantion,
      item.Freight,
      item.MBL_Type,
      item.HBL_Type,
      item.Liner_Bookingno,
      item.MBLNo,
      item.HBLNo,
      formatDateWithoutTime(item.Cargo_Pickup_Date),
      formatDateWithoutTime(item.Empty_Pickup_Date),
      formatDateWithoutTime(item.Stuffing_Date),
      formatDateWithoutTime(item.Laden_Gate_In_Date),
      formatDateWithoutTime(item.Vessel_Arrival_Date),
      formatDateWithoutTime(item.Operation_Transfer_Deadline),
      formatDateWithoutTime(item.Detention),
      formatDateWithoutTime(item.Demurrage),
      item.Detention_By_Liner,
      item.Demurage_By_Liner,
      item.DetentionNo,
      item.DemurrageNo,
      formatDateWithoutTime(item.Port_Storage),
      item.PortStorage,
      item.Port_Storage_Days,
      item.Current_Status,
      item.Notes,
      item.Containers,
      item.Vendors
    ]);

    const csvContent = [
      [
        "JobNumber",
        "BookingNumber",
        "Product",
        "HandleBy",
        "Job_Status",
        "DOBC",
        "POL",
        "POD",
        "Shipper",
        "Consignee",
        "Client",
        "Agent",
        "QuotationNumber",
        "Scope",
        "Region",
        "Incoterm",
        "Sales",
        "Cargo",
        "Cargo_Nature",
        "HS_Code",
        "Shipping_Line",
        "VesselNameVoyage",
        "CY_Open",
        "SI_Cutoff",
        "VGM_Cutoff",
        "CY_Cutoff",
        "Customs_Cutoff",
        "OOG_Cutoff",
        "ETD",
        "ETA",
        "days_at_origin",
        "days_at_destiantion",
        "Freight",
        "MBL_Type",
        "HBL_Type",
        "Liner_Bookingno",
        "MBLNo",
        "HBLNo",
        "Cargo_Pickup_Date",
        "Empty_Pickup_Date",
        "Stuffing_Date",
        "Laden_Gate_In_Date",
        "Vessel_Arrival_Date",
        "Operation_Transfer_Deadline",
        "Detention",
        "Demurrage",
        "Detention_By_Liner",
        "Demurage_By_Liner",
        "DetentionNo",
        "DemurrageNo",
        "Port_Storage",
        "PortStorage",
        "Port_Storage_Days",
        "Current_Status",
        "Notes",
        "Vendor",
        "Detail",
        "Amount",
        "Currency",
        "Containers",
        "Vendors",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };


  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);

    const tableColumnsJob = [
        "JobNumber", "BookingNumber", "Product", "HandleBy", "Job_Status", "DOBC",
        "POL", "POD", "Shipper", "Consignee", "Client", "Agent", "QuotationNumber",
        "Scope", "Region", "Incoterm", "Sales", "Cargo", "Cargo_Nature", "HS_Code",
        "Shipping_Line", "VesselNameVoyage", "CY_Open", "SI_Cutoff", "VGM_Cutoff",
        "CY_Cutoff", "Customs_Cutoff", "OOG_Cutoff", "ETD", "ETA", "days_at_origin",
        "days_at_destination", "Freight", "MBL_Type", "HBL_Type", "Liner_Bookingno",
        "MBLNo", "HBLNo", "Cargo_Pickup_Date", "Empty_Pickup_Date", "Stuffing_Date",
        "Laden_Gate_In_Date", "Vessel_Arrival_Date", "Operation_Transfer_Deadline",
        "Detention", "Demurrage", "Detention_By_Liner", "Demurrage_By_Liner",
        "DetentionNo", "DemurrageNo", "Port_Storage", "PortStorage", "Port_Storage_Days",
        "Current_Status", "Notes", "Containers", "Vendors"
    ];

    const dateOnlyColumns = [
        "DOBC", "CY_Open", "SI_Cutoff", "VGM_Cutoff", "CY_Cutoff", "Customs_Cutoff",
        "OOG_Cutoff", "ETD", "ETA", "Cargo_Pickup_Date", "Empty_Pickup_Date",
        "Stuffing_Date", "Laden_Gate_In_Date", "Vessel_Arrival_Date", "Operation_Transfer_Deadline"
    ];

    const headerTitles = [
        "Job Number", "Booking Number", "Product", "Handle By", "Job Status", "DOBC",
        "POL", "POD", "Shipper", "Consignee", "Client", "Agent", "Quotation Number",
        "Scope", "Region", "Incoterm", "Sales", "Cargo", "Cargo Nature", "HS Code",
        "Shipping Line", "Vessel Name Voyage", "CY Open", "SI Cutoff", "VGM Cutoff",
        "CY Cutoff", "Customs Cutoff", "OOG Cutoff", "ETD", "ETA", "Days At Origin",
        "Days At Destination", "Freight", "MBL Type", "HBL Type", "Liner Booking Number",
        "MBL Number", "HBL Number", "Cargo Pickup Date", "Empty Pickup Date", "Stuffing Date",
        "Laden Gate In Date", "Vessel Arrival Date", "Operation Transfer Deadline",
        "Detention", "Demurrage", "Detention By Liner", "Demurrage By Liner",
        "Detention Number", "Demurrage Number", "Port Storage", "Port Storage",
        "Port Storage Days", "Current Status", "Notes", "Containers", "Vendors"
    ];

    const formatData = (data) =>
        data.map((item) => {
            return tableColumnsJob.map((column) => {
                if (dateOnlyColumns.includes(column) && item[column]) {
                    return new Date(item[column]).toLocaleDateString();
                }

                if (column === "Vendors") {
                    return item[column]
                        ? item[column].replace(/, add_costid: [^,]+/g, "").replace(/;\s*$/, "")
                        : "";
                }
                if (column === "Containers") {
                    return item[column]
                        ? item[column].replace(/, Container_ID: [^,]+/g, "").replace(/;\s*$/, "")
                        : "";
                }
                return item[column];
            });
        });

    const tableRows = formatData(data);
    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 10;

    doc.setFontSize(titleFontSize);
    const title = "Job";
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
        startY: marginTop + titleFontSize + 10,
        headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 8 },
        bodyStyles: { fontSize: 8 },
        columnStyles: { cellWidth: "2000" },
        head: [headerTitles],
        body: tableRows,
        didDrawCell: (data) => {
            doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, "S");
        },
    };

    doc.autoTable(tableOptions);

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
};

  

  const exportToText = (data, fileName) => {
    // Function to format date without time
    const formatDateWithoutTime = () => {
      const date = new Date();
      return date.toISOString().split("T")[0];
    };
    const tableColumnsJob = [
      "JobNumber",
      "BookingNumber",
      "Product",
      "HandleBy",
      "Job_Status",
      "DOBC",
      "POL",
      "POD",
      "Shipper",
      "Consignee",
      "Client",
      "Agent",
      "QuotationNumber",
      "Scope",
      "Region",
      "Incoterm",
      "Sales",
      "Cargo",
      "Cargo_Nature",
      "HS_Code",
      "Shipping_Line",
      "VesselNameVoyage",
      "CY_Open",
      "SI_Cutoff",
      "VGM_Cutoff",
      "CY_Cutoff",
      "Customs_Cutoff",
      "OOG_Cutoff",
      "ETD",
      "ETA",
      "days_at_origin",
      "days_at_destiantion",
      "Freight",
      "MBL_Type",
      "HBL_Type",
      "Liner_Bookingno",
      "MBLNo",
      "HBLNo",
      "Cargo_Pickup_Date",
      "Empty_Pickup_Date",
      "Stuffing_Date",
      "Laden_Gate_In_Date",
      "Vessel_Arrival_Date",
      "Operation_Transfer_Deadline",
      "Detention",
      "Demurrage",
      "Detention_By_Liner",
      "Demurage_By_Liner",
      "DetentionNo",
      "DemurrageNo",
      "Port_Storage",
      "PortStorage",
      "Port_Storage_Days",
      "Current_Status",
      "Notes",
      "Vendor",
      "Detail",
      "Amount",
      "Currency",
      "Containers",
    ];

    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });

    const textContent = [tableColumnsJob.join(",")]
      .concat(tableRows)
      .join("\n");

    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";

    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };

  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            {/* <OperationSidebar
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
          size={20}
        /> */}
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
            <TbAdjustmentsHorizontal
              onClick={
                isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
              }
              size={25}
              style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
            />
            &nbsp;
            <div className="relative inline-block">
              {/* <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"> */}
              {/* <Link to="/addjob" >Create Jobs</Link> */}
              <UnallocatedJob
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                onApply={handleApply}
                setUnallocatedBookingState={setUnallocatedBookingState}
                setFormData={setFormData}
                formData={formData}
              />
              {/* </button> */}
            </div>
            &nbsp;&nbsp;
            <div className="relative inline-block">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={toggleActionDropdown}
              >
                Actions
              </button>
              &nbsp;&nbsp;
              {isActionOpen && (
                <div 
                ref = {dropdownRef}
                className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
                  <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                    <FiEdit size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={() => {
                        checkValidation.handleEditClickMassupdate();
                        handleActionSelection();
                      }}
                    >
                      Mass Update
                    </span>
                  </a>
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      checkValidation.handleEditClickMassdelete();
                    }}
                  >
                    <RiDeleteBinLine size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Mass Delete
                    </span>
                  </a>
                  <a
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                    onClick={() => {
                      onOpenDownload();
                      setOverlay(<OverlayOne />);
                      toggleActionDropdown();
                    }}
                  >
                    <MdOutlineDownloadForOffline size={20} />
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={handleActionSelection}
                    >
                      Download
                    </span>
                  </a>

                  {/* <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickJobTransferData();
                }}
              >
                <RiFileTransferLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Job Transfer
                </span>
              </a> */}

                  {/* <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickJobAdd();
                }}
              >
                <RiFileTransferLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Add OVS Operation
                </span>
              </a> */}
                </div>
              )}
            </div>
            <>
              {isOpenFilter && (
                <>
                 <div style={backdropStyle}></div> 
                <div className={`FilterModal`}>
                  {/* {${scrollBehavior}} */}
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200,
                    }}
                    cancel=".cancelDragAndDrop"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Criteria
                    </header>
                    <div
                      className="closemodule"
                      onClick={() => {
                        onCloseFilter(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    <FormControl className="closemodule cancelDragAndDrop">
                      {filterRows.map((index) => (
                        <Flex key={index}>
                          <Select
                            id={`column-select-${index}`}
                            placeholder="Select"
                            onChange={(e) => handleFilterTypeSelect(e, index)}
                            value={filterConditions[index]?.column || "select"}
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            style={{ position: "relative" }}
                          >
                            <option value="JobNumber">Job Number</option>
                            <option value="ETD">ETD</option>
                            <option value="ETA">ETA</option>
                            <option value="Product">Product</option>
                            <option value="HandleBy">
                              Shipment Handled By
                            </option>
                            <option value="Job_Status">Job Status</option>
                            <option value="POL">POL</option>
                            <option value="POD">POD</option>
                            <option value="ContainerType">Equipment</option>
                            <option value="Client">Client</option>
                            <option value="Agent">Agent</option>
                            <option value="Scope">Scope</option>
                            <option value="Region">Region</option>
                            <option value="Incoterm">Incoterm</option>
                            <option value="Sales">Sales</option>
                            <option value="CY_Cutoff">CY Cut Off</option>
                            <option value="SI_Cutoff">SI Cut Off</option>
                            <option value="VGM_Cutoff">VGM Cut Off</option>
                          </Select>
                          &nbsp;&nbsp;
                          <Select
                            id={`filter-type-${index}`}
                            onChange={(e) =>
                              handleFilterConditionSelect(e, index)
                            }
                            value={
                              filterConditions[index]?.condition || "select"
                            }
                            placeholder="Select"
                          >
                            {[
                              "DeletionFlag",
                              "ETD",
                              "ETA",
                              "CY_Cutoff",
                              "SI_Cutoff",
                              "VGM_Cutoff",
                              "ConversionFlag",
                            ].includes(filterConditions[index]?.column) ? (
                              <>
                                <option value="equals">=</option>
                                <option value="not-equals">!=</option>
                                <option value="greater">&gt;</option>
                                <option value="lesser">&lt;</option>
                                <option value="greater-equal">&gt;=</option>
                                <option value="lesser-equal">&lt;=</option>
                              </>
                            ) : (
                              <>
                                <option value="is">is</option>
                                <option value="contains">contains</option>
                                <option value="startsWith">starts with</option>
                                <option value="endsWith">ends with</option>
                              </>
                            )}
                          </Select>
                          &nbsp;&nbsp;
                          {[
                            "ETD",
                            "ETA",
                            "CY_Cutoff",
                            "SI_Cutoff",
                            "VGM_Cutoff",
                          ].includes(filterConditions[index]?.column) ? (
                            <Input
                              type="date"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          ) : //   <Input
                          //   type="text"
                          //   id={`value-input-${index}`}
                          //   value={filterConditions[index]?.value || ""}
                          //   onChange={(e) => handleFilterValueChange(e, index)}
                          // />
                          null}
                          &nbsp;&nbsp;
                          {filterConditions[index]?.column === "Job_Status" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option value="">Select Job Status</option>
                              <option value="Inprogress">Inprogress</option>
                              <option value="Completed">Completed</option>
                              <option value="Hold">Hold</option>
                              <option value="Cancelled">Cancelled</option>
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Product" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Product</option>
                              {productData.map((data) => (
                                <option key={data.Product} value={data.Product}>
                                  {data.Product}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column ===
                            "ContainerType" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Container Type</option>
                              {equipmentData.map((item, idx) => (
                                <option key={idx} value={item.ContainerType}>
                                  {item.ContainerType}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "Sales" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select Sales</option>
                              {salesPersonData.map((data) => (
                                <option
                                  key={data.fullname}
                                  value={data.fullname}
                                >
                                  {data.fullname}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "POL" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select POL</option>
                              {portData.map((data) => (
                                <option key={data.Port} value={data.Port}>
                                  {data.Port}
                                </option>
                              ))}
                            </Select>
                          )}
                          {filterConditions[index]?.column === "POD" && (
                            <Select
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            >
                              <option>Select POD</option>
                              {portData.map((data) => (
                                <option key={data.Port} value={data.Port}>
                                  {data.Port}
                                </option>
                              ))}
                            </Select>
                          )}
                          {[
                            "JobNumber",
                            "HandleBy",
                            "Client",
                            "Agent",
                            "Scope",
                            "Region",
                            "Incoterm",
                          ].includes(filterConditions[index]?.column) && (
                            <Input
                              type="text"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          )}
                          &nbsp;&nbsp;
                          <IoMdAddCircle
                            className="cursor-pointer closemodule cancelDragAndDrop"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={() => addFilterRow()}
                          />
                          {filterRows?.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer closemodule cancelDragAndDrop"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </FormControl>
                    <div
                      className="closemodule cancelDragAndDrop"
                      style={{
                        float: "right",
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSubmitFilter();
                          onCloseFilter();
                        }}
                        mr={3}
                      >
                        Apply
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleClearFilters();
                          onCloseFilter();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      <RadioGroup
                        style={{ flex: 1 }}
                        value={handleCheckboxFilter}
                      >
                        <Stack direction="row">
                          <Radio value="AND" onChange={handleCheckboxChange}>
                            All
                          </Radio>
                          <Radio value="OR" onChange={handleCheckboxChange}>
                            Any
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>
            <>
              {isOpenManagecolumn && (
                <>
                 <div style={backdropStyle}></div> 
                <div style={ManagecolumnmodalStyleUpdate}>
                  <div></div>
                  <Rnd
                    style={Managecolumn}
                    default={{
                      x: 0,
                      y: 0,
                      width: 1250,
                      height: 400
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Manage Column
                    </header>
                    <div
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />
                    <div className="grid gap-x-35 gap-y-5 grid-cols-5">
                      {tableColumnsJob.map(
                        (label, index) =>
                          label.key !== "id" && (
                            <label
                              key={index}
                              className="flex items-center"
                              draggable
                              onDragStart={(event) =>
                                handleDragStart(event, index)
                              }
                              onDragOver={handleDragOver}
                              onDrop={(event) => handleDrop(event, index)}
                            >
                              <input
                                type="checkbox"
                                // checked={label.label}
                                className="cursor-pointer"
                                disabled={
                                  label.key === "BookingNumber" ||
                                  label.key === "JobNumber" ||
                                  label.key === "Pol" ||
                                  label.key === "Pod" ||
                                  label.key === "Equipment" ||
                                  label.key === "Sales" ||
                                  label.key === "ShipmentHandledBy" ||
                                  label.key === "Client" ||
                                  label.key === "Containers" ||
                                  label.key === "JobOwner"
                                }
                                checked={label.isVisible}
                                onChange={(event) => {
                                  handleCheckboxColumn(event, label);
                                }}
                              />
                              <span
                                className="ml-2"
                                style={{ cursor: "all-scroll" }}
                              >
                                {label.label}
                              </span>
                              {label.isDisabled && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </label>
                          )
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        float: "right",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="green"
                      >
                        OK
                      </Button>
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="red"
                        ml={3}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>

              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}

              {/* <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip> */}
              {/* {isconvert ? (
            <Tooltip
              hasArrow
              label="Show assigned records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide assigned records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          )} */}
              <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
                  <RiDeleteBinLine
                    size={20}
                    onClick={() => {
                      checkValidation.handleEditClickMassdelete();
                    }}
                  />
                </div>
              </Tooltip>
              {isdelete ? (
                <Tooltip
                  hasArrow
                  label="Hide old records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiShow size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  hasArrow
                  label="Show old records"
                  bg="gray.300"
                  color="black"
                >
                  <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                    <BiHide size={24} onClick={handleRecordDelete} />
                  </div>
                </Tooltip>
              )}
              {/* <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <HiOutlineMail
                onClick={() => {
                  // checkValidation.handleEditClickMassmail();
                }}
                size={20}
              />
            </div>
          </Tooltip> */}
              {/* <Tooltip hasArrow label="Outlook Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <SiMicrosoftoutlook
                onClick={() => {
                  // checkValidation.handleEditClickOutlookMail();
                }}
                size={20}
              />
            </div>
          </Tooltip> */}

              <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
                  <FiEdit
                    size={20}
                    onClick={() => {
                      checkValidation.handleEditClickMassupdate();
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Select all pages"
                bg="gray.300"
                color="black"
              >
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                  <MdSelectAll
                    onClick={() => {
                      handleCheckbox(jobData.map((item) => item.id));
                      setCheckBoxAll((prev) => {
                        if (prev === true) {
                          setCheckBoxSelected([]);
                        }
                        return !prev;
                      });
                    }}
                    size={24}
                  />
                </div>
              </Tooltip>
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, jobData?.length)}
                  </span>{" "}
                  of <span className="font-medium">{jobData?.length}</span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <Selectallpages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggableJobTable
                isLoading={isLoading}
                columns={columns2}
                jobData={jobData}
                getData={getData}
                data={jobData.slice(indexOfFirstItem, indexOfLastItem)}
                setJobData={setJobData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsJob={tableColumnsJob}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
      <>
        {
          <div>
            {isOpenMassupdate && (
              <>
              <div style={backdropStyle}></div>
              <div className={`MassUpdateModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 500,
                    // height: 200
                  }}
                  // cancel="X"
                  cancel="cancel"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Mass Update
                  </header>
                  <div
                    onClick={() => {
                      onCloseMassupdate(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  <FormControl>
                    <Flex>
                      <Select
                        mt={7}
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={selectedOption}
                        onChange={(e) => handleOptionChange(e.target.value)}
                      >
                        <option value="Select a field">Select a field</option>
                        <option value="Product">Product</option>
                        <option value="Scope">Scope</option>
                        <option value="Region">Region</option>
                        <option value="SICutOff">SICutOff</option>
                      </Select>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {selectedOption === "Product" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Product</option>
                          {productData.map((data) => (
                            <option value={data.Product}>{data.Product}</option>
                          ))}
                        </Select>
                      ) : selectedOption === "Scope" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Scope</option>
                          {scopeData.map((data) => (
                            <option value={data.Scope}>{data.Scope}</option>
                          ))}
                        </Select>
                      ) : selectedOption === "Region" ? (
                        <Select
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        >
                          <option>Select Region</option>
                          {regionData.map((data) => (
                            <option value={data.Region}>{data.Region}</option>
                          ))}
                        </Select>
                      ) : selectedOption === "SICutOff" ? (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="date"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        />
                      ) : (
                        <Input
                          mt={7}
                          onChange={(e) => setUpdatedValue(e.target.value)}
                          value={updatedValue}
                          type="text"
                          name={selectedOption}
                          id="updateField"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          disabled={selectedOption === "Select a field"}
                        />
                      )}
                    </Flex>
                  </FormControl>
                  <br />
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      onOpenReportMassupdate();
                      onCloseMassupdate();
                    }}
                    mr={3}
                  >
                    Submit
                  </Button>
                </Rnd>
              </div>
              </>
            )}
          </div>
        }
      </>
      <>
        {isOpenReportMassupdate && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Update Job
              </header>
              <div
                onClick={() => {
                  onCloseReportMassupdate(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Update the jobs?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitUpdate();
                  onCloseReportMassupdate();
                }}
              >
                Update
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassupdate();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Job
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {bookingWarning
                  ? bookingWarning
                  : "Are you sure you want to delete the job?"}
              </FormControl>
              <br />
              {bookingWarning ? (
                <>
                  <Button
                    colorScheme="green"
                    onClick={handleSubmitDeleteWithBooking}
                    ml={3}
                    value="WITH"
                  >
                    Delete with Booking
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={handleSubmitDeleteWithBooking}
                    ml={3}
                    style={{ background: "red", color: "white" }}
                    value="WITHOUT"
                  >
                    Delete without Booking
                  </Button>
                </>
              ) : (
                <>
                  <Button colorScheme="green" onClick={handleSubmitDelete}>
                    Delete
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={onCloseReportDelete}
                    style={{ background: "red", color: "white" }}
                    ml={3}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>

      <>
        {isOpenDownload && (
          <>
          <div style={backdropStyle}></div>
          <div className={`DownloadExcelModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
                  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
                  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                    <option value="Text">Text</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleExport();
                }}
                disabled={isDownloading}
                mr={3}
              >
                {isDownloading ? "Downloading..." : "Export Data"}
              </Button>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>

      {/* <>
        {isOpenJobTransfer && (
          <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Job Transfer
              </header>
              <div
                onClick={() => {
                  onCloseJobTransfer(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl> Are you sure you want to job transfer?</FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitJobTransfer();
                  onCloseJobTransfer();
                }}
              >
                Send
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseJobTransfer();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
        )}
      </> */}
    </div>
  );
};

export default Job;
