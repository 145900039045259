import { React, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Badge,
  useToast,
  Tooltip,
  Button,
  useDisclosure,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiPrinter } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { MdSelectAll } from "react-icons/md";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import SelectAllPages from "./Selectallpages";
import {
  DraggableVendorOperationTable,
  Header,
  OperationSidebar,
} from "../../../components";
import { Checkvalidation } from "./helpers/Checkvalidation";
import Axios, { OperationDocumentAPIInstance } from "../../../api-instance";
import AddOpsVendor from "./AddOpsVendor";
import jsPDF from "jspdf";
import "jspdf-autotable";
const OperationVendors = () => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("Operation Vendor selectedJob:", selectedJob);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // State Variables
  const cancelRef = useRef();
  const searchRef = useRef(null);
  const [size, setSize] = useState("xl");
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const navigate = useNavigate();
  const [isActionOpen, setActionOpen] = useState(false);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const toast = useToast();
  const [operationVendorData, setOperationVendorData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);
  const [exportData, setExportData] = useState([]);
  const handleOpenVendor = () => {
    setIsOpen(true);
  };
  // Droppdown Action
  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  const [columns, setColumns] = useState({
    id: true,
    BookingNumber: true,
    JobNumber: true,
    Vendor_name: true,
    Currency: true,
    Amount: true,
    PaidAmount: true,
    Invoice_Attachment: true,
    Payment_Status: true,
    URL_Object: true,
  });
  const [tableColumnsOperationVendor, settableColumnsOperationVendor] =
    useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsOperationVendor((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsOperationVendor.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem(
      "tableColumnsOperationVendor",
      JSON.stringify(newData)
    );
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };


  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,

    onOpenReportDelete
    // handleOpenEditor,
    // onOpenReportOutlookEmail,
  );
  const getData = async () => {
    try {
      const { data } = await OperationDocumentAPIInstance.get(
        `get_vendor_ops?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&BookingNumber=${selectedJob.BookingNumber}&JobNumber=${
          selectedJob.JobNumber
        }`, //&BookingNumber=${selectedJob.BookingNumber}&JobNumber=${selectedJob.JobNumber}
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setOperationVendorData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setExportData(data.data);
      setOperationVendorData(filteredData);
    } catch (error) {
      console.log(error);
      // if (error?.response?.data?.message) {
      //   toast({
      //     title: error?.response?.data?.message,
      //     position: "bottom-center",
      //     isClosable: true,
      //     status: "error",
      //     containerStyle: {
      //       width: "98.5vw",
      //       maxWidth: "98.5vw",
      //       alignContent: "center",
      //     },
      //   });
      // } else if (error?.response?.statusText) {
      //   toast({
      //     title: error?.response?.statusText,
      //     position: "bottom-center",
      //     isClosable: true,
      //     status: "error",
      //     containerStyle: {
      //       width: "98.5vw",
      //       maxWidth: "98.5vw",
      //       alignContent: "center",
      //     },
      //   });
      // } else {
      //   toast({
      //     title: error?.message,
      //     position: "bottom-center",
      //     isClosable: true,
      //     status: "error",
      //     containerStyle: {
      //       width: "98.5vw",
      //       maxWidth: "98.5vw",
      //       alignContent: "center",
      //     },
      //   });
      // }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmitDelete = async () => {
    try {
      const { data } = await OperationDocumentAPIInstance.post(
        `delete_vendor_ops?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          id: selectAllPages
            ? operationVendorData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: `Vendor Deleted Successfully!`,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        onCloseReportDelete();
      }
      getData();
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      return;
    }
    getData();
  };

  // PDF Data Export

  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "landscape";
  
    const doc = new jsPDF(orientation, unit, size);
  
    const tableColumnsOperationVendor = [
      "JobNumber",
      "BookingNumber",
      "Vendor_name",
      "Currency",
      "Amount",
      "Invoice_Attachment",
      "Payment_Status",
      "CreatedBy",

    ];

    const headerTitles = [
     "Job Number",
      "Booking Number",
      "Vendor Name",
      "Currency",
      "Amount",
      "Invoice Attachment",
      "Payment Status",
      "Uploaded By",
    ];
  
    const formatData = (data) =>
      data.map((item) => {
        return tableColumnsOperationVendor.map((column) => {
        
          return item[column];
        });
      });
  
    const tableRows = formatData(data);
  
    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 5;
  
    doc.setFontSize(titleFontSize);
  
    const title = "Job Vendor Information";
    doc.text(title, marginLeft, marginTop);
  
    const tableOptions = {
      startY: marginTop + titleFontSize + 10,
      headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 5 },
      bodyStyles: { fontSize: 5 },
      columnStyles: { cellWidth: 2000 },
      head: [headerTitles],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };
  
    doc.autoTable(tableOptions);
  
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `Job Vendor Information_${currentDate}_${currentTime}.pdf`;
  
    // Download the PDF with the specified filename
    doc.save(finalFileName);
  };

  const handlePrintClick = () => {
    if (exportData.length > 0) {
      exportToPDF(exportData, "Job Vendor Information");
    } else {
      console.error("No data available for export.");
    }
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(operationVendorData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };
  useEffect(() => {
    if (localStorage.getItem("tableColumnsOperationVendor")) {
      settableColumnsOperationVendor(
        JSON.parse(localStorage.getItem("tableColumnsOperationVendor"))
      );
    } else {
      settableColumnsOperationVendor(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);
  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsOperationVendor];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsOperationVendor",
      JSON.stringify(updatedColumns)
    );
    settableColumnsOperationVendor(updatedColumns);
  };

  return (
    <>
      <Header />
      <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
        <p className="float-left">
          <OperationSidebar
            size={20}
            style={{ cursor: "pointer" }}
            selectedJob={selectedJob}
          />
        </p>
        <p className="float-left ml-5 mt-3 text-900">Vendor Information</p>
       
        &nbsp;&nbsp;
      </div>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>

          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(operationVendorData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>

          
          <Tooltip
            hasArrow
            label="Print"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
           
              <FiPrinter size={20}  onClick={() => {
                 handlePrintClick();
                }}
                />
              <span
                className="flex-grow cursor-pointer"
              >
              
              </span>
              </div>
              </Tooltip>
          
              <Tooltip
            hasArrow
            label="Delete"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <RiDeleteBinLine size={20} onClick={() => {
                  handleActionSelection();
                  checkValidation.handleEditClickMassdelete();
                }} />
              <span
                className="flex-grow cursor-pointer"
                
              >
               
              </span>
              </div>
              </Tooltip>
        
          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, operationVendorData?.length)}
              </span>{" "}
              of{" "}
              <span className="font-medium">{operationVendorData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <SelectAllPages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
            <a>Title :</a>
            {selectedJob?.JobNumber}/{selectedJob?.BookingNumber}
          </Badge>
          <DraggableVendorOperationTable
            isLoading={isLoading}
            columns={columns2}
            operationVendorData={operationVendorData}
            getData={getData}
            data={operationVendorData.slice(indexOfFirstItem, indexOfLastItem)}
            setOperationVendorData={setOperationVendorData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsOperationVendor={tableColumnsOperationVendor}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>
      </div>
      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              //cancel="X"
              cancel="cancel"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Vendor
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to delete the Vendor?
              </FormControl>
              <br />
              <>
                <Button colorScheme="green" onClick={handleSubmitDelete}>
                  Delete
                </Button>
                <Button
                  ref={cancelRef}
                  onClick={onCloseReportDelete}
                  style={{ background: "red", color: "white" }}
                  ml={3}
                >
                  Cancel
                </Button>
              </>
            </Rnd>
          </div>
          </>
        )}
      </>
      <Button float="left" colorScheme="blue" onClick={handleOpenModal}>
        Add Vendor
      </Button>
      <AddOpsVendor
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        operationVendorData={operationVendorData}
        getData={getData}
      />
    </>
  );
};

export default OperationVendors;
