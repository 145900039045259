import { React, useState, useEffect } from "react";
import "./Addunallocatedquotation.css";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    useToast,
} from "@chakra-ui/react";
import Axios, { QuotationAPIInstance } from "../../api-instance.js";
import { CgClose } from "react-icons/cg";
import { Rnd } from "react-rnd";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Addunallocatedquotation = ({
    selectedRows,
    setSelectedRows,
    selectedData,
    setSelectedData,
    onApply,
    setEnquirytoQuotationState,
    setFormData,
    formData,
}) => {
    const toast = useToast();
    const navigate = useNavigate();
    const style = {
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#ffff",
        color: "black",
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "0px 0px 5px grey",
        backdropFilter: "blur(10px)",
        // overflowX: "auto",
    };

    const backdropStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "199",
    };

    const closeModal = {
        position: "absolute",
        top: "5%",
        right: "5%",
        cursor: "pointer",
    };
    const handleModalClose = () => {
        onClose();
    };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchQuery, setSearchQuery] = useState(""); // Step 1: State for search query

    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        try {
            setIsLoading(true);
            const { data } = await QuotationAPIInstance.get(
                `get_enquiry?userId=${JSON.parse(localStorage.getItem("user")).userId
                }`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: JSON.parse(localStorage.getItem("token")),
                    },
                }
            );
            console.log("Data received:", data);
            if (data.data?.length === 0) {
                setData([]);
            } else {
                setData(data.data);
                setEnquirytoQuotationState(data.data);
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message) {
                toast({
                    title: error?.response?.data?.message,
                    position: "bottom-center",
                    isClosable: true,
                    status: "error",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            } else if (error?.response?.statusText) {
                toast({
                    title: error?.response?.statusText,
                    position: "bottom-center",
                    isClosable: true,
                    status: "error",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            } else {
                toast({
                    title: error?.message,
                    position: "bottom-center",
                    isClosable: true,
                    status: "error",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    const handleRowCheckboxClick = (EnquiryNumber, rowData) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(EnquiryNumber)) {
                return prevSelectedRows.filter((rowId) => rowId !== EnquiryNumber);
            } else {
                return [...prevSelectedRows, EnquiryNumber];
            }
        });

        if (rowData) {
            // Ensure rowData is defined
            setSelectedData((prevSelectedData) => {
                if (selectedRows.includes(EnquiryNumber)) {
                    return prevSelectedData.filter((row) => row?.EnquiryNumber !== EnquiryNumber); // Check row?.id
                } else {
                    return [...prevSelectedData, rowData];
                }
            });
        }
        setFormData([]);
    };
    useEffect(() => {
        if (selectedRows.length !== 0) {
            setFormData([]);
            setFormData(selectedData);
        }
    }, [selectedRows]);

    const handleAllCheckboxClick = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
            setSelectedData([]);
            setFormData([]);
        } else {
            setFormData(data);
            setSelectedRows(data.map((item) => item.EnquiryNumber));
            setSelectedData(data);
        }
    };
    console.log("Selected rows:", selectedRows);
    console.log("Selected data:", selectedData);

    const handleApplyClick = () => {
        if (selectedData.length > 0) {
            if (selectedData.length <= 5) {
                onApply(selectedData);
                onClose();
                navigate("/add_quotation", { state: { selectedData: selectedData } });
            } else {
                toast({
                    title: "Please select a maximum of 5 quotation.",
                    position: "bottom-center",
                    isClosable: true,
                    status: "warning",
                    containerStyle: {
                        width: "98.5vw",
                        maxWidth: "98.5vw",
                        alignContent: "center",
                    },
                });
            }
        } else {
            toast({
                title: "Please select at least one row.",
                position: "bottom-center",
                isClosable: true,
                status: "warning",
                containerStyle: {
                    width: "98.5vw",
                    maxWidth: "98.5vw",
                    alignContent: "center",
                },
            });
        }
    };

    const handleCancelClick = () => {
        setSelectedRows([]);
        setSelectedData([]);
        setFormData([{}]);
        setData([]);
        onApply();
        setEnquirytoQuotationState([]);
        getData();
        onClose();
    };


    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter data based on the search query
    const filteredData = data.filter((row) =>
        row.EnquiryNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.Mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.PickUpLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.DeliveryLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.Incoterm.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.Commodity.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                onClick={onOpen}
            >
                Create Quotation
            </button>

            {isOpen && (
                <>
                    <div style={backdropStyle}></div>
                    <div className={`MeetingModal`}>
                        <Rnd
                            style={style}
                            default={{
                                x: 0,
                                y: 0,
                                width: 950,
                                height: 600,
                            }}
                            cancel="X"
                            allowAnyClick={true}
                        >
                            <header
                                style={{ fontSize: "20px", fontWeight: "bold", float: "left" }}
                            >
                                Unassigned Enquiry
                            </header>

                            {/* Step 2: Search Bar */}
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search enquiries..."
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #000000",
                                }}
                            />

                            <div onClick={handleModalClose} style={closeModal}>
                                <CgClose />
                            </div>

                            <br />

                            <div
                                style={{
                                    width: "100%",
                                    maxHeight: "400px",
                                    overflowY: "auto",
                                }}
                            >
                                <div className="table-container">
                                    {!isLoading ? (
                                        filteredData.length !== 0 ? (
                                            <table className="table-auto border-collapse border w-full">
                                                <thead className="border border-slate-300 whitespace-nowrap text-left">
                                                    <tr>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            <input
                                                                type="checkbox"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    transform: "scale(1.3)",
                                                                    marginRight: "5px",
                                                                }}
                                                                checked={selectedRows.length === filteredData.length}
                                                                onChange={handleAllCheckboxClick}
                                                            />
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Enquiry Number
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Mode
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            PickUpLocation
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            DeliveryLocation
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Incoterm
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Commodity
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Address
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            Stackability
                                                        </th>
                                                        <th className="border border-slate-300 whitespace-nowrap text-center">
                                                            EquipmentDimensionandweight
                                                        </th>

                                                        <table className="table-auto border-collapse border w-full">
                                                            <tr>
                                                                <th className="whitespace-nowrap container-table-head">
                                                                    Equipment
                                                                </th>
                                                                <th>Quantity</th>
                                                                <th>Dimensions</th>
                                                                <th>Weight</th>  
                                                            </tr>
                                                        </table>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td className="whitespace-nowrap text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        transform: "scale(1.3)",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    checked={selectedRows.includes(row?.EnquiryNumber)}
                                                                    onChange={() =>
                                                                        handleRowCheckboxClick(row?.EnquiryNumber, row)
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.EnquiryNumber}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.Mode}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.PickUpLocation}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.DeliveryLocation}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.Incoterm}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.Commodity}
                                                            </td>
                                                           
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.Country}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.Stackable}
                                                            </td>
                                                            <td className="whitespace-nowrap text-center">
                                                                {row.EquipmentDimensionsandWeight}
                                                            </td>
                                                            <td className="flex-nowrap text-center">
                                                                {row.Containers.split(";").map(
                                                                    (containerInfo, index) => {
                                                                        const containerDetails = containerInfo
                                                                            .trim()
                                                                            .split(", ");
                                                                        return (
                                                                            <tr key={index}>
                                                                                {containerDetails.map(
                                                                                    (detail, detailIndex) => {
                                                                                        const [key, value] =
                                                                                            detail.split(": ");
                                                                                        if (key.trim() === "Container_ID") {
                                                                                            return null;
                                                                                        }
                                                                                        return (
                                                                                            <td
                                                                                                style={{
                                                                                                    textAlign: "left",
                                                                                                    paddingLeft: "50px",
                                                                                                    justifyContent:
                                                                                                        "space-evenly",
                                                                                                }}
                                                                                                key={detailIndex}
                                                                                            >
                                                                                                {value}
                                                                                            </td>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <h1 style={{ textAlign: "center", color: "black" }}>
                                                No Data Found!
                                            </h1>
                                        )
                                    ) : (
                                        <p>Loading...</p>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    float: "right",
                                    display: "block",
                                    marginTop: "10px",
                                }}
                            >
                                <Button colorScheme="green" onClick={handleApplyClick} mr={3}>
                                    Apply
                                </Button>
                                <Button colorScheme="red" onClick={handleCancelClick}>
                                    Cancel
                                </Button>
                            </div>
                        </Rnd>
                    </div>
                </>
            )}
        </>
    );
};

export default Addunallocatedquotation;
