export class Checkvalidation {
    constructor(
      checkBoxSelected,
      toast,
      handlePrint,
      handleCancel,
    ) {
      this.checkBoxSelected = checkBoxSelected;
      this.toast = toast;
      this.handlePrint = handlePrint;
      this.handleCancel=handleCancel;
    }
  

    handlePrintClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handlePrint();
    } 
    handleCancelClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for cancel.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for cancel.");
      this.toast({
        title: "Select only one row to cancel",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleCancel();
    } 
  }
  