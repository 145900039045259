import React, { useState } from 'react';
import './select.css';
import Select from 'react-select';

export default ({colourOptions,handleChange,name,placeholder,value}) => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "45px"
  }),
}

console.log("Select",value);
 
  return (
    <>


<Select
        onChange={(e) => handleChange(e)}
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        placeholder={placeholder}
        value={value ? { value: value, label: value } : null} // Ensures placeholder shows
        options={colourOptions}
        styles={customStyles}
        getOptionValue={(option) => option.value}
      />


    </>
  );
};