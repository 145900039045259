import React, { useEffect } from "react";
import { Button, Tooltip } from "@chakra-ui/react";

const SelectAllPages = ({ selectAllPages, setSelectAllPages, checkBoxAll }) => {
  useEffect(() => {
    if (!checkBoxAll) {
      setSelectAllPages(false); // When checkBoxAll becomes false, deselect all pages
    }
  }, [checkBoxAll]);

  function handleSelectAllPages(event) {
    setSelectAllPages(event.target.checked); // Toggle the selectAllPages state when the checkbox is clicked
  }

  return (
    <></>
  );
};

export default SelectAllPages;
