import React from "react"; // Import React
import { useState, useEffect } from "react"; // Import necessary hooks
import { Header } from "../../components"; // Import a Header component
import axios from "axios"; // Import Axios for making HTTP requests
import { v4 as uuidv4 } from "uuid"; // Import the uuidv4 function for generating unique IDs
import {MasterDataAPIInstance} from "../../api-instance"; // Import Axios with a custom configuration
import { Center, useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI for displaying notifications
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router for navigation

import "./Addvendor.css"; // Import CSS styles

const AddVendor = () => {
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const[vendorType,setvendorType]=useState([]);
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast(); // Initialize the useToast hook for notifications
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation

  const [formData, setFormData] = useState({
    // Initialize a state variable with form data,
    userId:JSON.parse(localStorage.getItem("user")).userId,
    Company:"",
    vendorType:"",
    ContactPerson:"",
    Specialization:"",
    Designation:"",
    Email:"",
    DialCode:"",
    Phone:"",
    City:"",
    Country:"",
    VendorOwner:"",
    Remarks:"",
  });
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countryData.find(
      (item) => item.Country_name === selectedCountry
    );
    console.log("Selected Country Data:", selectedCountryData);
    if (selectedCountryData) {
      setFormData((prevData) => ({
        ...prevData,
        Country: selectedCountry, // Update the "Country" field with the selected country name
        CountryData: selectedCountryData,
        PhoneWithCode: selectedCountryData.Phone_code,
      }));
      try {
        setCityData([]);
        const { data } = await MasterDataAPIInstance.get(
          `get_city?countryData=${selectedCountryData.Country_name}`
        );

        setCityData(data.data);
        console.log("Cities Data:", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "Country Data Not Found",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });

      // setFormData((prevData) => ({
      //   ...prevData,
      //   Country: selectedCountry, // Update the "Country" field with the selected country name
      //   CountryData: selectedCountryData,
      //   PhoneWithCode: selectedCountryData.Phone_code,
      // }));
    }
  };
  // Function to handle form submission
  const   handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(formData, "form"); // Log the form data to the console
    const { data } = await MasterDataAPIInstance.post(
      // Send a POST request using Axios
      `addMasterData/add_vendor?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData, // Include form data in the request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
        },
      }
    );

    console.log("===================================="); // Log a separator line
    console.log(data); // Log the response data
    console.log("===================================="); // Log another separator line

    if (data.success) {
      // Check if the response indicates success
      toast({
        title: "Vendor created successfully!", // Show a success toast notification
        position: "bottom-right",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/Vendor"); // Navigate to the "/Vendor" route
    } else {
      toast({
        title: data.message, // Show an error toast notification
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Function to handle input changes
  const handleChange = async (e) => {
    const { name, value } = e.target; // Get the name and value from the input element

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value, // Update the form data with the changed input value
      };
    });
  };
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await MasterDataAPIInstance.post(
        `addMasterData/add_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: "Vendor created successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header /> {/* Render a Header component */}
      <form>
        {/* Create a form element with a submission handler */}
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          {/* Create a top bar with buttons */}
          <p className="float-left py-1 px-2 rounded my-2">Create Vendor</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmitNext(e);
            }}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            onClick={() => {
              navigate("/Vendor");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>
        <div className="container-from-data bg-white dark-bg-slate-950 text-black dark-text-white">
          {/* Create a container for form data */}
          <div className="title">Vendor Information</div> {/* Add a title */}
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-vendor">
                <div className="input-box">
                  <span className="vendor">Company</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Company"
                    value={formData.Company}
                    onChange={handleChange}
                    placeholder="Enter the Company"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">VendorType</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="vendorType"
                    value={formData.vendorType}
                    onChange={handleChange}
                    placeholder="Enter the vendorType"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">ContactPerson</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="ContactPerson"
                    value={formData.ContactPerson}
                    onChange={handleChange}
                    placeholder="Enter the ContactPerson"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="vendor">Specialization</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Specialization"
                    value={formData.Specialization}
                    onChange={handleChange}
                    placeholder="Enter the Specialization"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">Designation</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Designation"
                    value={formData.Designation}
                    onChange={handleChange}
                    placeholder="Enter the Designation"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">Email</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Enter the Email"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="vendor">DialCode</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="DialCode"
                    value={formData.DialCode}
                    onChange={handleChange}
                    placeholder="Enter the DialCode"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">Phone</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Phone"
                    value={formData.Phone}
                    onChange={handleChange}
                    placeholder="Enter the Phone"
                  />{" "}
                </div>
              
                <div className="input-box">
                  <span className="vendor">Country</span>
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Country"
                    value={formData.Country}
                    onChange={handleChange}
                    placeholder="Enter the Country"
                  />{" "} */}
                   <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Country"
                    value={formData.Country}
                    onChange={(e) => {
                      handleCountryChange(e);
                      handleChange(e);
                    }}
                  >
                    <option>Select Country</option>
                    {countryData.map((item) => (
                      <option value={item.Country_name}>
                        {item.Country_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="vendor">City</span>{" "}
                  {/* Label for Description input */}
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    placeholder="Enter the City"
                    align-content="center"
                  />{" "} */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                  >
                    <option>Select City</option>
                    {cityData.map((item) => (
                      <option value={item.City_name}>{item.City_name}</option>
                    ))}
                  </select>
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="vendor">VendorOwner</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="VendorOwner"
                    value={formData.VendorOwner}
                    onChange={handleChange}
                    placeholder="Enter the VendorOwner"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="vendor">Remarks</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleChange}
                    placeholder="Enter the Remarks"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddVendor;
