import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import {MasterDataAPIInstance} from "../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./Addcountry.css";
const Addcountry = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);

  const toast = useToast();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userId: JSON.parse(localStorage.getItem("user")).userId,
    Country_code: "",
    Country_name: "",
    Phone_code: "",
    Capital: "",
    Currency: "",
    Region: "",
    Subregion: "",
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await MasterDataAPIInstance.post(
      `addMasterData/add_country?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-right",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/country");
    } else {
      toast({
        title: data.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await MasterDataAPIInstance.post(
        `addMasterData/add_country?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header />
      <form>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Country</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/country");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Counrty Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-country">
                <div className="input-box">
                  <span className="country">
                    Country Code<span style={{ color: "red" }}>*</span>
                  </span>  
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Country_code"
                    value={formData.Country_code}
                    onChange={handleChange}
                    placeholder="Enter your country code"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Country Name<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Country_name"
                    value={formData.Country_name}
                    onChange={handleChange}
                    placeholder="Enter your country name"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Phone Code<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Phone_code"
                    value={formData.Phone_code}
                    onChange={handleChange}
                    placeholder="Enter your phone code"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Capital<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Capital"
                    value={formData.Capital}
                    onChange={handleChange}
                    placeholder="Enter your capital"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Currency<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Currency"
                    value={formData.Currency}
                    onChange={handleChange}
                    placeholder="Enter your currency"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Region<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Region"
                    value={formData.Region}
                    onChange={handleChange}
                    placeholder="Enter your region"
                  />
                </div>
                <div className="input-box">
                  <span className="country">
                    Subregion<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Subregion"
                    value={formData.Subregion}
                    onChange={handleChange}
                    placeholder="Enter your subregion"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Addcountry;
