import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance";
import { Textarea, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./NoticeOfArrival.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { Header } from "../../../components";

const AddNoaCreation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  //const [formDataIndex, setFormDataIndex] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [descriptionType, setDescriptionType] = useState("Single");
  const [PortData, setportData] = useState([]);
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [editedNoaData, setEditedNoaData] = useState({});
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("SDJ:", selectedJob);
  const [selectedNoaData, setSelectedNoaData] = useState({});
  const [packageTypeData, setPackageTypeData] = useState([]);

  useEffect(() => {
    if (selectedJob) {
      setSelectedNoaData({
        JobNumber: selectedJob.JobNumber,
        BookingNumber: selectedJob.BookingNumber || "",
        JobInfoNo: selectedJob.JobInfono || "",
        NoaNumber: "",
        CompanyName: "",
        reference: "",
        PlaceOfReceipt: selectedJob.PlaceOfReceipt || "",
        Notify_Party: "",
        POL: selectedJob.POL || "",
        POD: selectedJob.POD || "",
        MBLNumber: "",
        HBLNumber: "",
        PlaceOfDelivery: selectedJob.PlaceOfDelivery || "",
        Carrier: "",
        VesselNameVoyage: selectedJob.VesselNameVoyage || "",
        OtherNumIdentification: "",
        Date: "",
        Consignee: selectedJob.Consignee || "",
        ShipperExport: selectedJob.Shipper || "",
        Marks_Number: "",
        EstCargoDate: "",
        RailBond_PickupNo: "",
        CargoPickupLocation: "",
        TareWeight: "",
        ETA: "",
        Size_Type_Height:"",
        Pkge:"",
        Measurement:"",
        NoaOwner: JSON.parse(localStorage.getItem("user")).userId,
        type: descriptionType,
        Operations:
        selectedJob.Containers && selectedJob.Containers !== ""
          ? selectedJob.Containers.split(";").map((container) => {
              const containerObj = {};
              container.split(",").forEach((attribute) => {
                const [key, value] = attribute
                  .trim()
                  .split(":")
                  .map((item) => item.trim());
                containerObj[key] = value === "" ? null : value;
              });
              return containerObj;
            })
          : [],
      });
    }
  }, [selectedJob, descriptionType]);
  const [formData, setFormData] = useState(selectedNoaData);
console.log("FORMDATA.nOA",formData)
  useEffect(() => {
    // Update formData with editedNoaData
    setFormData(editedNoaData);
  }, [editedNoaData, descriptionType]);

  const handleSingleDescription = (value) => {
    console.log(descriptionType);
    console.log(value);
    if (descriptionType === "Single" && value) {
      const updatedOperationsWithDescription = editedNoaData.Operations.map(
        (operation) => ({
          ...operation,
          descriptionOfGoods: value,
        })
      );
      setEditedNoaData((prevState) => ({
        ...prevState,
        type: "Single",
        Operations: updatedOperationsWithDescription,
      }));
    }
  };
  const handleDescriptionChange = (index, value) => {
    const updatedOperations = [...editedNoaData.Operations];
    updatedOperations[index].descriptionOfGoods = value;
    setEditedNoaData((prevState) => ({
      ...prevState,
      type: "Containerwise",
      Operations: updatedOperations,
    }));
  };

  useEffect(() => {
    setEditedNoaData(selectedNoaData);
  }, [selectedNoaData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          ponoation: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedNoaData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePolChange = (e) => {
    setEditedNoaData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedNoaData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };

  const handleConsigneeChange = (e) => {
    setEditedNoaData((prevData) => ({
      ...prevData,
      Consignee: e.target.value,
    }));
  };
const handleNotifyPartyChange = (e) => {
    setEditedNoaData((prevData) => ({
      ...prevData,
      Notify_Party: e.target.value,
    }));
  };
  const handleShipperExportChange = (e) => {
    setEditedNoaData((prevData) => ({
      ...prevData,
      ShipperExport: e.target.value,
    }));
  };
  // End
  //const Pagerefresh = () => window.location.reload(true);
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log(formData, "form");
  // };
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.post(
      `add_noacreation?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        ponoation: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        ponoation: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedNoaData.Operations];
    updatedOperations[index][key] = value;
    setEditedNoaData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown}>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Notice of Arrival</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Notice of Arrival Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-noacreations">
                {/* NoaNumber */}
                {/* <div className="input-box">
                  <span className="noacreations">
                  NoaNumber<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="NoaNumber"
                    defaultValue={
                        formData["NoaNumber"] || selectedNoaData["NoaNumber"]
                      }
                      value={editedNoaData["NoaNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your NoaNumber"
                    disabled
                  />
                </div> */}
                {/* CompanyName */}
                <div className="input-box">
                  <span className="noacreations">
                    CompanyName
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CompanyName"
                    defaultValue={
                      formData["CompanyName"] || selectedNoaData["CompanyName"]
                    }
                    value={editedNoaData["CompanyName"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your CompanyName"
                  />
                  {/* <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["CompanyName"] || selectedNoaData["CompanyName"]
                    }
                    value={editedNoaData["CompanyName"] || ""}
                    onChange={handleCompanyChange}
                  >
                    <option>Select CompanyName</option>
                  
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select> */}
                </div>
                {/* PlaceOfReceipt */}
                <div className="input-box">
                  <span className="noacreations">
                    Place of Receipt
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfReceipt"
                    defaultValue={
                      formData["PlaceOfReceipt"] ||
                      selectedNoaData["PlaceOfReceipt"]
                    }
                    value={editedNoaData["PlaceOfReceipt"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfReceipt"
                  />
                </div>
                {/* Notify_Party */}
                <div className="input-box">
                  <span className="noacreations">
                    Notify Party
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party"
                    defaultValue={
                      formData["Notify_Party"] ||
                      selectedNoaData["Notify_Party"]
                    }
                    value={editedNoaData["Notify_Party"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify_Party"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Notify_Party"] || selectedNoaData["Notify_Party"]
                    }
                    value={editedNoaData["Notify_Party"] || ""}
                    onChange={handleNotifyPartyChange}
                  >
                    <option>Select Notify_Party</option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                {/* POL */}
                <div className="input-box">
                  <span className="noacreations">
                    POL
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedNoaData["POL"]}
                    value={editedNoaData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option value={formData["POL"] || selectedNoaData["POL"]}>
                      {formData["POL"] || selectedNoaData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                {/* POD */}
                <div className="input-box">
                  <span className="noacreations">POD</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedNoaData["POD"]}
                    value={editedNoaData["POD"] || ""}
                    onChange={handlePodChange}
                  >
                    placeholder="Enter your POD"
                    <option value={formData["POD"] || selectedNoaData["POD"]}>
                      {formData["POD"] || selectedNoaData["POD"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="noacreations">
                    Consignee
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Consignee"
                    defaultValue={
                      formData["Consignee"] || selectedNoaData["Consignee"]
                    }
                    value={editedNoaData["Consignee"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Consignee"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Consignee"] || selectedNoaData["Consignee"]
                    }
                    value={editedNoaData["Consignee"] || ""}
                    onChange={handleConsigneeChange}
                  >
                    <option>Select Consignee</option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                {/* MBLNumber */}
                <div className="input-box">
                  <span className="noacreations">
                    MBLNumber
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBLNumber"
                    defaultValue={
                      formData["MBLNumber"] || selectedNoaData["MBLNumber"]
                    }
                    value={editedNoaData["MBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your MBLNumber"
                  />
                </div>
                {/* reference */}
                <div className="input-box">
                  <span className="noacreations">
                    Your reference
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="reference"
                    defaultValue={
                      formData["reference"] || selectedNoaData["reference"]
                    }
                    value={editedNoaData["reference"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your reference"
                  />
                </div>
                {/* HBLNumber */}
                <div className="input-box">
                  <span className="noacreations">
                    HBLNumber
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HBLNumber"
                    defaultValue={
                      formData["HBLNumber"] || selectedNoaData["HBLNumber"]
                    }
                    value={editedNoaData["HBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your HBLNumber"
                  />
                </div>
                {/* PlaceofDelivery */}
                <div className="input-box">
                  <span className="noacreations">Place of Delivery</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfDelivery"
                    defaultValue={
                      formData["PlaceOfDelivery"] ||
                      selectedNoaData["PlaceOfDelivery"]
                    }
                    value={editedNoaData["PlaceOfDelivery"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceofDelivery"
                  />
                </div>
                {/* Carrier */}
                <div className="input-box">
                  <span className="noacreations">Carrier</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Carrier"
                    defaultValue={
                      formData["Carrier"] || selectedNoaData["Carrier"]
                    }
                    value={editedNoaData["Carrier"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Carrier"
                  />
                </div>
                {/* VesselNameVoyage */}
                <div className="input-box">
                  <span className="noacreations">Vessel and Voyage</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    defaultValue={
                      formData["VesselNameVoyage"] ||
                      selectedNoaData["VesselNameVoyage"]
                    }
                    value={editedNoaData["VesselNameVoyage"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your VesselNameVoyage"
                  />
                </div>
                {/* OtherNumIdentification */}
                <div className="input-box">
                  <span className="noacreations">OtherNumIdentification</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="OtherNumIdentification"
                    defaultValue={
                      formData["OtherNumIdentification"] ||
                      selectedNoaData["OtherNumIdentification"]
                    }
                    value={editedNoaData["OtherNumIdentification"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your OtherNumIdentification"
                  />
                </div>
                {/* Date */}
                <div className="input-box">
                  <span className="noacreations">Date</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Date"
                    defaultValue={formData["Date"] || selectedNoaData["Date"]}
                    value={editedNoaData["Date"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Date"
                  />
                </div>
                {/* Consignee */}

                {/* ShipperExport */}
                <div className="input-box">
                  <span className="noacreations">Shipper / Exporter</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ShipperExport"
                    defaultValue={
                      formData["ShipperExport"] ||
                      selectedNoaData["ShipperExport"]
                    }
                    value={editedNoaData["ShipperExport"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Shipper / Exporter"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["ShipperExport"] || selectedNoaData["ShipperExport"]
                    }
                    value={editedNoaData["ShipperExport"] || ""}
                    onChange={handleShipperExportChange}
                  >
                    <option>Select ShipperExport</option>
                  
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Marks_Number */}
                <div className="input-box">
                  <span className="noacreations">Marks and Number</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Marks_Number"
                    defaultValue={
                      formData["Marks_Number"] ||
                      selectedNoaData["Marks_Number"]
                    }
                    value={editedNoaData["Marks_Number"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Marks_Number"
                  />
                </div>
                {/* EstCargoDate */}
                <div className="input-box">
                  <span className="noacreations">EstCargoDate</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="EstCargoDate"
                    defaultValue={
                      formData["EstCargoDate"] ||
                      selectedNoaData["EstCargoDate"]
                    }
                    value={editedNoaData["EstCargoDate"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the EstCargoDate"
                  />
                </div>
                {/* RailBond_PickupNo  */}
                <div className="input-box">
                  <span className="noacreations">RailBond_PickupNo</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="RailBond_PickupNo"
                    defaultValue={
                      formData["RailBond_PickupNo"] ||
                      selectedNoaData["RailBond_PickupNo"]
                    }
                    value={editedNoaData["RailBond_PickupNo"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the RailBond_PickupNo"
                  />
                </div>
                {/* Size_Type_Height  */}
                <div className="input-box">
                  <span className="noacreations">Size_Type_Height</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Size_Type_Height"
                    defaultValue={
                      formData["Size_Type_Height"] ||
                      selectedNoaData["Size_Type_Height"]
                    }
                    value={editedNoaData["Size_Type_Height"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Size_Type_Height"
                  />
                </div>
                {/* CargoPickupLocation  */}
                <div className="input-box">
                  <span className="noacreations">CargoPickupLocation</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CargoPickupLocation"
                    defaultValue={
                      formData["CargoPickupLocation"] ||
                      selectedNoaData["CargoPickupLocation"]
                    }
                    value={editedNoaData["CargoPickupLocation"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the CargoPickupLocation"
                  />
                </div>
                {/* TareWeight  */}
                <div className="input-box">
                  <span className="noacreations">TareWeight</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TareWeight"
                    defaultValue={
                      formData["TareWeight"] || selectedNoaData["TareWeight"]
                    }
                    value={editedNoaData["TareWeight"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the TareWeight"
                  />
                </div>
                {/* TareWeight  */}
                {/* <div className="input-box">
                  <span className="noacreations">Size type Height</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TareWeight"
                    defaultValue={
                      formData["TareWeight"] || selectedNoaData["TareWeight"]
                    }
                    value={editedNoaData["TareWeight"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the TareWeight"
                  />
                </div> */}
                {/* ETA  */}
                <div className="input-box">
                  <span className="noacreations">
                    Pkge
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Pkge"
                    defaultValue={
                      formData["Pkge"] || selectedNoaData["Pkge"]
                    }
                    value={editedNoaData["Pkge"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Pkge"
                  />
                </div>
                <div className="input-box">
                  <span className="noacreations">
                  Measurement
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Measurement"
                    defaultValue={
                      formData["Measurement"] || selectedNoaData["Measurement"]
                    }
                    value={editedNoaData["Measurement"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Measurement"
                  />
                </div>
                <div className="input-box">
                  <span className="noacreations">ETA</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ETA"
                    defaultValue={formData["ETA"] || selectedNoaData["ETA"]}
                    value={editedNoaData["ETA"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the ETA"
                  />
                </div>
                {/*Container table */}
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Type
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Seal Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Number of Packages
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Type Of Package
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Net Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Gross Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Volume
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedNoaData?.Operations?.map((operation, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="ContainerType"
                              value={
                                editedNoaData["ContainerType"] ||
                                operation.ContainerType
                              }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerType",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.ContainerType}>
                                {operation.ContainerType}
                              </option>

                              {/* Render options from equipmentData */}
                              {equipmentData.map((item, idx) => (
                                <option key={idx} value={item.ContainerType}>
                                  {item.ContainerType}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="ContainerNumber"
                              defaultValue={
                                formData[operation.ContainerNumber] ||
                                selectedNoaData[operation.ContainerNumber]
                              }
                              //value={editedNoaData[operation.ContainerNumber] || ""}
                              value={operation.ContainerNumber}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="SealNumber"
                              value={operation.SealNumber}
                              defaultValue={
                                formData[operation.SealNumber] ||
                                selectedNoaData[operation.SealNumber]
                              }
                              //value={editedNoaData[operation.SealNumber] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "SealNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="No_Of_Pkge"
                              value={operation.No_Of_Pkge}
                              defaultValue={
                                formData[operation.No_Of_Pkge] ||
                                selectedNoaData[operation.No_Of_Pkge]
                              }
                              //value={editedNoaData[operation.No_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "No_Of_Pkge",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            {/* <input
                              type="text"
                              name="Type_Of_Pkge"
                              value={operation.Type_Of_Pkge}
                              defaultValue={
                                formData[operation.Type_Of_Pkge] ||
                                selectedNoaData[operation.Type_Of_Pkge]
                              }
                              //value={editedNoaData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            /> */}
                            <select
                              width="190px"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              name="Type_Of_Pkge"
                              value={operation.Type_Of_Pkge}
                              defaultValue={
                                formData[operation.Type_Of_Pkge] ||
                                selectedNoaData[operation.Type_Of_Pkge]
                              }
                              //value={editedNoaData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.Type_Of_Pkge}>
                                {operation.Type_Of_Pkge}
                              </option>

                              {/* Render options from equipmentData */}
                              {packageTypeData.map((item, idx) => (
                                <option key={idx} value={item.packingType}>
                                  {item.packingType}
                                </option>
                              ))}
                            </select>


                          </td>
                          <td>
                            <input
                              type="text"
                              name="NtWeight"
                              value={operation.NtWeight}
                              defaultValue={
                                formData[operation.NtWeight] ||
                                selectedNoaData[operation.NtWeight]
                              }
                              //value={editedNoaData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "NtWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="GrWeight"
                              value={operation.GrWeight}
                              defaultValue={
                                formData[operation.GrWeight] ||
                                selectedNoaData[operation.GrWeight]
                              }
                              //value={editedNoaData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "GrWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="Volume"
                              value={operation.Volume}
                              defaultValue={
                                formData[operation.Volume] ||
                                selectedNoaData[operation.Volume]
                              }
                              //value={editedNoaData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Volume",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {/*description part */}
                <br />
                <br />
                <div className="description-box">
                  <div className="inline-flex">
                    <button
                      type="button"
                      onClick={() => setDescriptionType("Single")}
                      className={`${
                        descriptionType === "Single"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Single Description
                    </button>

                    <button
                      type="button"
                      onClick={() => setDescriptionType("Containerwise")}
                      className={`${
                        descriptionType === "Containerwise"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Containerwise Description
                    </button>
                    <br />
                  </div>
                </div>
                <div className="description-box">
                  {descriptionType === "Single" && (
                    <div className="input-box">
                      <span className="noacreations">Description of goods</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={editedNoaData["descriptionOfGoods"]}
                        onChange={(e) =>
                          handleSingleDescription(e.target.value)
                        }
                        placeholder="Enter the description of goods"
                        rows="3" // Increased number of rows for height
                        cols="155" // Increased number of columns for width
                      />
                    </div>
                  )}
                  {descriptionType === "Containerwise" && (
                    <div className="input-box">
                      <span className="noacreations">Description of goods</span>
                      <div className="table-container">
                        <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                          <thead className="flex-nowrap text-center bg-blue-950 text-white">
                            <tr>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Container Type
                              </th>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {editedNoaData.Operations.map((operation, idx) => (
                              <tr key={idx}>
                                <td>
                                  {operation.ContainerType}-
                                  {operation.ContainerNumber}
                                </td>
                                <td>
                                  <textarea
                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                    name={`Description_${idx}`}
                                    value={operation.descriptionOfGoods || ""}
                                    onChange={(e) =>
                                      handleDescriptionChange(
                                        idx,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter the description of goods"
                                    rows="3" // You can adjust the number of rows for the height
                                    cols="125" // You can adjust the number of columns for the width
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddNoaCreation;
