export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete,      
    onOpenReportOutlookEmail,
    onOpenReportMassEmail,
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.onOpenMassupdate = onOpenMassupdate;
    this.onOpenReportDelete = onOpenReportDelete;
    this.onOpenReportOutlookEmail = onOpenReportOutlookEmail;
    this.onOpenReportMassEmail = onOpenReportMassEmail;
  }

  handleEditClickMassupdate() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
          bottom:'0px'
        },
      });
      return;
    }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenMassupdate();
  }
  handleEditClickMassdelete() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    this.onOpenReportDelete();
  }

  handleEditClickOutlookMail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.onOpenReportOutlookEmail();
  }
  handleEditClickMassmail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for Mass Mail.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the action.
    this.onOpenReportMassEmail();
  }


 
}
