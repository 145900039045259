import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Grid,
  GridItem,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Badge,
  Input,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import Select from "react-select";
import Axios, { MasterDataAPIInstance, ProfomaInvoiceAPIInstance } from "../../api-instance";
import { useToast } from "@chakra-ui/react";

const AddCreditModal = ({ isOpen, onClose, selectedTiData }) => {
  const toast = useToast();
  const [editedTaxInv, setEditedTaxInv] = useState({});
  const [productData, setProductData] = useState([]);
  const [portData, setPortData] = useState([]);  
  const [bankData, setBankData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [billToStateData, setBillToStateData] = useState([]);
  const [billToCityData, setBillToCityData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [taxdesData, setTaxdesData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const custom = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
      ...provided,
      minWidth: "200px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    if (selectedTiData) {
      let containers = [];
      try {
        containers = JSON.parse(selectedTiData.Containers);
      } catch (e) {
        containers = selectedTiData.Containers || [];
      }
      // Initialize the form data with selectedTiData
      setEditedTaxInv({ ...selectedTiData, Containers: containers });
    }
  }, [selectedTiData]);
  // Currency Data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const currencyOptions = currencyData.map((item) => ({
    value: item.Currency,
    label: `${item.Currency}`,
  }));

 // client data
 useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setClientData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

 const clientOptions = clientData.map((client) => ({
    value: client.Company,
    label: client.Company,
    billTo_Address: client.Address_Line_1,
    billTo_Country: client.Country,
    billTo_State: client.State,
    billTo_City: client.City,
    billTo_Pincode: client.Pincode,
    billTo_Gst: client.GST,
  })); 
  // const clientOptions = clientData.map((client) => ({
  //   value: client.PartnerName,
  //   label: client.PartnerName,
  //   billTo_Address: client.Addressline1+client.Addressline2,
  //   billTo_Country: client.Country,
  //   billTo_State: client.State,
  //   billTo_City: client.City,
  //   billTo_Pincode: client.PostalCode,
  //   billTo_Gst: client.GSTInfo,
  // })); 
  const handleClientChange = async (selectedOption) => {
    const client = selectedOption ? selectedOption.value : "";
    const address = selectedOption ? selectedOption.billTo_Address : "";
    const city = selectedOption ? selectedOption.billTo_City : "";
    const state = selectedOption ? selectedOption.billTo_State : "";
    const country = selectedOption ? selectedOption.billTo_Country : "";
    const pincode = selectedOption ? selectedOption.billTo_Pincode : "";
    const gst = selectedOption ? selectedOption.billTo_Gst : "";

    // Update the form data
    setEditedTaxInv((prevData) => ({
      ...prevData,
      Bill_To: client,
      BillAddress: address,
      BillAddressCity: city,
      BillAddressCountry: country,
      BillAddressState: state,
      BillAddressPincode: pincode,
      BillGst: gst,
    }));

    // Fetch state options for the selected country
    if (country) {
      try {
        const { data: stateData } = await MasterDataAPIInstance.get(
          `get_country_state?countryData=${country}`
        );
        setBillToStateData(stateData.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }

    // Fetch city options for the selected state
    if (state) {
      try {
        const { data: cityData } = await MasterDataAPIInstance.get(
          `get_state_city?stateData=${state}`
        );
        setBillToCityData(cityData.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

//type of shipment
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const productOptions = productData.map((data) => ({
    label: data.Product,
    value: data.Product,
  }));

  const handletypechange = (selectedOption) => {
    setEditedTaxInv((prevData) => ({
      ...prevData,
      TypeOfShipment: selectedOption ? selectedOption.value : "",
    }));
  };
  //pol and pod 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const portOptions = portData.map((port) => ({
    value: port.Port,
    label: port.Port,
  }));
  const handlePolChange = (selectedOption) => {
    const pol = selectedOption ? selectedOption.value : "";
    setEditedTaxInv((prevData) => ({
      ...prevData,
      POL: pol,
    }));
  };

  const handlePodChange = (selectedOption) => {
    const pod = selectedOption ? selectedOption.value : "";
    setEditedTaxInv((prevData) => ({
      ...prevData,
      POD: pod,
    }));
  };
//country state city bank data 
useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    const fetchStateOptions = async () => {
      if (editedTaxInv.BillAddressCountry) {
        try {
          const { data } = await MasterDataAPIInstance.get(
            `get_country_state?countryData=${editedTaxInv.BillAddressCountry}`
          );
          setBillToStateData(data.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };

    const fetchCityOptions = async () => {
      if (editedTaxInv.BillAddressState) {
        try {
          const { data } = await MasterDataAPIInstance.get(
            `get_state_city?stateData=${editedTaxInv.BillAddressState}`
          );
          setBillToCityData(data.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };
    const fetchBankData = async () => {
      if (editedTaxInv.TiRegion) {
        try {
          const country = editedTaxInv.TiRegion;
          const { data } = await ProfomaInvoiceAPIInstance.get(
            `get_bank?country=${country}`
          );
          setBankData(data.data);
          console.log(data.data);
          setEditedTaxInv((prevData) => ({
            ...prevData,
          }));
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };

    fetchBankData();
    fetchCountryData();
    fetchStateOptions();
    fetchCityOptions();
  }, [
    editedTaxInv.BillAddressCountry,
    editedTaxInv.BillAddressState,
    editedTaxInv.TiRegion,
  ]);
  const countryOptions = countryData.map((country) => ({
    value: country.Country_name,
    label: country.Country_name,
  }));
  const stateOptions = billToStateData.map((state) => ({
    value: state.State_name,
    label: state.State_name,
  }));
  const cityOptions = billToCityData.map((city) => ({
    value: city.City_name,
    label: city.City_name,
  }));
  const handleCountryChange = async (selectedOption) => {
    const country = selectedOption ? selectedOption.value : "";
    setEditedTaxInv((prevData) => ({
      ...prevData,
      BillAddressCountry: country,
      BillAddressState: "",
      BillAddressCity: "",
    }));

    if (country) {
      try {
        const { data } = await MasterDataAPIInstance.get(
          `get_country_state?countryData=${country}`
        );
        setBillToStateData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleStateChange = async (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setEditedTaxInv((prevData) => ({
      ...prevData,
      BillAddressState: state,
      BillAddressCity: "",
    }));

    if (state) {
      try {
        const { data } = await MasterDataAPIInstance.get(
          `get_state_city?stateData=${state}`
        );
        setBillToCityData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleCityChange = (selectedOption) => {
    const city = selectedOption ? selectedOption.value : "";
    setEditedTaxInv((prevData) => ({
      ...prevData,
      BillAddressCity: city,
    }));
  };
  const bankIdToDetailsMap = bankData.reduce((map, bank) => {
    map[bank.id] = `${bank.AccNumber} - ${bank.BankName} - ${bank.CurrencyType}`;
    return map;
  }, {});
  const handleBankDetailsChange = (e) => {
    const { value } = e.target;
    const selectedBankId =
      Object.keys(bankIdToDetailsMap).find(
        (key) => bankIdToDetailsMap[key] === value
      ) || value;
 
    setEditedTaxInv((prevData) => ({
      ...prevData,
      BankDetails: selectedBankId,
    }));
  };
//invoice date and due date 
const calculateInvoiceDate = () => {
    const currentDate = new Date();
    const invoiceDate = new Date(currentDate);
    return invoiceDate.toISOString().split("T")[0];
  };

  const calculateDueDate = (invoiceDate) => {
    const date = new Date(invoiceDate);
    date.setDate(date.getDate() + 3); // Add 3 days
    return date.toISOString().split("T")[0];
  };
  useEffect(() => {
    // Calculate and set the initial invoice date and due date if not empty
    const calculatedInvoiceDate = calculateInvoiceDate();
    if (calculatedInvoiceDate) {
      const calculatedDueDate = calculateDueDate(calculatedInvoiceDate);
      setEditedTaxInv((prevData) => ({
        ...prevData,
        Invoice_Date: calculatedInvoiceDate,
        Due_Date: calculatedDueDate,
      }));
    }
  }, []);

//tax description data 
useEffect(() => {
    const getData = async () => {
      try {
        const region = selectedTiData.TiRegion;
        const country = editedTaxInv.BillAddressCountry;
  
        // Pass PiRegion and BillAddressCountry as query parameters
        const { data } = await ProfomaInvoiceAPIInstance.get(`get_edittax?piregion=${region}&billcountry=${country}`);
        setTaxdesData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [selectedTiData.TiRegion,editedTaxInv.BillAddressCountry]);
  const taxesOption = taxdesData.map((tax) => ({
    value: tax.Description,
    label: tax.Description,
    Tax: tax.Tax,  // Use 'Tax' field from API response
    SacCode: tax.SacCode,
  }));
  const handleDescriptionChange = (selectedOption, index) => {
    const updatedContainers = (editedTaxInv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          let updatedContainer = {
            ...container,
            Description: selectedOption.value,
            Gst: selectedOption.Tax,          // Update Gst based on selected tax data
            SacCode: selectedOption.SacCode,  
          };
          // if (editedTaxInv.TiRegion === "India") {
          //   updatedContainer.Gst = selectedOption.gst;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // } else if (editedTaxInv.TiRegion === "United Kingdom") {
          //   if (editedTaxInv.BillAddressCountry === "United Kingdom") {
          //     updatedContainer.Gst = selectedOption.vat;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   } else {
          //     updatedContainer.Gst = selectedOption.no_tax;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   }
          // } else if (editedTaxInv.TiRegion === "Malaysia") {
          //   if (editedTaxInv.BillAddressCountry === "Malaysia") {
          //     updatedContainer.Gst = selectedOption.sst;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   } else {
          //     updatedContainer.Gst = selectedOption.no_tax;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   }
          // } else if (editedTaxInv.TiRegion === "Singapore") {
          //   updatedContainer.Gst = selectedOption.no_tax;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // }
          // else if (editedTaxInv.TiRegion === "Vietnam") {
          //   updatedContainer.Gst = selectedOption.no_tax;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // }
          // Update Gst based on selected description
          // updatedContainer.Gst = selectedOption.gst;

          // Calculate other values based on updated Gst
          const qty = parseFloat(updatedContainer.Qty) || 0;
          const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
          const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
          const gstValue =
            parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;
          const taxableAmount = (qty * unitPrice * exRate).toFixed(2);
          const amtInclGst = (
            parseFloat(taxableAmount) *
            (1 + gstValue / 100)
          ).toFixed(2);

          updatedContainer.TaxableAmount = taxableAmount;
          updatedContainer.AmtInclGst = amtInclGst;

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedTaxInv({ ...editedTaxInv, Containers: updatedContainers });
  };

  //container equipment data 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const equipmentOptions = equipmentData.map((item) => ({
    value: item.ContainerType,
    label: item.ContainerType,
  }));

  useEffect(() => {
    if (editedTaxInv?.ContainerType) {
      const parsedContainers = editedTaxInv?.ContainerType.split(", ").map(
        (item) => {
          const [base, qty] = item.split("-");
          return { base, qty };
        }
      );

      const updatedContainers = (editedTaxInv.Containers || []).map(
        (container, index) => {
          if (parsedContainers[index]) {
            return {
              ...container,
              Base: parsedContainers[index].base,
              Qty: parsedContainers[index].qty,
            };
          }
          return container;
        }
      );

      setEditedTaxInv({ ...editedTaxInv, Containers: updatedContainers });
    }
  }, [editedTaxInv?.ContainerType]);

  const handleCurrencyChange = ( index, selectedOption) => {
    const currency = selectedOption ? selectedOption.value : "";
    const updatedContainers = (editedTaxInv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          const updatedContainer = { ...container, Currency: currency };
          const curr =currency;
          updatedContainer.Currency = curr;
          return updatedContainer;
        }
        return container;
      });
      setEditedTaxInv({ ...editedTaxInv, Containers: updatedContainers });
  }
  
  const handleBaseChange = (selectedOption, index) => {
    const base = selectedOption ? selectedOption.value : "";
    const containerTypeString = editedTaxInv?.ContainerType || "";

    // Extract the base and qty from the containerTypeString
    const regex = new RegExp(`(${base}-\\d+)`);
    const match = containerTypeString.match(regex);
    const qty = match ? match[0].split("-")[1] : "";

    const updatedContainers = (editedTaxInv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          const updatedContainer = { ...container, Base: base, Qty: qty };

          // Recalculate TaxableAmount and AmtInclGst
          const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
          const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
          const gstValue =
            parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;

          const taxableAmount = (parseFloat(qty) * unitPrice * exRate).toFixed(
            2
          );
          const amtInclGst = (
            parseFloat(taxableAmount) *
            (1 + gstValue / 100)
          ).toFixed(2);

          updatedContainer.TaxableAmount = taxableAmount;
          updatedContainer.AmtInclGst = amtInclGst;

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedTaxInv({ ...editedTaxInv, Containers: updatedContainers });
  };


  //useeffect to get total amt 
  useEffect(() => {
    const totalAmtInclGst = editedTaxInv.Containers
      ? editedTaxInv.Containers.reduce((sum, container) => {
          if (container.DeletionFlag === "0") {
            const amtInclGst = parseFloat(container.AmtInclGst);
            return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
          }
          return sum;
        }, 0)
      : 0;

    const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

    setEditedTaxInv((prevFormData) => ({
      ...prevFormData,
      TotalInvValue: roundedTotalAmtInclGst,
    }));
  }, [editedTaxInv.Containers]);


//handle container changes
const handleContainerChange = (e, index, field) => {
    const { value } = e.target;
    console.log("bill to country updated", editedTaxInv.BillAddressCountry);
    const updatedContainers = (editedTaxInv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          let updatedContainer = { ...container, [field]: value };

          // Handle the Description field update
          if (field === "Description") {
            const selectedTaxDes = taxesOption.find(
              (tax) => tax.Description === value
            );
            if (selectedTaxDes) {
              updatedContainer.Gst = selectedTaxDes.Tax;
              updatedContainer.SacCode = selectedTaxDes.SacCode;
             
            }
          }

          if (["Qty", "UnitPrice", "Ex_Rate", "Gst", "Base"].includes(field)) {
            const qty = parseFloat(updatedContainer.Qty) || 0;
            const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
            const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
            const gstValue =
              parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;

            const taxableAmount = (qty * unitPrice * exRate).toFixed(2);
            const amtInclGst = (
              parseFloat(taxableAmount) *
              (1 + gstValue / 100)
            ).toFixed(2);

            updatedContainer.TaxableAmount = taxableAmount;
            updatedContainer.AmtInclGst = amtInclGst;
          }

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedTaxInv({ ...editedTaxInv, Containers: updatedContainers });
  };

  //add container
  const addContainer = () => {
    setEditedTaxInv((prevState) => ({
      ...prevState,
      Containers: [
        ...(prevState.Containers || []),
        {
          Ti_DescriptionNumber: "",
          Description: "",
          Currency: "",
          UnitPrice: "",
          Base: "",
          Qty: "",
          Ex_Rate: "",
          TaxableAmount: "",
          Gst: "",
          AmtInclGst: "",
          SacCode: "",
          DeletionFlag: "0",
        },
      ],
    }));
  };

//remove container
const  removeContainer = (index) => {
    setEditedTaxInv((prevState) => {
      const updatedContainers = prevState.Containers.map((container, i) => {
        if (i === index) {
          return { ...container, DeletionFlag: 1 };
        }
        return container;
      });
      return { ...prevState, Containers: updatedContainers };
    });
  };


//handlechange
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTaxInv((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

//   const handleSubmit = async (event) => {
//     //TiRegion
// console.log("form",editedTaxInv)
//     console.log(editedTaxInv.TiRegion)
//     if (editedTaxInv.TiRegion === "India") {
//       try {
//         const apikey = "de3a3a01-273a-4a81-8b75-13fe37f14dc6";
//         const userId = JSON.parse(localStorage.getItem("user")).userId;
//         const token = JSON.parse(localStorage.getItem("token"));
//         // Fetch PI data with IDs as part of the query string
//         const crnTaxResponse = await ProfomaInvoiceAPIInstance.post(
//           `get_crndata?userId=${userId}`,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: token,
//             },
//             editedTaxInv
//           }

//         );
//         const CRNTaxData = crnTaxResponse.data.data;
//         console.log("CRNTaxData:", CRNTaxData);


//         // Send the CRNTaxData to GSTZen
//         const gstzenResponse = await axios.post(
//           "https://my.gstzen.in/~gstzen/a/post-einvoice-data/einvoice-json/",
//           JSON.stringify(CRNTaxData),
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Token: apikey,
//             },
//           }
//         );
//         if (gstzenResponse.data.status === 0) {
//           toast({
//             title: gstzenResponse.data.message,
//             position: "bottom-center",
//             isClosable: true,
//             status: "error",
//             containerStyle: {
//               width: "98.5vw",
//               maxWidth: "98.5vw",
//               alignContent: "center",
//             },
//           });
//         } else if (gstzenResponse.data.status === 1) {
//           toast({
//             title: gstzenResponse.data.message,
//             position: "bottom-center",
//             isClosable: true,
//             status: "success",
//             containerStyle: {
//               width: "98.5vw",
//               maxWidth: "98.5vw",
//               alignContent: "center",
//             },
//           });
//           // Handle the success case
//           console.log("GSTZen Response:", gstzenResponse.data);
//           console.log("Data Sent to GSTZen:", CRNTaxData.DocDtls.No);

//           // Send the response along with CRNTaxData.DocDtls.No to another endpoint
//           const crnInvoiceData = {
//             ...gstzenResponse.data,
//             editedTaxInv,
//            NoteNumber: CRNTaxData.DocDtls.No,
//            NoteType:CRNTaxData.DocDtls.Typ
//           };
// console.log(crnInvoiceData)
//           try {
//             // Make the API call
//             const crnInvoiceResponse = await ProfomaInvoiceAPIInstance.post(
//               `add_note?userId=${userId}`,
//               crnInvoiceData,
//               {
//                 headers: {
//                   "Content-Type": "application/json",
//                   Authorization: token,
//                 },
//               }
//             );

//             // Check if the response status code is in the 2xx range
//             if (
//               crnInvoiceResponse.status >= 200 &&
//               crnInvoiceResponse.status < 300
//             ) {
//               const isSuccess =
//               crnInvoiceResponse.data.success === "true" ||
//               crnInvoiceResponse.data.success === true;

//               if (isSuccess) {
//                 toast({
//                   title: crnInvoiceResponse.data.data.message,
//                   position: "bottom-center",
//                   isClosable: true,
//                   status: "success",
//                   containerStyle: {
//                     width: "98.5vw",
//                     maxWidth: "98.5vw",
//                     alignContent: "center",
//                   },
//                 });
                
//               } else {
//                 // Show an error toast if the success field indicates failure
//                 toast({
//                   title:
//                   crnInvoiceResponse.data.message || "Something went wrong.",
//                   position: "bottom-center",
//                   isClosable: true,
//                   status: "error",
//                   containerStyle: {
//                     width: "98.5vw",
//                     maxWidth: "98.5vw",
//                     alignContent: "center",
//                   },
//                 });
//               }
//             } else {
//               // Handle non-2xx status codes
//               toast({
//                 title: crnInvoiceResponse.data.message,
//                 position: "bottom-center",
//                 isClosable: true,
//                 status: "error",
//                 containerStyle: {
//                   width: "98.5vw",
//                   maxWidth: "98.5vw",
//                   alignContent: "center",
//                 },
//               });
//             }
//           } catch (error) {
//             // Handle network or unexpected errors
//             toast({
//               title: error.response?.data?.message,
//               position: "bottom-center",
//               isClosable: true,
//               status: "error",
//               containerStyle: {
//                 width: "98.5vw",
//                 maxWidth: "98.5vw",
//                 alignContent: "center",
//               },
//             });
//           }
//         } else {
//           // Handle the success case or other cases if needed
//           console.log("GSTZen Response:", gstzenResponse.data);
//           console.log("Data Sent to GSTZen:", CRNTaxData);
//         }
     
//       } catch (error) {
//         const errorMessage =
//           error.response?.data?.message || "An unexpected error occurred.";
//         toast({
//           title: errorMessage, // Show an error toast notification
//           position: "bottom-right",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     } 

//     else {
//       const userId = JSON.parse(localStorage.getItem("user")).userId;
//       const token = JSON.parse(localStorage.getItem("token"));

      
      
//       try {
//         // Fetch tax PI data
//          const userId = JSON.parse(localStorage.getItem("user")).userId;
//         const token = JSON.parse(localStorage.getItem("token"));
//         // Fetch PI data with IDs as part of the query string
//         const crnTaxResponse = await ProfomaInvoiceAPIInstance.post(
//           `get_crndata?userId=${userId}`,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: token,
//             },
//             editedTaxInv
//           }

//         );

//         const CRNTaxData = crnTaxResponse.data.data;
//         console.log("SSSS CRNTaxData:", CRNTaxData);
//          //const CRNInvoiceData={ crnTaxResponse.data.data,editedTaxInv,NoteType:"CRN"};
//         // Post the tax invoice data
//         // editedTaxInv={editedTaxInv,NoteType:"CRN"}
//         const crnInvoiceData = {
//           ...CRNTaxData, // Correct property name
//           editedTaxInv
//         };
//         const crnInvoiceResponse = await ProfomaInvoiceAPIInstance.post(
//           `add_note?userId=${userId}`,
//           crnInvoiceData,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: token,
//             },
//           }
//         );
//         // Check if the response status code is in the 2xx range
//         if (crnInvoiceResponse.status >= 200 && crnInvoiceResponse.status < 300) {
//           const isSuccess =
//           crnInvoiceResponse.data.success === "true" ||
//           crnInvoiceResponse.data.success === true;
    
//           if (isSuccess) {
//             toast({
//               title: crnInvoiceResponse.data.message,
//               position: "bottom-center",
//               isClosable: true,
//               status: "success",
//               containerStyle: {
//                 width: "98.5vw",
//                 maxWidth: "98.5vw",
//                 alignContent: "center",
//               },
//             });
//           } else {
//             // Show an error toast if the success field indicates failure
//             toast({
//               title: crnInvoiceResponse.data.message || "Something went wrong.",
//               position: "bottom-center",
//               isClosable: true,
//               status: "error",
//               containerStyle: {
//                 width: "98.5vw",
//                 maxWidth: "98.5vw",
//                 alignContent: "center",
//               },
//             });
//           }
//         } else {
//           // Handle non-2xx status codes
//           toast({
//             title: crnInvoiceResponse.data.message || "Failed to process request.",
//             position: "bottom-center",
//             isClosable: true,
//             status: "error",
//             containerStyle: {
//               width: "98.5vw",
//               maxWidth: "98.5vw",
//               alignContent: "center",
//             },
//           });
//         }
//       } catch (error) {
//         // Handle network or unexpected errors
//         toast({
//           title: error.response?.data?.message || "Network error occurred.",
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     }
//     onClose()
//   };
const handleSubmit = async (event) => {
  event.preventDefault();
  setIsSubmitting(true);
  try {
    console.log("form", editedTaxInv);

    // Making the POST request to the API
    const { data } = await ProfomaInvoiceAPIInstance.post(
      `add_crn_full?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      editedTaxInv,  // Sending the editedTaxInv data as the body of the POST request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");

    // Handling the API response
    if (data.data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      // Optionally navigate to another page
      // navigate("/proforma_invoice");
    } else {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  } catch (error) {
    // Handling any errors that occur during the API request
    console.error("Error during the form submission:", error);

    toast({
      title: "An error occurred. Please try again later.",
      position: "bottom-center",
      isClosable: true,
      status: "error",
      containerStyle: {
        width: "98.5vw",
        maxWidth: "98.5vw",
        alignContent: "center",
      },
    });
  }finally {
    setIsSubmitting(false); // Re-enable the button regardless of success or failure
  }
  onClose();
};


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full"  scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Credit Note
           &nbsp;  <Badge variant="solid" colorScheme="red" fontSize="12pt" >
                Tax Number : {editedTaxInv["TaxNumber"]}
              </Badge></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {editedTaxInv && Object.keys(editedTaxInv).length > 0 && (
            <form>
              
              <Grid templateColumns="repeat(5, 1fr)" gap={1}>
                <GridItem colSpan={1}>
                  <FormControl>
                  <h2
  style={{
    float: "left",
    color: "#172554",
    fontSize: "20px",
    borderRadius: "10px",
    fontFamily: "cursive",
    paddingBottom: "10px", // space for the border
    position: "relative",
  }}
>
  Job Details<span style={{ color: "red" }}>*</span>
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4px", // height of the border
      borderRadius: "10px",
      background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
    }}
  />
</h2>
<br/>
<br/>
<FormLabel>Job Number</FormLabel>
                    <Input
                      name="JobNumber"
                      value={editedTaxInv.JobNumber || ""}
                      onChange={handleChange}
                    />
                    <FormLabel>POL</FormLabel>
                      <Select
                        isClearable
                        options={portOptions}
                        defaultValue={portOptions.find(
                          (option) => option.value === editedTaxInv.POL
                        )}
                        value={portOptions.find(
                          (option) => option.value === editedTaxInv.POL
                        )}
                        onChange={handlePolChange}
                        classNamePrefix="react-select"
                        styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                        noOptionsMessage={() => "No options"}
                      />
                       <FormLabel>ETA</FormLabel>
                        <Input
                          type="date"
                          name="ETA"
                          defaultValue={
                            (
                                editedTaxInv["ETA"] ||
                              ""
                            )?.split("T")[0] || ""
                          }
                          onChange={handleChange}
                          readOnly
                        />
                         <FormLabel>HBL</FormLabel>
                        <Input
                          type="text"
                          name="HBL"
                          value={editedTaxInv["HBL"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                         <FormLabel>Due Date</FormLabel>
                    <Input
                      type="date"
                      name="Due_Date"
                      value={(editedTaxInv.Due_Date || "").split("T")[0]}
                      onChange={handleChange}
                    />
                 
                 <br/><br/>
                   <h2
  style={{
    float: "left",
    color: "#172554",
    fontSize: "20px",
    borderRadius: "10px",
    fontFamily: "cursive",
    paddingBottom: "10px", // space for the border
    position: "relative",
  }}
>
  Client Details<span style={{ color: "red" }}>*</span>
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4px", // height of the border
      borderRadius: "10px",
      background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
    }}
  />
</h2>
<br/><br/>
                       
                        <FormLabel>Client</FormLabel>
                        <Select
                          isClearable
                          options={clientOptions}
                          value={clientOptions.find(
                            (option) => option.value === editedTaxInv.Bill_To
                          )}
                          onChange={handleClientChange}
                          classNamePrefix="react-select"
                          styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          noOptionsMessage={() => "No options"}
                        //   isDisabled={true}
                        />
                         <FormLabel>Client State</FormLabel>
                        <Select
                          isClearable
                          options={stateOptions}
                          value={stateOptions.find(
                            (option) =>
                              option.value === editedTaxInv.BillAddressState
                          )}
                          onChange={handleStateChange}
                          classNamePrefix="react-select"
                          styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          noOptionsMessage={() => "No options"}
                          //isDisabled={true}
                        />
                         <FormLabel>Client Tax</FormLabel>
                        <Input
                          type="text"
                          name="BillGst"
                          value={editedTaxInv["BillGst"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                       
                       <FormLabel>Bank Details<span style={{ color: "red" }}>*</span></FormLabel>
                    <Input
                      name="BankDetails"
                      value={
                        bankIdToDetailsMap[editedTaxInv.BankDetails] ||
                        editedTaxInv.BankDetails
                      }
                      onChange={handleBankDetailsChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem gap={1} colSpan={1}  colStart={3} colEnd={4}>
                  <FormControl>
                    <br/><br/>
                    <FormLabel>Booking Number</FormLabel>
                    <Input
                      name="BookingNumber"
                      value={editedTaxInv.BookingNumber || ""}
                      onChange={handleChange}
                    />
                    
                    <FormLabel>POD</FormLabel>
                      <Select
                        isClearable
                        options={portOptions}
                        defaultValue={portOptions.find(
                          (option) => option.value === editedTaxInv.POD
                        )}
                        value={portOptions.find(
                          (option) => option.value === editedTaxInv.POD
                        )}
                        onChange={handlePodChange}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "No options"}
                        styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                      
                     <FormLabel>ETD</FormLabel>
                        <Input
                          type="date"
                          name="ETD"
                          defaultValue={
                            (
                                editedTaxInv["ETD"] ||
                              
                              ""
                            )?.split("T")[0] || ""
                          }
                          onChange={handleChange}
                          readOnly
                        />
                            <FormLabel>Vessel Name Voyage</FormLabel>
                      <Input
                        type="text"
                        name="VesselNameVoyage"
                        value={editedTaxInv["VesselNameVoyage"] || ""}
                        onChange={handleChange}
                        readOnly
                      />
                      <br/><br/><br/><br/><br/><br/><br/>
                        <FormLabel>Client Address</FormLabel>
                        <Input
                          type="text"
                          name="BillAddress"
                          value={editedTaxInv["BillAddress"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <FormLabel>Client City</FormLabel>
                        <Select
                          isClearable
                          options={cityOptions}
                          value={cityOptions.find(
                            (option) =>
                              option.value === editedTaxInv.BillAddressCity
                          )}
                          onChange={handleCityChange}
                          classNamePrefix="react-select"
                          styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          noOptionsMessage={() => "No options"}
                          //isDisabled={true}
                        />
                        <br/><br/><br/>
                         <FormLabel>Exchange Rate({editedTaxInv["TiCurrency"]||""})<span style={{ color: "red" }}>*</span></FormLabel>
                        <Input
                    
                    type="number"
                    name="ExchangeRate"
                    value={editedTaxInv["ExchangeRate"] || ""}
                    onChange={handleChange}
                    placeholder="Enter exchange rate"
                  />
                        {/* <FormLabel>Total Invoice Value</FormLabel>
                      <Input
                        type="text"
                        name="TotalInvValue"
                        value={editedTaxInv.TotalInvValue}
                        onChange={(e) =>
                          setEditedTaxInv({
                            ...editedTaxInv,
                            TotalInvValue: e.target.value,
                          })
                        }
                      /> */}
                    
                  </FormControl>
                </GridItem>
                <GridItem gap={1} colSpan={1}  colStart={5} colEnd={6}>
                  <FormControl>
                    <br/><br/>
                    <FormLabel>Type Of Shipment</FormLabel>
                    <Select
                      name="TypeOfShipment"
                      options={productOptions}
                      defaultValue={productOptions.find(
                        (data) => data.value === editedTaxInv.TypeOfShipment
                      )}
                      value={productOptions.find(
                        (data) => data.value === editedTaxInv.TypeOfShipment
                      )}
                      onChange={handletypechange}
                      classNamePrefix="react-select"
                      styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      noOptionsMessage={() => "No options"}
                      isDisabled={false}
                    />
                    <FormLabel>Container type</FormLabel>
                      <Input
                        type="text"
                        name="ContainerType"
                        value={editedTaxInv["ContainerType"] || ""}
                        onChange={handleChange}
                        readOnly
                      />
                     <FormLabel>MBL</FormLabel>
                        <Input
                          type="text"
                          name="MBL"
                          value={editedTaxInv["MBL"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                       
                       <FormLabel>Invoice Date</FormLabel>
                    <Input
                      type="date"
                      name="Invoice_Date"
                      value={(editedTaxInv.Invoice_Date || "").split("T")[0]}
                      onChange={handleChange}
                    />
                     
                 <br/><br/><br/><br/><br/><br/><br/>
                 <FormLabel>Client Country</FormLabel>
                        <Select
                          isClearable
                          options={countryOptions}
                          value={countryOptions.find(
                            (option) =>
                              option.value === editedTaxInv.BillAddressCountry
                          )}
                          onChange={handleCountryChange}
                          classNamePrefix="react-select"
                          styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          noOptionsMessage={() => "No options"}
                          //isDisabled={true}
                        />
                 <FormLabel>Client Pincode</FormLabel>
                        <Input
                          type="text"
                          name="BillAddressPincode"
                          
                          value={editedTaxInv["BillAddressPincode"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <br/><br/><br/>
                     <FormLabel>Remark</FormLabel>
                      <Textarea
                          type="text"
                          name="Remark"
                          value={editedTaxInv["Remark"] || ""}
                          onChange={handleChange}
                          // readOnly
                        />
                    </FormControl></GridItem>
              </Grid>
              <br/><br/>
              <div className="table-container">
                    <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                      <thead className="flex-nowrap text-center">
                        <tr>
                          {/* <th className="border border-slate-300 whitespace-nowrap text-left">Container Type</th> */}
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Description
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Currency
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Unit Price
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Base
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Qty
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Ex Rate
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Taxable Amount
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Tax
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Amount Incl Tax
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                      {editedTaxInv?.Containers?.every(container => container.DeletionFlag === 1) ? (
                      //this will render a add container when all the deletionflag is 1
     addContainer()
  ) : (
    // Render the rows for containers with DeletionFlag !== 1
    editedTaxInv?.Containers?.map((container, index) =>
      container.DeletionFlag !== 1 && (
                              <tr key={index}>
                                <td>
                                  <Select
                                    styles={custom}
                                    options={taxesOption}
                                    name={`Description-${index}`}
                                    value={
                                      taxesOption.find(
                                        (option) =>
                                          option.value === container.Description
                                        
                                      ) 
                                    }
                                    onChange={(selectedOption) =>
                                      handleDescriptionChange(
                                        selectedOption,
                                        index
                                      )
                                    }
                                    menuPortalTarget={document.body} // Render the dropdown menu in the body
                                    menuPosition="fixed"
                                  />
                                </td>
                                <td>
                                  {/* <Input
                                    name={`Currency-${index}`}
                                    type="text"
                                    value={container.Currency}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "Currency"
                                      )
                                    }
                                  /> */}
                                   <Select
                              name={`Currency-${index}`}
                              value={currencyOptions.find(
                                (option) => option.value === container.Currency
                              )|| null }
                              onChange={(selectedOption) =>
                                handleCurrencyChange(
                                  index,
                                  selectedOption)
                              }
                              options={currencyOptions}
                              styles={custom}
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                            />
                                </td>
                                <td>
                                  <Input
                                    width={150}
                                    name={`UnitPrice-${index}`}
                                    type="number"
                                    value={container.UnitPrice}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "UnitPrice"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <td>
                                    <Select
                                      styles={custom}
                                      options={equipmentOptions}
                                      name={`Base-${index}`}
                                      value={
                                        equipmentOptions.find(
                                          (option) =>
                                            option.value === container.Base
                                        ) || null
                                      }
                                      onChange={(selectedOption) =>
                                        handleBaseChange(selectedOption, index)
                                      }
                                      menuPortalTarget={document.body} // Render the dropdown menu in the body
                                      menuPosition="fixed"
                                    />
                                  </td>
                                </td>
                                <td>
                                  <Input
                                    width={100}
                                    name={`Qty-${index}`}
                                    type="number"
                                    value={container.Qty}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Qty")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`Ex_Rate-${index}`}
                                    type="number"
                                    value={container.Ex_Rate}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Ex_Rate")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`TaxableAmt-${index}`}
                                    type="text"
                                    value={container.TaxableAmount}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "TaxableAmount"
                                      )
                                    }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Input
                                    width={150}
                                    name={`Gst-${index}`}
                                    type="text"
                                    value={container.Gst}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Gst")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`AmtInclGst-${index}`}
                                    type="text"
                                    value={container.AmtInclGst}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "AmtInclGst"
                                      )
                                    }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <BiSolidMinusCircle
                                      className="cursor-pointer"
                                      size={30}
                                      style={{
                                        marginTop: "15px",
                                        color: "red",
                                      }}
                                      onClick={() => removeContainer(index)}
                                    />
                                    <IoMdAddCircle
                                      className="cursor-pointer"
                                      size={30}
                                      style={{
                                        marginTop: "15px",
                                        color: "green",
                                      }}
                                      onClick={addContainer}
                                    />
                                  </div>
                                </td>
                              </tr>
      )
                            )
                          )}
                           <tr>
                        <td colSpan={7}></td>
                        <td className="font-medium"> Total Incl Value :</td>
                        <td>
                          <Input
                            w="120px"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            type="number"
                            name="TotalInvValue"
                            value={editedTaxInv.TotalInvValue}
                            onChange={(e) =>
                              setEditedTaxInv({
                                ...editedTaxInv,
                                TotalInvValue: e.target.value,
                              })}
                            placeholder="Enter your TotalInvValue"
                            readOnly
                          />
                        </td>
                        <td></td>
                      </tr>
</tbody>
                    </table>
                  </div>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Create Credit Note"}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditModal;
