import React, { useEffect, useState } from "react";
import { OperationDashboardAPIInstance } from "../../../api-instance";
import ReactApexChart from "react-apexcharts";
import { Box } from "@chakra-ui/react";
import "./Home.css";

const RegionIncotermModule = () => {
  const [regions, setRegions] = useState([]);
  const [incoterms, setIncoterms] = useState([]);
  const [matrixData, setMatrixData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const token = JSON.parse(localStorage.getItem("token"));

      if (user && token) {
        const { data } = await OperationDashboardAPIInstance.get(
          `get_regionandincotermdata?userId=${user.userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        console.log("Fetched data:", data); // Debugging log

        // Extract unique regions and incoterms
        const specifiedRegions = [
          "Far East",
          "Europe",
          "Africa",
          "American Region",
        ];
        const uniqueRegions = Array.from(
          new Set(
            data.data
              .map((item) => item.RegionCategory)
              .filter((region) => specifiedRegions.includes(region))
          )
        );
        const uniqueIncoterms = Array.from(
          new Set(data.data.map((item) => item.IncotermCategory))
        );

        // Format matrix data
        const formattedMatrixData = uniqueRegions.map((region) => ({
          name: region,
          data: uniqueIncoterms.map((incoterm) => {
            const match = data.data.find(
              (item) =>
                item.IncotermCategory === incoterm &&
                item.RegionCategory === region
            );
            return {
              x: incoterm,
              y: match ? match.JobCount : 0,
            };
          }),
        }));

        console.log("Unique Regions:", uniqueRegions); // Debugging log
        console.log("Unique Incoterms:", uniqueIncoterms); // Debugging log
        console.log("Formatted Matrix Data:", formattedMatrixData); // Debugging log

        setRegions(uniqueRegions);
        setIncoterms(uniqueIncoterms);
        setMatrixData(formattedMatrixData);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError("User or token not found");
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(error.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <ApexHeatmap regions={regions} incoterms={incoterms} data={matrixData} />
    </div>
  );
};

const ApexHeatmap = ({ regions, incoterms, data }) => {
  // Debugging logs to understand the structure of incoming props
  console.log("Incoming props - regions:", regions);
  console.log("Incoming props - incoterms:", incoterms);
  console.log("Incoming props - data:", data);

  // Ensure data is not undefined or empty before accessing it
  if (!regions || !incoterms || !data || data.length === 0) {
    return <div>No data available</div>;
  }

  const chartOptions = {
    chart: {
      height: 350,
      width: 1200, // Increase the width of the chart
      type: "heatmap",
      margin: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val !== null ? val.toString() : ""; // Handle null or undefined values
      },
      style: {
        colors: ["#000000"],
      },
    },
    colors: ["#93c5fd"], // Change color to light blue
    title: {
      text: "Region and Incoterm Data",
      align: "center",
    },
    xaxis: {
      categories: incoterms,
      title: {
        text: "Incoterm",
      },
    },
    yaxis: {
      categories: regions,
      title: {
        text: "Region",
      },
    },
  };

  console.log("Chart series:", data); // Debugging log

  return (
    <div>
      <Box
        className="main-card"
        border="1px solid #ccc"
        borderRadius="md"
        p={4}
        shadow="md"
        bg="white"
        maxW="90%"
        mx="auto"
      >
        {/* Card Title */}
        <Box as="h2" fontSize="xl" fontWeight="bold" mb={4}>
          Incoterm Vs Region wise Job
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <ReactApexChart
            options={chartOptions}
            series={data}
            type="heatmap"
            height={350}
          />
        </Box>
      </Box>
    </div>
  );
};

export default RegionIncotermModule;
