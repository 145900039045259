import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  ProfomaInvoiceAPIInstance,
} from "../../api-instance";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
//import "./AddInterCompanyPi.css";
import Select from "react-select";
import { GiCancel, GiConsoleController } from "react-icons/gi";
import {
  Button,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  Textarea,
} from "@chakra-ui/react";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
const AddInterCompanyPi = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const [proinvId, setProinvId] = useState(uuidv4());
  const location = useLocation();
 const [countryData, setCountryData] = useState([]);
  const [billToStateData, setBillToStateData] = useState([]);
  const [billToCityData, setBillToCityData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [showOtherDescriptionInput, setShowOtherDescriptionInput] = useState(
    []
  );
  const [otherDescriptions, setOtherDescriptions] = useState({});
  const [descriptionOptions, setDescriptionOption] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Generated Date Auto
  const current = new Date();

  const [piRegionOptions, setPiRegionOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const {
    isOpen: isOpenCancelProforma,
    onOpen: onOpenCancelProforma,
    onClose: onCloseCancelProforma,
  } = useDisclosure();

  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formData, setFormData] = useState({
    ProinvId: proinvId,
    ProinvNumber: "",
    TypeOfShipment: "",
    JobNumber: "",
    BookingNumber: "",
    MBL: "",
    HBL: "",
    POL: "",
    POD: "",
    Invoice_Date: "",
    Due_Date: "",
    PiOwner: JSON.parse(localStorage.getItem("user")).userId,
    ETA: "",
    ETD: "",
    ContainerType: "",
    VesselNameVoyage: "",
    CompanySelection: "",
    CompanyId: "",
    BankDetails: "",
    BankId: "",
    PiContainers: [],
    TotalInvValue: "",
    Bill_to: "",
    BillAddress: "",
    BillAddressCountry: "",
    BillAddressState: "",
    BillAddressCity: "",
    BillAddressPincode: "",
    BillGst: "",
    ExchangeRate: "",
    PiRegion: "",
    PiState: "",
    Container_No: "",
    Shipper: "",
    Consignee: "",
    PiTax: "",
    Remark:"",
    PiCurrency:"",
  });
  const [PiContainers, setPiContainers] = useState([
    {
      OtherDescription: "",
      Description: "",
      Currency: "",
      UnitPrice: "",
      Base: "",
      Qty: "",
      Ex_Rate: "",
      TaxableAmount: "",
      Gst: "",
      SacCode: "",
      AmtInclGst: "",
    },
  ]);
  const [containers, setContainers] = useState([{}]);

  console.log("forms", formData);

  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
      ...provided,
      minWidth: "200px",
    }),
  };

  useEffect(() => {
    // Calculate and set the initial invoice date and due date if not empty
    const calculatedInvoiceDate = calculateInvoiceDate();
    if (calculatedInvoiceDate) {
      const calculatedDueDate = calculateDueDate(calculatedInvoiceDate);
      setFormData((prevData) => ({
        ...prevData,
        Invoice_Date: calculatedInvoiceDate,
        Due_Date: calculatedDueDate,
      }));
    }
  }, []);

  useEffect(() => {
    // Update due date whenever invoice date changes
    if (formData.Invoice_Date) {
      const calculatedDueDate = calculateDueDate(formData.Invoice_Date);
      setFormData((prevData) => ({
        ...prevData,
        Due_Date: calculatedDueDate,
      }));
    }
  }, [formData.Invoice_Date]);

  const calculateInvoiceDate = () => {
    const currentDate = new Date();
    const invoiceDate = new Date(currentDate);
    return invoiceDate.toISOString().split("T")[0];
  };

  const calculateDueDate = (invoiceDate) => {
    const date = new Date(invoiceDate);
    date.setDate(date.getDate() + 3); // Add 3 days
    return date.toISOString().split("T")[0];
  };

  const handlePolChange = (selectedOption) => {
    const pol = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, POL: pol });
  };
  const handlePodChange = (selectedOption) => {
    const pod = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, POD: pod });
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    if (name === "CompanySelection") {
      const selectedCompany = bankData.find(
        (bank) => bank.OfficeAddress === value
      );
      const selectedCompanyId = selectedCompany ? selectedCompany.id : "";
      setFormData((prevData) => ({
        ...prevData,
        CompanySelection: value,
        CompanyId: selectedCompanyId,
      }));
    } else if (name === "BankDetails") {
      const selectedBank = bankData.find(
        (bank) => `${bank.AccNumber}-${bank.BankName}` === value
      );
      const selectedBankId = selectedBank ? selectedBank.id : ""; // Assuming BankId is the ID field
      setFormData((prevData) => ({
        ...prevData,
        BankDetails: value,
        BankId: selectedBankId,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // End
  const Pagerefresh = () => window.location.reload(true);
  //handle submit
  const handleSubmit = async (event) => {
    formData.PiContainers = PiContainers;
    event.preventDefault();
    console.log(formData, "form");
    setIsSubmitting(true);
    try {
    const { data } = await ProfomaInvoiceAPIInstance.post(
      `add_ic?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/proforma_invoice");
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }}
    catch (error) {
      console.error("Submission error:", error);
      toast({
        title: "An error occurred. Please try again.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    } finally {
      setIsSubmitting(false); // Re-enable the button regardless of success or failure
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  //handle submit and refresh the same page
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      formData.PiContainers = PiContainers;
      event.preventDefault();
      console.log(formData, "formnext");
      const { data } = await ProfomaInvoiceAPIInstance.post(
        `add_ic?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Currency Data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //fetch piuser_region data
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await ProfomaInvoiceAPIInstance.get(
          `userregion_pi?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`
        );
        console.log("Fetched PI Region Data:", data.data); // Debugging line

        // Filter out duplicates based on PiRegion
        const uniqueRegions = Array.from(
          new Set(data.data.map((region) => region.user_region))
        ).map((uniqueRegion) => {
          return data.data.find(
            (region) => region.user_region === uniqueRegion
          );
        });

        setPiRegionOptions(
          uniqueRegions.map((region) => ({
            value: region.user_region,
            label: region.user_region,
            off_add: region.OfficeAddress,
            pi_state: region.State,
            companyId: region.id,
            region_code: region.regionCode,
            country: region.Country,
            pi_tax: region.Tax,
          }))
        );
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //fetch client data
//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const { data } = await MasterDataAPIInstance.get(`get_customer`);
//         setClientData(data.data);
//         console.log(data.data);
//       } catch (error) {
//         toast({
//           title: error.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     };
//     getData();
//   }, []);

 // console.log("client", clientData);

   const portOptions = portData.map((port) => ({
    value: port.Port,
    label: port.Port,
  }));
  const clientOptions = clientData.map((client) => ({
    value: client.id, // Unique value
    label: `${client.CompanyName}-${client.AccNumber}`,
    companyName: client.CompanyName,
    // value: client.CompanyName,
    // label: `${client.CompanyName}-${client.AccNumber}`,
    billTo_Address: client.AddressLine1,
    billTo_Country: client.Country,
    billTo_State: client.State,
    billTo_City: client.City,
    billTo_Pincode: client.Pincode,
    billTo_Gst: client.Gst_vatNumber,
    
  }));

  const uniqueCountries = Array.from(
    new Set(bankData.map((company) => company.Country))
  );

 //console.log("clientOptions",clientOptions)
  const handleBankDetailsChange = (selectedOption) => {
    const bankDetails = selectedOption ? selectedOption.value : "";
    const bank_id = selectedOption ? selectedOption.id : "";
    setFormData((prevData) => ({
      ...prevData,
      BankDetails: bankDetails,
      BankId: bank_id,
    }));
  };

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    fetchCountryData();
  }, []);

  const handleBillCountryChange = async (selectedOption) => {
    const country = selectedOption ? selectedOption.value : "";
    setFormData((prevData) => ({
      ...prevData,
      BillAddressCountry: country,
      BillAddressState: "",
      BillAddressCity: "",
    }));

    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_country_state?countryData=${country}`
      );
      setBillToStateData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleBillStateChange = async (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setFormData((prevData) => ({
      ...prevData,
      BillAddressState: state,
      BillAddressCity: "",
    }));

    try {
      const { data } = await MasterDataAPIInstance.get(
        `get_state_city?stateData=${state}`
      );
      setBillToCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const countryOptions = countryData.map((country) => ({
    value: country.Country_name,
    label: country.Country_name,
  }));
  const stateOptions = billToStateData.map((state) => ({
    value: state.State_name,
    label: state.State_name,
  }));
  const cityOptions = billToCityData.map((city) => ({
    value: city.City_name,
    label: city.City_name,
  }));

  const handleClientChange = async (selectedOption) => {
    const client = selectedOption ? selectedOption.companyName : "";
    const address = selectedOption ? selectedOption.billTo_Address : "";
    const city = selectedOption ? selectedOption.billTo_City : "";
    const state = selectedOption ? selectedOption.billTo_State : "";
    const country = selectedOption ? selectedOption.billTo_Country : "";
    const pincode = selectedOption ? selectedOption.billTo_Pincode : "";
    const gst = selectedOption ? selectedOption.billTo_Gst : "";

    // Update the form data
    setFormData({
      ...formData,
      Bill_to: client,
      BillAddress: address,
      BillAddressCity: city,
      BillAddressCountry: country,
      BillAddressState: state,
      BillAddressPincode: pincode,
      BillGst: gst,
    });
    try {
      console.log(
        "pir",
        formData.PiRegion,
        "  counutry",
        country,
        "tax",
        formData.PiTax
      );
      const piTaxQuery =
        formData.PiRegion === country ? formData.PiTax : "No_Tax";

      const { data: fetchedTaxData } = await ProfomaInvoiceAPIInstance.get(
        `get_tax?tax=${piTaxQuery}`
      );

      const descriptionOptions = Array.isArray(fetchedTaxData.data)
        ? fetchedTaxData.data.map((item) => ({
            Description: item.Description,
            value: item.Description,
            label: item.Description,
            Tax: item.Tax, // Store the tax directly here
            SacCode: item.SacCode,
          }))
        : [];

      setDescriptionOption(descriptionOptions);
      setTaxData(descriptionOptions);

      // Fetch state options for the selected country
      if (country) {
        try {
          const { data: stateData } = await MasterDataAPIInstance.get(
            `get_country_state?countryData=${country}`
          );
          setBillToStateData(stateData.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }

      // Fetch city options for the selected state
      if (state) {
        try {
          const { data: cityData } = await MasterDataAPIInstance.get(
            `get_state_city?stateData=${state}`
          );
          setBillToCityData(cityData.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleRegionChange = async (selectedOption) => {
    const piregion = selectedOption ? selectedOption.country : "";
    const picurrency = selectedOption ? selectedOption.CurrencyType : "";
    const pistate = selectedOption ? selectedOption.pi_state : "";
    const companyId = selectedOption ? selectedOption.companyId : "";
    const picode = selectedOption ? selectedOption.region_code : "";
    const pi_tax = selectedOption ? selectedOption.pi_tax : "";

    // Update formData with the selected region and company information
    setFormData({
      ...formData,
      PiRegion: piregion,
      PiCurrency: picurrency,
      CompanySelection: selectedOption ? selectedOption.off_add : "",
      CompanyId: companyId,
      PiState: pistate,
      PiTax: pi_tax,
    });

    try {
      // Fetch bank data based on selected region
      const bankResponse = await ProfomaInvoiceAPIInstance.get(
        `get_bank?country=${piregion}`
      );
      const banks = bankResponse.data.data;

      // Create bank options, avoiding duplicates
      const seenBankIds = new Set();
      const bankOptions = banks.reduce((acc, bank) => {
        if (!seenBankIds.has(bank.id)) {
          seenBankIds.add(bank.id);
          acc.push({
            value: `${bank.AccNumber}-${bank.BankName}`,
            label: `${bank.AccNumber} - ${bank.BankName}`,
            id: bank.id,
            state: bank.State,
          });
        }
        return acc;
      }, []);

      setBankOptions(bankOptions);


      const clientResponse = await ProfomaInvoiceAPIInstance.get(
        `get_bank?notcountry=${piregion}`
      );
      const clients = clientResponse.data.data;
     
      setClientData(clients);


      // Set default bank for India based on state
      if (piregion === "India") {
        const defaultBank = bankOptions.find((bank) => bank.state === pistate);
        if (defaultBank) {
          setFormData((prevData) => ({
            ...prevData,
            BankDetails: defaultBank.value,
            BankId: defaultBank.id,
          }));
        }
      } else {
        // Reset any previous bank selection when region is not India
        setFormData((prevData) => ({
          ...prevData,
          BankDetails: "",
          BankId: "",
        }));
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const addContainer = () => {
    setContainers([...containers, {}]);
    setPiContainers([
      ...PiContainers,
      {
        OtherDescription: "",
        Description: "",
        Currency: "",
        UnitPrice: "",
        Base: "",
        Qty: "",
        Ex_Rate: "",
        TaxableAmount: "",
        Gst: "",
        SacCode: "",
        AmtInclGst: "",
      },
    ]);
    setShowOtherDescriptionInput([...showOtherDescriptionInput, false]);
  };

  const removeContainer = (indexToRemove) => {
    setContainers(containers.filter((_, index) => index !== indexToRemove));
    setPiContainers(PiContainers.filter((_, index) => index !== indexToRemove));
    setShowOtherDescriptionInput(
      showOtherDescriptionInput.filter((_, index) => index !== indexToRemove)
    );
    setOtherDescriptions((prev) => {
      const updated = { ...prev };
      delete updated[indexToRemove];
      return updated;
    });
  };
  console.log(formData);

  const handleContainerChange = (index, fieldName, value) => {
    const updatedContainers = [...containers];
    const updatedPiContainers = [...PiContainers];

    // Update the value for both containers and PiContainers
    updatedContainers[index] = {
      ...updatedContainers[index],
      [fieldName]: value,
    };

    updatedPiContainers[index] = {
      ...updatedPiContainers[index],
      [fieldName]: value,
    };

    // Update GST and SacCode based on the selected Description
    if (fieldName === "Description") {
      const selectedDescription = taxData.find(
        (item) => item.Description === value
      );
      console.log(
        "sad",
        taxData.find((item) => item.Description === value)
      );
      if (selectedDescription) {
        // Convert the tax string to a number
        //const taxValue = parseFloat(selectedDescription.Tax) || 0; // Ensure it's a number
        updatedContainers[index].Gst = selectedDescription.Tax; // Store as number
        updatedContainers[index].SacCode = selectedDescription.SacCode;
        updatedPiContainers[index].Gst = selectedDescription.Tax; // Store as number
        updatedPiContainers[index].SacCode = selectedDescription.SacCode;
      }
      console.log("sad", value, taxData);
    }

    // Update Quantity when Base changes
    if (fieldName === "Base") {
     
      const updatedQty = "";

      updatedContainers[index].Qty = updatedQty;
      updatedPiContainers[index].Qty = updatedQty;
    }

    // Calculate and update TaxableAmount and AmtInclGst
    const { Qty, UnitPrice, Ex_Rate, Gst } = updatedContainers[index];
    const qty = parseFloat(Qty) || 0;
    const unitPrice = parseFloat(UnitPrice) || 0;
    const exRate = parseFloat(Ex_Rate) || 0;

    // Calculate Taxable Amount
    updatedContainers[index].TaxableAmount = (qty * unitPrice * exRate).toFixed(
      2
    );
    updatedPiContainers[index].TaxableAmount =
      updatedContainers[index].TaxableAmount;

    const taxableAmount =
      parseFloat(updatedContainers[index].TaxableAmount) || 0;
    const gst = parseFloat(Gst) || 0;

    // Calculate Amount Including GST
    updatedContainers[index].AmtInclGst = (
      taxableAmount *
      (1 + gst / 100)
    ).toFixed(2);
    updatedPiContainers[index].AmtInclGst = updatedContainers[index].AmtInclGst;

    // Update state
    setContainers(updatedContainers);
    setPiContainers(updatedPiContainers);
  };

  const baseOptions = equipmentData.map((item) => ({
    value: item.ContainerType,
    label: item.ContainerType,
  }));

  const currencyOptions = currencyData.map((item) => ({
    value: item.Currency,
    label: `${item.Currency}`,
  }));

  // Add "Others" option only in the frontend
  const extendedDescriptionOptions = [
    ...(descriptionOptions || []), // Ensure descriptionOptions is an array
    { value: "others", label: "Others" },
  ];

  const handleDescriptionChange = (selectedOption, index) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    handleContainerChange(index, "Description", selectedValue);

    // Check if "Others" is selected
    const updatedShowOtherDescriptionInput = [...showOtherDescriptionInput];
    updatedShowOtherDescriptionInput[index] = selectedValue === "others";
    setShowOtherDescriptionInput(updatedShowOtherDescriptionInput);
  };

  const handleOtherDescriptionChange = (e, index) => {
    const { value } = e.target;
    setOtherDescriptions({ ...otherDescriptions, [index]: value });
    handleContainerChange(index, "OtherDescription", value);
  };

  const handleBackToDropdown = (index) => {
    const updatedShowOtherDescriptionInput = [...showOtherDescriptionInput];
    updatedShowOtherDescriptionInput[index] = false;
    setShowOtherDescriptionInput(updatedShowOtherDescriptionInput);
    handleContainerChange(index, "Description", "");
  };

  const containerIndex = 0; // Change this to the index of the container you want to display

  // Check if the containers array is not empty and the specified index is valid
  if (
    containers.length > containerIndex &&
    containers[containerIndex].AmtInclGst !== undefined
  ) {
    // Display the AmtInclGst value of the specified container
    console.log("AmtInclGst Value:", containers[containerIndex].AmtInclGst);
  } else {
    console.log("Container or AmtInclGst value not available.");
  }
  const totalAmtInclGst = containers.reduce((sum, container) => {
    // Parse the AmtInclGst value to ensure it's a valid number
    const amtInclGst = parseFloat(container.AmtInclGst);
    return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
  }, 0);

  // Display the totalAmtInclGst
  const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

  // Display the totalAmtInclGst
  console.log("Total AmtInclGst for all containers:", roundedTotalAmtInclGst);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      TotalInvValue: roundedTotalAmtInclGst,
    }));
  }, [roundedTotalAmtInclGst]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  console.log("BD", bankOptions);
 
  const style = {
    border: "1px solid #ddd",
    backgroundColor: "white",
    zIndex: 20,
  };

  const closeModal = {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10,
  };

  const handleConfirmCancel = () => {
    navigate("/intercompanypi");
    onCloseCancelProforma();
  };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault();
//     }
//   };

  return (
    <>
      <Header />
      <div className="form" 
    //   onKeyDown={handleKeyDown}
      >
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Inter-Company Proforma Invoice </p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
            disabled={isSubmitting} 
          >
             {isSubmitting ? "Submitting..." : "Save"}
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>

          <div
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950 cursor-pointer"
            onClick={onOpenCancelProforma}
            disabled={!submitAllowed}
          >
            Cancel
          </div>

        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
     
<h2
  style={{
    float: "left",
    color: "#172554",
    fontSize: "20px",
    borderRadius: "10px",
    fontFamily: "cursive",
    paddingBottom: "10px", // space for the border
    position: "relative",
  }}
>
  Region Details<span style={{ color: "red" }}>*</span>
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4px", // height of the border
      borderRadius: "10px",
      background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
    }}
  />
</h2>



              <br/>
              <br/>
          <div className="content">
            <div className="form-user">
              <div className="user-proinvs">
                <div className="input-box">
                  <span className="proinvs">
                    Region
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <Select
                    isClearable
                    options={piRegionOptions}
                    value={piRegionOptions.find(
                      (option) => option.value === formData.PiRegion
                    )}
                    onChange={handleRegionChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">Bank Details</span>
                  <Select
                    isClearable
                    options={bankOptions}
                    value={bankOptions.find(
                      (option) => option.value === formData.BankDetails
                    )}
                    onChange={handleBankDetailsChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    isDisabled={!formData.CompanySelection} // Disable if no CompanySelection is chosen
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">Company Selection</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CompanySelection"
                    value={formData.CompanySelection} // Initially set based on fetched data
                    onChange={handleChange} // Update formData.CompanySelection on change
                    placeholder="Enter Company Name"
                  />
                </div>

                </div>
            </div>
              </div>
              <h2
  style={{
    float: "left",
    color: "#172554",
    fontSize: "20px",
    borderRadius: "10px",
    fontFamily: "cursive",
    paddingBottom: "10px", // space for the border
    position: "relative",
  }}
>
  Job Details<span style={{ color: "red" }}>*</span>
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4px", // height of the border
      borderRadius: "10px",
      background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
    }}
  />
</h2>


              <br/>
              <br/>
              <div className="content">
            <div className="form-user">
              <div className="user-proinvs">
               <div className="input-box">
                  <span className="proinvs">
                    Product
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                 
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TypeOfShipment"
                    value={formData.TypeOfShipment}
                    onChange={handleChange}
                    placeholder="Enter your Product"
                  >
                    <option>Select Product</option>
                    {productData.map((item) => (
                      <option value={item.Product}>{item.Product}</option>
                    ))}
                  </select>
                </div>
                {/* <div className="input-box"></div> */}
                <div className="input-box">
                  <span className="proinvs">
                    MBL
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBL"
                    value={formData.MBL}
                    onChange={handleChange}
                    placeholder="Enter your MBL"
                  />
                </div>

                <div className="input-box">
                  <span className="proinvs">
                    HBL
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HBL"
                    value={formData.HBL}
                    onChange={handleChange}
                    placeholder="Enter your HBL"
                  />
                </div>

                <div className="input-box">
                  <span className="proinvs">
                    POL
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <Select
                    isClearable
                    options={portOptions}
                    value={portOptions.find(
                      (option) => option.value === formData.POL
                    )}
                    onChange={handlePolChange}
                    classNamePrefix="react-select"
                    styles={customStyles}
                    noOptionsMessage={() => "No options"}
                  />
                </div>

                <div className="input-box">
                  <span className="proinvs">
                    POD
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <Select
                    isClearable
                    options={portOptions}
                    value={portOptions.find(
                      (option) => option.value === formData.POD
                    )}
                    onChange={handlePodChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">
                    Invoice Date
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Invoice_Date"
                    value={formData.Invoice_Date}
                    onChange={handleChange}
                    placeholder="Enter your Invoice_Date"
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">
                    Due Date
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Due_Date"
                    value={formData.Due_Date}
                    onChange={handleChange}
                    placeholder="Enter your Due_Date"
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">ETA</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ETA"
                    value={formData.ETA}
                    onChange={handleChange}
                    placeholder="Enter your ETA"
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">ETD</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="ETD"
                    type="date"
                    value={formData.ETD}
                    onChange={handleChange}
                    placeholder="Enter your ETD"
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">ContainerType</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContainerType"
                    value={formData.ContainerType}
                    onChange={handleChange}
                    placeholder="Enter your ContainerType"
                  />
                </div>
                <div className="input-box">
                  <span className="proinvs">VesselNameVoyage </span>

                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    value={formData.VesselNameVoyage}
                    onChange={handleChange}
                    placeholder="Enter your VesselNameVoyage  "
                  />
                </div>
                <div className="input-box"></div>
                </div>
            </div>
              </div>
              <h2
  style={{
    float: "left",
    color: "#172554",
    fontSize: "20px",
    borderRadius: "10px",
    fontFamily: "cursive",
    paddingBottom: "10px", // space for the border
    position: "relative",
  }}
>
  Client Details<span style={{ color: "red" }}>*</span>
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4px", // height of the border
      borderRadius: "10px",
      background: "linear-gradient(135deg, #71b7e6, #9b59b6)",
    }}
  />
</h2>

              <br/>
              <br/>
              <div className="content">
            <div className="form-user">
              <div className="user-proinvs">

               
                <div className="input-box">
                  <span className="proinvs">Client</span>
                  <Select
                    isClearable
                    options={clientOptions}
                    value={clientOptions.find(
                      (option) => option.value === formData.Bill_to
                    )}
                    onChange={handleClientChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                  
                 
                </div>
               
                <div className="input-box">
                  <span className="proinvs">Client Address</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BillAddress"
                    value={formData.BillAddress}
                    onChange={handleChange}
                    placeholder="Enter  Client Address "
                  />
                </div>
               
                <div className="input-box">
                  <span className="proinvs">Client Country</span>
                  <Select
                    isClearable
                    options={countryOptions}
                    value={countryOptions.find(
                      (option) => option.value === formData.BillAddressCountry
                    )}
                    onChange={handleBillCountryChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>


                <div className="input-box">
                  <span className="proinvs">Client State</span>
                  <Select
                    isClearable
                    options={stateOptions}
                    value={stateOptions.find(
                      (option) => option.value === formData.BillAddressState
                    )}
                    onChange={handleBillStateChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>

                <div className="input-box">
                  <span className="proinvs">Client City</span>
                  <Select
                    isClearable
                    options={cityOptions}
                    value={cityOptions.find(
                      (option) => option.value === formData.BillAddressCity
                    )}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        BillAddressCity: selectedOption
                          ? selectedOption.value
                          : "",
                      }))
                    }
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                </div>

                <div className="input-box">
                  <span className="proinvs">Client Pincode</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BillAddressPincode"
                    value={formData.BillAddressPincode}
                    onChange={handleChange}
                    placeholder="Enter  Bill To pincode"
                  />
                </div>
              
                <div className="input-box">
                  <span className="proinvs">Client Tax</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BillGst"
                    value={formData.BillGst}
                    onChange={handleChange}
                    placeholder="Enter  Bill To  Gst"
                  />
                </div>
                <div className="input-box"></div>
                <div className="input-box"></div>
                <div className="input-box">
                  <span className="proinvs">ExChange Rate<span style={{ color: "red" }}>*</span></span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ExchangeRate"
                    value={formData.ExchangeRate}
                    onChange={handleChange}
                    placeholder="Enter exchange rate"
                  />
                </div>
                 <div className="input-box">
                                  <span className="proinvs">Remark</span>
                                  <Textarea
                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                    type="text"
                                    name="Remark"
                                    value={formData.Remark} // Initially set based on fetched data
                                    onChange={handleChange} // Update formData.CompanySelection on change
                                    placeholder="Enter Remark"
                                  />
                                </div>
                                <div className="input-box"></div>

                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Description
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Base
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Qty
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Currency
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          UnitPrice
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Ex_Rate
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          TaxableAmount
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Tax
                        </th>

                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Amt Incl Tax
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {containers.map((container, index) => (
                        <tr key={index}>
                          <td>
                            {showOtherDescriptionInput[index] ? (
                              <div className="custom-input-container">
                                <input
                                  type="text"
                                  name={`OtherDescription-${index}`}
                                  value={otherDescriptions[index] || ""}
                                  onChange={(e) =>
                                    handleOtherDescriptionChange(e, index)
                                  }
                                  placeholder="Enter other description "
                                  className="custom-input"
                                />
                                <GiCancel
                                  onClick={() => handleBackToDropdown(index)}
                                  style={{ cursor: "pointer", color: "red" }}
                                  size={20}
                                  className="cancel-icon"
                                />
                              </div>
                            ) : (
                              <Select
                                name={`Description-${index}`}
                                value={extendedDescriptionOptions.find(
                                  (option) =>
                                    option.value === container.Description
                                )}
                                onChange={(selectedOption) =>
                                  handleDescriptionChange(selectedOption, index)
                                }
                                options={extendedDescriptionOptions}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "No options"}
                                styles={customStyles} // Add your custom styles here
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                              />
                            )}
                          </td>

                          <td>
                            <Select
                              name={`Base-${index}`}
                              value={baseOptions.find(
                                (option) => option.value === container.Base
                              )}
                              onChange={(selectedOption) =>
                                handleContainerChange(
                                  index,
                                  "Base",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              options={baseOptions}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "No options"}
                              styles={customStyles}
                              menuPortalTarget={document.body} // Render the dropdown menu in the body
                              menuPosition="fixed"
                            />
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`Qty-${index}`}
                              value={container.Qty || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Qty",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                          <Select
                              name={`Currency-${index}`}
                              value={currencyOptions.find(
                                (option) => option.value === container.Currency
                              )}
                              onChange={(selectedOption) =>
                                handleContainerChange(
                                  index,
                                  "Currency",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              options={currencyOptions}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "No options"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                            />
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`UnitPrice-${index}`}
                              value={container.UnitPrice || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "UnitPrice",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              w="80px"
                              type="number"
                              name={`Ex_Rate-${index}`}
                              value={container.Ex_Rate || ""}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Ex_Rate",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              w="150px"
                              type="number"
                              name={`TaxableAmount-${index}`}
                              value={container.TaxableAmount || ""}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              w="150px"
                              type="text"
                              name={`Gst-${index}`}
                              value={container.Gst}
                              onChange={(e) =>
                                handleContainerChange(
                                  index,
                                  "Gst",
                                  e.target.value
                                )
                              }
                              readOnly={container.Description !== "others"}
                            />
                          </td>
                          <td>
                            <Input
                              w="120px"
                              type="number"
                              name={`AmtInclGst-${index}`}
                              value={container.AmtInclGst || ""}
                              readOnly
                            />
                          </td>
                          <td>
                            {containers.length > 1 && (
                              <BiSolidMinusCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginTop: "15px", color: "red" }}
                                onClick={() => removeContainer(index)}
                              />
                            )}
                            <IoMdAddCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "green" }}
                              onClick={addContainer}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7}></td>
                        <td className="font-medium"> Total Incl Value :</td>
                        <td>
                          <Input
                            w="120px"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            type="number"
                            name="TotalInvValue"
                            value={formData.TotalInvValue}
                            onChange={handleChange}
                            placeholder="Enter your TotalInvValue"
                            readOnly
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="input-box"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenCancelProforma && (
        <>
          <div style={backdropStyle}></div>
          <div className="ConfirmCancelProforma">
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Create Proforma Cancel
              </header>
              <div onClick={onCloseCancelProforma} style={closeModal}>
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to cancel the proforma?
              </FormControl>
              <br />
              <Button colorScheme="green" onClick={handleConfirmCancel}>
                Yes
              </Button>
              <Button colorScheme="red" onClick={onCloseCancelProforma} ml={3}>
                No
              </Button>
            </Rnd>
          </div>
        </>
      )}
    </>
  );
};

export default AddInterCompanyPi;
