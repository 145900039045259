import React, { useEffect, useState } from "react";
import {
  OperationDocumentAPIInstance,
  AuthAPIInstance,
} from "../../api-instance";
import {
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
} from "@chakra-ui/react";

const OperationAddOps = ({ jobNumber, onSelectOps, onSelectOpsDocument }) => {
  const [userOpsData, setUserOpsData] = useState([]);
  const [selectedOpsUser, setSelectedOpsUser] = useState([]);
  const [documentAddOvsData, setDocumentAddOvsData] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await AuthAPIInstance.get(
          `user/get_ops_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setUserOpsData(response?.data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    getData();
  }, []);

  const toggleRowSelection = (row) => {
    const selectedIndex = selectedOpsUser.indexOf(row.userId);
    let newSelectedOpsUser = [];

    if (selectedIndex === -1) {
      newSelectedOpsUser = [...selectedOpsUser, row.userId];
    } else {
      newSelectedOpsUser = selectedOpsUser.filter(
        (code) => code !== row.userId
      );
    }

    setSelectedOpsUser(newSelectedOpsUser);
    onSelectOps(newSelectedOpsUser); // Pass selected region codes back to parent component
  };

  const handleSelectAllClick = () => {
    if (selectedOpsUser.length === userOpsData.length) {
      setSelectedOpsUser([]);
      onSelectOps([]);
    } else {
      const allOpsUser = userOpsData.map((row) => row.userId);
      setSelectedOpsUser(allOpsUser);
      onSelectOps(allOpsUser);
    }
  };

  useEffect(() => {
    console.log("Selected OPS User:", selectedOpsUser);
  }, [selectedOpsUser]);

  // Document API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await OperationDocumentAPIInstance.get(
          `get_document_jobtransfer?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&JobNumber=${jobNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        setDocumentAddOvsData(data.data);
        onSelectOpsDocument(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [jobNumber]);
  return (
    <div>
      <h2>Are you sure you want add this job OVS Operation ?</h2>
      {/* <TableContainer
        style={{ height: "300px", overflow: "scroll", overflowX: "hidden" }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={selectedOpsUser.length === userOpsData.length}
                  onChange={handleSelectAllClick}
                />
              </Th>
              <Th>Name</Th>
              <Th>OVS Operation Team</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userOpsData.map((row) => (
              <Tr key={row.userId}>
                <Td>
                  <Checkbox
                    isChecked={selectedOpsUser.includes(row.userId)}
                    onChange={() => toggleRowSelection(row)}
                  />
                </Td>
                <Td>
                  {row.firstname} {row.lastname}
                </Td>
                <Td>{row.user_region}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer> */}
    </div>
  );
};

export default OperationAddOps;
