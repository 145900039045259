import React from "react";
import { Button, Result } from 'antd';
// import "./error.css";
import error_img from "../../assets/img/error_img.png";

const Error = () => {
  return (
    <Result
    status="404"
    title="404"
    subTitle="OOPS! The page you requested could not found."
  />
  );
};

export default Error;
