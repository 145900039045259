import { React, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  OperationDocumentAPIInstance,
  MasterDataAPIInstance,
} from "../../../api-instance";
import {
  useToast,
  Tooltip,
  Select,
  Button,
  useDisclosure,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  GridItem,
  Grid,
} from "@chakra-ui/react";

const AddOpsVendor = ({ isOpen, onClose, onOpen, getData }) => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("DocumentUpload", selectedJob);
  const [currencyData, setCurrencyData] = useState([]);
  const [size, setSize] = useState("xl");
  const toast = useToast();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [formData, setFormData] = useState({
    Vendor_name: "",
    Amount: "",
    PaidAmount: "",
    Payment_Status: "",
    Currency: "",
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePageRefresh = () => {
    window.location.reload();
  };

  // Currency API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_currency");
        setCurrencyData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  const handleVendorAdd = async () => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      const formDataToSend = new FormData();
      formDataToSend.append("file", file);
      formDataToSend.append("BookingNumber", selectedJob.BookingNumber);
      formDataToSend.append("JobNumber", selectedJob.JobNumber);
      formDataToSend.append("JobInfono", selectedJob.JobInfono);
      formDataToSend.append("JobOwner", storedUser.userId);
      formDataToSend.append("Vendor_name", formData.Vendor_name);
      formDataToSend.append("Amount", formData.Amount);
      formDataToSend.append("PaidAmount", formData.PaidAmount);
      formDataToSend.append("Payment_Status", formData.Payment_Status);
      formDataToSend.append("Currency", formData.Currency);

      const response = await OperationDocumentAPIInstance.post(
        `add_vendor_ops?userId=${storedUser.userId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log("sss", response.data);
      const data = response.data;

      if (data?.data?.message === "Vendor added successfully.") {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        handlePageRefresh();
      } else {
        toast({
          title: data?.data?.message || "An error occurred",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.response?.data?.data?.message || error.message;
      toast({
        title: errorMessage,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Modal onClose={onClose} size={size} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Vendor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>Vendor Name</FormLabel>
                  {/* <Input
                    type="text"
                    name="Vendor_name"
                    placeholder="Enter your vendor name"
                    onChange={handleChange}
                  /> */}
                  <Select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Vendor_name"
                    value={formData.Company}
                    onChange={handleChange}
                  >
                    <option>Select Vendor</option>
                    {customerData.map((item) => (
                      <option value={item.Company}>{item.Company}</option>
                    ))}
                  </Select>
                  <FormLabel>Currency</FormLabel>
                  <Select
                    name="Currency"
                    value={formData.Currency}
                    onChange={handleChange}
                  >
                    <option>Select Currency</option>
                    {currencyData.map((item) => (
                      <option value={`${item.Currency}`}>
                        {item.Currency}
                      </option>
                    ))}
                  </Select>

                  <FormLabel>Invoice Attachment</FormLabel>
                  <Input
                    type="file"
                    name="file"
                    multiple
                    sx={{
                      "::file-selector-button": {
                        height: 10,
                        padding: 0,
                        mr: 4,
                        background: "none",
                        border: "none",
                        fontWeight: "bold",
                        color: "red",
                      },
                    }}
                    width={200}
                    onChange={handleFileChange}
                    placeholder="Enter your invoice attachment"
                  />
                </FormControl>
              </GridItem>
              <GridItem colStart={4} colEnd={6}>
                <FormControl>
                  <FormLabel> Payment Status</FormLabel>
                  <Select
                    name="Payment_Status"
                    value={formData.Payment_Status}
                    onChange={handleChange}
                  >
                    <option>Select option</option>
                    <option value="Paid">Paid</option>
                    <option value="Not Paid">Not Paid</option>
                    <option value="Partial Payment">Partial Payment</option>
                  </Select>
                  <FormLabel>Total Amount</FormLabel>
                  <Input
                    name="Amount"
                    type="text"
                    value={formData.Amount}
                    placeholder="Enter your total amount"
                    onChange={handleChange}
                  />

                  <FormLabel>Paid Amount</FormLabel>
                  <Input
                    name="PaidAmount"
                    type="text"
                    value={formData.PaidAmount}
                    placeholder="Enter your paid amount"
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>&nbsp;&nbsp;&nbsp;
            <Button
              onClick={() => {
                handleVendorAdd();

                navigate("/opertion_vendor", {
                  state: { selectedJob: selectedJob },
                });
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddOpsVendor;
