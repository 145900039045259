import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
  AuthAPIInstance,
} from "../../api-instance";

const OperationTransferData = ({
  jobNumber,
  onSelect,
  onSelectJobTransfer,
  onSelectProduct,
}) => {
  const [productData, setProductData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [selectedRegionCodes, setSelectedRegionCodes] = useState([]);
  const [selectedRegionCode, setSelectedRegionCode] = useState(null);
  const [selectedJobTransfer, setSelectedJobTransfer] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await AuthAPIInstance.get(
          `user/get_user_all?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setUserdata(response?.data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    getData();
  }, []);

  // const toggleRowSelection = (row) => {
  //   const selectedIndex = selectedRegionCodes.indexOf(row.regionCode);
  //   let newSelectedRegionCodes = [];

  //   if (selectedIndex === -1) {
  //     newSelectedRegionCodes = [...selectedRegionCodes, row.regionCode];
  //   } else {
  //     newSelectedRegionCodes = selectedRegionCodes.filter(
  //       (code) => code !== row.regionCode
  //     );
  //   }

  //   setSelectedRegionCodes(newSelectedRegionCodes);
  //   onSelect(newSelectedRegionCodes);
  // };

  const toggleRowSelection = (row) => {
    if (selectedRegionCode === row.regionCode) {
      // Deselect if already selected
      setSelectedRegionCode(null);
      onSelect([]);
    } else {
      if (selectedRegionCode) {
        // Show error if there's already a selected region code
        toast({
          title: "Please select only one row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        // Select new region code
        setSelectedRegionCode(row.regionCode);
        onSelect([row.regionCode]);
      }
    }
  };

  const toggleRowSelectionJobTransfer = (row) => {
    const selectedIndex = selectedJobTransfer.findIndex(
      (item) => item.Doc_Name === row.Doc_Name
    );
    let newSelectedJobTransfer = [];

    if (selectedIndex === -1) {
      newSelectedJobTransfer = [...selectedJobTransfer, row];
    } else {
      newSelectedJobTransfer = selectedJobTransfer.filter(
        (item) => item.Doc_Name !== row.Doc_Name
      );
    }

    setSelectedJobTransfer(newSelectedJobTransfer);
    onSelectJobTransfer(newSelectedJobTransfer);
  };

  const handleSelectAllClick = () => {
    if (selectedRegionCodes.length === userdata.length) {
      setSelectedRegionCodes([]);
      onSelect([]);
    } else {
      const allRegionCodes = userdata.map((row) => row.regionCode);
      setSelectedRegionCodes(allRegionCodes);
      onSelect(allRegionCodes);
    }
  };

  const handleSelectAllJobTransfer = () => {
    if (selectedJobTransfer.length === documentData.length) {
      setSelectedJobTransfer([]);
      onSelectJobTransfer([]);
    } else {
      setSelectedJobTransfer(documentData);
      onSelectJobTransfer(documentData);
    }
  };

  useEffect(() => {
    console.log("Selected Region Codes Updated:", selectedRegionCodes);
  }, [selectedRegionCodes]);

  useEffect(() => {
    console.log("Selected Job Transfer Updated:", selectedJobTransfer);
  }, [selectedJobTransfer]);

  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [toast]);

  // Document API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await OperationDocumentAPIInstance.get(
          `get_document_jobtransfer?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&JobNumber=${jobNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        setDocumentData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [jobNumber, toast]);

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setSelectedProduct(selectedProduct);
    onSelectProduct(selectedProduct);
  };

  return (
    <div style={{ height: "300px", overflow: "scroll", overflowX: "hidden" }}>
      <FormControl>
        <FormLabel>Select Product</FormLabel>
        <Select value={selectedProduct} onChange={handleProductChange}>
          <option>Select Product</option>
          {productData.map((data) => (
            <option key={data.Product} value={data.Product}>
              {data.Product}
            </option>
          ))}
        </Select>
        <br />
        <Badge
          colorScheme="purple"
          style={{ float: "left", marginTop: "10px" }}
        >
          Document Transfer
        </Badge>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={selectedJobTransfer.length === documentData.length}
                  onChange={handleSelectAllJobTransfer}
                />
              </Th>
              <Th>Job / Booking Number</Th>
              <Th>Document</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documentData.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <Checkbox
                    isChecked={selectedJobTransfer.some(
                      (selectedItem) => selectedItem.Doc_Name === item.Doc_Name
                    )}
                    onChange={() => toggleRowSelectionJobTransfer(item)}
                  />
                </Td>
                <Td>{item.BookingNumber}</Td>
                <Td>{item.Doc_Name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <br />
        <Badge
          colorScheme="purple"
          style={{ float: "left", marginTop: "10px" }}
        >
          Select Region
        </Badge>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                {/* <Checkbox
                  isChecked={selectedRegionCodes.length === userdata.length}
                  onChange={handleSelectAllClick}
                /> */}
              </Th>
              <Th>Region</Th>
              <Th>Region Code</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userdata.map((row) => (
              <Tr key={row.userId}>
                <Td>
                  {/* <Checkbox
                    isChecked={selectedRegionCodes.includes(row.regionCode)}
                    onChange={() => toggleRowSelection(row)}
                  /> */}
                  <Checkbox
                    isChecked={selectedRegionCode === row.regionCode}
                    onChange={() => toggleRowSelection(row)}
                  />
                </Td>
                <Td>{row.user_region}</Td>
                <Td>{row.regionCode}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </FormControl>
    </div>
  );
};

export default OperationTransferData;
