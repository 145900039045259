import { React, useState, useEffect } from "react";
import "./AddQuotation.css";
import { OperationHeader } from "../../components";
import {
  Button,
  Input,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Header } from "../../components";
import { Textarea } from '@chakra-ui/react'
import Axios, {
  QuotationAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import SearchSelect from "../../components/Select/select";
const AddQuotation = () => {
  const location = useLocation();
  const EnquirytoQuotation = location.state?.selectedData || {};

  console.log("Add Enquiry to Quotation Selected data:", EnquirytoQuotation);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [ModeData, setModeData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const fullName = `${storedUser.firstname} ${storedUser.lastname}`;
  const regionCode = storedUser.regions[0].regionCode;
  const [initialRegionCode, setInitialRegionCode] = useState(
    storedUser.regions.length > 0 ? storedUser.regions[0].regionCode : ""
  );
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [formData, setFormData] = useState([
    {
      QuotationNumber: "",
      QuotationOwner: storedUser.userId,
      EnquiryNumber: "",
      CompanyName: "",
      Address: "",
      PIC: "",
      Mode: "",
      Date: "",
      ValidTillDate: "",
      PickUpLocation: "",
      DeliveryLocation: "",
      Incoterm: "",
      Commodity: "",
      EquipmentDimensionandweight: "",
      Freight: "",
      ChargeableWeight: "",
      Remarks: "",
      QuoteInclusiveof: "",
      QuoteExclusiveof: "",
      Quoting_Address: "",
      TotalQuoteValue: "",
      Containers: "Equipment: , Quantity: , ChargeableWeight: ;",
      quotationContainers: []
    }]);


  useEffect(() => {
    setFormData((prevFormData) => {
      return prevFormData.map((item) => ({
        ...item,
        CreatedBy: fullName,
        QuotationOwner: storedUser.userId,
      }));
    });
  }, [storedUser, fullName]);


  useEffect(() => {
    setFormData(EnquirytoQuotation);
    setSelectedData(EnquirytoQuotation);
  }, []);

  useEffect(() => { }, [setSelectedData, setSelectedRows]);

  const [quotationContainers, setQuotationContainers] = useState([
    {
      Equipment: "",
      Description: "",
      Unit: "",
      UnitPrice: "",
      Currency: "",
      ExRate: "",
      TotalINRValue: "",
    },
  ]);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_mode");
        setModeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  //Customer
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Equipment API 
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Incoterm
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);



  // const handleContainerChange = (index, field, value) => {
  //   const updatedContainers = [
  //     ...formData[formDataIndex]?.Containers?.split(";"),
  //   ];
  //   const containerString = updatedContainers[index];

  //   if (containerString) {
  //     const container = {};
  //     containerString.split(",").forEach((pair) => {
  //       const [key, val] = pair.split(":");
  //       container[key?.trim()] = val?.trim();
  //     });

  //     // Update the value for the specified field
  //     container[field] = value;

  //     // If ContainerType changes, fetch the UnitPrice and update it
  //     if (field === "ContainerType") {
  //       const selectedEquipment = equipmentData.find(
  //         (item) => item.ContainerType === value
  //       );
  //       container.UnitPrice = selectedEquipment?.UnitPrice || '';
  //     }

  //     // If Quantity, UnitPrice, ExRate, or Currency changes, recalculate the TotalINRValue
  //     if (["Quantity", "UnitPrice", "ExRate"].includes(field)) {
  //       const unit = parseFloat(container.Quantity) || 0;
  //       const unitPrice = parseFloat(container.UnitPrice) || 0;
  //       const exRate = parseFloat(container.ExRate) || 0;
  //       const totalValue = unit * unitPrice * exRate;

  //       // Update the TotalINRValue in the container
  //       container.TotalValue = totalValue.toFixed(2); // Round to 2 decimal places
  //     }

  //     // Reconstruct the container string
  //     updatedContainers[index] = Object.entries(container)
  //       .map(([key, val]) => `${key}:${val}`)
  //       .join(", ");

  //     // Update the state with the new containers string
  //     setFormData((prevData) => {
  //       const newData = [...prevData];
  //       newData[formDataIndex].Containers = updatedContainers.join(";");

  //       // Calculate the total of all TotalINRValues
  //       const totalQuoteValue = updatedContainers.reduce((sum, containerStr) => {
  //         const containerObj = {};
  //         containerStr.split(",").forEach((pair) => {
  //           const [key, val] = pair.split(":");
  //           containerObj[key?.trim()] = val?.trim();
  //         });
  //         return sum + parseFloat(containerObj.TotalValue || 0);
  //       }, 0);

  //       // Update the TotalQuoteValue
  //       newData[formDataIndex].TotalQuoteValue = totalQuoteValue.toFixed(2);

  //       return newData;
  //     });
  //   }
  // };


  const handleContainerChange = (index, field, value) => {
    const updatedContainers = formData[formDataIndex]?.Containers?.split(";").filter((str) => str.trim() !== "") || [];
    const containerString = updatedContainers[index];

    if (containerString) {
      const container = {};
      containerString.split(",").forEach((pair) => {
        const [key, val] = pair.split(":");
        container[key?.trim()] = val?.trim();
      });

      // Update the value for the specified field
      container[field] = value;

      // If the field being updated is ChargeableWeight, ensure it's updated and recalculated
      if (field === "ChargeableWeight") {
        container.ChargeableWeight = value;

        // Update formData to sync ChargeableWeight
        setFormData((prevData) => {
          const newData = [...prevData];
          newData[formDataIndex] = { ...newData[formDataIndex], ChargeableWeight: value };  // Sync ChargeableWeight
          return newData;
        });
      }

      // Recalculate related values like TotalValue1 and TotalValue2 based on ChargeableWeight
      const chargeableWeight = parseFloat(container.ChargeableWeight) || 0;

      // Recalculate TotalValue1 and TotalValue2
      container.TotalValue1 = (parseFloat(container.Rate) || 0) * chargeableWeight;
      container.TotalValue2 = (parseFloat(container.Rate1) || 0) * chargeableWeight;

      // Handle special cases (e.g., updating UnitPrice, Rate1)
      if (field === "ContainerType") {
        const selectedEquipment = equipmentData.find((item) => item.ContainerType === value);
        container.UnitPrice = selectedEquipment?.UnitPrice || "";
      }

      // Calculate Rate1 based on Rate and ExRate
      if (field === "Rate" || field === "ExRate") {
        const rate = parseFloat(container.Rate) || 0;
        const exRate = parseFloat(container.ExRate) || 0;
        container.Rate1 = (rate * exRate).toFixed(2); // Calculate Rate1
      }

      if (["Quantity", "UnitPrice", "ExRate"].includes(field)) {
        const unit = parseFloat(container.Quantity) || 0;
        const unitPrice = parseFloat(container.UnitPrice) || 0;
        const exRate = parseFloat(container.ExRate) || 0;
        container.TotalValue = (unit * unitPrice * exRate).toFixed(2); // Round to 2 decimal places
      }

      // Reconstruct the container string with ChargeableWeight and other updated fields
      updatedContainers[index] = Object.entries(container)
        .map(([key, val]) => `${key}:${val}`)
        .join(", ");

      // Update the formData with the new container string
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex].Containers = updatedContainers.join(";");

        // Calculate total values
        const totalValue1 = updatedContainers.reduce((sum, containerStr) => {
          const containerObj = {};
          containerStr.split(",").forEach((pair) => {
            const [key, val] = pair.split(":");
            containerObj[key?.trim()] = val?.trim();
          });
          return sum + parseFloat(containerObj.TotalValue1 || 0);
        }, 0);

        const totalValue2 = updatedContainers.reduce((sum, containerStr) => {
          const containerObj = {};
          containerStr.split(",").forEach((pair) => {
            const [key, val] = pair.split(":");
            containerObj[key?.trim()] = val?.trim();
          });
          return sum + parseFloat(containerObj.TotalValue2 || 0);
        }, 0);

        const totalQuoteValue = updatedContainers.reduce((sum, containerStr) => {
          const containerObj = {};
          containerStr.split(",").forEach((pair) => {
            const [key, val] = pair.split(":");
            containerObj[key?.trim()] = val?.trim();
          });
          return sum + parseFloat(containerObj.TotalValue || 0);
        }, 0);

        // Update total values in formData
        newData[formDataIndex].TotalValue1 = totalValue1.toFixed(2);
        newData[formDataIndex].TotalValue2 = totalValue2.toFixed(2);
        newData[formDataIndex].TotalQuoteValue = totalQuoteValue.toFixed(2);

        return newData;
      });
    }
  };


  const addContainer = () => {
    setFormData((prevData) => {
      const newData = [...prevData];

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          newData[formDataIndex].Containers +
          "Equipment: , Unit: , ChargeableWeight: ;",
      };
      return newData;
    });
  };

  const addairContainer = () => {
    setFormData((prevData) => {
      const newData = [...prevData];

      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          newData[formDataIndex].Containers +
          "Description: , Equipment: , Quantity: , Weight: , ChargeableWeight: , Rate: , Rate1: , Currency: , Currency1: , UnitPrice: , ExRate: , TotalValue: 0, TotalValue1: 0, TotalValue2: 0",
      };
      return newData;
    });
  };

  function removeRowByIndex(data, indexToRemove) {
    let rows = data.split(";").filter((row) => row.trim() !== ""); // Split the data into rows
    if (indexToRemove >= 0 && indexToRemove < rows.length) {
      // Check if index is within bounds
      rows.splice(indexToRemove, 1); // Remove row at specified index
    }
    return rows.join("; "); // Join the rows back into a single string
  }

  const removeContainer = (indexToRemove) => {
    console.log(indexToRemove);
    setFormData((prevData) => {
      const newData = [...prevData];
      const containers = newData[formDataIndex]?.Containers || "";

      // Split the containers into rows
      let rows = containers.split(";").filter((row) => row.trim() !== "");

      if (indexToRemove >= 0 && indexToRemove < rows.length) {
        // Remove row at specified index
        rows.splice(indexToRemove, 1);
      }

      // Join the rows back into a single string with semicolons
      newData[formDataIndex] = {
        ...newData[formDataIndex],
        Containers:
          rows.join("; ").trim() + (containers.endsWith(";") ? ";" : ""), // Ensure ending semicolon if necessary
        // Operations: newData[formDataIndex]?.Operations.filter(
        //   (_, index) => index !== indexToRemove
        // ),
      };
      return newData;
    });
  };


  const handleChange = (e, index) => {
    if (
      e?.name === "POL" ||
      e?.name === "POD" ||
      e?.name === "Sales"
    ) {
      const { name, value } = e;
      console.log(name, value);
      setFormData((prevData) => {
        const newData = [...prevData];
        newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
        return newData;
      });

      return;
    }

    const { name, value } = e.target;
    console.log(name, value);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));


    setFormData((prevData) => {
      const newData = [...prevData];
      newData[formDataIndex] = { ...newData[formDataIndex], [name]: value };
      // newData[formDataIndex].regionCode = regionCode;
      return newData;
    });
    console.log("Pol Data", setFormData);
  };


  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    formData.quotationContainers = quotationContainers;
    event.preventDefault();
    console.log(formData, "form");

    try {
      const { data } = await QuotationAPIInstance.post(
        `add_quotation?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log("Response data:", data);

      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });

        navigate("/quotation");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const handleSubmitNext = async (event) => {
    event.preventDefault();
    formData.quotationContainers = quotationContainers;
    try {
      const { data } = await QuotationAPIInstance.post(
        `add_quotation?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data.success) {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message || "An error occurred.",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.response?.data?.message || "An unexpected error occurred.",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };


  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  const handleGetDuplicateBooking = (index) => {
    setFormDataIndex(index);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleQuoteChange = (e, fieldName) => {
    const updatedValue = e.target.value.split('\n'); // Split by new line
    const updatedFormData = [...formData];
    updatedFormData[formDataIndex] = {
      ...updatedFormData[formDataIndex],
      [fieldName]: updatedValue, // Store as an array
    };
    setFormData(updatedFormData);
  };

  // Utility function to extract weights from Containers string
  const extractWeights = (containersString) => {
    if (!containersString) return [];
    const containerData = containersString.split(';');
    return containerData
      .map((data) => {
        const match = data.match(/Weight:\s*(\d+)/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((weight) => weight !== null);
  };

  const containersString = formData[formDataIndex]?.Containers || "";
  const weights = extractWeights(containersString);

  const [updatedContainers, setUpdatedContainers] = useState([]);

  const handleChargeableWeightChange = (e, formDataIndex, index) => {
    const updatedValue = e.target.value;

    // Make sure that formData[formDataIndex]?.updatedContainers exists
    const updatedFormData = [...formData];
    const currentContainer = updatedFormData[formDataIndex]?.updatedContainers?.[index];

    if (currentContainer) {
      // Update the ChargeableWeight for the specific container
      currentContainer.ChargeableWeight = updatedValue;

      // Update formData with new container data
      updatedFormData[formDataIndex] = {
        ...updatedFormData[formDataIndex],
        updatedContainers: [...updatedFormData[formDataIndex]?.updatedContainers],
      };

      // Set the updated formData
      setFormData(updatedFormData);
    } else {
      // Handle case where the container does not exist
      console.warn('Container not found at the specified index.');
    }
  };




  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown}></form>
      <form>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Quotation</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/quotation");
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>

        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Quotation Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-quotation">
                <div className="input-box">
                  <span className="quotation">Enquiry Number<span style={{ color: "red" }}>*</span>
                  </span>
                  {/* <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="EnquiryNumber"
                    value={formData[formDataIndex]?.EnquiryNumber}
                    onChange={handleChange}
                    placeholder="Enter your Enquiry Number"
                  >
                    <option>Select Enquiry Number</option>
                    {uniqueEnquiryNumbers.map((enquiryNumber, index) => (
                      <option key={index} value={enquiryNumber}>
                        {enquiryNumber}
                      </option>
                    ))}
                  </select> */}
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="EnquiryNumber"
                    value={formData[formDataIndex]?.EnquiryNumber}
                    onChange={handleChange}
                    placeholder="Enter your EnquiryNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Company Name<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Company"
                    value={formData[formDataIndex]?.Company}
                    onChange={handleChange}
                    placeholder="Enter your CompanyName"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Address<span style={{ color: "red" }}>*</span>
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="City"
                    value={formData[formDataIndex]?.City}
                    onChange={handleChange}
                    placeholder="Enter your Address"
                  />
                  {/* <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Address"
                    value={Array.isArray(formData[formDataIndex]?.Address)
                      ? formData[formDataIndex]?.Address.join("\n")
                      : formData[formDataIndex]?.Address || ""}
                    onChange={(e) => handleQuoteChange(e, "Address")}
                    placeholder="Enter your Address"
                  /> */}
                </div>
                <div className="input-box">
                  <span className="quotation">
                    PIC<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContactPerson"
                    value={formData[formDataIndex]?.ContactPerson}
                    onChange={handleChange}
                    placeholder="Enter your PIC"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">Mode<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Mode"
                    value={formData[formDataIndex]?.Mode}
                    onChange={handleChange}
                    placeholder="Enter your Mode"
                  >
                    <option>Select Mode</option>
                    {ModeData.map((item) => (
                      <option value={item.modeId}>{item.modeId}</option>
                    ))}
                  </select>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Mode"
                    value={formData[formDataIndex]?.Mode}
                    onChange={handleChange}
                    placeholder="Enter your Mode"
                  /> */}
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Date
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Date"
                    value={formData[formDataIndex]?.Date}
                    onChange={handleChange}
                    placeholder="Enter your Date"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Valid Till Date
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ValidTillDate"
                    value={formData[formDataIndex]?.ValidTillDate}
                    onChange={handleChange}
                    placeholder="Enter your ValidTillDate"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">Incoterm<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Incoterm"
                    value={formData[formDataIndex]?.Incoterm}
                    onChange={handleChange}
                    placeholder="Enter your Incoterm"
                  >
                    <option>Select Incoterm</option>
                    {incotermData.map((item) => (
                      <option value={item.Incoterm}>
                        {item.Incoterm}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Incoterm"
                    value={formData[formDataIndex]?.Incoterm}
                    onChange={handleChange}
                    placeholder="Enter your Incoterm"
                  /> */}
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Commodity<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Commodity"
                    value={formData[formDataIndex]?.Commodity}
                    onChange={handleChange}
                    placeholder="Enter your Commodity"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Pick Up Location<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PickUpLocation"
                    value={formData[formDataIndex]?.PickUpLocation}
                    onChange={handleChange}
                    placeholder="Enter your PickUpLocation"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Delivery Location<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DeliveryLocation"
                    value={formData[formDataIndex]?.DeliveryLocation}
                    onChange={handleChange}
                    placeholder="Enter your  DeliveryLocation"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    {["Airfreight", "LCL", "Breakbulk/RORO"].includes(formData[formDataIndex]?.Mode) ? (
                      "Dimension and Weight" // Only show Dimension and Weight without "Equipment"
                    ) : (
                      <>
                        Equipment Dimension and weight<span style={{ color: "red" }}>*</span>
                      </>
                    )}
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="EquipmentDimensionsandWeight"
                    value={formData[formDataIndex]?.EquipmentDimensionsandWeight}
                    onChange={handleChange}
                    placeholder="Enter your Equipment Dimension and Weight"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">Freight</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Freight"
                    value={formData[formDataIndex]?.Freight}
                    onChange={handleChange}
                  >
                    <option value="">Select Freight</option>
                    <option value="Prepaid">Prepaid</option>
                    <option value="Collect">Collect</option>
                  </select>
                </div>


                {/* Gross Weight, Chargeable Weight, Stackability (with corrected alignment) */}


                {/* Chargeable Weight
                {formData[formDataIndex]?.Mode === 'Airfreight' && (
                  <div className="input-box">
                    <span className="quotation">Chargeable Weight</span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="ChargeableWeight"
                      value={formData[formDataIndex]?.ChargeableWeight || ''}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter your ChargeableWeight"
                    />
                  </div>
                )} */}


                {/* Stackability */}
                {(formData[formDataIndex]?.Mode === 'Airfreight' || formData[formDataIndex]?.Mode === 'LCL') && (
                  <div className="input-box">
                    <span className="quotation">Stackability</span>
                    <select
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      name="Stackability"
                      value={
                        formData[formDataIndex]?.Stackable === "Yes"
                          ? "Stackable"
                          : formData[formDataIndex]?.Stackable === "No"
                            ? "Non Stackable"
                            : formData[formDataIndex]?.Stackability || ""
                      }
                      onChange={handleChange}
                    >
                      <option value="">Select Stackability</option>
                      <option value="Stackable">Stackable</option>
                      <option value="Non Stackable">Non Stackable</option>
                    </select>
                  </div>
                )}

                <div className="input-box">
                  <span className="quotation">
                    Remarks<span style={{ color: "red" }}>*</span>
                  </span>
                  {/* Remarks<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Remarks"
                    value={formData[formDataIndex]?.Remarks}
                    onChange={handleChange}
                    placeholder="Enter your  Remarks"
                  /> */}
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Remarks"
                    value={Array.isArray(formData[formDataIndex]?.Remarks)
                      ? formData[formDataIndex]?.Remarks.join("\n")
                      : formData[formDataIndex]?.Remarks || ""}
                    onChange={(e) => handleQuoteChange(e, "Remarks")}
                    placeholder="Enter your Remarks"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Quote Inclusive of<span style={{ color: "red" }}>*</span>
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="QuoteInclusiveof"
                    value={Array.isArray(formData[formDataIndex]?.QuoteInclusiveof)
                      ? formData[formDataIndex]?.QuoteInclusiveof.join("\n")
                      : formData[formDataIndex]?.QuoteInclusiveof || ""}
                    onChange={(e) => handleQuoteChange(e, "QuoteInclusiveof")}
                    placeholder="Enter your Quote Inclusive of"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Quote Exclusive of<span style={{ color: "red" }}>*</span>
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="QuoteExclusiveof"
                    value={Array.isArray(formData[formDataIndex]?.QuoteExclusiveof)
                      ? formData[formDataIndex]?.QuoteExclusiveof.join("\n")
                      : formData[formDataIndex]?.QuoteExclusiveof || ""}
                    onChange={(e) => handleQuoteChange(e, "QuoteExclusiveof")}
                    placeholder="Enter your Quote Exclusive of"
                  />
                </div>
                <div className="input-box">
                  <span className="quotation">
                    Quoting Address<span style={{ color: "red" }}>*</span>
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Quoting_Address"
                    value={Array.isArray(formData[formDataIndex]?.Quoting_Address)
                      ? formData[formDataIndex]?.Quoting_Address.join("; ")
                      : formData[formDataIndex]?.Quoting_Address || ""}
                    onChange={(e) => handleQuoteChange(e, "Quoting_Address")}
                    placeholder="Enter your Quoting Address"
                  />
                </div>

                {/* <div className="input-box">
                  <span className="quotation">
                    TotalQuoteValue<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TotalQuoteValue"
                    value={formData[formDataIndex]?.TotalQuoteValue || ""} // Default to empty if undefined
                    readOnly // Make it read-only as it's calculated automatically
                    placeholder="Enter your TotalQuoteValue"
                  />
                </div> */}

              </div>
            </div>
          </div>
          <div className="table-container">
            {formData[formDataIndex]?.Mode === "Airfreight" ? (
              <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Description</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Weight (IN KG)</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Rate (per KG)</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">EXCHANGE RATE</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Rate (per KG)</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">{`Total (${formData[formDataIndex]?.Containers?.split(";")?.[0]?.split(",").find((pair) => pair.includes("Currency"))?.split(":")[1] || "Currency"})`}</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">{`Total (${formData[formDataIndex]?.Containers?.split(";")?.[0]?.split(",").find((pair) => pair.includes("Currency1"))?.split(":")[1] || "Currency1"})`}</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData[formDataIndex]?.Containers?.trim() !== "" &&
                    formData[formDataIndex]?.Containers.split(";")
                      .filter((containerString) => containerString.trim() !== "")
                      .map((containerString, index) => {
                        const container = {};
                        containerString.split(",").forEach((pair) => {
                          const [key, value] = pair.split(":");
                          container[key?.trim()] = value?.trim();
                        });

                        return (
                          <tr key={index}>
                            <td>
                              <input
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                type="text"
                                name="Description"
                                value={container.Description || ""}
                                onChange={(e) => handleContainerChange(index, "Description", e.target.value)}
                                placeholder="Enter Description"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="ChargeableWeight"
                                value={container.ChargeableWeight || ""}
                                onChange={(e) => handleContainerChange(index, "ChargeableWeight", e.target.value)} // Handle changes
                                className="text-black bg-gray-200 dark:bg-gray-800 dark:text-white"
                              />
                            </td>
                            <td>
                              <div className="flex items-center space-x-2">
                                <Select
                                  className="w-100"
                                  name="Currency"
                                  value={container.Currency || ""}
                                  onChange={(e) =>
                                    handleContainerChange(index, "Currency", e.target.value, "Currency")
                                  }
                                >
                                  <option value="">Select Currency</option>
                                  {countryData.map((item, idx) => (
                                    <option key={idx} value={item.Currency}>
                                      {item.Currency}
                                    </option>
                                  ))}
                                </Select>
                                <Input
                                  className="w-100"
                                  type="text"
                                  name="Rate"
                                  value={container.Rate || ""}
                                  onChange={(e) =>
                                    handleContainerChange(index, "Rate", e.target.value, "Rate")
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="number"
                                name="ExRate"
                                value={container.ExRate || ""}
                                onChange={(e) => handleContainerChange(index, "ExRate", e.target.value)}
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                placeholder="Enter Ex-Rate"
                              />
                            </td>
                            <td>
                              <div className="flex items-center space-x-2">
                                <Select
                                  className="w-72"
                                  name="Currency1"
                                  value={container.Currency1 || ""}
                                  onChange={(e) =>
                                    handleContainerChange(index, "Currency1", e.target.value, "Currency1")
                                  }
                                >
                                  <option value="">Select Currency</option>
                                  {countryData.map((item, idx) => (
                                    <option key={idx} value={item.Currency}>
                                      {item.Currency}
                                    </option>
                                  ))}
                                </Select>
                                <Input
                                  className="w-36"
                                  type="text"
                                  name="Rate1"
                                  value={container.Rate1 || ""}
                                  onChange={(e) =>
                                    handleContainerChange(index, "Rate1", e.target.value, "Rate1")
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                name="TotalValue1"
                                value={container.TotalValue1 ? parseFloat(container.TotalValue1).toFixed(2) : ""}
                                readOnly
                                className="text-black bg-gray-200 dark:bg-gray-800 dark:text-white"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="TotalValue2"
                                value={container.TotalValue2 ? parseFloat(container.TotalValue2).toFixed(2) : ""}
                                readOnly
                                className="text-black bg-gray-200 dark:bg-gray-800 dark:text-white"
                              />
                            </td>

                            <td>
                              <IoMdAddCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginLeft: "5px", color: "green" }}
                                onClick={addairContainer}
                              />
                              {formData[formDataIndex]?.Containers.split(";").length > 2 && (
                                <BiSolidMinusCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "red" }}
                                  onClick={() => removeContainer(index)}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            ) : (
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Description</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Equipment</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Unit</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">UnitPrice</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Currency</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Ex-Rate</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">TotalValue</th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData[formDataIndex]?.Containers?.trim() !== "" &&
                    formData[formDataIndex]?.Containers
                      .split(";")
                      .filter((containerString) => containerString.trim() !== "")
                      .map((containerString, index) => {
                        const container = {};
                        containerString.split(",").forEach((pair) => {
                          const [key, value] = pair.split(":");
                          container[key?.trim()] = value?.trim();
                        });

                        const isAirfreightOrLCL =
                          formData[formDataIndex]?.Mode === "Airfreight" ||
                          formData[formDataIndex]?.Mode === "LCL" ||
                          formData[formDataIndex]?.Mode === "Breakbulk/RORO";

                        // Split the weight value from EquipmentDimensionsAndWeight
                        const equipmentDimensionsAndWeight = container.EquipmentDimensionsAndWeight || "";
                        const weightValue = equipmentDimensionsAndWeight.split(":")[1]?.trim(); // Assuming weight is after a colon

                        return (
                          <tr key={index}>
                            <td>
                              <input
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                type="text"
                                name="Description"
                                value={container.Description || ""}
                                onChange={(e) => handleContainerChange(index, "Description", e.target.value)}
                                placeholder="Enter Description"
                              />
                            </td>
                            <td>
                              {isAirfreightOrLCL ? (
                                <span>-</span>
                              ) : (
                                <select
                                  className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                  name="Equipment"
                                  value={container.Equipment || ""}
                                  onChange={(e) => handleContainerChange(index, "Equipment", e.target.value)}
                                >
                                  <option value="">Select Equipment</option>
                                  {equipmentData.map((item, idx) => (
                                    <option key={idx} value={item.ContainerType}>
                                      {item.ContainerType}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="Quantity"
                                placeholder={isAirfreightOrLCL ? "Enter Unit (Quantity)" : "Enter Quantity"}
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                value={container.Quantity || ""}
                                onChange={(e) => handleContainerChange(index, "Quantity", e.target.value)}
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                name="UnitPrice"
                                placeholder="Enter UnitPrice"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                value={container.UnitPrice || ""}
                                onChange={(e) => handleContainerChange(index, "UnitPrice", e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "-" || e.key === "+" || e.key === "e") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <select
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                name="Currency"
                                value={container.Currency || ""}
                                onChange={(e) => handleContainerChange(index, "Currency", e.target.value)}
                              >
                                <option value="">Select Currency</option>
                                {countryData.map((item, idx) => (
                                  <option key={idx} value={item.Currency}>
                                    {item.Currency}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                name="ExRate"
                                placeholder="Enter Ex-Rate"
                                className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                value={container.ExRate || ""}
                                onChange={(e) => handleContainerChange(index, "ExRate", e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "-" || e.key === "+" || e.key === "e") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="TotalValue"
                                value={container.TotalValue || ""}
                                readOnly
                                className="text-black bg-gray-200 dark:bg-gray-800 dark:text-white"
                              />
                            </td>

                            <td>
                              <IoMdAddCircle
                                className="cursor-pointer"
                                size={30}
                                style={{ marginLeft: "5px", color: "green" }}
                                onClick={addContainer}
                              />
                              {formData[formDataIndex]?.Containers.split(";").length > 2 && (
                                <BiSolidMinusCircle
                                  className="cursor-pointer"
                                  size={30}
                                  style={{ marginLeft: "5px", color: "red" }}
                                  onClick={() => removeContainer(index)}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            )}
          </div>
          <tr>
            <td colSpan={5} style={{ height: "30px" }}></td>
          </tr>
          {formData[formDataIndex]?.Mode === "Airfreight" ? (
            <>
              <tr>
                <td colSpan={3}></td>
                <td className="font-bold text-left text-[16px]">{`Total (${formData[formDataIndex]?.Containers?.split(";")?.[0]?.split(",").find((pair) => pair.includes("Currency"))?.split(":")[1] || "Currency"})`}:</td>
                <td>
                  <div className="input-box">
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
                      style={{ width: "100%", height: "40px" }}
                      type="number"
                      name="TotalValue1"
                      value={formData[formDataIndex]?.TotalValue1 || ""}
                      readOnly
                    />
                  </div>
                </td>
              </tr>
              {/* Spacer Row */}
              <tr>
                <td colSpan={5} style={{ height: "30px" }}></td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td className="font-bold text-left text-[16px]">{`Total (${formData[formDataIndex]?.Containers?.split(";")?.[0]?.split(",").find((pair) => pair.includes("Currency1"))?.split(":")[1] || "Currency1"})`}:</td>
                <td>
                  <div className="input-box">
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
                      style={{ width: "100%", height: "40px" }}
                      type="number"
                      name="TotalValue2"
                      value={formData[formDataIndex]?.TotalValue2 || ""}
                      readOnly
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td colSpan={5} style={{ height: "10px" }}></td>
              </tr>
              <tr>
                <td colSpan={5}></td>
                <td className="font-bold text-left text-[16px]">TotalQuoteValue:</td>
                <td>
                  <div className="input-box">
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white p-2"
                      style={{ width: "100%", height: "40px" }}
                      type="number"
                      name="TotalQuoteValue"
                      value={formData[formDataIndex]?.TotalQuoteValue || ""}
                      readOnly
                      placeholder="Enter your TotalQuoteValue"
                    />
                  </div>
                </td>
              </tr>
            </>
          )}
        </div>
      </form>
    </>
  );
};


export default AddQuotation;
