import React, { useEffect, useState } from "react";
import Axios, { OperationDashboardAPIInstance } from "../../../api-instance";
import { useToast } from "@chakra-ui/react";
import { Pie, Bar, Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import Select from "react-select";
import { HStack } from "@chakra-ui/react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import html2pdf from "html2pdf.js";
import { Box, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

// Register ChartDataLabels plugin
Chart.register(ChartDataLabels);

const RegionModule = () => {
  const itemsPerPage = 5;
  const [filtered, setFiltered] = useState([]);
  const [selectedSection, setSelectedSection] = useState(0); // 0 = Chart, 1 = Table
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [regionData, setRegionData] = useState([]);
  const [selectedChart, setSelectedChart] = useState({
    value: "pie",
    label: "Pie",
  }); // Set default chart type to 'pie'
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMaxYValue, setSelectedMaxYValue] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [selectedUser, setSelectedUser] = useState({
    value: "all",
    label: "All",
  });
  const [userData, setUserData] = useState([]);
  const chartOptions = [
    { value: "select", label: "select" },
    { value: "pie", label: "Pie" },
    { value: "bar", label: "Bar" },
    { value: "line", label: "Line" },
    { value: "doughnut", label: "Doughnut" },
  ];

  // const regionOptions = [
  //     {value:'select',label:'select'},
  //     { value: 'Northern', label: 'Northern' },
  //     { value: 'Southern', label: 'Southern' },
  //     { value: 'Eastern', label: 'Eastern' },
  //     { value: 'Western', label: 'Western' },
  // ];
  const fixedBoxStyle = {
    width: "100%",
    height: "300px", // Adjust to your preferred fixed size
    minHeight: "300px",
    // overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid #e2e2e2",
    // borderRadius: "md",
    // bg: "gray.50",
  };
  // Fetch users data
  const fetchUsersData = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;

      const { data } = await OperationDashboardAPIInstance.get(
        `get_users?userId=${userId}`
      );
      setUserData(
        data.data.map((userId) => ({ value: userId, label: userId }))
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  // Function to fetch ongoing data
  const getRegionData = async (userId) => {
    try {
      setIsLoading(true);
      const token = JSON.parse(localStorage.getItem("token"));
      const storedUserId = JSON.parse(localStorage.getItem("user")).userId;

      const finalUserId = userId === "all" ? storedUserId : userId;
      const type = selectedUser.value === "all" ? "all" : "no";
      if (finalUserId && token) {
        const { data } = await OperationDashboardAPIInstance.get(
          `get_regiondata?userId=${finalUserId}&type=${type}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        setRegionData(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching ongoing data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      getRegionData(selectedUser.value);
    }
  }, [selectedUser]);

  function paginateArray(array, currentPage) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = array.slice(startIndex, endIndex);
    const totalPage = Math.ceil(array.length / itemsPerPage);

    setCurrentPage(currentPage);
    setFiltered(paginatedData);
    setTotalPage(totalPage);
  }

  useEffect(() => {
    if (!regionData.length) return;
    paginateArray(regionData, currentPage);
  }, [regionData, currentPage]);

  const handleChartChange = (selectedOption) => {
    setSelectedChart(selectedOption);
  };

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
  };

  const filteredData = selectedRegion.length
    ? regionData.filter((item) =>
        selectedRegion
          .map((region) => region.value)
          .includes(item.RegionCategory)
      )
    : filtered;

  const chartData = {
    labels: filteredData.map((item) => item.RegionCategory),
    datasets: [
      {
        label: "Regions",
        data: filteredData.map((item) => item.TotalJobCount),

        backgroundColor: [
          "rgba(255, 99, 71, 0.2)", // Tomato Red (Soft)
          "rgba(255, 165, 0, 0.2)", // Orange (Soft)
          "rgba(50, 205, 50, 0.2)", // Lime Green (Soft)
          "rgba(100, 149, 237, 0.2)", // Cornflower Blue (Soft)
          "rgba(255, 105, 180, 0.2)", // Hot Pink (Soft)
          "rgba(238, 130, 238, 0.2)", // Violet (Soft)
        ],
        borderColor: [
          "rgba(255, 99, 71, 1)", // Tomato Red (Vibrant)
          "rgba(255, 165, 0, 1)", // Orange (Vibrant)
          "rgba(50, 205, 50, 1)", // Lime Green (Vibrant)
          "rgba(100, 149, 237, 1)", // Cornflower Blue (Vibrant)
          "rgba(255, 105, 180, 1)", // Hot Pink (Vibrant)
          "rgba(238, 130, 238, 1)", // Violet (Vibrant)
        ],

        borderWidth: 1,
      },
    ],
  };
  const handlePrevious = () => {
    setSelectedSection((prev) => (prev === 0 ? 1 : 0));
  };
  const handleNext = () => {
    setSelectedSection((prev) => (prev === 1 ? 0 : 1));
  };
  const chartOptionsWithLabels = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2) + "%";
          return percentage;
        },
        color: "black",
      },
    },
  };
  const commonChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: selectedMaxYValue ? parseInt(selectedMaxYValue, 10) : undefined,
        ticks: {
          stepSize: selectedInterval
            ? parseInt(selectedInterval, 10)
            : undefined,
        },
      },
    },
  };
  const renderChart = () => {
    const chartHeight =
      100 + (selectedRegion.length > 1 ? (selectedRegion.length - 1) * 20 : 0);
    const chartStyle = { height: `${chartHeight}px` };
    switch (selectedChart.value) {
      case "pie":
        return (
          <Pie
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
      case "bar":
        return (
          <Bar
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={commonChartOptions}
          />
        );
      case "line":
        return (
          <Line
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={commonChartOptions}
          />
        );
      case "doughnut":
        return (
          <Doughnut
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
      default:
        return (
          <Pie
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
    }
  };
  const regionOptions = [
    ...new Set(
      regionData.map((item) => ({
        value: item.RegionCategory,
        label: item.RegionCategory,
      }))
    ),
  ];
  const renderTable = () => {
    return (
      <div className="relative">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-neutral-200">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 tracking-wider"
              >
                Region
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 tracking-wider"
              >
                Count
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-black-900">
                  {item.RegionCategory}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                  {item.TotalJobCount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  // Function to download the chart and table as PDF
  const downloadContentAsPDF = () => {
    const element = document.getElementById("content-container");
    const opt = {
      margin: 1,
      filename: "RegionWiseJob_chart.pdf",
      image: { type: "jpeg", quality: 1.0 }, // Set image quality to maximum (1.0)
      html2canvas: { scale: 4 }, // Increase scale for higher resolution (4)
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <Box
      className="main-card"
      // border="1px solid #ccc"
      borderRadius="2xl"
      p={4}
      // shadow="md"
      bg="white"
      maxW="90%"
      mx="auto"
    >
      {/* Card Title */}
      {/* <Box
        as="h2"
        fontSize="xl"
        fontWeight="bold"
        mb={4}
        bgGradient="linear(to-r, teal.400, blue.500, purple.600)"
        backgroundClip="text"
      >
        Region-Wise Job
      </Box> */}

      {/* Navigation Icons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={handlePrevious}
          aria-label="Previous Section"
          disabled={selectedSection === 0}
          // colorScheme="blue"
        />
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={handleNext}
          aria-label="Next Section"
          disabled={selectedSection === 1}
          //colorScheme="blue"
        />
      </Box>

      {/* Sections */}
      {selectedSection === 0 && (
        <Box>
          {/* Dropdowns and Chart */}
          <HStack spacing={4} mb={4} width="100%" justify="center">
            <Select
              options={[
                { value: "Select", label: "Select" },
                { value: "all", label: "All" },
                ...userData,
              ]}
              value={selectedUser}
              onChange={setSelectedUser}
              className="w-40"
            />
            <Select
              options={chartOptions}
              value={selectedChart}
              onChange={handleChartChange}
              className="w-40"
            />
            <Select
              isMulti
              options={regionOptions}
              value={selectedRegion}
              onChange={handleRegionChange}
              className="w-40"
            />
          </HStack>
          <Box style={fixedBoxStyle}>{renderChart()}</Box>
        </Box>
      )}

      {selectedSection === 1 && (
        <Box>
          {/* Table */}
          <HStack spacing={4} mb={4} width="100%" justify="center">
            {" "}
          </HStack>
          <Box style={fixedBoxStyle}>{renderTable()}</Box>
        </Box>
      )}
    </Box>
  );
};
export default RegionModule;
