export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    handleOpenEditor,
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.handleOpenEditor = handleOpenEditor;
  }


  handleEditClickMassmail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
  
    if (this.checkBoxSelected.length > 1) {
      console.log("Multiple rows selected.");
      this.toast({
        title: "Please select one row",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
  
    // If exactly one checkbox is selected, continue with the action.
    this.handleOpenEditor();
  }
  
 
}
