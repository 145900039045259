import React from "react";
import { useState, useEffect } from "react";
import { Rnd } from "react-rnd";


import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import './DraggableReportTable.css'
// Icons Imports
import { MdOutlineDatasetLinked } from "react-icons/md";
import { BiSort } from "react-icons/bi";
const DraggableReportTable = ({
 isLoading,
 columns,
 data,
 reportData,
 getData,
 setReportData,
 index,
 checkBoxSelected,
 setCheckBoxSelected,
 currentPage,
 handleIconClick,
 setCheckBoxAll,
 checkBoxAll,
 handleDragOver,
 handleDragStart,
 handleDrop,
 tableColumnsReport,
 pageNumbers,
 fromDate,
 toDate,


}) => {
 const toast = useToast();
 // State Variables
 const [sortKey, setSortKey] = useState("");
 const [sortOrder, setSortOrder] = useState("");
 const [selectedLeadOwner, setSelectedLeadOwner] = useState(null);
 //This state is used for header checkbox selection
 const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
 const handleLeadOwnerClick = (leadOwnerId) => {
   setSelectedLeadOwner(leadOwnerId);
 }
 useEffect(() => {
   if (checkBoxAll) {
     const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
       (number, i) => {
         return number;
       }
     );
     setActivePageCheckBoxSelect(a);
   }
 }, [pageNumbers, checkBoxAll]);


 const sortTable = (key) => {
   let newSortOrder = "asc";


   if (sortKey === key) {
     newSortOrder = sortOrder === "asc" ? "desc" : "asc";
   }


   const sortedData = [...reportData].sort((a, b) => {
     if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
     if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
     return 0;
   });


   setSortKey(key);
   setSortOrder(newSortOrder);


   if (typeof setReportData === "function") {
     setReportData(sortedData);
   }
 };


 return (
   <div>
     {!isLoading ? (
       tableColumnsReport?.length !== 0 ? (
         <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
           {/* Table header */}
           <thead className="flex-nowrap text-center">
             <tr>
               {/* <th className="border border-slate-300 whitespace-nowrap text-center"  style={{ textAlign: "left" }}>
               Details
             </th> */}


               {tableColumnsReport.map(
                 (column, index) =>
                   column.label !== "FromDate" &&
                   column.label !== "ToDate" &&
                   column.label !== "LeadIds" &&
                   column.label !== "ProspectIds" &&
                   column.label !== "MeetingIds" &&
                   column.label !== "CallIds" &&
                   column.label !== "ConductedMeetingIds" &&
                   column.label !== "CanceledMeetingIds" &&
                   column.label !== "ConductedCallIds" &&
                   column.label !== "CanceledCallIds" &&
                   column.isVisible === true && (
                     <th
                       className="border border-slate-300 whitespace-nowrap text-left"
                       key={column.key}
                       style={{ textAlign: "left" }}
                       draggable
                       onDragStart={(event) => handleDragStart(event, index)}
                       onDragOver={handleDragOver}
                       onDrop={(event) => handleDrop(event, index)}
                     >
                       <span style={{ cursor: "all-scroll" }}>
                         {column.label}
                       </span>
                       <BiSort
                         style={{
                           marginLeft: "160px",
                           marginTop: "-22px",
                           cursor: "pointer",
                         }}
                         onClick={() => sortTable(column.key)}
                       />
                     </th>
                   )
               )}
             </tr>
           </thead>
           {/* Table body */}
           <tbody>
             {data?.length !== 0 ? (
               data.map((row, rowIndex) => (
                 <tr key={rowIndex}
                 className={row.LeadOwnerName === selectedLeadOwner ? "highlighted-row" : ""}
                 >
                   {/* <td>
                 {false ? (
 <IconButton
   icon={<MdOutlineDatasetLinked />}
   onClick={() => handleIconClick(row.Id,fromDate,toDate)}
   style={{
     cursor: "pointer",
     transform: "scale(1.3)",
     marginRight: "5px",
   }}
 />
) : (
 <IconButton
   icon={<MdOutlineDatasetLinked />}
   onClick={() =>handleIconClick(row.Id,fromDate,toDate)}
   style={{
     cursor: "pointer",
     transform: "scale(1.3)",
     marginRight: "5px",
   }}
 />
)}
                 </td> */}
                   {tableColumnsReport.map(
                     (column, columnIndex) =>
                       column.label !== "FromDate" &&
                       column.label !== "ToDate" &&
                       column.label !== "LeadIds" &&
                   column.label !== "ProspectIds" &&
                   column.label !== "MeetingIds" &&
                   column.label !== "CallIds" &&
                   column.label !== "ConductedMeetingIds" &&
                   column.label !== "CanceledMeetingIds" &&
                   column.label !== "ConductedCallIds" &&
                   column.label !== "CanceledCallIds" &&
                       column.isVisible === true && (
                         <td
                           key={`${rowIndex}-${columnIndex}`}
                           style={{
                             textAlign: "left",
                             justifyContent: "space-evenly",
                           }}
                         >
                           {column.key === "LeadOwnerName" ? (
                             <button
                               onClick={() =>{
                                 handleIconClick(
                                   row.Id,
                                   row.LeadIds,
                                   row.ProspectIds,
                                   row.MeetingIds,
                                   row.CallIds,
                                   row.ConductedMeetingIds,
                                   row.CanceledMeetingIds,
                                   row.ConductedCallIds,
                                   row.CanceledCallIds,
                                 );
                                 handleLeadOwnerClick(row.LeadOwnerName);
                               }}
                             >
                               {row[column.key]}
                             </button>
                           ) : (
                             row[column.key]
                           )}
                         </td>
                       )
                   )}
                 </tr>
               ))
             ) : (
               <tr>
                 <td
                   colSpan={tableColumnsReport.length}
                   style={{ textAlign: "center" }}
                 >
                   No Data Found!
                 </td>
               </tr>
             )}
           </tbody>
         </table>
       ) : (
         <h1 style={{ textAlign: "center", color: "black" }}>
           No Data Found!
         </h1>
       )
     ) : (
       <p>Loading...</p>
     )}
   </div>
 );
};
export default DraggableReportTable;