import { React, useState, useEffect } from "react";
// Internal module import
import "./DraggableDocumentTable.css";
// Chakara ui modules

import { IconButton, Tooltip, useToast } from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
const DraggableDocumentTable = ({
  isLoading,
  columns,
  data,
  documentData,
  getData,
  setDocumentData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsDocument,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables

  const [selectedRowId, setSelectedRowId] = useState(null);

  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...documentData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setDocumentData === "function") {
      setDocumentData(sortedData);
    }
  };
  return (
    <div>
      {!isLoading ? (
        tableColumnsDocument?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
                <button
                onClick={toggleIcon}
                checked={selectAllPages}
                onChange={handleSelectAllPages}
                checkBoxAll={checkBoxAll}
                >
                  {showFactCheck ? (
                    <MdOutlineFactCheck size={20} />
                  ) : (
                    <MdCheckBoxOutlineBlank size={20} />
                  )}
                </button> */}
                  </div>
                </th>

                {tableColumnsDocument.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "JobNumber" &&
                    column.label !== "BookingNumber" &&
                    column.label !== "JobInfono" &&
                    column.label !== "Doc_Name" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.label === "URL_Object" ? (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              Uploaded Document
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "230px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : column.label === "DOCID" ? (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              Documnet ID
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "100px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label
                                .replace("_", " ")
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </span>
                            <BiSort
                              className="whitespace-nowrap text-left"
                              style={{
                                marginLeft: "120px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.JobNumber,
                                row?.BookingNumber,
                                row.Doc_Name,
                                row?.URL_Object
                              );
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.JobNumber,
                                row?.BookingNumber,
                                row.Doc_Name,
                                row?.URL_Object
                              );
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>

                    {tableColumnsDocument.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "JobNumber" &&
                        column.label !== "BookingNumber" &&
                        column.label !== "JobInfono" &&
                        column.label !== "Doc_Name" &&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                              margin: "2px",
                            }}
                          >
                            {column.key === "URL_Object" ? (
                              row.URL_Object ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  href={`${row.URL_Object}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.URL_Object.split("/").pop()}
                                </a>
                              ) : (
                                row.Doc_Name
                              )
                            ) : column.key === "ChangeDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsDocument.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DraggableDocumentTable;
