import { React, useState, useEffect } from "react";
// Internal module import
import "./DraggableActivelogTable.css";
// Chakara ui modules

import {
  IconButton,
  Tooltip,
  useToast,

} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";

const DraggableActivelogTable = ({
  isLoading,
  columns,
  data,
  operationActivitylogData,
  getData,
  setOperationActivitylogData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsOperationActivitylog,
  pageNumbers,
}) => {
  const toast = useToast();
  // State Variables

  const [selectedRowId, setSelectedRowId] = useState(null);

  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...operationActivitylogData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setOperationActivitylogData === "function") {
      setOperationActivitylogData(sortedData);
    }
  };
  return (
    <div>
    {!isLoading ? (
      tableColumnsOperationActivitylog?.length !== 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
          {/* Table header */}
          <thead className="flex-nowrap text-center">
            <tr>
              {tableColumnsOperationActivitylog.map(
                (column, index) =>
                  column.label !== "Id" &&

                  column.isVisible === true && (
                    <th
                      className="border border-slate-300 whitespace-nowrap text-left"
                      key={column.key}
                      style={{ textAlign: "left" }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, index)}
                      onDragOver={handleDragOver}
                      onDrop={(event) => handleDrop(event, index)}
                    >
                      {
                        <span style={{ cursor: "all-scroll" }}>
                        {column.label === "TransferredFrom"
                          ? "Transferred From"
                          : column.label === "ToTransferred"
                          ? "To Transferred"
                          : column.label}
                      </span>
                      }
                    </th>
                  )
              )}
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {data?.length !== 0 ? (
              data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={
                    selectedRowId === row.id
                      ? { backgroundColor: "#e2e8f0" }
                      : {}
                  }
                >
                  

                  {tableColumnsOperationActivitylog.map((column, columnIndex) =>
  column.label !== "Id" &&
  column.isVisible === true && (
    <td
      key={`${rowIndex}-${columnIndex}`}
      style={{
        textAlign: "left",
        justifyContent: "space-evenly",
      }}
    >
      {
        // Handle Date column separately
        column.key === "Date" ? (
          row[column.key] ? (
            new Date(row[column.key]).toLocaleDateString()
          ) : (
            ""
          )
        ) :
        // Handle TransferredFrom and ToTransferred columns
        column.key === "TransferredFrom" || column.key === "ToTransferred" ? (
          row[column.key] === null ? (
            "No Data"
          ) : (
            row[column.key]
          )
        ) :
        // Default case for other columns
        row[column.key]
      }
    </td>
  )
)}

                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={tableColumnsOperationActivitylog.length}
                  style={{ textAlign: "center" }}
                >
                  No Data Found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <h1 style={{ textAlign: "center", color: "black" }}>
          No Data Found!
        </h1>
      )
    ) : (
      <p>Loading...</p>
    )}

  </div>
  )
}

export default DraggableActivelogTable