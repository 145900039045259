import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SwitchHbl.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { Header } from "../../../components";

const AddSwitchHbl = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("Switch HBL Job:", selectedJob);
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [PortData, setportData] = useState([]);
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [editedSwitchHblData, setEditedSwitchHblData] = useState({});
  const [selectedSwitchHblData, setSelectedSwitchHblData] = useState({});
  const [formData, setFormData] = useState(selectedSwitchHblData);

  //get si data
  const [siData, setsiData] = useState({});
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await OperationDocumentAPIInstance.get(
          `get_sihbldata?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&BookingNumber=${selectedJob.BookingNumber}`
        );
        if (data.data.length === 0) {
          toast({
            title: "No freight data found.",
            position: "bottom-center",
            isClosable: true,
            status: "warning",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
          setsiData(data.data[0]);
        }
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [selectedJob]);
  let initial_SiData = siData;
  const [initialType, setInitialType] = useState("");
  const [descriptionType, setDescriptionType] = useState();
  console.log(initial_SiData);
  useEffect(() => {
    if (selectedJob) {
      setSelectedSwitchHblData({
        SiNumber: initial_SiData.SiNumber,
        BookingNumber: initial_SiData.BookingNumber,
        JobNumber: initial_SiData.JobNumber,
        BLNumber1: "",
        BLNumber2: "",
        Shipper1: "",
        Shipper2: "",
        Consignee1: "",
        Consignee2: "",
        JobInfoNo: initial_SiData.JobInfoNo || "",
        Notify_Party_1: initial_SiData.Notify_Party_1,
        Notify_Party_2: initial_SiData.Notify_Party_2,
        DeliveryAgent1: "",
        DeliveryAgent2: "",
        PlaceOfReceipt: initial_SiData.PlaceOfReceipt,
        TermsOfShipment: "",
        POL: initial_SiData.POL,
        POD: initial_SiData.POD,
        HSNCode: "",
        Marks_Number: initial_SiData.Marks_Number,
        PlaceOfDelivery: initial_SiData.PlaceOfDelivery,
        TypeOfShipment: "",
        VesselNameVoyage: initial_SiData.VesselNameVoyage,
        BLType1: "",
        BLType2: "",
        Originals1: "",
        Originals2: "",
        FreightTerms1: "",
        FreightTerms2: "",
        FreightPayableAt1: "",
        FreightPayableAt2: "",
        FreightDetails1: "",
        FreightDetails2: "",
        ShippedOnBoardDate: "",
        DateOfIssue: "",
        CompanyName: "",
        PlaceOfIssue: "",
        type: initialType, // Set type to descriptionType
        SHblOwner: JSON.parse(localStorage.getItem("user")).userId,
        //   Operations:
        // initial_SiData.Containers && initial_SiData.Containers !== ""
        //   ? initial_SiData.Containers.split(";").map((container) => {
        //       const containerObj = {};
        //       container.split(",").forEach((attribute) => {
        //         const [key, value] = attribute.trim().split(":");
        //         containerObj[key.trim()] = value.trim();
        //       });
        //       return containerObj;
        //     })
        //   : [],
        Operations:
          initial_SiData.Containers && initial_SiData.Containers !== ""
            ? initial_SiData.Containers.split(";").map((container) => {
                const containerObj = {};
                container.split(",").forEach((attribute) => {
                  const [key, value] = attribute
                    .trim()
                    .split(":")
                    .map((item) => item.trim());
                  containerObj[key] = value === "" ? null : value;
                });
                return containerObj;
              })
            : [],
      });
    }
  }, [selectedJob, initial_SiData, initialType]);
  console.log("jacna", selectedSwitchHblData);
  // Define initialType state variable
  useEffect(() => {
    if (
      selectedSwitchHblData &&
      selectedSwitchHblData.Operations &&
      selectedSwitchHblData.Operations.length > 0
    ) {
      const initialType = selectedSwitchHblData.Operations[0].type;
      setInitialType(initialType); // Update initialType state variable
    }
  }, [selectedSwitchHblData]);
  useEffect(() => {
    setDescriptionType(initialType);
  }, [initialType]);

  console.log("Description type:", descriptionType);
  // SiNumber: "SI123456",
  //   BLNumber1:"",
  //   BLNumber2:"",
  //   Shipper1: "Shipper Company1",
  //   Shipper2: "Shipper Company2",
  //   Consignee1: "Consignee Company1",
  //   Consignee2: "Consignee Company2",
  //   NotifyParty1: "Notify Party 1",
  //   NotifyParty2: "Notify Party 1",
  //   DeliveryAgent1:"Port E",
  //   DeliveryAgent2:"Port E",
  //   PlaceOfReceipt:"ABC",
  //   TermsOfShipment:"OKHD",
  //   POL:"USA",
  //   POD:"Canada",
  //   HSNCode:"H856",
  //   MarksAndNumber: "ABCD123456",
  //   PlaceOfDelivery:"Chennai",
  //   TypeOfShipment:"FCL",
  //   VesselNameVoyage: "Vessel ABC, Voyage XYZ",
  //   BLType1:"ORIGINAL",
  //   BLType2:"SURRENDER",
  //   Originals1:"GHT",
  //   Originals2:"FGT",
  //   FreightTerms1:"PREPAID",
  //   FreightTerms2:"PREPAID",
  //   FreightPayableAt1:"WEYT",
  //   FreightPayableat2:"JDJS",
  //   FreightDetails1:"SJDS",
  //   FreightDetails2:"WOEIU",
  //   ShippedOnBoardDate:"25jan25",
  //   DateOfIssue:"14Feb24",
  //   CompanyName:"Bruhat",
  //   PlaceOfIssue:"Mumbai",
  //   Operations: [
  //     {
  //       ContainerType: "20GP",
  //       ContainerNumber: "ABC123456",
  //       SealNumber: "SEAL123456",
  //       No_Of_Pkge: "10",
  //       Type_Of_Pkge: "Cartons",
  //       NtWeight: "5000 kg",
  //       GrWeight: "5500 kg",
  //       Volume: "20 cbm",
  //     },
  //     {
  //       ContainerType: "20GP",
  //       ContainerNumber: "ABC123456",
  //       SealNumber: "SEAL123456",
  //       No_Of_Pkge: "10",
  //       Type_Of_Pkge: "Cartons",
  //       NtWeight: "5000 kg",
  //       GrWeight: "5500 kg",
  //       Volume: "20 cbm",
  //     },
  //     {
  //       ContainerType: "40HC",
  //       ContainerNumber: "DEF123456",
  //       SealNumber: "SEAL789012",
  //       No_Of_Pkge: "20",
  //       Type_Of_Pkge: "Pallets",
  //       NtWeight: "10000 kg",
  //       GrWeight: "11000 kg",
  //       Volume: "40 cbm",
  //     },
  //   ],

  useEffect(() => {
    // Update formData with editedSwitchHblData
    setFormData(editedSwitchHblData);
  }, [editedSwitchHblData, descriptionType]);

  // const [formData, setFormData] = useState({
  //   JobInfoNo: "123456",
  //   SiNumber: "SI123456",
  //   Shipper: "Shipper Company",
  //   PlaceOfReceipt: "Port A",
  //   Consignee: "Consignee Company",
  //   POL: "Port B",
  //   NotifyParty: "Notify Party 1",
  //   POD: "Port C",
  //   NotifyParty2: "Notify Party 2",
  //   PlaceofDelivery: "Port D",
  //   LinerBookingNumber: "LBN123456",
  //   vesselAndVoyage: "Vessel ABC, Voyage XYZ",
  //   Incoterm: "FOB",
  //   BLType: "Master",
  //   HSNCode: "123456789",
  //   Originals: "2",
  //   MarksAndNumber: "ABCD123456",
  //   Origin: "Prepaid",
  //   Destination: "Prepaid",
  //   Freight: "Prepaid",
  //   Operations: [
  //     {
  //       ContainerType: "20GP",
  //       ContainerNumber: "ABC123456",
  //       SealNumber: "SEAL123456",
  //       No_Of_Pkge: "10",
  //       Type_Of_Pkge: "Cartons",
  //       NtWeight: "5000 kg",
  //       GrWeight: "5500 kg",
  //       Volume: "20 cbm",
  //     },
  //     {
  //       ContainerType: "20GP",
  //       ContainerNumber: "ABC123456",
  //       SealNumber: "SEAL123456",
  //       No_Of_Pkge: "10",
  //       Type_Of_Pkge: "Cartons",
  //       NtWeight: "5000 kg",
  //       GrWeight: "5500 kg",
  //       Volume: "20 cbm",
  //     },
  //     {
  //       ContainerType: "40HC",
  //       ContainerNumber: "DEF123456",
  //       SealNumber: "SEAL789012",
  //       No_Of_Pkge: "20",
  //       Type_Of_Pkge: "Pallets",
  //       NtWeight: "10000 kg",
  //       GrWeight: "11000 kg",
  //       Volume: "40 cbm",
  //     },
  //   ],
  // });
  const handleSingleDescription = (value) => {
    console.log(descriptionType);
    console.log(value);
    if (descriptionType === "Single" && value) {
      const updatedOperationsWithDescription =
        editedSwitchHblData.Operations.map((operation) => ({
          ...operation,
          descriptionOfGoods: value,
        }));
      setEditedSwitchHblData((prevState) => ({
        ...prevState,
        type: "Single",
        Operations: updatedOperationsWithDescription,
      }));
    }
    setDescriptionOfGoods(value);
  };
  const handleDescriptionChange = (index, value) => {
    const updatedOperations = [...editedSwitchHblData.Operations];
    updatedOperations[index].descriptionOfGoods = value;
    setEditedSwitchHblData((prevState) => ({
      ...prevState,
      type: "Containerwise",
      Operations: updatedOperations,
    }));
  };

  useEffect(() => {
    setEditedSwitchHblData(selectedSwitchHblData);
  }, [selectedSwitchHblData]);
  const [packageTypeData, setPackageTypeData] = useState([]);
  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const [customerData, setCustomerData] = useState([]);
  // Customer

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setEditedSwitchHblData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleShipper1Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Shipper1: e.target.value,
    }));
  };
  const handleNotifyParty1Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Notify_Party_1: e.target.value,
    }));
  };
  const handleDeliveryAgent1Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      DeliveryAgent1: e.target.value,
    }));
  };
  const handleShipper2Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Shipper2: e.target.value,
    }));
  };
  const handleNotifyParty2Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Notify_Party_2: e.target.value,
    }));
  };
  const handleDeliveryAgent2Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      DeliveryAgent2: e.target.value,
    }));
  };
  const handlePolChange = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };
  const handleConsignee1Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Consignee1: e.target.value,
    }));
  };
  const handleConsignee2Change = (e) => {
    setEditedSwitchHblData((prevData) => ({
      ...prevData,
      Consignee2: e.target.value,
    }));
  };

  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.post(
      `add_shblcreation?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedSwitchHblData.Operations];
    updatedOperations[index][key] = value;
    setEditedSwitchHblData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
  
  };
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await OperationDocumentAPIInstance.post(
        `add_switchhbl?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown}>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">SWITCH HBL</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>

          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Switch HBL Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-switchhbl">
                <div className="input-box" hidden>
                  <span className="switchhbl" hidden>
                    SI Number
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="SINumber"
                    defaultValue={
                      formData["SINumber"] || selectedSwitchHblData["SINumber"]
                    }
                    value={editedSwitchHblData["SINumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your SINumber"
                    required
                    disabled
                    hidden
                  />
                </div>
                {/* <div className="input-box">
                  <span className="switchhbl">
                    SiNumber
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="SiNumber"
                    value={SiNumber}
                    onChange={handleChange}
                    placeholder="Enter your SiNumber"
                    disabled
                  />
                </div> */}
                <div className="input-box">
                  <span className="switchhbl">
                    Shipper1
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Shipper1"
                    defaultValue={
                      formData["Shipper1"] || selectedSwitchHblData["Shipper1"]
                    }
                    value={editedSwitchHblData["Shipper1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Shipper1"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Shipper1"] || selectedSwitchHblData["Shipper1"]
                    }
                    value={editedSwitchHblData["Shipper1"] || ""}
                    onChange={handleShipper1Change}
                  >
                    <option>Select Shipper</option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Shipper2
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Shipper2"
                    defaultValue={
                      formData["Shipper2"] || selectedSwitchHblData["Shipper2"]
                    }
                    value={editedSwitchHblData["Shipper2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Shipper2"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Shipper2"] || selectedSwitchHblData["Shipper2"]
                    }
                    value={editedSwitchHblData["Shipper2"] || ""}
                    onChange={handleShipper2Change}
                  >
                    <option>Select Shipper</option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Consignee1
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Consignee1"
                    defaultValue={
                      formData["Consignee1"] ||
                      selectedSwitchHblData["Consignee1"]
                    }
                    value={editedSwitchHblData["Consignee1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Consignee1"
                  /> */}
                  {/* <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="Consignee1"
                    value={editedSwitchHblData["Consignee1"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Consignee1</option>
                    <option value="Original">Original </option>
                    <option value="Non - Negotiable">Non - Negotiable</option>
                  </select> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Consignee1"] || selectedSwitchHblData["Consignee1"]
                    }
                    value={editedSwitchHblData["Consignee1"] || ""}
                    onChange={handleConsignee1Change}
                  >
                    <option>Select Consignee1</option>
                    <option
                      value={
                        formData["Consignee1"] || selectedSwitchHblData["Consignee1"]
                      }
                    >
                      {formData["Consignee1"] || selectedSwitchHblData["Consignee1"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Consignee2
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Consignee2"
                    defaultValue={
                      formData["Consignee2"] ||
                      selectedSwitchHblData["Consignee2"]
                    }
                    value={editedSwitchHblData["Consignee2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Consignee2"
                  /> */}
                  {/* <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="Consignee2"
                    value={editedSwitchHblData["Consignee2"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Consignee2</option>
                    <option value="Original">Original </option>
                    <option value="Non - Negotiable">Non - Negotiable</option>
                  </select> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Consignee2"] || selectedSwitchHblData["Consignee2"]
                    }
                    value={editedSwitchHblData["Consignee2"] || ""}
                    onChange={handleConsignee2Change}
                  >
                    <option>Select Consignee1</option>
                    <option
                      value={
                        formData["Consignee2"] || selectedSwitchHblData["Consignee2"]
                      }
                    >
                      {formData["Consignee2"] || selectedSwitchHblData["Consignee2"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Notify Party1
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party_1"
                    defaultValue={
                      formData["Notify_Party_1"] ||
                      selectedSwitchHblData["Notify_Party_1"]
                    }
                    value={editedSwitchHblData["Notify_Party_1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify_Party_1"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Notify_Party_1"
                    defaultValue={
                      formData["Notify_Party_1"] ||
                      selectedSwitchHblData["Notify_Party_1"]
                    }
                    value={editedSwitchHblData["Notify_Party_1"] || ""}
                    onChange={handleNotifyParty1Change}
                  >
                    <option>Select Notify Party 1</option>
                    <option
                      value={
                        formData["Notify_Party_1"] || selectedSwitchHblData["Notify_Party_1"]
                      }
                    >
                      {formData["Notify_Party_1"] || selectedSwitchHblData["Notify_Party_1"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Notify Party2
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party_2"
                    defaultValue={
                      formData["Notify_Party_2"] ||
                      selectedSwitchHblData["Notify_Party_2"]
                    }
                    value={editedSwitchHblData["Notify_Party_2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify_Party_2"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Notify_Party_2"
                    defaultValue={
                      formData["Notify_Party_2"] ||
                      selectedSwitchHblData["Notify_Party_2"]
                    }
                    value={editedSwitchHblData["Notify_Party_2"] || ""}
                    onChange={handleNotifyParty2Change}
                  >
                    <option>Select Notify Party 2</option>
                    <option
                      value={
                        formData["Notify_Party_2"] || selectedSwitchHblData["Notify_Party_2"]
                      }
                    >
                      {formData["Notify_Party_2"] || selectedSwitchHblData["Notify_Party_2"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Delivery Agent1
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DeliveryAgent1"
                    defaultValue={
                      formData["DeliveryAgent1"] ||
                      selectedSwitchHblData["DeliveryAgent1"]
                    }
                    value={editedSwitchHblData["DeliveryAgent1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Delivery Agent1"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="DeliveryAgent1"
                    defaultValue={
                      formData["DeliveryAgent1"] ||
                      selectedSwitchHblData["DeliveryAgent1"]
                    }
                    value={editedSwitchHblData["DeliveryAgent1"] || ""}
                    onChange={handleDeliveryAgent1Change}
                  >
                    <option>Select Delivery Agent 1</option>
                    <option
                      value={
                        formData["DeliveryAgent1"] || selectedSwitchHblData["DeliveryAgent1"]
                      }
                    >
                      {formData["DeliveryAgent1"] || selectedSwitchHblData["DeliveryAgent1"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Delivery Agent2
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DeliveryAgent2"
                    defaultValue={
                      formData["DeliveryAgent2"] ||
                      selectedSwitchHblData["DeliveryAgent2"]
                    }
                    value={editedSwitchHblData["DeliveryAgent2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Delivery Agent2"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="DeliveryAgent2"
                    defaultValue={
                      formData["DeliveryAgent2"] ||
                      selectedSwitchHblData["DeliveryAgent2"]
                    }
                    value={editedSwitchHblData["DeliveryAgent2"] || ""}
                    onChange={handleDeliveryAgent2Change}
                  >
                    <option>Select Delivery Agent 2</option>
                    <option
                      value={
                        formData["DeliveryAgent2"] || selectedSwitchHblData["DeliveryAgent2"]
                      }
                    >
                      {formData["DeliveryAgent2"] || selectedSwitchHblData["DeliveryAgent2"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Place of Receipt
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfReceipt"
                    defaultValue={
                      formData["PlaceOfReceipt"] ||
                      selectedSwitchHblData["PlaceOfReceipt"]
                    }
                    value={editedSwitchHblData["PlaceOfReceipt"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfReceipt"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    Terms of Shipment
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TermsOfShipment"
                    defaultValue={
                      formData["TermsOfShipment"] ||
                      selectedSwitchHblData["TermsOfShipment"]
                    }
                    value={editedSwitchHblData["TermsOfShipment"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your TermsOfShipment"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    POL
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={
                      formData["POL"] || selectedSwitchHblData["POL"]
                    }
                    value={editedSwitchHblData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option
                      value={formData["POL"] || selectedSwitchHblData["POL"]}
                    >
                      {formData["POL"] || selectedSwitchHblData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">
                    HSN Code
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HSNCode"
                    defaultValue={
                      formData["HSNCode"] || selectedSwitchHblData["HSNCode"]
                    }
                    value={editedSwitchHblData["HSNCode"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your HSNCode"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">POD</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={
                      formData["POD"] || selectedSwitchHblData["POD"]
                    }
                    value={editedSwitchHblData["POD"] || ""}
                    onChange={handlePodChange}
                  >
                    placeholder="Enter your POD"
                    <option
                      value={formData["POD"] || selectedSwitchHblData["POD"]}
                    >
                      {formData["POD"] || selectedSwitchHblData["POD"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">Marks and Number</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MarksAndNumber"
                    defaultValue={
                      formData["MarksAndNumber"] ||
                      selectedSwitchHblData["MarksAndNumber"]
                    }
                    value={editedSwitchHblData["MarksAndNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the MarksAndNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Place of Delivery</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfDelivery"
                    defaultValue={
                      formData["PlaceOfDelivery"] ||
                      selectedSwitchHblData["PlaceOfDelivery"]
                    }
                    value={editedSwitchHblData["PlaceOfDelivery"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceofDelivery"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Type of Shipment</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="TypeOfShipment"
                    defaultValue={
                      formData["TypeOfShipment"] ||
                      selectedSwitchHblData["TypeOfShipment"]
                    }
                    value={editedSwitchHblData["TypeOfShipment"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Type Of Shipment</option>
                    <option value="FCL/FCL">FCL/FCL</option>
                    <option value="LCL/LCL">LCL/LCL</option>
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">Vessel and Voyage</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    defaultValue={
                      formData["VesselNameVoyage"] ||
                      selectedSwitchHblData["VesselNameVoyage"]
                    }
                    value={editedSwitchHblData["VesselNameVoyage"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your vesselAndVoyage"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">BL Type1</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BLType1"
                    defaultValue={
                      formData["BLType1"] ||
                      selectedSwitchHblData["BLType1"]
                    }
                    value={editedSwitchHblData["BLType1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your BLType1"
                  /> */}
                  <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="BLType1"
                    defaultValue={
                      formData["BLType1"] || selectedSwitchHblData["BLType1"]
                    }
                    value={editedSwitchHblData["BLType1"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select BLType1</option>
                    <option value="Draft Copy">Draft Copy</option>
                    <option value="Original">Original</option>
                    <option value="Non - Negotiable">Non - Negotiable</option>
                    <option value="Surrender">Surrender</option>
                    <option value="Seaway">Seaway</option>
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">BL Type2</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="BLType2"
                    defaultValue={
                      formData["BLType2"] ||
                      selectedSwitchHblData["BLType2"]
                    }
                    value={editedSwitchHblData["BLType2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your BLType2"
                  /> */}
                  <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="BLType2"
                    defaultValue={
                      formData["BLType2"] || selectedSwitchHblData["BLType2"]
                    }
                    value={editedSwitchHblData["BLType2"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select BLType2</option>
                    <option value="Draft Copy">Draft Copy</option>
                    <option value="Original">Original</option>
                    <option value="Non - Negotiable">Non - Negotiable</option>
                    <option value="Surrender">Surrender</option>
                    <option value="Seaway">Seaway</option>
                  </select>
                </div>
                <div className="input-box">
                  <span className="switchhbl">In Case Original1</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="number"
                    name="Originals1"
                    defaultValue={
                      formData["Originals1"] ||
                      selectedSwitchHblData["Originals1"]
                    }
                    value={editedSwitchHblData["Originals1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Originals1"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">In Case Original2</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="number"
                    name="Originals2"
                    defaultValue={
                      formData["Originals2"] ||
                      selectedSwitchHblData["Originals2"]
                    }
                    value={editedSwitchHblData["Originals2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Originals2"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Freight Terms 1</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightTerms1"
                    defaultValue={
                      formData["FreightTerms1"] ||
                      selectedSwitchHblData["FreightTerms1"]
                    }
                    value={editedSwitchHblData["FreightTerms1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightTerms1"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Freight Terms 2</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightTerms2"
                    defaultValue={
                      formData["FreightTerms2"] ||
                      selectedSwitchHblData["FreightTerms2"]
                    }
                    value={editedSwitchHblData["FreightTerms2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightTerms2"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">FreightPayableAt1</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightPayableAt1"
                    defaultValue={
                      formData["FreightPayableAt1"] ||
                      selectedSwitchHblData["FreightPayableAt1"]
                    }
                    value={editedSwitchHblData["FreightPayableAt1"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightPayableAt1"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">FreightPayableAt2</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightPayableAt2"
                    defaultValue={
                      formData["FreightPayableAt2"] ||
                      selectedSwitchHblData["FreightPayableAt2"]
                    }
                    value={editedSwitchHblData["FreightPayableAt2"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightPayableAt2"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Shipped OnBoard Date</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ShippedOnBoardDate"
                    defaultValue={
                      formData["ShippedOnBoardDate"] ||
                      selectedSwitchHblData["ShippedOnBoardDate"]
                    }
                    value={editedSwitchHblData["ShippedOnBoardDate"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ShippedOnBoardDate"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Date Of Issue</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="DateOfIssue"
                    defaultValue={
                      formData["DateOfIssue"] ||
                      selectedSwitchHblData["DateOfIssue"]
                    }
                    value={editedSwitchHblData["DateOfIssue"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the DateOfIssue"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Company Name</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CompanyName"
                    defaultValue={
                      formData["CompanyName"] ||
                      selectedSwitchHblData["CompanyName"]
                    }
                    value={editedSwitchHblData["CompanyName"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the CompanyName"
                  />
                </div>
                <div className="input-box">
                  <span className="switchhbl">Place Of Issue</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfIssue"
                    defaultValue={
                      formData["PlaceOfIssue"] ||
                      selectedSwitchHblData["PlaceOfIssue"]
                    }
                    value={editedSwitchHblData["PlaceOfIssue"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the PlaceOfIssue"
                  />
                </div>
                &nbsp;
                {/* Payment terms */}
                {/* <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                   
                      <tr>
                        <th
                          className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white"
                          rowSpan="3"
                        >
                          Payment terms
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                          Origin
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                          Freight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center flex-nowrap text-center bg-blue-950 text-white">
                          Destination
                        </th>
                      </tr>
                 
                      <tr>
                        <td>
                          <select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            name="Origin"
                            defaultValue={
                              formData["Origin"] || selectedSwitchHblData["Origin"]
                            }
                            value={editedSwitchHblData["Origin"] || ""}
                            onChange={handleChange}
                          >
                            <option value="Prepaid">Prepaid</option>
                            <option value="Collect">Collect</option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            name="Freight"
                            defaultValue={
                              formData["Freight"] || selectedSwitchHblData["Freight"]
                            }
                            value={editedSwitchHblData["Freight"] || ""}
                            onChange={handleChange}
                          >
                            <option value="Prepaid">Prepaid</option>
                            <option value="Collect">Collect</option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            name="Destination"
                            defaultValue={
                              formData["Destination"] ||
                              selectedSwitchHblData["Destination"]
                            }
                            value={editedSwitchHblData["Destination"] || ""}
                            onChange={handleChange}
                          >
                            <option value="Prepaid">Prepaid</option>
                            <option value="Collect">Collect</option>
                          </select>
                        </td>
                      </tr>
                   
                  </table>
                </div> */}
                <br />
                &nbsp;
                {/*Container table */}
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Type
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Seal Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Number of Packages
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Type Of Package
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Net Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Gross Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Volume
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedSwitchHblData?.Operations?.map(
                        (operation, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                name="ContainerType"
                                value={
                                  editedSwitchHblData["ContainerType"] ||
                                  operation.ContainerType
                                }
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "ContainerType",
                                    e.target.value
                                  )
                                }
                              >
                                {/* Render initial value */}
                                <option value={operation.ContainerType}>
                                  {operation.ContainerType}
                                </option>

                                {/* Render options from equipmentData */}
                                {equipmentData.map((item, idx) => (
                                  <option key={idx} value={item.ContainerType}>
                                    {item.ContainerType}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                name="ContainerNumber"
                                defaultValue={
                                  formData[operation.ContainerNumber] ||
                                  selectedSwitchHblData[
                                    operation.ContainerNumber
                                  ]
                                }
                                //value={editedSwitchHblData[operation.ContainerNumber] || ""}
                                value={operation.ContainerNumber}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "ContainerNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="SealNumber"
                                value={operation.SealNumber}
                                defaultValue={
                                  formData[operation.SealNumber] ||
                                  selectedSwitchHblData[operation.SealNumber]
                                }
                                //value={editedSwitchHblData[operation.SealNumber] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "SealNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="No_Of_Pkge"
                                value={operation.No_Of_Pkge}
                                defaultValue={
                                  formData[operation.No_Of_Pkge] ||
                                  selectedSwitchHblData[operation.No_Of_Pkge]
                                }
                                //value={editedSwitchHblData[operation.No_Of_Pkge] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "No_Of_Pkge",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                            {/*  <input
                                type="text"
                                name="Type_Of_Pkge"
                                value={operation.Type_Of_Pkge}
                                defaultValue={
                                  formData[operation.Type_Of_Pkge] ||
                                  selectedSwitchHblData[operation.Type_Of_Pkge]
                                }
                                //value={editedSwitchHblData[operation.Type_Of_Pkge] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "Type_Of_Pkge",
                                    e.target.value
                                  )
                                }
                              />*/}
                              <select
                              width="190px"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              name="Type_Of_Pkge"
                                value={operation.Type_Of_Pkge}
                                defaultValue={
                                  formData[operation.Type_Of_Pkge] ||
                                  selectedSwitchHblData[operation.Type_Of_Pkge]
                                }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.Type_Of_Pkge}>
                                {operation.Type_Of_Pkge}
                              </option>

                              {/* Render options from equipmentData */}
                              {packageTypeData.map((item, idx) => (
                                <option key={idx} value={item.packingType}>
                                  {item.packingType}
                                </option>
                              ))}
                            </select>

                            </td>
                            <td>
                              <input
                                type="text"
                                name="NtWeight"
                                value={operation.NtWeight}
                                defaultValue={
                                  formData[operation.NtWeight] ||
                                  selectedSwitchHblData[operation.NtWeight]
                                }
                                //value={editedSwitchHblData[operation.Type_Of_Pkge] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "NtWeight",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="GrWeight"
                                value={operation.GrWeight}
                                defaultValue={
                                  formData[operation.GrWeight] ||
                                  selectedSwitchHblData[operation.GrWeight]
                                }
                                //value={editedSwitchHblData[operation.GrWeight] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "GrWeight",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="Volume"
                                value={operation.Volume}
                                defaultValue={
                                  formData[operation.Volume] ||
                                  selectedSwitchHblData[operation.Volume]
                                }
                                //value={editedSwitchHblData[operation.GrWeight] || ""}
                                onChange={(e) =>
                                  handleOperationChange(
                                    index,
                                    "Volume",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <br />
                {/*description part */}
                <br />
                <br />
                <div className="description-box">
                  <div className="inline-flex">
                    <button
                      type="button"
                      onClick={() => setDescriptionType("Single")}
                      className={`${
                        descriptionType === "Single"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Single Description
                    </button>

                    <button
                      type="button"
                      onClick={() => setDescriptionType("Containerwise")}
                      className={`${
                        descriptionType === "Containerwise"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Containerwise Description
                    </button>
                    <br />
                  </div>
                </div>
                <div className="description-box">
                  {descriptionType === "Single" && (
                    <div className="input-box">
                      <span className="switchhbl">Description of goods</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        value={
                          editedSwitchHblData.Operations[0]
                            ?.descriptionOfGoods || ""
                        }
                        onChange={(e) =>
                          handleSingleDescription(e.target.value)
                        }
                        placeholder="Enter the description of goods"
                        rows="3" // Increased number of rows for height
                        cols="155" // Increased number of columns for width
                      />
                    </div>
                  )}
                  {descriptionType === "Containerwise" && (
                    <div className="input-box">
                      <span className="switchhbl">Description of goods</span>
                      <div className="table-container">
                        <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                          <thead className="flex-nowrap text-center bg-blue-950 text-white">
                            <tr>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Container Type
                              </th>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {editedSwitchHblData.Operations.map(
                              (operation, idx) => (
                                <tr key={idx}>
                                  <td>
                                    {operation.ContainerType}-
                                    {operation.ContainerNumber}
                                  </td>
                                  <td>
                                    <textarea
                                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                      name={`Description_${idx}`}
                                      value={operation.descriptionOfGoods || ""}
                                      onChange={(e) =>
                                        handleDescriptionChange(
                                          idx,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter the description of goods"
                                      rows="3" // You can adjust the number of rows for the height
                                      cols="125" // You can adjust the number of columns for the width
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSwitchHbl;
