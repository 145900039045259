import { BsThreeDotsVertical } from "react-icons/bs";
import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Header, Table, UnAuthorizedModal } from "../../../components";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../../hooks";
import { OperationDashboardAPIInstance } from "../../../api-instance";
import {
  SimpleGrid,
  useToast,
  Heading,
  Text,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Box,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
//import TotalCount from "./TotalCount";
import IncotermModule from "./dashboardIncoterm";
import RegionModule from "./dashboardRegionwise";
import OngoingModule from "./dashboardOngoing";
// import DashboardAllJobChart from "./dashboardAllJob.js";
import JobStatusModule from "./dashboardJobStatus";
import RegionIncotermModule from "./dashboardRegionIncoterm";
// import DashboardJobModule from "./dashboardAllJob";
import { useDisclosure } from "@chakra-ui/react";

// import Chart from "./chart";
import TotalCount from "./TotalCount";
import { useQuery } from "@tanstack/react-query";
import SideBard from "./sidebar";
import RecentTable from "./RecentTable";
import { FaListUl } from "react-icons/fa";
import { Radio, RadioGroup, Stack, Select } from "@chakra-ui/react";
import { CgClose } from "react-icons/cg";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { chartColors } from "../../../Utils/colors.js";
export default function IncotermWiseJobsAnalytics() {
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const toast = useToast();
  const [selected, setSelected] = useState("Jobs");
  const [showSidebar, setShowSidebar] = useState(true);
  const [showOngoingModule, setShowOngoingModule] = useState(false);
  const [showDashboardAllJobChart, setDashboardAllJobChart] = useState(false);
  const [showRegionModule, setShowRegionModule] = useState(false);
  const [showJobModule, setShowJobModule] = useState(false);
  const [showIncotermModule, setShowIncotermModule] = useState(false);
  const [showRegionIncotermModule, setShowRegionIncotermModule] =
    useState(false);
  const [showDashboardJobModule, setShowDashboardJobModule] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [selectedFields, setSelectedFields] = useState([""]);
  const [selectedChart, setSelectedChart] = useState("bar");
  const defaultData = {
    labels: [
      "Air Import",
      "Air Export",
      "Ocean Import",
      "Ocean Export",
      "Domestic",
      "Cross Trader ",
    ],
    datasets: [
      {
        label: "Ongoing Jobs",
        data: [12, 19, 3, 5, 2], // Example data
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  <FormControl>
    {selectedFields.map((item, index) => (
      <Flex key={index}>
        <select
          size={"lg"}
          onChange={(e) => {
            const updatedFields = [...selectedFields];
            updatedFields[index] = e.target.value;
            setSelectedFields(updatedFields);
          }}
          id={`fieldSelector${index}`}
          value={item}
        >
          <option>Select Field</option>
          <option value="LeadNumber">Lead Number</option>
          <option value="Ongoing wise Job">Ongoing wise job</option>
          <option value="Region wise Job">Region Wise Job </option>
          <option value="Job Wise Status">Job Wise Status</option>
          <option value="Incoterm Wise Job ">Incoterm wise job</option>
        </select>
        &nbsp;&nbsp;
        {/* <IoMdAddCircle
                      className="cursor-pointer"
                      size={25}
                      style={{ color: "green", marginTop: "5px" }}
                      onClick={() => handleAddField(item, index)}
                    />
                    {selectedFields.length > 1 && index > 0 && (
                      <BiSolidMinusCircle
                        className="cursor-pointer"
                        size={25}
                        style={{ color: "red", marginTop: "5px" }}
                        onClick={() => handleRemoveField(index)}
                      />
                    )} */}
      </Flex>
    ))}
  </FormControl>;
  const {
    isOpen: isOpenAlljobwise,
    onOpen: onOpenAlljobwise,
    onClose: onCloseAlljobwise,
  } = useDisclosure();

  const [components, setComponents] = useState([
    { name: "TOTALCOUNT", visible: true },
    { name: "RECENTS", visible: true },
    // add more components as needed
  ]);

  const [isLoading, setIsLoading] = useState(true);
  //const[sample,setSample]=use
  const [inProgressCount, setinProgressCount] = useState("");
  const [holdCount, setholdCount] = useState("");
  const [completedCount, setcompletedCount] = useState("");
  const [cancelledCount, setcancelledCount] = useState([]);

  // Mock data and functions for the example

  useEffect(() => {
    const getData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = JSON.parse(localStorage.getItem("token"));

        if (user && token) {
          const { data } = await OperationDashboardAPIInstance.get(
            `get_jobstatusdata?userId=${user.userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          );
          if (data?.data?.[0]) {
            setinProgressCount(data.data[0].Inprogress);
            setholdCount(data.data[0].Hold);
            setcompletedCount(data.data[0].Completed);
            setcancelledCount(data.data[0].Cancelled);
          }
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          // If 401 Unauthorized, show the Unauthorized Modal
          setIsUnauthorized(true);
        } else if (error?.response?.data?.message) {
          toast({
            title: error?.response?.data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else if (error?.response?.statusText) {
          toast({
            title: error?.response?.statusText,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
          toast({
            title: error?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };
    getData();
  }, []);

  console.log("cc", cancelledCount);
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const columnstyle = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    scrollbarWidth: "thin", // Set the width of the scrollbar to thin
    scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)",
  };
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //const inProgressCount = 10;
  // const holdCount = 20;
  //const completedCount = 15;
  //const cancelledCount=10;
  const [recentCustomers, setRecentCustomers] = useState([]);
  const handleOngoingCardClick = () => {
    setShowOngoingModule((prevState) => !prevState);
    setShowRegionModule(false);
    setShowIncotermModule(false);
    setShowJobModule(false);
    setShowRegionIncotermModule(false);
    setShowDashboardJobModule(false);
  };
  const handleDashboardAlljobstatusClick = () => {
    setShowOngoingModule((prevState) => !prevState);
    setShowRegionModule(false);
    setShowIncotermModule(false);
    setShowJobModule(false);
    setShowRegionIncotermModule(false);
    setShowDashboardJobModule(false);
  };
  const handleRegionCardClick = () => {
    setShowRegionModule((prevState) => !prevState);
    setShowIncotermModule(false);
    setShowOngoingModule(false);
    setShowJobModule(false);
    setShowRegionIncotermModule(false);
    setShowDashboardJobModule(false);
  };
  const handleJobCardClick = () => {
    setShowJobModule((prevState) => !prevState);
    setShowRegionModule(false);
    setShowIncotermModule(false);
    setShowOngoingModule(false);
    setShowRegionIncotermModule(false);
    setShowDashboardJobModule(false);
  };
  const handleIncotermCardClick = () => {
    setShowIncotermModule((prevState) => !prevState);
    setShowRegionModule(false);
    setShowOngoingModule(false);
    setShowJobModule(false);
    setShowRegionIncotermModule(false);
    setShowDashboardJobModule(false);
  };
  const handleRegionIncotermCardClick = () => {
    setShowRegionIncotermModule((prevState) => !prevState);
    setShowRegionModule(false);
    setShowOngoingModule(false);
    setShowJobModule(false);
    setShowIncotermModule(false);
    setShowDashboardJobModule(false);
  };

  useEffect(() => {
    const fetchRecentCustomers = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const { data } = await OperationDashboardAPIInstance.get(
          `get_recentjob?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        setRecentCustomers(data.data); // Update the state with the fetched data
      } catch (error) {
        if (error?.response?.status === 401) {
          // If 401 Unauthorized, show the Unauthorized Modal
          setIsUnauthorized(true);
        } else if (error?.response?.data?.message) {
          toast({
            title: error?.response?.data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else if (error?.response?.statusText) {
          toast({
            title: error?.response?.statusText,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
          toast({
            title: error?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };

    fetchRecentCustomers();
  }, []);
  const hideChild = (name) => {
    setComponents((prev) =>
      prev.map((component) =>
        component.name === name ? { ...component, visible: false } : component
      )
    );
  };
  const handleChartChange = (chartType) => {
    setSelectedChart(chartType);
  };
  return (
    <>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="w-auto h-auto flex flex-col font-roboto">
            <div className="flex-1 flex overflow-hidden">
              {showSidebar && <SideBard {...{ selected, setSelected }} />}
              <div className="relative  flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto">
                <div>
                  <div className="flex flex-wrap mb-4 gap">
                    {components.map((component) => {
                      if (component.visible) {
                        if (component.name === "TOTALCOUNT") {
                          return (
                            <div
                              className="mb-4 flex-initial w-full"
                              key={`component.name`}
                            >
                              <TotalCount
                                // totalConverted={totalConverted}
                                inProgressCount={inProgressCount}
                                holdCount={holdCount}
                                completedCount={completedCount}
                                cancelledCount={cancelledCount}
                                selected={selected}
                              />
                            </div>
                          );
                        } else if (component.name === "RECENTS") {
                          return (
                            <div
                              key={component.name}
                              className="mb-4 flex-initial w-full"
                            >
                              <RecentTable
                                data={recentCustomers || []}
                                hideComponent={() => hideChild(component.name)}
                                selectedScreen={selected}
                              />
                            </div>
                          );
                        }
                      }
                      return null;
                    })}
                    <div className="mb-4 flex-initial w-full">
                      <div className="grid grid-cols-1 grid-cols-2 md:grid-cols-3  gap-6">
                        <div className="bg-white rounded-3xl p-4 overflow-hidden shadow-lg">
                          {/* <div className="bg-gradient-to-r from-[#D1C6FF] to-[#A2E1E1] p-4 rounded-3xl"> */}
                          <Box
                            as="h2"
                            fontSize="text-medium"
                            fontWeight="bold"
                            mb={4}
                            color="black"
                          >
                            Ongoing-Wise Job
                          </Box>
                          <OngoingModule />
                          {/* </div> */}
                        </div>

                        <div className="bg-white rounded-2xl p-4 shadow-lg">
                          {/* <div className="bg-gradient-to-r from-[#D1C6FF] to-[#A2E1E1] p-4 rounded-3xl"> */}
                          <Box
                            as="h2"
                            fontSize="text-medium"
                            fontWeight="bold"
                            mb={4}
                            color="black"
                          >
                            Region-Wise Job
                          </Box>
                          <RegionModule />
                          {/* </div> */}
                        </div>
                        <div className="bg-white rounded-2xl p-4 shadow-lg">
                          <Box
                            as="h2"
                            fontSize="text-medium"
                            fontWeight="bold"
                            mb={4}
                            color="black"
                          >
                            Job-Wise Status
                          </Box>
                          {/* <div className="bg-gradient-to-r from-[#D1C6FF] to-[#A2E1E1] p-4 rounded-3xl"> */}
                          {/* <p>Content for the first column</p> */}
                          <JobStatusModule />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 flex-initial w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
                        <div className="bg-white rounded-2xl p-4 shadow-lg">
                          {/* <div className="bg-gradient-to-r from-[#D1C6FF] to-[#A2E1E1] p-4 rounded-4xl"> */}
                          <Box
                            as="h2"
                            fontSize="text-medium"
                            fontWeight="bold"
                            mb={4}
                            color="black"
                          >
                            Incoterm-Wise Status
                          </Box>
                          <IncotermModule />
                          {/* </div> */}
                        </div>

                        <div className="bg-white rounded-2xl p-4 shadow-lg">
                          {/* <div className="bg-gradient-to-r from-[#D1C6FF] to-[#A2E1E1] p-4 rounded-4xl"> */}
                          {/* <p>Content for the first column</p> */}
                          <Box
                            as="h2"
                            fontSize="text-medium"
                            fontWeight="bold"
                            mb={4}
                            color="black"
                          >
                            Region Vs Incoterm Jobs
                          </Box>
                          <RegionIncotermModule />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
    </>
  );
}
