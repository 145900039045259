import React from "react";
import {
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import { Header } from "../../components";
const Createjob = () => {
  return (
    <>
      <Header />
      <form>
        <div
          className="top-bar bg-blue-950  text-white  dark:bg-indigo-900"
          style={{
            position: "sticky",
            top: "0",
            zIndex: "100",
          }}
        >
          <p className="float-left py-1 px-2 rounded my-2">Create Jobs</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            // onClick={(e) => {
            //   handleSubmit(e);
            // }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            // onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            // onClick={() => {
            //   navigate("/lead");
            // }}
            // disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Job Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-job">
                <div className="input-box">
                  <span className="job">
                    Job Number<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="JobNumber"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your job number"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    POL<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Pol"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your pol"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Product<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Product"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your product"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Pod<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Pod"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your pod"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Shipment Handled By<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ShipmentHandledBy"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your shipment handled by"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Shipper<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Shipper"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your shipper"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Job Status<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="JobStatus"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your job status"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Consignee<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Consignee"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your consignee"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Date Of Business Confirmed
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DateOfBusinessConfirmed"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your date of business confirmed"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Client<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Client"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your client"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Agent<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Agent"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your agent"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Scope<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Scope"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your scope"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Quote Number<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="QuoteNumber"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your quote number"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Region<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Region"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your region"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Sales<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Sales"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your sales"
                  />
                </div>

                <div className="input-box">
                  <span className="job">
                    Incoterm<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Incoterm"
                    // value={formData.Company}
                    // onChange={handleChange}
                    placeholder="Enter your incoterm"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Start Cargo details */}
          <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
            <div className="title">Cargo Details</div>
            <br />
            <br />
            <div className="content">
              <div className="form-user">
                <div className="user-job">
                  <div className="input-box">
                    <span className="job">
                      Cargo<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Cargo"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your cargo"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      HS Code<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="HsCode"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your hs code"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Cargo Nature<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CargoNature"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your cargo nature"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End Cargo */}
            {/* Table Conatiner */}

            <div className="table-container">
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Conatiner Type
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      QTY
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Weight Per container(KG)
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Dimension(LXWXH IN M)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input type="text" value="40 HC" />
                    </td>
                    <td>
                      <Input type="text" value="2" />
                    </td>
                    <td>
                      <Input type="text" value="20000" />{" "}
                    </td>
                    <td>
                      <Input type="text" value="Inguage" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input type="text" value="40 FR" />
                    </td>
                    <td>
                      <Input type="text" value="1" />
                    </td>
                    <td>
                      <Input type="text" value="25000" />
                    </td>
                    <td>
                      <Input type="text" value="9 X 2.5 X 3" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            {/* Liner Details */}
            <div className="title">Liner Details</div>
            <br />
            <br />
            <div className="content">
              <div className="form-user">
                <div className="user-job">
                  <div className="input-box">
                    <span className="job">
                      Shipping Line / NVOCC / LCL
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="ShippingLine"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your shipping line"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      SI Cutt Off<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="SICutOff"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your si cut off"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Vessel Name & Voy<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="VesselNameVoy"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your vessel name"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      VGM Cut Off<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="VGMCutOff"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your vgm cutt off"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      CY Open<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CyOpen"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your cy open"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Customs Cut Off<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CustomsCuttOff"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your customs cutt off"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      CY Cut Off<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CYCutOff"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your cy cut off"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Freight<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Freight"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your freight"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      ETD<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="ETD"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your ETD"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      ETA<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="ETA"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your ETA"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Free Days At Origin<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="FreeDaysAtOrigin"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your free days at origin"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Free Days At Destination
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="FreeDaysAtDestination"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your free days at destination"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      MBL Type<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="MBLType"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your MBL type"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      HBL Type<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="HBLType"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your HBL type"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Operation Details */}
            <div className="title">Opertion Details</div>
            <br />
            <br />
            <div className="content">
              <div className="form-user">
                <div className="user-job">
                  <div className="input-box">
                    <span className="job">
                      Liner Booking Number
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="LinerBookingNumber"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your liner booking number"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      MBL Number<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="MBLNumber"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your MBL number"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Cargo PickUp Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CargoPickUpDate"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your cargo pick up date"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Stuffing Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Stuffingdate"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your stuffing date"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Empty PickUp date<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="EmptyPickUpDate"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your empty pickup date"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Laden Gate In Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="LadenGateIndate"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your laden gate in date"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Vessel Arrival Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="VesselArrivalDate"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your vessel arrival date"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Opertion Tranfer Deadline
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="OperationTranferDeadline"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your operation transfer deadline"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End Operation */}
            {/* Table Conatiner */}
            <div className="table-container">
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Conatiner Type
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Container Number
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Seal Number
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Number of Packages
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Type Of Package
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Net Weight
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Gross Weight
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Volume
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Tare Weight
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      VGM Weight
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      VGM Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>40 HC</td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your container number"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your seal number"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your number of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your type of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your net weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your gross weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your volume"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your tare weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm type"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>40 HC</td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your container number"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your seal number"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your number of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your type of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your net weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your gross weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your volume"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your tare weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm type"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>40 FR</td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your container number"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your seal number"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your number of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="270px"
                        type="text"
                        placeholder="Enter your type of packages"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your net weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your gross weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your volume"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your tare weight"
                      />
                    </td>
                    <td>
                      <Input
                        w="200px"
                        type="text"
                        placeholder="Enter your vgm type"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />

            {/* Additional Charges */}
            <div className="title">Additional Charges</div>
            <br />
            <br />
            <div className="table-container">
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Vendor
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Additional Charges Details
                    </th>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <Input type="text" placeholder="Enter your vendor" />
                    </td>
                    <td>
                      {" "}
                      <Input type="text" placeholder="Enter your charges" />
                    </td>
                    <td>
                      {" "}
                      <Input type="text" placeholder="Enter your amount" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            {/* Detention and Demurrage */}
            <div className="title">Detention & Demurrage</div>
            <br />
            <br />
            <div className="content">
              <div className="form-user">
                <div className="user-job">
                  <div className="input-box">
                    <span className="job">
                      Demurrage<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Demurrage"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your demurrage"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Detention<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Detention"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your detention"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Storage<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Storage"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your storage"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Demurrage By Port<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="DemurrageByPort"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your demurrage by port"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Detention By Liner<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="DetentionByLiner"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your detention by liner"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Port Storage<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="PortStorage"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your port storage"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      No Of Days Demurrage
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="NoOfDaysDemurrage"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your no of days demurrage"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      No Of Days Detention
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="NoOfDaysDetention"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your no of days detention"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      No Of Days Port<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="NoOfDaysPort"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your no of days port"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Demurrage Occuring<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="DemurrageOccuring"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your demurrage occuring"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Detention Occuring<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="DetentionOccuring"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your detention occuring"
                    />
                  </div>

                  <div className="input-box">
                    <span className="job">
                      Storage Occuring<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="StorageOccuring"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your storage occuring"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End De & D */}

            {/* Current Status */}

            {/* <div className="content">
              <div className="form-user">
                <div className="user-job">
                  <div className="input-box">
                    <span className="job">
                      Status<span style={{ color: "red" }}>*</span>
                    </span>
                    <input
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="CurrentStatus"
                      // value={formData.Company}
                      // onChange={handleChange}
                      placeholder="Enter your current staus"
                    />
                  </div>
  
                </div>
              </div>
            </div> */}
            <div className="table-container">
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Current Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        type="text"
                        marginLeft="40%"
                        w="250px"
                        placeholder="Enter your current status"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Notes */}
            <div className="table-container">
              <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                <thead className="flex-nowrap text-center bg-blue-950 text-white">
                  <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-center">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        type="text"
                        marginLeft="40%"
                        w="200px"
                        placeholder="Enter your notes"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Createjob;
