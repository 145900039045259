export class Checkvalidation {
    constructor(
      checkBoxSelected,
      toast,
      handlePrint,
      handleConvert,
      onOpenMassupdate,
      onOpenReportDelete,
      // handleOpenEditor,
      // onOpenReportOutlookEmail,
    ) {
      this.checkBoxSelected = checkBoxSelected;
      this.toast = toast;
      this.handlePrint = handlePrint;
      this.handleConvert=handleConvert;
      this.onOpenMassupdate = onOpenMassupdate;
      this.onOpenReportDelete = onOpenReportDelete;
      // this.handleOpenEditor = handleOpenEditor;
      // this.onOpenReportOutlookEmail = onOpenReportOutlookEmail;
    }
  
    handleEditClickMassupdate() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for deletion.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      // If checkBoxSelected has at least one item selected continue with
      this.onOpenMassupdate();
    }
  
    handleEditClickMassdelete() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for deletion.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      this.onOpenReportDelete();
    }
  
    handlePrintClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handlePrint();
    } 
  
    // handleEditClickMassmail() {
    //   if (this.checkBoxSelected.length === 0) {
    //     console.log("No row selected for deletion.");
    //     this.toast({
    //       title: "Please select a row",
    //       position: "bottom-center",
    //       isClosable: true,
    //       status: "error",
    //       containerStyle: {
    //         width: "98.5vw",
    //         maxWidth: "98.5vw",
    //         alignContent: "center",
    //       },
    //     });
    //     return;
    //   }
    //   // If checkBoxSelected is true, you can continue with the  action.
    //   this.handleOpenEditor();
    // }
  
    // handleEditClickOutlookMail() {
    //   if (this.checkBoxSelected.length === 0) {
    //     console.log("No row selected for deletion.");
    //     this.toast({
    //       title: "Please select a row",
    //       position: "bottom-center",
    //       isClosable: true,
    //       status: "error",
    //       containerStyle: {
    //         width: "98.5vw",
    //         maxWidth: "98.5vw",
    //         alignContent: "center",
    //       },
    //     });
    //     return;
    //   }
    //   // If checkBoxSelected is true, you can continue with the  action.
    //   this.onOpenReportOutlookEmail();
    // }
  
    handleConvertClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleConvert();
    } 
   
  }
  