import React from "react"; // Import React
import { useState, useEffect } from "react"; // Import necessary hooks
import { Header } from "../../components"; // Import a Header component
import axios from "axios"; // Import Axios for making HTTP requests
import { v4 as uuidv4 } from "uuid"; // Import the uuidv4 function for generating unique IDs
import {MasterDataAPIInstance} from "../../api-instance"; // Import Axios with a custom configuration
import { useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI for displaying notifications
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router for navigation

import "./AddOperationuserRegion.css"; // Import CSS styles

const AddOperationuserRegion = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [userIdData, setuserIdData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  
  const toast = useToast(); // Initialize the useToast hook for notifications
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation

  const [formData, setFormData] = useState({
    // Initialize a state variable with form data
    userId: JSON.parse(localStorage.getItem("user")).userId,
    regionId: "",
    user_region: "",
    regionCode: "",
    user_state: "",
    user_city: "",
    Role:"",
    userId:"",

    // PiCode: "",
  });

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(formData, "form"); // Log the form data to the console
    const { data } = await MasterDataAPIInstance.post(
      // Send a POST request using Axios
      `addMasterData/add_operation_user_region?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData, // Include form data in the request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
        },
      }
    );

    console.log("===================================="); // Log a separator line
    console.log(data); // Log the response data
    console.log("===================================="); // Log another separator line

    if (data.success) {
      // Check if the response indicates success
      toast({
        title: "Operation user Region created successfully!", // Show a success toast notification
        position: "bottom-right",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/operationuserregion"); // Navigate to the "/numrange" route
    } else {
      toast({
        title: data.message, // Show an error toast notification
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Function to handle input changes
  const handleChange = async (e) => {
    const { name, value } = e.target; // Get the name and value from the input element

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value, // Update the form data with the changed input value
      };
    });
  };
//Role Api 
useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("getmasterdata/get_role");
      setRoleData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);

//UserId
useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("getmasterdata/get_user");
      setuserIdData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);

//City

useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("getmasterdata/get_city");
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);

//State

useEffect(() => {
  const getData = async () => {
    try {
      const { data } = await MasterDataAPIInstance.get("getmasterdata/get_state");
      setStateData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  getData();
}, []);

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await MasterDataAPIInstance.post(
        `addMasterData/add_operation_user_region?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: "Operation user Region created successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header /> {/* Render a Header component */}
      <form>
        {/* Create a form element with a submission handler */}
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          {/* Create a top bar with buttons */}
          <p className="float-left py-1 px-2 rounded my-2">Create Operation User Region</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmitNext(e);
            }}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            onClick={() => {
              navigate("/operationuserregion");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>
        <div className="container-from-data bg-white dark-bg-slate-950 text-black dark-text-white">
          {/* Create a container for form data */}
          <div className="title">Operation User Region Information</div> {/* Add a title */}
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-operation">
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>User_region</span>{" "}
                  {/* Label for numrange input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="user_region"
                    value={formData.user_region}
                    onChange={handleChange}
                    placeholder="Enter the user_region"
                  />{" "}
                  {/* Input field for numrange */}
                </div>
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>RegionCode</span>{" "}
                  {/* Label for Object input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="regionCode"
                    value={formData.regionCode}
                    onChange={handleChange}
                    placeholder="Enter the regioncode"
                  />{" "}
                  {/* Input field for Object */}
                </div>
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>User_state</span>{" "}
                  {/* Label for SubObject input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="user_state"
                    value={formData.user_state}
                    onChange={(e) => {
                      handleChange(e);
                     
                    }}
                  >
                    <option>Select user_state</option>
                    {stateData.map((item) => (
                      <option value={item.State_name}>
                        {item.State_name}
                      </option>
                    ))}
                  </select>
                  {/* Input field for SubObject */}
                </div>
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>User_city</span>{" "}
                  {/* Label for ValidForm input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="user_city"
                    value={formData.user_city}
                    onChange={(e) => {
                      handleChange(e);
                      
                    }}
                  >
                    <option>Select User_city</option>
                    {cityData.map((item) => (
                      <option value={item.City_name}>
                        {item.City_name}
                      </option>
                    ))}
                  </select>
                  {/* Input field for ValidForm */}
                </div>
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>Role</span>{" "}
                  {/* Label for ValidForm input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Role"
                    value={formData.Role}
                    onChange={(e) => {
                      handleChange(e);
                     
                    }}
                  >
                    <option>Select Role</option>
                    {roleData.map((item) => (
                      <option value={item.Role}>
                        {item.Role}
                      </option>
                    ))}
                  </select>
                  {/* Input field for ValidForm */}
                </div>
                <div className="input-box">
                  <span className="operation"style={{ textAlign: 'left', display: 'block' }}>User_Id</span>{" "}
                  {/* Label for ValidForm input */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="userId"
                    value={formData.userId}
                    onChange={(e) => {
                      handleChange(e);
                      
                    }}
                  >
                    <option>Select userId</option>
                    {userIdData.map((item) => (
                      <option value={item.userId}>
                        {item.userId}
                      </option>
                    ))}
                  </select>
                  {/* Input field for ValidForm */}
                </div>
                <div className="input-box">
                  {/* <span className="operation"style={{ textAlign: 'left', display: 'block' }}>PiCode</span>{" "} */}
                  {/* Label for Prefix input */}
                  {/* <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="PiCode"
                    value={formData.PiCode}
                    onChange={handleChange}
                    placeholder="Enter the PiCode"
                  />{" "} */}
                  {/* Input field for Prefix */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddOperationuserRegion;
