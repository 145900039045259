import { useState, useEffect } from "react";
import { OperationDocumentAPIInstance } from "../../api-instance";
import { useToast, Button, Stack, Select, Badge } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AddChecklist.css";
import {  Header, OperationSidebar } from "../../components";

const AddChecklist = () => {
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("Checklist selectedJob:", selectedJob);
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [checklistItems, setChecklistItems] = useState([]);
  const handleEditClick = (index) => {
    setEditIndex(index); // Set the index of the row being edited
  };
  const getData = async () => {
    try {
      const { data } = await OperationDocumentAPIInstance.get(
        `get_checklist?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&JobNumber=${selectedJob?.JobNumber}&BookingNumber=${selectedJob.BookingNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      setChecklistItems(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (index, value) => {
    const updatedChecklistItems = [...checklistItems];
    updatedChecklistItems[index].Status = value;
    setChecklistItems(updatedChecklistItems);
  };

  const handleRowUpdateData = async (index) => {
    try {
      const rowData = checklistItems[index];
      const { data } = await OperationDocumentAPIInstance.put(
        `update_checklist?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        { rowData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.success) {
        toast({
          title: "Checklist Updated  Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setEditIndex(null);
      console.log("Row Data:", rowData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Header */}
    
        {/* <OperationHeader size={20} style={{ cursor: "pointer" }} /> */}
        <Header />
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          <p className="float-left"></p>
          <OperationSidebar
            size={20}
            style={{ cursor: "pointer" }}
            selectedJob={selectedJob}
          />
          <p className="float-left py-1 px-2 roundedmy-2">Check List</p>
        </div>
     
      <br />
      <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
        <a>Title :</a>
        {selectedJob?.JobNumber}/ {selectedJob?.BookingNumber}
      </Badge>
      {/* Checklist items */}
      <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
        <thead className="flex-nowrap">
          <tr>
            <th
              width="500px"
              className="border border-slate-300 text-left   whitespace-nowrap"
            >
              Name
            </th>
            <th
              width="500px"
              className="border border-slate-300 text-left   whitespace-nowrap"
            >
              Status
            </th>
            <th
              width="500px"
              className="border border-slate-300 text-left   whitespace-nowrap"
            >
              CreateBy
            </th>
            <th
              width="500px"
              className="border border-slate-300 text-left   whitespace-nowrap"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {checklistItems.length > 0 &&
            checklistItems.map((item, index) => (
              <tr key={index}>
                <td className="flex-nowrap text-left">{item.Documents}</td>
                <td>
                  {/* <Select
                    width="90px"
                    value={item.Status}
                    onChange={(e) => handleChange(index, e.target.value)}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </Select> */}
                  <Select
                width="90px"
                value={item.Status}
                onChange={(e) => handleChange(index, e.target.value)}
                isDisabled={editIndex !== index} // Disable if not in edit mode
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </Select>
                </td>
                <td className="flex-nowrap text-left">{item.CreateBy}</td>
               
                <td>
                  <Stack spacing={4} direction="row" align="center">
                  <Button
                      colorScheme="teal"
                      size="xs"
                      onClick={() => handleEditClick(index)}
                    >
                      Edit
                    </Button>
                    
                    <Button
                      colorScheme="teal"
                      size="xs"
                      onClick={() => handleRowUpdateData(index)}
                    >
                      Save
                    </Button>
                    
                  </Stack>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default AddChecklist;

// import { React, useState, useEffect } from "react";
// import Axios, { OperationDocumentAPIInstance } from "../../api-instance";
// import { useToast, Button, Stack, Select, Badge } from "@chakra-ui/react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./AddChecklist.css";
// import { OperationHeader, OperationSidebar } from "../../components";

// const AddChecklist = () => {
//   const location = useLocation();
//   const { selectedJob } = location.state || {};
//   console.log("Checklist selectedJob:", selectedJob);
//   const [submitAllowed, setSubmitAllowed] = useState(true);
//   const toast = useToast();
//   const navigate = useNavigate();
//   const initialChecklistItems = [
//     { roll: 1, Documents: "SI SUBMISSION", Status: "" },
//     { roll: 2, Documents: "VGM SUBMISSION", Status: "" },
//     { roll: 3, Documents: "CY CUT OFF GATE IN STATUS", Status: "" },
//     { roll: 4, Documents: "EXPORT DECLARATION SUBMISSION", Status: "" },
//     { roll: 5, Documents: "OOG DEC SUBMISSION", Status: "" },
//   ];

//   const [checklistItems, setChecklistItems] = useState(initialChecklistItems);

//   const handleChange = (index, status) => {
//     if (index < checklistItems.length) {
//       const updatedItems = [...checklistItems];
//       updatedItems[index].Status = status;
//       setChecklistItems(updatedItems);
//     }
//   };

//   const handleChangeInitial = (index, status) => {
//     if (index < initialChecklistItems.length) {
//       const updatedItems = [...initialChecklistItems];
//       updatedItems[index].Status = status;
//       setChecklistItems(updatedItems);
//     }
//   };

//   const getData = async () => {
//     try {
//       const { data } = await OperationDocumentAPIInstance.get(
//         `get_checklist?userId=${
//           JSON.parse(localStorage.getItem("user")).userId
//         }&BookingNumber=${selectedJob.BookingNumber}&JobNumber=${
//           selectedJob.JobNumber
//         }`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: JSON.parse(localStorage.getItem("token")),
//           },
//         }
//       );
//       setChecklistItems(data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   console.log(checklistItems);
//   useEffect(() => {
//     getData();
//     // handleSubmit();
//   }, []);
//   console.log(selectedJob);

//   const handleSubmit = async () => {
//     try {
//       const requestData = checklistItems.map((item) => ({
//         name: item.Documents,
//         status: item.Status,
//         JobNumber: selectedJob.JobNumber,
//         JobInfono: selectedJob.JobInfono,
//         BookingNumber: selectedJob.BookingNumber,
//         JobOwner: selectedJob.JobOwner,
//       }));

//       const { data } = await OperationDocumentAPIInstance.post(
//         `/documents/add_checklist?userId=${
//           JSON.parse(localStorage.getItem("user")).userId
//         }`,
//         requestData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: JSON.parse(localStorage.getItem("token")),
//           },
//         }
//       );
//       if (data?.success) {
//         toast({
//           title: "Checklist added  Successfully!",
//           position: "bottom-center",
//           isClosable: true,
//           status: "success",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//       console.log(requestData);
//     } catch (error) {
//       console.log(error);
//       if (error?.response?.data?.message) {
//         toast({
//           title: error?.response?.data?.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       } else if (error?.response?.statusText) {
//         toast({
//           title: error?.response?.statusText,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       } else {
//         toast({
//           title: error?.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     }
//   };

// const handleRowUpdateData = async (index) => {
//   try {
//     const rowData = checklistItems[index];
//     const { data } = await OperationDocumentAPIInstance.put(
//       `/documents/update_checklist?userId=${
//         JSON.parse(localStorage.getItem("user")).userId
//       }`,
//       { rowData },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: JSON.parse(localStorage.getItem("token")),
//         },
//       }
//     );
//     if (data?.success) {
//       toast({
//         title: "Checklist Updated  Successfully!",
//         position: "bottom-center",
//         isClosable: true,
//         status: "success",
//         containerStyle: {
//           width: "98.5vw",
//           maxWidth: "98.5vw",
//           alignContent: "center",
//         },
//       });
//     }
//     console.log("Row Data:", rowData);
//   } catch (error) {
//     console.log(error);
//   }
// };

//   return (
//     <>
//       {/* Header */}
//       <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
//         <OperationHeader size={20} style={{ cursor: "pointer" }} />
//         <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
//           <p className="float-left"></p>
//           <OperationSidebar
//             size={20}
//             style={{ cursor: "pointer" }}
//             selectedJob={selectedJob}
//           />
//           <p className="float-left py-1 px-2 roundedmy-2">Create Check List</p>

//           <button
//             className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark:bg-blue-950"
//             onClick={handleSubmit}
//           >
//             Save
//           </button>
//         </div>
//       </div>
//       <br />
//       <br />
//       <br />
//       <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
//         <a>Title :</a>
//         {selectedJob?.JobNumber}/ {selectedJob?.BookingNumber}
//       </Badge>
//       {/* Checklist items */}
//       <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
//         <thead className="flex-nowrap">
//           <tr>
//             {/* <th                className="border border-slate-300             whitespace-nowrap">S.No</th> */}
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Name
//             </th>
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Status
//             </th>
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Action
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {checklistItems.length > 0
//             ? checklistItems.map((item, index) => (
//                 <tr key={index}>
//                   {/* <td>{item.roll}</td> */}
//                   <td className="flex-nowrap text-left">{item.Documents}</td>
//                   <td>
//                     <Select
//                       width="90px"
//                       value={item.Status}
//                       onChange={(e) => handleChange(index, e.target.value)}
//                     >
//                       <option value="0">No</option>
//                       <option value="1">Yes</option>
//                     </Select>
//                   </td>
//                   <td>
//                     <Stack spacing={4} direction="row" align="center">
//                       {checklistItems.length > 4 && (
//                         <Button
//                           colorScheme="teal"
//                           size="xs"
//                           onClick={() => handleRowUpdateData(index)}
//                         >
//                           Update
//                         </Button>
//                       )}
//                     </Stack>
//                   </td>
//                 </tr>
//               ))
//             : initialChecklistItems.map((item, index) => (
//                 <tr key={index}>
//                   {/* <td>{item.roll}</td> */}
//                   <td className="flex-nowrap text-left">{item.Documents}</td>
//                   <td>
//                     <Select
//                       width="90px"
//                       value={item.Status}
//                       onChange={(e) =>
//                         handleChangeInitial(index, e.target.value)
//                       }
//                     >
//                       <option value="0">No</option>
//                       <option value="1">Yes</option>
//                     </Select>
//                   </td>
//                   <td>
//                     <Stack spacing={4} direction="row" align="center">
//                       <Button isDisabled colorScheme="teal" size="xs">
//                         Update
//                       </Button>
//                     </Stack>
//                   </td>
//                 </tr>
//               ))}
//         </tbody>
//       </table>
//     </>
//   );
// };

// export default AddChecklist;

// import { React, useState, useEffect } from "react";
// import Axios from "../../api-instance";
// import { useToast, Button, Stack, Select, Badge } from "@chakra-ui/react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "./AddChecklist.css";
// import { OperationHeader, OperationSidebar } from "../../components";

// const AddChecklist = () => {
//   const location = useLocation();
//   const { selectedJob } = location.state || {};
//   console.log("Checklist selectedJob:", selectedJob);
//   const [submitAllowed, setSubmitAllowed] = useState(true);
//   const toast = useToast();
//   const navigate = useNavigate();
//   const initialChecklistItems = [
//     { roll: 1, Documents: "SI SUBMISSION", Status: "" },
//     { roll: 2, Documents: "VGM SUBMISSION", Status: "" },
//     { roll: 3, Documents: "CY CUT OFF GATE IN STATUS", Status: "" },
//     { roll: 4, Documents: "EXPORT DECLARATION SUBMISSION", Status: "" },
//     { roll: 5, Documents: "OOG DEC SUBMISSION", Status: "" },
//   ];

//   const [checklistItems, setChecklistItems] = useState(initialChecklistItems);

//   const handleChange = (index, status) => {
//     if (index < checklistItems.length) {
//       const updatedItems = [...checklistItems];
//       updatedItems[index].Status = status;
//       setChecklistItems(updatedItems);
//     }
//   };

//   const handleChangeInitial = (index, status) => {
//     if (index < initialChecklistItems.length) {
//       const updatedItems = [...initialChecklistItems];
//       updatedItems[index].Status = status;
//       setChecklistItems(updatedItems);
//     }
//   };

//   const getData = async () => {
//     try {
//       const { data } = await Axios.get(
//         `/documents/get_checklist?userId=${
//           JSON.parse(localStorage.getItem("user")).userId
//         }&BookingNumber=${selectedJob.BookingNumber}&JobNumber=${
//           selectedJob.JobNumber
//         }`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: JSON.parse(localStorage.getItem("token")),
//           },
//         }
//       );
//       setChecklistItems(data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   console.log(checklistItems);
//   useEffect(() => {
//     getData();
//     handleSubmit();
//   }, []);
//   console.log(selectedJob);

//   const handleSubmit = async () => {
//     try {
//       const requestData = checklistItems.map((item) => ({
//         name: item.Documents,
//         status: item.Status,
//         JobNumber: selectedJob.JobNumber,
//         JobInfono: selectedJob.JobInfono,
//         BookingNumber: selectedJob.BookingNumber,
//         JobOwner: selectedJob.JobOwner,
//       }));

//       const { data } = await Axios.post(
//         `/documents/add_checklist?userId=${
//           JSON.parse(localStorage.getItem("user")).userId
//         }`,
//         requestData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: JSON.parse(localStorage.getItem("token")),
//           },
//         }
//       );
//       if (data?.success) {
//         toast({
//           title: "Checklist added  Successfully!",
//           position: "bottom-center",
//           isClosable: true,
//           status: "success",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//       console.log(requestData);
//     } catch (error) {
//       console.log(error);
//       if (error?.response?.data?.message) {
//         toast({
//           title: error?.response?.data?.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       } else if (error?.response?.statusText) {
//         toast({
//           title: error?.response?.statusText,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       } else {
//         toast({
//           title: error?.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     }
//   };

//   const handleRowUpdateData = async (index) => {
//     try {
//       const rowData = checklistItems[index];
//       const { data } = await Axios.put(
//         `/documents/update_checklist?userId=${
//           JSON.parse(localStorage.getItem("user")).userId
//         }`,
//         { rowData },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: JSON.parse(localStorage.getItem("token")),
//           },
//         }
//       );
//       if (data?.success) {
//         toast({
//           title: "Checklist Updated  Successfully!",
//           position: "bottom-center",
//           isClosable: true,
//           status: "success",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//       console.log("Row Data:", rowData);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <>
//       {/* Header */}
//       <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
//         <OperationHeader size={20} style={{ cursor: "pointer" }} />
//         <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
//           <p className="float-left"></p>
//           <OperationSidebar
//             size={20}
//             style={{ cursor: "pointer" }}
//             selectedJob={selectedJob}
//           />
//           <p className="float-left py-1 px-2 roundedmy-2">Create Check List</p>

//           <button
//             className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark:bg-blue-950"
//             onClick={handleSubmit}
//           >
//             Save
//           </button>
//         </div>
//       </div>
//       <br />
//       <br />
//       <br />
//       <Badge variant="solid" float="left" colorScheme="red" fontSize="12pt">
//         <a>Title :</a>
//         {selectedJob?.JobNumber}/ {selectedJob?.BookingNumber}
//       </Badge>
//       {/* Checklist items */}
//       <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
//         <thead className="flex-nowrap">
//           <tr>
//             {/* <th                className="border border-slate-300             whitespace-nowrap">S.No</th> */}
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Name
//             </th>
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Status
//             </th>
//             <th
//               width="500px"
//               className="border border-slate-300 text-left   whitespace-nowrap"
//             >
//               Action
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {checklistItems.length > 0 &&
//             checklistItems.map((item, index) => (
//               <tr key={index}>
//                 <td className="flex-nowrap text-left">{item.Documents}</td>
//                 <td>
//                   <Select
//                     width="90px"
//                     value={item.Status}
//                     onChange={(e) => handleChange(index, e.target.value)}
//                   >
//                     <option value="0">No</option>
//                     <option value="1">Yes</option>
//                   </Select>
//                 </td>
//                 <td>
//                   <Stack spacing={4} direction="row" align="center">
//                     {checklistItems.length > 4 && (
//                       <Button
//                         colorScheme="teal"
//                         size="xs"
//                         onClick={() => handleRowUpdateData(index)}
//                       >
//                         Update
//                       </Button>
//                     )}
//                   </Stack>
//                 </td>
//               </tr>
//             ))}
//         </tbody>
//       </table>
//     </>
//   );
// };

// export default AddChecklist;
