export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete,
    onOpenReportLeadConvert,
    handleOpenEditor,
    onOpenReportOutlookEmail,
    handleCopyOpenModal
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.onOpenMassupdate = onOpenMassupdate;
    this.onOpenReportDelete = onOpenReportDelete;
    this.onOpenReportLeadConvert = onOpenReportLeadConvert;
    this.handleOpenEditor = handleOpenEditor;
    this.onOpenReportOutlookEmail = onOpenReportOutlookEmail;
    // Bind functions directly in the constructor using arrow functions
    this.handleCopyOpenModal = (id) => handleCopyOpenModal.call(this, id);
  }
  handleEditClickMassupdate() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenMassupdate();
  }

  handleEditClickMassdelete() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }
    this.onOpenReportDelete();
  }

  handleEditClickMassleadtoprospect() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }

    // If checkBoxSelected is true, you can continue with the  action.
    this.onOpenReportLeadConvert();
  }

  handleEditClickMassmail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.handleOpenEditor();
  }

  handleEditClickOutlookMail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.onOpenReportOutlookEmail();
  }

  handleEditCopy() {
    if (this.checkBoxSelected.length !== 1) {
      console.log("select only one row to copy ");
      this.toast({
        title: "select only one row to copy",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
      return;
    }
    const selectedId = this.checkBoxSelected[0];
    console.log(`Selected ID for copy: ${selectedId}`);
    this.handleCopyOpenModal(selectedId);
  }
}
