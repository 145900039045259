/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import Axios, {
  AuthAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance.js";
import "../../../assets/css/container.css";
import {
  DraggableIncompleteTask,
  Header,
  OperationSidebar,
  UnAuthorizedModal,
} from "../../../components";
// Internal modules
import "./Incompletetask.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Text, Tooltip } from "@chakra-ui/react";
import { ModalOverlay, Button } from "@chakra-ui/react";

import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";

import { MdOutlineDownloadForOffline } from "react-icons/md";

import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiPrinter } from "react-icons/fi";

const IncompleteTask = () => {
  // State Variables
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();

  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();

  const cancelRef = useRef();
  const searchRef = useRef(null);
  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [incompletetaskData, setIncompleteTaskData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // Column Toggle
  const [columns, setColumns] = useState({
    // id: true,
    // Incomplete_Task: true,
    // JobNumber: true,
    // BookingNumber: true,
    // POL: true,
    // POD: true,
    // Client: false,
    // HandleBy: true,
    JobNumber: true,
    BookingNumber: true,
    Documents: true,
    POL: true,
    POD: true,
    Equipments: true,
    Client: true,
    HandleBy: true,
  });

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };

  const [tableColumnsIncompleteTask, settableColumnsIncompleteTask] = useState(
    []
  );

  useEffect(() => {
    getData();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsIncompleteTask((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsIncompleteTask.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsIncompleteTask", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await OperationDocumentAPIInstance.get(
        `get_incompletetask?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setIncompleteTaskData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "JobStatus"
              ? false
              : item === "Shipper"
              ? false
              : item === "DateOfBussinessConfirmed"
              ? false
              : item === "Consignee"
              ? false
              : item === "Agent"
              ? false
              : item === "QuoteNumber"
              ? false
              : item === "Scope"
              ? false
              : item === "Region"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Cargo"
              ? false
              : item === "Commodity"
              ? false
              : item === "Dimension"
              ? false
              : item === "Weight"
              ? false
              : item === "CargoNature"
              ? false
              : item === "PackingType"
              ? false
              : item === "ShipmentLineNVOCCLCL"
              ? false
              : item === "SICutOff"
              ? false
              : item === "CYOpen"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "CustomsCutOff"
              ? false
              : item === "OOGCutOff"
              ? false
              : item === "Freight"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "FreeDaysAtOrigin"
              ? false
              : item === "FreeDaysAtDestination"
              ? false
              : item === "MBLType"
              ? false
              : item === "HBLType"
              ? false
              : item === "LinerBookingNumber"
              ? false
              : item === "MBLNumber"
              ? false
              : item === "ContainerNumber"
              ? false
              : item === "HBLNumber"
              ? false
              : item === "CargoPickUpDate"
              ? false
              : item === "StuffingDate"
              ? false
              : item === "EmptyPickUpDate"
              ? false
              : item === "LadenGateInDate"
              ? false
              : item === "Vendor"
              ? false
              : item === "AdditionalChargesDetails"
              ? false
              : item === "Amount"
              ? false
              : item === "Detention"
              ? false
              : item === "Demurrage"
              ? false
              : item === "DetentionByLiner"
              ? false
              : item === "DemurageByPort"
              ? false
              : item === "NoOfDetentionOccuring"
              ? false
              : item === "NoOfDemurrageOccuring"
              ? false
              : item === "CurrentStatus"
              ? false
              : item === "OperationTransferDeadline"
              ? false
              : item === "Notes"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setIncompleteTaskData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        // If 401 Unauthorized, show the Unauthorized Modal
        setIsUnauthorized(true);
      } else if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("incompletetaskfilter")?.length === 0) {
      return setIncompleteTaskData([]);
    }

    if (window.localStorage.getItem("incompletetaskfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("incompletetaskfilterRows"))
      ? JSON.parse(localStorage.getItem("incompletetaskfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("incompletetaskfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem(
      "incompletetaskfilterRows",
      JSON.stringify(filterRows)
    );
    localStorage.setItem(
      "incompletetaskfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "incompletetaskfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await OperationDocumentAPIInstance.post(
        `incompletetask_massfilter?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("incompletetaskfilter", JSON.stringify([]));
        return setIncompleteTaskData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "JobStatus"
              ? false
              : item === "Shipper"
              ? false
              : item === "DateOfBussinessConfirmed"
              ? false
              : item === "Consignee"
              ? false
              : item === "Agent"
              ? false
              : item === "QuoteNumber"
              ? false
              : item === "Scope"
              ? false
              : item === "Region"
              ? false
              : item === "Incoterm"
              ? false
              : item === "Cargo"
              ? false
              : item === "Commodity"
              ? false
              : item === "Dimension"
              ? false
              : item === "Weight"
              ? false
              : item === "CargoNature"
              ? false
              : item === "PackingType"
              ? false
              : item === "ShipmentLineNVOCCLCL"
              ? false
              : item === "SICutOff"
              ? false
              : item === "CYOpen"
              ? false
              : item === "VGMCutOff"
              ? false
              : item === "CYCutOff"
              ? false
              : item === "CustomsCutOff"
              ? false
              : item === "OOGCutOff"
              ? false
              : item === "Freight"
              ? false
              : item === "ETD"
              ? false
              : item === "ETA"
              ? false
              : item === "FreeDaysAtOrigin"
              ? false
              : item === "FreeDaysAtDestination"
              ? false
              : item === "MBLType"
              ? false
              : item === "HBLType"
              ? false
              : item === "LinerBookingNumber"
              ? false
              : item === "MBLNumber"
              ? false
              : item === "ContainerNumber"
              ? false
              : item === "HBLNumber"
              ? false
              : item === "CargoPickUpDate"
              ? false
              : item === "StuffingDate"
              ? false
              : item === "EmptyPickUpDate"
              ? false
              : item === "LadenGateInDate"
              ? false
              : item === "Vendor"
              ? false
              : item === "AdditionalChargesDetails"
              ? false
              : item === "Amount"
              ? false
              : item === "Detention"
              ? false
              : item === "Demurrage"
              ? false
              : item === "DetentionByLiner"
              ? false
              : item === "DemurageByPort"
              ? false
              : item === "NoOfDetentionOccuring"
              ? false
              : item === "NoOfDemurrageOccuring"
              ? false
              : item === "CurrentStatus"
              ? false
              : item === "OperationTransferDeadline"
              ? false
              : item === "Notes"
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setIncompleteTaskData(filteredData);

      localStorage.setItem(
        "incompletetaskfilter",
        JSON.stringify(filteredData)
      );
      setFilterOpen(false);
      setCurrentPage(1);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("incompletetaskfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setIncompleteTaskData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setIncompleteTaskData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("incompletetaskfilterConditions");
    window.localStorage.removeItem("incompletetaskfilter");
    window.localStorage.removeItem("incompletetaskfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = incompletetaskData[0] && Object?.keys(incompletetaskData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("incompletetaskfilter")?.length === 0) {
      return setIncompleteTaskData([]);
    }

    if (window.localStorage.getItem("incompletetaskfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type
  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await OperationDocumentAPIInstance.get(
      `search_incomplete?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setIncompleteTaskData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible: true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setIncompleteTaskData(filteredData);
    setCurrentPage(1);
  };

  // PDF Data Export
  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "landscape";
    const doc = new jsPDF(orientation, unit, size);
  
    // Column names as they appear in the data
    const tableColumnsIncompleteTask = [
      "JobNumber",
      "BookingNumber",
      "POL",
      "POD",
      "Documents",
      "Equipments",
      "HandleBy",
      "Client",
      "Submission_Status", // data property name
    ];
  
    // Header titles to display in the PDF
    const headerTitles = [
      "Job Number",
      "Booking Number",
      "POL",
      "POD",
      "Documents",
      "Equipments",
      "Handle By",
      "Client",
      "Submission Status", // displayed as "Submission Status"
    ];
  
    const formatData = (data) =>
      data.map((item) =>
        tableColumnsIncompleteTask.map((column) => item[column])
      );
  
    const tableRows = formatData(data);
  
    const marginLeft = 40;
    const marginTop = 60;
    const titleFontSize = 15; // Increased for better readability
    const tableFontSize = 10; // Adjusted for table content visibility
  
    doc.setFontSize(titleFontSize);
    const title = "Job Incomplete Task";
    doc.text(title, marginLeft, marginTop);
  
    const tableOptions = {
      startY: marginTop + titleFontSize + 10,
      headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: tableFontSize },
      bodyStyles: { fontSize: tableFontSize },
      columnStyles: { 0: { cellWidth: "auto" } }, // Set first column to auto for dynamic width
      head: [headerTitles], // Use display titles as header
      body: tableRows,
    };
  
    doc.autoTable(tableOptions);
  
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `Job_Incomplete_Task_${currentDate}_${currentTime}.pdf`;
  
    doc.save(finalFileName);
  };
  
  const handlePrintClick = () => {
    if (exportData.length > 0) {
      exportToPDF(exportData, "Job Incomplete Task");
    } else {
      console.error("No data available for export.");
    }
  };
  

  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(incompletetaskData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsIncompleteTask")) {
      settableColumnsIncompleteTask(
        JSON.parse(localStorage.getItem("tableColumnsIncompleteTask"))
      );
    } else {
      settableColumnsIncompleteTask(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsIncompleteTask];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsIncompleteTask",
      JSON.stringify(updatedColumns)
    );
    settableColumnsIncompleteTask(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const Managecolumn = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    // backdropFilter: "blur(10px)",
    overflowY: "auto",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
  );
  return (
    <div>
      <Header />
      {!isUnauthorized ? (
        <>
          <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
            
            <FaFilter
              size={25}
              onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
              style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
            />
            &nbsp;&nbsp;
            <>
              <div className="pt-2 ml-10 relative mx-auto text-gray-600">
                <input
                  className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                  ref={searchRef}
                  type="search"
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </>
            &nbsp;
           
          
            
            <>
              {isOpenFilter && (
                <>
                <div style={backdropStyle}></div>
                <div className={`FilterModal`}>
                  {/* {${scrollBehavior}} */}
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 600,
                      // height: 200,
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Criteria
                    </header>
                    <div
                      className="closemodule"
                      onClick={() => {
                        onCloseFilter(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    <FormControl>
                      {filterRows.map((index) => (
                        <Flex key={index}>
                          <Select
                            id={`column-select-${index}`}
                            placeholder="Select"
                            onChange={(e) => handleFilterTypeSelect(e, index)}
                            value={filterConditions[index]?.column || "select"}
                            size={"md"}
                            isDisabled={false}
                            blurInputOnSelect={true}
                            isSearchable={false}
                            style={{ position: "relative" }}
                          >
                            <option value="JobNumber">Job Number</option>
                            <option value="Documents">Incomplete Task</option>
                            <option value="HandleBy">Handle By</option>
                          </Select>
                          &nbsp;&nbsp;
                          <Select
                            id={`filter-type-${index}`}
                            onChange={(e) =>
                              handleFilterConditionSelect(e, index)
                            }
                            value={
                              filterConditions[index]?.condition || "select"
                            }
                            placeholder="Select"
                          >
                            {filterConditions[index]?.column === "CreatedAt" ||
                            filterConditions[index]?.column === "ChangedAt" ? (
                              <>
                                <option value="equalsDate">=</option>
                                <option value="not-equalsDate">!=</option>
                                <option value="greaterDate">&gt;</option>
                                <option value="lesserDate">&lt;</option>
                                <option value="greater-equalDate">&gt;=</option>
                                <option value="lesser-equalDate">&lt;=</option>
                              </>
                            ) : filterConditions[index]?.column ===
                              "JobOwnerName" ? (
                              <>
                                <option value="isleadname">is</option>
                                <option value="containsleadname">
                                  contains
                                </option>
                                <option value="startsWithleadname">
                                  starts with
                                </option>
                                <option value="endsWithleadname">
                                  ends with
                                </option>
                              </>
                            ) : filterConditions[index]?.column === "Phone" ||
                              filterConditions[index]?.column ===
                                "FollowUpInterval" ||
                              filterConditions[index]?.column ===
                                "NotificationDays" ||
                              filterConditions[index]?.column ===
                                "FollowUpDate" ||
                              filterConditions[index]?.column ===
                                "DeletionFlag" ||
                              filterConditions[index]?.column ===
                                "ConversionFlag" ? (
                              <>
                                <option value="equals">=</option>
                                <option value="not-equals">!=</option>
                                <option value="greater">&gt;</option>
                                <option value="lesser">&lt;</option>
                                <option value="greater-equal">&gt;=</option>
                                <option value="lesser-equal">&lt;=</option>
                              </>
                            ) : (
                              <>
                                <option value="is">is</option>
                                <option value="contains">contains</option>
                                <option value="startsWith">starts with</option>
                                <option value="endsWith">ends with</option>
                              </>
                            )}
                          </Select>
                          &nbsp;&nbsp;
                          {/* <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      /> */}
                          {/* Conditionally render the input field based on the selected column */}
                          {["FollowUpDate", "ChangedAt", "CreatedAt"].includes(
                            filterConditions[index]?.column
                          ) ? (
                            <Input
                              type="date"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              id={`value-input-${index}`}
                              value={filterConditions[index]?.value || ""}
                              onChange={(e) =>
                                handleFilterValueChange(e, index)
                              }
                            />
                          )}
                          &nbsp;&nbsp;{" "}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "green" }}
                            onClick={() => addFilterRow()}
                          />
                          {filterRows?.length > 1 && index > 0 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={70}
                              style={{ marginTop: "-15px", color: "red" }}
                              onClick={() => removeFilterRow(index)}
                            />
                          )}
                        </Flex>
                      ))}
                    </FormControl>
                    <div
                      style={{
                        float: "right",
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSubmitFilter();
                          onCloseFilter();
                        }}
                        mr={3}
                      >
                        Apply
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleClearFilters();
                          onCloseFilter();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      <RadioGroup
                        style={{ flex: 1 }}
                        value={handleCheckboxFilter}
                      >
                        <Stack direction="row">
                          <Radio value="AND" onChange={handleCheckboxChange}>
                            All
                          </Radio>
                          <Radio value="OR" onChange={handleCheckboxChange}>
                            Any
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </Rnd>
                </div>
                </>
              )}
            </>
            <>
              {isOpenManagecolumn && (
                <div style={modalStyleUpdate}>
                  <Rnd
                    style={Managecolumn}
                    default={{
                      x: 0,
                      y: 0,
                      width: 320,
                      height: 500,
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Manage Column
                    </header>
                    <div
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />

                    {tableColumnsIncompleteTask.map(
                      (label, index) =>
                        label.key !== "id" && (
                          <label
                            key={index}
                            className="flex items-center"
                            draggable
                            onDragStart={(event) =>
                              handleDragStart(event, index)
                            }
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, index)}
                          >
                            <input
                              type="checkbox"
                              // checked={label.label}
                              className="cursor-pointer"
                              disabled={
                                label.key === "IncompleteT_ask"
                                  ? true
                                  : label.key === "JobNumber"
                                  ? true
                                  : label.key === "BookingNumber"
                                  ? true
                                  : label.key === "POL"
                                  ? true
                                  : label.key === "POD"
                                  ? true
                                  : label.key === "HandleBy"
                                  ? true
                                  : label.key === "Client"
                                  ? true
                                  : null
                              }
                              checked={label.isVisible}
                              onChange={(event) => {
                                handleCheckboxColumn(event, label);
                              }}
                            />
                            <span
                              className="ml-1"
                              style={{ cursor: "all-scroll" }}
                            >
                              {label.label}
                            </span>
                            {label.isDisabled && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </label>
                        )
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        float: "right",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="green"
                      >
                        OK
                      </Button>
                      <Button
                        onClick={() => {
                          onCloseManagecolumn(false);
                        }}
                        colorScheme="red"
                        ml={3}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Rnd>
                </div>
              )}
            </>
          </div>
          <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
            <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
              {/* Next page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
                style={{ float: "right", marginRight: "5px" }}
              >
                <FiArrowRightCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToNextPage}
                  disabled={currentPage === pageNumbers}
                />
              </div>

              {/* Previous page button */}
              <div
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
                style={{ float: "right", marginRight: "10px" }}
              >
                <FiArrowLeftCircle
                  className="cursor-pointer w-6 h-6"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                />
              </div>

              {/* Page numbers */}
              <p
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
                style={{ float: "right", marginRight: "20px" }}
              >
                <a className="relative inline-flex items-center ">
                  {renderPageNumbers()}
                </a>
              </p>

              {/* Items per page dropdown */}
              <select
                className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
                style={{
                  float: "right",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {availableItemsPerPage.map((option) => (
                  <option key={option} value={option}>
                    {option} Records Per Page
                  </option>
                ))}
              </select>
              {/* Icons */}

              <Tooltip hasArrow label="Print" bg="gray.300" color="black">
                <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
                <FiPrinter
      size={20}
      onClick={() => {
      handlePrintClick();
      }}
    />
                </div>
              </Tooltip>

        
              {/* Record count */}
              <p className="py-1 px-2 my-2" style={{ float: "left" }}>
                <p className="text-sm text-gray-700 dark:text-white">
                  Showing{" "}
                  <span className="font-medium">
                    {currentPage === 1 ? 1 : indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, incompletetaskData?.length)}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {incompletetaskData?.length}
                  </span>{" "}
                  records
                </p>
                <p style={{ float: "left", marginTop: "10px" }}>
                  <Selectallpages
                    selectAllPages={selectAllPages}
                    setSelectAllPages={setSelectAllPages}
                    checkBoxAll={checkBoxAll}
                  />
                </p>
              </p>
            </div>

            <div className="table-container">
              <DraggableIncompleteTask
                isLoading={isLoading}
                columns={columns2}
                incompletetaskData={incompletetaskData}
                getData={getData}
                data={incompletetaskData.slice(
                  indexOfFirstItem,
                  indexOfLastItem
                )}
                setIncompleteTaskData={setIncompleteTaskData}
                handleCheckbox={handleCheckbox}
                checkBoxSelected={checkBoxSelected}
                setCheckBoxSelected={setCheckBoxSelected}
                setCheckBoxAll={setCheckBoxAll}
                checkBoxAll={checkBoxAll}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                tableColumnsIncompleteTask={tableColumnsIncompleteTask}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto mt-52">
          <UnAuthorizedModal
            heading={"UnAuthorized"}
            text={"Contact the administrator for more details"}
          />
        </div>
      )}
     
     
     
    </div>
  );
};

export default IncompleteTask;
