export class Checkvalidation {
    constructor(
      checkBoxSelected,
      toast,
      onOpenReportDelete,
      //onOpenMassupdate,
      handlePrint,
      handleEditClick,
      handleDownload,
    ) {
      this.checkBoxSelected = checkBoxSelected;
      this.toast = toast;
      this.onOpenReportDelete = onOpenReportDelete;
      // this.onOpenMassupdate = onOpenMassupdate;
      this.handlePrint = handlePrint;
      this.handleEditClick=handleEditClick;
      this.handleDownload=handleDownload;
    }
    handleEditClickMassdelete() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for deletion.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      this.onOpenReportDelete();
    }
  
  
    handleEditClickupdate() {
       if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for print.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
  } else if (this.checkBoxSelected.length > 1) {
    console.log("Select only one row for print.");
    this.toast({
      title: "Select only one row to print",
      position: "bottom-center",
      isClosable: true,
      status: "error",
      containerStyle: {
        width: "98.5vw",
        maxWidth: "98.5vw",
        alignContent: "center",
      },
    });
    return;
  }
    // If checkBoxSelected has at least one item selected continue with
    this.handleEditClick();
  } 
  
    handlePrintClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for print.");
      this.toast({
        title: "Select only one row to print",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handlePrint();
    }  
    handleDownloadClick() {
      if (this.checkBoxSelected.length === 0) {
        console.log("No row selected for print.");
        this.toast({
          title: "Please select a row",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for download.");
      this.toast({
        title: "Select only one row to download",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
      // If checkBoxSelected has at least one item selected continue with
      this.handleDownload();
    } 
  }
  