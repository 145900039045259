import React from "react";
import { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../../api-instance";
import { useToast,Textarea } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../AddDocument/HBLcreation/HBLcreation.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";

const EditHBLCreation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  //const [formDataIndex, setFormDataIndex] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [editedHblData, setEditedHblData] = useState({});
  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("SDJ:", selectedJob);
  const [selectedHblData, setSelectedHblData] = useState({});
  const [PortData, setportData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [initialType, setInitialType] = useState("");
  const [descriptionType, setDescriptionType] = useState();
  useEffect(() => {
    if (selectedJob) {
      setSelectedHblData({
        JobInfoNo: selectedJob.JobInfoNo || "",
        SiNumber: selectedJob.SINumber || "",
        Shipper: selectedJob.Shipper || "",
        JobNumber: selectedJob.JobNumber || "",
        HBLNumber: selectedJob.HBLNumber || "",
        BookingNumber: selectedJob.BookingNumber || "",
        PlaceOfReceipt: selectedJob.PlaceOfReceipt || "",
        Consignee: selectedJob.Consignee || "",
        POL: selectedJob.POL || "",
        Notify_Party: selectedJob.Notify_Party || "", // You mentioned you want this to be updated
        POD: selectedJob.POD || "",
        DeliveryAgent: selectedJob.DeliveryAgent || "",
        TermsOfShippment: selectedJob.TermsOfShipment || "",
        VesselNameVoyage: selectedJob.VesselNameVoyage || "",
        PlaceOfDelivery: selectedJob.PlaceOfDelivery || "",
        HSNCode: selectedJob.HSNCode || "", // You mentioned you want this to be updated
        BLType: selectedJob.BLType || "",
        Marks_Number: selectedJob.Marks_Number || "",
        Originals: selectedJob.Originals || "",
        FreightPayableAt: selectedJob.FreightPayableAt || "",
        TypeOfShipment: selectedJob.TypeOfShipment || "",
        FreightTerms: selectedJob.FreightTerms || "",
        ShippedOnBoardDate: selectedJob.ShippedOnBoardDate || "",
        FreightDetails: selectedJob.FreightDetails || "",
        DateOfIssue: selectedJob.DateOfIssue || "",
        CompanyName: selectedJob.CompanyName || "",
        PlaceOfIssue: selectedJob.PlaceOfIssue || "",
        type: initialType, // Set type to descriptionType
        HblOwner: selectedJob.HblOwner,
        AsAgency: selectedJob.AsAgency || "",
        Operations:
          selectedJob.Containers && selectedJob.Containers !== ""
            ? selectedJob.Containers.split(";").map((container) => {
                const containerObj = {};
                container.split(",").forEach((attribute) => {
                  const [key, value] = attribute
                    .trim()
                    .split(":")
                    .map((item) => item.trim());
                  containerObj[key] = value === "" ? null : value;
                });
                return containerObj;
              })
            : [],
      });
    }
  }, [selectedJob, initialType]);

  // Define initialType state variable
  useEffect(() => {
    if (
      selectedHblData &&
      selectedHblData.Operations &&
      selectedHblData.Operations.length > 0
    ) {
      const initialType = selectedHblData.Operations[0].type;
      setInitialType(initialType); // Update initialType state variable
    }
  }, [selectedHblData]);
  useEffect(() => {
    setDescriptionType(initialType);
  }, [initialType]);

  console.log("Description type:", descriptionType);
  console.log(selectedHblData.Operations);

  const [formData, setFormData] = useState(selectedHblData);

  useEffect(() => {
    // Update formData with editedHblData
    setFormData(editedHblData);
  }, [editedHblData, descriptionType]);

  const handleSingleDescription = (value) => {
    console.log(descriptionType);
    console.log(value);
    if (descriptionType === "Single" && value) {
      const updatedOperationsWithDescription = editedHblData.Operations.map(
        (operation) => ({
          ...operation,
          descriptionOfGoods: value,
        })
      );
      setEditedHblData((prevState) => ({
        ...prevState,
        type: "Single",
        Operations: updatedOperationsWithDescription,
      }));
    }
  };
  const handleDescriptionChange = (index, value) => {
    const updatedOperations = [...editedHblData.Operations];
    updatedOperations[index].descriptionOfGoods = value;
    setEditedHblData((prevState) => ({
      ...prevState,
      type: "Containerwise",
      Operations: updatedOperations,
    }));
  };

  useEffect(() => {
    setEditedHblData(selectedHblData);
  }, [selectedHblData]);

  const [packageTypeData, setPackageTypeData] = useState([]);
  // Package Type API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_packagetype");
        setPackageTypeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);


  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setportData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedHblData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAsAgencyChange = async (e) => {
    const { name, value } = e.target;

    setEditedHblData((prev) => ({
      ...prev,
      AsAgency: e.target.value,
    }));
  };
  const handleShipperChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      Shipper: e.target.value,
    }));
  };
  const handleConsigneeChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      Consignee: e.target.value,
    }));
  };
  const handleNotifyPartyChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      Notify_Party: e.target.value,
    }));
  };
  const handleDeliveryAgentChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      DeliveryAgent: e.target.value,
    }));
  };
  const handlePolChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      POL: e.target.value,
    }));
  };
  const handlePodChange = (e) => {
    setEditedHblData((prevData) => ({
      ...prevData,
      POD: e.target.value,
    }));
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.put(
      `edit_hbl?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedHblData.Operations];
    updatedOperations[index][key] = value;
    setEditedHblData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
  };

  return (
    <>
      <form>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Edit HBL Creation</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-hblcreations">
                <div className="input-box">
                  <span className="hblcreations">
                    Shipper
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Shipper"
                    defaultValue={
                      formData["Shipper"] || selectedHblData["Shipper"]
                    }
                    value={editedHblData["Shipper"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Shipper"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Shipper"] || selectedHblData["Shipper"]
                    }
                    value={editedHblData["Shipper"] || ""}
                    onChange={handleShipperChange}
                  >
                    <option>Select Shipper</option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">
                    Place of Receipt
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfReceipt"
                    defaultValue={
                      formData["PlaceOfReceipt"] ||
                      selectedHblData["PlaceOfReceipt"]
                    }
                    value={editedHblData["PlaceOfReceipt"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfReceipt"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">
                    Consignee
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Consignee"
                    defaultValue={
                      formData["Consignee"] || selectedHblData["Consignee"]
                    }
                    value={editedHblData["Consignee"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Consignee"
                  /> */}
                  <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    defaultValue={
                      formData["Consignee"] || selectedHblData["Consignee"]
                    }
                    value={editedHblData["Consignee"] || ""}
                    onChange={handleConsigneeChange}
                  >
                    <option>Select Consignee</option>
                    <option
                      value={
                        formData["Consignee"] || selectedHblData["Consignee"]
                      }
                    >
                      {formData["Consignee"] || selectedHblData["Consignee"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">
                    POL
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedHblData["POL"]}
                    value={editedHblData["POL"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POL"
                  /> */}
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POL"
                    defaultValue={formData["POL"] || selectedHblData["POL"]}
                    value={editedHblData["POL"] || ""}
                    onChange={handlePolChange}
                  >
                    placeholder="Enter your POL"
                    <option value={formData["POL"] || selectedHblData["POL"]}>
                      {formData["POL"] || selectedHblData["POL"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">
                    Notify Party
                  </span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Notify_Party"
                    defaultValue={
                      formData["Notify_Party"] ||
                      selectedHblData["Notify_Party"]
                    }
                    value={editedHblData["Notify_Party"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Notify_Party"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Notify_Party"
                    defaultValue={
                      formData["Notify_Party"] ||
                      selectedHblData["Notify_Party"]
                    }
                    value={editedHblData["Notify_Party"] || ""}
                    onChange={handleNotifyPartyChange}
                  >
                    <option>Select Notify Party</option>
                    <option
                      value={
                        formData["Notify_Party"] || selectedHblData["Notify_Party"]
                      }
                    >
                      {formData["Notify_Party"] || selectedHblData["Notify_Party"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">POD</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedHblData["POD"]}
                    value={editedHblData["POD"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your POD"
                  /> */}
                   <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="POD"
                    defaultValue={formData["POD"] || selectedHblData["POD"]}
                    value={editedHblData["POD"] || ""}
                    onChange={handlePodChange}
                  >
                    placeholder="Enter your POD"
                    <option value={formData["POD"] || selectedHblData["POD"]}>
                      {formData["POD"] || selectedHblData["POD"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {PortData.map((item, idx) => (
                      <option key={idx} value={item.Port}>
                        {item.Port}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">Delivery Agent</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DeliveryAgent"
                    defaultValue={
                      formData["DeliveryAgent"] ||
                      selectedHblData["DeliveryAgent"]
                    }
                    value={editedHblData["DeliveryAgent"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Delivery Agent"
                  /> */}
                   <select
                    width="190px"
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="DeliveryAgent"
                    defaultValue={
                      formData["DeliveryAgent"] ||
                      selectedHblData["DeliveryAgent"]
                    }
                    value={editedHblData["DeliveryAgent"] || ""}
                    onChange={handleDeliveryAgentChange}
                  >
                    <option>Select Delivery Agent</option>
                    <option
                      value={
                        formData["DeliveryAgent"] || selectedHblData["DeliveryAgent"]
                      }
                    >
                      {formData["DeliveryAgent"] || selectedHblData["DeliveryAgent"]}
                    </option>
                    {/* Render options from equipmentData */}
                    {customerData.map((item, idx) => (
                      <option key={idx} value={item.Company}>
                        {item.Company}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">Place of Delivery</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfDelivery"
                    defaultValue={
                      formData["PlaceOfDelivery"] ||
                      selectedHblData["PlaceOfDelivery"]
                    }
                    value={editedHblData["PlaceOfDelivery"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfDelivery"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">TermsOfShippment</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TermsOfShippment"
                    defaultValue={
                      formData["TermsOfShippment"] ||
                      selectedHblData["TermsOfShippment"]
                    }
                    value={editedHblData["TermsOfShippment"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your TermsOfShippment"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">Vessel and Voyage</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VesselNameVoyage"
                    defaultValue={
                      formData["VesselNameVoyage"] ||
                      selectedHblData["VesselNameVoyage"]
                    }
                    value={editedHblData["VesselNameVoyage"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your VesselNameVoyage"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">HSN Code</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HSNCode"
                    defaultValue={
                      formData["HSNCode"] || selectedHblData["HSNCode"]
                    }
                    value={editedHblData["HSNCode"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the HSNCode"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">B/L Type</span>
                  <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="BLType"
                    defaultValue={
                      formData["BLType"] || selectedHblData["BLType"]
                    }
                    value={editedHblData["BLType"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select BLType</option>
                    <option value="Original">Original</option>
                    <option value="Surrender">Surrender</option>
                    <option value="Seaway">Seaway</option>
                  </select>
                </div>
                <div className="input-box">
                  <span className="hblcreations">Marks and Number</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Marks_Number"
                    defaultValue={
                      formData["Marks_Number"] ||
                      selectedHblData["Marks_Number"]
                    }
                    value={editedHblData["Marks_Number"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Marks_Number"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">
                    In case of Original(No of Originals)
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="number"
                    name="Originals"
                    defaultValue={
                      formData["Originals"] || selectedHblData["Originals"]
                    }
                    value={editedHblData["Originals"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Originals"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">FreightPayableAt</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightPayableAt"
                    defaultValue={
                      formData["FreightPayableAt"] ||
                      selectedHblData["FreightPayableAt"]
                    }
                    value={editedHblData["FreightPayableAt"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightPayableAt"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">TypeOfShipment</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="TypeOfShipment"
                    defaultValue={
                      formData["TypeOfShipment"] ||
                      selectedHblData["TypeOfShipment"]
                    }
                    value={editedHblData["TypeOfShipment"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your TypeOfShipment"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">FreightTerms</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightTerms"
                    defaultValue={
                      formData["FreightTerms"] ||
                      selectedHblData["FreightTerms"]
                    }
                    value={editedHblData["FreightTerms"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightTerms"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">ShippedOnBoardDate</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="ShippedOnBoardDate"
                    defaultValue={
                      (
                        formData["ShippedOnBoardDate"] ||
                        selectedHblData["ShippedOnBoardDate"] ||
                        editedHblData["ShippedOnBoardDate"] ||
                        ""
                      )?.split("T")[0] || ""
                    }
                    // value={editedHblData["ShippedOnBoardDate"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ShippedOnBoardDate"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">FreightDetails</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightDetails"
                    defaultValue={
                      formData["FreightDetails"] ||
                      selectedHblData["FreightDetails"]
                    }
                    value={editedHblData["FreightDetails"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightDetails"
                  /> */}
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FreightDetails"
                    defaultValue={
                      formData["FreightDetails"] ||
                      selectedHblData["FreightDetails"]
                    }
                    value={editedHblData["FreightDetails"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your FreightDetails"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">DateOfIssue</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="DateOfIssue"
                    defaultValue={
                      (
                        formData["DateOfIssue"] ||
                        selectedHblData["DateOfIssue"] ||
                        editedHblData["DateOfIssue"] ||
                        ""
                      )?.split("T")[0] || ""
                    }
                    // value={editedHblData["DateOfIssue"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your DateOfIssue"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">CompanyName</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CompanyName"
                    defaultValue={
                      formData["CompanyName"] || selectedHblData["CompanyName"]
                    }
                    value={editedHblData["CompanyName"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your CompanyName"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">PlaceOfIssue</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PlaceOfIssue"
                    defaultValue={
                      formData["PlaceOfIssue"] ||
                      selectedHblData["PlaceOfIssue"]
                    }
                    value={editedHblData["PlaceOfIssue"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfIssue"
                  />
                </div>
                <div className="input-box">
                  <span className="hblcreations">AS Agency</span>
                  {/* <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="AsAgency"
                    name="PlaceOfIssue"
                    defaultValue={
                      formData["PlaceOfIssue"] || selectedHblData["PlaceOfIssue"]
                    }
                    value={editedHblData["PlaceOfIssue"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your PlaceOfIssue"
                  /> */}
                  <select
                    className="dark:bg-gray-950 dark:text-white"
                    name="AsAgency"
                    defaultValue={
                      formData["AsAgency"] || selectedHblData["AsAgency"]
                    }
                    value={editedHblData["AsAgency"] || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select AS Agency</option>
                    <option value="As Agent">As Agent</option>
                    <option value="As Agent for Carrier">
                      As Agent for Carrier
                    </option>
                  </select>
                </div>

                {/* &nbsp;
                <br />
                &nbsp; */}

                {/*Container table */}
                <div className="table-container">
                  <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                    <thead className="flex-nowrap text-center bg-blue-950 text-white">
                      <tr>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Type
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Container Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Seal Number
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Number of Packages
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Type Of Package
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Net Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Gross Weight
                        </th>
                        <th className="border border-slate-300 whitespace-nowrap text-center">
                          Volume
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedHblData?.Operations?.map((operation, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="ContainerType"
                              value={
                                editedHblData["ContainerType"] ||
                                operation.ContainerType
                              }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerType",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.ContainerType}>
                                {operation.ContainerType}
                              </option>

                              {/* Render options from equipmentData */}
                              {equipmentData.map((item, idx) => (
                                <option key={idx} value={item.ContainerType}>
                                  {item.ContainerType}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="ContainerNumber"
                              defaultValue={
                                formData[operation.ContainerNumber] ||
                                selectedHblData[operation.ContainerNumber]
                              }
                              //value={editedHblData[operation.ContainerNumber] || ""}
                              value={operation.ContainerNumber}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "ContainerNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="SealNumber"
                              value={operation.SealNumber}
                              defaultValue={
                                formData[operation.SealNumber] ||
                                selectedHblData[operation.SealNumber]
                              }
                              //value={editedHblData[operation.SealNumber] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "SealNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="No_Of_Pkge"
                              value={operation.No_Of_Pkge}
                              defaultValue={
                                formData[operation.No_Of_Pkge] ||
                                selectedHblData[operation.No_Of_Pkge]
                              }
                              //value={editedHblData[operation.No_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "No_Of_Pkge",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            {/* <input
                              type="text"
                              name="Type_Of_Pkge"
                              value={operation.Type_Of_Pkge}
                              defaultValue={
                                formData[operation.Type_Of_Pkge] ||
                                selectedHblData[operation.Type_Of_Pkge]
                              }
                              //value={editedHblData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            /> */}
                            <select
                              width="190px"
                              className="text-black bg-white dark:bg-gray-950 dark:text-white"
                              name="Type_Of_Pkge"
                              value={operation.Type_Of_Pkge}
                              defaultValue={
                                formData[operation.Type_Of_Pkge] ||
                                selectedHblData[operation.Type_Of_Pkge]
                              }
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Type_Of_Pkge",
                                  e.target.value
                                )
                              }
                            >
                              {/* Render initial value */}
                              <option value={operation.Type_Of_Pkge}>
                                {operation.Type_Of_Pkge}
                              </option>

                              {/* Render options from equipmentData */}
                              {packageTypeData.map((item, idx) => (
                                <option key={idx} value={item.packingType}>
                                  {item.packingType}
                                </option>
                              ))}
                            </select>

                          </td>
                          <td>
                            <input
                              type="text"
                              name="NtWeight"
                              value={operation.NtWeight}
                              defaultValue={
                                formData[operation.NtWeight] ||
                                selectedHblData[operation.NtWeight]
                              }
                              //value={editedHblData[operation.Type_Of_Pkge] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "NtWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="GrWeight"
                              value={operation.GrWeight}
                              defaultValue={
                                formData[operation.GrWeight] ||
                                selectedHblData[operation.GrWeight]
                              }
                              //value={editedHblData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "GrWeight",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="Volume"
                              value={operation.Volume}
                              defaultValue={
                                formData[operation.Volume] ||
                                selectedHblData[operation.Volume]
                              }
                              //value={editedHblData[operation.GrWeight] || ""}
                              onChange={(e) =>
                                handleOperationChange(
                                  index,
                                  "Volume",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {/*description part */}
                <br />
                <br />
                <div className="description-box">
                  <div className="inline-flex">
                    <button
                      type="button"
                      onClick={() => setDescriptionType("Single")}
                      className={`${
                        descriptionType === "Single"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Single Description
                    </button>

                    <button
                      type="button"
                      onClick={() => setDescriptionType("Containerwise")}
                      className={`${
                        descriptionType === "Containerwise"
                          ? "bg-gray-400"
                          : "bg-gray-300"
                      } hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l`}
                    >
                      Containerwise Description
                    </button>
                    <br />
                  </div>
                </div>
                <div className="description-box">
                  {descriptionType === "Single" && (
                    <div className="input-box">
                      <span className="hblcreations">Description of goods</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        value={
                          editedHblData &&
                          editedHblData.Operations &&
                          editedHblData.Operations.length > 0
                            ? editedHblData.Operations[0].descriptionOfGoods
                            : ""
                        }
                        onChange={(e) =>
                          handleSingleDescription(e.target.value)
                        }
                        placeholder="Enter the descriptionOfGoods"
                      />
                    </div>
                  )}
                  {descriptionType === "Containerwise" && (
                    <div className="input-box">
                      <span className="hblcreations">Description of goods</span>
                      <div className="table-container">
                        <table className="table-auto border-collapse border bg-white border-slate-400 w-full dark:border-gray-600 dark:bg-slate-950">
                          <thead className="flex-nowrap text-center bg-blue-950 text-white">
                            <tr>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Container Type
                              </th>
                              <th className="border border-slate-300 whitespace-nowrap text-center">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {editedHblData.Operations.map((operation, idx) => (
                              <tr key={idx}>
                                <td>
                                  {operation.ContainerType}-
                                  {operation.ContainerNumber}
                                </td>
                                <td>
                                  <input
                                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                                    type="text"
                                    name={`Description_${idx}`}
                                    value={operation.descriptionOfGoods || ""}
                                    onChange={(e) =>
                                      handleDescriptionChange(
                                        idx,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter the descriptionOfGoods"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditHBLCreation;
