import { React, useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
// External module
import {MasterDataAPIInstance }from "../../api-instance.js";
import "../../assets/css/container.css";
import { DraggableVendorTable } from "../../components";
import { Header } from "../../components";
// Internal modules
import "./Vendor.css";
import Selectallpages from "./Selectallpages";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import
import { MdDownloading } from "react-icons/md";
import { FiEdit, FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd, GoCopy } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BsFiletypeExe } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const Vendor= () => {
  // State Variables
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [size, setSize] = useState("xl");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassUpload,
    onOpen: onOpenReportMassUpload,
    onClose: onCloseReportMassUpload,
  } = useDisclosure();
  const cancelRef = useRef();
  const searchRef = useRef(null);
  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [VendorData, setVendorData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState("");
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const dropdownRef = useRef(null);
  const backdropStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
      zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
    }; 
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActionOpen(false);
      }
    };
    useEffect(() => {
      if (isActionOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isActionOpen]);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [editedVendor, seteditedVendor] = useState({});
  // UseEffects

  const {
    isOpen: isCopyOpen,
    onOpen: onCopyOpen,
    onClose: onCopyClose,
  } = useDisclosure();
  const [selectedVendor, setselectedVendor] = useState({});
  const [copiedVendor, setcopiedVendor] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const CopyButton = () => {
    if (checkBoxSelected.length !== 1) {
      console.log("Please select one row");
      toast({
        title: "Please select one row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    const selectedId = checkBoxSelected[0];
    console.log(`Selected ID for copy: ${selectedId}`);
    handleCopyOpenModal(selectedId);
  };

  useEffect(() => {
    setcopiedVendor(selectedVendor);
  }, [selectedVendor]);

  const handleCopyChange = async (e) => {
    const { name, value } = e.target;
    setcopiedVendor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCopyCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onCopyClose(true);
  };

  const handleCopyOpenModal = async (id) => {
    const selectedVendorData = VendorData.find((Vendor) => Vendor.id === id);

    setselectedVendor(selectedVendorData);
    handleCopyFullSizeClick();
    setSelectedRowId(id);
  };

  const handleCopyFullSizeClick = () => {
    setSize("xl");
    onCopyOpen();
  };

  //Copy Vendor
  const handleSubmitCopy = async () => {
    const updatedData = {
      ...copiedVendor,
    };
    const { id, ...dataToSend } = updatedData;
    console.log(dataToSend);
    try {
      const { data } = await MasterDataAPIInstance.post(
        // Send a POST request using MasterDataAPIInstance
        `addMasterData/add_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        dataToSend, // Include form data in the request
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Vendor added Successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedVendor state here
        setselectedVendor((prevselectedVendor) => ({
          ...prevselectedVendor,
          ...copiedVendor,
        }));
        setCheckBoxSelected([]);
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setCheckBoxSelected([]);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  useEffect(() => {
    seteditedVendor(selectedVendor);
  }, [selectedVendor]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    seteditedVendor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const singleEditButton = () => {
    if (checkBoxSelected.length !== 1) {
      console.log("Please select one row");
      toast({
        title: "Please select one row",
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    const selectedId = checkBoxSelected[0];
    console.log(`Selected ID for copy: ${selectedId}`);
    handleOpenModal(selectedId);
  };

  //  Function to open the modal and fetch Vendor data for a particular ID
  const handleOpenModal = async (id) => {
    const selectedVendorData = VendorData.find((Vendor) => Vendor.id === id);

    setselectedVendor(selectedVendorData);
    handleFullSizeClick();
    setSelectedRowId(id);
  };

  const handleFullSizeClick = () => {
    setSize("xl");
    onEditOpen();
  };

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onEditClose(true);
  };

  //Single Update
  const handleSingleSubmitUpdate = async () => {
    const updateData = {
      ...editedVendor,
    };
    console.log(updateData);
    try {
      const { data } = await MasterDataAPIInstance.put(
        `singleupdateMasterData/singleupdate_vendor?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedVendor.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Vendor Updated Successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedVendor state here
        setselectedVendor((prevselectedVendor) => ({
          ...prevselectedVendor,
          ...editedVendor,
        }));
        setCheckBoxSelected([]);
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setCheckBoxSelected([]);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout (you should use your actual export functions here)
      setTimeout(() => {
        exportToExcel(exportData, "Vendor");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Vendor");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Vendor");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Vendor");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };
  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArray = data.map((item) => [
      item.id,
      item.vendorId,
      item.VendorNumber,
      item.Company,
      item.vendorType,
      item.ContactPerson,
      item.Specialization,
      item.Designation,
      item.Email,
      item.DialCode,
      item.Phone,
      item.City,
      item.Country,
      item.VendorOwner,
      item.Remarks
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      ["id", "vendorId","VendorNumber" ,"Company","vendorType","ContactPerson","Specialization","Designation","Email","DialCode","City","Country","VendorOwner","Remarks"],
      ...dataArray,
    ]);
    console.log(exportToExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Vendor");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(dataBlob, finalFileName);
  };
  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.id,
      item.vendorId,
      item.VendorNumber,
      item.Company,
      item.vendorType,
      item.ContactPerson,
      item.Specialization,
      item.Designation,
      item.Email,
      item.DialCode,
      item.Phone,
      item.City,
      item.Country,
      item.VendorOwner,
      item.Remarks

    ]);

    const csvContent = [
      ["id", "vendorId","VendorNumber" ,"Company","vendorType","ContactPerson","Specialization","Designation","Email","DialCode","City","Country","VendorOwner","Remarks"],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code

  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const doc = new jsPDF(orientation, unit, size);
    const tableColumns = ["id", "vendorId","VendorNumber" ,"Company","vendorType","ContactPerson","Specialization","Designation","Email","DialCode","City","Country","VendorOwner","Remarks"];

    const tableRows = data.map((item) => {
      return tableColumns.map((column) => item[column]);
    });

    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;

    doc.setFontSize(titleFontSize);

    const title = "Vendor Report";
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumns],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };

    doc.autoTable(tableOptions);

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };
  const exportToText = (data, fileName) => {
    const tableColumns = ["id", "vendorId","VendorNumber" ,"Company","vendorType","ContactPerson","Specialization","Designation","Email","DialCode","City","Country","VendorOwner","Remarks"];

    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });

    const textContent = [tableColumns.join(",")].concat(tableRows).join("\n");

    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";

    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };

  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    vendorId: true,
    VendorNumber:true,
    Company:true,
    vendorType:true,
    ContactPerson:true,
    Specialization:true,
    Designation:true,
    Email:true,
    DialCode:true,
    Phone:true,
    City:true,
    Country:true,
    VendorOwner:true,
    Remarks:true,
    userId: true,
  });
  const [tableColumnsVendor, settableColumnsVendor] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsVendor((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsVendor.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsVendor", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await MasterDataAPIInstance.get(
        `getmasterdata/get_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setVendorData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setVendorData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("Vendorfilter")?.length === 0) {
      return setVendorData([]);
    }

    if (window.localStorage.getItem("Vendorfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("VendorfilterRows"))
      ? JSON.parse(localStorage.getItem("VendorfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("VendorfilterConditions")
    );
    return savedConditions || {};
  });
  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("VendorfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "VendorfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };
  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "VendorfilterConditions",
      JSON.stringify(filterConditions)
    );

    const transformedConditions = {};

    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;

      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });

    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    try {
      const { data } = await MasterDataAPIInstance.post(
        `massfilterMasterData/massfilter_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&filterType=${handleCheckboxFilter}`,
        { sendData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("Vendorfilter", JSON.stringify([]));
        return setVendorData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setVendorData(filteredData);
      setCurrentPage(1);
      localStorage.setItem("Vendorfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("Vendorfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setVendorData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : item === ""
              ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setVendorData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("VendorfilterConditions");
    window.localStorage.removeItem("Vendorfilter");
    window.localStorage.removeItem("VendorfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = VendorData[0] && Object?.keys(VendorData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("Vendorfilter")?.length === 0) {
      return setVendorData([]);
    }

    if (window.localStorage.getItem("Vendorfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await MasterDataAPIInstance.put(
        `updateMasterData/update_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? VendorData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.VendorData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Vendor Updated Successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    }
  };
  const handleSubmitDelete = async () => {
    try {
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);

      const { data } = await MasterDataAPIInstance.post(
        `deleteMasterData/delete_vendor?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? VendorData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: `Vendor Deleted Successfully!`,
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        onCloseReportDelete();
      } else {
        toast({
          title: data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected([]);
        onCloseReportDelete();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
      onCloseReportDelete();
      
    }

    if (selectAllPages) {
      localStorage.removeItem("Vendorfilter");
      localStorage.removeItem("VendorfilterConditions");
      localStorage.removeItem("VendorfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("Vendorfilter")) {
      const storedData = JSON.parse(localStorage.getItem("Vendorfilter") || []);

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? VendorData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("Vendorfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("Vendorfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected([]);
    }
  };

  // Mass Upload Excel
  const handleSubmitMassuploadVendor = async (event) => {
    const validateData = () => {
      const requiredColumns = ["Company","vendorType","ContactPerson","Specialization","Designation","Email","DialCode","City","Country","VendorOwner","Remarks"];
      let isValid = true;
      const missingColumns = [];

      for (let index = 0; index < movies.length; index++) {
        const movie = movies[index];
        for (const column of requiredColumns) {
          if (!movie[column]) {
            if (!missingColumns.includes(column)) {
              missingColumns.push(column);
            }
            isValid = false;
          }
        }
      }
      if (missingColumns.length > 0) {
        const errorTitle = `${missingColumns.join(", ")} ${
          missingColumns.length > 1 ? "are" : "is"
        } required for all row data`;
        toast({
          title: errorTitle,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      return isValid;
    };

    if (event) {
    }
    if (!validateData()) {
      return;
    }
    const movieData = movies?.map((item, i) => {
      return {
        ...item,
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };
    });
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
      movies: movieData,
    };
    console.log(dataSet,"vendor");
    try {
      const response = await MasterDataAPIInstance.post(
        `addMultipleMasterData/add_multiple_vendor?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        dataSet,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(response);

      if (response.data.success) {
        // const dataLength = response.data.data.length;
        // Show success toast
        toast({
          title: response.data.data,
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: response.data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      onClose();
      getFilterData();
      getData();
    }
  };

  // Search API
  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await MasterDataAPIInstance.get(
      `searchMasterData/search_vendor?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setVendorData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : item === ""
            ? false
            : true,
      };
    });
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setVendorData(filteredData);
    setCurrentPage(1);
  };
  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(VendorData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);

    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combination
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  function excelDateToJSDate(excelDate) {
    const jsDate = new Date(
      (excelDate - 25569) * 86400 * 1000
    ).toLocaleDateString("en-US", { timeZone: "UTC" });
    return jsDate;
  }

  useEffect(() => {
    if (localStorage.getItem("tableColumnsVendor")) {
      settableColumnsVendor(
        JSON.parse(localStorage.getItem("tableColumnsVendor"))
      );
    } else {
      settableColumnsVendor(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsVendor];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsVendor", JSON.stringify(updatedColumns));
    settableColumnsVendor(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

  //class declaration
  const checkValidation = new Checkvalidation(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete
  );
  const MassUpload = new Massupload(
    setExcelOpen,
    setMovies,
    excelOpen,
    read,
    utils
  );
  return (
    <>
      <Header />
      <>
        {isOpen && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 1000,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{
                  float: "left",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Mass Upload
              </header>
              <div
                className="closemodule"
                onClick={() => {
                  onClose(false);
                  setExcelOpen(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {excelOpen ? (
                <div
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  <TableContainer>
                    <Table variant="simple">
                      <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        <Thead>
                          <Tr>
                            {movies?.length > 0 &&
                              Object.keys(movies[0]).map((item, index) => (
                                <Th
                                  key={index}
                                  className="dark:text-white bg-slate-200 dark:bg-slate-700 dark:border-gray-600"
                                >
                                  {item}
                                </Th>
                              ))}
                          </Tr>
                        </Thead>

                        <Tbody>
                          {movies?.map((mainItem, i) => (
                            <Tr key={i}>
                              {Object.keys(mainItem).map((item, index) => (
                                <Td key={index}>{mainItem[item]}</Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </div>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onOpenReportMassUpload();
                        onClose(false);
                        setExcelOpen(false);
                        MassUpload.handleCloseExcel();
                      }}
                      colorScheme="blue"
                      mr={3}
                      mt={5}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      className="custom-file-input"
                      id="inputGroupFile"
                      required
                      onChange={(event) => {
                        MassUpload.handleImport(event);
                      }}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{ display: "none" }}
                    />

                    <label
                      className="fileUploadContainer"
                      htmlFor="inputGroupFile"
                    >
                      <label
                        className="custom-file-label bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                        style={{ cursor: "pointer" }}
                        htmlFor="inputGroupFile"
                      >
                        Upload the file
                      </label>
                    </label>
                  </div>
                </div>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>

      <div className="containerw-1/2 md:w-full  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
        <FaFilter
          size={25}
          onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
          style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
        />
        &nbsp;&nbsp;
        <>
          <div className="pt-2 ml-10 relative mx-auto text-gray-600">
            <input
              className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
              ref={searchRef}
              type="search"
              name="search"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </>
        &nbsp;
        <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        />
        &nbsp;
        <div className="relative inline-block">
          <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
            <Link to="/addVendor">Create Vendor</Link>
          </button>
        </div>
        &nbsp;&nbsp;
        <div className="relative inline-block">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
            onClick={toggleActionDropdown}
          >
            Actions
          </button>
          &nbsp;&nbsp;
          {isActionOpen && (
            <div 
            ref={dropdownRef}
            className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <FiEdit size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    checkValidation.handleEditClickMassupdate();
                    handleActionSelection();
                  }}
                >
                  Mass Update
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              >
                <RiDeleteBinLine size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Mass Delete
                </span>
              </a>

              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <LiaFileUploadSolid size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    onOpen();
                    toggleActionDropdown();
                    handleActionSelection();
                  }}
                >
                  Mass Upload
                </span>
              </a>
              <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                <MdOutlineDownloadForOffline size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={() => {
                    onOpenDownload();
                    toggleActionDropdown();
                    handleActionSelection();
                  }}
                >
                  Export Data
                </span>
              </a>
              <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  toggleActionDropdown();
                  {
                    navigate("/Vendortemplate");
                  }
                }}
              >
                <BsFiletypeExe size={20} />
                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Vendor Template
                </span>
              </a>
has context menu              
            </div>
          )}
        </div>
        <>
          {isOpenFilter && (
            <>
            <div style={backdropStyle}></div>
            <div className={`FilterModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200,
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Criteria
                </header>
                <div
                  className="closemodule"
                  onClick={() => {
                    onCloseFilter(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />

                <FormControl>
                  {filterRows.map((index) => (
                    <Flex key={index}>
                      <Select
                        id={`column-select-${index}`}
                        placeholder="Select"
                        onChange={(e) => handleFilterTypeSelect(e, index)}
                        value={filterConditions[index]?.column || "select"}
                        size={"md"}
                      >
                        <option value="vendorId">VendorId</option>
                        <option value="VendorNumber">VendorNumber</option>
                        <option value="Company">Company</option>
                        <option value="vendorType">vendorType</option>
                        <option value="ContactPerson">ContactPerson</option>
                        <option value="Specialization">Specialization</option>
                        <option value="Designation">Designation</option>
                        <option value="Email">Email</option>
                        <option value="DialCode">DialCode</option>
                        <option value="Phone">Phone</option>
                        <option value="City">City</option>
                        <option value="Country">Country</option>
                        <option value="VendorOwner">VendorOwner</option>
                        <option value="Remarks">Remarks</option>
                      </Select>
                      &nbsp;&nbsp;
                      <Select
                        id={`filter-type-${index}`}
                        onChange={(e) => handleFilterConditionSelect(e, index)}
                        value={filterConditions[index]?.condition || "select"}
                        placeholder="Select"
                      >
                        {filterConditions[index]?.column === "ValidForm" ||
                        filterConditions[index]?.column === "FromNumber" ||
                        filterConditions[index]?.column === "ToNumber" ? (
                          <>
                            <option value="equals">=</option>
                            <option value="not-equals">!=</option>
                            <option value="greater">&gt;</option>
                            <option value="lesser">&lt;</option>
                            <option value="greater-equal">&gt;=</option>
                            <option value="lesser-equal">&lt;=</option>
                          </>
                        ) : (
                          <>
                            <option value="is">is</option>
                            <option value="contains">contains</option>
                            <option value="startsWith">starts with</option>
                            <option value="endsWith">ends with</option>
                          </>
                        )}
                      </Select>
                      &nbsp;&nbsp;
                      <Input
                        type="text"
                        id={`value-input-${index}`}
                        value={filterConditions[index]?.value || ""}
                        onChange={(e) => handleFilterValueChange(e, index)}
                      />
                      &nbsp;&nbsp;{" "}
                      <IoMdAddCircle
                        className="cursor-pointer"
                        size={70}
                        style={{ marginTop: "-15px", color: "green" }}
                        onClick={() => addFilterRow()}
                      />
                      {filterRows?.length > 1 && index > 0 && (
                        <BiSolidMinusCircle
                          className="cursor-pointer"
                          size={70}
                          style={{ marginTop: "-15px", color: "red" }}
                          onClick={() => removeFilterRow(index)}
                        />
                      )}
                    </Flex>
                  ))}
                </FormControl>

                <div style={{ float: "right", display: "block" }}>
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      handleSubmitFilter();
                      onCloseFilter();
                    }}
                    mr={3}
                  >
                    Apply
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      handleClearFilters();
                      onCloseFilter();
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div
                  style={{
                    float: "left",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  <RadioGroup style={{ flex: 1 }} value={handleCheckboxFilter}>
                    <Stack direction="row">
                      <Radio value="AND" onChange={handleCheckboxChange}>
                        All
                      </Radio>
                      <Radio value="OR" onChange={handleCheckboxChange}>
                        Any
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
              </Rnd>
            </div>
            </>
          )}
        </>
        <>
          {isOpenManagecolumn && (
            <>
            <div style={backdropStyle}></div>
            <div style={modalStyleUpdate}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 320,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Manage Column
                </header>
                <div
                  onClick={() => {
                    onCloseManagecolumn(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />

                {tableColumnsVendor.map(
                  (label, index) =>
                    label.key !== "id" && (
                      <label
                        key={index}
                        className="flex items-center"
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <input
                          type="checkbox"
                          // checked={label.label}
                          className="cursor-pointer"
                          checked={label.isVisible}
                          onChange={(event) => {
                            handleCheckboxColumn(event, label);
                          }}
                        />
                        <span className="ml-1" style={{ cursor: "all-scroll" }}>
                          {label.label}
                        </span>
                        {label.isDisabled && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>
                    )
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <Button
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    colorScheme="green"
                  >
                    OK
                  </Button>
                  <Button
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    colorScheme="red"
                    ml={3}
                  >
                    Cancel
                  </Button>
                </div>
              </Rnd>
            </div>
            </>
          )}
        </>
      </div>

      <div className="containerw-1/2 md:w-full bg-slate-200 text-black h-screen dark:text-white dark:bg-slate-800 leadFixedComponent2">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>
          {/* Icons */}

          <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassdelete();
                }}
              />
            </div>
          </Tooltip>

          <Tooltip hasArrow label="Copy" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 copy">
              <GoCopy
                size={20}
                onClick={() => {
                  CopyButton();
                }}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Edit" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 edit">
              <FiEdit3
                size={20}
                onClick={() => {
                  singleEditButton();
                }}
              />
            </div>
          </Tooltip>

          <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <FiEdit
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickMassupdate();
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(VendorData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>
          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, VendorData?.length)}
              </span>{" "}
              of <span className="font-medium">{VendorData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <DraggableVendorTable
            isLoading={isLoading}
            columns={columns2}
            VendorData={VendorData}
            getData={getData}
            data={VendorData.slice(indexOfFirstItem, indexOfLastItem)}
            setVendorData={setVendorData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsVendor={tableColumnsVendor}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>

        <>
          {
            <div>
              {isOpenMassupdate && (
                <>
                <div style={backdropStyle}></div>
                <div className={`MassUpdateModal ${scrollBehavior}`}>
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 500,
                      // height: 200
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Mass Update
                    </header>
                    <div
                      onClick={() => {
                        onCloseMassupdate(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />
                    <FormControl>
                      <Flex>
                        <Select
                          mt={7}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          value={selectedOption}
                          onChange={(e) => handleOptionChange(e.target.value)}
                        >
                          <option value="Select a field">Select a field</option>
                          <option value="vendorId">VendorId</option>
                        <option value="VendorNumber">VendorNumber</option>
                        <option value="Company">Company</option>
                        <option value="vendorType">vendorType</option>
                        <option value="ContactPerson">ContactPerson</option>
                        <option value="Specialization">Specialization</option>
                        <option value="Designation">Designation</option>
                        <option value="Email">Email</option>
                        <option value="DialCode">DialCode</option>
                        <option value="Phone">Phone</option>
                        <option value="City">City</option>
                        <option value="Country">Country</option>
                        <option value="VendorOwner">VendorOwner</option>
                        <option value="Remarks">Remarks</option>
                        </Select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedOption === "vendor" ? (
                          <Input
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            type="text"
                            name={selectedOption}
                            id="updateField"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          />
                        ) : (
                          <Input
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            type="text"
                            name={selectedOption}
                            id="updateField"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            disabled={selectedOption === "Select a field"}
                          />
                        )}
                      </Flex>
                    </FormControl>
                    <br />
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        onOpenReportMassupdate();
                        onCloseMassupdate();
                      }}
                      mr={3}
                    >
                      Submit
                    </Button>
                  </Rnd>
                </div>
             </>
             )}
            </div>
          }
        </>
      </div>
      <>
        {isOpenReportMassUpload && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUploadModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Mass Upload Vendor
              </header>
              <div
                onClick={() => {
                  onCloseReportMassUpload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Mass Upload the Vendor?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassuploadVendor();
                  onCloseReportMassUpload();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassUpload();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}{" "}
      </>
      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Vendor
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to delete the Vendor?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitDelete();
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Delete
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportDelete();
                }}
                mr={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>

      <>
        {isOpenReportMassupdate && (
          <>
          <div style={backdropStyle}></div>
          <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Update Vendor
              </header>
              <div
                onClick={() => {
                  onCloseReportMassupdate(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Update the Vendor?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitUpdate();
                  onCloseReportMassupdate();
                }}
              >
                Update
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassupdate();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenDownload && (
          <>
          <div style={backdropStyle}></div>
          <div className={`DownloadExcelModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
                  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
                  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                    <option value="Text">Text</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleExport();
                }}
                disabled={isDownloading}
                mr={3}
              >
                {isDownloading ? "Downloading..." : "Export Data"}
              </Button>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isCopyOpen && (
          <>
          <div style={backdropStyle}></div>
          <div className={`singleCopyModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 310,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Copy Vendor Information
              </header>
              <div
                onClick={() => {
                  handleCopyCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedVendor && Object.keys(selectedVendor).length > 0 && (
                <form>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4} mt={10}>
                    <GridItem colSpan={2}>
                      <FormControl>
                        
                        <FormLabel>Company</FormLabel>
                        <Input
                          type="text"
                          name="Company"
                          defaultValue={
                            formData["Company"] ||
                            selectedVendor["Company"]
                          }
                          value={copiedVendor["Company"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>Designation</FormLabel>
                        <Input
                          type="text"
                          name="Designation"
                          defaultValue={
                            formData["Designation"] || selectedVendor["Designation"]
                          }
                          value={copiedVendor["Designation"] || ""}
                          onChange={handleCopyChange}
                        />
                         <FormLabel>Email</FormLabel>
                        <Input
                          type="text"
                          name="Email"
                          defaultValue={
                            formData["Email"] || selectedVendor["Email"]
                          }
                          value={copiedVendor["Email"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>DialCode</FormLabel>
                        <Input
                          type="text"
                          name="DialCode"
                          defaultValue={
                            formData["DialCode"] ||
                            selectedVendor["DialCode"]
                          }
                          value={copiedVendor["DialCode"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>VendorOwner</FormLabel>
                        <Input
                          type="text"
                          name="VendorOwner"
                          defaultValue={
                            formData["VendorOwner"] ||
                            selectedVendor["VendorOwner"]
                          }
                          value={copiedVendor["VendorOwner"] || ""}
                          onChange={handleCopyChange}
                        />
                         <FormLabel>Remarks</FormLabel>
                        <Input
                          type="text"
                          name="Remarks"
                          defaultValue={
                            formData["Remarks"] ||
                            selectedVendor["Remarks"]
                          }
                          value={copiedVendor["Remarks"] || ""}
                          onChange={handleCopyChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>VendorType</FormLabel>
                        <Input
                          type="text"
                          name="vendorType"
                          defaultValue={
                            formData["vendorType"] || selectedVendor["vendorType"]
                          }
                          value={copiedVendor["vendorType"] || ""}
                          onChange={handleCopyChange}
                        />
                         <FormLabel>ContactPerson</FormLabel>
                        <Input
                          type="text"
                          name="ContactPerson"
                          defaultValue={
                            formData["ContactPerson"] || selectedVendor["ContactPerson"]
                          }
                          value={copiedVendor["ContactPerson"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>Specialization</FormLabel>
                        <Input
                          type="text"
                          name="Specialization"
                          defaultValue={
                            formData["Specialization"] ||
                            selectedVendor["Specialization"]
                          }
                          value={copiedVendor["Specialization"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>Phone</FormLabel>
                        <Input
                          type="text"
                          name="Phone"
                          defaultValue={
                            formData["Phone"] || selectedVendor["Phone"]
                          }
                          value={copiedVendor["Phone"] || ""}
                          onChange={handleCopyChange}
                        />
                         <FormLabel>City</FormLabel>
                        <Input
                          type="text"
                          name="City"
                          defaultValue={
                            formData["City"] || selectedVendor["City"]
                          }
                          value={copiedVendor["City"] || ""}
                          onChange={handleCopyChange}
                        />
                        <FormLabel>Country</FormLabel>
                        <Input
                          type="text"
                          name="Country"
                          defaultValue={
                            formData["Country"] ||
                            selectedVendor["Country"]
                          }
                          value={copiedVendor["Country"] || ""}
                          onChange={handleCopyChange}
                        />
                        
                       
                      </FormControl>
                    </GridItem>
                   
                   
                  </Grid>
                  {!selectedVendor && (
                    <p>Data not available for the selected Vendor.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCopyCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitCopy();
                    handleCopyCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isEditOpen && (
          <>
          <div style={backdropStyle}></div>
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 310,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Vendor Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedVendor && Object.keys(selectedVendor).length > 0 && (
                <form>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4} mt={10}>
                  <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>VendorId</FormLabel>
                        <Input
                          type="text"
                          name="vendorId"
                          defaultValue={
                            formData["vendorId"] || selectedVendor["vendorId"]
                          }
                          value={editedVendor["vendorId"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>VendorNumber</FormLabel>
                        <Input
                          type="text"
                          name="VendorNumber"
                          defaultValue={
                            formData["VenderNumber"] || selectedVendor["VendorNumber"]
                          }
                          value={editedVendor["VendorNumber"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Company</FormLabel>
                        <Input
                          type="text"
                          name="Company"
                          defaultValue={
                            formData["Company"] ||
                            selectedVendor["Company"]
                          }
                          value={editedVendor["Company"] || ""}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>VendorType</FormLabel>
                        <Input
                          type="text"
                          name="vendorType"
                          defaultValue={
                            formData["vendorType"] || selectedVendor["vendorType"]
                          }
                          value={editedVendor["vendorType"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>ContactPerson</FormLabel>
                        <Input
                          type="text"
                          name="ContactPerson"
                          defaultValue={
                            formData["ContactPerson"] || selectedVendor["ContactPerson"]
                          }
                          value={editedVendor["ContactPerson"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Specialization</FormLabel>
                        <Input
                          type="text"
                          name="Specialization"
                          defaultValue={
                            formData["Specialization"] ||
                            selectedVendor["Specialization"]
                          }
                          value={editedVendor["Specialization"] || ""}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>Designation</FormLabel>
                        <Input
                          type="text"
                          name="Designation"
                          defaultValue={
                            formData["Designation"] || selectedVendor["Designation"]
                          }
                          value={editedVendor["Designation"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>Email</FormLabel>
                        <Input
                          type="text"
                          name="Email"
                          defaultValue={
                            formData["Email"] || selectedVendor["Email"]
                          }
                          value={editedVendor["Email"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>DialCode</FormLabel>
                        <Input
                          type="text"
                          name="DialCode"
                          defaultValue={
                            formData["DialCode"] ||
                            selectedVendor["DialCode"]
                          }
                          value={editedVendor["DialCode"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>VendorOwner</FormLabel>
                        <Input
                          type="text"
                          name="VendorOwner"
                          defaultValue={
                            formData["VendorOwner"] ||
                            selectedVendor["VendorOwner"]
                          }
                          value={editedVendor["VendorOwner"] || ""}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>Phone</FormLabel>
                        <Input
                          type="text"
                          name="Phone"
                          defaultValue={
                            formData["Phone"] || selectedVendor["Phone"]
                          }
                          value={editedVendor["Phone"] || ""}
                          onChange={handleChange}
                        />
                         <FormLabel>City</FormLabel>
                        <Input
                          type="text"
                          name="City"
                          defaultValue={
                            formData["City"] || selectedVendor["City"]
                          }
                          value={editedVendor["City"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Country</FormLabel>
                        <Input
                          type="text"
                          name="Country"
                          defaultValue={
                            formData["Country"] ||
                            selectedVendor["Country"]
                          }
                          value={editedVendor["Country"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Remarks</FormLabel>
                        <Input
                          type="text"
                          name="Remarks"
                          defaultValue={
                            formData["Remarks"] ||
                            selectedVendor["Remarks"]
                          }
                          value={editedVendor["Remarks"] || ""}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {!selectedVendor && (
                    <p>Data not available for the selected Vendor.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSingleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
          </>
        )}
      </>
    </>
  );
};
export default Vendor;
