import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios, {
  MasterDataAPIInstance,
  OperationDocumentAPIInstance,
} from "../../../api-instance";
import { Textarea, useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./HDocreation.css";
import { GiCancel } from "react-icons/gi";
import { Button } from "antd";
import {
  Select,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { Header } from "../../../components";

const AddHdoCreation = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [formDataIndex, setFormDataIndex] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [descriptionType, setDescriptionType] = useState("Single");
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");

  const location = useLocation();
  const { selectedJob } = location.state || {};
  console.log("hdosss:", selectedJob);

  const [freightData, setfreightData] = useState({});
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await OperationDocumentAPIInstance.get(
          `get_hdofreight?BookingNumber=${selectedJob.BookingNumber}&JobNumber=${selectedJob.JobNumber}`
        );
        if (data.data.length === 0) {
          toast({
            title: "No freight data found.",
            position: "bottom-center",
            isClosable: true,
            status: "warning",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        } else {
          setfreightData(data.data[0]);
        }
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [selectedJob]);
  console.log("HDO Document", freightData);

  const intial_MBLNumber = freightData.MBLNumber;
  const intial_HBLNumber = freightData.HBLNumber;
  const inital_ContainerNumber = freightData.ContainerNumber;
  console.log(
    "initial",
    intial_HBLNumber,
    intial_MBLNumber,
    inital_ContainerNumber
  );
  console.log("Job Select", selectedJob.BookingNumber);
  const [editedHdoData, setEditedHdoData] = useState({});
  const [selectedHdoData, setSelectedHdoData] = useState({});

  useEffect(() => {
    if (selectedJob) {
      setSelectedHdoData({
        BookingNumber: selectedJob.BookingNumber || "",
        JobNumber: selectedJob.JobNumber || "",
        HdoNumber: "",
        to_: "",
        MBLNumber: intial_MBLNumber || "",
        HBLNumber: intial_HBLNumber || "",
        DescriptionOfGoods: "",
        ReleasePartyName: "",
        CHA: "",
        Date: "",
        Name: "",
        Designation: "",
        ContainerNumber: inital_ContainerNumber || "",
        Phone: "",
        Place: "",
        LineNumber: "",
        IGMNumber: "",
        JobInfoNo: selectedJob.JobInfono || "",
        HDOOwner: JSON.parse(localStorage.getItem("user")).userId,
      });
    }
  }, [selectedJob, intial_MBLNumber, intial_HBLNumber, inital_ContainerNumber]);

  console.log("selectedHDO", selectedHdoData);
  const [formData, setFormData] = useState(selectedHdoData);

  useEffect(() => {
    // Update formData with editedHdoData
    setFormData(editedHdoData);
  }, [editedHdoData, descriptionType]);

  useEffect(() => {
    setEditedHdoData(selectedHdoData);
  }, [selectedHdoData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedHdoData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await OperationDocumentAPIInstance.post(
      `add_hdo?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/opertion_docs", { state: { selectedJob: selectedJob } });
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleOperationChange = (index, key, value) => {
    const updatedOperations = [...editedHdoData.Operations];
    updatedOperations[index][key] = value;
    setEditedHdoData((prevState) => ({
      ...prevState,
      Operations: updatedOperations,
    }));
  };
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await OperationDocumentAPIInstance.post(
        `add_hdocreation?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <form onKeyDown={handleKeyDown}>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">HDO Creation</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>

          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/opertion_docs", {
                state: { selectedJob: selectedJob },
              });
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">HDO Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-hdocreations">
                <div className="input-box" hidden>
                  <span className="hdocreations">
                    HDONumber
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HdoNumber"
                    defaultValue={
                      formData["HdoNumber"] || selectedHdoData["HdoNumber"]
                    }
                    value={editedHdoData["HdoNumber"] || ""}
                    placeholder="Enter your HDONumber"
                    // disabled
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">
                    To
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="to_"
                    defaultValue={formData["to_"] || selectedHdoData["to_"]}
                    value={editedHdoData["to_"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your To"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">
                    ReleasePartyName
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ReleasePartyName"
                    defaultValue={
                      formData["ReleasePartyName"] ||
                      selectedHdoData["ReleasePartyName"]
                    }
                    value={editedHdoData["ReleasePartyName"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ReleasePartyName"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">
                    MBLNumber
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="MBLNumber"
                    defaultValue={
                      formData["MBLNumber"] || selectedHdoData["MBLNumber"]
                    }
                    value={editedHdoData["MBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your MBLNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">
                    CHA
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="CHA"
                    defaultValue={formData["CHA"] || selectedHdoData["CHA"]}
                    value={editedHdoData["CHA"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your CHA"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">
                    HBLNumber
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="HBLNumber"
                    defaultValue={
                      formData["HBLNumber"] || selectedHdoData["HBLNumber"]
                    }
                    value={editedHdoData["HBLNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your HBLNumber"
                  />
                </div>

                <div className="input-box">
                  <span className="hdocreations">
                    Name
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Name"
                    defaultValue={
                      formData["Name"] || selectedHdoData["Name"]
                    }
                    value={editedHdoData["Name"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Name"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">ContainerNumber</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContainerNumber"
                    defaultValue={
                      formData["ContainerNumber"] ||
                      selectedHdoData["ContainerNumber"]
                    }
                    value={editedHdoData["ContainerNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your ContainerNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">Designation</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Designation"
                    defaultValue={
                      formData["Designation"] || selectedHdoData["Designation"]
                    }
                    value={editedHdoData["Designation"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Designation"
                  />
                </div>

                <div className="input-box">
                  <span className="hdocreations">DescriptionOfGoods</span>
                  <Textarea
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="DescriptionOfGoods"
                    defaultValue={
                      formData["DescriptionOfGoods"] ||
                      selectedHdoData["DescriptionOfGoods"]
                    }
                    value={editedHdoData["DescriptionOfGoods"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your DescriptionOfGoods"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">Mobile</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Phone"
                    defaultValue={formData["Phone"] || selectedHdoData["Phone"]}
                    value={editedHdoData["Phone"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your Phone"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">LineNumber</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="LineNumber"
                    defaultValue={
                      formData["LineNumber"] || selectedHdoData["LineNumber"]
                    }
                    value={editedHdoData["LineNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter your LineNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">Place</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Place"
                    defaultValue={formData["Place"] || selectedHdoData["Place"]}
                    value={editedHdoData["Place"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Place"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">IGMNumber</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="IGMNumber"
                    defaultValue={
                      formData["IGMNumber"] || selectedHdoData["IGMNumber"]
                    }
                    value={editedHdoData["IGMNumber"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the IGMNumber"
                  />
                </div>
                <div className="input-box">
                  <span className="hdocreations">Date</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="Date"
                    defaultValue={formData["Date"] || selectedHdoData["Date"]}
                    value={editedHdoData["Date"] || ""}
                    onChange={handleChange}
                    placeholder="Enter the Date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddHdoCreation;
