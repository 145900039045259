import { PrimaryButton } from "../../../components";

export default function SideBard({ selected, setSelected }) {
const buttons = [ "Jobs", "Booking"];

  // return (
  //   // <div className=" w-64 flex flex-col bg-blue-100 gap-4 py-10 px-5 border-r-2 border-r-bg-cyan-light-primary">
  //   //   <div className="text-black-primary text-heading1 font-semibold">
  //   //     Dashboard
  //   //   </div>
  //   //   <hr className="border-t-2 bg-black-primary bg-opacity-30" />
  //   //   {buttons.map((button, index) => (
  //   //     <PrimaryButton
  //   //       key={index}
  //   //       selected={selected === button}
  //   //       onClick={() => setSelected(button)}
          
  //   //     >
  //   //       {button} 
  //   //     </PrimaryButton>
  //   //   ))}
  //   // </div>
  // );
}


// import { PrimaryButton } from "../../../components";

// export default function SideBard({ selected, setSelected }) {
//   const buttons = [ "Booking", "Jobs"];

//   return (
//     <div className="w-64 flex flex-col bg-white-half gap-4 py-10 px-5 border-r-2 border-r-bg-cyan-light-primary">
//       <div className="text-black-primary text-heading1 font-semibold">
//         Dashboard
//       </div>
//       <hr className="border-t-2 bg-black-primary bg-opacity-30" />
//       {buttons.map((button, index) => (
//         <PrimaryButton
//           key={index}
//           selected={selected === button}
//           onClick={() => setSelected(button)}
//         >
//           {button}
//         </PrimaryButton>
//       ))}
//     </div>
//   );
// }
