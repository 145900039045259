import { React, useState, useEffect } from "react";
import "./Addbooking.css";
import {
  Button,
  Input,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
} from "@chakra-ui/react";
import { useToast, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import Axios, {
  BookingAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import SearchSelect from "../../components/Select/select";
import { Header } from "../../components";
import TextArea from "antd/es/input/TextArea";
const Createbooking = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [incotermData, setIncotermData] = useState([]);
  const [scopeData, setScopeData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const fullName = user.firstname + " " + user.lastname;
  const regionCode = user.regions[0].regionCode;
  const initialRegionCode =
    user.regions.length > 0 ? user.regions[0].regionCode : "";
  console.log(fullName);
  const [errors, setErrors] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index

  // Define required fields for each tab
  const requiredFields = {
    0: ["BookingType", "Product", "Cargo", "Sales", "POL", "POD"], // Required fields for Job Information tab
    1: ["CompanyName"], // Example fields for Cargo Details tab
    2: ["VesselNameVoyage", "ETA", "ETD"], // Example fields for Liner Details tab
  };
  const [formData, setFormData] = useState({
    BookingType: "",
    BookingNumber: "",
    Product: "",
    PlaceOfReceipt: "",
    POL: "",
    POD: "",
    PlaceOfDelivery: "",
    Cargo: "",
    CargoNature: "",
    Incoterm: "",
    Sales: "",
    Scope: "",
    CompanyName: "",
    Address: "",
    PIC: "",
    LinerRefNumber: "",
    SICutOff: "",
    VesselNameVoyage: "",
    VGMCutOff: "",
    ETD: "",
    ETA: "",
    ETAPOL: "",
    ETDOrigin: "",
    ETDDestination: "",
    CYCutOff: "",
    EmptyPickupLocation: "",
    Region: "",
    Remarks: "",
    CreatedBy: fullName,
    regionCode: initialRegionCode,
    BookingOwner: JSON.parse(localStorage.getItem("user")).userId,
    bookingContainers: [],
    termsandconditions: [],
    sailingVesselDetails: [],
  });

  const [bookingContainers, setBookingContainers] = useState([
    {
      ContainerType: "",
      Quantity: "",
      Weight: "",
      Dimensions: "",
    },
  ]);

  // Terms and Conditions

  const [termsandconditions, setTermsandconditions] = useState([
    { TermsAndConditions: "" },
  ]);


  const removeTermsandconditions = (indexToRemove) => {
    setTermsandconditions((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  const addTermsandcondition = () => {
    setTermsandconditions((prev) => [...prev, { TermsAndConditions: "" }]);
  };

  const handleTermsandConditionChange = (index, fieldName, value) => {
    setTermsandconditions((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, [fieldName]: value } : item
      )
    );
  };

  // Vessel Details

  const [sailingVesselDetails, setSailingVesselDetails] = useState([
    {
      VesselType : "",
      Vessel : "",
      Voyage : "",
      PolEta : "",
      PortOfLoading : "",
      ETD : "",
      PortOfDischarge : "",
      ETA : ""
    }
  ])

  const removeSailingVesselDetails = (indexToRemove) => {
    setSailingVesselDetails((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  
  const addSailingVesselDetails = () => {
    setSailingVesselDetails((prev) => [...prev,  {
      VesselType : "",
      Vessel : "",
      Voyage : "",
      PolEta : "",
      PortOfLoading : "",
      ETD : "",
      PortOfDischarge : "",
      ETA : ""
    }]);
  };

  const handleSailingVesselDetails = (index, fieldName, value) => {
    setSailingVesselDetails((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, [fieldName]: value } : item
      )
    );
  };

  // const [bookingTermsandconditions, setBookingTermsandConditions] = useState([
  //   {
  //     TermsAndConditions: "",
  //   },
  // ]);

  const [containers, setContainers] = useState([{}]);
  // const [termsandconditions, setTermsandconditions] = useState([{}]);

  const addContainer = () => {
    setContainers([...containers, {}]);
    setBookingContainers([
      ...bookingContainers,
      {
        ContainerType: "",
        Quantity: "",
        Weight: "",
        Dimensions: "",
      },
    ]);
  };

  // const addTermsandcondition = () => {
  //   setTermsandconditions([...termsandconditions, {}]);
  //   setBookingTermsandConditions([
  //     ...bookingTermsandconditions,
  //     {
  //       TermsAndConditions: "",
  //     },
  //   ]);
  // };

  const removeContainer = (indexToRemove) => {
    setContainers(containers.filter((_, index) => index !== indexToRemove));
    setBookingContainers(
      bookingContainers.filter((_, index) => index !== indexToRemove)
    );
  };

  // const removeTermsandconditions = (indexToRemove) => {
  //   setTermsandconditions(termsandconditions.filter((_, index) => index !== indexToRemove));
  //   setBookingTermsandConditions(
  //     bookingTermsandconditions.filter((_, index) => index !== indexToRemove)
  //   );
  // };

  const handleContainerChange = (index, fieldName, value) => {
    const updatedContainers = [...containers];
    updatedContainers[index] = {
      ...updatedContainers[index],
      [fieldName]: value,
    };
    setContainers(updatedContainers);

    const updatedBookingContainers = [...bookingContainers];
    updatedBookingContainers[index] = {
      ...updatedBookingContainers[index],
      [fieldName]: value,
    };
    setBookingContainers(updatedBookingContainers);
  };

  // const handleTermsandConditionChange = (index, fieldName, value) => {
  //   const updatedTermsandcondition = [...termsandconditions];
  //   updatedTermsandcondition[index] = {
  //     ...updatedTermsandcondition[index],
  //     [fieldName]: value,
  //   };
  //   setTermsandconditions(updatedTermsandcondition);

  //   const updatedBookingTermsandcondition = [...bookingTermsandconditions];
  //   updatedBookingTermsandcondition[index] = {
  //     ...updatedBookingTermsandcondition[index],
  //     [fieldName]: value,
  //   };
  //   setBookingTermsandConditions(updatedBookingTermsandcondition);
  // };

  // Product API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Equipment API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Incoterm API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_incoterm");
        setIncotermData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Scope API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_scope");
        setScopeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Region API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Port API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  //Sales Person API
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_salesperson");
        setSalesPersonData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  // Customer API
  const [filteredPIC, setFilteredPIC] = useState([]);
  const [filteredAddress, setFilteredAddress] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_customer");
        setCustomerData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (formData.CompanyName) {
      const filteredPIC = customerData.filter(
        (item) => item.Company === formData.CompanyName
      );
      setFilteredPIC(filteredPIC);
    }
  }, [formData.CompanyName, customerData]);

  useEffect(() => {
    if (formData.CompanyName) {
      const filteredAddress = customerData.find(
        (item) => item.Company === formData.CompanyName
      );
      setFilteredAddress(
        filteredAddress
          ? [
              {
                value: `${filteredAddress.Address_Line_1}, ${filteredAddress.Address_Line_2}, ${filteredAddress.Address_Line_3}, ${filteredAddress.City}, ${filteredAddress.State}, ${filteredAddress.Country} - ${filteredAddress.Pincode}`,
                label: `${filteredAddress.Address_Line_1}, ${filteredAddress.Address_Line_2}, ${filteredAddress.Address_Line_3}, ${filteredAddress.City}, ${filteredAddress.State}, ${filteredAddress.Country} - ${filteredAddress.Pincode}`,
                name: "Address",
              },
            ]
          : []
      );
    }
  }, [formData.CompanyName, customerData]);

  const handleChange = (e, index) => {
    if (
      e?.name === "POL" ||
      e?.name === "POD" ||
      e?.name === "Sales" ||
      e?.name === "CompanyName" ||
      e?.name === "PIC" ||
      e?.name === "Address"
    ) {
      const { name, value } = e;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      return;
    }

    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Remove error for the field if it's been corrected
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Delete the error for the specific field
        return updatedErrors;
      });
    }

    console.log("Pol Data", setFormData);
  };

  const validateAllTabs = () => {
    let tabErrors = {};
    Object.keys(requiredFields).forEach((tabIndex) => {
      requiredFields[tabIndex].forEach((field) => {
        if (!formData[field]) {
          tabErrors[field] = `${field} is required`;
        }
      });
    });
    return tabErrors;
  };

  const getFirstErrorTabIndex = (tabErrors) => {
    // Find the first tab with a required field missing
    for (let tabIndex in requiredFields) {
      if (requiredFields[tabIndex].some((field) => tabErrors[field])) {
        return parseInt(tabIndex);
      }
    }
    return 0; // Default to the first tab if no errors are found
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    formData.bookingContainers = bookingContainers;
    formData.termsandconditions = termsandconditions;
    formData.sailingVesselDetails = sailingVesselDetails;
    event.preventDefault();
    console.log(bookingContainers, "Conatiner");
    const tabErrors = validateAllTabs();

    if (Object.keys(tabErrors).length) {
      setErrors(tabErrors);
      setActiveTabIndex(getFirstErrorTabIndex(tabErrors)); // Activate the first tab with errors

      // toast({
      //   title: "Please fill in the required fields",
      //   position: "bottom-center",
      //   status: "error",
      //   isClosable: true,
      //   containerStyle: {
      //     width: "98.5vw",
      //     maxWidth: "98.5vw",
      //     alignContent: "center",
      //   },
      // });
      return;
    }
    const { data } = await BookingAPIInstance.post(
      `create_booking?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/booking");
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      const tabErrors = validateAllTabs();

      if (Object.keys(tabErrors).length) {
        setErrors(tabErrors);
        setActiveTabIndex(getFirstErrorTabIndex(tabErrors)); // Activate the first tab with errors

        // toast({
        //   title: "Please fill in the required fields",
        //   position: "bottom-center",
        //   status: "error",
        //   isClosable: true,
        //   containerStyle: {
        //     width: "98.5vw",
        //     maxWidth: "98.5vw",
        //     alignContent: "center",
        //   },
        // });
        return;
      }

      console.log(bookingContainers, "conatiner");
      formData.bookingContainers = bookingContainers;
      formData.termsandconditions = termsandconditions;
      formData.sailingVesselDetails = sailingVesselDetails;
      const { data } = await BookingAPIInstance.post(
        `create_booking?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const {
    isOpen: isOpenCancelBooking,
    onOpen: onOpenCancelBooking,
    onClose: onCloseCancelBooking,
  } = useDisclosure();

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10,
  };

  const style = {
    border: "1px solid #ddd",
    backgroundColor: "white",
    zIndex: 20,
  };

  const closeModal = {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  };

  const handleConfirmCancel = () => {
    navigate("/booking");
    onCloseCancelBooking(); // Close the modal after navigating
  };

  return (
    <div>
      <Header />
      <div>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Booking</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <div
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950 cursor-pointer"
            // onClick={() => {
            //   navigate("/booking");
            // }}
            onClick={onOpenCancelBooking}
            disabled={!submitAllowed}
          >
            Cancel
          </div>
        </div>

        <Tabs
          index={activeTabIndex}
          onChange={setActiveTabIndex}
          w="100%"
          variant="enclosed"
        >
          &nbsp;
          <TabList>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Booking Information
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Customer Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Sailing Details
            </Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>Container</Tab>
            <Tab _selected={{ color: "white", bg: "#172554" }}>
              Terms & Conditions
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Booking Infromation */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="job">Select Region</span>
                      {user.regions.length > 1 ? (
                        <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={formData.regionCode}
                          onChange={handleChange}
                        >
                          {user.regions.map((region, index) => (
                            <option key={index} value={region.regionCode}>
                              {region.user_region} - {region.regionCode}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          name="regionCode"
                          value={formData.regionCode}
                          readOnly
                        />
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Booking Type</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="BookingType"
                        value={formData.BookingType}
                        onChange={handleChange}
                      >
                        <option>Select Booking Type</option>
                        <option value="Original">Original</option>
                        <option value="Dummy">Dummy</option>
                      </select>
                      {errors.BookingType && (
                        <p style={{ color: "red" }}>{errors.BookingType}</p>
                      )}
                    </div>

                    <div className="input-box" hidden>
                      <span className="booking">Booking Number</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="BookingNumber"
                        value={formData.BookingNumber}
                        onChange={handleChange}
                        placeholder="Enter your booking number"
                        hidden
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Product<span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Product"
                        value={formData.Product}
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Product</option>
                        {productData.map((item) => (
                          <option value={item.Product}>{item.Product}</option>
                        ))}
                      </select>
                      {errors.Product && (
                        <p style={{ color: "red" }}>{errors.Product}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Place of Receipt</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfReceipt"
                        value={formData.PlaceOfReceipt}
                        onChange={handleChange}
                        placeholder="Enter your place of receipt"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        POL/RAMP <span style={{ color: "red" }}>*</span>
                      </span>
                      {/* <input
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Pol"
                          value={formData.Pol}
                          onChange={handleChange}
                          placeholder="Enter your pol"
                        /> */}
                      {/* <select
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          type="text"
                          name="Pol"
                          value={formData.Pol}
                          onChange={handleChange}
                          placeholder="Enter your pol"
                        >
                          <option>Select Pol</option>
                          {portData.map((item) => (
                            <option value={item.Port}>{item.Port}</option>
                          ))}
                        </select> */}
                      <br />
                      <SearchSelect
                        name="POL"
                        value={formData.POL}
                        handleChange={handleChange}
                        placeholder="Select Pol"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POL",
                          };
                        })}
                      />
                      {errors.POL && (
                        <p style={{ color: "red" }}>{errors.POL}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        POD/RAMP <span style={{ color: "red" }}>*</span>
                      </span>
                      <SearchSelect
                        name="POD"
                        value={formData.POD}
                        handleChange={handleChange}
                        placeholder="Select Pod"
                        colourOptions={portData.map((item) => {
                          return {
                            value: item.Port,
                            label: item.Port,
                            name: "POD",
                          };
                        })}
                      />
                      {errors.POD && (
                        <p style={{ color: "red" }}>{errors.POD}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Place Of Delivery</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="PlaceOfDelivery"
                        value={formData.PlaceOfDelivery}
                        onChange={handleChange}
                        placeholder="Enter your place of delivery"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Cargo<span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Cargo"
                        value={formData.Cargo}
                        onChange={handleChange}
                        placeholder="Enter your cargo"
                      />
                      {errors.Cargo && (
                        <p style={{ color: "red" }}>{errors.Cargo}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Incoterm</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Incoterm"
                        value={formData.Incoterm}
                        onChange={handleChange}
                        placeholder="Enter your Product"
                      >
                        <option>Select Incoterm</option>
                        {incotermData.map((item) => (
                          <option value={item.Incoterm}>{item.Incoterm}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">Scope</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Scope"
                        value={formData.Scope}
                        onChange={handleChange}
                        placeholder="Enter your Scope"
                      >
                        <option>Select Scope</option>
                        {scopeData.map((item) => (
                          <option value={item.Scope}>{item.Scope}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Sales<span style={{ color: "red" }}>*</span>
                      </span>
                      <SearchSelect
                        name="Sales"
                        value={formData.Sales}
                        handleChange={handleChange}
                        placeholder="Select Sales"
                        colourOptions={salesPersonData.map((item) => {
                          return {
                            value: item.fullname,
                            label: item.fullname,
                            name: "Sales",
                          };
                        })}
                      />
                      {errors.Sales && (
                        <p style={{ color: "red" }}>{errors.Sales}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Cargo Nature</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="CargoNature"
                        value={formData.CargoNature}
                        onChange={handleChange}
                        placeholder="Enter your cargo nature"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Booking info */}
            </TabPanel>

            <TabPanel>
              {/* Customer details */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="booking">
                        Company Name<span style={{ color: "red" }}>*</span>
                      </span>

                      <SearchSelect
                        name="CompanyName"
                        value={formData.CompanyName}
                        handleChange={handleChange}
                        placeholder="Select Company Name"
                        colourOptions={customerData.map((item) => {
                          return {
                            value: item.Company,
                            label: item.Company,
                            name: "CompanyName",
                          };
                        })}
                      />
                      {errors.CompanyName && (
                        <p style={{ color: "red" }}>{errors.CompanyName}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Person Incharge</span>

                      {/* <SearchSelect
                        name="PIC"
                        value={formData.PIC}
                        handleChange={handleChange}
                        placeholder="Select PIC"
                        colourOptions={CustomerData.map((item) => {
                          return {
                            value: item.Name,
                            label: item.Name,
                            name: "PIC",
                          };
                        })}
                      /> */}
                      <SearchSelect
                        name="PIC"
                        value={formData.PIC}
                        handleChange={handleChange}
                        placeholder="Select PIC"
                        colourOptions={filteredPIC.map((item) => {
                          return {
                            value: item.Name,
                            label: item.Name,
                            name: "PIC",
                          };
                        })}
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Address</span>

                      {/* <SearchSelect
                        name="Address"
                        value={formData.Address}
                        handleChange={handleChange}
                        placeholder="Select Address"
                        colourOptions={CustomerData.map((item) => {
                          return {
                            value: `${item.Address_Line_1}, ${item.Address_Line_2}, ${item.Address_Line_3}, ${item.City}, ${item.State}, ${item.Country} - ${item.Pincode}`,
                            label: `${item.Address_Line_1}, ${item.Address_Line_2}, ${item.Address_Line_3}, ${item.City}, ${item.State}, ${item.Country} - ${item.Pincode}`,
                            name: "Address",
                          };
                        })}
                      /> */}
                      <SearchSelect
                        name="Address"
                        value={formData.Address}
                        handleChange={handleChange}
                        placeholder="Select Address"
                        colourOptions={filteredAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Customer */}
            </TabPanel>

            <TabPanel>
              {/* Sailing details */}
              <div className="content">
                <div className="form-user">
                  <div className="user-booking">
                    <div className="input-box">
                      <span className="booking">Liner REF No</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="LinerRefNumber"
                        value={formData.LinerRefNumber}
                        onChange={handleChange}
                        placeholder="Enter your liner ref no"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Vessel Name/Voyage
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="VesselNameVoyage"
                        value={formData.VesselNameVoyage}
                        onChange={handleChange}
                        placeholder="Enter your vessel voy"
                      />
                      {errors.VesselNameVoyage && (
                        <p style={{ color: "red" }}>
                          {errors.VesselNameVoyage}
                        </p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">Region</span>
                      <select
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Region"
                        value={formData.Region}
                        onChange={handleChange}
                        placeholder="Enter your Region"
                      >
                        <option>Select Internal Region</option>
                        {regionData.map((item) => (
                          <option key={item.Region} value={item.Region}>
                            {item.Region}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Estimated time of arrival(ETA)
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETA"
                        value={formData.ETA ? formData.ETA.split("T")[0] : ""}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your eta"
                      />
                      {errors.ETA && (
                        <p style={{ color: "red" }}>{errors.ETA}</p>
                      )}
                    </div>

                    <div className="input-box">
                      <span className="booking">
                        Estimated time of departure(ETD)
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETD"
                        value={formData.ETD ? formData.ETD.split("T")[0] : ""}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                        placeholder="Enter your etd"
                      />
                      {errors.ETD && (
                        <p style={{ color: "red" }}>{errors.ETD}</p>
                      )}
                    </div>

                    {/* <div className="input-box">
                      <span className="booking">ETA POL</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETAPOL"
                        value={formData.ETAPOL}
                        onChange={handleChange}
                        placeholder="Enter your eta pol"
                      />
                    </div> */}

                    {/* <div className="input-box">
                      <span className="booking">ETD Origin</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETDOrigin"
                        value={
                          formData.ETDOrigin
                            ? formData.ETDOrigin.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your etd origin"
                      />
                    </div> */}

                    <div className="input-box">
                      <span className="booking">ETA Destination</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="ETDDestination"
                        value={
                          formData.ETDDestination
                            ? formData.ETDDestination.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your eta designation"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Empty Pick Up Location</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="EmptyPickupLocation"
                        value={formData.EmptyPickupLocation}
                        onChange={handleChange}
                        placeholder="Enter your empty pickup location"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">SI Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="SICutOff"
                        value={
                          formData.SICutOff
                            ? formData.SICutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your si cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">VGM Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="VGMCutOff"
                        value={
                          formData.VGMCutOff
                            ? formData.VGMCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your vgm cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">CY Cut Off</span>
                      <input
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="date"
                        name="CYCutOff"
                        value={
                          formData.CYCutOff
                            ? formData.CYCutOff.split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        placeholder="Enter your cy cut off"
                      />
                    </div>

                    <div className="input-box">
                      <span className="booking">Remarks</span>
                      <textarea
                        className="text-black bg-white dark:bg-gray-950 dark:text-white"
                        type="text"
                        name="Remarks"
                        value={formData.Remarks}
                        onChange={handleChange}
                        placeholder="Enter your remarks"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Sailing */}
              {/* Vessel Details */}
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Vessel
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Voyage
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        POL ETA
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Port of Loading
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        ETD
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                      Port of Discharge
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                     ETA
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sailingVesselDetails.map((sailingVesselDetails, index) => (
                      <tr key={index}>
                        <td>
                        <Select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            width=""
                            type="text"
                            name="VesselType"
                            value={sailingVesselDetails.VesselType || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "VesselType",
                                e.target.value
                              )
                            }
                          >
                            <option>Type &nbsp;&nbsp;&nbsp;</option>
                            <option>Feeder</option>
                            <option>Mother</option>
                          </Select>
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Vessel"
                            value={sailingVesselDetails.Vessel || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "Vessel",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Voyage"
                            value={sailingVesselDetails.Voyage || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "Voyage",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="date"
                            name="PolEta"
                            value={sailingVesselDetails.PolEta || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "PolEta",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="PortOfLoading"
                            value={sailingVesselDetails.PortOfLoading || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "PortOfLoading",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="date"
                            name="ETD"
                            value={sailingVesselDetails.ETD || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "ETD",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="PortOfDischarge"
                            value={sailingVesselDetails.PortOfDischarge || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "PortOfDischarge",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="date"
                            name="ETA"
                            value={sailingVesselDetails.ETA || ""}
                            onChange={(e) =>
                              handleSailingVesselDetails(
                                index,
                                "ETA",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          {sailingVesselDetails.length > 1 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "red" }}
                              onClick={() => removeSailingVesselDetails(index)}
                            />
                          )}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={30}
                            style={{ marginTop: "15px", color: "green" }}
                            onClick={addSailingVesselDetails}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* Table Conatiner */}

              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Container Type
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        QTY
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Weight Per container(KG)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Dimension(LXWXH IN M)
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {containers.map((container, index) => (
                      <tr key={index}>
                        <td>
                          <Select
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            type="text"
                            name="ContainerType"
                            value={container.ContainerType || ""}
                            onChange={(e) =>
                              handleContainerChange(
                                index,
                                "ContainerType",
                                e.target.value
                              )
                            }
                          >
                            <option>Select Container Type / Equipment</option>
                            {equipmentData.map((item, idx) => (
                              <option key={idx} value={item.ContainerType}>
                                {item.ContainerType}
                              </option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Quantity"
                            value={container.Quantity || ""}
                            onChange={(e) =>
                              handleContainerChange(
                                index,
                                "Quantity",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Weight"
                            value={container.Weight || ""}
                            onChange={(e) =>
                              handleContainerChange(
                                index,
                                "Weight",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="Dimensions"
                            value={container.Dimensions || ""}
                            onChange={(e) =>
                              handleContainerChange(
                                index,
                                "Dimensions",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          {containers.length > 1 && (
                            // <Button colorScheme='red' size='sm' float="left" marginLeft="5px" >
                            //   Remove
                            // </Button>
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "red" }}
                              onClick={() => removeContainer(index)}
                            />
                          )}
                          {/* <Button colorScheme='teal' size='sm' float="left" marginLeft="5px" >
                              Add More
                            </Button> */}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={30}
                            style={{ marginTop: "15px", color: "green" }}
                            onClick={addContainer}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            {/* Terms and Conditions */}
            <TabPanel>
              <div className="table-container">
                <table className="table-auto border-collapse border bg-white border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
                  <thead className="flex-nowrap text-center bg-blue-950 text-white">
                    <tr>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Terms & Conditions
                      </th>
                      <th className="border border-slate-300 whitespace-nowrap text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {termsandconditions.map((condition, index) => (
                      <tr key={index}>
                        <td>
                          <TextArea
                            type="text"
                            name="TermsAndConditions"
                            value={condition.TermsAndConditions || ""}
                            onChange={(e) =>
                              handleTermsandConditionChange(
                                index,
                                "TermsAndConditions",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          {termsandconditions.length > 1 && (
                            <BiSolidMinusCircle
                              className="cursor-pointer"
                              size={30}
                              style={{ marginTop: "15px", color: "red" }}
                              onClick={() => removeTermsandconditions(index)}
                            />
                          )}
                          <IoMdAddCircle
                            className="cursor-pointer"
                            size={30}
                            style={{ marginTop: "15px", color: "green" }}
                            onClick={addTermsandcondition}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {isOpenCancelBooking && (
        <>
          <div style={backdropStyle}></div>
          <div className="ConfirmCancelBooking">
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
              }}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Create Booking Cancel
              </header>
              <div onClick={onCloseCancelBooking} style={closeModal}>
                <CgClose />
              </div>
              <br />
              <FormControl>
                Are you sure you want to cancel the booking?
              </FormControl>
              <br />
              <Button colorScheme="green" onClick={handleConfirmCancel}>
                Yes
              </Button>
              <Button colorScheme="red" onClick={onCloseCancelBooking} ml={3}>
                No
              </Button>
            </Rnd>
          </div>
        </>
      )}
    </div>
  );
};

export default Createbooking;
