
import React, { createContext, useContext, useState } from "react";

// Create a Context
const SelectedModulesContext = createContext();

// Provider component
export const SelectedModulesProvider = ({ children }) => {
  const [selectedOtherModules, setSelectedOtherModules] = useState([]);

  const handleModuleSelect = (module) => {
    setSelectedOtherModules((prev) => {
      if (prev.includes(module)) {
        return prev.filter((m) => m !== module); // Remove module if already selected
      } else {
        return [...prev, module]; // Add module if not selected
      }
    });
  };

  return (
    <SelectedModulesContext.Provider
      value={{ selectedOtherModules, handleModuleSelect }}
    >
      {children}
    </SelectedModulesContext.Provider>
  );
};

// Custom hook for using the context
export const useSelectedModules = () => {
  return useContext(SelectedModulesContext);
};
