import React, { useEffect, useState } from "react";
import { OperationDashboardAPIInstance } from "../../../api-instance";
import { useToast } from "@chakra-ui/react";
import { Pie, Bar, Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import Select from "react-select";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Stack, HStack, VStack, IconButton } from "@chakra-ui/react";
import { Flex, Spacer } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import html2pdf from "html2pdf.js";

// Register ChartDataLabels plugin
Chart.register(ChartDataLabels);

const OngoingModule = () => {
  const itemsPerPage = 5;
  const [selectedSection, setSelectedSection] = useState(0); // 0 = Chart, 1 = Table
  const [showContent, setShowContent] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [ongoingData, setOngoingData] = useState([]);
  const [selectedChart, setSelectedChart] = useState({
    value: "pie",
    label: "Pie",
  }); // Set default chart to 'Pie'
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMaxYValue, setSelectedMaxYValue] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    value: "all",
    label: "All",
  }); // Default to 'All'
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const chartOptions = [
    { value: "pie", label: "Pie" },
    { value: "bar", label: "Bar" },
    { value: "line", label: "Line" },
    { value: "doughnut", label: "Doughnut" },
  ];

  // Fetch users data
  const fetchUsersData = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).userId;

      const { data } = await OperationDashboardAPIInstance.get(
        `get_users?userId=${userId}`
      );
      setUserData(
        data.data.map((userId) => ({ value: userId, label: userId }))
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Function to fetch ongoing data
  const getOngoingData = async (userId) => {
    try {
      setIsLoading(true);
      const token = JSON.parse(localStorage.getItem("token"));
      const storedUserId = JSON.parse(localStorage.getItem("user")).userId;

      const finalUserId = userId === "all" ? storedUserId : userId;
      const type = userId === "all" ? "all" : "no";
      if (finalUserId && token) {
        const { data } = await OperationDashboardAPIInstance.get(
          `get_ongoingdata?userId=${finalUserId}&type=${type}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        setOngoingData(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching ongoing data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersData();
    getOngoingData("all"); // Fetch data for "All" users initially
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getOngoingData(selectedUser.value);
    }
  }, [selectedUser]);

  // Pagination Logic
  function paginateArray(array, currentPage) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = array.slice(startIndex, endIndex);
    const totalPage = Math.ceil(array.length / itemsPerPage);

    setCurrentPage(currentPage);
    setFiltered(paginatedData);
    setTotalPage(totalPage);
  }

  useEffect(() => {
    if (!ongoingData.length) return;
    paginateArray(ongoingData, currentPage);
  }, [ongoingData, currentPage]);

  // Handle chart and product selection changes
  const handleChartChange = (selectedOption) => {
    setSelectedChart(selectedOption);
  };

  const handleProductsChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  const handleMaxYValueChange = (event) => {
    setSelectedMaxYValue(event.target.value);
  };

  const handleIntervalChange = (event) => {
    setSelectedInterval(event.target.value);
  };
  // const renderChart = () => <div>Chart Component</div>;
  // const renderTable = () => <div>Table Component</div>;

  const handlePrevious = () => {
    setSelectedSection((prev) => (prev === 0 ? 1 : 0));
  };

  const handleNext = () => {
    setSelectedSection((prev) => (prev === 1 ? 0 : 1));
  };
  // Filter data based on the selected products
  const filteredData = selectedProducts.length
    ? ongoingData.filter((item) =>
        selectedProducts.map((product) => product.value).includes(item.Product)
      )
    : filtered;

  // Define chart data structure
  const chartData = {
    labels: filteredData.map((item) => item.Product),
    datasets: [
      {
        label: "Count",
        data: filteredData.map((item) => item.Count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.3)", // Bright Pink
          "rgba(54, 162, 235, 0.3)", // Bright Blue
          "rgba(255, 206, 86, 0.3)", // Bright Yellow
          "rgba(75, 192, 192, 0.3)", // Teal
          "rgba(153, 102, 255, 0.3)", // Bright Purple
          "rgba(255, 159, 64, 0.3)", // Bright Orange
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Bright Pink
          "rgba(54, 162, 235, 1)", // Bright Blue
          "rgba(255, 206, 86, 1)", // Bright Yellow
          "rgba(75, 192, 192, 1)", // Teal
          "rgba(153, 102, 255, 1)", // Bright Purple
          "rgba(255, 159, 64, 1)", // Bright Orange
        ],

        borderWidth: 1,
      },
    ],
  };
  // Chart options with data labels
  const chartOptionsWithLabels = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2) + "%";
          return percentage;
        },
        color: "black",
      },
    },
  };

  // Bar and Line chart specific options
  const commonChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: selectedMaxYValue ? parseInt(selectedMaxYValue, 10) : undefined,
        ticks: {
          stepSize: selectedInterval
            ? parseInt(selectedInterval, 10)
            : undefined,
        },
      },
    },
  };

  // Function to render selected chart type
  const renderChart = () => {
    const chartHeight =
      100 +
      (selectedProducts.length > 1 ? (selectedProducts.length - 1) * 20 : 0);
    const chartStyle = { height: `${chartHeight}px` };
    switch (selectedChart.value) {
      case "pie":
        return (
          <Pie
            style={fixedBoxStyle}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
      case "bar":
        return (
          <Bar
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={commonChartOptions}
          />
        );
      case "line":
        return (
          <Line
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={commonChartOptions}
          />
        );
      case "doughnut":
        return (
          <Doughnut
            style={{ width: "100%", height: "100%" }}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
      default:
        return (
          <Pie
            style={fixedBoxStyle}
            data={chartData}
            options={chartOptionsWithLabels}
          />
        );
    }
  };

  // Generate options for the product select dropdown
  const productOptions = [
    ...new Set(
      ongoingData.map((item) => ({ value: item.Product, label: item.Product }))
    ),
  ];

  // Function to render the data table
  const renderTable = () => {
    return (
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-neutral-200">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase tracking-wider"
            >
              Product
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase tracking-wider"
            >
              Count
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.Product}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {item.Count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const fixedBoxStyle = {
    width: "100%",
    height: "300px", // Adjust to your preferred fixed size
    minHeight: "300px",
    // overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid #e2e2e2",
    // borderRadius: "md",
    // bg: "gray.50",
  };

  // Function to download the chart and table as PDF
  const downloadContentAsPDF = () => {
    const element = document.getElementById("content-container");
    const opt = {
      margin: 1,
      filename: "OngoingwiseJob_chart.pdf",
      image: { type: "jpeg", quality: 2.0 }, // Set image quality to maximum (1.0)
      html2canvas: { scale: 4 }, // Increase scale for higher resolution (4)
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };

  // return (
  //   <>
  //     <div id="content-container" className="flex flex-wrap mb-4 gap">
  //       <div
  //         className="flex flex-wrap mb-4 flex-col gap"
  //         style={{ marginLeft: "10%" }}
  //       >
  //         <HStack spacing="24px">
  //           <Select
  //             options={[{ value: "all", label: "All" }, ...userData]}
  //             value={selectedUser}
  //             onChange={setSelectedUser}
  //             className="w-40"
  //           />
  //           <Select
  //             options={chartOptions}
  //             value={selectedChart}
  //             onChange={handleChartChange}
  //             className="w-40"
  //           />
  //           <Select
  //             isMulti
  //             options={productOptions}
  //             value={selectedProducts}
  //             onChange={handleProductsChange}
  //             isClearable
  //             className="w-40"
  //           />
  //         </HStack>
  //         <div id="chart-container" className="mb-4 flex-initial w-full">
  //           {renderChart()}
  //         </div>
  //       </div>
  //       <div
  //         className="flex flex-wrap mb-4 flex-col gap"
  //         style={{ marginLeft: "30%" }}
  //       >
  //         <div id="table-container">{renderTable()}</div>
  //       </div>
  //       <div
  //         className="no-print"
  //         style={{ position: "absolute", top: "10px", right: "10px" }}
  //       >
  //         <Menu>
  //           <MenuButton>
  //             <BsThreeDotsVertical />
  //           </MenuButton>
  //           <MenuList>
  //             <MenuItem onClick={openModal}>View Chart</MenuItem>
  //           </MenuList>
  //         </Menu>
  //       </div>
  //     </div>
  //     <Modal isOpen={isModalOpen} onClose={closeModal}>
  //       <ModalOverlay />
  //       <ModalContent>
  //         <ModalHeader>Ongoing wise job</ModalHeader>
  //         <ModalCloseButton />
  //         <ModalBody>
  //           {renderChart()}
  //           {renderTable()}
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button colorScheme="blue" mr={3} onClick={closeModal}>
  //             Close
  //           </Button>
  //           <Button colorScheme="blue" mr={3} onClick={downloadContentAsPDF}>
  //             Download Pdf
  //           </Button>
  //         </ModalFooter>
  //       </ModalContent>
  //     </Modal>
  //   </>
  // );

  return (
    <Box
      className="main-card"
      // border="1px solid #ccc"
      borderRadius="2xl"
      p={4}
      // shadow="md"
      bg="white"
      maxW="90%"
      mx="auto"
    >
      {/* Card Title */}

      {/* Navigation Icons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={handlePrevious}
          aria-label="Previous Section"
          disabled={selectedSection === 0}
          //colorScheme="blue"
        />

        <IconButton
          icon={<ChevronRightIcon />}
          onClick={handleNext}
          aria-label="Next Section"
          disabled={selectedSection === 1}
          //colorScheme="blue"
        />
      </Box>

      {/* Sections */}
      {selectedSection === 0 && (
        <Box>
          {/* Dropdowns and Chart */}
          <HStack spacing={4} mb={4} width="100%" justify="center">
            <Select
              options={[{ value: "all", label: "All" }, ...userData]}
              value={selectedUser}
              onChange={setSelectedUser}
              className="w-40"
            />
            <Select
              options={chartOptions}
              value={selectedChart}
              onChange={handleChartChange}
              className="w-40"
            />
            <Select
              isMulti
              options={productOptions}
              value={selectedProducts}
              onChange={handleProductsChange}
              isClearable
              className="w-40"
            />
          </HStack>
          <Box style={fixedBoxStyle}>{renderChart()}</Box>
        </Box>
      )}

      {selectedSection === 1 && (
        <Box>
          {/* Table */}
          <HStack spacing={4} mb={4} width="100%" justify="center">
            {" "}
          </HStack>
          <Box style={fixedBoxStyle}>{renderTable()}</Box>
        </Box>
      )}
    </Box>
  );
};

export default OngoingModule;
