import React, { useState, useEffect } from "react";
import "./Register.css";
import "../../assets/css/login.css";
// import MXM from "../assets/img/MXM.png";
import MXM from "../../assets/img/MXM.mp4";
import { useNavigate } from "react-router-dom";
import Axios, {
  AuthAPIInstance,
  MasterDataAPIInstance,
} from "../../api-instance";
import { useToast } from "@chakra-ui/react";
import { BiShow, BiHide } from "react-icons/bi";
import { Tooltip } from "@chakra-ui/react";

const Register = () => {
  const toast = useToast();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setShowPassword(!showPassword);
  };
  const [inputType, setInputType] = useState("text");

  const changeInputType = () => {
    setInputType("date");
  };
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [roleid, setRoleId] = useState(null);
  const [roledata, setRoleData] = useState([]);
  const [reporterData, setReporterData] = useState([]);
  const [userId, setuserId] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredReporters, setFilteredReporters] = useState([]);
  const [noMatchingReporters, setNoMatchingReporters] = useState(false);
  const [formData, setFormData] = useState({
    //fullname: null,
    phone: null,
    userId,
    regionId,
    roleid,
    username: null,
    firstname: null,
    lastname: null,
    //initial: null,
    email: null,
    // role: null,
    //role_id: "",
    //reportingId: "",
    //country: null,
    //validfrom: null,
    //validto: null,
    password: null,
    // regionId: null,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_role");
        setRoleData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_reporter");
        setReporterData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await AuthAPIInstance.get(
          "/uniqueId/get_useridnumber"
        );
        setFormData((prev) => {
          return {
            ...prev,
            userId: data.data,
          };
        });
        setuserId(data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [userId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await AuthAPIInstance.get("/uniqueId/get_rolenumber");
        setFormData((prev) => {
          return {
            ...prev,
            roleid: data.data,
          };
        });
        setRoleId(data?.data);
      } catch (error) { 
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [roleid]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await AuthAPIInstance.get("/uniqueId/get_regionId");
        setFormData((prev) => {
          return {
            ...prev,
            regionId: data.data,
          };
        });
        setRegionId(data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [regionId]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // If the input field being changed is 'fullname'
    if (name === "fullname") {
      const [firstname, lastname] = value.split(" ");
      setFormData((prev) => {
        return {
          ...prev,
          firstname: firstname,
          lastname: lastname,
          //initial: initial,
          [name]: value,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setInputValue(inputText);

    if (inputText === "") {
      setFilteredReporters([]);
      setNoMatchingReporters(false); // Clear no matching reporters message
    } else {
      const filtered = reporterData.filter(
        (item) =>
          item.userId.toLowerCase().includes(inputText.toLowerCase()) ||
          item.firstname.toLowerCase().includes(inputText.toLowerCase()) ||
          item.lastname.toLowerCase().includes(inputText.toLowerCase())
      );

      setFilteredReporters(filtered);
      setNoMatchingReporters(filtered.length === 0); // Show message if no matches
    }
  };

  const handleSuggestionClick = (item) => {
    const selectedReporter = `${item.userId} - ${item.firstname} ${item.lastname}`;
    setInputValue(selectedReporter);

    setFormData((prev) => ({
      ...prev,
      reportingId: item.userId, // Update the reportingId field
    }));

    setFilteredReporters([]); // Clear suggestions
    setNoMatchingReporters(false); // Clear no matching reporters message
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const handleValidToChange = (e) => {
    const { value } = e.target;
    const selectedDate = new Date(value);
    const selectedDay = selectedDate.getDay(); // 0: Sunday, 6: Saturday

    if (selectedDay === 0 || selectedDay === 6) {
      toast({
        title: "Saturday and Sunday should not be considered",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    const currentDateObj = new Date(currentDate);
    if (selectedDate < currentDateObj) {
      toast({
        title: "Selected date should not be before the current date",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    handleChange(e);
  };

  const handleValidFromChange = (e) => {
    const { value } = e.target;
    const selectedDate = new Date(value);
    const selectedDay = selectedDate.getDay(); // 0: Sunday, 6: Saturday

    if (selectedDay === 0 || selectedDay === 6) {
      toast({
        title: "Saturday and Sunday should not be considered",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    const currentDateObj = new Date(currentDate);
    if (selectedDate < currentDateObj) {
      toast({
        title: "Selected date should not be before the current date",
        position: "bottom-center",
        isClosable: true,
        status: "warning",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    setFormData((prev) => ({
      ...prev,
      validfrom: value, // Update the validfrom property in form data
    }));
    handleChange(e);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await AuthAPIInstance.post("/register", formData);

      console.log("====================================");
      console.log(data);
      console.log("====================================");

      if (data.success) {
        toast({
          title: "User created Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });

        navigate("/");
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <div className="p-4 flex flex-shrink w-full bg-slate-200">
      <div className="rounded-2xl flex flex-shrink shadow-md sm:flex-row flex-col w-full">
        <div className="flex flex-1 w-full ">
          <div className="container-control">
            <div className="rotating-text-wrapper">
              <h2>Welcome to Matrix Mindz</h2>
            </div>
            <video
              className="h-screen video-control"
              height=""
              autoPlay
              muted
              loop
            >
              <source src={MXM} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-evenly">
          <div className="px-8 md:px-16 overflow-auto">
            <h2 className="mt-20 font-bold text-2xl text-[#002D74]">Welcome</h2>
            <p className="text-xs  text-[#002D74]">
              Welcome! Please enter your details.
            </p>
            <br />
            <form
              onSubmit={handleSubmit}
              className="font-sans text-sm rounded w-full max-w-xs mx-auto pb-8"
            >
              {/* <Tooltip
                hasArrow
                label="User Id"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your username"
                    name="userId"
                    value={userId}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </Tooltip> */}
              <Tooltip
                hasArrow
                label="Username"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your username"
                    name="username"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Full Name"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your full name"
                    name="fullname"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Email Id"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip>
              {/* <Tooltip
                hasArrow
                label="First Name"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your firstname"
                    name="firstname"
                    id="firstname"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip> */}
              {/* <Tooltip
                hasArrow
                label="Last Name"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your lastname"
                    id="lastname"
                    name="lastname"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip> */}
              {/* <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                <select
                  className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded"
                  type="text"
                  placeholder="Enter your role"
                  name="role"
                  id="role"
                  onChange={handleChange}
                >
                  <option>Select Role</option>
                  {roledata.map((item) => (
                    <option key={item.Role_name} value={item.Role}>
                      {item.Role}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <Tooltip
                hasArrow
                label="Reporter Id"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your reporter id"
                    name="reportingId"
                    id="reportingId"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <div className="suggestions">
                    {filteredReporters.map((item) => (
                      <div
                        key={item.userId}
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(item)}
                      >
                        {item.userId} - {item.firstname} {item.lastname}
                      </div>
                    ))}
                    {noMatchingReporters && inputValue.length > 0 && (
                      <p className="no-matching-reporters">
                        No matching reporters
                      </p>
                    )}
                  </div>
                </div>
              </Tooltip> */}
              {/* <Tooltip
                hasArrow
                label="Role Id"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your role id"
                    name="role_id"
                    value={roleid}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </Tooltip> */}
              {/* <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                <input
                  className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded"
                  type="text"
                  placeholder="Enter your user group"
                  required
                  name="usergroup"
                  onChange={handleChange}
                />
              </div> */}
              {/* <Tooltip
                hasArrow
                label="Country"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating ">
                  <select
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your country"
                    name="country"
                    id="country"
                    onChange={handleChange}
                  >
                    <option>Select Country</option>
                    {countryData.map((item) => (
                      <option key={item.Country_code} value={item.Country_name}>
                        {item.Country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Tooltip> */}
              {/* <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                <select
                  className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded"
                  type="text"
                  placeholder="Enter your city"
                  required
                  name="city"
                  onChange={handleChange}
                >
                  <option>Select City</option>
                  {citiesDataSet?.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </div> */}
              {/* <Tooltip
                hasArrow
                label="Valid from"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    placeholder="Enter your valid from date"
                    type="date"
                    id="validfrom"
                    name="validfrom"
                    onFocus={changeInputType}
                    onChange={handleValidFromChange}
                    //value={formData.validfrom || currentDate}
                    min={currentDate} // Set the minimum date to the current date
                  />
                </div>
              </Tooltip> */}
              {/* <Tooltip
                hasArrow
                label="Valid to"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    placeholder="Enter your valid to date"
                    type="date"
                    id="validto"
                    name="validto"
                    onFocus={changeInputType}
                    onChange={handleValidToChange}
                    //value={formData.validto || currentDate}
                    min={formData.validfrom || currentDate} // Set the minimum date to valid from date
                  />
                </div>
              </Tooltip> */}
              <Tooltip
                hasArrow
                label="Password"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    id="password"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Confirm Password"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter your confirm password"
                    id="password"
                    onChange={handleChange}
                  />
                  {showPassword ? (
                    <BiShow
                      size={20}
                      className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={handleShowPassword}
                    />
                  ) : (
                    <BiHide
                      size={20}
                      className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={handleShowPassword}
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Phone Number"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="flex border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-1/4 py-2 px-3 bg-slate-200 text-base leading-normal rounded"
                    type="text"
                    placeholder="+91"
                    value="+91"
                    disabled
                    onChange={handleChange}
                  />
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your phone number"
                    name="phone"
                    onChange={handleChange}
                  />
                </div>
              </Tooltip>
              {emailError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className=" font-bold block sm:inline">
                    {emailError}
                  </span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    {/* <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg> */}
                  </span>
                </div>
              )}

              {passwordError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className=" font-bold block sm:inline">
                    {emailError}
                    {passwordError}
                  </span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    &nbsp;
                    {/* <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg> */}
                  </span>
                </div>
              )}
              <div className="text-xs  text-[#002D74]">
                <div className="flex items-center">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-amber-500 bg-slate-200 rounded focus:ring-amber-500"
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 text-sm font-medium whitespace-nowrap"
                  >
                    Remember for 30 days
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    {/* <a
                    href="#"
                    className="ml-2 text-sm font-medium whitespace-nowrap text-amber-500"
                  >
                    Forgot password?
                  </a> */}
                  </label>
                </div>
                <br />
                <button
                  type="submit"
                  className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
                >
                  Sign up
                </button>
                <br />
                <br />
                <p className="font-medium text-red-500">
                  Already have an account?
                </p>
                <br />
                <button
                  className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 text-blue-950 text-sm"
                  onClick={() => navigate(-1)}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
