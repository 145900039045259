import React from "react"; // Import React
import { useState, useEffect } from "react"; // Import necessary hooks
import { Header } from "../../components"; // Import a Header component
import axios from "axios"; // Import Axios for making HTTP requests
import { v4 as uuidv4 } from "uuid"; // Import the uuidv4 function for generating unique IDs
import {MasterDataAPIInstance} from "../../api-instance"; // Import Axios with a custom configuration
import { Center, useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI for displaying notifications
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router for navigation

import "./AddGst.css"; // Import CSS styles

const AddGst = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast(); // Initialize the useToast hook for notifications
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation

  const [formData, setFormData] = useState({
    // Initialize a state variable with form data,
    userId:JSON.parse(localStorage.getItem("user")).userId,
    Description: "",
    Gst: "",
    Sst: "",
    Vat: "",
    SacCode: "",
    ChargeType: "",
    No_tax: "",

  });

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(formData, "form"); // Log the form data to the console
    const { data } = await MasterDataAPIInstance.post(
      // Send a POST request using Axios
      `addMasterData/add_gst?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData, // Include form data in the request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
        },
      }
    );

    console.log("===================================="); // Log a separator line
    console.log(data); // Log the response data
    console.log("===================================="); // Log another separator line

    if (data.success) {
      // Check if the response indicates success
      toast({
        title: "Gst created successfully!", // Show a success toast notification
        position: "bottom-right",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/gst"); // Navigate to the "/gst" route
    } else {
      toast({
        title: data.message, // Show an error toast notification
        position: "top-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  // Function to handle input changes
  const handleChange = async (e) => {
    const { name, value } = e.target; // Get the name and value from the input element

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value, // Update the form data with the changed input value
      };
    });
  };
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await MasterDataAPIInstance.post(
        `addMasterData/add_gst?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: "Gst created successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header /> {/* Render a Header component */}
      <form>
        {/* Create a form element with a submission handler */}
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          {/* Create a top bar with buttons */}
          <p className="float-left py-1 px-2 rounded my-2">Create Gst</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmitNext(e);
            }}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            onClick={() => {
              navigate("/gst");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>
        <div className="container-from-data bg-white dark-bg-slate-950 text-black dark-text-white">
          {/* Create a container for form data */}
          <div className="title">Gst Information</div> {/* Add a title */}
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-gst">
                <div className="input-box">
                  <span className="gst">Description</span>
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    placeholder="Enter the Description"
                  />{" "}
                </div>
                <div className="input-box">
                  <span className="gst">Gst</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Gst"
                    value={formData.Gst}
                    onChange={handleChange}
                    placeholder="Enter the Gst"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="gst">Sst</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Sst"
                    value={formData.Sst}
                    onChange={handleChange}
                    placeholder="Enter the Sst"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="gst">Vat</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Vat"
                    value={formData.Vat}
                    onChange={handleChange}
                    placeholder="Enter the Vat"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="gst">SacCode</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="SacCode"
                    value={formData.SacCode}
                    onChange={handleChange}
                    placeholder="Enter the SacCode"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="gst">ChargeType</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="ChargeType"
                    value={formData.ChargeType}
                    onChange={handleChange}
                    placeholder="Enter the ChargeType"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                <div className="input-box">
                  <span className="gst">No_tax</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="No_tax"
                    value={formData.No_tax}
                    onChange={handleChange}
                    placeholder="Enter the No_tax"
                    align-content="center"
                  />{" "}
                  {/* Input field for Description */}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddGst;
