import { BsThreeDotsVertical } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Menu, PrimaryButton, Table } from "../../../components";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../../hooks";
// import { BiMinus } from "react-icons/bi";
// import { MdAdd } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import {
  formatDate,
  defaultOmnitedColumns,
  customizeTableHelpText,
} from "./helper";
import { Card } from "./Cards";
import { Tooltip } from "@chakra-ui/react";

export default function RecentTable({
  data,
  hideComponent,
  selectedScreen = "Jobs",
}) {
  const [columns, setColumnsOrg] = useLocalStorage(
    "DASHBOARD_RECENT_TABLE_COLUMNS",
    []
  );
 

  const setColumns = (newColumns) => {
    setColumnsOrg(newColumns);
  };

  const [showContent, setShowContent] = useState(true);
  const [customizingColumns, setCustomizingColumns] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [showContentIcon, setShowContentIcon] = useState(<IoIosArrowUp />);

  // Toggle the content and change the icon when the button is clicked
  const toggleContent = () => {
    setShowContent((prev) => !prev);
    setShowContentIcon((prevIcon) =>
      prevIcon.type === IoIosArrowUp ? <IoIosArrowDown /> : <IoIosArrowUp />
    );
  };

  const itemsPerPage = 5;

  const menuOptions = [
    // { text: "Hide Component", onClick: hideComponent },
    { text: "Customize Columns", onClick: () => setCustomizingColumns(true) },
    // { text: "Export" },
  ];

  useEffect(() => {
    if (!data) return;
    console.log("Data:", data);
    initialColumnSetup(data, selectedScreen);
    paginateArray(data, currentPage);
  }, [data]);

  function initialColumnSetup(data, selectedScreen) {
    // Pass 'selected' as a parameter
    if (!data || data[0] === undefined) return;
    const newColumns = Object.keys(data[0]).map((key) => ({
      name: key,
      visible: true,
    }));
    const omitted = [...defaultOmnitedColumns];
    const filteredColumns = newColumns.filter(
      (column) => !omitted.includes(column.name)
    );

    // Check the value of 'selected' and set the first column accordingly
    let jobOwnerColumnName = "JobOwnerName";
    if (selectedScreen === "Booking") {
      jobOwnerColumnName = "BookingOwnerName";
    }
    console.log("Job Owner Column Name:", jobOwnerColumnName);

    // Create a new array with the leadOwnerColumnName or jobOwnerColumnName as the first element
    const sortedColumns = [
      { name: jobOwnerColumnName, visible: true },
      ...filteredColumns.filter(
        (column) => column.name !== jobOwnerColumnName
      ),
    ];

    setColumns(sortedColumns);
  }

  function paginateArray(array, currentPage) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = array.slice(startIndex, endIndex);
    const totalPage = Math.ceil(array.length / itemsPerPage);

    setCurrentPage(currentPage);
    setFiltered(formatDate(paginatedData));
    setTotalPage(totalPage);
  }

  function prevPage() {
    if (currentPage === 1) return;
    paginateArray(data, currentPage - 1);
  }

  function nextPage() {
    if (currentPage === totalPage) return;
    paginateArray(data, currentPage + 1);
  }

  return (
    <Card className="flex flex-col gap-4">
      <div className="flex justify-between">
        <span className="flex items-center gap-6">
          <span className="text-medium font-semibold">Recently Added</span>
          <span
            onClick={toggleContent}
            className="flex-center w-6 h-6 p-1 rounded-full active:bg-gray-light cursor-pointer"
          >
            {showContentIcon}
          </span>
        </span>
        {customizingColumns ? (
          <span className="flex items-center gap-6">
            <PrimaryButton
              selected={true}
              onClick={() => setCustomizingColumns(false)}
            >
              Save
            </PrimaryButton>
            <Tooltip hasArrow label={customizeTableHelpText} placement="left">
              <span>
                <AiFillQuestionCircle className="cursor-help" />
              </span>
            </Tooltip>
          </span>
        ) : (
          <span className="flex items-center gap-6">
            <Menu data={menuOptions} toggleType="active" position="top-left">
              <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" />
            </Menu>
          </span>
        )}
      </div>
      <div
        data-hide={!showContent}
        className="max-h-screen  overflow-hidden transition-[max-height] duration-500 data-[hide=true]:max-h-0"
      >
        <div className="max-width-table overflow-x-auto">
          <Table
            {...{
              columns,
              setColumns,
              data: filtered,
              setData: () => null,
              enableDrag: customizingColumns,
              enableGrapScroll: true,
            }}
          />
        </div>
      </div>
    </Card>
  );
}
