import { React, useState, useEffect, useMemo } from "react";
import Axios, {
  MasterDataAPIInstance,
  ProfomaInvoiceAPIInstance,
} from "../../api-instance";
// Internal module import
import "./DraggableIcPiTable.css";
import { Rnd } from "react-rnd";
// Chakara ui modules
import { useNavigate } from "react-router-dom";

import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import {
  useDisclosure,
  IconButton,
  useToast,
  Stack,
  Button,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Badge,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { CgClose } from "react-icons/cg";

const DraggableIcPiTable = ({
  isLoading,
  columns,
  data,
  proinvData,
  getData,
  setProinvData,
  index,
  checkBoxSelected,
  setCheckBoxSelected,
  currentPage,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsProinv,
  pageNumbers,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  // State Variables
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedProinv, setSelectedProinv] = useState({});
  const [editedProinv, setEditedProinv] = useState({});
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const {
    isOpen: isTaxOpen,
    onOpen: onTaxOpen,
    onClose: onTaxClose,
  } = useDisclosure();
  const [portData, setPortData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [taxdesData, setTaxdesData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [billToStateData, setBillToStateData] = useState([]);
  const [billToCityData, setBillToCityData] = useState([]);
  //console.log(proinvData)
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  // const userRegion = JSON.parse(localStorage.getItem("user")).region;

  // Grade Map
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_product");
        setProductData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // UseEffects
  const productOptions = productData.map((data) => ({
    label: data.Product,
    value: data.Product,
  }));
  const handletypechange = (selectedOption) => {
    const typeOfShipment = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      TypeOfShipment: typeOfShipment,
    }));
  };

  useEffect(() => {
    if (selectedProinv) {
      let containers = [];
      try {
        containers = JSON.parse(selectedProinv.Containers);
      } catch (e) {
        containers = selectedProinv.Containers || [];
      }
      setEditedProinv({ ...selectedProinv, Containers: containers });
    }
  }, [selectedProinv]);

  // const handleInnerContainerChange = (e, index, field) => {
  //   const newContainers = editedProinv.Containers.map((container, i) => {
  //     if (i === index) {
  //       return { ...container, [field]: e.target.value };
  //     }
  //     return container;
  //   });

  //   setEditedProinv({ ...editedProinv, Containers: newContainers });
  // };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_equipment");
        setEquipmentData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const { data } =  await ProfomaInvoiceAPIInstance.get(
//             `get_bank?notcountry=${piregion}`
//           );
//         setClientData(data.data);
//         console.log(data.data);
//       } catch (error) {
//         toast({
//           title: error.message,
//           position: "bottom-center",
//           isClosable: true,
//           status: "error",
//           containerStyle: {
//             width: "98.5vw",
//             maxWidth: "98.5vw",
//             alignContent: "center",
//           },
//         });
//       }
//     };
//     getData();
//   }, []);

  useEffect(() => {
    if (selectedProinv) {
      let containers = [];
      try {
        containers = JSON.parse(selectedProinv.Containers);
      } catch (e) {
        containers = selectedProinv.Containers || [];
      }
      setEditedProinv({ ...selectedProinv, Containers: containers });
    }
  }, [selectedProinv]);

  useEffect(() => {
    if (editedProinv.Containers) {
      const totalAmtInclGst = editedProinv.Containers.reduce(
        (sum, container) => {
          const amtInclGst = parseFloat(container.AmtInclGst);
          return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
        },
        0
      );

      const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

      setEditedProinv((prev) => ({
        ...prev,
        TotalInvValue: roundedTotalAmtInclGst,
      }));
    }
  }, [editedProinv.Containers]);

  useEffect(() => {
    const getData = async () => {
      try {
        // Assuming you have PiRegion and BillAddressCountry available
        const region = selectedProinv.PiRegion;
        const country = editedProinv.BillAddressCountry;
  
        // Pass PiRegion and BillAddressCountry as query parameters
        const { data } = await ProfomaInvoiceAPIInstance.get(`get_edittax?piregion=${region}&billcountry=${country}`);
        
        setTaxdesData(data.data);  // Update state with fetched tax data
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
  
    // Fetch tax data when region or country changes
    if (selectedProinv.PiRegion && editedProinv.BillAddressCountry) {
      getData();
    }
  }, [selectedProinv.PiRegion, editedProinv.BillAddressCountry]);
  
  const taxesOption = taxdesData.map((tax) => ({
    value: tax.Description,
    label: tax.Description,
    Tax: tax.Tax,  // Use 'Tax' field from API response
    SacCode: tax.SacCode,
  }));
  
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);

  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...proinvData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setProinvData === "function") {
      setProinvData(sortedData);
    }
  };

  // function handleContainerChange(e, index, field) {
  //   const { value } = e.target;

  //   const updatedContainers = editedProinv.Containers.map((container, i) => {
  //     if (i === index) {
  //       let updatedContainer = { ...container, [field]: value };

  //       // If the field is Description, update the Gst based on selected description
  //       if (field === "Description") {
  //         const selectedTaxDes = taxdesData.find((tax) => tax.Description === value);
  //         if (selectedTaxDes) {
  //           updatedContainer.Gst = selectedTaxDes.Gst;
  //         }
  //       }

  //       // Update TaxableAmount and AmtInclGst fields if relevant fields are changed
  //       if (["Qty", "UnitPrice", "Ex_Rate", "Gst", "amtInclGst"].includes(field)) {
  //         const qty = parseFloat(updatedContainer.Qty) || 0;
  //         const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
  //         const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
  //         const gst = parseFloat(updatedContainer.Gst) || 0;

  //         console.log('Values for calculation:', { qty, unitPrice, exRate, gst });

  //         const taxableAmount = (qty * unitPrice * exRate).toFixed(2);
  //         const amtInclGst = (parseFloat(taxableAmount) * (1 + gst / 100)).toFixed(2);
  //         console.log("GST Amount calc", amtInclGst);

  //         updatedContainer.TaxableAmount = taxableAmount;
  //         updatedContainer.AmtInclGst = amtInclGst;

  //         console.log('Updated Container:', updatedContainer);
  //       }

  //       return updatedContainer;
  //     }
  //     return container;
  //   });

  //   console.log('Updated Containers:', updatedContainers);

  //   setEditedProinv({ ...editedProinv, Containers: updatedContainers });
  // }
  // Function to add a new container
  const addContainer = () => {
    setEditedProinv((prevState) => ({
      ...prevState,
      Containers: [
        ...(prevState.Containers || []),
        {
          Pi_DescriptionNumber: "",
          Description: "",
          Currency: "",
          UnitPrice: "",
          Base: "",
          Qty: "",
          Ex_Rate: "",
          TaxableAmount: "",
          Gst: "",
          AmtInclGst: "",
          SacCode: "",
          DeletionFlag: "0",
        },
      ],
    }));
  };

  // Function to remove a container by index
  // const removeContainer = (index) => {
  //   setEditedProinv((prevState) => {
  //     const updatedContainers = (prevState.Containers || []).filter(
  //       (container, i) => i !== index
  //     );
  //     return { ...prevState, Containers: updatedContainers };
  //   });
  // };
  // Function to mark a container as deleted by setting DeletionFlag to 1
  const removeContainer = (index) => {
    setEditedProinv((prevState) => {
      const updatedContainers = prevState.Containers.map((container, i) => {
        if (i === index) {
          return { ...container, DeletionFlag: 1 };
        }
        return container;
      });
      return { ...prevState, Containers: updatedContainers };
    });
  };

  // Memoize the filtered containers
  const filteredContainers = useMemo(() => {
    return (editedProinv.Containers || []).filter(
      (container) => container.DeletionFlag !== 1
    );
  }, [editedProinv.Containers]);
  //split the ContainerType as base and QTy
  const parseContainerType = (containerType) => {
    return containerType.split(",").map((item) => {
      const [base, qty] = item.trim().split("-");
      return { base: base.trim(), qty: qty.trim() };
    });
  };
  // Function to handle changes in container fields
  console.log(
    "billtoCountry",
    editedProinv.BillAddressCountry,
    "userregion",
    // userRegion
    selectedProinv.PiRegion
  );
  const handleContainerChange = (e, index, field) => {
    const { value } = e.target;
    console.log("bill to country updated", editedProinv.BillAddressCountry);
    const updatedContainers = (editedProinv.Containers || []).map(
      (container, i) => {
        if (i === index) {
        let updatedContainer = { ...container, [field]: value };

          // Handle the Description field update
          if (field === "Description") {
            const selectedTaxDes = taxesOption.find(
              (tax) => tax.Description === value
            );
            console.log("assa",selectedTaxDes)
            if (selectedTaxDes) {
              updatedContainer.Gst = selectedTaxDes.Tax;
              updatedContainer.SacCode = selectedTaxDes.SacCode;

            }
          }

          if (["Qty", "UnitPrice", "Ex_Rate", "Gst", "Base"].includes(field)) {
            const qty = parseFloat(updatedContainer.Qty) || 0;
            const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
            const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
            const gstValue =
              parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;

            const taxableAmount = (qty * unitPrice * exRate).toFixed(2);
            const amtInclGst = (
              parseFloat(taxableAmount) *
              (1 + gstValue / 100)
            ).toFixed(2);

            updatedContainer.TaxableAmount = taxableAmount;
            updatedContainer.AmtInclGst = amtInclGst;
          }

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedProinv({ ...editedProinv, Containers: updatedContainers });
  };
  const handleDescriptionChange = (selectedOption, index) => {
    const updatedContainers = (editedProinv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          let updatedContainer = {
            ...container,
            Description: selectedOption.value,
            Gst: selectedOption.Tax,          // Update Gst based on selected tax data
            SacCode: selectedOption.SacCode,  
          };
          // if (selectedProinv.PiRegion === "India") {
          //   updatedContainer.Gst = selectedOption.gst;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // } else if (selectedProinv.PiRegion === "United Kingdom") {
          //   if (editedProinv.BillAddressCountry === "United Kingdom") {
          //     updatedContainer.Gst = selectedOption.vat;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   } else {
          //     updatedContainer.Gst = selectedOption.no_tax;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   }
          // } else if (selectedProinv.PiRegion === "Malaysia") {
          //   if (editedProinv.BillAddressCountry === "Malaysia") {
          //     updatedContainer.Gst = selectedOption.sst;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   } else {
          //     updatedContainer.Gst = selectedOption.no_tax;
          //     updatedContainer.SacCode = selectedOption.SacCode;
          //   }
          // } else if (selectedProinv.PiRegion === "Singapore") {
          //   updatedContainer.Gst = selectedOption.no_tax;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // }
          // else if (selectedProinv.PiRegion === "Vietnam") {
          //   updatedContainer.Gst = selectedOption.no_tax;
          //   updatedContainer.SacCode = selectedOption.SacCode;
          // }
          // Update Gst based on selected description
          // updatedContainer.Gst = selectedOption.gst;

          // Calculate other values based on updated Gst
          const qty = parseFloat(updatedContainer.Qty) || 0;
          const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
          const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
          const gstValue =
            parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;
          const taxableAmount = (qty * unitPrice * exRate).toFixed(2);
          const amtInclGst = (
            parseFloat(taxableAmount) *
            (1 + gstValue / 100)
          ).toFixed(2);

          updatedContainer.TaxableAmount = taxableAmount;
          updatedContainer.AmtInclGst = amtInclGst;

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedProinv({ ...editedProinv, Containers: updatedContainers });
  };

  const equipmentOptions = equipmentData.map((item) => ({
    value: item.ContainerType,
    label: item.ContainerType,
  }));
  useEffect(() => {
    if (editedProinv?.ContainerType) {
      const parsedContainers = editedProinv?.ContainerType.split(", ").map(
        (item) => {
          const [base, qty] = item.split("-");
          return { base, qty };
        }
      );

      const updatedContainers = (editedProinv.Containers || []).map(
        (container, index) => {
          if (parsedContainers[index]) {
            return {
              ...container,
              Base: parsedContainers[index].base,
              Qty: parsedContainers[index].qty,
            };
          }
          return container;
        }
      );

      setEditedProinv({ ...editedProinv, Containers: updatedContainers });
    }
  }, [editedProinv?.ContainerType]);

  const handleBaseChange = (selectedOption, index) => {
    const base = selectedOption ? selectedOption.value : "";
    const containerTypeString = editedProinv?.ContainerType || "";

    // Extract the base and qty from the containerTypeString
    const regex = new RegExp(`(${base}-\\d+)`);
    const match = containerTypeString.match(regex);
    const qty = match ? match[0].split("-")[1] : "";

    const updatedContainers = (editedProinv.Containers || []).map(
      (container, i) => {
        if (i === index) {
          const updatedContainer = { ...container, Base: base, Qty: qty };

          // Recalculate TaxableAmount and AmtInclGst
          const unitPrice = parseFloat(updatedContainer.UnitPrice) || 0;
          const exRate = parseFloat(updatedContainer.Ex_Rate) || 0;
          const gstValue =
            parseFloat((updatedContainer.Gst || "0").replace("%", "")) || 0;

          const taxableAmount = (parseFloat(qty) * unitPrice * exRate).toFixed(
            2
          );
          const amtInclGst = (
            parseFloat(taxableAmount) *
            (1 + gstValue / 100)
          ).toFixed(2);

          updatedContainer.TaxableAmount = taxableAmount;
          updatedContainer.AmtInclGst = amtInclGst;

          return updatedContainer;
        }
        return container;
      }
    );

    setEditedProinv({ ...editedProinv, Containers: updatedContainers });
  };

  // Function to parse the ContainerType string
  // const parseContainerType = (containerType) => {
  //   if (!containerType) {
  //     return [];
  //   }
  //   const parts = containerType.split(', ');
  //   return parts.map(part => {
  //     const [base, qty] = part.split('-');
  //     return { base, qty };
  //   });
  // };

  // const parsedContainerTypes = parseContainerType(selectedProinv?.ContainerType);
  useEffect(() => {
    console.log("editedProinv updated:", editedProinv);
  }, [editedProinv]);

  useEffect(() => {
    const totalAmtInclGst = editedProinv.Containers
      ? editedProinv.Containers.reduce((sum, container) => {
          if (container.DeletionFlag === "0") {
            const amtInclGst = parseFloat(container.AmtInclGst);
            return sum + (isNaN(amtInclGst) ? 0 : amtInclGst);
          }
          return sum;
        }, 0)
      : 0;

    const roundedTotalAmtInclGst = totalAmtInclGst.toFixed(2);

    setEditedProinv((prevFormData) => ({
      ...prevFormData,
      TotalInvValue: roundedTotalAmtInclGst,
    }));
  }, [editedProinv.Containers]);

  //   useEffect(() => {
  //     const fetchBankData = async () => {
  //       try {
  //         const country = selectedProinv.PiRegion;
  //         const { data } = await ProfomaInvoiceAPIInstance.get(
  //           `get_bank?country=${country}`
  //         );
  //         setBankData(data.data);
  // console.log(data.data);
  //         setFormData((prevData) => ({
  //           ...prevData,
  //         }));
  //       } catch (error) {
  //         toast({
  //           title: error.message,
  //           position: "bottom-center",
  //           isClosable: true,
  //           status: "error",
  //           containerStyle: {
  //             width: "98.5vw",
  //             maxWidth: "98.5vw",
  //             alignContent: "center",
  //           },
  //         });
  //       }
  //     };

  //     fetchBankData();
  //   }, []);
  console.log(selectedProinv.PiRegion);
  // Mapping from bank ID to bank details
  const bankIdToDetailsMap = bankData.reduce((map, bank) => {
    map[bank.id] = `${bank.AccNumber}-${bank.BankName}`;
    return map;
  }, {});

  const bankOptions = bankData.map((bank) => ({
    value: bank.id,
    label: `${bank.BankName}-${bank.AccNumber}`,
  }));
  console.log(bankOptions);
  const handleBankDetailsChange = (e) => {
    const { value } = e.target;
    const selectedBankId =
    Object.keys(bankIdToDetailsMap).find(
      (key) => bankIdToDetailsMap[key] === value
    ) || value;
    setFormData((prevData) => ({
      ...prevData,
      BankDetails: value,
    }));
    setEditedProinv((prevData) => ({
      ...prevData,
      BankDetails: selectedBankId,
    }));
  };
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      console.log("name:", name);
      console.log("value:", value);

      // if (name === "BankDetails") {
      //   const selectedBank = bankData.find(
      //     (bank) => `${bank.AccNumber}-${bank.BankName}` === value
      //   );
      //   console.log("selectedBank:", selectedBank);
      //   const selectedBankName = bankData.find(
      //     (bank) => bank.BankName === value
      //   );
      //   const selectedBankId = selectedBank ? selectedBank.id : "";
      //   console.log("selectedBankId:", selectedBankId);

      //   setEditedProinv((prevData) => ({
      //     ...prevData,
      //     BankDetails: value,
      //     BankId: value,
      //     // BankName:selectedBankName
      //   }));
      // } else {
      setEditedProinv((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      // }
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
      ...provided,
      minWidth: "200px",
    }),
  };
  const custom = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided) => ({
      ...provided,
      minWidth: "200px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_port");
        setPortData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const portOptions = portData.map((port) => ({
    value: port.Port,
    label: port.Port,
  }));
  const handlePolChange = (selectedOption) => {
    const pol = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      POL: pol,
    }));
  };

  const handlePodChange = (selectedOption) => {
    const pod = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      POD: pod,
    }));
  };
  // const handleCountryChange = (selectedOption) => {
  //   const billAddressCountry = selectedOption ? selectedOption.value : "";
  //   setEditedProinv((prevData) => ({
  //     ...prevData,
  //     BillAddressCountry: billAddressCountry,
  //   }));
  // };
  // const handleStateChange = async(selectedOption) => {
  //   // const billAddressState = selectedOption ? selectedOption.value : "";
  //   // setEditedProinv((prevData) => ({
  //   //   ...prevData,
  //   //   BillAddressState: billAddressState,
  //   // }));
  //   const country = selectedOption ? selectedOption.value : "";
  //   setEditedProinv((prevData) => ({
  //     ...prevData,
  //     BillAddressCountry: country,
  //     BillAddressState: "",
  //     BillAddressCity: "",
  //   }));

  //   try {
  //     const { data } = await Axios.get(
  //       `/masterdata/get_country_state?countryData=${country}`
  //     );
  //     setBillToStateData(data.data);
  //   } catch (error) {
  //     toast({
  //       title: error.message,
  //       position: "bottom-center",
  //       isClosable: true,
  //       status: "error",
  //       containerStyle: {
  //         width: "98.5vw",
  //         maxWidth: "98.5vw",
  //         alignContent: "center",
  //       },
  //     });
  //   }
  // };
  const [countryData, setCountryData] = useState([]);
  const handleCountryChange = async (selectedOption) => {
    const country = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      BillAddressCountry: country,
      BillAddressState: "",
      BillAddressCity: "",
    }));

    if (country) {
      try {
        const { data } = await MasterDataAPIInstance.get(
          `get_country_state?countryData=${country}`
        );
        setBillToStateData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleStateChange = async (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      BillAddressState: state,
      BillAddressCity: "",
    }));

    if (state) {
      try {
        const { data } = await MasterDataAPIInstance.get(
          `get_state_city?stateData=${state}`
        );
        setBillToCityData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleCityChange = (selectedOption) => {
    const city = selectedOption ? selectedOption.value : "";
    setEditedProinv((prevData) => ({
      ...prevData,
      BillAddressCity: city,
    }));
  };

  const clientOptions = clientData.map((client) => ({
    value: client.id, // Unique value
    label: `${client.CompanyName}-${client.AccNumber}`,
    companyName: client.CompanyName,
    // value: client.CompanyName,
    // label: `${client.CompanyName}-${client.AccNumber}`,
    billTo_Address: client.AddressLine1,
    billTo_Country: client.Country,
    billTo_State: client.State,
    billTo_City: client.City,
    billTo_Pincode: client.Pincode,
    billTo_Gst: client.Gst_vatNumber,
    
  }));
  console.log("EIV", editedProinv);
  //country data
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const { data } = await MasterDataAPIInstance.get("get_country");
        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    const fetchStateOptions = async () => {
      if (selectedProinv.BillAddressCountry) {
        try {
          const { data } = await MasterDataAPIInstance.get(
            `get_country_state?countryData=${selectedProinv.BillAddressCountry}`
          );
          setBillToStateData(data.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };

    const fetchCityOptions = async () => {
      if (selectedProinv.BillAddressState) {
        try {
          const { data } = await MasterDataAPIInstance.get(
            `get_state_city?stateData=${selectedProinv.BillAddressState}`
          );
          setBillToCityData(data.data);
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };
    const fetchBankData = async () => {
      if (selectedProinv.PiRegion) {
        try {
          const country = selectedProinv.PiRegion;
          const { data } = await ProfomaInvoiceAPIInstance.get(
            `get_bank?country=${country}`
          );
          setBankData(data.data);
          console.log(data.data);
          
          setFormData((prevData) => ({
            ...prevData,
          }));
        } catch (error) {
          toast({
            title: error.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
        }
      }
    };
    const fetchClientData = async () => {
        if (selectedProinv.PiRegion) {
          try {
            const country = selectedProinv.PiRegion;
            const { data } = await ProfomaInvoiceAPIInstance.get(
              `get_bank?notcountry=${country}`
            );
            setClientData(data.data);
            console.log(data.data);
            
            setFormData((prevData) => ({
              ...prevData,
            }));
          } catch (error) {
            toast({
              title: error.message,
              position: "bottom-center",
              isClosable: true,
              status: "error",
              containerStyle: {
                width: "98.5vw",
                maxWidth: "98.5vw",
                alignContent: "center",
              },
            });
          }
        }
      };
      fetchClientData();
    fetchBankData();
    fetchCountryData();
    fetchStateOptions();
    fetchCityOptions();
  }, [
    selectedProinv.BillAddressCountry,
    selectedProinv.BillAddressState,
    selectedProinv.PiRegion,
  ]);

  const countryOptions = countryData.map((country) => ({
    value: country.Country_name,
    label: country.Country_name,
  }));
  const stateOptions = billToStateData.map((state) => ({
    value: state.State_name,
    label: state.State_name,
  }));
  const cityOptions = billToCityData.map((city) => ({
    value: city.City_name,
    label: city.City_name,
  }));

  const handleClientChange = async (selectedOption) => {
    const client = selectedOption ? selectedOption.value : "";
    const address = selectedOption ? selectedOption.billTo_Address : "";
    const city = selectedOption ? selectedOption.billTo_City : "";
    const state = selectedOption ? selectedOption.billTo_State : "";
    const country = selectedOption ? selectedOption.billTo_Country : "";
    const pincode = selectedOption ? selectedOption.billTo_Pincode : "";
    const gst = selectedOption ? selectedOption.billTo_Gst : "";

    // Update the form data
    setEditedProinv((prevData) => ({
      ...prevData,
      Bill_To: client,
      BillAddress: address,
      BillAddressCity: city,
      BillAddressCountry: country,
      BillAddressState: state,
      BillAddressPincode: pincode,
      BillGst: gst,
    }));

    // Fetch state options for the selected country
    if (country) {
      try {
        const { data: stateData } = await MasterDataAPIInstance.get(
          `get_country_state?countryData=${country}`
        );
        setBillToStateData(stateData.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }

    // Fetch city options for the selected state
    if (state) {
      try {
        const { data: cityData } = await MasterDataAPIInstance.get(
          `get_state_city?stateData=${state}`
        );
        setBillToCityData(cityData.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedProinv,
    };
    console.log("update data:", updateData);
    try {
      const { data } = await ProfomaInvoiceAPIInstance.put(
        `single_ic_update?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedProinv.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Inter-Company Proforma invoice Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedBooking state here
        setSelectedProinv((prevSelectedProinv) => ({
          ...prevSelectedProinv,
          ...editedProinv,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  //  Function to open the modal and fetch booking data for a particular ID
  const handleOpenModal = async (id) => {
    try {
      const selectedProinvData = proinvData.find((proinv) => proinv.id === id);
      setSelectedProinv(selectedProinvData);
      handleFullSizeClick();
      setSelectedRowId(id);

      // const { data } = await Axios.get(
      //   `/master_api/get_city?countryData=${selectedBookingData?.Country}`
      // );

      // setCityData(data.data);
    } catch (error) {
      console.log(error);
    }
    // console.log(ProinvNumber);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };
  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };
  const taxstyle = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "hidden",
    overflowX: "hidden",
  };


  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  const handleCloseContainer = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
    onClose(true);
  };

  // const handleEditProinv = (data) => {
  //   console.log(data);
  //   navigate(`/editproinv/${data.id}`);
  // };
  console.log("JD", proinvData);
  // const handleTaxationClick = (row) => {
  //   setSelectedRow(row);
  //   onTaxOpen(); // Ensure onTaxOpen is accessible here
  // };
  // const handleTaxationClick = async (id) => {
  //   console.log("Proforma Incovice id Data",id);
  //   try {
  //     const selectedProinvData = proinvData.find((proinv) => proinv.id === id);
  //     //console.log(selectedProinvData.Containers);
  //     setSelectedRow(selectedProinvData.Containers);
  //     console.log("ssrcontainer",selectedProinvData);
  //     onTaxOpen();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleTaxationClick = async (id) => {
    console.log("Proforma Invoice ID Data", id);
    try {
      const selectedProinvData = proinvData.find((proinv) => proinv.id === id);
      // Parse the Containers data
      setSelectedRow(JSON.parse(selectedProinvData.Containers));
      console.log("Selected Container Data", selectedProinvData);
      onTaxOpen();
    } catch (error) {
      console.log(error);
    }
  };
  console.log(selectedRow);
  console.log("selectedProinv data", selectedProinv);
  console.log("formdata", formData);
  console.log("edited data", editedProinv);
  console.log(data);
  return (
    <div>
      {!isLoading ? (
        tableColumnsProinv?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
                    <button
                    onClick={toggleIcon}
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    checkBoxAll={checkBoxAll}
                    >
                      {showFactCheck ? (
                        <MdOutlineFactCheck size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}
                    </button> */}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>
               
                {/* <th
              className="border border-slate-300 whitespace-nowrap text-left"
              style={{ textAlign: "left" }}
              draggable
            >
              <span style={{ cursor: "all-scroll" }}>
                Supply to
              </span>
              <BiSort
                className="whitespace-nowrap text-left"
                style={{
                  marginLeft: "160px",
                  marginTop: "-22px",
                  cursor: "pointer",
                }}
                onClick={() => sortTable('BillTo')}
              />
            </th> */}
                {tableColumnsProinv.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "Containers" &&
                    column.label !== "BankDetails" &&
                    column.label !== "BeneficaryName" &&
                    column.label !== "CompanySelection" &&
                    column.label !== "BankName" &&
                    column.label !== "AccNumber" &&
                    column.label !== "IFSCCode" &&
                    column.label !== "CompanyName" &&
                    column.label !== "Gst_vatNumber" &&
                    column.label !== "PiOwnerPhone" &&
                    column.label !== "PiOwnerEmail" &&
                    // column.label !== "add_costid" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <>
                          <span style={{ cursor: "all-scroll" }}>
                            {column.label}
                          </span>
                          <BiSort
                            className="whitespace-nowrap text-left"
                            style={{
                              marginLeft: "160px",
                              marginTop: "-22px",
                              cursor: "pointer",
                            }}
                            onClick={() => sortTable(column.key)}
                          />
                        </>
                        {/* )} */}
                      </th>
                    )
                )}
 <th
                  className="border border-slate-300 whitespace-nowrap text-left"
                  style={{ textAlign: "left" }}
                  draggable
                >
                  <span style={{ cursor: "all-scroll" }}>Bill to</span>
                  <BiSort
                    className="whitespace-nowrap text-left"
                    style={{
                      marginLeft: "160px",
                      marginTop: "-22px",
                      cursor: "pointer",
                    }}
                    onClick={() => sortTable("BillTo")}
                  />
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  Tax Details
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.PiNumber,
                                row?.PiRegion,
                                row?.BillAddressCountry,
                                row?.DeletionFlag,
                                row?.ConversionFlag,
                              );
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(
                                row?.id,
                                row?.PiNumber,
                                row?.PiRegion,
                                row?.BillAddressCountry,
                                row?.DeletionFlag,
                                row?.ConversionFlag,
                              );
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(
                                row?.id,
                                row?.PiNumber,
                                row?.PiRegion,
                                row?.BillAddressCountry,
                                row?.DeletionFlag,
                                row?.ConversionFlag,
                              )
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={
                            () => handleOpenModal(row?.id)
                            //handleEditProinv(row)
                          }
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>

                    {tableColumnsProinv.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "Containers" &&
                        column.label !== "BankDetails" &&
                        column.label !== "BeneficaryName" &&
                        column.label !== "CompanySelection" &&
                        column.label !== "BankName" &&
                        column.label !== "AccNumber" &&
                        column.label !== "IFSCCode" &&
                        column.label !== "CompanyName" &&
                        column.label !== "Gst_vatNumber" &&
                        column.label !== "PiOwnerPhone" &&
                        column.label !== "PiOwnerEmail" &&
                        column.isVisible === true &&
                        column.label !== "Bill to" && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {[
                              "ETA",
                              "ETD",
                              "Invoice_Date",
                              "Due_Date",
                              "ChangeAt",
                            ].includes(column.key) ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row["DeletionFlag"] === 0 ? (
                                <Badge colorScheme="green">NOT DELETED</Badge>
                              ) : row["DeletionFlag"] === 1 ? (
                                <Badge colorScheme="red">DELETED</Badge>
                              ) : (
                                row[column.key]
                              )
                            ) : column.key === "ConversionFlag" ? (
                              row["ConversionFlag"] === 1 ? (
                                <Badge colorScheme="red">CONVERTED</Badge>
                              ) : row["ConversionFlag"] === 0 ? (
                                <Badge colorScheme="green">NOT CONVERTED</Badge>
                              ) : (
                                row[column.key]
                              )
                            ) : (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                    <td>
                      {`${row.Bill_To}, ${row.BillAddress}, ${row.BillAddressCity}, ${row.BillAddressCountry}, ${row.BillAddressPincode}, ${row.BillAddressState}`}
                    </td>
                    <td>
                      <Button onClick={() => handleTaxationClick(row?.id)}>
                        Taxation
                      </Button>
                    </td>
                    {/* <td className="flex-nowrap text-center">
  {row.Containers && JSON.parse(row.Containers).map((container, index) => {
    return (
      <tr key={index}>
        {Object.entries(container).map(([key, value], detailIndex) => {
          if (key.trim() === "Pi_DescriptionNumber") {
            // If it's Pi_DescriptionNumber, you might want to handle it differently
            // For now, let's just return null
            return null;
          }
          return (
            <td
              style={{
                whiteSpace: "nowrap",
                paddingLeft: "50px",
              }}
              key={detailIndex}
            >
              {value}
            </td>
          );
        })}
      </tr>
    );
  })}
</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsProinv.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      {/* taxation button */}
      <>
        {Array.isArray(selectedRow) && selectedRow.length > 0 && (
          <div className={`MassUploadModal ${scrollBehavior}`}>
            <Rnd
              style={taxstyle}
              default={{
                x: 0,
                y: 0,
                width: "100%",
              }}
              cancel=".closemodule"
              allowAnyClick={true}
            >
              <header>Taxation Details</header>

              <div
                className="closemodule"
                onClick={() => handleCloseContainer(false)}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Tax</Th>
                      <Th>Base</Th>
                      <Th>Sac Code</Th>
                      <Th>Currency</Th>
                      <Th>PiNumber</Th>
                      <Th>UnitPrice</Th>
                      <Th>Amt Incl Tax</Th>
                      <Th>Description</Th>
                      <Th>TaxableAmt</Th>
                      <Th>Total Invoice Amt</Th>
                      <Th>Pi_DescriptionNumber</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedRow.map((container, index) => (
                      <Tr key={index}>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["Gst"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["Base"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["SacCode"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["Currency"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["PiNumber"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["UnitPrice"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["AmtInclGst"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["Description"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["TaxableAmount"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["AmtInclGst"]}
                        </Td>
                        <Td style={{ whiteSpace: "nowrap" }}>
                          {container["Pi_DescriptionNumber"]}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Rnd>
          </div>
        )}
      </>
      {/* single edit */}
      <>
        {isOpen && (
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 900,
                height: 500,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Proinv Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedProinv && Object.keys(selectedProinv).length > 0 && (
                <form>
                  <Badge variant="solid" colorScheme="red" fontSize="12pt">
                    Pi Number : {selectedProinv["PiNumber"]}
                  </Badge>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                      <FormLabel>JobNumber</FormLabel>
                        <Input
                          className="cursor-pointer"
                          type="text"
                          name="JobNumber"
                          defaultValue={
                            formData["JobNumber"] || selectedProinv["JobNumber"]
                          }
                          value={editedProinv["JobNumber"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <FormLabel>
                          TypeOfShipment<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Select
                          name="TypeOfShipment"
                          options={productOptions}
                          defaultValue={productOptions.find(
                            (data) =>
                              data.value === selectedProinv.TypeOfShipment
                          )}
                          value={productOptions.find(
                            (data) => data.value === formData.TypeOfShipment
                          )}
                          onChange={handletypechange}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          noOptionsMessage={() => "No options"}
                          isDisabled={true}
                        />
                         <FormLabel>ETA</FormLabel>
                        <Input
                          type="date"
                          name="ETA"
                          defaultValue={
                            (
                              editedProinv["ETA"] ||
                              formData["ETA"] ||
                              selectedProinv["ETA"] ||
                              ""
                            )?.split("T")[0] || ""
                          }
                          onChange={handleChange}
                          readOnly
                        />

                        <FormLabel>MBL</FormLabel>
                        <Input
                          type="text"
                          name="MBL"
                          defaultValue={
                            formData["MBL"] || selectedProinv["MBL"]
                          }
                          value={editedProinv["MBL"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                         <FormLabel>Invoice Date</FormLabel>
                      <Input
                        type="date"
                        name="Invoice_Date"
                        defaultValue={
                          (
                            editedProinv["Invoice_Date"] ||
                            formData["Invoice_Date"] ||
                            selectedProinv["Invoice_Date"] ||
                            ""
                          )?.split("T")[0] || ""
                        }
                        onChange={handleChange}
                        readOnly
                      />
                       <FormLabel>POL</FormLabel>
                      <Select
                        isClearable
                        options={portOptions}
                        defaultValue={portOptions.find(
                          (option) => option.value === selectedProinv.POL
                        )}
                        value={portOptions.find(
                          (option) => option.value === formData.POL
                        )}
                        onChange={handlePolChange}
                        classNamePrefix="react-select"
                        styles={customStyles}
                        noOptionsMessage={() => "No options"}
                        isDisabled={true}
                      />
                       <FormLabel>Vessel Name Voyage</FormLabel>
                      <Input
                        type="text"
                        name="VesselNameVoyage"
                        defaultValue={
                          formData["VesselNameVoyage"] ||
                          selectedProinv["VesselNameVoyage"]
                        }
                        value={editedProinv["VesselNameVoyage"] || ""}
                        onChange={handleChange}
                        readOnly
                      />
                       <FormLabel>Client</FormLabel>
                        {/* <Select
                          isClearable
                          options={clientOptions}
                          defaultValue={clientOptions.find(
                            (option) => option.value === selectedProinv.Bill_To
                          )}
                          value={clientOptions.find(
                            (option) => option.value === formData.Bill_To
                          )}
                          onChange={handleClientChange}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          noOptionsMessage={() => "No options"}
                          isDisabled={true}
                        /> */}
                        <div className="input-box">
                  <span className="proinvs">Client</span>
                  <Select
                    isClearable
                    options={clientOptions}
                    defaultValue={clientOptions.find(
                        (option) => option.companyName === selectedProinv.Bill_To
                      )}
                    value={clientOptions.find(
                      (option) => option.value === formData.Bill_to
                    )}
                    onChange={handleClientChange}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "No options"}
                    styles={customStyles}
                  />
                  
                 
                </div>
 <FormLabel>Client Country</FormLabel>
                        <Select
                          isClearable
                          options={countryOptions}
                          defaultValue={countryOptions.find(
                            (option) =>
                              option.value === selectedProinv.BillAddressCountry
                          )}
                          value={countryOptions.find(
                            (option) =>
                              option.value === editedProinv.BillAddressCountry
                          )}
                          onChange={handleCountryChange}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          noOptionsMessage={() => "No options"}
                          isDisabled={true}
                        />
                        <FormLabel>Client City</FormLabel>
                        <Select
                          isClearable
                          options={cityOptions}
                          defaultValue={cityOptions.find(
                            (option) =>
                              option.value === selectedProinv.BillAddressCity
                          )}
                          value={cityOptions.find(
                            (option) =>
                              option.value === editedProinv.BillAddressCity
                          )}
                          onChange={handleCityChange}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          noOptionsMessage={() => "No options"}
                          isDisabled={true}
                        />
                        <FormLabel>Client Tax</FormLabel>
                        <Input
                          type="text"
                          name="BillGst"
                          defaultValue={
                            formData["BillGst"] || selectedProinv["BillGst"]
                          }
                          value={editedProinv["BillGst"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                       

                        
                       
                      </FormControl>

                     
                     
                      
                     
                      {/* <FormLabel>Total Invoice Value</FormLabel>
                      <Input
                        type="text"
                        name="TotalInvValue"
                        value={editedProinv.TotalInvValue}
                        onChange={(e) =>
                          setEditedProinv({
                            ...editedProinv,
                            TotalInvValue: e.target.value,
                          })
                        }
                        readOnly
                      /> */}
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                      <FormControl>
                      <FormLabel>BookingNumber</FormLabel>
                        <Input
                          className="cursor-pointer"
                          type="text"
                          name="BookingNumber"
                          defaultValue={
                            formData["BookingNumber"] ||
                            selectedProinv["BookingNumber"]
                          }
                          value={editedProinv["BookingNumber"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <FormLabel>Container type</FormLabel>
                      <Input
                        type="text"
                        name="ContainerType"
                        defaultValue={
                          formData["ContainerType"] ||
                          selectedProinv["ContainerType"]
                        }
                        value={editedProinv["ContainerType"] || ""}
                        onChange={handleChange}
                        readOnly
                      />
                        <FormLabel>ETD</FormLabel>
                        <Input
                          type="date"
                          name="ETD"
                          defaultValue={
                            (
                              editedProinv["ETD"] ||
                              formData["ETD"] ||
                              selectedProinv["ETD"] ||
                              ""
                            )?.split("T")[0] || ""
                          }
                          onChange={handleChange}
                          readOnly
                        />
                       
                        <FormLabel>HBL</FormLabel>
                        <Input
                          type="text"
                          name="HBL"
                          defaultValue={
                            formData["HBL"] || selectedProinv["HBL"]
                          }
                          value={editedProinv["HBL"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <FormLabel>Due Date</FormLabel>
                      <Input
                        type="date"
                        name="Due_Date"
                        defaultValue={
                          (
                            editedProinv["Due_Date"] ||
                            formData["Due_Date"] ||
                            selectedProinv["Due_Date"] ||
                            ""
                          )?.split("T")[0] || ""
                        }
                        onChange={handleChange}
                      />
                     
                     <FormLabel>POD</FormLabel>
                      <Select
                        isClearable
                        options={portOptions}
                        defaultValue={portOptions.find(
                          (option) => option.value === selectedProinv.POD
                        )}
                        value={portOptions.find(
                          (option) => option.value === formData.POD
                        )}
                        onChange={handlePodChange}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "No options"}
                        styles={customStyles}
                        isDisabled={true}
                      />
                      <FormLabel>Bank Details</FormLabel>
                        <Input
                          className="cursor-pointer"
                          type="text"
                          name="BankDetails"
                          defaultValue={
                            bankIdToDetailsMap[selectedProinv.BankDetails] ||
                            formData.BankDetails
                          }
                          value={
                            bankIdToDetailsMap[editedProinv.BankDetails] ||
                            editedProinv.BankDetails
                          }
                          onChange={handleBankDetailsChange}
                          readOnly
                        />

                        <FormLabel>Client Address</FormLabel>
                        <Input
                          type="text"
                          name="BillAddress"
                          defaultValue={
                            formData["BillAddress"] ||
                            selectedProinv["BillAddress"]
                          }
                          value={editedProinv["BillAddress"] || ""}
                          onChange={handleChange}
                          readOnly
                        />

                       

                        <FormLabel>Client State</FormLabel>
                        <Select
                          isClearable
                          options={stateOptions}
                          defaultValue={stateOptions.find(
                            (option) =>
                              option.value === selectedProinv.BillAddressState
                          )}
                          value={stateOptions.find(
                            (option) =>
                              option.value === editedProinv.BillAddressState
                          )}
                          onChange={handleStateChange}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          noOptionsMessage={() => "No options"}
                          isDisabled={true}
                        />

                        

                        <FormLabel>Client Pincode</FormLabel>
                        <Input
                          type="text"
                          name="BillAddressPincode"
                          defaultValue={
                            formData["BillAddressPincode"] ||
                            selectedProinv["BillAddressPincode"]
                          }
                          value={editedProinv["BillAddressPincode"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        <FormLabel>Exchange Rate</FormLabel>
                        <Input
                          type="number"
                          name="ExchangeRate"
                          defaultValue={
                            formData["ExchangeRate"] ||
                            selectedProinv["ExchangeRate"]
                          }
                          value={editedProinv["ExchangeRate"] || ""}
                          onChange={handleChange}
                          readOnly
                        />
                        
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <br />
                  <br />
                  <div className="table-container">
                    <table className="table-auto border-collapse w-full border bg-white border-slate-400 dark:border-gray-600 dark:bg-slate-950">
                      <thead className="flex-nowrap text-center">
                        <tr>
                          {/* <th className="border border-slate-300 whitespace-nowrap text-left">Container Type</th> */}
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Description
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Currency
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Unit Price
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Base
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Qty
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Ex Rate
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Taxable Amount
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Tax
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Amount Incl Tax
                          </th>
                          <th className="border border-slate-300 whitespace-nowrap text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                     <tbody>
                     {editedProinv?.Containers?.every(container => container.DeletionFlag === 1) ? (
                      //this will render a add container when all the deletionflag is 1
     addContainer()
  ) : (
    // Render the rows for containers with DeletionFlag !== 1
    editedProinv?.Containers?.map((container, index) =>
      container.DeletionFlag !== 1 && (
        <tr key={index}>
                                <td>
                                  <Select
                                    styles={custom}
                                    options={taxesOption}
                                    name={`Description-${index}`}
                                    value={
                                      taxesOption.find(
                                        (option) =>
                                          option.value === container.Description
                                        
                                      ) 
                                    }
                                    onChange={(selectedOption) =>
                                      handleDescriptionChange(
                                        selectedOption,
                                        index
                                      )
                                    }
                                     menuPortalTarget={document.body} // Render the dropdown menu in the body
                                      menuPosition="fixed"
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`Currency-${index}`}
                                    type="text"
                                    value={container.Currency}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "Currency"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    width={150}
                                    name={`UnitPrice-${index}`}
                                    type="number"
                                    value={container.UnitPrice}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "UnitPrice"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <td>
                                    <Select
                                      styles={custom}
                                      options={equipmentOptions}
                                      name={`Base-${index}`}
                                      value={
                                        equipmentOptions.find(
                                          (option) =>
                                            option.value === container.Base
                                        ) || null
                                      }
                                      onChange={(selectedOption) =>
                                        handleBaseChange(selectedOption, index)
                                      }
                                      menuPortalTarget={document.body} // Render the dropdown menu in the body
                                      menuPosition="fixed"
                                    />
                                  </td>
                                </td>
                                <td>
                                  <Input
                                    width={100}
                                    name={`Qty-${index}`}
                                    type="number"
                                    value={container.Qty}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Qty")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`Ex_Rate-${index}`}
                                   type="number"
                                    value={container.Ex_Rate}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Ex_Rate")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`TaxableAmt-${index}`}
                                    type="text"
                                    value={container.TaxableAmount}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "TaxableAmount"
                                      )
                                    }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Input
                                    width={150}
                                    name={`Gst-${index}`}
                                    type="text"
                                    value={container.Gst}
                                    onChange={(e) =>
                                      handleContainerChange(e, index, "Gst")
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`AmtInclGst-${index}`}
                                    type="text"
                                    value={container.AmtInclGst}
                                    onChange={(e) =>
                                      handleContainerChange(
                                        e,
                                        index,
                                        "AmtInclGst"
                                      )
                                    }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <BiSolidMinusCircle
                                      className="cursor-pointer"
                                      size={30}
                                      style={{
                                        marginTop: "15px",
                                        color: "red",
                                      }}
                                      onClick={() => removeContainer(index)}
                                    />
                                    <IoMdAddCircle
                                      className="cursor-pointer"
                                      size={30}
                                      style={{
                                        marginTop: "15px",
                                        color: "green",
                                      }}
                                      onClick={addContainer}
                                    />
                                  </div>
                                </td>
          
        </tr>
      )
    )
  )}
  <tr>
                        <td colSpan={7}></td>
                        <td className="font-medium"> Total Incl Value :</td>
                        <td>
                          <Input
                            w="120px"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            type="number"

                            name="TotalInvValue"
                            value={editedProinv.TotalInvValue}
                            onChange={(e) =>
                              setEditedProinv({
                                ...editedProinv,
                                TotalInvValue: e.target.value,
                              })
                            }
                            readOnly
                          />
                        </td>
                        <td></td>
                      </tr>
</tbody>
                    </table>
                  </div>
                  {!selectedProinv && (
                    <p>Data not available for the selected booking.</p>
                  )}
                </form>
              )}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};

export default DraggableIcPiTable;
